import { Params } from '@api/types';

import { Graph } from '@modelTypes/graph';
import client from './client';

const GET_CONDITION_TAGS_URI = 'condition-tags/chart';
const PUT_CONDITION_TAGS_URI = 'condition-tags/chart';

export async function getConditionTags(params?: Params) {
  return client.get<Graph>(GET_CONDITION_TAGS_URI, params);
}

export async function putConditionTags(params?: Params) {
  return client.put(PUT_CONDITION_TAGS_URI, params);
}
