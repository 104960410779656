import {
  AntGetListParams,
  CreateTagRequest,
  GetListParams,
  ListResponse,
  UpdateTagRequest,
  UpdateTagResponse
} from "@api/types";

import { AxiosRequestConfig } from 'axios';
import { Tag } from "../modelTypes/tag";
import client from "./client";
import { getRestUri } from '@api/helpers';

const getUri = getRestUri('tags');
const ROOT_URI = getUri('');
const GET_TAGS_FOR_SELECT_URI = 'tags/select';

export async function getTags(params?: AntGetListParams) {
  return client.get<ListResponse<Tag>>(ROOT_URI.GET(), params);
}

export async function getTagsForSelect(params?: GetListParams) {
  return client.get<ListResponse<Tag>>(GET_TAGS_FOR_SELECT_URI, params);
}

export async function createTag(payload: CreateTagRequest) {
  return client.post<CreateTagRequest, Tag>(ROOT_URI.POST(), payload);
}

export async function updateTag(id: number, payload: UpdateTagRequest) {
  return client.patch<UpdateTagRequest, UpdateTagResponse>(ROOT_URI.PATCH({ id }), payload);
}

export async function deleteTag(id: number, payload?: AxiosRequestConfig) {
  return client.del(ROOT_URI.DELETE({ id }), payload);
}
