import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M11.3231 18.0136C11.6228 17.4114 11.6333 16.7649 11.3538 16.1929C11.0737 15.62 10.5223 15.2008 9.87899 15.0714C9.27911 14.9506 8.62798 14.7707 7.88899 14.5223C6.45128 14.0391 5.22771 13.2676 4.25307 12.2293C3.41204 11.3334 2.90734 10.3318 2.75313 9.25188C2.58978 8.10977 2.84877 6.98092 3.52324 5.89692C3.84491 5.37986 3.87694 4.90123 3.61933 4.47477C3.49212 4.26474 3.32465 4.09315 3.16267 3.9275C3.11416 3.87763 3.06566 3.82821 3.01853 3.77788C2.76 3.50058 2.50055 3.22466 2.2411 2.94829C1.85628 2.53876 1.45819 2.11504 1.07108 1.69544C0.957597 1.57235 0.612583 1.19805 0.218609 1.50783C0.0616594 1.63184 0.0149865 1.7746 0.00354702 1.87252C-0.0165864 2.04503 0.0488472 2.22348 0.203509 2.41887C0.236454 2.46051 0.268942 2.50215 0.30143 2.54425C0.419943 2.69708 0.542574 2.85494 0.684881 2.99999C1.33876 3.6676 1.91851 4.24735 2.45708 4.77265C2.68129 4.99137 2.69182 5.05498 2.55271 5.33913C2.51931 5.40731 2.48545 5.47549 2.45159 5.54367C2.23698 5.97745 2.01506 6.42634 1.85811 6.89764C1.40511 8.25894 1.49525 9.65638 2.12625 11.052C2.83595 12.6219 4.06043 13.8661 5.8697 14.8558C7.00083 15.4745 8.24041 15.9064 9.5532 16.1384C9.91926 16.2034 10.1966 16.3919 10.3347 16.6701C10.4679 16.9387 10.4537 17.2581 10.294 17.5688C9.93207 18.2735 9.95312 19.028 10.3558 19.8119C10.3874 19.8727 10.4194 19.9336 10.4514 19.9949C10.6422 20.355 10.823 20.6964 10.839 21.1022V21.2253V21.2821C10.8372 21.8691 11.1382 21.9954 11.3913 21.9982H11.3982C11.5977 21.9982 11.9289 21.9053 11.9619 21.2985C12.0017 20.5632 11.7015 19.9455 11.4169 19.4243C11.125 18.8894 11.0966 18.467 11.3227 18.0136H11.3231Z"
    fill="currentColor" />
  <path
    d="M24.1696 1.50509C24.0584 1.41723 23.7633 1.24884 23.4302 1.58791C23.3949 1.6236 23.3647 1.66021 23.3382 1.69224C23.3176 1.71695 23.2979 1.74028 23.2796 1.7595C23.0737 1.97365 22.8669 2.19237 22.6665 2.40423C22.1865 2.91168 21.6905 3.43607 21.1821 3.92613C20.5611 4.52464 20.4577 5.21467 20.8837 5.92254C21.4328 6.83541 21.6854 7.6833 21.6786 8.5916V8.60258C21.7056 9.40929 21.4877 10.2229 21.0302 11.02C20.2569 12.3684 19.047 13.4094 17.332 14.2024C16.3661 14.649 15.3608 14.8984 14.5046 15.0837C13.8338 15.2288 13.2944 15.6401 13.0244 16.2121C12.7512 16.7905 12.7777 17.4773 13.0967 18.0973C13.136 18.1742 13.1694 18.2588 13.196 18.349C13.2994 18.7031 13.185 19.0262 12.9667 19.4321C12.6629 19.9972 12.3879 20.6186 12.4318 21.3347C12.4469 21.5831 12.521 21.7666 12.6524 21.8801C12.7434 21.9588 12.8565 22 12.9809 22C12.9969 22 13.0134 21.9995 13.0303 21.9982C13.4984 21.9588 13.5392 21.5598 13.5588 21.3681L13.5602 21.3548C13.5895 21.0693 13.6201 20.7737 13.7034 20.506C13.7597 20.3257 13.854 20.1491 13.9533 19.9619C13.9894 19.8938 14.0256 19.826 14.0603 19.7579C14.4456 18.996 14.4507 18.2415 14.0759 17.5153C13.9414 17.2549 13.9258 16.974 14.0311 16.7241C14.1349 16.4784 14.3436 16.2972 14.6177 16.2139C14.9366 16.1174 15.2665 16.0291 15.5859 15.9435C16.0934 15.8076 16.6177 15.6671 17.1247 15.4868C18.8384 14.8773 20.2587 13.8899 21.3468 12.5524C22.2002 11.5032 22.6848 10.3249 22.7868 9.05055C22.8879 7.78672 22.585 6.56544 21.8872 5.42058C21.668 5.06092 21.6731 5.03438 22.0144 4.7063C22.6125 4.13158 23.1886 3.53445 23.7459 2.95744C23.9243 2.77258 24.0982 2.55843 24.2771 2.30173C24.4689 2.02764 24.4254 1.70734 24.1691 1.50509H24.1696Z"
    fill="currentColor" />
  <path
    d="M4.14783 3.63694C4.69464 4.00255 5.27027 4.01444 5.81204 3.67126C6.81505 3.03569 7.93063 2.56987 9.12719 2.28709C10.5654 1.94711 11.9358 1.84278 13.3163 1.96678C15.234 2.13975 16.9005 2.65956 18.411 3.55595C18.5959 3.66577 18.7926 3.75225 18.9825 3.83599C19.069 3.87397 19.1587 3.91378 19.2465 3.95496L19.3563 4.00667L19.4685 3.95999C19.5513 3.92567 19.6382 3.89593 19.722 3.8671C19.9169 3.8003 20.1187 3.7312 20.2893 3.59805C20.9469 3.08373 21.6305 2.51679 22.3782 1.86474C22.6019 1.66936 22.7946 1.4456 22.9808 1.22963C23.038 1.16328 23.0948 1.09693 23.1524 1.03195C23.2554 0.915727 23.3904 0.736356 23.3757 0.519007C23.3666 0.379446 23.2979 0.253612 23.1725 0.145166C22.9538 -0.0438139 22.7507 -0.00766526 22.6491 0.0307713C22.5219 0.0788169 22.4294 0.17262 22.3553 0.247663L22.3347 0.268254C22.2963 0.306691 22.2629 0.345127 22.2304 0.382191C22.2171 0.397291 22.2043 0.411934 22.191 0.427034C21.2946 1.42044 20.4738 2.14478 19.6066 2.70623C19.4483 2.80873 19.3234 2.81193 19.1756 2.71721C17.6848 1.76362 15.9941 1.16236 14.1505 0.929912C12.0937 0.670923 10.1352 0.807281 8.32872 1.33487C7.18706 1.66844 6.17307 2.11778 5.22954 2.70852C5.01448 2.84304 4.89231 2.78585 4.80171 2.71218L4.71797 2.64446C4.07919 2.12648 3.41937 1.59111 2.7902 1.04431C2.62135 0.897424 2.47035 0.725832 2.3102 0.544631C2.21685 0.438473 2.1203 0.329112 2.01872 0.223869C1.84164 0.0412956 1.60187 -0.0337472 1.39321 0.0293985C1.20972 0.0847655 1.0784 0.237139 1.04225 0.4371C1.0029 0.653535 1.12004 0.884154 1.2326 1.01594C2.11801 2.05372 3.09906 2.93548 4.14783 3.63694Z"
    fill="currentColor" />
  <path
    d="M12.9287 12.6865C12.7018 13.3083 12.6725 13.905 12.8404 14.4591C12.9557 14.8384 13.1983 14.9135 13.3717 14.9135C13.4408 14.9135 13.4989 14.9016 13.5332 14.8924C13.7263 14.8412 13.8251 14.7295 13.8741 14.6453C13.9514 14.5113 13.9652 14.3475 13.9144 14.1589C13.8247 13.8249 13.8348 13.517 13.9455 13.2172C14.1825 12.5766 14.1171 12.0417 13.746 11.5814C13.4655 11.2336 13.3255 10.7948 13.3419 10.313V10.2965C13.3204 9.4976 13.6627 8.98466 13.9542 8.69547C14.3646 8.28776 14.9517 8.05486 15.5264 8.07499C16.1025 8.09421 16.545 7.89653 16.9202 7.45223C17.3929 6.89261 17.9424 6.72331 18.6009 6.93379C18.7551 6.98321 18.9065 7.05368 19.0667 7.12872C19.1001 7.14428 19.133 7.15983 19.1665 7.17493C19.5961 7.37306 20.0189 7.34012 20.3886 7.08022C20.5575 6.9617 20.6591 6.67846 20.6064 6.47393C20.5067 6.08636 20.1274 6.10374 19.9658 6.11107H19.9544C19.9178 6.11335 19.8803 6.11564 19.8418 6.11839C19.7439 6.12525 19.5961 6.13578 19.5494 6.10924C18.9615 5.77566 18.2733 5.66355 17.6121 5.79351C16.9454 5.92437 16.3647 6.28906 15.9767 6.82077C15.9241 6.89261 15.8572 6.93974 15.6857 6.95072C15.3182 6.97451 14.9595 7.00243 14.6039 7.08388C13.8407 7.25821 13.2019 7.70664 12.7567 8.38065C11.9774 9.56029 12.0209 11.133 12.8656 12.2943C12.9708 12.4389 12.9855 12.5309 12.9287 12.6865Z"
    fill="currentColor" />
  <path
    d="M18.7006 8.67442C18.6819 8.652 18.6636 8.63049 18.6485 8.61036C18.5798 8.52067 18.4998 8.4502 18.4288 8.38752L18.4137 8.37425C18.4 8.36235 18.3822 8.34359 18.3629 8.32391C18.2733 8.23194 18.1502 8.10473 17.9671 8.118C17.7731 8.13081 17.5572 8.27587 17.4643 8.45524C17.3581 8.66023 17.3659 8.90641 17.4858 9.13017C17.6427 9.42347 17.9539 9.62892 18.2975 9.6669L18.4211 9.68063L18.513 9.59644C18.5355 9.57585 18.5657 9.55297 18.5977 9.52871C18.7057 9.44681 18.8402 9.34477 18.8901 9.17546C18.95 8.97093 18.8118 8.8062 18.7006 8.67396V8.67442Z"
    fill="currentColor" />
  <path
    d="M18.2751 11.3778C18.2682 11.3746 18.2595 11.37 18.2527 11.3663C18.1959 11.3375 18.1113 11.2931 18.0005 11.2977C17.8642 11.3027 17.6102 11.3114 17.4707 11.4881C17.338 11.656 17.3206 12.0157 17.4899 12.1808C17.6121 12.3003 17.758 12.3808 17.8871 12.4522C17.9351 12.4787 17.9804 12.5034 18.0225 12.5295L18.1296 12.5949L18.249 12.5565C18.4586 12.4892 18.8095 12.3767 18.8489 11.9795C18.869 11.7782 18.7981 11.6482 18.735 11.5745C18.5926 11.4084 18.3712 11.3842 18.2746 11.3778H18.2751Z"
    fill="currentColor" />
  <path
    d="M15.3365 12.2573C15.1398 12.298 14.9599 12.4288 14.8433 12.6155C14.7307 12.7958 14.6932 13.0045 14.7403 13.1875C14.7787 13.3381 14.8789 13.4442 14.9453 13.5147C14.9567 13.527 14.9677 13.5376 14.9755 13.5476L15.0579 13.657H15.1951C15.5177 13.6556 15.801 13.3737 15.9387 13.0951C16.0558 12.8576 16.0572 12.643 15.9419 12.4911C15.8138 12.3227 15.536 12.2156 15.3361 12.2568L15.3365 12.2573Z"
    fill="currentColor" />
  <path
    d="M15.7429 9.31045C15.6811 9.28254 15.6216 9.2679 15.5736 9.25646C15.5552 9.25188 15.5374 9.24776 15.5196 9.24227C15.056 9.09951 14.8584 9.34157 14.7819 9.49852C14.6433 9.78313 14.744 10.07 15.0505 10.2663C15.148 10.329 15.2491 10.3556 15.3557 10.3839C15.401 10.3958 15.4477 10.4082 15.4981 10.4242L15.6518 10.4732L15.7694 10.3624C15.7868 10.3459 15.8133 10.3254 15.8412 10.3039C15.9369 10.2297 16.0563 10.1373 16.114 9.98584C16.1753 9.82477 16.2087 9.52277 15.7419 9.30999L15.7429 9.31045Z"
    fill="currentColor" />
  <path
    d="M19.9672 9.2711C19.9722 9.49028 20.072 9.69802 20.228 9.81333C20.3141 9.87693 20.4111 9.90942 20.5094 9.90942C20.5511 9.90942 20.5932 9.90347 20.6344 9.89203C20.7886 9.84856 20.8961 9.7424 20.9675 9.67194C20.9794 9.66004 20.9954 9.64402 21.0018 9.63899L21.1089 9.55388L21.1052 9.41661C21.0983 9.16082 20.9963 8.92746 20.832 8.79247C20.7195 8.70004 20.5859 8.66115 20.4559 8.68311C20.147 8.73528 19.9599 8.9604 19.9668 9.27064L19.9672 9.2711Z"
    fill="currentColor" />
</svg>`;

export default { id: '131-bladder-cancer', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
