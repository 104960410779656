import { FrontendLanguage } from '@modelTypes/frontendLanguage';

import { BaseRow, FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { useDispatch } from 'react-redux';
import { ModalWithFormTypes } from '@components/Modal/ModalWithForm/ModalWithForm';
import pick from 'lodash/pick';
import { openModalWithForm, setModalWithFormData } from '@storage/frontendTranslations/actions';
import { message, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons/lib/icons';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import React from 'react';
import { deleteFrontendLanguage } from '@storage/frontendTranslations/thunkActions';
import { dispatchRefreshFrontendTranslationsGridEvent } from '@containers/Settings/FrontendLanguages/components/FrontendLanguagesAntGrid';
import getIdCell from '@components/AntGrid/cells/IdCell';

export type FrontendLanguageColumnsNames = `${keyof FrontendLanguage}Column` | 'hasDescriptionColumn' | 'previewColumn' | 'eyeIconColumn' | 'deleteColumn';

export const frontendLanguageColumnsDefinitions: Partial<Record<FrontendLanguageColumnsNames, ColumnType<FrontendLanguage>>> = {
  idColumn: createColumnDefinition<FrontendLanguage>('#', 'id', { width: 80, render: getIdCell }),
  keyColumn: createColumnDefinition<FrontendLanguage>('Key', 'key'),
  messageEnColumn: createColumnDefinition<FrontendLanguage>('English', 'messageEn'),
  messageRuColumn: createColumnDefinition<FrontendLanguage>('Russian', 'messageRu'),
  linkColumn: createColumnDefinition<FrontendLanguage>('Link', 'link'),
  // isActiveColumn: createColumnDefinition<FrontendLanguage>('Active', 'isActive', { render: getIsActiveCell, width: 80, filtered: false }),
  // updatedAtColumn: createColumnDefinition<FrontendLanguage>('Updated At', 'updatedAt', { render: getDateFormattedCell, width: 160 }),
  // createdAtColumn: createColumnDefinition<FrontendLanguage>('Created At', 'createdAt', { render: getDateFormattedCell, width: 160 }),
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, record: FrontendLanguage) => {
      return (
        <EditCell record={record} />
      );
    },
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell<FrontendLanguage>(),
    sorter: false,
    filtered: false,
  }
};

export const frontendLanguageColumns: any = [
  frontendLanguageColumnsDefinitions.idColumn,
  frontendLanguageColumnsDefinitions.keyColumn,
  frontendLanguageColumnsDefinitions.messageEnColumn,
  frontendLanguageColumnsDefinitions.messageRuColumn,
  frontendLanguageColumnsDefinitions.linkColumn,
  // frontendLanguageColumnsDefinitions.updatedAtColumn,
  // frontendLanguageColumnsDefinitions.createdAtColumn,
  // frontendLanguageColumnsDefinitions.isActiveColumn,
  frontendLanguageColumnsDefinitions.eyeIconColumn,
  frontendLanguageColumnsDefinitions.deleteColumn,
];

export const frontendLanguageFiltersConfig: FiltersConfig = {
  [frontendLanguageColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [frontendLanguageColumnsDefinitions.keyColumn?.key as string]: { filterType: FilterType.SEARCH },
  [frontendLanguageColumnsDefinitions.messageEnColumn?.key as string]: { filterType: FilterType.SEARCH },
  [frontendLanguageColumnsDefinitions.messageRuColumn?.key as string]: { filterType: FilterType.SEARCH },
  [frontendLanguageColumnsDefinitions.linkColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [frontendLanguageColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [frontendLanguageColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};


function EditCell({ record }: { record: FrontendLanguage }) {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    const formData = pick(record, ['key', 'messageEn', 'messageRu', 'link']);

    dispatch(setModalWithFormData({ type: ModalWithFormTypes.EDIT, formData, editRecord: record }));
    dispatch(openModalWithForm());
  };

  return (
    <Tooltip title="Edit item">
      <EditOutlined onClick={handleOnClick} style={{ fontSize: 18, color: '#000', cursor: 'pointer' }} />
    </Tooltip>
  );
}

function getDeleteCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <DeleteCell
        record={record}
        title="Delete Frontend Language"
        deleteThunkAction={deleteFrontendLanguage}
        onSuccess={() => {
          dispatchRefreshFrontendTranslationsGridEvent();
          message.success('Frontend Language key has been successfully deleted');
        }}
        hasNotRelationsContent={() => <div>Frontend Language has no any relations.</div>}
        hasRelationsContent={() => {
          return (
            <div style={{}}>
              <div>If you really want to continue, enter your password.</div>

            </div>
          );
        }}
      />
    );
  };
}
