import { useRef, useState } from 'react';

import { AppState } from '@storage/app/reducer';
import ArticleIcon from '@mui/icons-material/Article';
import cn from 'classnames';
import { getHistorySearchParam } from '@helpers/history';
import { kebabCase } from 'lodash';
import styles from './ButtonsPlugin.module.scss';
import { useHistory } from 'react-router-dom';
import useOnClickOutside from '@hooks/useOnClickOutside';
import { useSelector } from '@hooks';
import { valueOnly } from '@helpers/className';

interface Props {
  editor: any;
}

const simpleButtons: Record<string, Array<string>> = {
  en: [
    'Contact us', 'Send request', 'Get consultation', 'Select a hospital', 'Select a doctor', 'Find medical solution',
    'Let us help', 'Request a second opinion', 'Get personal cost', 'Tell us about your case', 'Find a specialist',
    'Find care', 'Select a treatment program', 'Select the proper clinic', 'Request a call',
    'Request an expert consultation', 'Speak with a consultant', 'Receive a treatment program', 'Choose a doctor', 'Choose a clinic',
    'Check out our service',
    'Try our service',
    'Get help from a trusted doctor',
    'Get help from a world-class doctor',
    'Get in touch with a consultant',
    'Get treatment',
    'Get your treatment plan',
    'Get a call',
    'Get assistance from our expert',
    'Book treatment',
    'Book an appointment',
    'Book treatment program',
    'Book a call',
    'Tell us how we can help',
    'Let us help you feel better',
    'Ask our expert',
    'Ask the oncologist',
    'Schedule an appointment',
  ],
  ru: [
    'Связаться с нами', 'Оставить обращение', 'Получить консультацию', 'Подобрать клинику', 'Подобрать доктора', 'Найти медицинское решение'
  ],
};

const usualSearchButton: Record<string, string> = {
  en: 'Insert Usual Search',
  ru: 'Вставить обычный поиск',
};

const textWithContactButton: Record<string, string> = {
  en: 'Insert text with contact button',
  ru: 'Вставить текст с `Contact us` кнопкой',
};

export default function ButtonsPlugin(
  {
    editor
  }: Props
) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const ref = useRef<any>();
  const activeTab = parseInt(getHistorySearchParam(history, 'language-tab', 0), 10);
  const languages = useSelector<AppState['languages']>('app.languages');
  const language = languages[activeTab];

  useOnClickOutside(ref, () => setOpen(false));

  if (!languages.length) {
    return null;
  }

  const buttons = simpleButtons[language.slug as string] ? simpleButtons[language.slug as string] : simpleButtons.en;
  const usualSearch = usualSearchButton[language.slug as string] ? usualSearchButton[language.slug as string] : usualSearchButton.en;
  const textWithContact = textWithContactButton[language.slug as string] ? textWithContactButton[language.slug as string] : textWithContactButton.en;

  const onSelectSimpleButton = (e: any, buttonName: string, variant: string) => {
    e.preventDefault();
    e.stopPropagation();

    editor.append(`{{ btn-${kebabCase(buttonName)}__language=${language.slug}&${variant} }}`);
    setOpen(false);
  };

  const onSelectButton = (e: any, buttonName: string) => {
    e.preventDefault();
    e.stopPropagation();

    editor.append(`{{ ${kebabCase(buttonName)}__language=${language.slug} }}`);
    setOpen(false);
  };

  const onOpenClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setOpen(!open);
  };

  return (
    <div className={cn(styles.buttonsPlugin, valueOnly(styles, open, 'open'))} ref={ref}>
      <div
        className={styles.trigger}
        onMouseDown={onOpenClick}
      >
        <ArticleIcon />
      </div>
      {open && (
        <div className={styles.list}>
          <div className={styles.title}>Buttons:</div>
          <div className={styles.buttons}>
            {buttons.map((button, key) => {
              console.log(`4,component.editor.simple-btn.${kebabCase(simpleButtons.en[key])},${button}`);
              return (
                <button
                  key={key}
                  className={styles.button2}
                  onMouseDown={(e) => onSelectSimpleButton(e, simpleButtons.en[key], 'variant=2')}
                >
                  {button}
                  {/*{button.substring(0, 7)}...*/}
                </button>
              );
            })}
          </div>
          <div
            className={styles.usualSearch}
            onMouseDown={(e) => onSelectButton(e,'usualSearch')}
          >
            {usualSearch}
          </div>
          <div
            className={styles.textWithContactButton}
            onMouseDown={(e) => onSelectButton(e, 'textWithContactButton')}
          >
            {textWithContact}
          </div>
        </div>
      )}
    </div>
  );
}
