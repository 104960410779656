import {
  AntGetListParams,
  CreateTemplateRequest,
  CreateTemplateResponse,
  GetListParams,
  ListResponse,
  Params,
  UpdateTemplateRequest,
  UpdateTemplateResponse,
} from '@api/types';
import { Template, TemplateTypes } from '@modelTypes/template';

import { AxiosRequestConfig } from 'axios';
import client from "./client";
import { getRestUri } from '@api/helpers';

const getUri = getRestUri('templates');
const ROOT_URI = getUri('');
// const GET_BLOG_FOR_SELECT_URI = 'blog/select';
const GET_BLOGS_URI = (type: TemplateTypes) => `templates/${type}`;
// const GET_ACCESS_KEY_URI = (id: number) => `templates/${id}/access-key`;

export async function getTemplates(type: TemplateTypes, params?: AntGetListParams) {
  return client.get<ListResponse<Template>>(GET_BLOGS_URI(type), params);
}

// export async function getArticlesForSelect(params?: GetListParams) {
//   return client.get<ListResponse<Blog>>(GET_BLOG_FOR_SELECT_URI, params);
// }

export async function getTemplate(id: number, params?: Params) {
  return client.get<Template>(ROOT_URI.SHOW({ id }), params);
}

// export async function getAccessKey(id: number) {
//   return client.get<BlogAccessKey>(GET_ACCESS_KEY_URI(id));
// }

export async function createTemplate(payload: CreateTemplateRequest) {
  return client.post<CreateTemplateRequest, CreateTemplateResponse>(ROOT_URI.POST(), payload);
}

export async function updateTemplate(id: number, payload: UpdateTemplateRequest) {
  return client.patch<UpdateTemplateRequest, UpdateTemplateResponse>(ROOT_URI.PATCH({ id }), payload);
}

export async function deleteTemplate(id: number, payload?: AxiosRequestConfig) {
  return client.del(ROOT_URI.DELETE({ id }), payload);
}
