import React from 'react';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const TemplateSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    x="0"
    y="0"
    enableBackground="new 0 0 958.752 958.752"
    version="1.1"
    viewBox="0 0 958.752 958.752"
    xmlSpace="preserve"
  >
    <path d="M449.253 958.752v-126.18a125.442 125.442 0 01-52.273 11.438c-17.053 0-33.599-3.375-49.179-10.03-15.033-6.423-28.497-15.601-40.015-27.279-23.725-24.055-36.533-55.922-36.065-89.728.453-32.706 13.529-63.528 36.821-86.788 23.291-23.261 54.13-36.296 86.842-36.707a125.474 125.474 0 0153.871 11.427l.122-125.12H299.506c3.631-6.34 8.994-14.236 15.154-23.226 11.912-17.385 20.783-37.496 20.497-60.273-.646-51.367-42.547-93.327-93.914-94.038a101.37 101.37 0 00-1.35-.009c-52.62-.004-95.271 42.652-95.271 95.271 0 22.269 8.737 41.944 20.445 58.973 5.693 8.28 11.58 16.957 15.213 23.302H40.411c-22.091 0-40 17.909-40 40V918.75c0 22.092 17.909 40 40 40l408.842.002z"/>
    <path d="M832.692 508.965a125.458 125.458 0 0111.438 52.273c0 33.463-13.029 64.922-36.688 88.582-23.66 23.66-55.119 36.688-88.582 36.688-.583 0-1.177-.004-1.767-.012-32.707-.453-63.527-13.529-86.789-36.82-23.26-23.291-36.296-54.131-36.707-86.838a125.458 125.458 0 0111.423-53.875H479.235l.018 150.16c-5.298-3.033-15.108-9.637-24.136-15.863-16.723-11.537-36.252-19.793-58.14-19.793-.408 0-.813.002-1.224.008-51.367.646-93.326 42.548-94.038 93.914-.244 17.599 4.289 34.119 12.378 48.354 16.382 28.824 47.36 48.268 82.884 48.268 21.888 0 40.968-8.93 58.136-19.793 10.578-6.692 18.84-12.832 24.138-15.865v179.869H918.216c22.092 0 40-17.909 40-40V508.965H832.692z"/>
    <path d="M918.34 0H479.375v183.766s16.088-13.928 21.23-17.577c16.507-13.723 37.721-21.979 60.863-21.979 53.066 0 95.999 43.386 95.264 96.622-.711 51.366-42.671 93.268-94.038 93.913-.408.005-.818.008-1.227.008-23.145 0-44.356-8.255-60.863-21.979-5.145-3.648-21.229-17.576-21.229-17.576v183.768h179.87c-1.389 2.841-9.421 15.021-15.986 24.294-11.921 16.836-19.943 36.914-19.665 59.205.646 51.365 42.548 93.324 93.914 94.037.448.006.902.01 1.352.01 24.636 0 47.087-9.352 63.999-24.694 19.21-17.431 31.271-42.595 31.271-70.578 0-21.832-8.228-41.308-19.7-58.017-7.307-10.643-12.829-18.817-15.786-23.966l-.171-.292H958.341V40c-.001-22.091-17.909-40-40.001-40z"/>
  </svg>
);

export default function TemplateIcon(props: Partial<CustomIconComponentProps>) {
  return (
    <Icon
      component={TemplateSvg}
      {...props}
    />
  );
}
