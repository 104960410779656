import { Button, Card, Checkbox, Col, Row, Space, Typography, } from 'antd';
import { ContactMethods, Order, Status } from '../../../../modelTypes/order';
import * as React from 'react';
import SelectStatuses from '@containers/Orders/Ant/components/SelectStatuses';
import moment from 'moment/moment';
import styles from './OrderCard.module.scss'

import { CheckSquareTwoTone, MailOutlined, PhoneOutlined, WhatsAppOutlined } from '@ant-design/icons';
import TelegramIcon from '@components/icons/contactMethods/TelegramIcon';
import CustomSelect from '@base/CustomSelect';
import { useSelect } from '@zustandStorage/customSelect/customSelect';


interface OrderCardProps {
  item: Order;
  statusesForSelect: Array<Status>;
  onChange: (id: number, option: any) => void;
  onChangeId: (id: number) => void;
  showDetails: (id: number) => string;
}

const { Text } = Typography;

export default function OrderCard({ item, statusesForSelect, onChange, onChangeId, showDetails }: OrderCardProps) {

  const { fetchUserForSelect } = useSelect();

  return (
    <Card>
      <Row gutter={[48, 32]}>
        <Col span={3}>
          <Space direction={'vertical'}>
            <span>ID {item.id}</span>
            <Checkbox onChange={() => onChangeId(item.id)} />
          </Space>
        </Col>
        <Col span={6}>
          <Space direction={'vertical'} style={{ width: '100%' }}>
            <Space size={24}>
              <Text>Data: {moment(item.createdAt).format('DD.MM.YYYY')}</Text>
              {item.contactMethods.indexOf(ContactMethods.PHONE) > -1 && <PhoneOutlined style={{ fontSize: 16 }} />}
              {item.contactMethods.indexOf(ContactMethods.WHATSAPP) > -1 && <WhatsAppOutlined style={{ fontSize: 16 }} />}
              {item.contactMethods.indexOf(ContactMethods.TELEGRAM) > -1 && <TelegramIcon style={{ fontSize: 16 }} />}
              {item.contactMethods.indexOf(ContactMethods.MAIL) > -1 && <MailOutlined style={{ fontSize: 16 }} />}
            </Space>
            <Space size={0} direction={'vertical'} style={{ width: '100%' }}>
              <Text style={{ fontSize: '12px' }}>Status</Text>
              <SelectStatuses
                options={statusesForSelect}
                onChange={(option) => onChange(item.id, { status: option && option.id ? option.id : null })}
                style={{ width: '100%' }}
                value={{ value: item.status }}
              />
            </Space>
          </Space>
        </Col>
        <Col span={9}>
          <Space direction={'vertical'} style={{ width: '100%' }}>
            <Text style={{ display: 'flex', gap: '6px' }}>URL: {item.url &&
              <a href={item.url} target="_blank" rel="noreferrer" className={styles.link}>{item.url}</a>}
            </Text>
            <div className={styles.descriptionContainer}>
              <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description }} />
            </div>
          </Space>
        </Col>
        <Col span={6} style={{ width: '100%' }}>
          <Space direction={'vertical'} style={{ width: '100%' }}>
            <Space style={{ width: '100%' }} size={'large'}>
              <Space>
                <CheckSquareTwoTone
                  style={{ fontSize: '18px', alignItems: 'center' }}
                  twoToneColor={!item?.phone?.length ? 'gray' : '#1677ff'}
                />
                Phone
              </Space>
              <Space>
                <CheckSquareTwoTone
                  style={{ fontSize: '18px', alignItems: 'center' }}
                  twoToneColor={!item?.email?.length ? 'gray' : '#1677ff'}
                />
                Email
              </Space>
              <Space>
                <CheckSquareTwoTone
                  style={{ fontSize: '18px', alignItems: 'center' }}
                  twoToneColor={!item?.files?.length ? 'gray' : '#1677ff'}
                />
                Files
              </Space>
            </Space>
            <Space size={0} direction={'vertical'} style={{ width: '100%' }}>
              <Text style={{ fontSize: '12px' }}>Sale manager</Text>
              <CustomSelect
                placeholder="Select manager"
                selectId="selectUser"
                onUpdateSelect={fetchUserForSelect}
                defaultQueryParams={{ page: 1, perPage: 50 }}
                onChange={(option) => onChange(item.id, { saleManagerId: option && option.id ? option.id : null })}
                style={{ width: '100%' }}
                value={{ label: item.saleManager?.name, value: item.saleManagerId }}
              />
            </Space>
            <Space size={0} direction={'vertical'} style={{ width: '100%' }}>
              <Text style={{ fontSize: '12px' }}>Doctor</Text>
              <CustomSelect
                placeholder="Select doctor"
                selectId="selectDoctor"
                onUpdateSelect={fetchUserForSelect}
                defaultQueryParams={{ page: 1, perPage: 50 }}
                onChange={(option) => onChange(item.id, { doctorId: option && option.id ? option.id : null })}
                style={{ width: '100%' }}
                value={{ label: item.doctor?.name, value: item.doctorId }}
              />
            </Space>
            <Space.Compact block style={{ justifyContent: 'flex-end' }}>
              <Button
                key="1"
                type="primary"
                href={showDetails(item.id)}
              >
                View details
              </Button>
            </Space.Compact>
          </Space>
        </Col>
      </Row>
    </Card>
  )
}