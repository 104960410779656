import { create } from 'zustand';
import produce from 'immer';
import { initialFormState, initialGridState } from '@zustandStorage/helpers/initialStates';
import {
  createMenuItem,
  createMenuSubItem,
  getMenuContents,
  getMenuItem,
  getMenuSubItems,
  updateMenuItem,
  updateMenuSubItemLink
} from '@api';
import { AntGetListParams, CreateMenuItemRequest, ListResponse, Params, UpdateMenuItemRequest } from '@api/types';
import { Content, MenuItem } from '@modelTypes/content';

interface Editable {
  status: boolean,
  rowId: number | null;
  form: any,
}

export interface MenuState {
  menuGrid: ListResponse<Content> & { loading: boolean; };
  subMenuGrid: ListResponse<Content> & {
    loading: boolean;
    editable: Editable
  };
  form: {
    loading: boolean;
    row: MenuItem | null;
  };
  subMenuForm: {
    loading: boolean;
    row: any;
  };
  clearFormData: () => void;
  clearSubMenuFormData: () => void;
  fetchMenuItem: (id: number, params?: Params) => Promise<MenuItem>;
  createMenu: (payload: CreateMenuItemRequest) => Promise<MenuItem>;
  updateMenuItem: (id: number, payload: UpdateMenuItemRequest) => Promise<MenuItem>;
  // Menu.
  fetchMenuItems: (params: AntGetListParams, payload: any) => Promise<any>;
  clearMenuGridData: () => void;

  // Sub items.
  setSubMenuGridEditable: (data: Editable) => void;
  fetchMenuSubItems: (id: number, params?: AntGetListParams) => Promise<any>;
  createMenuSubItem: (id: number, payload: any) => Promise<any>;
  updateMenuSubItemLink: (id: number, payload: any) => Promise<any>;
  clearSubMenuGridData: () => void;
}

const editableInitialData = {
  status: false,
  rowId: null,
  form: {},
};

export const useMenuStore = create<MenuState>((set) => ({
  menuGrid: initialGridState,
  subMenuGrid: {
    ...initialGridState,
    editable: editableInitialData,
  },
  setSubMenuGridEditable: (data) => {
    set(produce((state) => {
      state.subMenuGrid.editable = data;
    }))
  },
  form: initialFormState,
  subMenuForm: initialFormState,
  clearFormData: () => set(() => ({ form: initialFormState })),
  clearMenuGridData: () => set(() => ({ menuGrid: initialGridState })),
  clearSubMenuGridData: () => set(() => ({ subMenuGrid: { ...initialGridState, editable: editableInitialData } })),
  clearSubMenuFormData: () => set(() => ({ subMenuForm: initialFormState })),
  fetchMenuItem: async (id: number, params?:Params): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return getMenuItem(id, params)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  createMenu: async (payload: any): Promise<any> => {
    set(produce((state:any) => {
      state.form.loading = true;
    }));

    return createMenuItem(payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  fetchMenuItems: async (params, payload) => {
    set(produce((state) => {
      state.menuGrid.loading = true;
    }));

    return getMenuContents(params, payload)
      .then((response) => {
        set(produce((state) => {
          state.menuGrid.data = response.data;
          state.menuGrid.meta = response.meta;
          state.menuGrid.loading = false;
        }));

        return response;
      });
  },

  // Sub items.
  fetchMenuSubItems: async (id: number, params?: AntGetListParams): Promise<any> => {
    set(produce((state) => {
      state.subMenuGrid.loading = true;
    }));

    return getMenuSubItems(id, params)
      .then((response) => {
        set(produce((state) => {
          state.subMenuGrid.data = response.data;
          state.subMenuGrid.meta = response.meta;
          state.subMenuGrid.loading = false;
        }));

        return response;
      });
  },
  createMenuSubItem: async (id, payload) => {
    set(produce((state) => {
      state.subMenuForm.loading = true;
    }));

    return createMenuSubItem(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.subMenuForm.row = response;
          state.subMenuForm.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.subMenuForm.loading = false;
        }));

        throw e;
      })
  },
  updateMenuSubItemLink: async (id, payload) => {
    set(produce((state) => {
      state.subMenuGrid.loading = true;
    }));

    return updateMenuSubItemLink(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.subMenuGrid.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.subMenuGrid.loading = false;
        }));

        throw e;
      });
  },
  updateMenuItem: async (id: number, payload: any): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateMenuItem(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
}));