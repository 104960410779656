import { SaveFilled, SyncOutlined } from '@ant-design/icons';
import { getConditionTags, putConditionTags } from '@api/conditionTagsApi';
import compareObjects from '@helpers/compareObjects';
import cn from 'classnames';
import { omit } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, {
  Background,
  BackgroundVariant,
  Connection,
  ConnectionLineType,
  ConnectionMode,
  ControlButton,
  Controls,
  Edge,
  Node,
  OnSelectionChangeParams,
  SelectionMode,
  XYPosition,
  addEdge,
  useEdgesState,
  useNodesState,
  useStoreApi,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { v4 as uuid } from 'uuid';
import ClipboardManager from '../components/ClipboardManager';
import CustomEdge, { CustomEdgeProps, EdgeType } from '../components/CustomEdge/CustomEdge';
import CustomNode, { CustomNodeProps } from '../components/CustomNode/CustomNode';
import RevisionManager from '../components/RevisionManager';
import styles from './StaticTagCloud.module.scss';
import { Spin } from 'antd';
import FindNodeModal from '../components/FindNodeModal';

const nodeTypes = {
  custom: CustomNode,
};

const edgeTypes = {
  custom: CustomEdge,
};

const omitProperties = ['selected', 'data.highlightLevel', 'dragging', 'height', 'width', 'data.position'];

const connectionTypeMap = [ConnectionLineType.Straight, ConnectionLineType.Step, ConnectionLineType.Bezier];

export default function StaticTagCloud() {
  const fetchedNodes = useRef<Node<CustomNodeProps>[]>([]);
  const fetchedEdges = useRef<Edge<CustomEdgeProps>[]>([]);
  const [nodes, setNodes, onNodesChange] = useNodesState<CustomNodeProps>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<CustomEdgeProps>([]);
  const store = useStoreApi();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [highlightedNodes, setHighlightedNodes] = useState<string[]>([]);
  const [highlightedEdges, setHighlightedEdges] = useState<string[]>([]);
  const [edgeType, setEdgeType] = useState<EdgeType>(EdgeType.straight);
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [revisionKey, setRevisionKey] = useState(0);
  const [canvasMousePos, setCanvasMousePos] = useState<XYPosition>({ x: 0, y: 0 });
  const [isLoading, setLoading] = useState(true);

  const onConnect = useCallback(
    (params: Connection) => {
      if (params.source === params.target) {
        return;
      }

      if (edges.some((i) => i.source === params.source && i.target === params.target)) {
        return;
      }

      if (edges.some((i) => i.source === params.target && i.target === params.source)) {
        return;
      }

      setEdges((prev) =>
        addEdge(
          {
            ...params,
            type: 'custom',
            data: { type: edgeType },
          },
          prev
        )
      );
    },
    [edgeType, edges, setEdges]
  );

  useEffect(() => {
    if (!isSaving && !unsavedChanges) {
      return;
    }

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isSaving, unsavedChanges]);

  const fetchGraph = async () => {
    setLoading(true);

    try {
      const data = await getConditionTags();

      fetchedNodes.current = data.nodes;
      fetchedEdges.current = data.edges;
      setNodes(data.nodes);
      setEdges(data.edges);
      setUnsavedChanges(false);
      setInitiallyLoaded(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChangedNodesAndEdges = () => {
    const diffNodes = nodes.filter((i) => {
      const oldNode = fetchedNodes.current.find((j) => j.id === i.id);

      if (!oldNode) {
        return true;
      }

      return !compareObjects(omit(i, [...omitProperties, 'data.id']), omit(oldNode, [...omitProperties, 'data.id']));
    });

    fetchedNodes.current.forEach((i) => {
      if (nodes.some((j) => j.id === i.id)) {
        return;
      }

      diffNodes.push({ id: i.id, deleted: true } as any);
    });

    const diffEdges = edges.filter((i) => {
      const oldEdge = fetchedEdges.current.find((j) => j.id === i.id);

      if (!oldEdge) {
        return true;
      }

      return !compareObjects(omit(i, [...omitProperties, 'data.id']), omit(oldEdge, [...omitProperties, 'data.id']));
    });

    fetchedEdges.current.forEach((i) => {
      if (edges.some((j) => j.id === i.id)) {
        return;
      }

      if (i.data?.id === undefined) {
        return;
      }

      diffEdges.push({ data: { id: (i.data as CustomEdgeProps).id }, deleted: true } as any);
    });

    return { nodes: diffNodes, edges: diffEdges };
  };

  const saveGraph = async () => {
    const { nodes: diffNodes, edges: diffEdges } = getChangedNodesAndEdges();

    if (!diffNodes.length && !diffEdges.length) {
      return;
    }

    const omittedNodes = diffNodes.map((i) => omit(i, omitProperties));
    const omittedEdges = diffEdges.map((i) => omit(i, omitProperties));

    setIsSaving(true);

    try {
      await putConditionTags({ nodes: omittedNodes, edges: omittedEdges });
      await fetchGraph();
    } catch (err: any) {
      alert(`Failed to save: ${err.message}`);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const { nodes: diffNodes, edges: diffEdges } = getChangedNodesAndEdges();

    setUnsavedChanges(!!diffNodes.length || !!diffEdges.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes, edges]);

  useEffect(() => {
    setNodes((prev) =>
      prev.map((i) => ({
        ...i,
        data: { ...i.data, highlightLevel: highlightedNodes?.includes(i.id) ? 1 : undefined },
      }))
    );
    setEdges((prev) =>
      prev.map((i) => ({
        ...i,
        data: {
          ...i.data,
          highlightLevel: highlightedEdges?.includes(i.id) ? 1 : undefined,
          type: i.data?.type ?? EdgeType.step,
        },
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(highlightedEdges), JSON.stringify(highlightedNodes)]);

  const createNewNode = () => {
    const newNodeId = uuid();

    const {
      height,
      width,
      transform: [transformX, transformY, zoomLevel],
    } = store.getState();
    const zoomMultiplier = 1 / zoomLevel;
    const centerX = -transformX * zoomMultiplier + (width * zoomMultiplier) / 2;
    const centerY = -transformY * zoomMultiplier + (height * zoomMultiplier) / 2;
    setNodes((prev) => [
      ...prev,
      {
        id: newNodeId,
        position: { x: centerX, y: centerY },
        data: { name: 'Double click to edit' },
        type: 'custom',
      },
    ]);
  };

  const handleSelectionChange = useCallback(
    (event: OnSelectionChangeParams) => {
      const newHighlightedNodes: Set<string> = new Set();
      const newHighlightedEdges: Set<string> = new Set();

      event.nodes.forEach((i) => {
        newHighlightedNodes.add(i.id);

        const relatedEdges = edges.filter((j) => j.source === i.id || j.target === i.id);
        relatedEdges.forEach((j) => newHighlightedEdges.add(j.id));
      });

      event.edges.forEach((i) => {
        newHighlightedEdges.add(i.id);
        newHighlightedNodes.add(i.source);
        newHighlightedNodes.add(i.target);
      });

      setHighlightedNodes(Array.from(newHighlightedNodes));
      setHighlightedEdges(Array.from(newHighlightedEdges));
    },
    [edges]
  );

  const highlightLevels = (levels: 2 | 3) => {
    let newNodes = JSON.parse(JSON.stringify(nodes)) as Node<CustomNodeProps>[];
    newNodes = newNodes.map((i) => ({ ...i, data: { ...i.data, highlightLevel: undefined } }));

    const selectedNode = newNodes.find((i) => i.selected);

    if (!selectedNode) {
      return;
    }

    selectedNode.data.highlightLevel = 1;

    let newEdges = JSON.parse(JSON.stringify(edges)) as Edge<CustomEdgeProps>[];
    newEdges = newEdges.map((i) => ({
      ...i,
      data: { ...i.data, highlightLevel: undefined },
    })) as Edge<CustomEdgeProps>[];

    newEdges = newEdges.map((i) => ({
      ...i,
      data: {
        ...i.data,
        highlightLevel: [i.source, i.target].includes(selectedNode.id) ? 1 : undefined,
      },
    })) as Edge<CustomEdgeProps>[];

    const highlightNodesLevel = (level: 2 | 3) => {
      newNodes = newNodes.map((i) => ({
        ...i,
        data: {
          ...i.data,
          highlightLevel:
            i.data.highlightLevel ||
            (newEdges.some((j) => j.data?.highlightLevel === level - 1 && [j.source, j.target].includes(i.id))
              ? level
              : undefined),
        },
      }));
    };

    const highlightEdgesLevel = (level: 2 | 3) => {
      newEdges = newEdges.map((i) => ({
        ...i,
        data: {
          ...i.data,
          highlightLevel:
            i.data?.highlightLevel ||
            (newNodes.some((j) => j.data.highlightLevel === level && [i.source, i.target].includes(j.id))
              ? level
              : undefined),
        },
      })) as Edge<CustomEdgeProps>[];
    };

    highlightNodesLevel(2);
    highlightEdgesLevel(2);

    if (levels === 2) {
      setNodes(newNodes);
      setEdges(newEdges);
      return;
    }

    highlightNodesLevel(3);
    highlightEdgesLevel(3);

    setNodes(newNodes);
    setEdges(newEdges);
  };

  const handleNodeDragStop = (_: React.MouseEvent, node: Node) => {
    const newNodes = JSON.parse(JSON.stringify(nodes)) as Node<CustomNodeProps>[];
    const foundNode = newNodes.find((i) => i.id === node.id);

    if (!foundNode) {
      return;
    }

    foundNode.data.position = node.position;
    delete foundNode.dragging;

    setNodes(newNodes);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            deleteKeyCode={['Delete', 'Backspace']}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            connectionMode={ConnectionMode.Loose}
            elevateNodesOnSelect={false}
            elevateEdgesOnSelect={false}
            onSelectionChange={handleSelectionChange}
            onlyRenderVisibleElements
            panOnScroll
            panOnDrag={[1]}
            panOnScrollSpeed={2}
            selectionOnDrag
            selectionMode={SelectionMode.Partial}
            fitView
            connectionLineType={connectionTypeMap[edgeType]}
            connectOnClick={false}
            selectionKeyCode={null}
            onNodeDragStop={handleNodeDragStop}
            onMouseMove={(e) =>
              setCanvasMousePos({
                x: e.clientX - e.currentTarget.getBoundingClientRect().left,
                y: e.clientY - e.currentTarget.getBoundingClientRect().top,
              })
            }
            minZoom={0.1}
          >
            <Controls>
              <ControlButton onClick={createNewNode}>➕</ControlButton>
              <ControlButton
                className={cn(styles.edgeTypeBtn, edgeType === EdgeType.straight && styles.active)}
                onClick={() => setEdgeType(EdgeType.straight)}
              >
                /
              </ControlButton>
              <ControlButton
                className={cn(styles.edgeTypeBtn, edgeType === EdgeType.bezier && styles.active)}
                onClick={() => setEdgeType(EdgeType.bezier)}
              >
                ⌒
              </ControlButton>
              <ControlButton
                className={cn(styles.edgeTypeBtn, edgeType === EdgeType.step && styles.active)}
                onClick={() => setEdgeType(EdgeType.step)}
              >
                ↯
              </ControlButton>
              <ControlButton
                onClick={() => highlightLevels(2)}
                disabled={!nodes.some((i) => i.selected)}
                className={styles.highlightLevelsBtn}
              >
                x2
              </ControlButton>
              <ControlButton
                onClick={() => highlightLevels(3)}
                disabled={!nodes.some((i) => i.selected)}
                className={styles.highlightLevelsBtn}
              >
                x3
              </ControlButton>
            </Controls>
            <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
          </ReactFlow>
        </div>
        {unsavedChanges && !isSaving && (
          <button onClick={() => saveGraph()} className={styles.savingIndicator}>
            <SaveFilled />
          </button>
        )}
        {isSaving && (
          <div className={styles.savingIndicator}>
            <SyncOutlined spin />
          </div>
        )}
        {(isLoading || isSaving) && <Spin className={styles.loadingIndicator} size="large" />}
      </div>
      <FindNodeModal />
      <ClipboardManager canvasMousePos={canvasMousePos} />
      {initiallyLoaded && (
        <RevisionManager
          key={Number(isSaving)}
          revisionKey={revisionKey}
          onRevisionKeyUpdate={() => setRevisionKey((prev) => prev + 1)}
        />
      )}
    </>
  );
}
