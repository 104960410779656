import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks';
import { PaginationConfig } from '@components/AntGrid/types';
import { getCities } from '@storage/cities/thunkActions';
import { City } from '@modelTypes/city';
import { cityColumns, cityFiltersConfig } from '@components/AntGrid/columns/city';
import { CitiesState } from '@storage/cities/reducer';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeLanguage from '@components/AntGrid/hooks/useChangeLanguage';
import useChangeGridOptions from "@components/AntGrid/hooks/useChangeGridOptions";

export const [
  refreshCitiesGridEvent,
  dispatchRefreshCitiesGridEvent
] = makeRefreshGridEvent('onRefreshCitiesGrid');

export default function CitiesAntGrid() {
  const dispatch = useDispatch();
  const [languageId, setFirstLoad] = useChangeLanguage((languageId) => dispatch(getCities({ languageId })));
  const { data, meta, loading } = useSelector<CitiesState['grid']>('cities.grid');

  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
    // ...rest.paginationConfig,
  };

  const onFetchData = (params?: AntGetListParams) => {
    dispatch(getCities({ ...params, languageId }) as unknown as Promise<void>)
      .then(() => {
        setFirstLoad(true);
      });
  };

  useRefreshGridEvent(refreshCitiesGridEvent, onFetchData);
  useChangeGridOptions('citiesAntGrid');

  return (
    <Grid<City>
      id="doctorsGrid"
      onFetch={onFetchData}
      columns={cityColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={cityFiltersConfig}
    />
  );
}