import React from 'react';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const TelegramSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 51 51"
    fill="currentColor"
  >
    <path d="M25.5 0C11.4166 0 0 11.4166 0 25.5C0 39.5834 11.4166 51 25.5 51C39.5834 51 51 39.5834 51 25.5C51 11.4166 39.5834 0 25.5 0Z" fill="#40B3E0"/>
    <path d="M37.9078 14.6686L33.3533 37.6319C33.3533 37.6319 32.7164 39.2245 30.9647 38.4601L20.4543 30.4021L16.6325 28.5549L10.1989 26.389C10.1989 26.389 9.21158 26.0388 9.11596 25.2744C9.02053 24.51 10.2308 24.096 10.2308 24.096L35.8057 14.0634C35.8057 14.0634 37.9078 13.1398 37.9078 14.6686Z" fill="white"/>
    <path d="M19.6465 37.374C19.6465 37.374 19.3398 37.3454 18.9575 36.1349C18.5753 34.9246 16.6326 28.5548 16.6326 28.5548L32.0794 18.7453C32.0794 18.7453 32.9713 18.2038 32.9394 18.7453C32.9394 18.7453 33.0986 18.8407 32.6209 19.2866C32.1431 19.7326 20.4863 30.2109 20.4863 30.2109" fill="#D2E5F1"/>
    <path d="M24.4843 33.4917L20.327 37.282C20.327 37.282 20.0021 37.5287 19.6465 37.3741L20.4426 30.3335" fill="#B5CFE4"/>
  </svg>
);

export default function TelegramIcon(props: Partial<CustomIconComponentProps>) {
  return (
    <Icon
      component={TelegramSvg}
      {...props}
    />
  );
}
