import { ActionType, Actions } from './actions';

import { ListResponse } from '@api/types';
import { Permission } from '@modelTypes/permission';
import { Role } from '@modelTypes/role';

export interface RolesState {
  readonly grid: ListResponse<Role> & { loading: boolean }
  readonly form: {
    readonly loading: boolean;
    readonly row: Role | null;
    readonly permissions: Array<Permission>;
  };
  readonly selectRole: {
    readonly loading: boolean;
    readonly search: string | null;
    readonly data: Array<Role>;
  };
}

const initialState: RolesState = {
  grid: {
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  form: {
    loading: false,
    row: null,
    permissions: [],
  },
  selectRole: {
    loading: false,
    search: null,
    data: [],
  }
};

export default function doctors(state = initialState, action: Actions): RolesState {
  switch (action.type) {
    case ActionType.START_FETCH_ROLES:
    // case ActionType.START_DELETE_AUTHOR:
      return {
        ...state,
        grid: {
          ...state.grid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_ROLES:
      return {
        ...state,
        grid: {
          ...action.data,
          loading: false,
        },
      }
    case ActionType.START_FETCH_ROLES_FOR_SELECT:
      return {
        ...state,
        selectRole: {
          ...state.selectRole,
          loading: true,
        }
      }
    case ActionType.END_FETCH_ROLES_FOR_SELECT:
      return {
        ...state,
        selectRole: {
          ...state.selectRole,
          loading: false,
          data: action.data.data,
        }
      }
    case ActionType.CLEAR_SELECT_ROLE_DATA:
      return {
        ...state,
        selectRole: initialState.selectRole,
      }
    case ActionType.START_FETCH_ROLE:
    case ActionType.START_FETCH_PERMISSIONS:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        }
      }
    case ActionType.END_FETCH_ROLE:
    case ActionType.END_POST_ROLE:
    case ActionType.END_PATCH_ROLE:
      return {
        ...state,
        form: {
          ...state.form,
          row: action.data,
          loading: false,
        },
      }
    case ActionType.START_POST_ROLE:
    case ActionType.START_PATCH_ROLE:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        },
      }
    case ActionType.END_POST_ROLE_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.END_PATCH_ROLE_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.END_FETCH_PERMISSIONS:
      return {
        ...state,
        form: {
          ...state.form,
          permissions: action.data,
          loading: false,
        },
      }
    case ActionType.CLEAR_FORM_DATA:
      return {
        ...state,
        form: {
          ...initialState.form,
        }
      }
    default:
      return state;
  }
}