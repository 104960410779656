import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg data-id="16" width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_5965_332380)">
  <path
    d="M12.1838 3.78082C12.565 3.78082 12.8917 3.61644 13.164 3.34247L15.3422 3.89041C15.2333 4.10959 15.1788 4.38356 15.1788 4.65753C15.1788 4.9863 15.2877 5.36986 15.4511 5.64384L14.6343 6.46575C14.4709 6.41096 14.3075 6.35616 14.0897 6.35616C13.3273 6.35616 12.7283 6.9589 12.7283 7.72603C12.7283 8.49315 13.3273 9.09589 14.0897 9.09589C14.8521 9.09589 15.4511 8.49315 15.4511 7.72603C15.4511 7.50685 15.3966 7.28767 15.2877 7.12329L16.1046 6.35616C16.4313 6.57534 16.8125 6.68493 17.1937 6.68493C18.2828 6.68493 19.2085 5.75342 19.2085 4.65753C19.2085 4.27397 19.0996 3.94521 18.9362 3.61644L20.0254 2.57534C20.2432 2.68493 20.461 2.73973 20.6244 2.73973C21.3867 2.73973 21.9858 2.13699 21.9858 1.36986C21.9858 0.60274 21.3867 0 20.6244 0C19.862 0 19.3174 0.493151 19.3174 1.26027C19.3174 1.42466 19.3719 1.64384 19.4263 1.80822L18.2828 2.90411C17.5749 2.46575 16.5947 2.52055 15.9412 3.06849L13.5452 2.46575V2.41096C13.5452 1.64384 12.9461 1.0411 12.1838 1.0411C11.4214 1.0411 10.8224 1.64384 10.8224 2.41096C10.8224 3.12329 11.4214 3.78082 12.1838 3.78082ZM11.7481 2.35616C11.7481 2.13699 11.966 1.91781 12.1838 1.91781C12.4016 1.91781 12.6194 2.13699 12.6194 2.35616C12.6194 2.57534 12.4561 2.79452 12.1838 2.79452C11.966 2.79452 11.7481 2.63014 11.7481 2.35616ZM16.159 4.60274C16.159 4 16.6491 3.50685 17.2481 3.50685C17.8471 3.50685 18.3372 4 18.3372 4.60274C18.3372 5.20548 17.8471 5.69863 17.2481 5.69863C16.6491 5.69863 16.159 5.20548 16.159 4.60274ZM14.5254 7.72603C14.5254 7.94521 14.362 8.16438 14.0897 8.16438C13.8174 8.16438 13.6541 7.94521 13.6541 7.72603C13.6541 7.45205 13.8174 7.28767 14.0897 7.28767C14.362 7.28767 14.5254 7.45205 14.5254 7.72603ZM20.2432 1.26027C20.2432 1.0411 20.4065 0.821918 20.6788 0.821918C20.8966 0.821918 21.1145 1.0411 21.1145 1.26027C21.1145 1.47945 20.9511 1.69863 20.6788 1.69863C20.461 1.69863 20.2432 1.53425 20.2432 1.26027Z"
    fill="currentColor" />
  <path
    d="M22.15 15.3993C22.15 15.2897 22.15 15.2349 22.0956 15.1801C22.0411 15.1253 21.9867 15.0705 21.9322 15.0157L18.4471 12.9883C18.3381 12.9335 18.2837 12.9335 18.1748 12.9335C18.1203 12.9335 18.0114 12.9335 17.957 12.9883L14.6896 14.9061L11.5857 13.0979V9.3171C11.5857 9.20751 11.5857 9.15272 11.5312 9.09793C11.4768 9.04313 11.4223 8.98834 11.3678 8.93354L8.15497 7.07053V3.18012C8.15497 3.07053 8.15497 3.01573 8.10052 2.96094C8.04606 2.90615 7.93715 2.79656 7.8827 2.74176L4.39755 0.714365C4.34309 0.65957 4.23418 0.65957 4.17973 0.65957H4.12527C4.07082 0.65957 3.96191 0.714365 3.90745 0.714365L0.422302 2.74176C0.367846 2.79656 0.313391 2.85135 0.258935 2.90615C0.20448 3.01573 0.20448 3.07053 0.20448 3.18012V7.28971C0.20448 7.3993 0.20448 7.45409 0.258935 7.50888C0.313391 7.56368 0.367846 7.61847 0.422302 7.67327L3.63517 9.53628V13.0979L0.367846 15.0157C0.313391 15.0705 0.258935 15.1253 0.20448 15.1801C0.150024 15.2349 0.150024 15.3445 0.150024 15.3993V19.5089C0.150024 19.6185 0.150024 19.6733 0.20448 19.7281C0.258935 19.7829 0.313391 19.8377 0.367846 19.8924L3.85299 21.9198C3.90745 21.9746 4.01636 21.9746 4.07082 21.9746C4.17973 21.9746 4.23418 21.9746 4.28864 21.9198L7.55596 20.002L10.8233 21.9198C10.9867 22.0294 11.15 22.0294 11.3134 21.9198L14.5807 20.002L17.848 21.9198C18.0114 22.0294 18.1748 22.0294 18.3381 21.9198L21.8233 19.8924C21.8777 19.8377 21.9322 19.7829 21.9867 19.7281C22.0411 19.6733 22.0411 19.5637 22.0411 19.5089L22.15 15.3993ZM21.2243 19.1801L18.1748 20.9335L15.1253 19.1801V15.6185L18.1748 13.865L21.2243 15.6185V19.1801ZM14.1451 19.2349L11.15 20.9883L8.10052 19.2349V15.6733L11.15 13.9198L14.1995 15.6733L14.1451 19.2349ZM10.6599 13.0979L7.61042 14.8514L4.56091 13.0979V9.59108L7.61042 7.78286L10.6055 9.53628L10.6599 13.0979ZM1.13022 3.45409L4.12527 1.64587L7.17478 3.3993V6.90615L4.12527 8.71436L1.13022 6.96094V3.45409ZM7.17478 19.2349L4.17973 20.9883L1.13022 19.2349V15.6733L4.17973 13.9198L7.22923 15.6733L7.17478 19.2349Z"
    fill="currentColor" />
  <path
    d="M4.61534 16.3293L5.70445 16.9869V18.5211C5.70445 18.5759 5.70445 18.6307 5.75891 18.6855C5.75891 18.7403 5.81336 18.7951 5.86782 18.8499C5.92227 18.9047 5.97673 18.9047 6.03118 18.9595C6.08564 18.9595 6.14009 19.0143 6.19455 19.0143C6.24901 19.0143 6.30346 19.0143 6.35792 18.9595C6.41237 18.9595 6.46683 18.9047 6.52128 18.8499C6.57574 18.7951 6.57574 18.7403 6.63019 18.6855C6.63019 18.6307 6.68465 18.5759 6.68465 18.5211V16.7129C6.68465 16.6033 6.68465 16.5485 6.63019 16.4937C6.57574 16.4389 6.52128 16.3841 6.46683 16.3293L5.1599 15.5622C5.10544 15.5074 4.99653 15.5074 4.94208 15.5074C4.83316 15.5074 4.72425 15.5622 4.6698 15.617C4.56089 15.6718 4.50643 15.7814 4.50643 15.891C4.45198 16.0006 4.50643 16.1102 4.50643 16.2198C4.45198 16.165 4.50643 16.2745 4.61534 16.3293Z"
    fill="currentColor" />
  <path
    d="M4.61551 3.89097L5.70462 4.5485V6.08275C5.70462 6.13755 5.70462 6.19234 5.75908 6.24713C5.75908 6.30193 5.81353 6.35672 5.86799 6.41152C5.92245 6.46631 5.9769 6.46631 6.03136 6.52111C6.08581 6.52111 6.14027 6.5759 6.19472 6.5759C6.24918 6.5759 6.30363 6.5759 6.35809 6.52111C6.41254 6.52111 6.467 6.46631 6.52146 6.41152C6.57591 6.35672 6.57591 6.30193 6.63037 6.24713C6.63037 6.19234 6.68482 6.13755 6.68482 6.08275V4.27453C6.68482 4.16494 6.68482 4.11015 6.63037 4.05535C6.57591 4.00056 6.52145 3.94577 6.467 3.89097L5.05116 3.06905C4.9967 3.01426 4.94225 3.01426 4.83334 3.01426H4.77888C4.66997 3.01426 4.61551 3.06905 4.5066 3.12385C4.45215 3.17864 4.39769 3.28823 4.34324 3.39782C4.34324 3.50741 4.34324 3.617 4.39769 3.67179C4.45215 3.78138 4.5066 3.83618 4.61551 3.89097Z"
    fill="currentColor" />
  <path
    d="M18.1203 16.1091C18.1748 16.2187 18.2292 16.2735 18.3381 16.3283L19.4272 16.9858V18.5201C19.4272 18.6296 19.4817 18.7392 19.5906 18.8488C19.6995 18.9584 19.8084 18.9584 19.9173 18.9584C20.0262 18.9584 20.1352 18.9036 20.2441 18.8488C20.353 18.7392 20.4074 18.6296 20.4074 18.5201V16.7118C20.4074 16.6022 20.4074 16.5475 20.353 16.4927C20.2985 16.4379 20.2441 16.3831 20.1896 16.3283L18.7738 15.5064C18.7193 15.4516 18.6104 15.4516 18.5559 15.4516H18.5015C18.3926 15.4516 18.2837 15.5064 18.2292 15.5612C18.1748 15.6159 18.1203 15.7255 18.0658 15.8351C18.0658 15.8899 18.0658 15.9995 18.1203 16.1091Z"
    fill="currentColor" />
  <path
    d="M11.4766 16.3293L12.5657 16.9869V18.5211C12.5657 18.5759 12.5657 18.6307 12.6202 18.6855C12.6746 18.7403 12.6746 18.7951 12.7291 18.8499C12.7836 18.9047 12.838 18.9047 12.8925 18.9595C12.9469 18.9595 13.0014 19.0143 13.0558 19.0143C13.1103 19.0143 13.1647 19.0143 13.2192 18.9595C13.2737 18.9595 13.3281 18.9047 13.3826 18.8499C13.437 18.7951 13.437 18.7403 13.4915 18.6855C13.4915 18.6307 13.5459 18.5759 13.5459 18.5211V16.7129C13.5459 16.6033 13.5459 16.5485 13.4915 16.4937C13.437 16.4389 13.3826 16.3841 13.3281 16.3293L12.0212 15.5622C11.9667 15.5074 11.8578 15.5074 11.8034 15.5074C11.6944 15.5074 11.5855 15.5622 11.5311 15.617C11.4222 15.6718 11.3677 15.7814 11.3677 15.891C11.3133 16.0006 11.3677 16.1102 11.3677 16.2198C11.3133 16.165 11.3677 16.2745 11.4766 16.3293Z"
    fill="currentColor" />
  <path
    d="M8.2089 10.1375L9.29801 10.7951V12.3293C9.29801 12.3841 9.29801 12.4389 9.35246 12.4937C9.35246 12.5485 9.40692 12.6033 9.46137 12.6581C9.51583 12.7129 9.57029 12.7129 9.62474 12.7677C9.6792 12.7677 9.73365 12.8225 9.78811 12.8225C9.84256 12.8225 9.89702 12.8225 9.95147 12.7677C10.0059 12.7677 10.0604 12.7129 10.1148 12.6581C10.1693 12.6033 10.1693 12.5485 10.2237 12.4937C10.2237 12.4389 10.2782 12.3841 10.2782 12.3293V10.5211C10.2782 10.4115 10.2782 10.3567 10.2237 10.3019C10.1693 10.2471 10.1148 10.1923 10.0604 10.1375L8.75345 9.37042C8.699 9.31563 8.59009 9.31563 8.53563 9.31563H8.48118C8.37226 9.31563 8.26335 9.37042 8.2089 9.42521C8.15444 9.48001 8.09999 9.5896 8.04553 9.69919C8.04553 9.80878 8.04553 9.91836 8.09999 9.97316C8.04553 9.97316 8.09999 10.0827 8.2089 10.1375Z"
    fill="currentColor" />
</g>
<defs>
  <clipPath id="clip0_5965_332380">
    <rect width="22" height="22" fill="white" transform="translate(0.150024)" />
  </clipPath>
</defs>
</svg>`;

export default { id: '16-rheumatology', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
