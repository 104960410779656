import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M9.70922 0.187017C9.62737 0.0837674 9.51014 0.0184523 9.37865 0.00305043C9.10912 -0.0268976 8.86412 0.167051 8.83303 0.436013C8.72836 1.33502 7.85216 2.66557 5.72728 3.45791C3.62465 4.24169 2.22935 5.58707 1.79867 7.24647C0.949006 10.5174 0.0217588 14.5592 0.0126317 14.5983C-0.0167458 14.7266 0.00550127 14.8587 0.0753799 14.9705C0.145259 15.082 0.254498 15.1596 0.38199 15.189C0.418213 15.1972 0.455577 15.2018 0.493226 15.2018C0.724253 15.2018 0.92191 15.0444 0.973535 14.8188C0.982662 14.7788 1.89964 10.7783 2.75245 7.49433C3.22135 5.68775 5.03306 4.76878 6.07097 4.38173C9.34985 3.15985 9.76227 0.975647 9.8119 0.550386C9.8273 0.41947 9.79051 0.290551 9.70894 0.187302L9.70922 0.187017Z"
    fill="currentColor" />
  <path
    d="M21.8179 14.8059C21.8111 14.7643 21.1351 10.6172 20.4038 7.28184C20.0364 5.60789 18.664 4.24968 16.5388 3.45762C14.4136 2.66557 13.5377 1.33502 13.433 0.436298C13.402 0.168477 13.1572 -0.0260419 12.8863 0.00333565C12.7554 0.0184523 12.6384 0.0837674 12.5566 0.187017C12.4747 0.290266 12.4382 0.419185 12.4533 0.549815C12.5027 0.975362 12.9154 3.15957 16.194 4.38145C17.2367 4.7702 19.045 5.69117 19.44 7.49319C20.1642 10.796 20.8376 14.9231 20.8442 14.9645C20.8832 15.2044 21.0878 15.3784 21.3305 15.3784C21.3579 15.3784 21.385 15.3761 21.4098 15.3721C21.6782 15.3284 21.861 15.0746 21.8173 14.8062L21.8179 14.8059Z"
    fill="currentColor" />
  <path
    d="M11.3912 12.2418C11.5957 12.2418 11.7927 12.1463 11.9188 11.9862L12.6972 10.9954C12.8983 10.9697 13.0737 10.8568 13.1809 10.6822L15.2171 7.3734C15.2861 7.2613 15.3072 7.12896 15.277 7.0009C15.2465 6.87255 15.168 6.76417 15.0562 6.69543C14.9441 6.62612 14.8121 6.60445 14.6837 6.63496C14.5551 6.66548 14.4464 6.7442 14.3771 6.85658L12.4895 9.92382L11.6798 9.54021L13.3771 6.37684C13.4393 6.26076 13.4527 6.12756 13.4148 6.00121C13.3769 5.87514 13.2919 5.77132 13.1758 5.70914C13.06 5.64697 12.9265 5.63356 12.8004 5.67149C12.6741 5.70943 12.5705 5.79442 12.5081 5.91051L10.6533 9.36708C10.5669 9.52794 10.5501 9.71961 10.6071 9.89274C10.6208 9.93466 10.6399 9.97631 10.6636 10.0171L10.3093 11.1511C10.2414 11.369 10.2891 11.6055 10.4331 11.7786L8.8125 14.4525C8.74404 14.5652 8.72379 14.6975 8.75517 14.8256C8.78654 14.9534 8.86583 15.0615 8.97878 15.1299C9.05579 15.1764 9.14392 15.2009 9.23376 15.2009C9.40775 15.2009 9.56547 15.1122 9.6556 14.9634L11.3087 12.2358C11.3361 12.2392 11.3635 12.2409 11.3906 12.2409L11.3912 12.2418ZM11.7157 10.6483L11.3575 11.1043L11.5278 10.5593L11.7157 10.6483Z"
    fill="currentColor" />
  <path
    d="M4.78321 9.905H4.78292C4.52023 9.97431 4.36251 10.2444 4.43125 10.5071C4.5807 11.0767 5.07841 13.1314 5.20733 15.6599C5.3274 18.0163 5.069 19.9675 4.45948 21.3024C4.34682 21.5496 4.45606 21.8426 4.70306 21.9552C4.7678 21.9849 4.83683 22 4.90813 22C5.10065 22 5.27692 21.8868 5.35678 21.7116C6.88128 18.3726 6.04188 12.7583 5.38502 10.2564C5.31628 9.99513 5.04646 9.83712 4.78321 9.90443V9.905Z"
    fill="currentColor" />
  <path
    d="M17.8873 16.4377C18.0379 16.4377 18.1782 16.3703 18.2726 16.2526C18.4423 16.0401 18.4078 15.7295 18.1956 15.5595C18.0485 15.442 17.8428 14.7606 17.747 13.2275C17.6782 12.1263 17.6979 11.0091 17.7558 10.589C17.8556 10.4526 17.8788 10.2726 17.8154 10.1149C17.7116 9.8548 17.4834 9.77152 17.3203 9.7795C17.1489 9.78492 16.9335 9.88589 16.8443 10.1531C16.7273 10.5028 16.694 12.0427 16.7447 13.0846C16.8311 14.8638 17.1121 15.9556 17.5793 16.3296C17.6663 16.3994 17.7758 16.4379 17.8873 16.4379V16.4377Z"
    fill="currentColor" />
  <path
    d="M18.0641 19.6016C17.7923 19.6016 17.571 19.8226 17.571 20.0947V21.4193C17.571 21.6911 17.792 21.9124 18.0641 21.9124C18.3362 21.9124 18.5573 21.6914 18.5573 21.4193V20.0947C18.5573 19.8229 18.3362 19.6016 18.0641 19.6016Z"
    fill="currentColor" />
  <path
    d="M13.5611 13.6856C11.5366 13.6856 9.88948 15.3327 9.88948 17.3572C9.88948 19.3817 11.5366 21.0288 13.5611 21.0288C15.5856 21.0288 17.2327 19.3817 17.2327 17.3572C17.2327 15.3327 15.5856 13.6856 13.5611 13.6856ZM13.5611 20.0431C12.0802 20.0431 10.8755 18.8383 10.8755 17.3575C10.8755 15.8766 12.0802 14.6719 13.5611 14.6719C15.042 14.6719 16.2467 15.8766 16.2467 17.3575C16.2467 18.8383 15.042 20.0431 13.5611 20.0431Z"
    fill="currentColor" />
  <path
    d="M13.5611 15.1867C12.3643 15.1867 11.3903 16.1604 11.3903 17.3575C11.3903 18.5546 12.364 19.5283 13.5611 19.5283C14.7582 19.5283 15.7319 18.5546 15.7319 17.3575C15.7319 16.1604 14.7582 15.1867 13.5611 15.1867ZM13.5611 18.542C12.908 18.542 12.3766 18.0106 12.3766 17.3575C12.3766 16.7043 12.908 16.173 13.5611 16.173C14.2143 16.173 14.7456 16.7043 14.7456 17.3575C14.7456 18.0106 14.2143 18.542 13.5611 18.542Z"
    fill="currentColor" />
  <path
    d="M13.5611 16.8643C13.2405 16.8643 12.9798 17.125 12.9798 17.4456C12.9798 17.7662 13.2405 18.0269 13.5611 18.0269C13.8817 18.0269 14.1424 17.7662 14.1424 17.4456C14.1424 17.125 13.8817 16.8643 13.5611 16.8643Z"
    fill="currentColor" />
</svg>`;

export default { id: '214-interventional-nephrology', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
