import { Button, Input, Space } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import React, { useEffect, useRef, useState } from 'react';

import { BaseRow, FilterType, GetFilterParams } from '@components/AntGrid/types';

function SearchFilter<Row extends BaseRow>(props: GetFilterParams<Row> & FilterDropdownProps): JSX.Element {
  const [value, setValue] = useState(props.value || '');
  const searchInput = useRef(null);

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleApply = (closeDropdown = false) => {
    props.onFilter(props.column, { type: FilterType.SEARCH, value: value as string });
    props.confirm({ closeDropdown });
  };

  const handleClear = () => {
    setValue('');
    props.onFilter(props.column, { type: FilterType.SEARCH, value: '' });
  };

  const handleReset = () => {
    setValue('');
    props.onResetFilter(props.column);
    props.confirm();
  };

  useEffect(() => {
    setValue(props.visible ? props.value || '' : '');

    // todo: fix autofocus issue for case: open and hide the same filter dropdown 2 or more times
    // if (visible) {
    //   console.info('focus', searchInput);
    //   searchInput.current?.focus();
    // }
  }, [props.visible]);

  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        autoFocus={true}
        style={{ marginBottom: 8, display: 'block' }}
        value={value}
        placeholder={`Search ${props.column}`}
        onChange={handleChangeValue}
        onPressEnter={() => handleApply()}
      />
      <Space>
        <Button
          onClick={() => handleReset()}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        <Button
          onClick={() => handleClear()}
          size="small"
          style={{ width: 90 }}
        >
          Clear
        </Button>
        <Button
          type="primary"
          onClick={() => handleApply(true)}
          size="small"
          style={{ width: 90 }}
        >
          Apply
        </Button>
      </Space>
    </div>
  );
}

export function getSearchFilter<T extends BaseRow>(params: GetFilterParams<T>) {
  return (props: FilterDropdownProps): JSX.Element => {
    return (<SearchFilter {...props} {...params} />);
  };
}
