import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './QuillEditor.module.scss';
import { random } from 'lodash';

interface QuillEditorProps {
  defaultValue?: string;
  onChange?: (value: string, name?: string) => void;
}

interface QuillEditorState {
  html: string;
}

function insertDl(this: any) {
  const length = this.quill.getSelection().length;
  const index = this.quill.getSelection().index;

  this.quill.insertText(index + length, '</dl>');
  this.quill.insertText(index, '<dl>');
}

// function customClean(this: any) {
//   const range = this.quill.getSelection(true);
//
//   if (range) {
//     const { index, length } = range;
//
//     const content = this.quill.getContents(index, length);
//     content.forEach((op: any) => {
//       const { attributes } = op;
//       if (attributes) {
//         const { link } = attributes;
//         op.attributes = link ? { link } : null;
//       }
//     });
//
//     // const newContent = content.map((op: any) => {
//     //   const { attributes, ...rest } = op;
//     //   const { link } = attributes || {};
//     //   return { ...rest, attributes: link ? { link } : null };
//     // });
//
//     console.log('content, ', 'customClean --- ', content);
//
//     // this.quill.deleteText(index, length);
//     // this.quill.insertText(index, '');
//
//   }
// }


const formats = [
  'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'ordered', 'link',
]

const CustomButton = () => <span className="octicon octicon-star">DL</span>;
const CustomCleanBtn = () => (
  <span className="octicon octicon-star">
  <svg className="" viewBox="0 0 18 18"> <line className="ql-stroke" x1="5" x2="13" y1="3" y2="3" /> <line
    className="ql-stroke" x1="6" x2="9.35" y1="12" y2="3" /> <line className="ql-stroke" x1="11" x2="15" y1="11"
                                                                   y2="15" /> <line className="ql-stroke"
                                                                                    x1="15" x2="11" y1="11"
                                                                                    y2="15" /> <rect
    className="ql-fill" height="1" rx="0.5" ry="0.5" width="7" x="2" y="14" />
  </svg>
</span>
);
const CustomToolbar = (props: any) => (
  <div id={props.id}>
    <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
      <option value="1" />
      <option value="2" />
      <option value="3" />
      <option value="4" />
      <option value="5" />
      <option value="6" />
      <option selected />
    </select>
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" />
    <button className="ql-strike" />
    <button className="ql-dl">
      <CustomButton />
    </button>
    <button className="ql-blockquote" />
    <button className="ql-list" value="ordered" />
    <button className="ql-list" value="bullet" />
    <button className="ql-link" />
    <button className="ql-clean" />
    {/*<button className="ql-cclean">*/}
    {/*  <CustomCleanBtn />*/}
    {/*</button>*/}
  </div>
);

export default class QuillEditor extends Component<QuillEditorProps, QuillEditorState> {

  toolbarId: string;
  modules: any;
  quillRef: any;

  constructor(props: QuillEditorProps) {
    super(props)
    this.state = { html: '' };

    this.quillRef = null;
    this.toolbarId = `quillToolbar${random(1, 1000)}`;
    this.modules = {
      toolbar: {
        container: `#${this.toolbarId}`,
        handlers: {
          dl: insertDl,
          // cclean: customClean,
        }
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      }
    };
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      this.setState({ html: this.props.defaultValue });
    }
  }

  handleEditorChange = (html: string) => {
    this.setState({ html });
    this.props.onChange && this.props.onChange(html);
  };

  render() {
    const { html } = this.state;

    return (
      <div className={styles.container}>
        <CustomToolbar id={this.toolbarId} />
        <ReactQuill
          ref={(ref) => (this.quillRef = ref)}
          theme="snow"
          className={styles.editor}
          value={html}
          onChange={this.handleEditorChange}
          modules={this.modules}
          formats={formats}
        />
      </div>
    )
  }
}
