import React from 'react';
import { Select } from 'antd';

interface Props {
  onChange: (option: any) => void;
  onClear?: () => void;
  loading?: boolean;
  multiple?: boolean;
  mode?: 'multiple' | 'tags';
  defaultValue?: any;
  value?: any;
  disabled?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
}

export const translationsSectionsOptions = [
  { value: 'languages__layout', label: 'Layout' },
  { value: 'languages__home', label: 'Main' },
  { value: 'languages__services', label: 'Providers' },
  { value: 'languages__search', label: 'Search' },
  { value: 'languages__health-library__index', label: 'Health Library / Index' },
  { value: 'languages__health-library__show', label: 'Health Library / Show' },
  { value: 'languages__doctor__index', label: 'Doctor / Index' },
  { value: 'languages__doctor__show', label: 'Doctor / Show' },
  { value: 'languages__hospital__index', label: 'Hospital / Index' },
  { value: 'languages__hospital__show', label: 'Hospital / Show' },
  { value: 'languages__blogs', label: 'Blog' },
  { value: 'languages__how-it-works', label: 'How it works' },
  { value: 'languages__about-us', label: 'About us' },
  { value: 'languages__components', label: 'Components' },
  { value: 'languages__404', label: '404' },
];

export default function SelectTranslationSection(
  {
    onChange,
    onClear,
    placeholder,
    mode,
    defaultValue = undefined,
    value = undefined,
    loading = false,
    multiple = false,
    disabled = false,
    style = { width: 180 },
  }: Props
) {
  const placeholderValue = !placeholder ?  'Select section' : placeholder;

  const handleChange = (value: string[], option: any) => {
    onChange(option);
  };

  // useEffect(() => {
  //   if (!data.length) {
  //     dispatch(getCountriesForSelect());
  //   }
  // }, [options]);

  // useEffect(() => {
  //   if (!isEqual(prevDefaultValue, defaultValue)) {
  //     setValue(find(data, ['id', defaultValue]) || null);
  //   }
  // }, [data, defaultValue]);

  return (
    <Select
      allowClear
      mode={mode}
      style={style}
      placeholder={placeholderValue}
      // fieldNames={{
      //   label: 'name',
      //   value: 'id',
      // }}
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      onClear={onClear}
      loading={loading}
      options={translationsSectionsOptions}
      disabled={disabled}
    />
  );
}
