import { DeleteFilled, EditOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import { Price } from '../types';
import FolderElement from './FolderElement/FolderElement';

interface Props {
  folder: Price;
  editHandler: (price: Price) => void;
  deleteHandler: (price: Price) => void;
  onClick?: () => void;
}

export default function PriceFolder(props: Props) {
  const { folder, editHandler, deleteHandler, onClick } = props;

  const menu: MenuProps['items'] = [
    {
      key: 'rename',
      label: 'Rename',
      icon: <EditOutlined />,
      onClick: () => editHandler(folder),
    },
    {
      key: 'delete',
      label: 'Delete',
      icon: <DeleteFilled />,
      onClick: () => deleteHandler(folder),
    },
  ];

  return <FolderElement title={folder.name} menu={menu} onClick={onClick} />;
}
