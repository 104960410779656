import { ListResponse } from '@api/types';
import { TreatmentProgram, TreatmentProgramExcludedHospital, TreatmentProgramRelation } from '@modelTypes/treatment';
import { createAction } from '@storage/helpers';
import { SearchSectionsState } from '@storage/searchSections/reducer';
import { Hospital } from '@modelTypes/hospital';

export enum ActionType {
  START_FETCH_PROGRAMS = 'PROGRAMS__START_FETCH_PROGRAMS',
  END_FETCH_PROGRAMS = 'PROGRAMS__END_FETCH_PROGRAMS',

  START_FETCH_PROGRAMS_FOR_SELECT = 'PROGRAMS__START_FETCH_PROGRAMS_FOR_SELECT',
  END_FETCH_PROGRAMS_FOR_SELECT = 'PROGRAMS__END_FETCH_PROGRAMS_FOR_SELECT',
  CLEAR_PROGRAMS_SELECT_DATA = 'PROGRAMS__CLEAR_PROGRAMS_SELECT_DATA',

  START_FETCH_PROGRAM = 'PROGRAMS__START_FETCH_PROGRAM',
  END_FETCH_PROGRAM = 'PROGRAMS__END_FETCH_PROGRAM',

  START_POST_PROGRAM = 'PROGRAMS__START_POST_PROGRAM',
  END_POST_PROGRAM = 'PROGRAMS__END_POST_PROGRAM',
  END_POST_PROGRAM_ERROR = 'PROGRAMS__END_POST_PROGRAM_ERROR',

  START_PATCH_PROGRAM = 'PROGRAMS__START_PATCH_PROGRAM',
  END_PATCH_PROGRAM = 'PROGRAMS__END_PATCH_PROGRAM',
  END_PATCH_PROGRAM_ERROR = 'PROGRAMS__END_PATCH_PROGRAM_ERROR',

  START_DELETE_PROGRAM = 'PROGRAMS__START_DELETE_PROGRAM',
  END_DELETE_PROGRAM = 'PROGRAMS__END_DELETE_PROGRAM',

  CLEAR_FORM_DATA = 'PROGRAMS__CLEAR_FORM_DATA',


  // Relations.
  START_FETCH_RELATIONS = 'PROGRAMS__START_FETCH_RELATIONS',
  END_FETCH_RELATIONS = 'PROGRAMS__END_FETCH_RELATIONS',

  START_FETCH_ALL_RELATIONS = 'PROGRAMS__START_FETCH_ALL_RELATIONS',
  END_FETCH_ALL_RELATIONS = 'PROGRAMS__END_FETCH_ALL_RELATIONS',
  CLEAR_ALL_RELATIONS = 'PROGRAMS__CLEAR_ALL_RELATIONS',


  // Excluded.
  START_FETCH_EXCLUDED_HOSPITALS = 'PROGRAMS__START_FETCH_EXCLUDED_HOSPITALS',
  END_FETCH_EXCLUDED_HOSPITALS = 'PROGRAMS__END_FETCH_EXCLUDED_HOSPITALS',

  START_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT = 'PROGRAMS__START_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT',
  END_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT = 'PROGRAMS__END_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT',
  CLEAR_EXCLUDED_HOSPITALS_SELECT = 'PROGRAMS__CLEAR_EXCLUDED_HOSPITALS_SELECT',

  START_POST_EXCLUDED_HOSPITALS = 'PROGRAMS__START_POST_EXCLUDED_HOSPITALS',
  END_POST_EXCLUDED_HOSPITALS = 'PROGRAMS__END_POST_EXCLUDED_HOSPITALS',

  START_DELETE_EXCLUDED_HOSPITAL = 'PROGRAMS__START_DELETE_EXCLUDED_HOSPITAL',
  END_DELETE_EXCLUDED_HOSPITAL = 'PROGRAMS__END_DELETE_EXCLUDED_HOSPITAL',
}

type AllRelations = Pick<SearchSectionsState['allRelations'], 'hospitalsIds' | 'departmentsIds'> & { excludedHospitalsIds: Array<number> };

export const startFetchPrograms = createAction<ActionType.START_FETCH_PROGRAMS>(ActionType.START_FETCH_PROGRAMS);
export const endFetchPrograms = createAction<ActionType.END_FETCH_PROGRAMS, ListResponse<TreatmentProgram>>(ActionType.END_FETCH_PROGRAMS);

export const startFetchProgramsForSelect = createAction<ActionType.START_FETCH_PROGRAMS_FOR_SELECT>(ActionType.START_FETCH_PROGRAMS_FOR_SELECT);
export const endFetchProgramsForSelect = createAction<ActionType.END_FETCH_PROGRAMS_FOR_SELECT, ListResponse<TreatmentProgram>>(ActionType.END_FETCH_PROGRAMS_FOR_SELECT);
export const clearProgramsSelectData = createAction<ActionType.CLEAR_PROGRAMS_SELECT_DATA>(ActionType.CLEAR_PROGRAMS_SELECT_DATA);

export const startFetchProgram = createAction<ActionType.START_FETCH_PROGRAM>(ActionType.START_FETCH_PROGRAM);
export const endFetchProgram = createAction<ActionType.END_FETCH_PROGRAM, TreatmentProgram>(ActionType.END_FETCH_PROGRAM);

export const startPostProgram = createAction<ActionType.START_POST_PROGRAM>(ActionType.START_POST_PROGRAM);
export const endPostProgram = createAction<ActionType.END_POST_PROGRAM, TreatmentProgram>(ActionType.END_POST_PROGRAM);
export const endPostProgramError = createAction<ActionType.END_POST_PROGRAM_ERROR>(ActionType.END_POST_PROGRAM_ERROR);

export const startUpdateProgram = createAction<ActionType.START_PATCH_PROGRAM>(ActionType.START_PATCH_PROGRAM);
export const endUpdateProgram = createAction<ActionType.END_PATCH_PROGRAM, TreatmentProgram>(ActionType.END_PATCH_PROGRAM);
export const endUpdateProgramError = createAction<ActionType.END_PATCH_PROGRAM_ERROR>(ActionType.END_PATCH_PROGRAM_ERROR);

export const startDeleteProgram = createAction<ActionType.START_DELETE_PROGRAM>(ActionType.START_DELETE_PROGRAM);
export const endDeleteProgram = createAction<ActionType.END_DELETE_PROGRAM>(ActionType.END_DELETE_PROGRAM);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

// Relations.
export const startFetchRelations = createAction<ActionType.START_FETCH_RELATIONS>(ActionType.START_FETCH_RELATIONS);
export const endFetchRelations = createAction<ActionType.END_FETCH_RELATIONS, ListResponse<TreatmentProgramRelation>>(ActionType.END_FETCH_RELATIONS);

export const startFetchAllRelations = createAction<ActionType.START_FETCH_ALL_RELATIONS>(ActionType.START_FETCH_ALL_RELATIONS);
export const endFetchAllRelations = createAction<ActionType.END_FETCH_ALL_RELATIONS, AllRelations>(ActionType.END_FETCH_ALL_RELATIONS);
export const clearAllRelations = createAction<ActionType.CLEAR_ALL_RELATIONS>(ActionType.CLEAR_ALL_RELATIONS);

// Excluded.
export const startFetchExcludedHospitals = createAction<ActionType.START_FETCH_EXCLUDED_HOSPITALS>(ActionType.START_FETCH_EXCLUDED_HOSPITALS);
export const endFetchExcludedHospitals = createAction<ActionType.END_FETCH_EXCLUDED_HOSPITALS, ListResponse<TreatmentProgramExcludedHospital>>(ActionType.END_FETCH_EXCLUDED_HOSPITALS);

export const startFetchExcludedHospitalsForSelect = createAction<ActionType.START_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT>(ActionType.START_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT);
export const endFetchExcludedHospitalsForSelect = createAction<ActionType.END_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT, Array<Hospital>>(ActionType.END_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT);
export const clearExcludedHospitalsSelect = createAction<ActionType.CLEAR_EXCLUDED_HOSPITALS_SELECT>(ActionType.CLEAR_EXCLUDED_HOSPITALS_SELECT);

export const startPostExcludedHospitals = createAction<ActionType.START_POST_EXCLUDED_HOSPITALS>(ActionType.START_POST_EXCLUDED_HOSPITALS);
export const endPostExcludedHospitals = createAction<ActionType.END_POST_EXCLUDED_HOSPITALS>(ActionType.END_POST_EXCLUDED_HOSPITALS);

export const startDeleteExcludedHospital = createAction<ActionType.START_DELETE_EXCLUDED_HOSPITAL>(ActionType.START_DELETE_EXCLUDED_HOSPITAL);
export const endDeleteExcludedHospital = createAction<ActionType.END_DELETE_EXCLUDED_HOSPITAL>(ActionType.END_DELETE_EXCLUDED_HOSPITAL);


export type Actions =
  | ReturnType<typeof startFetchPrograms>
  | ReturnType<typeof endFetchPrograms>
  | ReturnType<typeof startFetchProgramsForSelect>
  | ReturnType<typeof endFetchProgramsForSelect>
  | ReturnType<typeof clearProgramsSelectData>
  | ReturnType<typeof startFetchProgram>
  | ReturnType<typeof endFetchProgram>
  | ReturnType<typeof startPostProgram>
  | ReturnType<typeof endPostProgram>
  | ReturnType<typeof endPostProgramError>
  | ReturnType<typeof startUpdateProgram>
  | ReturnType<typeof endUpdateProgram>
  | ReturnType<typeof endUpdateProgramError>
  | ReturnType<typeof startDeleteProgram>
  | ReturnType<typeof endDeleteProgram>
  | ReturnType<typeof clearFormData>
  // Relations.
  | ReturnType<typeof startFetchRelations>
  | ReturnType<typeof endFetchRelations>
  | ReturnType<typeof startFetchAllRelations>
  | ReturnType<typeof endFetchAllRelations>
  | ReturnType<typeof clearAllRelations>
  // Excluded.
  | ReturnType<typeof startFetchExcludedHospitals>
  | ReturnType<typeof endFetchExcludedHospitals>
  | ReturnType<typeof startFetchExcludedHospitalsForSelect>
  | ReturnType<typeof endFetchExcludedHospitalsForSelect>
  | ReturnType<typeof clearExcludedHospitalsSelect>
  | ReturnType<typeof startPostExcludedHospitals>
  | ReturnType<typeof endPostExcludedHospitals>
  | ReturnType<typeof startDeleteExcludedHospital>
  | ReturnType<typeof endDeleteExcludedHospital>;
