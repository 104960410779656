import { ContentPages, ContentTypes } from './../../modelTypes/content';

export const indexPageTitles: Record<ContentPages, string> = {
  [ContentPages.MAIN]: 'Main page',
  [ContentPages.PAGE_MENU_HEALTH_LIBRARY]: 'Menu / Health library',
  [ContentPages.PAGE_MENU_TREATMENTS]: 'Menu / Treatments & Packages',
  [ContentPages.PAGE_MENU_HOSPITALS]: 'Menu / Hospitals',
  [ContentPages.PAGE_MENU_DOCTORS]: 'Menu / Doctors',
  [ContentPages.PAGE_MENU_DESTINATIONS]: 'Menu / Destinations',
  [ContentPages.PAGE_MENU_BLOGS]: 'Menu / Blogs',
  [ContentPages.PAGE_MENU_OFFERS]: 'Menu / Offers',
};

export const indexPageUri: Record<string, ContentPages> = {
  'main': ContentPages.MAIN,
  'health-library': ContentPages.PAGE_MENU_HEALTH_LIBRARY,
  'treatments': ContentPages.PAGE_MENU_TREATMENTS,
  'hospitals': ContentPages.PAGE_MENU_HOSPITALS,
  'doctors': ContentPages.PAGE_MENU_DOCTORS,
  'destinations': ContentPages.PAGE_MENU_DESTINATIONS,
  'blogs': ContentPages.PAGE_MENU_BLOGS,
  'offers': ContentPages.PAGE_MENU_OFFERS,
};

export const typesTitles: Record<ContentTypes, string> = {
  [ContentTypes.HOSPITALS]: 'Hospitals',
  [ContentTypes.DOCTORS]: 'Doctors',
  [ContentTypes.COUNTRIES]: 'Countries',
  [ContentTypes.CITIES]: 'Cities',
};