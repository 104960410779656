import { useHistory, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import { indexPageTitles, indexPageUri } from './../helpers';
import { Button } from 'antd';
import ContentsMenuGrid from '@containers/Contents/components/ContentsMenuGrid';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import * as React from 'react';
import { Language } from '@modelTypes/language';
import { updateQueryParams } from '@helpers/history';

interface RouterParams {
  page: string;
}

export default function MenuIndex(): JSX.Element {
  const history = useHistory();
  const { page } = useParams<RouterParams>();
  const onSelectLanguage = (language: Language) => {
    updateQueryParams(history, {
      ['language-id']: language.id > 1 ? language.id : null,
    });
  };

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.push("/")}
        title={`Content / ${indexPageTitles[indexPageUri[page]]}`}
        extra={[
          <SelectLanguagePerForm
            key="selectLanguage"
            updateParamKey={false}
            queryParamKey="language-id"
            onInit={onSelectLanguage}
            onChange={onSelectLanguage}
          />,
          <Button
            key="createContent"
            type="primary"
            href={`/contents/menu/${page}/create`}
            onClick={(e) => {
              e.preventDefault();

              history.push(`/contents/menu/${page}/create`);
            }}
          >
            Create
            {/*{createButtonContents[type]}*/}
          </Button>,
        ]}
      />
      <ContentsMenuGrid pageName={indexPageUri[page]} />
    </>
  );
}
