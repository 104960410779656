import { List, Modal, Space, Typography } from 'antd';
import 'cropperjs/dist/cropper.css';
import * as React from 'react';
import { Service } from '@modelTypes/service';

const { Text } = Typography

interface ModalProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  data: Array<Service>;
  loading: boolean;
  selectedItems: Array<number>;
  contextHolder: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  handleOnFinish(): Promise<void>;
  handleItemClick(itemId: number): void
}

export default function ExtraServicesModal(
  {
    isOpen,
    handleCloseModal,
    handleOnFinish,
    loading,
    data,
    handleItemClick,
    selectedItems,
    contextHolder

  }: ModalProps) {

  return (
    <Modal
      title={<Text style={{ fontSize: 20 }} strong>Add extra services</Text>}
      open={isOpen}
      onCancel={handleCloseModal}
      onOk={handleOnFinish}
      okText={'Save'}
      width={920}
    >
      {contextHolder}
      <List
        size="small"
        grid={{ gutter: 32, column: 3 }}
        loading={loading}
        dataSource={data}
        renderItem={(item) => {

          return (
            <List.Item
              onClick={() => handleItemClick(item.id)}
              style={{
                borderRadius: 6,
                backgroundColor: selectedItems.includes(item.id) ? '#e6f7ff' : 'transparent',
                cursor: 'pointer',
                padding: '8px 12px',
                // fontWeight: selectedItems.includes(item.id) ? 600: 500,
              }}
            >
              <Space size="middle" align="center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  dangerouslySetInnerHTML={{ __html: item.svg }}
                />
                {item.name}
              </Space>
            </List.Item>
          )
        }}
      />
    </Modal>

  )
}
