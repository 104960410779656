import { useHistory } from 'react-router-dom';
import FormContainer from './components/FormContainer';
import { useEffect } from 'react';
import ShowGAQRCodeForm from '@containers/Auth/components/ShowGAQRCodeForm';

export default function ShowGAQRCode() {
  const history = useHistory();
  const qrCodeImg: string | null = history.location && history.location.state ? (history.location.state as any).qrCodeImg : null;

  useEffect(() => {
    if (!qrCodeImg) {
      history.push('/login');
    }
  }, [qrCodeImg]);
  
  return (
    <FormContainer
      title="QR Code"
      description="Scan this QR code with your Google Authenticator app to get your 2FA code"
    >
      <ShowGAQRCodeForm qrCode={qrCodeImg} />
    </FormContainer>
  );
}
