import { BaseRow, FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import React from 'react';
import getIdCell from '@components/AntGrid/cells/IdCell';
import { EVENT_TYPES, Log } from '../../../modelTypes/log';

export type LogColumnsNames = `${keyof Log}Column` | 'propsColumn' | 'traceColumn' | 'sourceColumn' | 'requestIndexColumn';

export const logColumnsDefinitions: Partial<Record<LogColumnsNames, ColumnType<Log>>> = {
  idColumn: createColumnDefinition<Log>('#', 'id', { width: 20, render: getIdCell }),
  eventTypeColumn: createColumnDefinition<Log>('Event type', 'eventType',
    {
      width: 40,
      render: getEventType,
      sorter: false,
    }),
  messageColumn: createColumnDefinition<Log>('Message', 'message', { width: 127 }),
  propsColumn: {
    title: 'Props',
    width: 120,
    render: getAdditionalInfoCell<Log>('props'),
    sorter: false,
    filtered: false,
  },
  sourceColumn: {
    title: 'Source',
    width: 60,
    render: getAdditionalInfoCell<Log>('source'),
    sorter: false,
    filtered: false,
  },
  requestIndexColumn: {
    title: 'Request index',
    width: 40,
    render: getAdditionalInfoCell<Log>('requestIndex'),
    sorter: false,
    filtered: false,
  },
  traceColumn: {
    title: 'Trace',
    width: 160,
    render: getAdditionalInfoCell<Log>('trace'),
    sorter: false,
    filtered: false,
  },
};

export const logsColumns: any = [
  logColumnsDefinitions.idColumn,
  logColumnsDefinitions.eventTypeColumn,
  logColumnsDefinitions.messageColumn,
  logColumnsDefinitions.propsColumn,
  logColumnsDefinitions.sourceColumn,
  logColumnsDefinitions.requestIndexColumn,
  logColumnsDefinitions.traceColumn,
];

export const logsFiltersConfig: FiltersConfig = {
  [logColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [settingColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
};


function getEventType() {
  return (value: any, record: Log) => {
    return <EventCell record={record} />
  };
}

function EventCell({ record }: { record: Log }) {
  return (
    <>
      {EVENT_TYPES[record.eventType]}
    </>

  );
}


function getAdditionalInfoCell<T extends BaseRow>(fildName : string) {
  return (value: any, record: T) => {
    return (
      <>{record.info[fildName]}</>
    );
  };
}


