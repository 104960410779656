import { create } from 'zustand';

export interface AuthState {
  loginForm: {
    email: string;
    password: string;
  };
}

const initialLoginFormState = {
  email: '',
  password: '',
}

export const useAuthStore = create<AuthState>((set) => ({
  loginForm: initialLoginFormState,
  clearFormData: () => set(() => ({ loginForm: initialLoginFormState })),
}));