import { ActionType, Actions } from './actions';
import { ModalWithFormData, ModalWithFormTypes } from '@components/Modal/ModalWithForm/ModalWithForm';

import { FrontendLanguage } from '@modelTypes/frontendLanguage';
import { ListResponse } from '@api/types';

export type ModalData = ModalWithFormData<Omit<FrontendLanguage, 'id'>, FrontendLanguage>;

export interface FrontendTranslationsState {
  readonly grid: ListResponse<FrontendLanguage> & { loading: boolean; frontendDictionaryLoading: boolean };
  readonly modalWithForm: {
    open: boolean;
    loading: boolean;
    data: ModalData
  }
}

const initialState: FrontendTranslationsState = {
  grid: {
    frontendDictionaryLoading: false,
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  modalWithForm: {
    open: false,
    loading: false,
    data: {
      type: ModalWithFormTypes.ADD,
      formData: {
        key: '',
        messageEn: '',
        messageRu: '',
        link: '',
      },
    },
  }
};

export default function frontendTranslations(state = initialState, action: Actions): FrontendTranslationsState {
  switch (action.type) {
    case ActionType.START_FETCH_FRONTEND_DICTIONARY:
    // case ActionType.START_DELETE_FRONTEND_TRANSLATION:
      return {
        ...state,
        grid: {
          ...state.grid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_FRONTEND_DICTIONARY:
      return {
        ...state,
        grid: {
          ...state.grid,
          ...action.data,
          loading: false,
        },
      }
    case ActionType.START_POST_FRONTEND_DICTIONARY:
      return {
        ...state,
        grid: {
          ...state.grid,
          frontendDictionaryLoading: true,
        },
      }
    case ActionType.END_POST_FRONTEND_DICTIONARY:
      return {
        ...state,
        grid: {
          ...state.grid,
          frontendDictionaryLoading: false,
        },
      }
    case ActionType.START_POST_FRONTEND_TRANSLATION:
    case ActionType.START_UPDATE_FRONTEND_TRANSLATION:
      return {
        ...state,
        modalWithForm: {
          ...state.modalWithForm,
          loading: true,
        },
      };
    case ActionType.END_POST_FRONTEND_TRANSLATION:
      return {
        ...state,
        // data: state.data.concat(action.data),
        modalWithForm: {
          ...state.modalWithForm,
          loading: false,
        },
      };
    case ActionType.END_UPDATE_FRONTEND_TRANSLATION:
      return {
        ...state,
        // data: replaceObjectsBy(state.data, [action.data], 'id'),
        modalWithForm: {
          ...state.modalWithForm,
          loading: false,
        },
      };
    // case ActionType.END_DELETE_FRONTEND_TRANSLATION:
    //   return {
    //     ...state,
    //     loading: false,
    //     data: filter(state.data, (i) => i.id !== action.data),
    //   };
    case ActionType.OPEN_MODAL_WITH_FORM:
      return {
        ...state,
        modalWithForm: {
          ...state.modalWithForm,
          open: true,
        },
      };
    case ActionType.MODAL_WITH_FORM_SET_DATA:
      return {
        ...state,
        modalWithForm: {
          ...state.modalWithForm,
          data: action.data,
        },
      };
    case ActionType.CLOSE_MODAL_WITH_FORM:
      return {
        ...state,
        modalWithForm: {
          ...initialState.modalWithForm,
        },
      };
    default:
      return state;
  }
}