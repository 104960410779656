import { useHistory, useParams } from 'react-router-dom';
import UsersVendorsGrid from './components/UsersVendorsGrid';
import { Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Vendors } from '../../modelTypes/vendor';
import CompanyVendorsGrid from '@containers/Vendors/components/CompanyVendorsGrid';
import SelectVendor from '@containers/Vendors/components/SelectVendor';


interface RouterParams {
  seller: Vendors;
}

const vendorsScreen = {
  [Vendors.USERS]: <UsersVendorsGrid />,
  [Vendors.COMPANY]: <CompanyVendorsGrid />,
};

const gridTitle = {
  [Vendors.USERS]: 'Vendor list',
  [Vendors.COMPANY]: 'Organizations',
}

export default function UsersIndex(): JSX.Element {
  const history = useHistory();
  const { seller } = useParams<RouterParams>();
  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.push('/')}
        title={gridTitle[seller]}
        extra={[
          <SelectVendor key="selectVendor" />,
          <Button
            key="createUser"
            type="primary"
            href={`/vendors/${seller}/create`}
            onClick={(e) => {
              e.preventDefault();
              history.push(`/vendors/${seller}/create`);
            }}
          >
            Add {Vendors.USERS === seller ? 'Vendor' : 'Organization'}
          </Button>
        ]}
      />
      {vendorsScreen[seller]}
    </>
  );
}