import React, { useEffect } from 'react';
import { Select, SelectProps, Spin } from 'antd';
import { FetchFunction, useSelect } from '@zustandStorage/customSelect/customSelect';
import _ from 'lodash';

// interface QueryParamsType {
//   page: number;
//   perPage: number;
//   search?: string;
//   disease?: string;
//   onlyTreatments?: boolean;
//   isActive?:boolean;
// }

interface FieldNames {
  label: string;
  value: string;
}

interface Props extends SelectProps<any> {
  selectId: any;
  options?: Array<any>;
  onChange?: (option: any, value: any) => void;
  onUpdateSelect: FetchFunction<any>;
  alwaysUpdate?: boolean;
  onClear?: () => void;
  defaultQueryParams?: any;
  multiple?: boolean;
  mode?: 'multiple' | 'tags';
  defaultValue?: any;
  value?: any;
  disabled?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
  fieldNames?: FieldNames;
  transformFieldLabel?: (i: any) => any;
  optionLabelProp?: string;
}

export default function CustomSelect(
  {
    options,
    selectId,
    onChange,
    onUpdateSelect,
    onClear,
    defaultQueryParams,
    placeholder,
    mode,
    transformFieldLabel,
    optionLabelProp,
    alwaysUpdate = false,
    defaultValue = undefined,
    value = undefined,
    disabled = false,
    style = { width: 180 },
    fieldNames = {
      label: 'name',
      value: 'id',
    },
    ...props
  }: Props
) {
  const { clearFormData, setQueryParams } = useSelect();
  const select = useSelect((state) => state.data?.[selectId]);

  const handleChange = (value: string[], option: any) => {
    onChange && onChange(option, value);
  };

  const handleSearch = async (value: string) => {
    onUpdateSelect(selectId, {...select.params, search: value, page: 1 }, { saveParams: true, clearDataBeforeFetch: true });
  }

  const handleVisibleChange = (open: boolean) => {
    if (open && alwaysUpdate && !value ? false : select?.opened) {
      return;
    }

    onUpdateSelect(selectId, { ...select.params }, { clearDataBeforeFetch: true });
  };

  const handleLoadMore = (e: React.UIEvent<HTMLDivElement>) => {
    if (e.currentTarget.scrollTop + e.currentTarget.offsetHeight === e.currentTarget.scrollHeight) {
      if (select.params.page >= select?.meta.lastPage) {
        return;
      }

      const page = (select.params.page as number) + 1;

      setQueryParams(selectId, { ...select.params, page });
      onUpdateSelect(selectId, { page });
    }
  }

  useEffect(() => {
    setQueryParams(selectId, defaultQueryParams);

    return () => {
      clearFormData(selectId)
    };
  }, []);

  return (
    <Select
      allowClear
      showSearch
      mode={mode}
      style={style}
      placeholder={placeholder}
      fieldNames={fieldNames}
      defaultValue={defaultValue}
      value={value}
      onChange={handleChange}
      onClear={onClear}
      loading={select?.loading}
      optionLabelProp={optionLabelProp}
      options={transformFieldLabel
        ? select?.data.map((i) => transformFieldLabel(i))
        : select?.data
      }
      onPopupScroll={handleLoadMore}
      onSearch={_.debounce(handleSearch, 300)}
      onDropdownVisibleChange={handleVisibleChange}
      dropdownRender={(menu: any) => (
        <Spin spinning={select?.loading}>
          {menu}
        </Spin>
      )}
      filterOption={() => true}
      disabled={disabled}
      {...props}
    />
  );
}
