import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useSelector } from '@hooks';
import {
  Form, FormInstance,
} from 'antd';
import { CitiesState } from '@storage/cities/reducer';
import { getCitiesForSelect } from '@storage/cities/thunkActions';
import SelectCity from '@base/Select/SelectCity';

interface Props {
  form: FormInstance;
  initialValue?: any;
}

export default function CountriesField(
  {
    form,
    initialValue,
  }: Props
) {
  const dispatch = useDispatch();
  const data = useSelector<CitiesState['selectCity']>('cities.selectCity');
  const [count, setCount] = React.useState<number>();
  const filteredData = useMemo(
    () => {
      return data.data.filter((i) => i.isActive);
    },
    [data.fetched, data.loading]
  );

  useEffect(() => {
    if (!data.fetched && !data.loading) {
      dispatch(getCitiesForSelect() as unknown as Promise<void>)
        .then(() => {
          form.resetFields(['cities']);
        });
    }
  }, [data.fetched]);

  useEffect(() => {
    if (initialValue && initialValue.length) {
      setCount(initialValue.length);
    }
  }, [initialValue]);
  
  return (
    <Form.Item
      name="cities"
      label={`Cities ${count ? `(${count} selected)` : ''}`}
      initialValue={data.fetched ? initialValue : []}
      valuePropName="defaultValue"
    >
      <SelectCity
        mode="multiple"
        loading={data.loading}
        options={filteredData}
        onChange={() => setCount(form.getFieldValue('cities')?.length)}
        style={{ width: '100%' }}
      />
    </Form.Item>
  );
}
