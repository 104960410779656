import {
  CreateDestinationsContentRequest,
  CreateHospitalContentRequest,
  CreateHospitalRequest,
  DestinationPlace,
  GetListParams,
  Params,
  UpdateHospitalImageOrderRequest,
  UpdateHospitalImageOrderResponse,
  UpdateHospitalImageRequest,
  UpdateHospitalImageResponse
} from '@api/types';
import { Hospital, HospitalAccessKey } from '@modelTypes/hospital';
import { endFetchAccessKey, startFetchAccessKey } from '@storage/blogs/actions';
import {
  endFetchHospital,
  endFetchHospitalsForSelect,
  endFetchTranslation,
  endFetchTranslations,
  endPatchTranslation,
  endPostHospital,
  endUpdateHospital,
  endUploadHospitalImages,
  startDeleteHospital,
  startFetchHospital,
  startFetchHospitals,
  startFetchHospitalsForSelect,
  startFetchTranslation,
  startFetchTranslations,
  startPatchTranslation,
  startPostHospital,
  startUpdateHospital,
  startUploadHospitalImages
} from './actions';

import { AxiosRequestConfig } from 'axios';
import { ThunkAction } from '@storage/types';
import api from '@api';
import { HospitalRelations } from '@api/hospitalApi';

export function getHospitals(params?: GetListParams): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchHospitals());

    // return api.getHospitals(params)
    //   .then((data) => {
    //     dispatch(endFetchHospitals(data));
    //   });
  };
}

export function getHospitalsForSelect(): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    const params: GetListParams & Params = { page: 1, perPage: 5000 };

    dispatch(startFetchHospitalsForSelect());

    return api.getHospitalsForSelect(params)
      .then((data) => {
        dispatch(endFetchHospitalsForSelect(data));
      });
  };
}

export function getHospital(id: number, params?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchHospital());

    return api.getHospital(id, params)
      .then((data) => {
        dispatch(endFetchHospital(data));
      });
  };
}

export function getHospitalAccessKey(id: number): ThunkAction<Promise<HospitalAccessKey>> {
  return async (dispatch): Promise<HospitalAccessKey> => {
    dispatch(startFetchAccessKey());

    return api.getHospitalAccessKey(id)
      .then((data) => {
        dispatch(endFetchAccessKey(data));

        return data;
      });
  };
}

export function createHospital(payload: CreateHospitalRequest): ThunkAction<Promise<Hospital>> {
  return async (dispatch): Promise<Hospital> => {
    dispatch(startPostHospital());

    return api.createHospital(payload)
      .then((data) => {
        dispatch(endPostHospital(data));

        return data;
      });
  };
}

export function updateHospital(id: number, payload: CreateHospitalRequest): ThunkAction<Promise<Hospital>> {
  return async (dispatch, getState): Promise<Hospital> => {
    dispatch(startUpdateHospital());

    return api.editHospital(id, payload)
      .then((data) => {
        dispatch(endUpdateHospital(data));

        return data;
      });
  };
}

export function deleteHospital(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startDeleteHospital());

    return api.deleteHospital(id, config);
  };
}


export function uploadHospitalImages(id: number, payload: FormData): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startUploadHospitalImages());

    return api.createHospitalImages(id, payload)
      .then((data) => {
        dispatch(endUploadHospitalImages());

        return data;
      });
  };
}

export function updateHospitalImage(id: number, payload: UpdateHospitalImageRequest): ThunkAction<Promise<UpdateHospitalImageResponse>> {
  return async (dispatch): Promise<UpdateHospitalImageResponse> => {
    dispatch(startUploadHospitalImages());

    return api.updateHospitalImage(id, payload)
      .then((data) => {
        return data;
      });
  };
}

export function updateHospitalImageOrder(id: number, payload: UpdateHospitalImageOrderRequest): ThunkAction<Promise<UpdateHospitalImageOrderResponse>> {
  return async (dispatch): Promise<UpdateHospitalImageOrderResponse> => {
    dispatch(startUploadHospitalImages());

    return api.updateHospitalImageOrder(id, payload)
      .then((data) => {
        return data;
      });
  };
}

export function deleteHospitalImage(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startUploadHospitalImages());

    return api.deleteHospitalImage(id, config);
  };
}

// Hospitals Content.
export function getHospitalsContents(params?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchTranslations());

    return api.getHospitalsContents(params)
      .then((data) => {
        dispatch(endFetchTranslations(data));
      });
  };
}

export function getHospitalsContentsByPlace(relations: HospitalRelations, params?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchTranslation());

    return api.getHospitalsContentsByPlace(relations, params)
      .then((data) => {
        dispatch(endFetchTranslation(data));
      });
  };
}

export function updateOrCreateHospitalsContentsByPlace(payload?: CreateHospitalContentRequest): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startPatchTranslation());

    return api.updateHospitalContentByPlace(payload)
      .then((data) => {
        dispatch(endPatchTranslation(data));
      });
  };
}

// Destinations Content.
export function getDestinationsContents(params?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchTranslations());

    return api.getDestinationsContents(params)
      .then((data) => {
        dispatch(endFetchTranslations(data));
      });
  };
}

export function getDestinationsContentsByPlace(relations: DestinationPlace, params?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchTranslation());

    return api.getDestinationContentsByPlace(relations, params)
      .then((data) => {
        dispatch(endFetchTranslation(data));
      });
  };
}

export function updateOrCreateDestinationsContentsByPlace(payload?: CreateDestinationsContentRequest): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startPatchTranslation());

    return api.updateDestinationsContentByPlace(payload)
      .then((data) => {
        dispatch(endPatchTranslation(data));
      });
  };
}
