import { useHistory } from 'react-router-dom';
import AuthorsGrid from './components/AuthorsGrid';
import { Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

export default function CitiesIndex(): JSX.Element {
  const history = useHistory();

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.push("/")}
        title="Authors"
        extra={(
          <Button
            key="createAuthor"
            type="primary"
            href="/authors/create"
            onClick={(e) => {
              e.preventDefault();

              history.push("/authors/create");
            }}
          >
            Add Author
          </Button>
        )}
      />
      <AuthorsGrid />
    </>
  );
}