import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M22.601 16.5544L20.0615 13.9936C20.0155 13.9094 19.9488 13.8375 19.8672 13.7889L18.4089 12.9811L18.3979 12.9752C18.385 12.9687 18.3714 12.9642 18.3584 12.959L13.032 7.63265C12.896 7.49661 12.6822 7.44025 12.4879 7.48883C12.2754 7.54195 12.1192 7.7363 12.1115 7.95526C11.945 9.6843 12.5326 11.3155 13.858 12.8029C14.842 13.9068 15.9764 14.6414 16.632 15.0113L17.3348 16.2811C17.3426 16.2972 17.351 16.3134 17.3601 16.329C17.3802 16.364 17.4055 16.3964 17.434 16.4255C17.4359 16.4275 17.4372 16.4294 17.4391 16.4313L17.4502 16.4424C17.454 16.4462 17.4573 16.4501 17.4612 16.4534L20.0881 19.0583C20.4049 19.3984 20.8629 19.5934 21.3461 19.5934C21.8294 19.5934 22.2842 19.3997 22.601 19.0621C22.9385 18.746 23.1322 18.2893 23.1322 17.8073C23.1322 17.3253 22.9476 16.901 22.601 16.5544ZM17.3549 13.4915L16.9202 13.9262C15.85 13.2752 13.6857 11.6951 13.2426 9.36103L17.3549 13.4915ZM19.0982 15.516L19.1196 15.492C19.3029 15.2627 19.2939 14.9427 19.0982 14.747L19.0742 14.7256C18.8449 14.5423 18.5249 14.5514 18.3293 14.747L17.9529 15.1234L17.6937 14.668L18.2502 14.1115L19.1552 14.6194C19.1682 14.6356 19.1818 14.6518 19.1967 14.6667L21.8301 17.3448C21.9622 17.4769 22.0348 17.6492 22.0348 17.83C22.0348 17.9978 21.9525 18.1928 21.8301 18.3152C21.6979 18.4474 21.5256 18.5199 21.3449 18.5199C21.1771 18.5199 20.9821 18.4376 20.8596 18.3152L18.5787 16.0342L19.0982 15.516Z"
    fill="currentColor" />
  <path
    d="M12.8558 13.2479L12.8584 13.2356C12.8817 13.0957 12.85 12.9538 12.7696 12.8366C12.6861 12.7148 12.5617 12.6345 12.4179 12.6105C12.278 12.5872 12.1361 12.6189 12.0188 12.6992C11.897 12.7828 11.8167 12.9072 11.794 13.0452C11.6716 13.6982 11.4876 14.3214 11.2311 14.9504C10.1699 17.5184 8.70846 19.3614 7.0034 20.2807C5.66953 20.9998 4.65375 20.9071 4.64468 20.9058L4.58638 20.9039C1.8014 20.9039 1.17107 18.8866 1.16653 18.8723L1.16006 18.8509C1.11406 18.7123 1.01818 18.6009 0.891211 18.5374C0.764238 18.4739 0.617831 18.4642 0.479197 18.5102C0.340564 18.5562 0.229138 18.6521 0.165652 18.779C0.102166 18.906 0.0924484 19.0524 0.138444 19.191L0.16047 19.2565L0.169539 19.2655C0.451989 19.936 1.56559 21.9799 4.60841 22H4.78656C5.25363 22 6.49615 21.9028 7.93755 21.0056C9.71582 19.8985 11.1624 17.9958 12.2391 15.3475C12.4859 14.7198 12.6932 14.0137 12.8552 13.2492L12.8558 13.2479Z"
    fill="currentColor" />
  <path
    d="M0.446746 8.05046L0.470716 8.05435H0.539385C0.797865 8.05435 1.01424 7.87944 1.06865 7.62809C1.07189 7.61578 1.07448 7.60023 1.07967 7.57432C1.28503 6.49246 2.53078 1.09546 6.91847 1.09546C8.48555 1.09546 9.77147 1.78863 10.74 3.15553C11.5245 4.26266 11.9345 5.5719 12.1412 6.4795C12.1645 6.61878 12.2371 6.73863 12.3459 6.81831C12.4638 6.90447 12.6128 6.93816 12.7657 6.91289C12.9089 6.88892 13.0339 6.80859 13.1175 6.6868C13.1978 6.5689 13.2295 6.42703 13.2062 6.28774L13.2036 6.27349C12.3479 2.34511 9.99821 0 6.91847 0C4.55846 0 2.61306 1.28592 1.2928 3.71849C0.379373 5.40023 0.0483369 7.10465 0.00752424 7.42532C-0.0417101 7.72137 0.155875 8.00188 0.448042 8.05046H0.446746Z"
    fill="currentColor" />
  <path
    d="M1.77604 14.2067C1.6225 14.3091 1.5344 14.4704 1.5344 14.6498C1.5344 14.7697 1.54347 15.8599 1.85442 17.3253C2.17315 18.698 3.41567 19.7119 4.81496 19.7365H5.03781C5.28787 19.7365 6.19223 19.6801 7.32462 18.9578C8.77897 18.0288 9.98392 16.4145 10.9051 14.1601C12.1658 11.1341 11.8438 6.66802 10.1867 4.20565C9.31083 2.90353 8.20306 2.26997 6.79988 2.26997C6.18704 2.26997 5.58911 2.4073 5.02421 2.67744C3.61455 3.34664 2.73481 4.81914 2.7834 6.43156C2.85142 8.20594 3.81343 9.63244 5.56514 10.5562C5.69081 10.6281 5.845 10.6392 5.99011 10.586C6.11449 10.5407 6.22397 10.4506 6.2907 10.3392C6.36714 10.2122 6.3801 10.0542 6.32568 9.90583C6.28033 9.78144 6.19028 9.67196 6.07886 9.60459L6.06525 9.59682C4.63487 8.83951 3.89894 7.76154 3.87821 6.38751C3.8374 5.20654 4.47226 4.1318 5.49064 3.65241C5.91885 3.45936 6.34706 3.36608 6.79859 3.36608C7.81437 3.36608 8.62479 3.84158 9.2778 4.82108C9.98327 5.86861 10.4367 7.37933 10.5559 9.07532C10.6751 10.7765 10.4413 12.4401 9.89711 13.7584C7.94199 18.5037 5.32868 18.641 5.03716 18.641H4.82014C3.90672 18.6216 3.10471 17.968 2.91166 17.0804C2.67068 16.0757 2.62015 15.2704 2.60978 14.931C3.91514 13.9405 3.58022 12.926 3.25371 12.398C2.91166 11.8577 2.79635 11.2371 2.92656 10.669C3.97798 11.7327 5.28787 12.2464 5.30147 12.2509C5.60271 12.3513 5.91172 12.2114 6.00501 11.9329C6.10542 11.6316 5.96549 11.3226 5.68693 11.2293L5.6811 11.2274C5.58651 11.1885 4.02656 10.5258 3.20578 9.31437C3.10666 9.16537 2.92786 9.07338 2.73999 9.07338C2.54046 9.07338 2.37203 9.18091 2.27874 9.36813L2.24765 9.42968V9.44522C2.24311 9.45559 2.23793 9.46725 2.2321 9.48085C2.17509 9.61366 2.0682 9.86177 1.97103 10.1688C1.67951 11.0926 1.8162 12.089 2.36037 12.9784C2.48799 13.1773 2.51196 13.3568 2.43616 13.542C2.34871 13.7565 2.12132 13.9852 1.77474 14.2067H1.77604Z"
    fill="currentColor" />
  <path
    d="M11.3315 19.5746C11.1021 19.3912 10.7821 19.4003 10.5865 19.5959C10.1239 20.0585 9.78317 20.2956 9.77993 20.2982L9.76309 20.3105C9.54153 20.488 9.48323 20.8035 9.62834 21.0451L9.63611 21.0568C9.73847 21.2103 9.89978 21.2978 10.0792 21.2978C10.157 21.2978 10.3111 21.2978 10.4368 21.1552C10.5547 21.0736 10.9026 20.8184 11.3561 20.3649L11.3775 20.3409C11.5608 20.1116 11.5517 19.7916 11.3561 19.5959L11.3315 19.5746Z"
    fill="currentColor" />
  <path
    d="M12.9717 17.2774C12.8512 17.2048 12.7015 17.1854 12.561 17.2236C12.4256 17.2605 12.3077 17.3493 12.237 17.4665C12.0259 17.819 11.8322 18.1157 11.6294 18.4007C11.5406 18.5154 11.5024 18.6566 11.5206 18.7998C11.5387 18.941 11.6119 19.0693 11.7253 19.1599L11.7492 19.1794L11.7771 19.193C11.8127 19.2105 11.9377 19.2681 12.0634 19.2681C12.2591 19.2681 12.4262 19.1742 12.5143 19.0148C12.7333 18.687 12.9587 18.3476 13.1608 18.0114C13.3092 17.7639 13.2224 17.4283 12.9717 17.2774Z"
    fill="currentColor" />
  <path
    d="M14.029 14.6634C13.7446 14.5688 13.4479 14.7152 13.3501 14.9977C13.3054 15.1169 13.2633 15.2361 13.2212 15.3553C13.1363 15.595 13.056 15.8211 12.9562 16.0491L12.9465 16.0737C12.8519 16.3575 12.9705 16.6483 13.2179 16.7585C13.3112 16.8142 13.409 16.8142 13.4466 16.8142C13.6235 16.8142 13.7932 16.717 13.9253 16.5415L13.9506 16.5078L13.9655 16.4689C13.9966 16.3853 14.029 16.3011 14.062 16.2156C14.1715 15.9312 14.2842 15.6377 14.3723 15.3378C14.4637 15.0534 14.314 14.758 14.029 14.6634Z"
    fill="currentColor" />
</svg>`;

export default { id: '106-ear-surgery', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
