import { useCallback, useState } from 'react';
import { Edge, EdgeProps, getBezierPath, getSmoothStepPath, getStraightPath, useReactFlow, useStore } from 'reactflow';
import { getEdgeParams } from '../../helpers/getEdgeParams';
import SelectEdgeTypeModal from '../SelectEdgeTypeModal/SelectEdgeTypeModal';
import cn from 'classnames';
import styles from './CustomEdge.module.scss';

export const enum EdgeType {
  straight,
  step,
  bezier,
}

export interface CustomEdgeProps {
  id?: number;
  highlightLevel?: number;
  type: EdgeType;
}

export const pathFunctionMap = [getStraightPath, getSmoothStepPath, getBezierPath];

export default function CustomEdge({
  id,
  sourcePosition,
  targetPosition,
  source,
  target,
  selected,
  data,
}: EdgeProps<CustomEdgeProps>) {
  const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));
  const flowState = useReactFlow();
  const [selectingEdgeType, setSelectingEdgeType] = useState(false);

  if (!sourceNode || !targetNode || !data) {
    return null;
  }

  const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

  const [edgePath] = pathFunctionMap[data.type]({
    sourceX: sx,
    sourceY: sy,
    sourcePosition,
    targetX: tx,
    targetY: ty,
    targetPosition,
    borderRadius: 0,
  });

  const handleEdgeTypeSelect = (edgeType: EdgeType) => {
    const newEdges = JSON.parse(JSON.stringify(flowState.getEdges())) as Edge<CustomEdgeProps>[];
    const foundEdge = newEdges.find((i) => i.id === id);

    if (!foundEdge?.data) {
      return;
    }

    foundEdge.selected = false;
    foundEdge.data.type = edgeType;
    flowState.setEdges(newEdges);
  };

  return (
    <>
      <path
        className={cn(
          'react-flow__edge-path',
          data.highlightLevel && styles.highlighted,
          data.highlightLevel && styles[`level-${data.highlightLevel}`],
          selected && styles.selected,
        )}
        d={edgePath}
      />
      <path
        style={{ opacity: 0 }}
        strokeWidth={20}
        className="react-flow__edge-interaction"
        d={edgePath}
        onDoubleClick={() => setSelectingEdgeType(true)}
      />
      {selectingEdgeType && (
        <SelectEdgeTypeModal open={true} onClose={() => setSelectingEdgeType(false)} onSelect={handleEdgeTypeSelect} />
      )}
    </>
  );
}
