import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks';
import { GridInstance, PaginationConfig } from '@components/AntGrid/types';
import { useEffect, useRef } from 'react';
import { getFrontendLanguages } from '@storage/frontendTranslations/thunkActions';
import { FrontendLanguage } from '@modelTypes/frontendLanguage';
import { frontendLanguageColumns, frontendLanguageFiltersConfig } from '@components/AntGrid/columns/frontendLanguage';
import { FrontendTranslationsState } from '@storage/frontendTranslations/reducer';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';

interface Props {
  type: number;
}

export const [
  refreshFrontendTranslationsGridEvent,
  dispatchRefreshFrontendTranslationsGridEvent
] = makeRefreshGridEvent('onRefreshFrontendTranslationsGrid');
export const [
  refreshFrontendTranslationsGridOnlyEvent,
  dispatchRefreshFrontendTranslationsGridOnlyEvent
] = makeRefreshGridEvent('onRefreshFrontendTranslationsGridOnly');

export default function FrontendLanguagesAntGrid(
  {
    type
  }: Props
) {
  const dispatch = useDispatch();
  const firstLoad = useRef<boolean>(false);
  const { data, meta, loading } = useSelector<FrontendTranslationsState['grid']>('frontendTranslations.grid');
  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
    // ...rest.paginationConfig,
  };
  let gridInstance: GridInstance | null = null;

  const onFetchData = (params?: AntGetListParams) => {
    dispatch(getFrontendLanguages(type, params) as unknown as Promise<void>)
      .then(() => {
        firstLoad.current = true;
      });
  };
  const onRefreshGrid = () => {
    gridInstance?.refreshData();
  }

  useEffect(() => {
    if (firstLoad.current) {
      dispatch(getFrontendLanguages(type));
    }
  }, [firstLoad, type]);

  useRefreshGridEvent(refreshFrontendTranslationsGridEvent, onFetchData);
  useRefreshGridEvent(refreshFrontendTranslationsGridOnlyEvent, onRefreshGrid);

  return (
    <Grid<FrontendLanguage>
      id="frontendLanguagesGrid"
      onFetch={onFetchData}
      columns={frontendLanguageColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={frontendLanguageFiltersConfig}
      instance={(grid: GridInstance) => gridInstance = grid}
    />
  );
}