import {
  AntGetListParams,
  CreateDepartmentRequest,
  CreateDepartmentResponse,
  CreateDestinationsContentRequest,
  CreateHospitalContentRequest,
  CreateHospitalPricesCategoryRequest,
  CreateHospitalRequest,
  CreateHospitalServicePriceRequest,
  DestinationPlace,
  GetListParams,
  HospitalPricesGridListParams,
  ListResponse,
  Params,
  UpdateDepartmentRequest,
  UpdateDepartmentResponse,
  UpdateHospitalImageOrderRequest,
  UpdateHospitalImageOrderResponse,
  UpdateHospitalImageRequest,
  UpdateHospitalImageResponse,
  UpdateHospitalServicePriceRequest,
  UpdatePricesCategoryRequest,
} from '@api/types';
import { Hospital, HospitalAccessKey, HospitalDepartment } from '../modelTypes/hospital';

import { AxiosRequestConfig } from 'axios';
import client from './client';
import { getRestUri } from '@api/helpers';
import { Service } from '@modelTypes/service';
import { HospitalPrices } from '@modelTypes/price';
import { Price, PriceType } from '@containers/Hospitals/FormSteps/PricesStep/types';

export type HospitalRelations = { countryId: number; cityId: number; searchSectionId: number };

const getUri = getRestUri('hospitals');
const getDepartmentUri = getRestUri('hospitals/:hospitalId/departments');

const ROOT_URI = getUri('');
const DEPARTMENT_URI = getDepartmentUri('');

const GET_PRICES_URI = (clinicId: number) => `/hospitals/${clinicId}/prices`;
const POST_PRICE_URI = (clinicId: number) => `/hospitals/${clinicId}/prices`;
const GET_PRICE_DETAILS_URI = (clinicId: number, priceId: number) => `/hospitals/${clinicId}/prices/${priceId}`;
const PATCH_PRICE_DETAILS_URI = (clinicId: number, priceId: number) => `/hospitals/${clinicId}/prices/${priceId}`;
const DELETE_PRICE_DETAILS_URI = (clinicId: number, priceId: number) => `/hospitals/${clinicId}/prices/${priceId}`;
const RESTORE_PRICE_DETAILS_URI = (clinicId: number, priceId: number) => `/hospitals/${clinicId}/prices/${priceId}`;

const GET_HOSPITALS_FOR_SELECT_URI = '/hospitals/select';
const GET_DEPARTMENTS_FOR_SELECT_URI = '/departments/select';
const GET_ACCESS_KEY_URI = (id: number) => `/hospitals/${id}/access-key`;
const GET_IMAGES_URI = (hospitalId: number) => `/hospitals/${hospitalId}/images`;
const POST_IMAGES_URI = (hospitalId: number) => `/hospitals/${hospitalId}/images`;
const PATCH_IMAGES_URI = (id: number) => `/hospitals/images/${id}`;
const PUT_IMAGES_ORDER_URI = (id: number) => `/hospitals/images/${id}/order`;

const GET_HOSPITALS_CONTENTS_URI = '/hospitals/contents';
const GET_HOSPITALS_CONTENTS_BY_PLACE_URI = '/hospitals/contents/place';

const GET_DESTINATIONS_CONTENTS_URI = '/destinations/contents';
const GET_DESTINATIONS_CONTENTS_BY_PLACE_URI = '/destinations/contents/place';

const GET_SERVICES_URI = `/hospitals/services`;
const PATCH_SERVICES_URI = (hospitalId: number) => `/hospitals/${hospitalId}/services`;

export interface UrlParams extends Record<string, string | number> {
  id: number;
  hospitalId: number;
}

export async function getHospitals(payload?: AntGetListParams) {
  return client.get<ListResponse<Hospital>>(ROOT_URI.GET(), payload);
}

export async function getHospitalsForSelect(payload?: GetListParams) {
  return client.get<ListResponse<Hospital>>(GET_HOSPITALS_FOR_SELECT_URI, payload);
}

export async function getHospital(id: number, params?: Params) {
  return client.get<Hospital>(ROOT_URI.SHOW({ id }), params);
}

export async function getHospitalAccessKey(id: number) {
  return client.get<HospitalAccessKey>(GET_ACCESS_KEY_URI(id));
}

export async function createHospital(payload: CreateHospitalRequest) {
  return client.post<CreateHospitalRequest, Hospital>(ROOT_URI.POST(), payload);
}

export async function editHospital(id: number, payload: CreateHospitalRequest) {
  return client.patch<CreateHospitalRequest, Hospital>(ROOT_URI.PATCH({ id }), payload);
}

export async function deleteHospital(id: number, payload?: AxiosRequestConfig) {
  return client.del(ROOT_URI.DELETE({ id }), payload);
}

// Departments.
export async function getDepartments(hospitalId: number, payload?: AntGetListParams) {
  return client.get<ListResponse<HospitalDepartment>>(DEPARTMENT_URI.GET({ hospitalId }), payload);
}

export async function getDepartmentsForSelect(payload?: GetListParams, filters?: Record<string, string>) {
  return client.get<ListResponse<HospitalDepartment>>(GET_DEPARTMENTS_FOR_SELECT_URI, { ...payload, ...filters });
}

export async function getDepartment(urlParams: UrlParams, params?: Params) {
  return client.get<HospitalDepartment>(DEPARTMENT_URI.SHOW(urlParams), params);
}

export async function createDepartment(hospitalId: number, payload: CreateDepartmentRequest) {
  return client.post<CreateDepartmentRequest, CreateDepartmentResponse>(DEPARTMENT_URI.POST({ hospitalId }), payload);
}

export async function updateDepartment(urlParams: UrlParams, payload: UpdateDepartmentRequest) {
  return client.patch<UpdateDepartmentRequest, UpdateDepartmentResponse>(DEPARTMENT_URI.PATCH(urlParams), payload);
}

export async function deleteDepartment(id: number, payload?: AxiosRequestConfig) {
  return client.del(DEPARTMENT_URI.DELETE({ id }), payload);
}

// export async function updateCity(id: number, payload: UpdateCityRequest) {
//   return client.patch<UpdateCityRequest, UpdateCityResponse>(ROOT_URI.PATCH({ id }), payload);
// }

export async function getHospitalImages(id: number) {
  return client.get<any>(GET_IMAGES_URI(id));
}

export async function createHospitalImages(id: number, payload: FormData) {
  const config = {
    'content-type': 'multipart/form-data',
  };

  return client.post<FormData, any>(POST_IMAGES_URI(id), payload, config);
}

export async function updateHospitalImage(id: number, payload: UpdateHospitalImageRequest) {
  return client.patch<UpdateHospitalImageRequest, UpdateHospitalImageResponse>(PATCH_IMAGES_URI(id), payload);
}

export async function updateHospitalImageOrder(id: number, payload: UpdateHospitalImageOrderRequest) {
  return client.patch<UpdateHospitalImageOrderRequest, UpdateHospitalImageOrderResponse>(
    PUT_IMAGES_ORDER_URI(id),
    payload
  );
}

export async function deleteHospitalImage(id: number, payload?: AxiosRequestConfig) {
  return client.del(PATCH_IMAGES_URI(id), payload);
}

// Hospitals Content.
export async function getHospitalsContents(payload?: Params) {
  return client.get<any>(GET_HOSPITALS_CONTENTS_URI, payload);
}

export async function getHospitalsContentsByPlace(relations: HospitalRelations, payload?: Params) {
  return client.get<any>(GET_HOSPITALS_CONTENTS_BY_PLACE_URI, { relations, ...payload });
}

export async function updateHospitalContentByPlace(payload?: CreateHospitalContentRequest) {
  return client.patch<CreateHospitalContentRequest, any>(GET_HOSPITALS_CONTENTS_BY_PLACE_URI, payload);
}

// Destinations Content

export async function getDestinationsContents(payload?: Params) {
  return client.get<any>(GET_DESTINATIONS_CONTENTS_URI, payload);
}

export async function getDestinationContentsByPlace(relations: DestinationPlace, payload?: Params) {
  return client.get<any>(GET_DESTINATIONS_CONTENTS_BY_PLACE_URI, { relations, ...payload });
}

export async function updateDestinationsContentByPlace(payload?: CreateDestinationsContentRequest) {
  return client.patch<CreateDestinationsContentRequest, any>(GET_DESTINATIONS_CONTENTS_BY_PLACE_URI, payload);
}

// Hospital Services.

export async function getHospitalServices(params?: Params) {
  return client.get<ListResponse<Service>>(GET_SERVICES_URI, params);
}

export async function updateHospitalServices(hospitalId: number, params?: Params) {
  return client.patch<any, any>(PATCH_SERVICES_URI(hospitalId), params);
}

export class HospitalRelation {}

// Hospital prices

export async function getClinicPrices(
  clinicId: number,
  options?: { type?: PriceType; parentId?: number | null; search?: string }
) {
  return client.get<ListResponse<Price>>(GET_PRICES_URI(clinicId), { ...options });
}

export async function createClinicPrice(
  clinicId: number,
  body: Omit<Price, 'id' | 'currency'> & { currencyId?: number | null }
) {
  return client.post(POST_PRICE_URI(clinicId), body);
}

export async function getClinicPriceDetails(clinicId: number, priceId: number) {
  return client.get(GET_PRICE_DETAILS_URI(clinicId, priceId));
}

export function updatePriceDetails(
  clinicId: number,
  priceId: number,
  body: Partial<Omit<Price, 'id' | 'currency'> & { currencyId: number | null }>
) {
  return client.patch(PATCH_PRICE_DETAILS_URI(clinicId, priceId), body);
}

export async function deleteClinicPrice(clinicId: number, priceId: number) {
  return client.del(DELETE_PRICE_DETAILS_URI(clinicId, priceId));
}

export async function restoreClinicPrice(clinicId: number, priceId: number) {
  return client.post(RESTORE_PRICE_DETAILS_URI(clinicId, priceId));
}
