import { AntGetListParams, FilterCondition, GetListParams } from '@api/types';
import { ColumnFilterItem, ColumnsType, SortOrder, TablePaginationConfig } from 'antd/es/table/interface';

// import { ColumnsEntities } from 'components/ColumnsVisibility/helpers';
import React from 'react';

// import { FilterCondition, GetListParams } from 'api/types';


export type BaseRow = Record<string, any>;

export type SortParams = { field: string | undefined, order: SortOrder | undefined };
export type PaginationParams = { perPage: number, page: number };
export type ScrollParams = {
  x?: string | number | true | 'auto';
  y?: string | number;
  scrollToFirstRowOnChange?: boolean;
  bottomHeightOverheads?: Array<number>; // uses for auto calculate of grid height; not works with `y` param;
};

export enum FilterType {
  SEARCH = 'SEARCH',
  MULTISELECT = 'MULTISELECT',
  DATE = 'DATE',
  DATE_RANGE = 'DATE_RANGE',
  DURATION = 'DURATION'
}

export type FilterParams = {
  type: FilterType.SEARCH,
  value: string | undefined,
} | {
  type: FilterType.MULTISELECT,
  value: Array<string> | undefined,
} | {
  type: FilterType.DATE,
  value: string | undefined,
} | {
  type: FilterType.DATE_RANGE,
  value: [string, string] | undefined,
} | {
  type: FilterType.DURATION,
  value: string | undefined,
  condition: FilterCondition
};
export type FiltersParams = Record<string, FilterParams>;

export type FilterResult = { field: string, value: string | Array<string> };


export type FiltersConfig = Record<string, {
  filterType: FilterType;
  filterOptions?: Array<ColumnFilterItem>; // only for the FilterType.MULTISELECT
}>;

export type ExpandableColumnsType<T> = T extends BaseRow ? T : undefined;
export type GridInstance = {
  refreshData: () => void;
  resetAllFilters: () => void
};

export type GridProps<Row extends BaseRow> = {
  id?: string;
  className?: string;
  // columnsEntity?: ColumnsEntities;
  loading?: boolean;
  data: Array<Row>;
  columns: ColumnsType<Row>; //FYI: it's too difficult to create override for the columns type

  enableFiltering?: boolean;
  enableSorting?: boolean;
  enableSelection?: boolean;

  filtersConfig?: FiltersConfig;
  paginationConfig?: PaginationConfig;

  selection?: Record<string, any>;
  scroll?: ScrollParams;

  expandableRowRenderer?: (row: Row, index: number, indent: number, expanded: boolean) => React.ReactNode;
  expandableRow?: boolean | ((row: Row) => boolean);

  onFetch?: (params: AntGetListParams) => void;

  instance?: (gridInstance: GridInstance) => void;
  header?: (data: readonly Row[]) => React.ReactNode;
  footer?: (data: readonly Row[]) => React.ReactNode;
  customPage?: number;
  customPerPage?: number;
};

export type NestedGridProps<ParentRow extends BaseRow, Row extends BaseRow> = Omit<GridProps<Row>, 'onFetch' | 'data'> & {
  onFetch: (parentRow: ParentRow, params: AntGetListParams) => void;
  data: Record<number, {
    loading: boolean,
    data: Array<Row>
  }>;
};

export type GetPreparedColumnsParams<Row extends BaseRow> = {
  enableFiltering: boolean,
  enableSorting: boolean,
  columns: ColumnsType<Row>,
  filter: FiltersParams,
  filtersConfig?: FiltersConfig,
  onFilter: (field: string, params: FilterParams) => void,
  onResetFilter: (field: string) => void,
  columnsVisibility?: Record<string, boolean>,
};

export type GetFilterParams<Row extends BaseRow> = Pick<GetPreparedColumnsParams<Row>, 'onFilter' | 'onResetFilter'> & {
  column: string,
  columnTitle: string,
  value?: string | Array<string>,
};

export type PaginationConfig = Omit<TablePaginationConfig, 'total'> & {
  serverSide?: boolean;
  showCounter?: boolean;
  total?: number | null;
  selected?: number | null;
  filtered?: number | null;
};

// some duplicates from rc-table - keep updated!

export type TriggerEventHandler<RecordType> = (record: RecordType, event: React.MouseEvent<HTMLElement>) => void;
export interface RenderExpandIconProps<RecordType> {
  prefixCls: string;
  expanded: boolean;
  record: RecordType;
  expandable: boolean;
  onExpand: TriggerEventHandler<RecordType>;
}
