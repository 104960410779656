import { create } from 'zustand';
import produce from 'immer';
import { CreateTemplateRequest, Params, UpdateTemplateRequest } from '@api/types';
import { initialFormState } from '@zustandStorage/helpers/initialStates';
import { getTemplate, createTemplate, updateTemplate } from '@api';
import { Template } from '@modelTypes/template';


export interface LegalsState {
  form: {
    loading: boolean;
    row: Template | null;
  };
  clearFormData: () => void;
  fetchLegalsTemplate: (id: number, params?: Params) => Promise<Template>;
  createLegalsTemplate: (payload: CreateTemplateRequest) => Promise<Template>;
  updateLegalsTemplate: (id: number, payload: UpdateTemplateRequest) => Promise<Template>;
}

export const useLegalStore = create<LegalsState>((set) => ({
  form: initialFormState,
  clearFormData: () => set(() => ({ form: initialFormState })),
  fetchLegalsTemplate: async (id: number, params?: Params): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return getTemplate(id, params)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  createLegalsTemplate: async (payload: CreateTemplateRequest): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return createTemplate(payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateLegalsTemplate: async (id: number, payload: UpdateTemplateRequest): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateTemplate(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
}));