import { Checkbox, Form, Input, message, Modal } from 'antd';
import 'cropperjs/dist/cropper.css';
import * as React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { CreateDoctorRequest, CreateGalleriesVideoRequest } from '@api/types';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  handleSaveForm: (formData: CreateGalleriesVideoRequest) => any;
  updateGrid: () => void;

}

export default function AddVideoGalleriesModal({ isOpen, closeModal, handleSaveForm, updateGrid }: ModalProps) {
  const { confirm } = Modal;
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm<CreateDoctorRequest>();

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const showConfirm = () => {
    confirm({
      title: 'Data not saved!',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to close modal window?',
      onOk() {
        closeModal()
        resetForm()
      },
      // onCancel() {
      //
      // },
    });
  };

  const handleCloseModal = () => {
    if (formChanged) {
      showConfirm()
    } else {
      closeModal()
      resetForm()
    }
  }

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving video link...',
      duration: 0,
    });

    const formData = form.getFieldsValue(true);
    const regexMatch = formData.path.match(/v=([^&]+)/);
    const videoId = regexMatch ? regexMatch[1] : '';

    await handleSaveForm({ ...formData, path: videoId })
      .then((data: any) => {
        messageApi.destroy('startCreate');
        message.success('The video link has been successfully created');
        resetForm();
        closeModal()
        updateGrid();
      })
      .catch((e: any) => {
        messageApi.destroy('startCreate');
        message.error('An error occurred, please try again now')
      })
  };

  return (
    <Modal
      open={isOpen}
      title={<div>Add video for gallery</div>}
      onCancel={handleCloseModal}
      onOk={form.submit}
      bodyStyle={{ maxHeight: '70vh' }}
      width={510}
      okText={'Save'}
    >
      {contextHolder}
      <Form
        form={form}
        layout="vertical"
        onFinish={handleOnFinish}
        onFieldsChange={handleFieldsChange}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        id={'test'}>
        <Form.Item
          name="path"
          label="Link"
          messageVariables={{ name: 'Link' }}
          rules={[{ required: true, type: 'string' }]}
          initialValue={''}
          style={{ width: 460, margin: '16px 0 0 0' }}
        >
          <Input
            placeholder="Enter link"
          />
        </Form.Item>
        <Form.Item
          name="name"
          label="Caption"
          messageVariables={{ name: 'Caption' }}
          rules={[{ type: 'string', max: 100 }]}
          initialValue={''}
          style={{ width: 460, margin: '16px 0 0 0' }}
        >
          <Input
            placeholder="Enter video caption"
          />
        </Form.Item>
        <Form.Item
          name="isSensitive"
          valuePropName="checked"
          initialValue={false}
          style={{ margin: '16px 0 0 0' }}
        >
          <Checkbox>Sensitive content</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}