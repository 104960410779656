import React from 'react';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const HospitalSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    x="0"
    y="0"
    enableBackground="new 0 0 502.001 502.001"
    version="1.1"
    viewBox="0 0 502.001 502.001"
    xmlSpace="preserve"
  >
    <path d="M11.97 201.522l-8 6.049c-4.406 3.331-5.277 9.602-1.946 14.007a9.983 9.983 0 007.984 3.97 9.95 9.95 0 006.023-2.024l8-6.049c4.406-3.331 5.277-9.602 1.946-14.007-3.331-4.407-9.603-5.278-14.007-1.946zM498.032 207.57l-60.705-45.899-180.295-136.32a10 10 0 00-12.062 0L36.434 183.024c-4.405 3.331-5.276 9.602-1.945 14.008s9.603 5.277 14.008 1.945l12.209-9.232v278.928c0 5.523 4.477 10 10 10h360.589c5.522 0 10-4.477 10-10V189.746l44.674 33.778a9.954 9.954 0 006.023 2.024 9.983 9.983 0 007.984-3.969c3.332-4.406 2.461-10.677-1.944-14.009zm-76.736 251.104H80.706v-284.05l170.295-128.76 170.295 128.759v284.051z"/>
    <path d="M246.023 71.149c-3.331 4.405-2.46 10.677 1.945 14.008l20.924 15.821a9.954 9.954 0 006.023 2.024 9.983 9.983 0 007.984-3.969c3.331-4.405 2.46-10.677-1.945-14.008L260.03 69.204c-4.404-3.332-10.676-2.46-14.007 1.945zM293.968 119.938l99 74.854a9.954 9.954 0 006.023 2.024 9.983 9.983 0 007.984-3.969c3.331-4.406 2.46-10.677-1.945-14.008l-99-74.854c-4.405-3.331-10.678-2.458-14.008 1.945-3.33 4.406-2.459 10.677 1.946 14.008zM363.001 243.401h-61.4v-61.4c0-5.523-4.478-10-10-10h-81.2c-5.523 0-10 4.477-10 10v61.4h-61.4c-5.523 0-10 4.477-10 10v81.2c0 5.523 4.477 10 10 10h61.4v61.4c0 5.523 4.477 10 10 10h81.2c5.522 0 10-4.477 10-10v-61.4h61.4c5.522 0 10-4.477 10-10v-81.2c0-5.523-4.478-10-10-10zm-10 81.2h-61.4c-5.522 0-10 4.477-10 10v61.4h-61.2v-61.4c0-5.523-4.477-10-10-10h-61.4v-61.2h61.4c5.523 0 10-4.477 10-10v-61.4h61.2v61.4c0 5.523 4.478 10 10 10h61.4v61.2z"/>
  </svg>
);

export default function HospitalIcon(props: Partial<CustomIconComponentProps>) {
  return (
    <Icon
      component={HospitalSvg}
      {...props}
    />
  );
}
