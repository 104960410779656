import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { PaginationConfig } from '@components/AntGrid/types';
import { certificateColumns, certificateFiltersConfig } from '@components/AntGrid/columns/certificate';
import { Certificate } from '@modelTypes/certificate';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeGridOptions from '@components/AntGrid/hooks/useChangeGridOptions';
import { useCertificatesStore } from '@zustandStorage/certificates/certificates';

export const [
  refreshCertificatesGridEvent,
  dispatchRefreshCertificatesGridEvent
] = makeRefreshGridEvent('onRefreshCertificatesGrid');

export default function CertificatesAntGrid() {

  const { fetchCertificates, grid: { data, meta, loading } } = useCertificatesStore()
  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
    // ...rest.paginationConfig,
  };

  const onFetchData = (params?: AntGetListParams) => {
    fetchCertificates(params)
  };

  useRefreshGridEvent(refreshCertificatesGridEvent, onFetchData);
  useChangeGridOptions('certificatesAntGrid');

  return (
    <Grid<Certificate>
      id="doctorsGrid"
      onFetch={onFetchData}
      columns={certificateColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={certificateFiltersConfig}
    />
  );
}