import { create } from 'zustand';
import { initialSelectState } from '@zustandStorage/helpers/initialStates';
import produce from 'immer';
import { getRolesForSelect } from '@api';
import { Role } from '@modelTypes/role';

export interface UserRolesState {
  select: {
    loading: boolean;
    search: string;
    data: Array<Role>;
  },
  fetchUserRolesForSelect : any,
}

export const useUserRolesStore = create<UserRolesState>((set) => ({
  select: initialSelectState,
  fetchUserRolesForSelect: async (params?: any): Promise<any> => {
    set(produce((state) => {
      state.select.loading = true;
    }));

    return getRolesForSelect(params)
      .then((response) => {
        set(produce((state) => {
          state.select.data = response.data;
          state.select.loading = false;
        }));

        return response;
      });
  },
}));