import './Editor.scss';

import { debounce, DebouncedFunc, isEmpty, random } from 'lodash';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import React, { FocusEventHandler } from 'react';

import { BASE_URL } from '@api/client';
import ButtonsPlugin from '@components/Editor/CustomPlugins/ButtonsPlugin';
// @ts-ignore
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document/build/ckeditor';
import IconsPlugin from '@components/Editor/CustomPlugins/IconsPlugin/IconsPlugin';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import isEqual from 'lodash/isEqual';
import ActionsPlugin from '@components/Editor/CustomPlugins/ActionsPlugin';

export interface EditorProps {
  readonly defaultValue?: string;
  readonly onChange?: (value: string, name?: string) => void;
  readonly label?: string;
  readonly entityName: string;
  readonly name?: string;
  readonly error?: boolean;
  readonly helperText?: React.ReactNode;
  readonly onBlur?: FocusEventHandler<any>;
  readonly sx?: SxProps<Theme>,
}


export default class Editor extends React.Component<EditorProps>{

  private debouncedOnChange: DebouncedFunc<(newValue: string) => void>;
  private editor: any;
  private toolbarId: string;
  private customToolbarButtonsId: string;
  private editableAreaId: string;

  constructor(props: EditorProps) {
    super(props);

    this.toolbarId = `toolbar${props.entityName}${random(1, 1000)}`;
    this.customToolbarButtonsId = `customToolbar${props.entityName}${random(1, 1000)}`;
    this.editableAreaId = `editableArea${props.entityName}${random(1, 1000)}`;

    this.debouncedOnChange = debounce((newValue: string) => props.onChange && props.onChange(newValue, props.name), 300);
  }

  componentDidMount() {
    this.createEditor();
  }

  componentWillUnmount() {
    if (this.editor) {
      this.editor.destroy()
        .then(() => {
          // Remove the toolbar from DOM.
          this.editor.ui.view.toolbar.element.remove();
          // Remove the editable from DOM.
          this.editor.ui.view.editable.element.remove();

          console.log('Editor was destroyed');
        });
    }
  }

  appendToEditor = (text: string) => {
    try {
      const viewFragment = this.editor.data.processor.toView(text);
      const modelFragment = this.editor.data.toModel(viewFragment);
      this.editor.model.insertContent(modelFragment, this.editor.model.document.selection);
    } catch (error) {
      console.log('Error in AppendToRichTextEditor ' + error);
    }
  };

  createEditor = async () => {
    const toolbarContainer = document.getElementById(this.toolbarId);

    DecoupledEditor.defaultConfig = {
      ...DecoupledEditor.defaultConfig,
      ckfinder: {
        uploadUrl: `${BASE_URL}ckfinder/upload?entity=${this.props.entityName}`,
      }
    }

    this.editor = await DecoupledEditor.create(document.getElementById(this.editableAreaId));
    // this.editor.model.schema.register( 'button', {
    //   inheritAllFrom: '$block',
    //   allowChildren: '$text',
    // } );
    // this.editor.conversion.elementToElement( {
    //   model: 'button',
    //   view: 'button'
    // } );
    this.editor.append = this.appendToEditor;

    // @ts-ignore
    toolbarContainer.appendChild(this.editor.ui.view.toolbar.element);
    // // @ts-ignore
    // window.editor = this.editor;

    // console.log('Editor, ', 'createEditor --- ', this.props.defaultValue);

    if (!isEmpty(this.props.defaultValue)) {
      this.editor.setData(this.props.defaultValue);
    }

    this.editor.model.document.on('change:data', () => {
      this.debouncedOnChange(this.editor.getData());
    });

    console.log('Editor, ', 'createEditor --- ', this.editor);
  };

  componentDidUpdate(prevProps: Readonly<EditorProps>) {
    if (typeof this.props.defaultValue === 'string'
      // && !isEmpty(this.props.defaultValue)
      && this.props.defaultValue !== "<p></p>\n"
      && !isEqual(prevProps.defaultValue, this.props.defaultValue)
      && !isEqual(this.props.defaultValue, this.editor.getData())
      && this.editor
    ) {
      this.editor.setData(this.props.defaultValue);
      this.props.onChange && this.props.onChange(this.props.defaultValue as string, this.props.name);
    }
  }

  // handleOnBlur = (e: any) => {
  //   if (!e.currentTarget.contains(e.relatedTarget)) {
  //     console.log('Editor, ', 'handleOnBlur --- 111');
  //   }
  //
  //   console.log('Editor, ', 'handleOnBlur --- ', e);
  // };

  render() {
    const { error, sx, label, helperText, onBlur } = this.props;

    return (
      <FormControl
        sx={sx}
        variant="standard"
        error={error}
        fullWidth
        onBlur={onBlur}
      >
        {label && <FormLabel component="legend" sx={{ mb: 1 }}>{label}</FormLabel>}
        <div className="document-editor" >
          <div className="document-editor__toolbar">
            <div id={this.customToolbarButtonsId} className="custom">
              <IconsPlugin editor={this.editor} />
              <ButtonsPlugin editor={this.editor} />
              <ActionsPlugin editor={this.editor} />
            </div>
            <div id={this.toolbarId} className="original" />
          </div>
          <div className="document-editor__editable-container">
            <div id={this.editableAreaId} className="document-editor__editable" />
          </div>
        </div>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
}
