import { FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import React from 'react';
import { Image, Space } from 'antd';
import getIdCell from '@components/AntGrid/cells/IdCell';
import { Language } from '@modelTypes/language';
import { getIsActiveCell } from '@components/AntGrid/cells/StatusCell';

export type SettingColumnsNames = `${keyof Language}Column` | 'editColumn';

export const settingColumnsDefinitions: Partial<Record<SettingColumnsNames, ColumnType<Language>>> = {
  idColumn: createColumnDefinition<Language>('#', 'id', { width: 80, render: getIdCell }),
  nameColumn: createColumnDefinition<Language>('Name', 'name', { render: getNameCell }),
  isActiveColumn: createColumnDefinition<Language>('Active', 'isActive', {
    render: getIsActiveCell,
    width: 160,
    filtered: false
  }),
  isUseForTranslationColumn: createColumnDefinition<Language>('Use For Translation', 'isUseForTranslation', {
    render: getIsActiveCell,
    width: 160,
    filtered: false
  }),
  // editColumn: {
  //   title: '',
  //   width: 104,
  //   fixed: 'right',
  //   render: (value: any, row: Language) => {
  //     return <EditCell record={row} />
  //   },
  //   sorter: false,
  //   filtered: false,
  //   className: 'no-padding',
  // },
};

export const languagesColumns: any = [
  settingColumnsDefinitions.idColumn,
  settingColumnsDefinitions.nameColumn,
  settingColumnsDefinitions.isActiveColumn,
  settingColumnsDefinitions.isUseForTranslationColumn,
  // settingColumnsDefinitions.editColumn,
  //
];

export const languagesFiltersConfig: FiltersConfig = {
  [settingColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [settingColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
};


function getNameCell() {
  return (value: any, record: Language) => {
    return <NameCell record={record} />
  };
}

function NameCell({ record }: { record: Language }) {
  return (
    <Space size={'middle'}>
      <Image
        preview={false}
        width={24}
        height={16}
        src={`${process.env.REACT_APP_BUCKET_URL}/icons/country-flags/${record.countryCode}.svg`}
        alt={record.name + ' flag'}
      />
      {record.name}
    </Space>
  );
}


