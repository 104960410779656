import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M5.16428 12.8794C4.07299 11.8768 2.94443 10.8399 2.16591 9.33556C1.27857 7.62172 0.981648 5.52183 1.25758 2.91551C1.27386 2.76169 1.2293 2.61087 1.13204 2.4909C1.03478 2.37093 0.896813 2.29553 0.742996 2.27924C0.589179 2.26296 0.438789 2.30752 0.318391 2.40478C0.197994 2.50204 0.123013 2.64001 0.106732 2.79382C-0.193619 5.63238 0.143579 7.94606 1.13804 9.86728C2.01081 11.5528 3.27006 12.7101 4.38148 13.7311C5.4355 14.6995 6.34555 15.5354 6.84899 16.6588C7.40127 17.8915 7.38628 19.3508 6.80057 21.2511C6.70674 21.5557 6.87812 21.88 7.18319 21.9743C7.23889 21.9914 7.29587 22 7.35372 22C7.60908 22 7.83102 21.8359 7.90643 21.5917C8.5834 19.3941 8.58211 17.6713 7.90172 16.1699C7.30016 14.8417 6.26285 13.8888 5.16428 12.8798V12.8794Z"
    fill="currentColor" />
  <path
    d="M21.4646 13.5829C20.9702 11.037 19.6788 8.5699 18.01 6.98288C16.9294 5.95544 15.7421 5.35602 14.5758 5.25019C13.2326 5.12851 11.9798 5.63195 10.8525 6.7468C8.86404 8.71301 7.79032 8.86768 7.23974 8.65131C6.64718 8.41823 6.18787 7.61444 5.87467 6.26221C5.56704 4.93484 5.46892 3.34011 5.41793 2.09929C5.4115 1.94376 5.34509 1.80065 5.23112 1.69611C5.11758 1.59199 4.97062 1.53886 4.81637 1.54529C4.66213 1.55172 4.51945 1.6177 4.41447 1.73124C4.30993 1.84478 4.25552 1.9926 4.26194 2.14685C4.39819 5.45199 4.80823 8.93752 6.81643 9.7276C8.10009 10.2328 9.68668 9.52708 11.6662 7.56945C12.1285 7.11228 12.88 6.54114 13.8363 6.41475C14.9422 6.26864 16.078 6.74209 17.213 7.82138C18.7143 9.24901 19.8789 11.4851 20.3292 13.8035C20.8151 16.3075 20.3918 18.4596 19.1364 19.8637C19.0336 19.979 18.9813 20.1272 18.9898 20.2814C18.9984 20.4357 19.0665 20.5775 19.1818 20.6803C19.2881 20.7755 19.4247 20.8277 19.5674 20.8277C19.7319 20.8277 19.8892 20.7575 19.9989 20.6349C21.5011 18.9549 22.0216 16.4506 21.4651 13.5833L21.4646 13.5829Z"
    fill="currentColor" />
  <path
    d="M3.63468 9.72589C3.62439 9.81415 3.62268 9.90756 3.63039 10.0035C3.66081 10.3891 3.83177 10.7975 4.11198 11.1535C4.3922 11.5096 4.74867 11.7722 5.11629 11.8922C5.25897 11.9389 5.39693 11.9625 5.52676 11.9625C5.79497 11.9625 5.97579 11.8643 6.08033 11.7816C6.22301 11.6694 6.45652 11.4016 6.41453 10.8707C6.38411 10.4851 6.21315 10.0768 5.93294 9.72075C5.65273 9.3647 5.29625 9.10205 4.92863 8.98208C4.77138 8.93067 4.62014 8.9071 4.47789 8.9131C3.34247 7.17655 3.42987 3.03976 3.76964 0.660091C3.79149 0.50713 3.7525 0.355027 3.65996 0.231202C3.56741 0.107805 3.43201 0.0276829 3.27905 0.00583145C2.96328 -0.0391569 2.66978 0.181072 2.62479 0.496847C2.54596 1.04828 2.29831 3.01791 2.41999 5.20692C2.53482 7.27167 2.94357 8.79185 3.63468 9.72632V9.72589ZM4.66255 9.97997C4.8198 10.0468 4.98904 10.1843 5.12486 10.357C5.26068 10.5297 5.35452 10.7268 5.38237 10.8952C5.22512 10.8283 5.05588 10.6908 4.92006 10.5181C4.78424 10.3454 4.69083 10.1488 4.66255 9.97997Z"
    fill="currentColor" />
  <path
    d="M6.73502 7.17441C6.80057 7.19883 6.8687 7.21125 6.93811 7.21125C7.17805 7.21125 7.3957 7.06044 7.47968 6.83592L7.85716 5.82904C7.91157 5.68422 7.906 5.5274 7.84216 5.38644C7.77832 5.24591 7.66349 5.13836 7.51867 5.08438C7.22004 4.97255 6.88584 5.12422 6.77401 5.42286L6.39653 6.42974C6.34212 6.57456 6.34769 6.73138 6.41153 6.87234C6.47537 7.01288 6.5902 7.12042 6.73502 7.17441Z"
    fill="currentColor" />
  <path
    d="M2.07294 12.2658C1.93626 11.9779 1.59049 11.8549 1.30214 11.9912L0.330388 12.4522C0.0424627 12.5889 -0.080934 12.9346 0.0557449 13.223C0.150863 13.4239 0.356096 13.5533 0.578467 13.5533C0.664587 13.5533 0.747709 13.5345 0.826117 13.4972L1.79787 13.0362C2.08579 12.8995 2.20919 12.5537 2.07251 12.2654L2.07294 12.2658Z"
    fill="currentColor" />
  <path
    d="M7.41199 7.3278C7.37985 7.47904 7.40856 7.63329 7.49296 7.76311C7.60008 7.92807 7.78175 8.02619 7.97841 8.02619C8.09024 8.02619 8.19907 7.99405 8.29333 7.93278L9.19524 7.34665C9.32463 7.26267 9.41375 7.13285 9.44589 6.9816C9.47802 6.83035 9.44932 6.67611 9.36491 6.54628C9.19095 6.27892 8.83233 6.20266 8.56454 6.37661L7.66264 6.96275C7.53324 7.04673 7.44412 7.17655 7.41199 7.3278Z"
    fill="currentColor" />
  <path
    d="M2.64279 13.1356C2.50654 13.0623 2.35015 13.0469 2.20233 13.0914C2.05451 13.136 1.93283 13.2358 1.85999 13.3721L1.35184 14.3198C1.279 14.4561 1.26315 14.6125 1.30814 14.7603C1.35312 14.9081 1.45253 15.0298 1.58878 15.1026C1.67276 15.1476 1.76702 15.1712 1.86171 15.1712C2.07551 15.1712 2.27089 15.0542 2.372 14.8657L2.88016 13.9179C2.953 13.7817 2.96885 13.6253 2.92386 13.4775C2.8793 13.3297 2.77947 13.208 2.64322 13.1352L2.64279 13.1356Z"
    fill="currentColor" />
</svg>`;

export default { id: '255-interventional-gi', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
