import { useEffect, useState } from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { Button, Col, Form, Input, message, Row, Spin, Tooltip } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { CreateRoleRequest } from '@api/types';

import { setFormErrors } from '@api/helpers';
import { SaveOutlined } from '@ant-design/icons';
import { useGridStore } from '@zustandStorage/grid/grid';
import QueryString from 'query-string';
import { useRoleStore } from '@zustandStorage/role/role';
import RolesPermissions from '@containers/Roles/components/RolesPermissionsAnt';

export default function RoleCreate() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [messageApi, contextHolder] = message.useMessage();
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
  const [permissionsIds, setPermissionsIds] = useState<any>([])
  const [form] = Form.useForm<CreateRoleRequest>();
  const {
    fetchRole,
    createRole,
    updateRole,
    fetchPermission,
    clearFormData,
    form: { row, loading }
  } = useRoleStore();

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving role...',
      duration: 0,
    });

    setFormSubmitting(true);
    const fieldValue = form.getFieldsValue();
    const formData = { ...fieldValue, permissionsIds };
    if (id) {
      await updateRole(parseInt(id, 10), formData)
        .then(() => {
          messageApi.destroy('startCreate');
          message.success('The role has been successfully updated');
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          message.error('An error occurred, please try again now')
          setFormSubmitting(false);
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    } else {
      await createRole(formData)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The role has been successfully created');
          history.push(`/roles/${data.id}/edit`);
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          message.error('An error occurred, please try again now');
          setFormSubmitting(false);
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    }
  };


  useEffect(() => {
    fetchPermission();

    if (id) {
      fetchRole(+id)
        .then(() => {
          resetForm()
        })
    }
  }, [id])

  useEffect(() => {
    return () => {
      clearFormData();
    }
  }, [])
  console.log(permissionsIds)

  return (
    <>
      <Prompt
        when={!formSubmitting && formChanged}
        message={(location) =>
          `Data not saved!\nAre you sure you want to go to ${location.pathname}?`
        }
      />
      {contextHolder}
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.usersAntGrid || {};
          history.push(`/roles?${QueryString.stringify(params)}`)
        }}
        title={`${row ? 'Edit' : 'Create'} Role`}
        extra={[
          <Button
            key="save"
            type="primary"
            loading={formSubmitting}
            onClick={() => form.submit()}
          >
            {id ? 'Update' : 'Create'}
          </Button>
        ]}
      />
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          autoComplete="off"
          style={{ paddingBottom: 32 }}
        >
          <Row gutter={[32, 24]}>
            <Col span={16}>
              <Form.Item
                name="slug"
                label="Slug"
                messageVariables={{ name: 'Name' }}
                rules={[{ required: true, type: 'string', max: 255 }]}
                initialValue={row?.slug}
              >
                <Input placeholder="Slug" />
              </Form.Item>
              <Form.Item
                name="name"
                label="Name"
                messageVariables={{ name: 'Email' }}
                rules={[{ type: 'string', required: true, max: 255 }]}
                initialValue={row?.name}

              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                initialValue={row && row?.description ? row.description : undefined}
                name="description"
                label="Description"
              >
                <Input placeholder="Description" />

              </Form.Item>
              <RolesPermissions
                defaultValue={row && row.permissions ? row.permissions : []}
                onChange={(ids: Array<number>) => setPermissionsIds(ids)} />
            </Col>
          </Row>
        </Form>
      </Spin>
      <Tooltip title={id ? 'Update' : 'Create'} placement="topRight">
        <Button
          style={{ position: 'fixed', bottom: 20, right: 16 }}
          onClick={() => form.submit()}
          shape="circle"
          icon={<SaveOutlined />}
          size="large"
        />
      </Tooltip>
    </>
  );
}
