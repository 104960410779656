import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks';
import { PaginationConfig } from '@components/AntGrid/types';
import { authorColumns, authorFiltersConfig } from '@components/AntGrid/columns/author';
import { AuthorsState } from '@storage/authors/reducer';
import { getAuthors } from '@storage/authors/thunkActions';
import { Author } from '@modelTypes/author';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeGridOptions from "@components/AntGrid/hooks/useChangeGridOptions";

export const [refreshAuthorsGridEvent, dispatchRefreshAuthorsGridEvent] = makeRefreshGridEvent('onRefreshAuthorsGrid');

export default function AuthorsGrid() {
  const dispatch = useDispatch();
  const { data, meta, loading } = useSelector<AuthorsState['grid']>('authors.grid');
  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
  };

  const onFetchData = (params?: AntGetListParams) => {
    dispatch(getAuthors(params));
  };

  useRefreshGridEvent(refreshAuthorsGridEvent, onFetchData);
  useChangeGridOptions('authorsAntGrid');

  return (
    <Grid<Author>
      id="authorsGrid"
      onFetch={onFetchData}
      columns={authorColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={authorFiltersConfig}
    />
  );
}