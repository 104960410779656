import { ActionType, Actions } from './actions';
import { Blog, BlogTypes } from '@modelTypes/blog';
import { Template, TemplateTypes } from '@modelTypes/template';

import { ListResponse } from '@api/types';
import { SearchSynonym } from '@modelTypes/searchSynonym';

export interface TemplatesState {
  // readonly hospital: {
  //   readonly row: Hospital | null;
  //   readonly loading: boolean;
  // }
  readonly grid: ListResponse<Template> & {
    readonly type: TemplateTypes | null;
    readonly loading: boolean;
  }
  readonly form: {
    readonly type: TemplateTypes | null;
    readonly loading: boolean;
    readonly row: Template | null;
  };
  // readonly selectBlogs: {
  //   readonly loading: boolean;
  //   readonly search: string | null;
  //   readonly data: Array<Blog>;
  // };
}

const initialState: TemplatesState = {
  // hospital: {
  //   loading: false,
  //   row: null,
  // },
  grid: {
    type: null,
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  form: {
    type: null,
    loading: false,
    row: null,
  },
};

export default function blogs(state = initialState, action: Actions): TemplatesState {
  switch (action.type) {
    case ActionType.START_FETCH_TEMPLATES:
      return {
        ...state,
        grid: {
          ...state.grid,
          type: null,
          loading: true,
        }
      }
    case ActionType.END_FETCH_TEMPLATES:
      return {
        ...state,
        grid: {
          ...action.data,
          loading: false,
        },
      }
    case ActionType.START_FETCH_TEMPLATE:
    // case ActionType.START_FETCH_ACCESS_KEY:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        }
      }
    case ActionType.END_FETCH_TEMPLATE:
    case ActionType.END_POST_TEMPLATE:
    case ActionType.END_PATCH_TEMPLATE:
      return {
        ...state,
        form: {
          ...state.form,
          row: action.data,
          loading: false,
        },
      }
    case ActionType.START_POST_TEMPLATE:
    case ActionType.START_PATCH_TEMPLATE:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        },
      }
    case ActionType.END_POST_TEMPLATE_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.END_PATCH_TEMPLATE_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.CLEAR_FORM_DATA:
      return {
        ...state,
        form: {
          ...initialState.form,
        }
      }
    default:
      return state;
  }
}