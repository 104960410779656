import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg data-id="36" width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_5965_332485)">
  <mask id="path-1-outside-1_5965_332485" maskUnits="userSpaceOnUse" x="-1" y="-1" width="25"
    height="24" fill="black">
    <rect fill="white" x="-1" y="-1" width="25" height="24" />
    <path
      d="M19.14 16.4589C14.135 16.5137 7.315 16.4589 2.365 16.4589C1.485 16.4589 0.77 15.7456 0.77 14.8678V2.3591C0.77 1.4813 1.485 0.76808 2.365 0.76808H18.92C19.8 0.76808 20.515 1.4813 20.515 2.3591V2.79801C20.515 3.29177 21.285 3.29177 21.285 2.79801C21.45 1.31671 20.515 0 18.92 0H2.365C1.045 0 0 1.04239 0 2.3591V14.9227C0 16.2394 1.045 17.2818 2.365 17.2818H7.095V18.0499H5.885C4.785 18.0499 3.905 18.9277 3.905 20.0249C3.905 21.1222 4.785 22 5.885 22H15.4C16.445 22 17.38 21.0673 17.38 20.0249C17.38 18.9277 16.5 18.0499 15.4 18.0499H14.19V17.2818H18.92C19.03 17.2818 19.14 17.2818 19.25 17.2818C19.745 17.1721 19.69 16.404 19.14 16.4589ZM7.865 17.2269H13.365V18.3791C13.365 18.818 13.035 19.1471 12.595 19.1471H8.635C8.195 19.1471 7.865 18.818 7.865 18.3791V17.2269ZM15.4 18.818C16.995 18.8728 16.94 21.1222 15.4 21.1771H5.94C4.4 21.1222 4.4 18.8728 5.94 18.818H7.15C7.315 19.4763 7.92 19.9701 8.69 19.9701H12.65C13.365 19.9701 13.97 19.4763 14.19 18.818H15.4Z" />
    <path
      d="M18.9204 2.68825C18.9754 3.18202 19.7454 3.12716 19.6904 2.63339C19.6354 2.0299 19.1404 1.53613 18.5354 1.53613H2.75043C2.09043 1.53613 1.54043 2.08476 1.54043 2.68825V14.4289C1.54043 15.0873 2.09043 15.581 2.75043 15.581H17.0504C17.2704 15.581 17.4354 15.4164 17.4354 15.197C17.4354 14.9775 17.2704 14.8129 17.0504 14.8129H2.75043C2.53043 14.8129 2.36543 14.6484 2.36543 14.4289V2.74312C2.36543 2.52366 2.53043 2.35908 2.75043 2.35908H18.5354C18.7554 2.35908 18.9204 2.52366 18.9204 2.68825Z" />
    <path
      d="M15.7296 7.84409L13.9146 3.34533C13.8046 3.07102 13.4196 3.01616 13.2546 3.23561C12.1546 4.60718 9.1296 4.60718 8.0296 3.23561C7.8646 3.01616 7.4246 3.07102 7.3696 3.34533L4.3996 10.6421C4.3446 10.7518 4.3446 10.9164 4.4546 11.0261C6.8746 14.0984 12.1546 15.6895 16.1696 11.7393C16.5546 11.3553 16.0046 10.8067 15.6196 11.1907C12.3196 14.4276 7.8096 13.7144 5.2246 10.7518L6.2696 8.22813C6.7096 8.61217 7.2046 8.88648 7.6996 9.16079C7.9196 9.27052 8.1396 9.16079 8.2496 8.94134C8.3596 8.72189 8.2496 8.50244 8.0296 8.44758C7.4796 8.17326 6.9846 7.84409 6.5446 7.46005L7.8096 4.278C9.1296 5.43012 12.0446 5.43012 13.3646 4.278L13.8596 5.53985C13.3096 6.14334 12.4846 6.52738 11.7146 6.6371C11.4946 6.69197 11.3296 6.85655 11.3846 7.07601C11.5496 8.00867 13.8596 6.69197 14.1896 6.30793L14.9596 8.17326C15.2346 8.61217 15.9496 8.33785 15.7296 7.84409Z" />
    <path
      d="M21.6705 7.46152H17.7105C15.2355 7.51639 15.0705 11.0825 17.4905 11.3568V14.0999C17.4905 15.3618 18.5905 16.1847 19.8555 16.075C20.9555 16.075 21.8355 15.1972 21.8355 14.0999V11.3568C24.3655 11.0276 24.2005 7.51639 21.6705 7.46152ZM21.6705 10.5887H21.4505C21.2305 10.5887 21.0655 10.7533 21.0655 10.9727V14.0999C21.0655 14.7583 20.5155 15.252 19.8555 15.252H19.4705C18.8105 15.252 18.2605 14.7034 18.2605 14.0999V10.9727C18.2605 10.7533 18.0955 10.5887 17.8755 10.5887C16.2255 10.7533 16.0055 8.28447 17.6555 8.2296H21.6155C23.2655 8.28447 23.2655 10.5338 21.6705 10.5887Z" />
    <path
      d="M19.7449 4.99297C18.9199 4.99297 18.1499 5.43187 17.7099 6.14509C17.5999 6.30968 17.6549 6.58399 17.8199 6.69372C17.9849 6.80344 18.2599 6.74858 18.3699 6.58399C18.9199 5.59646 20.5149 5.59646 21.0649 6.58399C21.1749 6.74858 21.3949 6.80344 21.6149 6.69372C21.7799 6.58399 21.8899 6.36454 21.7249 6.14509C21.3399 5.43187 20.5699 4.99297 19.7449 4.99297Z" />
    <path
      d="M16.3347 5.32127C16.0597 5.76017 16.7197 6.14421 16.9947 5.70531C18.1497 3.67538 21.2297 3.67538 22.3847 5.70531C22.6597 6.14421 23.3197 5.76017 23.0447 5.32127C21.6147 2.85244 17.8197 2.85244 16.3347 5.32127Z" />
    <path
      d="M10.3404 6.4733C9.84543 6.36358 9.35043 6.14413 8.96543 5.81495C8.80043 5.70522 8.52543 5.70522 8.41543 5.86981C7.92043 6.4733 9.79043 7.18652 10.2304 7.24138C10.7254 7.29625 10.7804 6.58303 10.3404 6.4733Z" />
    <path
      d="M13.9701 8.12033C13.8601 7.95574 13.5851 7.90088 13.4201 8.0106C12.3201 8.66896 11.0551 8.99813 9.73513 8.83354C9.46013 8.77868 9.13013 8.77868 9.02013 9.10786C8.96513 9.32731 9.13013 9.54676 9.35013 9.54676C10.8901 9.82107 12.4851 9.43704 13.8051 8.61409C14.0251 8.55923 14.0801 8.33978 13.9701 8.12033Z" />
    <path
      d="M7.205 10.5341C7.095 10.6987 7.15 10.973 7.37 11.0827C8.36 11.6314 9.46 11.9057 10.615 11.9057C11 11.8508 11.88 12.0154 11.825 11.4119C11.77 11.1924 11.605 11.0279 11.385 11.0827C10.12 11.2473 8.855 11.0279 7.755 10.3695C7.535 10.2598 7.315 10.3146 7.205 10.5341Z" />
    <path
      d="M13.0353 11.412C13.9153 11.1377 14.6303 10.589 15.2903 9.98555C15.4553 9.82096 15.4553 9.60151 15.2903 9.43692C15.1253 9.27233 14.9053 9.27233 14.7403 9.43692C14.1903 9.98555 13.5853 10.3696 12.8703 10.6988C12.4853 10.8085 12.5953 11.412 13.0353 11.412Z" />
  </mask>
  <path
    d="M19.14 16.4589C14.135 16.5137 7.315 16.4589 2.365 16.4589C1.485 16.4589 0.77 15.7456 0.77 14.8678V2.3591C0.77 1.4813 1.485 0.76808 2.365 0.76808H18.92C19.8 0.76808 20.515 1.4813 20.515 2.3591V2.79801C20.515 3.29177 21.285 3.29177 21.285 2.79801C21.45 1.31671 20.515 0 18.92 0H2.365C1.045 0 0 1.04239 0 2.3591V14.9227C0 16.2394 1.045 17.2818 2.365 17.2818H7.095V18.0499H5.885C4.785 18.0499 3.905 18.9277 3.905 20.0249C3.905 21.1222 4.785 22 5.885 22H15.4C16.445 22 17.38 21.0673 17.38 20.0249C17.38 18.9277 16.5 18.0499 15.4 18.0499H14.19V17.2818H18.92C19.03 17.2818 19.14 17.2818 19.25 17.2818C19.745 17.1721 19.69 16.404 19.14 16.4589ZM7.865 17.2269H13.365V18.3791C13.365 18.818 13.035 19.1471 12.595 19.1471H8.635C8.195 19.1471 7.865 18.818 7.865 18.3791V17.2269ZM15.4 18.818C16.995 18.8728 16.94 21.1222 15.4 21.1771H5.94C4.4 21.1222 4.4 18.8728 5.94 18.818H7.15C7.315 19.4763 7.92 19.9701 8.69 19.9701H12.65C13.365 19.9701 13.97 19.4763 14.19 18.818H15.4Z"
    fill="currentColor" />
  <path
    d="M18.9204 2.68825C18.9754 3.18202 19.7454 3.12716 19.6904 2.63339C19.6354 2.0299 19.1404 1.53613 18.5354 1.53613H2.75043C2.09043 1.53613 1.54043 2.08476 1.54043 2.68825V14.4289C1.54043 15.0873 2.09043 15.581 2.75043 15.581H17.0504C17.2704 15.581 17.4354 15.4164 17.4354 15.197C17.4354 14.9775 17.2704 14.8129 17.0504 14.8129H2.75043C2.53043 14.8129 2.36543 14.6484 2.36543 14.4289V2.74312C2.36543 2.52366 2.53043 2.35908 2.75043 2.35908H18.5354C18.7554 2.35908 18.9204 2.52366 18.9204 2.68825Z"
    fill="currentColor" />
  <path
    d="M15.7296 7.84409L13.9146 3.34533C13.8046 3.07102 13.4196 3.01616 13.2546 3.23561C12.1546 4.60718 9.1296 4.60718 8.0296 3.23561C7.8646 3.01616 7.4246 3.07102 7.3696 3.34533L4.3996 10.6421C4.3446 10.7518 4.3446 10.9164 4.4546 11.0261C6.8746 14.0984 12.1546 15.6895 16.1696 11.7393C16.5546 11.3553 16.0046 10.8067 15.6196 11.1907C12.3196 14.4276 7.8096 13.7144 5.2246 10.7518L6.2696 8.22813C6.7096 8.61217 7.2046 8.88648 7.6996 9.16079C7.9196 9.27052 8.1396 9.16079 8.2496 8.94134C8.3596 8.72189 8.2496 8.50244 8.0296 8.44758C7.4796 8.17326 6.9846 7.84409 6.5446 7.46005L7.8096 4.278C9.1296 5.43012 12.0446 5.43012 13.3646 4.278L13.8596 5.53985C13.3096 6.14334 12.4846 6.52738 11.7146 6.6371C11.4946 6.69197 11.3296 6.85655 11.3846 7.07601C11.5496 8.00867 13.8596 6.69197 14.1896 6.30793L14.9596 8.17326C15.2346 8.61217 15.9496 8.33785 15.7296 7.84409Z"
    fill="currentColor" />
  <path
    d="M21.6705 7.46152H17.7105C15.2355 7.51639 15.0705 11.0825 17.4905 11.3568V14.0999C17.4905 15.3618 18.5905 16.1847 19.8555 16.075C20.9555 16.075 21.8355 15.1972 21.8355 14.0999V11.3568C24.3655 11.0276 24.2005 7.51639 21.6705 7.46152ZM21.6705 10.5887H21.4505C21.2305 10.5887 21.0655 10.7533 21.0655 10.9727V14.0999C21.0655 14.7583 20.5155 15.252 19.8555 15.252H19.4705C18.8105 15.252 18.2605 14.7034 18.2605 14.0999V10.9727C18.2605 10.7533 18.0955 10.5887 17.8755 10.5887C16.2255 10.7533 16.0055 8.28447 17.6555 8.2296H21.6155C23.2655 8.28447 23.2655 10.5338 21.6705 10.5887Z"
    fill="currentColor" />
  <path
    d="M19.7449 4.99297C18.9199 4.99297 18.1499 5.43187 17.7099 6.14509C17.5999 6.30968 17.6549 6.58399 17.8199 6.69372C17.9849 6.80344 18.2599 6.74858 18.3699 6.58399C18.9199 5.59646 20.5149 5.59646 21.0649 6.58399C21.1749 6.74858 21.3949 6.80344 21.6149 6.69372C21.7799 6.58399 21.8899 6.36454 21.7249 6.14509C21.3399 5.43187 20.5699 4.99297 19.7449 4.99297Z"
    fill="currentColor" />
  <path
    d="M16.3347 5.32127C16.0597 5.76017 16.7197 6.14421 16.9947 5.70531C18.1497 3.67538 21.2297 3.67538 22.3847 5.70531C22.6597 6.14421 23.3197 5.76017 23.0447 5.32127C21.6147 2.85244 17.8197 2.85244 16.3347 5.32127Z"
    fill="currentColor" />
  <path
    d="M10.3404 6.4733C9.84543 6.36358 9.35043 6.14413 8.96543 5.81495C8.80043 5.70522 8.52543 5.70522 8.41543 5.86981C7.92043 6.4733 9.79043 7.18652 10.2304 7.24138C10.7254 7.29625 10.7804 6.58303 10.3404 6.4733Z"
    fill="currentColor" />
  <path
    d="M13.9701 8.12033C13.8601 7.95574 13.5851 7.90088 13.4201 8.0106C12.3201 8.66896 11.0551 8.99813 9.73513 8.83354C9.46013 8.77868 9.13013 8.77868 9.02013 9.10786C8.96513 9.32731 9.13013 9.54676 9.35013 9.54676C10.8901 9.82107 12.4851 9.43704 13.8051 8.61409C14.0251 8.55923 14.0801 8.33978 13.9701 8.12033Z"
    fill="currentColor" />
  <path
    d="M7.205 10.5341C7.095 10.6987 7.15 10.973 7.37 11.0827C8.36 11.6314 9.46 11.9057 10.615 11.9057C11 11.8508 11.88 12.0154 11.825 11.4119C11.77 11.1924 11.605 11.0279 11.385 11.0827C10.12 11.2473 8.855 11.0279 7.755 10.3695C7.535 10.2598 7.315 10.3146 7.205 10.5341Z"
    fill="currentColor" />
  <path
    d="M13.0353 11.412C13.9153 11.1377 14.6303 10.589 15.2903 9.98555C15.4553 9.82096 15.4553 9.60151 15.2903 9.43692C15.1253 9.27233 14.9053 9.27233 14.7403 9.43692C14.1903 9.98555 13.5853 10.3696 12.8703 10.6988C12.4853 10.8085 12.5953 11.412 13.0353 11.412Z"
    fill="currentColor" />
  <path
    d="M19.14 16.4589C14.135 16.5137 7.315 16.4589 2.365 16.4589C1.485 16.4589 0.77 15.7456 0.77 14.8678V2.3591C0.77 1.4813 1.485 0.76808 2.365 0.76808H18.92C19.8 0.76808 20.515 1.4813 20.515 2.3591V2.79801C20.515 3.29177 21.285 3.29177 21.285 2.79801C21.45 1.31671 20.515 0 18.92 0H2.365C1.045 0 0 1.04239 0 2.3591V14.9227C0 16.2394 1.045 17.2818 2.365 17.2818H7.095V18.0499H5.885C4.785 18.0499 3.905 18.9277 3.905 20.0249C3.905 21.1222 4.785 22 5.885 22H15.4C16.445 22 17.38 21.0673 17.38 20.0249C17.38 18.9277 16.5 18.0499 15.4 18.0499H14.19V17.2818H18.92C19.03 17.2818 19.14 17.2818 19.25 17.2818C19.745 17.1721 19.69 16.404 19.14 16.4589ZM7.865 17.2269H13.365V18.3791C13.365 18.818 13.035 19.1471 12.595 19.1471H8.635C8.195 19.1471 7.865 18.818 7.865 18.3791V17.2269ZM15.4 18.818C16.995 18.8728 16.94 21.1222 15.4 21.1771H5.94C4.4 21.1222 4.4 18.8728 5.94 18.818H7.15C7.315 19.4763 7.92 19.9701 8.69 19.9701H12.65C13.365 19.9701 13.97 19.4763 14.19 18.818H15.4Z"
    stroke="currentColor" stroke-width="0.4" mask="url(#path-1-outside-1_5965_332485)" />
  <path
    d="M18.9204 2.68825C18.9754 3.18202 19.7454 3.12716 19.6904 2.63339C19.6354 2.0299 19.1404 1.53613 18.5354 1.53613H2.75043C2.09043 1.53613 1.54043 2.08476 1.54043 2.68825V14.4289C1.54043 15.0873 2.09043 15.581 2.75043 15.581H17.0504C17.2704 15.581 17.4354 15.4164 17.4354 15.197C17.4354 14.9775 17.2704 14.8129 17.0504 14.8129H2.75043C2.53043 14.8129 2.36543 14.6484 2.36543 14.4289V2.74312C2.36543 2.52366 2.53043 2.35908 2.75043 2.35908H18.5354C18.7554 2.35908 18.9204 2.52366 18.9204 2.68825Z"
    stroke="currentColor" stroke-width="0.4" mask="url(#path-1-outside-1_5965_332485)" />
  <path
    d="M15.7296 7.84409L13.9146 3.34533C13.8046 3.07102 13.4196 3.01616 13.2546 3.23561C12.1546 4.60718 9.1296 4.60718 8.0296 3.23561C7.8646 3.01616 7.4246 3.07102 7.3696 3.34533L4.3996 10.6421C4.3446 10.7518 4.3446 10.9164 4.4546 11.0261C6.8746 14.0984 12.1546 15.6895 16.1696 11.7393C16.5546 11.3553 16.0046 10.8067 15.6196 11.1907C12.3196 14.4276 7.8096 13.7144 5.2246 10.7518L6.2696 8.22813C6.7096 8.61217 7.2046 8.88648 7.6996 9.16079C7.9196 9.27052 8.1396 9.16079 8.2496 8.94134C8.3596 8.72189 8.2496 8.50244 8.0296 8.44758C7.4796 8.17326 6.9846 7.84409 6.5446 7.46005L7.8096 4.278C9.1296 5.43012 12.0446 5.43012 13.3646 4.278L13.8596 5.53985C13.3096 6.14334 12.4846 6.52738 11.7146 6.6371C11.4946 6.69197 11.3296 6.85655 11.3846 7.07601C11.5496 8.00867 13.8596 6.69197 14.1896 6.30793L14.9596 8.17326C15.2346 8.61217 15.9496 8.33785 15.7296 7.84409Z"
    stroke="currentColor" stroke-width="0.4" mask="url(#path-1-outside-1_5965_332485)" />
  <path
    d="M21.6705 7.46152H17.7105C15.2355 7.51639 15.0705 11.0825 17.4905 11.3568V14.0999C17.4905 15.3618 18.5905 16.1847 19.8555 16.075C20.9555 16.075 21.8355 15.1972 21.8355 14.0999V11.3568C24.3655 11.0276 24.2005 7.51639 21.6705 7.46152ZM21.6705 10.5887H21.4505C21.2305 10.5887 21.0655 10.7533 21.0655 10.9727V14.0999C21.0655 14.7583 20.5155 15.252 19.8555 15.252H19.4705C18.8105 15.252 18.2605 14.7034 18.2605 14.0999V10.9727C18.2605 10.7533 18.0955 10.5887 17.8755 10.5887C16.2255 10.7533 16.0055 8.28447 17.6555 8.2296H21.6155C23.2655 8.28447 23.2655 10.5338 21.6705 10.5887Z"
    stroke="currentColor" stroke-width="0.4" mask="url(#path-1-outside-1_5965_332485)" />
  <path
    d="M19.7449 4.99297C18.9199 4.99297 18.1499 5.43187 17.7099 6.14509C17.5999 6.30968 17.6549 6.58399 17.8199 6.69372C17.9849 6.80344 18.2599 6.74858 18.3699 6.58399C18.9199 5.59646 20.5149 5.59646 21.0649 6.58399C21.1749 6.74858 21.3949 6.80344 21.6149 6.69372C21.7799 6.58399 21.8899 6.36454 21.7249 6.14509C21.3399 5.43187 20.5699 4.99297 19.7449 4.99297Z"
    stroke="currentColor" stroke-width="0.4" mask="url(#path-1-outside-1_5965_332485)" />
  <path
    d="M16.3347 5.32127C16.0597 5.76017 16.7197 6.14421 16.9947 5.70531C18.1497 3.67538 21.2297 3.67538 22.3847 5.70531C22.6597 6.14421 23.3197 5.76017 23.0447 5.32127C21.6147 2.85244 17.8197 2.85244 16.3347 5.32127Z"
    stroke="currentColor" stroke-width="0.4" mask="url(#path-1-outside-1_5965_332485)" />
  <path
    d="M10.3404 6.4733C9.84543 6.36358 9.35043 6.14413 8.96543 5.81495C8.80043 5.70522 8.52543 5.70522 8.41543 5.86981C7.92043 6.4733 9.79043 7.18652 10.2304 7.24138C10.7254 7.29625 10.7804 6.58303 10.3404 6.4733Z"
    stroke="currentColor" stroke-width="0.4" mask="url(#path-1-outside-1_5965_332485)" />
  <path
    d="M13.9701 8.12033C13.8601 7.95574 13.5851 7.90088 13.4201 8.0106C12.3201 8.66896 11.0551 8.99813 9.73513 8.83354C9.46013 8.77868 9.13013 8.77868 9.02013 9.10786C8.96513 9.32731 9.13013 9.54676 9.35013 9.54676C10.8901 9.82107 12.4851 9.43704 13.8051 8.61409C14.0251 8.55923 14.0801 8.33978 13.9701 8.12033Z"
    stroke="currentColor" stroke-width="0.4" mask="url(#path-1-outside-1_5965_332485)" />
  <path
    d="M7.205 10.5341C7.095 10.6987 7.15 10.973 7.37 11.0827C8.36 11.6314 9.46 11.9057 10.615 11.9057C11 11.8508 11.88 12.0154 11.825 11.4119C11.77 11.1924 11.605 11.0279 11.385 11.0827C10.12 11.2473 8.855 11.0279 7.755 10.3695C7.535 10.2598 7.315 10.3146 7.205 10.5341Z"
    stroke="currentColor" stroke-width="0.4" mask="url(#path-1-outside-1_5965_332485)" />
  <path
    d="M13.0353 11.412C13.9153 11.1377 14.6303 10.589 15.2903 9.98555C15.4553 9.82096 15.4553 9.60151 15.2903 9.43692C15.1253 9.27233 14.9053 9.27233 14.7403 9.43692C14.1903 9.98555 13.5853 10.3696 12.8703 10.6988C12.4853 10.8085 12.5953 11.412 13.0353 11.412Z"
    stroke="currentColor" stroke-width="0.4" mask="url(#path-1-outside-1_5965_332485)" />
</g>
<defs>
  <clipPath id="clip0_5965_332485">
    <rect width="23.65" height="22" fill="white" />
  </clipPath>
</defs>
</svg>`;

export default { id: '36-interventional-radiology', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
