import { Form } from 'antd';
import CustomSelect from '@base/CustomSelect';
import { useSelect } from '@zustandStorage/customSelect/customSelect';
import { useEffect } from 'react';

interface Props {
  indexPage: boolean;
}

export default function Hospital({ indexPage }: Props): JSX.Element {
  const { fetchHospitalsForSelect, clearFormData } = useSelect();
  // const selectCity = useSelect((state) => state.data?.['selectCities']);

  useEffect(() => {
    console.log('change index page')
    clearFormData('selectCountries')
  }, [indexPage])

  return (
    <>
      {!indexPage &&
        <Form.Item
          name="hospitalId"
          label="Hospital"
          valuePropName="defaultValue"
        >
          <CustomSelect
            fieldNames={{
              label: 'title',
              value: 'id',
            }}
            placeholder="Select Hospital"
            selectId="selectHospital"
            onUpdateSelect={fetchHospitalsForSelect}
            defaultQueryParams={{ page: 1, perPage: 50}}
            onChange={() => null}
            style={{ width: '100%' }}
          />
        </Form.Item>
      }

      {/*{indexPage &&*/}
      {/*  <>*/}
      {/*    <Form.Item*/}
      {/*      name="country"*/}
      {/*      label="Country"*/}
      {/*      valuePropName="defaultValue"*/}
      {/*    >*/}
      {/*      <CustomSelect*/}
      {/*        placeholder={'Select country'}*/}
      {/*        defaultQueryParams={{ page: 1, perPage: 50 }}*/}
      {/*        selectId={'selectCountries'}*/}
      {/*        onUpdateSelect={fetchCountriesForSelect}*/}
      {/*        onChange={(value) => {*/}
      {/*          if (value && value.urlSlug && indexPage) {*/}
      {/*            const params = { countryUrlSlug: value.urlSlug };*/}

      {/*            fetchCitiesForSelect('selectCities', params, { clearDataBeforeFetch: true, saveParams: true });*/}
      {/*          }*/}
      {/*        }}*/}
      {/*        style={{ width: '100%' }}*/}
      {/*      />*/}
      {/*    </Form.Item>*/}
      {/*    <Form.Item*/}
      {/*      name="city"*/}
      {/*      label="City"*/}
      {/*      valuePropName="defaultValue"*/}
      {/*    >*/}
      {/*      <CustomSelect*/}
      {/*        disabled={!selectCity?.opened}*/}
      {/*        placeholder={'Select city'}*/}
      {/*        defaultQueryParams={{ page: 1, perPage: 50,}}*/}
      {/*        selectId={'selectCities'}*/}
      {/*        onUpdateSelect={fetchCitiesForSelect}*/}
      {/*        style={{ width: '100%' }}*/}
      {/*      />*/}
      {/*    </Form.Item>*/}
      {/*  </>*/}
      {/*}*/}
    </>
  );
}