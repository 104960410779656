import { useHistory } from 'react-router-dom';
import CertificatesAntGrid from './components/CertificatesAntGrid';
import { PageHeader } from '@ant-design/pro-layout';
import CreateModal from '@containers/Certificates/components/CreateModal';

export default function CertificatesIndex(): JSX.Element {
  const history = useHistory();

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.push("/hospitals")}
        title="Certificates"
        extra={(
          <CreateModal buttonProps={{ children: 'Add Certificate' }} />
          // <Button
          //   key="createCertificate"
          //   type="primary"
          //   href="/certificates/create"
          //   onClick={(e) => {
          //     e.preventDefault();
          //
          //     history.push("/certificates/create");
          //   }}
          // >
          //   Add Certificate
          // </Button>
        )}
      />
      <CertificatesAntGrid />
    </>
  );
}
