import { useParams } from 'react-router-dom';
import MainDataStep from '@containers/Doctors/FormSteps/MainDataStep';
import ReviewsStep from '@containers/Doctors/FormSteps/ReviewsStep';
import PublicationsStep from '@containers/Doctors/FormSteps/PublicationsStep';
import SkillsStep from '@containers/Doctors/FormSteps/SkillsStep';
import GalleryStep from '@components/Gallery/GalleryStep';
import SelectStep from '@containers/Doctors/components/SelectStep';


const stepsScreen = {
  '1': <MainDataStep />,
  '2': <SkillsStep />,
  '3': <GalleryStep
    entityGallery={'doctorId'}
    backPath={'/doctors'}
    selectStep={<SelectStep key="selectStep" />}
  />,
  '4': <PublicationsStep />,
  '5': <ReviewsStep />,
};

export default function CreateDoctor() {
  const { step = '1' } = useParams<{ step: string }>();

  return stepsScreen[step as (keyof typeof stepsScreen)];
}
