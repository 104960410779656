import { ActionType, Actions } from './actions';

import { ListResponse } from '@api/types';
import { TreatmentServiceCost } from '@modelTypes/treatment';

export interface ServicesCostState {
  readonly grid: ListResponse<TreatmentServiceCost> & { loading: boolean }
  readonly form: {
    readonly loading: boolean;
    readonly row: TreatmentServiceCost | null;
  };
  readonly select: {
    readonly loading: boolean;
    readonly search: string | null;
    readonly data: Array<TreatmentServiceCost>;
  };
}

const initialState: ServicesCostState = {
  grid: {
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  form: {
    loading: false,
    row: null,
  },
  select: {
    loading: false,
    search: null,
    data: [],
  }
};

export default function treatmentServicesCost(state = initialState, action: Actions): ServicesCostState {
  switch (action.type) {
    case ActionType.START_FETCH_SERVICES_COST:
    // case ActionType.START_DELETE_TYPE:
      return {
        ...state,
        grid: {
          ...state.grid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_SERVICES_COST:
      return {
        ...state,
        grid: {
          ...action.data,
          loading: false,
        },
      }
    case ActionType.START_FETCH_SERVICE_COST:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        }
      }
    case ActionType.END_FETCH_SERVICE_COST:
    case ActionType.END_PATCH_SERVICE_COST:
      return {
        ...state,
        form: {
          ...state.form,
          row: action.data,
          loading: false,
        },
      }
    case ActionType.START_PATCH_SERVICE_COST:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        },
      }
    case ActionType.END_PATCH_SERVICE_COST_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.CLEAR_FORM_DATA:
      return {
        ...state,
        form: {
          ...initialState.form,
        }
      }
    default:
      return state;
  }
}