import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg data-id="27" width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_5965_332461)">
  <path
    d="M11.562 13.8904C11.5072 13.8359 11.3975 13.7271 11.2329 13.7271C10.849 13.7271 10.5748 14.108 10.5748 14.6522C10.5748 15.0876 9.75202 16.7202 9.25838 17.21C8.60019 17.9174 8.05169 18.1351 7.11926 18.1351C5.96743 18.1351 5.25439 17.6997 4.81559 16.829C4.2671 15.7406 4.32195 14.3801 5.03499 11.4959C5.19954 10.7885 5.63833 9.04703 5.52863 8.88377C5.47378 8.77493 5.19954 8.72051 5.03499 8.72051C4.7059 8.72051 4.5962 8.99261 4.4865 9.48238C4.43165 9.8089 4.32195 9.97216 4.2671 10.0266C4.2671 9.97216 4.2671 9.91774 4.2671 9.8089C4.2671 9.64564 4.21225 9.5368 4.1574 9.48238C4.10255 9.42796 3.99286 9.37354 3.88316 9.37354C3.49921 9.37354 3.44436 9.64564 3.44436 12.8564C3.44436 15.0876 3.33467 16.829 3.11527 18.0807C2.89587 19.4956 2.84102 20.0398 3.06042 20.2575C3.17012 20.3663 3.27982 20.3663 3.38951 20.3663C3.77346 20.3663 3.88316 19.9309 4.04771 18.6249C4.1574 17.9174 4.2671 17.8086 4.2671 17.8086C4.2671 17.8086 4.32195 17.8086 4.3768 17.9174C4.43165 18.0263 4.54135 18.7337 4.5962 19.8765C4.70589 21.6179 4.70589 21.7812 5.19954 21.8356L5.74803 21.89L5.41893 18.897L5.96743 19.0058C8.54534 19.55 10.2457 18.4616 11.3426 15.6318C11.6717 14.6522 11.7266 14.108 11.562 13.8904Z"
    fill="currentColor" />
  <path
    d="M13.1515 5.2382C13.0966 5.07494 13.0418 4.9661 12.9321 4.91168C12.8224 4.85726 12.6578 4.91168 12.3836 4.9661C12.0545 5.07494 11.8351 5.07494 11.6157 4.9661C11.2866 4.80284 11.1221 4.85726 10.9575 5.18378C10.8478 5.34704 10.8478 5.56472 10.9575 5.67356C11.1221 5.89123 11.4511 6.00007 11.8899 6.00007C11.9996 6.00007 12.1642 6.00007 12.2739 5.94565C12.7675 5.89123 13.0418 5.83681 13.1515 5.67356C13.2063 5.61914 13.2063 5.45588 13.1515 5.2382Z"
    fill="currentColor" />
  <path
    d="M5.6927 15.0345C5.14421 15.5787 5.08936 16.2318 5.63785 16.8848C5.9121 17.2113 6.29604 17.3746 6.73484 17.3746C7.06393 17.3746 7.39303 17.2657 7.66727 17.1025C7.99637 16.8848 8.10606 16.6127 8.10606 16.0685C8.10606 15.4155 7.77697 14.9257 7.28333 14.708C6.67999 14.4359 6.13149 14.5992 5.6927 15.0345ZM6.29604 15.9597C6.29604 15.6876 6.35089 15.6332 6.62514 15.6332C6.84453 15.6332 7.00908 15.742 7.06393 15.8508C7.11878 15.9597 7.06393 16.1229 6.89938 16.2862C6.78968 16.395 6.62514 16.4495 6.46059 16.4495C6.40574 16.3406 6.29604 16.1774 6.29604 15.9597Z"
    fill="currentColor" />
  <path
    d="M8.27068 5.23717C8.92887 5.45485 9.75161 5.72695 9.91615 5.72695C10.0807 5.72695 10.2452 5.56369 10.2452 5.34601C10.3001 5.12833 10.1904 4.80181 9.971 4.74739C9.8613 4.69297 9.09342 4.4753 8.27068 4.14878C7.72219 3.9311 7.2834 3.82226 7.11885 3.71342C7.1737 3.71342 7.22855 3.659 7.33824 3.659C7.61249 3.55016 8.10613 3.27807 8.43523 3.00597C8.70947 2.84271 8.87402 2.73387 8.98372 2.67945C9.03857 2.78829 9.09342 2.95155 9.14827 3.11481C9.25796 3.49574 9.42251 3.659 9.58706 3.71342C9.69676 3.76784 9.8613 3.71342 10.0259 3.60458C10.2452 3.44132 10.1904 3.16923 10.0259 2.57061C9.8613 2.08083 9.80646 1.53664 9.8613 0.938023C9.91615 0.448247 9.91615 0.230569 9.80646 0.12173C9.75161 0.0673102 9.64191 0.0128906 9.53221 0.0128906C9.36766 0.0128906 9.09342 0.12173 8.65462 1.10128C8.05128 2.29851 7.72219 2.51619 4.97973 3.33248C2.56637 4.03994 1.85333 4.42088 1.19514 5.40043C0.482099 6.48882 0.262702 7.74047 0.207853 11.7131C0.153004 14.815 0.153004 15.196 0.317551 15.3592C0.3724 15.4136 0.427249 15.4136 0.482099 15.4136C0.536948 15.4136 0.536948 15.4136 0.591797 15.4136C1.03059 15.3592 1.03059 15.0327 1.14029 13.8355C1.19514 13.0192 1.24999 11.2777 1.24999 9.97167C1.24999 6.1623 1.74363 5.34601 4.32154 4.58413C5.36367 4.25762 6.02186 4.36646 8.27068 5.23717Z"
    fill="currentColor" />
  <path
    d="M23.7922 12.4757C23.6277 7.30583 23.6277 6.97931 23.1341 5.94534C22.8598 5.40114 22.421 4.80253 22.0919 4.53043C21.7628 4.25833 20.3916 3.76856 19.0204 3.27878C16.2779 2.40807 15.6746 2.02713 15.2906 0.829898C15.1261 0.285703 14.9067 0.0680245 14.6324 0.0136049C14.4679 0.0136049 14.3582 -0.0408147 14.3033 0.0680245C14.1936 0.176864 14.1936 0.394542 14.2485 0.993157C14.3033 1.59177 14.2485 2.13597 14.0839 2.68016C13.8645 3.27878 13.8097 3.55088 14.1388 3.71414C14.4679 3.82297 14.6324 3.82297 14.7421 3.76856C14.6873 3.71414 14.7421 3.65972 14.7421 3.55088C14.7421 3.49646 14.797 3.22436 14.9067 2.95226L15.0164 2.62574L15.4003 2.89784C15.6746 3.11552 16.1682 3.38762 16.4973 3.55088L16.8264 3.71414L15.8391 4.14949C15.0712 4.42159 14.413 4.63927 14.2485 4.63927C14.0291 4.63927 13.8097 4.80253 13.7548 5.02021C13.7 5.23788 13.7 5.45556 13.8645 5.5644C14.0291 5.72766 14.3582 5.72766 15.6746 5.23788C18.2525 4.36717 18.6913 4.31275 19.9528 4.69369C20.7207 4.91137 21.2143 5.18346 21.5983 5.5644C22.5307 6.54395 22.6404 6.97931 22.7501 10.8975C22.8598 15.1422 22.9147 15.5232 23.4632 15.5232C23.518 15.5232 23.6277 15.5232 23.6825 15.4143C23.8471 15.1967 23.8471 14.7613 23.7922 12.4757Z"
    fill="currentColor" />
  <path
    d="M20.6679 9.91922C20.7227 9.81038 20.6679 9.70154 20.613 9.64713C20.5582 9.53829 20.3936 9.53829 20.1742 9.53829C19.9548 9.53829 19.7354 9.42945 19.6806 9.37503C19.5709 9.21177 19.4063 9.10293 19.1869 9.10293C19.0224 9.10293 18.8578 9.15735 18.803 9.32061C18.6933 9.48387 19.0224 10.409 19.0772 10.5723C19.7903 12.5858 19.6806 14.3272 18.7481 15.3068C18.09 16.0142 16.993 16.2319 15.7863 15.851C15.0733 15.6333 14.6345 15.3612 14.0311 14.817C13.5375 14.3272 13.2084 13.9463 13.2084 13.8374C13.1535 13.6742 12.989 13.6198 12.8244 13.6198C12.605 13.6742 12.3308 13.8374 12.3308 14.1095C12.3308 14.6537 13.5375 15.9054 14.4151 16.3952C15.5669 17.0482 17.2124 17.2114 18.1997 16.8305L18.6933 16.6128V17.2114C18.7481 17.538 18.6384 17.8645 18.5836 18.191C18.5288 18.4087 18.4739 18.6264 18.4739 18.8985C18.4739 19.2794 18.4191 19.6059 18.4191 19.9868C18.4191 21.7283 18.4191 21.9459 18.8578 22.0004C18.9127 22.0004 18.9127 22.0004 18.9675 22.0004C19.0224 22.0004 19.0772 22.0004 19.1321 21.9459C19.2966 21.7827 19.3515 21.3473 19.4612 19.4971C19.5709 17.8645 19.6257 17.2114 19.6806 16.8849C19.6806 16.9938 19.7354 17.1026 19.7354 17.3203C20.0645 20.0413 20.2839 20.4766 20.5582 20.4766C20.7227 20.4766 20.7776 20.4222 20.8873 20.3678C21.1067 20.1501 21.0518 19.6059 20.8324 17.9189C20.613 16.1231 20.4485 10.6267 20.6679 9.91922Z"
    fill="currentColor" />
  <path
    d="M18.09 15.0879C18.7482 14.5981 18.6933 13.7818 18.3094 13.1832C17.9803 12.6934 17.3221 12.3125 16.6091 12.6935C16.1154 12.9655 15.8412 13.3465 15.7863 13.7818C15.7315 14.2172 15.896 14.6526 16.2251 14.9791C16.4994 15.2512 16.8285 15.4144 17.1576 15.4144C17.4318 15.4688 17.7609 15.3056 18.09 15.0879ZM17.5415 14.2716C17.4866 14.326 17.3769 14.3805 17.2124 14.3805C17.1027 14.3805 16.993 14.326 16.8833 14.2172C16.7736 14.1084 16.7736 13.9451 16.8285 13.7274C16.8833 13.5642 17.0479 13.5642 17.1027 13.5642C17.2673 13.5642 17.4318 13.673 17.4866 13.8363C17.5963 13.9995 17.6512 14.1628 17.5415 14.2716Z"
    fill="currentColor" />
  <path
    d="M16.0591 17.918C15.8397 17.918 15.6203 18.1356 15.6203 18.3533C15.6203 18.571 15.8397 18.7887 16.0591 18.7887C16.2785 18.7887 16.4979 18.571 16.4979 18.3533C16.4979 18.1356 16.2785 17.918 16.0591 17.918Z"
    fill="currentColor" />
  <path
    d="M12.933 17.918C12.7136 17.918 12.4942 18.1356 12.4942 18.3533C12.4942 18.571 12.7136 18.7887 12.933 18.7887C13.1524 18.7887 13.3718 18.571 13.3718 18.3533C13.3718 18.1356 13.2072 17.918 12.933 17.918Z"
    fill="currentColor" />
  <path
    d="M11.3979 17.918C11.1785 17.918 10.9591 18.1356 10.9591 18.3533C10.9591 18.571 11.1785 18.7887 11.3979 18.7887C11.6173 18.7887 11.8367 18.571 11.8367 18.3533C11.8367 18.1356 11.6173 17.918 11.3979 17.918Z"
    fill="currentColor" />
  <path
    d="M17.5939 17.918C17.3745 17.918 17.1551 18.1356 17.1551 18.3533C17.1551 18.571 17.3745 18.7887 17.5939 18.7887C17.8133 18.7887 18.0327 18.571 18.0327 18.3533C18.0327 18.1356 17.8682 17.918 17.5939 17.918Z"
    fill="currentColor" />
  <path
    d="M14.5218 17.918C14.3024 17.918 14.083 18.1356 14.083 18.3533C14.083 18.571 14.3024 18.7887 14.5218 18.7887C14.7411 18.7887 14.9605 18.571 14.9605 18.3533C14.9057 18.1356 14.7411 17.918 14.5218 17.918Z"
    fill="currentColor" />
</g>
<defs>
  <clipPath id="clip0_5965_332461">
    <rect width="24" height="22" fill="white" />
  </clipPath>
</defs>
</svg>`;

export default { id: '27-plastic-surgery', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
