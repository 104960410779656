import { Author } from '@modelTypes/author';
import { ListResponse } from '@api/types';
import { createAction } from '../helpers';

export enum ActionType {
  START_FETCH_AUTHORS = 'AUTHORS__START_FETCH_AUTHORS',
  END_FETCH_AUTHORS = 'AUTHORS__END_FETCH_AUTHORS',

  START_FETCH_AUTHORS_FOR_SELECT = 'AUTHORS__START_FETCH_AUTHORS_FOR_SELECT',
  END_FETCH_AUTHORS_FOR_SELECT = 'AUTHORS__END_FETCH_AUTHORS_FOR_SELECT',
  CLEAR_SELECT_AUTHOR_DATA = 'AUTHORS__CLEAR_SELECT_AUTHOR_DATA',

  START_FETCH_AUTHOR = 'AUTHORS__START_FETCH_AUTHOR',
  END_FETCH_AUTHOR = 'AUTHORS__END_FETCH_AUTHOR',

  START_POST_AUTHOR = 'AUTHORS__START_POST_AUTHOR',
  END_POST_AUTHOR = 'AUTHORS__END_POST_AUTHOR',
  END_POST_AUTHOR_ERROR = 'AUTHORS__END_POST_AUTHOR_ERROR',

  START_PATCH_AUTHOR = 'AUTHORS__START_PATCH_AUTHOR',
  END_PATCH_AUTHOR = 'AUTHORS__END_PATCH_AUTHOR',
  END_PATCH_AUTHOR_ERROR = 'AUTHORS__END_PATCH_AUTHOR_ERROR',

  START_DELETE_AUTHOR = 'AUTHORS__START_DELETE_AUTHOR',
  END_DELETE_AUTHOR = 'AUTHORS__END_DELETE_AUTHOR',

  CLEAR_FORM_DATA = 'AUTHORS__CLEAR_FORM_DATA',

  START_UPLOAD_AUTHOR_CERTIFICATES = 'AUTHORS__START_UPLOAD_AUTHOR_CERTIFICATES',
  END_UPLOAD_AUTHOR_CERTIFICATES = 'AUTHORS__END_UPLOAD_AUTHOR_CERTIFICATES',
}

export const startFetchAuthors = createAction<ActionType.START_FETCH_AUTHORS>(ActionType.START_FETCH_AUTHORS);
export const endFetchAuthors = createAction<ActionType.END_FETCH_AUTHORS, ListResponse<Author>>(ActionType.END_FETCH_AUTHORS);

export const startFetchAuthorsForSelect = createAction<ActionType.START_FETCH_AUTHORS_FOR_SELECT>(ActionType.START_FETCH_AUTHORS_FOR_SELECT);
export const endFetchAuthorsForSelect = createAction<ActionType.END_FETCH_AUTHORS_FOR_SELECT, ListResponse<Author>>(ActionType.END_FETCH_AUTHORS_FOR_SELECT);
export const clearSelectAuthorData = createAction<ActionType.CLEAR_SELECT_AUTHOR_DATA>(ActionType.CLEAR_SELECT_AUTHOR_DATA);

export const startFetchAuthor = createAction<ActionType.START_FETCH_AUTHOR>(ActionType.START_FETCH_AUTHOR);
export const endFetchAuthor = createAction<ActionType.END_FETCH_AUTHOR, Author>(ActionType.END_FETCH_AUTHOR);

export const startPostAuthor = createAction<ActionType.START_POST_AUTHOR>(ActionType.START_POST_AUTHOR);
export const endPostAuthor = createAction<ActionType.END_POST_AUTHOR, Author>(ActionType.END_POST_AUTHOR);
export const endPostAuthorError = createAction<ActionType.END_POST_AUTHOR_ERROR>(ActionType.END_POST_AUTHOR_ERROR);

export const startUpdateAuthor = createAction<ActionType.START_PATCH_AUTHOR>(ActionType.START_PATCH_AUTHOR);
export const endUpdateAuthor = createAction<ActionType.END_PATCH_AUTHOR, Author>(ActionType.END_PATCH_AUTHOR);
export const endUpdateAuthorError = createAction<ActionType.END_PATCH_AUTHOR_ERROR>(ActionType.END_PATCH_AUTHOR_ERROR);

export const startDeleteAuthor = createAction<ActionType.START_DELETE_AUTHOR>(ActionType.START_DELETE_AUTHOR);
export const endDeleteAuthor = createAction<ActionType.END_DELETE_AUTHOR>(ActionType.END_DELETE_AUTHOR);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

export const startUploadAuthorCertificates = createAction<ActionType.START_UPLOAD_AUTHOR_CERTIFICATES>(ActionType.START_UPLOAD_AUTHOR_CERTIFICATES);
export const endUploadAuthorCertificates = createAction<ActionType.END_UPLOAD_AUTHOR_CERTIFICATES>(ActionType.END_UPLOAD_AUTHOR_CERTIFICATES);

export type Actions =
  | ReturnType<typeof startFetchAuthors>
  | ReturnType<typeof endFetchAuthors>
  | ReturnType<typeof startFetchAuthorsForSelect>
  | ReturnType<typeof endFetchAuthorsForSelect>
  | ReturnType<typeof clearSelectAuthorData>
  | ReturnType<typeof startFetchAuthor>
  | ReturnType<typeof endFetchAuthor>
  | ReturnType<typeof startPostAuthor>
  | ReturnType<typeof endPostAuthor>
  | ReturnType<typeof endPostAuthorError>
  | ReturnType<typeof startUpdateAuthor>
  | ReturnType<typeof endUpdateAuthor>
  | ReturnType<typeof endUpdateAuthorError>
  | ReturnType<typeof startDeleteAuthor>
  | ReturnType<typeof endDeleteAuthor>
  | ReturnType<typeof clearFormData>
  | ReturnType<typeof startUploadAuthorCertificates>
  | ReturnType<typeof endUploadAuthorCertificates>;