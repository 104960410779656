import { useState } from 'react';
import { Button } from 'antd';
import ExtraServicesModal from '@containers/Hospitals/components/ExtraServicesModal/ExtraServicesModal';


export default function ExtraServicesTrigger() {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleCloseModal = async () => {
    setIsOpenModal(false)
  }

  return (
    <>
      <Button type={'primary'} onClick={() => setIsOpenModal(true)}>Extra Services</Button>
      <ExtraServicesModal
        closeModal={handleCloseModal}
        isOpen={isOpenModal}
      />
    </>
  )
}