import { BaseRow, FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { getDateFormattedCell } from '../cells/DateFormattedCell';
import React, { useEffect, useState } from 'react';
import { Publication } from '@modelTypes/publication';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { Doctor } from '@modelTypes/doctor';
import IdCell from '@components/AntGrid/cells/IdCell';
import getIdCell from '@components/AntGrid/cells/IdCell';

export type DoctorPublicationColumnsNames = `${keyof Publication}Column`;

const providersTitles: Record<number, string> = {
  0: 'Pubmed',
  1: 'Researchgate',
  2: 'Semanticscholar',
};

export const doctorPublicationColumnsDefinitions: Partial<Record<DoctorPublicationColumnsNames, ColumnType<Publication>>> = {
  idColumn: createColumnDefinition<Publication>('#', 'id', { width: 80, render: getIdCell }),
  originalIdColumn: createColumnDefinition<Publication>('##', 'originalId', { width: 80, render: getIdCell }),
  titleColumn: createColumnDefinition<Publication>('Name', 'title'),
  providerColumn: createColumnDefinition<Publication>('Provider', 'provider', { render: getProviderCell, width: 200 }),
  updatedAtColumn: createColumnDefinition<Publication>('Updated At', 'updatedAt', { render: getDateFormattedCell, width: 160 }),
  publishedAtColumn: createColumnDefinition<Publication>('Published At', 'publishedAt', { render: getPublishedAtCell, width: 160 }),
};

export const doctorPublicationColumns: any = [
  doctorPublicationColumnsDefinitions.idColumn,
  doctorPublicationColumnsDefinitions.originalIdColumn,
  doctorPublicationColumnsDefinitions.titleColumn,
  doctorPublicationColumnsDefinitions.providerColumn,
  doctorPublicationColumnsDefinitions.publishedAtColumn,
  doctorPublicationColumnsDefinitions.updatedAtColumn,
];

export const doctorPublicationFiltersConfig: FiltersConfig = {
  [doctorPublicationColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [doctorPublicationColumnsDefinitions.originalIdColumn?.key as string]: { filterType: FilterType.SEARCH },
  [doctorPublicationColumnsDefinitions.titleColumn?.key as string]: { filterType: FilterType.SEARCH },
  [doctorPublicationColumnsDefinitions.publishedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [doctorPublicationColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

export function DateFormattedCell<T extends BaseRow>({ record, column }: {
  column: keyof T;
  record: T;
}): JSX.Element {
  const initValue = record[column];
  const [value, setValue] = useState<string | null>(initValue);

  useEffect(() => {
    if (!isEqual(initValue, value)) {
      setValue(initValue);
    }
  }, [initValue]);

  if (!value) {
    return (
      <>N/A</>
    );
  }

  return <>{moment(value).format('YYYY MMM')}</>;
}

export function ProviderCell({ record }: { record: Publication}): JSX.Element {
  return (
    <>{providersTitles[record.provider]}</>
  );
}

export function getPublishedAtCell<T extends BaseRow>(column: string) {
  return (value: any, record: T) => {
    return (
      <DateFormattedCell<T>
        column={column}
        record={record}
      />
    );
  };
}

export function getProviderCell() {
  return (value: any, record: Publication) => {
    return (
      <ProviderCell record={record} />
    );
  };
}
