import { History, LocationState } from 'history';

import QueryString from 'query-string';

export const updateQueryParams = <HistoryLocationState = LocationState>(
  history: History<HistoryLocationState>,
  params: Record<string, string | number | Date | null>,
  pathname?: string
): void => {
  const prevParams = QueryString.parse(history.location.search) || {};

  history.push({
    ...(pathname ? { pathname } : {}),
    search: QueryString.stringify({ ...prevParams, ...params }, { skipNull: true }),
  });
};

export const replaceQueryParams = <HistoryLocationState = LocationState>(
  history: History<HistoryLocationState>,
  params: Record<string, string | number>,
  pathname?: string
): void => {
  const prevParams = QueryString.parse(history.location.search) || {};

  history.replace({
    ...(pathname ? { pathname } : {}),
    search: QueryString.stringify({ ...prevParams, ...params }, { skipNull: true }),
  });
};

export const getHistorySearchParam = <HistoryLocationState = LocationState>(
  history: History<HistoryLocationState>,
  key: string,
  defaultValue: string | number
): string => {
  const locationParams = QueryString.parse(history.location.search.slice(1));

  if (locationParams[key] && typeof locationParams[key] === 'string' && locationParams[key]?.length) {
    return locationParams[key] as string;
  }

  return defaultValue.toString();
};

const history = {
  updateQueryParams,
  replaceQueryParams,
  getHistorySearchParam
};

export default history;
