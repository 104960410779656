import { ListResponse } from '@api/types';
import { SearchSynonym } from '@modelTypes/searchSynonym';
import { createAction } from '../helpers';

export enum ActionType {
  START_FETCH_SEARCH_SYNONYMS = 'SEARCH_SYNONYMS__START_FETCH_SEARCH_SYNONYMS',
  END_FETCH_SEARCH_SYNONYMS = 'SEARCH_SYNONYMS__END_FETCH_SEARCH_SYNONYMS',

  START_FETCH_SEARCH_SYNONYMS_FOR_SELECT = 'SEARCH_SYNONYMS__START_FETCH_SEARCH_SYNONYMS_FOR_SELECT',
  END_FETCH_SEARCH_SYNONYMS_FOR_SELECT = 'SEARCH_SYNONYMS__END_FETCH_SEARCH_SYNONYMS_FOR_SELECT',
  CLEAR_SYNONYMS_FOR_SELECT = 'SEARCH_SYNONYMS__CLEAR_SYNONYMS_FOR_SELECT',

  START_FETCH_SEARCH_SYNONYM = 'SEARCH_SYNONYMS__START_FETCH_SEARCH_SYNONYM',
  END_FETCH_SEARCH_SYNONYM = 'SEARCH_SYNONYMS__END_FETCH_SEARCH_SYNONYM',

  START_FETCH_PARENT_SEARCH_SYNONYM = 'SEARCH_SYNONYMS__START_FETCH_PARENT_SEARCH_SYNONYM',
  END_FETCH_PARENT_SEARCH_SYNONYM = 'SEARCH_SYNONYMS__END_FETCH_PARENT_SEARCH_SYNONYM',
  CLEAR_PARENT_SEARCH_SYNONYM_DATA = 'SEARCH_SYNONYMS__CLEAR_PARENT_SEARCH_SYNONYM_DATA',

  START_POST_SEARCH_SYNONYM = 'SEARCH_SYNONYMS__START_POST_SEARCH_SYNONYM',
  END_POST_SEARCH_SYNONYM = 'SEARCH_SYNONYMS__END_POST_SEARCH_SYNONYM',
  END_POST_SEARCH_SYNONYM_ERROR = 'SEARCH_SYNONYMS__END_POST_SEARCH_SYNONYM_ERROR',

  START_PATCH_SEARCH_SYNONYM = 'SEARCH_SYNONYMS__START_PATCH_SEARCH_SYNONYM',
  END_PATCH_SEARCH_SYNONYM = 'SEARCH_SYNONYMS__END_PATCH_SEARCH_SYNONYM',
  END_PATCH_SEARCH_SYNONYM_ERROR = 'SEARCH_SYNONYMS__END_PATCH_SEARCH_SYNONYM_ERROR',

  START_DELETE_SEARCH_SYNONYM = 'SEARCH_SYNONYMS__START_DELETE_SEARCH_SYNONYM',
  END_DELETE_SEARCH_SYNONYM = 'SEARCH_SYNONYMS__END_DELETE_SEARCH_SYNONYM',

  CLEAR_FORM_DATA = 'SEARCH_SYNONYMS__CLEAR_FORM_DATA',
}

export const startFetchSearchSynonyms = createAction<ActionType.START_FETCH_SEARCH_SYNONYMS>(ActionType.START_FETCH_SEARCH_SYNONYMS);
export const endFetchSearchSynonyms = createAction<ActionType.END_FETCH_SEARCH_SYNONYMS, ListResponse<SearchSynonym>>(ActionType.END_FETCH_SEARCH_SYNONYMS);

export const startFetchSearchSynonymsForSelect = createAction<ActionType.START_FETCH_SEARCH_SYNONYMS_FOR_SELECT>(ActionType.START_FETCH_SEARCH_SYNONYMS_FOR_SELECT);
export const endFetchSearchSynonymsForSelect = createAction<ActionType.END_FETCH_SEARCH_SYNONYMS_FOR_SELECT, ListResponse<SearchSynonym>>(ActionType.END_FETCH_SEARCH_SYNONYMS_FOR_SELECT);
export const clearSynonymsForSelect = createAction<ActionType.CLEAR_SYNONYMS_FOR_SELECT>(ActionType.CLEAR_SYNONYMS_FOR_SELECT);

export const startFetchSearchSynonym = createAction<ActionType.START_FETCH_SEARCH_SYNONYM>(ActionType.START_FETCH_SEARCH_SYNONYM);
export const endFetchSearchSynonym = createAction<ActionType.END_FETCH_SEARCH_SYNONYM, SearchSynonym>(ActionType.END_FETCH_SEARCH_SYNONYM);

export const startFetchParentSearchSynonym = createAction<ActionType.START_FETCH_PARENT_SEARCH_SYNONYM>(ActionType.START_FETCH_PARENT_SEARCH_SYNONYM);
export const endFetchParentSearchSynonym = createAction<ActionType.END_FETCH_PARENT_SEARCH_SYNONYM, SearchSynonym>(ActionType.END_FETCH_PARENT_SEARCH_SYNONYM);
export const clearParentSearchSynonymData = createAction<ActionType.CLEAR_PARENT_SEARCH_SYNONYM_DATA>(ActionType.CLEAR_PARENT_SEARCH_SYNONYM_DATA);

export const startPostSearchSynonym = createAction<ActionType.START_POST_SEARCH_SYNONYM>(ActionType.START_POST_SEARCH_SYNONYM);
export const endPostSearchSynonym = createAction<ActionType.END_POST_SEARCH_SYNONYM, SearchSynonym>(ActionType.END_POST_SEARCH_SYNONYM);
export const endPostSearchSynonymError = createAction<ActionType.END_POST_SEARCH_SYNONYM_ERROR>(ActionType.END_POST_SEARCH_SYNONYM_ERROR);

export const startUpdateSearchSynonym = createAction<ActionType.START_PATCH_SEARCH_SYNONYM>(ActionType.START_PATCH_SEARCH_SYNONYM);
export const endUpdateSearchSynonym = createAction<ActionType.END_PATCH_SEARCH_SYNONYM, SearchSynonym>(ActionType.END_PATCH_SEARCH_SYNONYM);
export const endUpdateSearchSynonymError = createAction<ActionType.END_PATCH_SEARCH_SYNONYM_ERROR>(ActionType.END_PATCH_SEARCH_SYNONYM_ERROR);

export const startDeleteSearchSynonym = createAction<ActionType.START_DELETE_SEARCH_SYNONYM>(ActionType.START_DELETE_SEARCH_SYNONYM);
export const endDeleteSearchSynonym = createAction<ActionType.END_DELETE_SEARCH_SYNONYM>(ActionType.END_DELETE_SEARCH_SYNONYM);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

export type Actions =
  | ReturnType<typeof startFetchSearchSynonyms>
  | ReturnType<typeof endFetchSearchSynonyms>
  | ReturnType<typeof startFetchSearchSynonymsForSelect>
  | ReturnType<typeof endFetchSearchSynonymsForSelect>
  | ReturnType<typeof clearSynonymsForSelect>
  | ReturnType<typeof startFetchSearchSynonym>
  | ReturnType<typeof endFetchSearchSynonym>
  | ReturnType<typeof startFetchParentSearchSynonym>
  | ReturnType<typeof endFetchParentSearchSynonym>
  | ReturnType<typeof clearParentSearchSynonymData>
  | ReturnType<typeof startPostSearchSynonym>
  | ReturnType<typeof endPostSearchSynonym>
  | ReturnType<typeof endPostSearchSynonymError>
  | ReturnType<typeof startUpdateSearchSynonym>
  | ReturnType<typeof endUpdateSearchSynonym>
  | ReturnType<typeof endUpdateSearchSynonymError>
  | ReturnType<typeof startDeleteSearchSynonym>
  | ReturnType<typeof endDeleteSearchSynonym>
  | ReturnType<typeof clearFormData>;