import { create } from 'zustand';
import produce from 'immer';
import { CreateArticleRequest, Params, UpdateArticleRequest } from '@api/types';
import { initialFormState } from '@zustandStorage/helpers/initialStates';
import { createArticle, getArticle, updateArticle } from '@api';
import { Blog } from '@modelTypes/blog';

export interface BlogState {
  form: {
    loading: boolean;
    row: Blog | null;
  };
  clearFormData: () => void;
  fetchBlog: (id: number, params?:Params) => Promise<Blog>;
  createBlog: (payload: CreateArticleRequest) => Promise<Blog>;
  updateBlog: (id: number, payload: UpdateArticleRequest) => Promise<Blog>;
}

export const useBlogStore = create<BlogState>((set) => ({
  form: initialFormState,
  clearFormData: () => set(() => ({ form: initialFormState })),

  fetchBlog: async (id: number, params?:Params): Promise<Blog> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return getArticle(id, params)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  createBlog: async (payload: CreateArticleRequest): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return createArticle(payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateBlog: async (id: number, payload: UpdateArticleRequest): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateArticle(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
}));