import { create } from 'zustand';
import produce from 'immer';
import { CreateUserRequest, UpdateUserRequest } from '@api/types';
import { initialFormState } from '@zustandStorage/helpers/initialStates';
import { createUser, getUser, resetGoogleSecret, updateUser } from '@api';
import { User } from '@modelTypes/user';

export interface UserState {
  form: {
    loading: boolean;
    row: User | null;
  };
  clearFormData: () => void;
  fetchUser: (id: number) => Promise<User>;
  createUser: (payload: CreateUserRequest) => Promise<User>;
  updateUser: (id: number, payload: UpdateUserRequest) => Promise<User>;
  resetGoogleSecret: (id: number) => Promise<any>;
}

export const useUserStore = create<UserState>((set) => ({
  form: initialFormState,
  clearFormData: () => set(() => ({ form: initialFormState })),
  resetGoogleSecret: async (id: number): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }))

    return resetGoogleSecret(id)
      .then((response: any) => {
        set(produce((state) => {
          state.form.loading = false;
          state.form.row = response;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  fetchUser: async (id: number): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return getUser(id)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response
          state.form.loading = false;
        }));

        return response;
      });
  },
  createUser: async (payload: CreateUserRequest): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return createUser(payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateUser: async (id: number, payload: UpdateUserRequest): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateUser(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
}));