import {
  AntGetListParams,
  CreateRoleRequest,
  CreateRoleResponse,
  GetListParams,
  ListResponse,
  Params,
  UpdateRoleRequest,
  UpdateRoleResponse,
} from '@api/types';

import { AxiosRequestConfig } from 'axios';
import { Permission } from '@modelTypes/permission';
import { Role } from '@modelTypes/role';
import client from "./client";
import { getRestUri } from '@api/helpers';

const getRootUri = getRestUri('roles');
const ROOT_URI = getRootUri('');
const PERMISSION_URI = getRootUri('permissions');

export async function getRoles(params?: AntGetListParams) {
  return client.get<ListResponse<Role>>(ROOT_URI.GET(), params);
}

export async function getRolesForSelect(params?: GetListParams) {
  return client.get<ListResponse<Role>>(ROOT_URI.GET(), params);
}

export async function getRole(id: number, params?: Params) {
  return client.get<Role>(ROOT_URI.SHOW({ id }), params);
}

export async function createRole(payload: CreateRoleRequest) {
  return client.post<CreateRoleRequest, CreateRoleResponse>(ROOT_URI.POST(), payload);
}

export async function updateRole(id: number, payload: UpdateRoleRequest) {
  return client.patch<UpdateRoleRequest, UpdateRoleResponse>(ROOT_URI.PATCH({ id }), payload);
}

export async function deleteRole(id: number, payload?: AxiosRequestConfig) {
  return client.del(ROOT_URI.DELETE({ id }), payload);
}

// Role Permissions.
export async function getPermissions(params?: GetListParams) {
  return client.get<Array<Permission>>(PERMISSION_URI.GET(), params);
}

