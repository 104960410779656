import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M4.95927 12.6039C6.22134 11.9559 6.62701 11.4004 6.67208 10.3358C6.67208 10.0118 6.67208 9.7341 6.62701 9.54896C6.58193 9.36381 6.35656 8.3918 6.04104 8.3918C5.99597 8.3918 5.9509 8.3918 5.7706 8.53065C5.54523 8.66951 5.50016 8.85466 5.54523 8.94723L5.68045 9.4101C5.90582 10.0118 5.86075 10.6598 5.63538 11.0764C5.36493 11.5393 3.83242 12.3261 1.39842 13.0204C0.812462 13.1593 0.722314 13.2519 0.67724 13.437C0.67724 13.5759 0.767387 13.8536 0.857535 13.9462L0.947684 14.0387H1.03783C1.17306 14.0387 2.6605 13.5759 3.02109 13.437C3.83242 13.113 4.28316 12.9279 4.95927 12.6039Z"
    fill="currentColor" />
  <path
    d="M6.76223 7.04964V7.09593C6.76223 7.37365 6.9876 7.41993 7.12282 7.41993C7.70878 7.55879 7.844 7.09593 7.97923 6.4942C8.06937 6.1702 8.11445 6.12391 8.11445 6.07763C8.20459 6.07763 8.56519 6.40163 9.33144 6.95707L9.42159 7.00335C10.7287 7.97537 10.7287 7.97537 10.9992 7.97537H11.4048V7.5125C11.4048 7.32736 11.4048 7.09593 11.0893 7.04964C10.9541 7.00335 10.2329 6.4942 9.82726 6.1702C9.51174 5.93877 8.56519 5.24447 8.42997 5.19818C8.2046 5.10561 7.93415 5.10561 7.70878 5.24447C7.43834 5.38333 7.25804 5.56848 7.16789 5.93877L7.12282 6.07763C7.07774 6.26277 7.03267 6.40163 6.9876 6.4942C6.8073 6.58678 6.76223 6.81821 6.76223 7.04964Z"
    fill="currentColor" />
  <path
    d="M3.15518 10.0123C3.20025 9.73462 3.20025 9.50319 3.15518 8.80889C3.1101 7.97573 3.1101 7.7443 3.24532 7.51287C3.33547 7.32772 3.51577 7.09629 4.28203 7.32772C4.64262 7.46658 4.95814 7.4203 5.22858 7.28144H5.27365C5.27365 7.32772 5.31873 7.37401 5.31873 7.4203C5.3638 7.65173 5.49902 8.06831 5.85962 8.06831C6.22021 8.06831 6.31036 7.7443 6.4005 7.46658C6.49065 7.14258 6.53573 7.00372 6.49065 6.726C6.44558 6.54085 6.31036 6.44828 6.17514 6.44828C6.08499 6.44828 5.99484 6.49457 5.85962 6.58714C5.67932 6.35571 5.3638 6.30942 4.95814 6.49457C4.73277 6.58714 4.68769 6.58714 4.37218 6.49457C4.01158 6.402 3.4707 6.35571 3.15518 6.49457C2.83966 6.63343 2.65936 6.81857 2.47907 7.14258C2.2537 7.60544 2.20862 7.97574 2.29877 8.85518C2.38892 10.0123 2.29877 10.2901 1.98325 10.6141C1.75788 10.8455 1.48744 10.9844 0.766257 11.1695C0.135221 11.3546 0.13522 11.3546 0.0901463 11.4009L0 11.4935V11.5861C0 11.7249 0.0901479 12.0027 0.180296 12.0952C0.405666 12.3729 2.16355 11.5398 2.16355 11.5398C2.74951 11.2158 3.06503 10.7066 3.15518 10.0123Z"
    fill="currentColor" />
  <path
    d="M5.72412 14.1319C6.35515 13.9468 6.94111 13.8079 7.21156 13.8079C7.61722 13.8079 8.15811 13.7153 8.42856 13.6227C8.92437 13.4376 9.14974 13.1136 9.33004 12.5119C9.42018 12.1416 9.42018 12.0953 9.916 11.6324C10.547 10.9844 10.6372 10.7993 10.6372 10.105C10.6372 9.6884 10.6372 9.50326 10.547 9.17925C10.2766 8.11466 9.96107 7.69808 9.10467 7.09636C8.83422 6.91122 8.699 6.81864 8.5187 6.86493C8.38348 6.91122 8.29333 7.05008 8.24826 7.14265L8.06796 7.46665L8.699 7.88323C9.33004 8.3461 9.46526 8.53124 9.69063 9.22554C9.78078 9.45697 9.78078 9.6884 9.82585 9.96612C9.87092 10.4753 9.82585 10.5216 9.28496 11.077C8.78915 11.5861 8.65393 11.7713 8.5187 12.2804C8.38348 12.7433 8.38348 12.8359 7.25663 12.9284C6.40023 12.9747 5.76919 13.1599 4.50712 13.669C3.29012 14.1319 1.26179 14.9651 1.26179 15.2428C1.26179 15.3353 1.30687 15.4742 1.35194 15.5205L1.48716 15.8908L2.07313 15.6593C4.14653 14.7336 4.95786 14.4096 5.72412 14.1319Z"
    fill="currentColor" />
  <path
    d="M12.4406 15.3807C12.3504 14.8253 12.0349 14.3162 11.5842 14.0847C11.2686 13.8996 10.8179 13.8533 10.5024 13.8996C10.2319 13.9459 10.1869 13.9922 10.1418 14.0384C10.0066 14.1773 10.0967 14.3162 10.1418 14.5013L10.277 14.8716L10.6376 14.779C10.9531 14.6864 11.4039 14.9179 11.5391 15.1956C11.7194 15.6122 11.6292 16.1213 11.3588 16.399C11.1785 16.5842 10.9982 16.6768 10.5925 16.6768C10.0967 16.6768 9.73613 16.5842 9.28539 16.3528C8.96988 16.2139 8.87973 16.1213 8.56421 15.7973C8.15854 15.3807 7.97825 15.0567 7.97825 14.9179C7.97825 14.8716 8.02332 14.8253 8.11347 14.8253C8.33884 14.8253 8.47406 14.779 8.51914 14.5013C8.56421 14.3162 8.56421 14.1773 8.47406 14.0384C8.38391 13.8996 8.20362 13.9459 8.02332 13.9459C7.79795 13.9459 7.66273 13.9922 7.48243 14.131C7.25706 14.3162 7.07677 14.6402 7.07677 14.9642C7.07677 15.2882 7.39229 15.8436 7.66273 16.1676C8.42899 17.0933 9.42061 17.5562 10.5475 17.5562C11.0884 17.5562 11.4039 17.4636 11.7645 17.1859C12.2603 16.8619 12.5307 16.1676 12.4406 15.3807Z"
    fill="currentColor" />
  <path
    d="M14.7396 21.2131C16.9482 20.1023 18.3005 17.9268 18.4808 15.3348C18.5258 14.733 18.5258 14.6867 18.4808 13.9462C18.3455 11.5856 17.6694 9.68781 16.5426 8.53065C15.551 7.51235 14.2438 7.0032 12.531 7.0032H11.8098L11.8549 7.88265L12.7113 7.92893C13.4775 7.97522 13.9283 8.02151 14.4692 8.25294C15.0101 8.43808 15.4157 8.7158 15.8665 9.13238C16.9032 10.1507 17.4891 11.7707 17.6244 14.1313C17.7596 16.2605 17.1736 18.0194 15.9115 19.3154C15.4608 19.7783 14.965 20.1485 14.334 20.4726C13.7931 20.7503 13.3874 20.8891 12.7113 21.028C12.0803 21.1668 10.9985 21.1668 10.4125 21.028C9.33074 20.7966 8.65463 20.4263 7.43763 19.5005C7.07704 19.1765 6.446 18.8988 6.04034 18.76C5.27408 18.5748 4.32753 18.7137 3.20068 19.1302L2.65979 19.3617L2.79501 19.7783C2.88516 20.0097 2.88516 20.0097 2.88516 20.0097C2.97531 20.1485 3.02038 20.1485 3.69649 19.9171C4.41768 19.6857 4.73319 19.5931 5.22901 19.5931C5.86004 19.5931 6.35586 19.7783 6.89675 20.1948C7.93345 21.028 8.51941 21.352 9.33074 21.6297C10.0519 21.9074 10.6379 22 11.4943 22C12.7564 21.9537 13.748 21.7223 14.7396 21.2131Z"
    fill="currentColor" />
  <path
    d="M15.37 11.2619C15.37 11.3082 15.37 11.3545 15.5052 11.6322C15.7756 12.2339 15.9559 13.1597 16.0461 14.0391C16.0461 14.3168 16.0911 14.502 16.0911 14.5483C16.0911 14.826 16.4067 14.826 16.5419 14.826H16.5869C16.6771 14.826 16.8123 14.826 16.9025 14.7334C16.9475 14.5945 16.9475 14.4557 16.9475 14.2242V14.1317C16.9025 13.2985 16.7222 12.4191 16.5419 11.8174C16.2264 10.8453 16.0911 10.8453 15.9559 10.8453C15.8207 10.8453 15.37 11.0305 15.37 11.2619Z"
    fill="currentColor" />
  <path
    d="M15.6869 10.3362C15.6869 10.2436 15.6869 10.1973 15.3263 9.82704C15.146 9.5956 15.0108 9.45675 14.8305 9.45675C14.6502 9.45675 14.56 9.5956 14.4699 9.68818L14.2896 9.9659L15.146 10.8916L15.4615 10.6602C15.6869 10.5213 15.6869 10.3825 15.6869 10.3362Z"
    fill="currentColor" />
  <path
    d="M11.5809 5.89099C11.4415 5.89099 11.3284 5.77795 11.3284 5.63852V5.37295C11.3284 5.31521 11.3482 5.25922 11.3845 5.21431L13.1263 3.05786V3.03156H11.6431C11.5036 3.03156 11.3906 2.91852 11.3906 2.77909V2.47115C11.3906 2.33171 11.5036 2.21868 11.6431 2.21868H14.1004C14.2398 2.21868 14.3528 2.33171 14.3528 2.47115V2.79433C14.3528 2.85046 14.3341 2.90499 14.2997 2.94931L12.6649 5.05181V5.07811H14.1625C14.302 5.07811 14.415 5.19114 14.415 5.33058V5.63852C14.415 5.77795 14.302 5.89099 14.1625 5.89099H11.5809Z"
    fill="currentColor" />
  <path
    d="M15.4015 3.67231C15.262 3.67231 15.149 3.55928 15.149 3.41984V3.15427C15.149 3.09653 15.1688 3.04054 15.2051 2.99563L16.9469 0.83918V0.812881H15.4636C15.3242 0.812881 15.2112 0.699846 15.2112 0.56041V0.252472C15.2112 0.113036 15.3242 0 15.4636 0H17.9209C18.0604 0 18.1734 0.113035 18.1734 0.252471V0.575656C18.1734 0.631786 18.1547 0.686316 18.1202 0.730628L16.4855 2.83313V2.85943H17.9831C18.1225 2.85943 18.2356 2.97246 18.2356 3.1119V3.41984C18.2356 3.55928 18.1225 3.67231 17.9831 3.67231H15.4015Z"
    fill="currentColor" />
</svg>`;

export default { id: '102-sleep-surgery', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
