import { ReactFlowProvider } from 'reactflow';
import StaticTagCloud from './StaticTagCloud';

export default function StaticTagCloudWithProvider() {
  return (
    <ReactFlowProvider>
      <StaticTagCloud />
    </ReactFlowProvider>
  );
}
