import Modal from 'antd/es/modal/Modal';
import { fieldIcons } from '@components/icons/fields';
import styles from './SelectIconModal.module.scss';
import { Button, Input, Tooltip } from 'antd';
import cn from 'classnames';
import { SearchOutlined } from '@ant-design/icons';
import { useState } from 'react';

interface Props {
  onClose(): void;
  onSelect(svg: string | null): void;
  selected?: string | null;
}

export default function SelectIconModal({ onClose, onSelect, selected }: Props) {
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <Modal
      open
      onCancel={onClose}
      footer={null}
      title="Select icon"
      bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
    >
      <div className={styles.searchContainer}>
        <Input
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.currentTarget.value)}
          addonBefore={<SearchOutlined />}
          placeholder="Start typing to filter icons"
        />
      </div>
      <div className={styles.grid}>
        {fieldIcons
          .filter((i) => i.id.includes(searchQuery.replace(' ', '-').toLowerCase()))
          .map((i) => (
            <Tooltip key={i.svg} placement="bottom" title={i.id}>
              <Button
                onClick={() => onSelect(selected === i.svg ? null : i.svg)}
                className={cn(styles.cell, selected === i.svg && styles.selected)}
              >
                {i.component}
              </Button>
            </Tooltip>
          ))}
      </div>
    </Modal>
  );
}
