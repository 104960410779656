import { ListResponse } from '@api/types';
import { TreatmentMethod } from '@modelTypes/treatment';
import { createAction } from '@storage/helpers';

export enum ActionType {
  START_FETCH_METHODS = 'METHODS__START_FETCH_METHODS',
  END_FETCH_METHODS = 'METHODS__END_FETCH_METHODS',

  START_FETCH_METHODS_FOR_SELECT = 'METHODS__START_FETCH_METHODS_FOR_SELECT',
  END_FETCH_METHODS_FOR_SELECT = 'METHODS__END_FETCH_METHODS_FOR_SELECT',

  START_FETCH_METHOD = 'METHODS__START_FETCH_METHOD',
  END_FETCH_METHOD = 'METHODS__END_FETCH_METHOD',

  START_POST_METHOD = 'METHODS__START_POST_METHOD',
  END_POST_METHOD = 'METHODS__END_POST_METHOD',
  END_POST_METHOD_ERROR = 'METHODS__END_POST_METHOD_ERROR',

  START_PATCH_METHOD = 'METHODS__START_PATCH_METHOD',
  END_PATCH_METHOD = 'METHODS__END_PATCH_METHOD',
  END_PATCH_METHOD_ERROR = 'METHODS__END_PATCH_METHOD_ERROR',

  START_DELETE_METHOD = 'METHODS__START_DELETE_METHOD',
  END_DELETE_METHOD = 'METHODS__END_DELETE_METHOD',

  CLEAR_FORM_DATA = 'METHODS__CLEAR_FORM_DATA',
}

export const startFetchMethods = createAction<ActionType.START_FETCH_METHODS>(ActionType.START_FETCH_METHODS);
export const endFetchMethods = createAction<ActionType.END_FETCH_METHODS, ListResponse<TreatmentMethod>>(ActionType.END_FETCH_METHODS);

export const startFetchMethodsForSelect = createAction<ActionType.START_FETCH_METHODS_FOR_SELECT>(ActionType.START_FETCH_METHODS_FOR_SELECT);
export const endFetchMethodsForSelect = createAction<ActionType.END_FETCH_METHODS_FOR_SELECT, ListResponse<TreatmentMethod>>(ActionType.END_FETCH_METHODS_FOR_SELECT);

export const startFetchMethod = createAction<ActionType.START_FETCH_METHOD>(ActionType.START_FETCH_METHOD);
export const endFetchMethod = createAction<ActionType.END_FETCH_METHOD, TreatmentMethod>(ActionType.END_FETCH_METHOD);

export const startPostMethod = createAction<ActionType.START_POST_METHOD>(ActionType.START_POST_METHOD);
export const endPostMethod = createAction<ActionType.END_POST_METHOD, TreatmentMethod>(ActionType.END_POST_METHOD);
export const endPostMethodError = createAction<ActionType.END_POST_METHOD_ERROR>(ActionType.END_POST_METHOD_ERROR);

export const startUpdateMethod = createAction<ActionType.START_PATCH_METHOD>(ActionType.START_PATCH_METHOD);
export const endUpdateMethod = createAction<ActionType.END_PATCH_METHOD, TreatmentMethod>(ActionType.END_PATCH_METHOD);
export const endUpdateMethodError = createAction<ActionType.END_PATCH_METHOD_ERROR>(ActionType.END_PATCH_METHOD_ERROR);

export const startDeleteMethod = createAction<ActionType.START_DELETE_METHOD>(ActionType.START_DELETE_METHOD);
export const endDeleteMethod = createAction<ActionType.END_DELETE_METHOD>(ActionType.END_DELETE_METHOD);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

export type Actions =
  | ReturnType<typeof startFetchMethods>
  | ReturnType<typeof endFetchMethods>
  | ReturnType<typeof startFetchMethodsForSelect>
  | ReturnType<typeof endFetchMethodsForSelect>
  | ReturnType<typeof startFetchMethod>
  | ReturnType<typeof endFetchMethod>
  | ReturnType<typeof startPostMethod>
  | ReturnType<typeof endPostMethod>
  | ReturnType<typeof endPostMethodError>
  | ReturnType<typeof startUpdateMethod>
  | ReturnType<typeof endUpdateMethod>
  | ReturnType<typeof endUpdateMethodError>
  | ReturnType<typeof startDeleteMethod>
  | ReturnType<typeof endDeleteMethod>
  | ReturnType<typeof clearFormData>;