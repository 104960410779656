import { BaseRow, FiltersConfig } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { MenuContent } from '@modelTypes/content';
import { Doctor } from '@modelTypes/doctor';
import { IconButtonRenderer } from '@components/AntGrid/renderers/renderers';
import useLanguageTabParam from '@components/AntGrid/hooks/useLanguageTabParam';
import { Link, useParams } from 'react-router-dom';
import { message } from 'antd';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import { dispatchRefreshContentsMenuGridEvent } from '@containers/Contents/components/ContentsMenuGrid';
import { deleteMenuItem } from '@storage/contents/thunkActions';
import getIdCell from '@components/AntGrid/cells/IdCell';

export type ContentMenuColumnsNames = `${keyof MenuContent}Column`
  | 'orderColumn'
  | 'linkColumn'
  | 'viewSubItemsColumn'
  | 'editColumn'
  | 'deleteColumn';

export const contentMenuColumnsDefinitions: Partial<Record<ContentMenuColumnsNames, ColumnType<MenuContent>>> = {
  idColumn: createColumnDefinition<MenuContent>('#', 'id', {  width: 80, render: getIdCell, filtered: false, sorter: false }),
  orderColumn: createColumnDefinition<any>('Order', 'order', { width: 130, filtered: false, sorter: false }),
  nameColumn: createColumnDefinition<any>('Name', 'title', { render: getNameCell, filtered: false, sorter: false }),
  linkColumn: createColumnDefinition<any>('Link', 'link', { render: getLinkCell, filtered: false, sorter: false }),
  keyColumn: createColumnDefinition<MenuContent>('Key', 'key', { filtered: false, sorter: false }),
  editColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getEditCell<MenuContent>(),
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell<MenuContent>(),
    sorter: false,
    filtered: false,
  }
};

export const contentMenuColumns: any = [
  contentMenuColumnsDefinitions.idColumn,
  contentMenuColumnsDefinitions.orderColumn,
  contentMenuColumnsDefinitions.nameColumn,
  contentMenuColumnsDefinitions.linkColumn,
  contentMenuColumnsDefinitions.keyColumn,
  contentMenuColumnsDefinitions.editColumn,
  contentMenuColumnsDefinitions.deleteColumn,
];

export const contentMenuFiltersConfig: FiltersConfig = {
  // [contentMenuColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [contentMenuColumnsDefinitions.keyColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [contentMenuColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [contentMenuColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [contentMenuColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function NameCell<T extends BaseRow>({ record }: { record: T }) {
  const { page } = useParams<{ page: string }>();
  const to = `/contents/menu/${page}/items/${record.id}`;

  return (
    <Link to={to} title={record.title}>
      {record.title}
    </Link>
  );
}

function getNameCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <NameCell<T> record={record as any} />
    );
  };
}

function getLinkCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <>{record?.link && record?.link[0] ? record?.link[0].value : ''}</>
    );
  };
}

function getDeleteCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <DeleteCell
        record={record}
        title="Delete menu"
        deleteThunkAction={deleteMenuItem}
        onSuccess={() => {
          dispatchRefreshContentsMenuGridEvent();
          message.success('The menu item has been successfully deleted');
        }}
        hasNotRelationsContent={() => <div>The menu has no any relations.</div>}
        hasRelationsContent={() => {
          return (
            <div style={{}}>
              <div>If you really want to continue, enter your password.</div>
            </div>
          );
        }}
      />
    );
  };
}

function getEditCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    const { page } = useParams<{ page: string }>();
    const langParam = useLanguageTabParam();

    return IconButtonRenderer<Doctor>(`/contents/menu/${page}/${record.id}/edit${langParam}`);
  };
}