import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg data-id="73" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
  d="M12.4223 18.5964C12.7219 18.5478 12.9716 18.5478 13.7205 18.5964C14.6193 18.645 14.8689 18.645 15.1186 18.4992C15.3183 18.4019 15.568 18.2074 15.3183 17.3808C15.1685 16.9918 15.2184 16.6515 15.3682 16.3597V16.3111C15.3183 16.3111 15.2684 16.2625 15.2184 16.2625C14.9688 16.2139 14.5194 16.068 14.5194 15.679C14.5194 15.29 14.8689 15.1928 15.1685 15.0956C15.518 14.9983 15.6678 14.9497 15.9674 14.9983C16.1671 15.0469 16.267 15.1928 16.267 15.3387C16.267 15.4359 16.2171 15.5332 16.1172 15.679C16.3669 15.8735 16.4168 16.2139 16.2171 16.6515C16.1172 16.8946 16.1172 16.9432 16.2171 17.2836C16.3169 17.6726 16.3668 18.256 16.2171 18.5964C16.0673 18.9368 15.8675 19.1313 15.518 19.3257C15.0187 19.5689 14.6193 19.6175 13.6706 19.5202C12.4223 19.423 12.1227 19.5202 11.7732 19.8606C11.5236 20.1037 11.3738 20.3954 11.1741 21.1734C10.9743 21.8541 10.9743 21.8541 10.9244 21.9028L10.8245 22H10.7247C10.5749 22 10.2753 21.9028 10.1754 21.8055C9.87585 21.5624 10.7746 19.6661 10.7746 19.6661C11.1241 19.034 11.6734 18.6936 12.4223 18.5964Z"
  fill="currentColor" />
<path
  d="M6.7824 15.732C6.53644 15.732 6.29047 15.978 6.29047 16.2731C6.29047 16.5683 6.48724 16.8143 6.7824 16.8143C7.02837 16.8143 7.27433 16.5683 7.27433 16.2731C7.27433 15.978 7.02837 15.732 6.7824 15.732Z"
  fill="currentColor" />
<path
  d="M7.07745 14.1586C7.07745 13.8634 6.88068 13.6175 6.58552 13.6175C6.33956 13.6175 6.0936 13.8634 6.0936 14.1586C6.0936 14.4538 6.29037 14.6997 6.58552 14.6997C6.88068 14.7489 7.07745 14.503 7.07745 14.1586Z"
  fill="currentColor" />
<path
  d="M5.15918 14.6021C4.91321 14.6021 4.66725 14.8481 4.66725 15.1432C4.66725 15.4384 4.86402 15.6844 5.15918 15.6844C5.40514 15.6844 5.65111 15.4384 5.65111 15.1432C5.60191 14.8481 5.40514 14.6021 5.15918 14.6021Z"
  fill="currentColor" />
<path
  d="M16.1275 12.6777H16.0775C15.778 12.6777 15.728 12.4345 15.728 12.2887C15.5782 11.6566 16.0775 11.5107 16.7266 11.3648C17.0762 11.2676 17.1261 11.219 17.176 11.219C17.176 11.1217 16.8265 10.7327 16.2273 9.90615L16.1774 9.80891C15.1289 8.39884 15.1289 8.39884 15.1289 8.10711V7.6695H15.6282C15.8279 7.6695 16.0775 7.6695 16.1275 8.00986C16.1774 8.15573 16.7266 8.93369 17.0762 9.3713C17.3258 9.71166 18.0748 10.7327 18.1247 10.8786C18.2246 11.1217 18.2246 11.4135 18.0748 11.6566C17.925 11.9483 17.7253 12.1428 17.3258 12.2401L17.176 12.2887C16.9763 12.3373 16.8265 12.3859 16.7266 12.4345C16.6268 12.629 16.3771 12.6777 16.1275 12.6777Z"
  fill="currentColor" />
<path
  d="M7.14042 6.55223C7.73959 6.64947 8.28883 6.98983 8.53848 7.47606C8.73821 7.81642 8.78814 8.30265 8.73821 8.64301C8.68828 8.93475 8.63835 8.98337 8.58842 9.032C8.43862 9.17786 8.28883 9.08062 8.08911 9.032L7.68966 8.88613L7.78952 8.49714C7.88938 8.15678 7.63973 7.67056 7.34015 7.52469C6.89077 7.33019 6.34153 7.42744 6.04195 7.71918C5.84222 7.91367 5.74236 8.10816 5.74236 8.54577C5.74236 9.08062 5.84222 9.4696 6.09188 9.95583C6.24167 10.2962 6.34153 10.3934 6.69105 10.7338C7.14042 11.1714 7.48994 11.3659 7.63973 11.3659C7.68966 11.3659 7.73959 11.3173 7.73959 11.22C7.73959 10.9769 7.78952 10.831 8.08911 10.7824C8.28883 10.7338 8.43862 10.7338 8.58842 10.831C8.73821 10.9283 8.68828 11.1228 8.68828 11.3173C8.68828 11.5604 8.63835 11.7063 8.48855 11.9007C8.28883 12.1439 7.93932 12.3384 7.5898 12.3384C7.24028 12.3384 6.64112 11.998 6.2916 11.7063C5.29298 10.8797 4.79368 9.80996 4.79368 8.59439C4.79368 8.01092 4.89354 7.67055 5.19312 7.28157C5.54264 6.74672 6.2916 6.45498 7.14042 6.55223Z"
  fill="currentColor" />
<path
  d="M7.34002 9.90672C7.43988 9.90672 7.53974 9.95534 7.6396 10.004C7.83932 9.95534 7.93919 9.95534 8.03905 10.0526C8.13891 10.1498 8.13891 10.2957 8.13891 10.3443C8.13891 10.6361 7.83932 10.8792 7.48981 10.8792C7.14029 10.8792 6.74085 10.7333 6.49119 10.5388C6.24154 10.2957 6.09175 10.004 6.09175 9.71222C6.09175 9.42049 6.24154 9.12875 6.49119 8.88563C6.69092 8.69114 7.14029 8.54528 7.43988 8.64252C7.58967 8.69114 7.6396 8.78839 7.6396 8.93426C7.6396 9.17737 7.48981 9.51773 7.24016 9.51773C7.19023 9.51773 7.09036 9.6636 7.09036 9.6636C7.09036 9.76085 7.19022 9.90672 7.34002 9.90672Z"
  fill="currentColor" />
<path
  d="M0.848821 4.07217C2.04716 1.68965 4.39391 0.230959 7.19003 0.0364672C7.83913 -0.0121557 7.88906 -0.0121557 8.68795 0.0364672C11.2344 0.182336 13.2816 0.911679 14.5299 2.12725C15.6283 3.19695 16.1776 4.60702 16.1776 6.45469V7.23265L15.2289 7.18403L15.179 6.2602C15.129 5.43361 15.0791 4.94738 14.8294 4.3639C14.6297 3.78043 14.3301 3.34282 13.8808 2.85659C12.7823 1.73827 11.0347 1.10617 8.48823 0.960302C6.19141 0.814433 4.29404 1.44653 2.89598 2.80797C2.39668 3.2942 1.99723 3.82905 1.64771 4.50977C1.34813 5.09325 1.19834 5.53085 1.04854 6.2602C0.898751 6.94092 0.898751 8.10787 1.04854 8.73996C1.2982 9.90691 1.69764 10.6363 2.69626 11.9491C3.04577 12.3381 3.34536 13.0188 3.49515 13.4564C3.69488 14.283 3.54508 15.3041 3.09571 16.5196L2.84605 17.1031L2.39667 16.9572C2.14702 16.86 2.14702 16.86 2.14702 16.86C1.99723 16.7627 1.99723 16.7141 2.24688 15.9848C2.49654 15.2068 2.5964 14.8664 2.5964 14.3316C2.5964 13.6509 2.39667 13.116 1.9473 12.5325C1.04854 11.4142 0.699028 10.7821 0.399443 9.90691C0.0998586 9.12895 0 8.49685 0 7.57301C0.0499308 6.21157 0.299582 5.14187 0.848821 4.07217Z"
  fill="currentColor" />
<path
  d="M11.5835 3.39221C11.5336 3.39221 11.4837 3.39221 11.1841 3.24634C10.535 2.9546 9.53639 2.76011 8.5877 2.66286C8.28812 2.66286 8.08839 2.61424 8.03846 2.61424C7.73888 2.61424 7.73888 2.27388 7.73888 2.12801V2.07939C7.73888 1.98214 7.73888 1.83627 7.83874 1.73903C7.98853 1.6904 8.13832 1.6904 8.38798 1.6904H8.48784C9.38659 1.73903 10.3353 1.93352 10.9844 2.12801C12.0329 2.46837 12.0329 2.61424 12.0329 2.76011C12.0329 2.90598 11.8332 3.39221 11.5835 3.39221Z"
  fill="currentColor" />
<path
  d="M12.5822 3.05033C12.682 3.05033 12.732 3.05033 13.1314 3.43931C13.3811 3.6338 13.5308 3.77967 13.5308 3.97416C13.5308 4.16865 13.3811 4.2659 13.2812 4.36314L12.9816 4.55764L11.983 3.6338L12.2326 3.29344C12.3824 3.05033 12.5322 3.05033 12.5822 3.05033Z"
  fill="currentColor" />
<path
  d="M12.9514 11.4914C12.9359 10.695 12.2748 10.0593 11.4784 10.0749C10.682 10.0905 10.0463 10.7516 10.0618 11.548C10.0774 12.3444 10.7385 12.9801 11.5349 12.9645C12.3313 12.9489 12.967 12.2878 12.9514 11.4914ZM11.5142 11.9073C11.3007 11.9115 11.1232 11.7408 11.119 11.5273C11.1148 11.3137 11.2855 11.1363 11.4991 11.1321C11.7126 11.1279 11.8901 11.2986 11.8943 11.5121C11.8985 11.7257 11.7278 11.9032 11.5142 11.9073Z"
  fill="currentColor" />
<path
  d="M21.6401 15.5397L19.9091 14.4223C19.7563 14.3237 19.5738 14.2899 19.3962 14.3287C19.2178 14.3667 19.0655 14.4726 18.9669 14.6254L18.7705 14.9289L14.5645 12.2418C14.3182 12.0845 13.9917 12.1564 13.8344 12.4027C13.677 12.6491 13.749 12.9755 13.9953 13.1328L18.1971 15.8165L18.0239 16.0843C17.82 16.3999 17.9108 16.8226 18.2264 17.0265L19.9573 18.144C20.073 18.2186 20.2049 18.2555 20.3388 18.2528C20.3825 18.252 20.4268 18.2469 20.4703 18.2376C20.6487 18.1995 20.801 18.0936 20.8995 17.9408L21.8418 16.4812C22.0457 16.1656 21.9557 15.7437 21.6401 15.5397ZM20.2159 17.0524L19.1162 16.3421L19.6506 15.5138L20.7503 16.2241L20.2159 17.0524Z"
  fill="currentColor" />
</svg>`;

export default { id: '73-skull-base-surgery', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
