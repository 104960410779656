import { Author } from './author';
import { Doctor } from '@modelTypes/doctor';
import { Hospital } from '@modelTypes/hospital';
import { SearchSection } from '@modelTypes/searchSection';

export enum BlogTypes {
  ARTICLES = 'articles',
  DISEASE_GUIDES = 'disease-guides',
  TREATMENT_GUIDES = 'treatment-guides',
}

export const BlogTypesByIndex = [
  BlogTypes.ARTICLES, BlogTypes.DISEASE_GUIDES, BlogTypes.TREATMENT_GUIDES,
];

export interface Blog {
  id: number;
  userId: number;
  numberOfViews: number;
  numberOfRequests: number;
  type: number;
  title: string;
  urlSlug: string;
  imgPath: string;
  content: string;
  metaTitle: string;
  metaDescription: string;
  isActive: boolean;
  isLanguageActive: boolean;
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
  authors: Array<Author>;
  reviewers: Array<Author>;
  doctors?: Array<Doctor>;
  hospitals?: Array<Hospital>;
  searchSections?: Array<SearchSection>;
  translations?: Array<any>;
}

export interface BlogAccessKey {
  accessKey: string;
  accessKeyCreatedAt: string;
}
