import { CreateTagRequest, ListResponse, UpdateTagRequest } from '@api/types';

import { ModalData } from './reducer';
import { Tag } from '@modelTypes/tag';
import { createAction } from '../helpers';

export enum ActionType {
  START_FETCH_TAGS = 'TAGS__START_FETCH_TAGS',
  END_FETCH_TAGS = 'TAGS__END_FETCH_TAGS',

  START_FETCH_TAGS_FOR_SELECT = 'TAGS__START_FETCH_TAGS_FOR_SELECT',
  END_FETCH_TAGS_FOR_SELECT = 'TAGS__END_FETCH_TAGS_FOR_SELECT',

  START_POST_TAG = 'TAGS__START_POST_TAG',
  START_PATCH_TAG = 'TAGS__START_PATCH_TAG',
  START_DELETE_TAG = 'TAGS__START_DELETE_TAG',

  OPEN_MODAL_WITH_FORM = 'TAGS__OPEN_MODAL_WITH_FORM',
  CLOSE_MODAL_WITH_FORM = 'TAGS__CLOSE_MODAL_WITH_FORM',
  MODAL_WITH_FORM_SET_DATA = 'TAGS__MODAL_WITH_FORM_SET_DATA',
}

export const startFetchTags = createAction<ActionType.START_FETCH_TAGS>(ActionType.START_FETCH_TAGS);
export const endFetchTags = createAction<ActionType.END_FETCH_TAGS, ListResponse<Tag>>(ActionType.END_FETCH_TAGS);

export const startFetchTagsForSelect = createAction<ActionType.START_FETCH_TAGS_FOR_SELECT>(ActionType.START_FETCH_TAGS_FOR_SELECT);
export const endFetchTagsForSelect = createAction<ActionType.END_FETCH_TAGS_FOR_SELECT, ListResponse<Tag>>(ActionType.END_FETCH_TAGS_FOR_SELECT);

export const startPostTag = createAction<ActionType.START_POST_TAG, CreateTagRequest>(ActionType.START_POST_TAG);
export const startUpdateTag = createAction<ActionType.START_PATCH_TAG, UpdateTagRequest>(ActionType.START_PATCH_TAG);
export const startDeleteTag = createAction<ActionType.START_DELETE_TAG>(ActionType.START_DELETE_TAG);

export const openModalWithForm = createAction<ActionType.OPEN_MODAL_WITH_FORM>(ActionType.OPEN_MODAL_WITH_FORM);
export const closeModalWidthForm = createAction<ActionType.CLOSE_MODAL_WITH_FORM>(ActionType.CLOSE_MODAL_WITH_FORM);
export const setModalWithFormData = createAction<ActionType.MODAL_WITH_FORM_SET_DATA, ModalData>(ActionType.MODAL_WITH_FORM_SET_DATA);

export type Actions = ReturnType<typeof startFetchTags>
  | ReturnType<typeof endFetchTags>
  | ReturnType<typeof startFetchTagsForSelect>
  | ReturnType<typeof endFetchTagsForSelect>
  | ReturnType<typeof startPostTag>
  | ReturnType<typeof startUpdateTag>
  | ReturnType<typeof startDeleteTag>
  | ReturnType<typeof openModalWithForm>
  | ReturnType<typeof closeModalWidthForm>
  | ReturnType<typeof setModalWithFormData>;