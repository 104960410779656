import { useHistory } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import * as React from 'react';
import LogsGrid from '@containers/Settings/Logs/LogsGrid';

export default function MethodsIndex(): JSX.Element {
  const history = useHistory();

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.push('/')}
        title="Logs"
      />
      <LogsGrid />
    </>
  );
}
