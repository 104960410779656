import { create } from 'zustand';
import produce from 'immer';
import { ListResponse, Params, UpdateReviewRequest, UpdateReviewResponse } from '@api/types';
import { initialFormState, initialGridState, initialSelectState } from '@zustandStorage/helpers/initialStates';
import { Review } from '@modelTypes/review';
import { deleteReview, getReviews, updateReviewsStatus } from '@api/reviewsApi';
import { AxiosRequestConfig } from 'axios';

interface Editable {
  status: boolean,
  rowId: number | null;
  form: any,
}

export interface ReviewsState {
  grid: ListResponse<Review> & {
    loading: boolean;
    editable: Editable

  };
  form: {
    loading: boolean;
    row: Review | null;
  };
  select: {
    loading: boolean;
    search: string;
    data: Array<Review>;
  }
  setGridEditable: (data: Editable) => void;
  clearGridData: () => void;
  clearFormData: () => void;
  fetchReviews: (params?: Params) => Promise<ListResponse<Review>>;
  updateReviewsStatus: (id: number, payload?: UpdateReviewRequest) => Promise<UpdateReviewResponse>;
  deleteReview: (id: number, config?: AxiosRequestConfig) => Promise<any>;


}

const editableInitialData = {
  status: false,
  rowId: null,
  form: {},
};

export const useReviewsStore = create<ReviewsState>((set) => ({
  grid: {
    ...initialGridState,
    editable: editableInitialData,
  },
  form: initialFormState,
  select: initialSelectState,
  setGridEditable: (data) => {
    set(produce((state) => {
      state.grid.editable = data;
    }))
  },
  clearGridData: () => set(() => ({ grid: { ...initialGridState, editable: editableInitialData } })),
  clearFormData: () => set(() => ({ form: initialFormState })),
  fetchReviews: async (params?: Params): Promise<ListResponse<Review>> => {
    set(produce((state) => {
      state.grid.loading = true;
    }));

    return getReviews(params)
      .then((response) => {
        set(produce((state) => {
          state.grid.data = response.data;
          state.grid.meta = response.meta;
          state.grid.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.grid.loading = false;
        }));

        throw e;
      })
  },
  updateReviewsStatus: async (id: number, payload) => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateReviewsStatus(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  deleteReview: async (id, payload) => {
    return deleteReview(id, payload)
  },
}));