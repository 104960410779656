import { create } from 'zustand';
import { ListResponse, Params } from '@api/types';
import produce from 'immer';
import { initialGridState } from '@zustandStorage/helpers/initialStates';
import { Log } from '@modelTypes/log';
import { getLogs } from '@api/log';

export interface LanguagesState {
  grid: ListResponse<Log> & {
    loading: boolean;
  };
  fetchLogs: (params?: Params) => Promise<ListResponse<Log>>;
}

export const useLogStore = create<LanguagesState>((set) => ({
  grid: initialGridState,
  fetchLogs: async (params?) => {
    set(produce((state) => {
      state.grid.loading = true;
    }));

    return getLogs(params)
      .then((response) => {
        set(produce((state) => {
          state.grid.data = response.data;
          state.grid.meta = response.meta;
          state.grid.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.grid.loading = false;
        }));

        throw e;
      })
  },
}));