import FormContainer from './components/FormContainer';
import LoginForm from './components/LoginForm';

export default function Login() {
  return (
    <FormContainer
      title="Login"
    >
      <LoginForm />
    </FormContainer>
  );
}
