import { AxiosRequestConfig } from 'axios';
import { DeleteRowParams } from '@api/types';
import { ThunkAction } from '@storage/types';
import { useDispatch } from 'react-redux';
import { ReactNode, useState } from 'react';
import { useParams } from 'react-router-dom';

export interface DeleteModalProps {
  loading: boolean,
  open?: boolean,
  showConfirmForm?: boolean,
  title?: ReactNode | string,
  content?: ReactNode,
  row?: any,
}

export interface UseDeleteModalOptions {
  deleteThunkAction?: (id: number, config?: AxiosRequestConfig) => ThunkAction<Promise<void>>;
  deleteAction?: (id: number, config?: AxiosRequestConfig) => Promise<void>;
  extendedDeleteAction?: (id: number, essenceId: number, config?: AxiosRequestConfig) => Promise<void>;
  onSuccess: (data: any) => void;
  hasRelationsContent: (data: any) => JSX.Element;
  hasNotRelationsContent: (data: any) => JSX.Element;
}

interface RouteParams {
  id: string;
}

export type UseDeleteModalResponse = [DeleteModalProps, (params: DeleteRowParams, data: any) => Promise<any>, () => void];

export default function useDeleteModal(initialDeleteModalState: DeleteModalProps, options: UseDeleteModalOptions): UseDeleteModalResponse {
  const { id } = useParams<RouteParams>();
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState<DeleteModalProps>(initialDeleteModalState);

  const onCancel = () => {
    setDeleteModal(initialDeleteModalState);
  };

  const getAction = (params: DeleteRowParams, data: any): any => {
    return options.deleteThunkAction
      ? dispatch(options.deleteThunkAction(params.id, data))
      : options.deleteAction?.(params.id, data) ? options.deleteAction?.(params.id, data) : options.extendedDeleteAction?.(params.id, +id, data);
  }

  const onDelete = async (params: DeleteRowParams, data: any): Promise<any> => {
    setDeleteModal({ ...deleteModal, loading: true });

    return getAction(params, data)
      .then((data: any) => {
        if (data.status === 'Deleted') {
          setDeleteModal(initialDeleteModalState);
          options.onSuccess(data);

          return data;
        }

        if (data.relations) {
          setDeleteModal({
            ...deleteModal,
            open: true,
            loading: false,
            row: data.row,
            showConfirmForm: true,
            content: options.hasRelationsContent(data),
          });
        } else {
          setDeleteModal({
            ...deleteModal,
            open: true,
            loading: false,
            showConfirmForm: false,
            row: data.row,
            content: options.hasNotRelationsContent(data),
          });
        }

        return data;
      })
      .catch((e: any) => {
        setDeleteModal({ ...deleteModal, loading: false });

        throw e;
      })
  };

  return [deleteModal, onDelete, onCancel];
}