import { ExclamationCircleFilled } from '@ant-design/icons';
import { CreateDoctorRequest } from '@api/types';
import { Card, Checkbox, Form, Input, message, Modal, Progress, Skeleton, Spin } from 'antd';
import { AxiosRequestConfig } from 'axios';
import 'cropperjs/dist/cropper.css';
import * as React from 'react';
import { useState, useMemo } from 'react';
import UploadVideoAction from './UploadVideoAction';
import styles from './UploadVideoModal.module.scss';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  handleSaveForm: (formData: FormData, onUploadProgress?: AxiosRequestConfig<any>['onUploadProgress']) => any;
  updateGrid: () => void;
}

export default function UploadVideoModal({ isOpen, closeModal, handleSaveForm, updateGrid }: ModalProps) {
  const { confirm } = Modal;
  const [prevVideo, setPrevVideo] = useState<Blob | null>(null);
  const videoUrlObject = useMemo(() => (prevVideo ? URL.createObjectURL(prevVideo) : ''), [prevVideo]);
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [uploadStatus, setUploadStatus] = useState<{ active: boolean; progress: number }>({
    active: false,
    progress: 0,
  });

  const [form] = Form.useForm<CreateDoctorRequest>();

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const handleSaveVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const video = event.currentTarget.files?.[0];

    if (!video) {
      return;
    }

    setPrevVideo(video);
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
    setPrevVideo(null);
  };

  const showConfirm = () => {
    confirm({
      title: 'Data not saved!',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to close modal window?',
      onOk() {
        closeModal();
        resetForm();
      },
      // onCancel() {
      //
      // },
    });
  };

  const handleCloseModal = () => {
    if (formChanged || prevVideo) {
      showConfirm();
    } else {
      closeModal();
      resetForm();
    }
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving video...',
      duration: 0,
    });

    if (prevVideo) {
      const data = form.getFieldsValue(true);
      const formData = new FormData();

      formData.append('video', prevVideo);
      formData.append('isSensitive', JSON.stringify(data.isSensitive));

      if (data.name && data.name.length) {
        formData.append('name', data.name);
      }

      setUploadStatus({ active: true, progress: 0 });

      await handleSaveForm(formData, (e) =>
        setUploadStatus((prev) => ({ ...prev, progress: (e.loaded / e.total) * 100 }))
      )
        .then((data: any) => {
          messageApi.destroy('startCreate');
          message.success('The video has been successfully created');
          resetForm();
          closeModal();
          updateGrid();
        })
        .catch((e: any) => {
          console.log(e.response);
          messageApi.destroy('startCreate');
          message.error(
            e.response.data.errors?.[0]?.args
              ? `Supported file extensions: ${e.response.data.errors[0].args.extnames.join(', ')} Max video size: ${
                  e.response.data.errors[0].args.size
                }`
              : 'An error occurred, please try again now',
            7
          );
        })
        .finally(() => {
          setUploadStatus({ active: false, progress: 0 });
        });
    }
  };

  return (
    <Modal
      okButtonProps={{ disabled: prevVideo === null || uploadStatus.active }}
      cancelButtonProps={{ disabled: uploadStatus.active }}
      title={<div>Upload video for gallery</div>}
      open={isOpen}
      onCancel={handleCloseModal}
      onOk={form.submit}
      bodyStyle={{ maxHeight: '70vh' }}
      width={510}
      okText={'Save'}
    >
      <Spin spinning={uploadStatus.active && uploadStatus.progress === 100}>
        {contextHolder}
        <Card
          className={styles.videoCard}
          style={{ width: 460 }}
          bodyStyle={{ padding: 0 }}
          cover={
            !prevVideo ? (
              <Skeleton.Image style={{ width: 460, height: 274 }} active={false} />
            ) : (
              <video style={{ width: 460, height: 274 }} src={videoUrlObject} controls />
            )
          }
          // actions={[<UploadVideoAction onChange={handleSaveVideo} />]}
          actions={[
            !uploadStatus.active && <UploadVideoAction onChange={handleSaveVideo} />,
            uploadStatus.active && <Progress percent={uploadStatus.progress} showInfo={false} />,
          ].filter(Boolean)}
        ></Card>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          id={'test'}
        >
          <Form.Item
            name="name"
            label="Caption"
            messageVariables={{ name: 'Name' }}
            rules={[{ type: 'string', max: 100 }]}
            initialValue={''}
            style={{ width: 460, margin: '16px 0 0 0' }}
          >
            <Input placeholder="Enter video caption" />
          </Form.Item>
          <Form.Item name="isSensitive" valuePropName="checked" initialValue={false} style={{ margin: '16px 0 0 0' }}>
            <Checkbox>Sensitive content</Checkbox>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}
