import { useRef, useState } from 'react';

import { HOST_URL } from '@api/client';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import cn from 'classnames';
import { editorIcons } from '@assets';
import styles from './IconsPlugin.module.scss';
import useOnClickOutside from '@hooks/useOnClickOutside';
import { valueOnly } from '@helpers/className';

interface Props {
  editor: any;
}

const icons = [
  'airo', 'chart', 'doctor', 'edit', 'microscope', 'plus', 'respect', 'search', 'destinations', 'doctors', 'hospitals',
  'overview', 'symptoms',
] as Array<keyof typeof editorIcons>;

export default function IconsPlugin(
  {
    editor
  }: Props
) {
  const [open, setOpen] = useState(false);
  const ref = useRef<any>();

  const onSelectIcon = (e: any, icon: string) => {
    e.preventDefault();
    e.stopPropagation();

    const imgSrc = `${HOST_URL}/images/templates/icons/${icon}.png`;
    editor.append(`<span style="width: 50px; height: 50px"><img src="${imgSrc}" alt="Icon ${icon}" /></span>`);
    setOpen(false);
  };

  const onOpenClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setOpen(!open);
  };

  useOnClickOutside(ref, () => setOpen(false));

  return (
    <div className={cn(styles.iconsPlugin, valueOnly(styles, open, 'open'))} ref={ref}>
      <div
        className={styles.trigger}
        onMouseDown={onOpenClick}
      >
        <InsertEmoticonIcon />
      </div>
      {open && (
        <div className={styles.icons}>
          {icons.map((icon, key) => {
            const src = editorIcons[icon];

            return (
              <div
                key={key}
                className={styles.item}
                title={`Icon ${icon}`}
                onMouseDown={(e) => onSelectIcon(e, icon)}
              >
                <img src={src} alt={`Icon ${icon}`} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
