import { FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { getDateFormattedCell } from '../cells/DateFormattedCell';

import { IconButtonRenderer } from '../renderers/renderers';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import { message } from 'antd';
import React from 'react';
import getIdCell from '@components/AntGrid/cells/IdCell';
import { VendorCompany } from '@modelTypes/vendor';
import { useVendorCompanyStore } from '@zustandStorage/vendor/company';
import { dispatchRefreshVendorsCompaniesGridEvent } from '@containers/Vendors/components/CompanyVendorsGrid';

export type VendorCompanyColumnsNames = `${keyof VendorCompany}Column` | 'eyeIconColumn' | 'deleteColumn';

export const vendorCompanyColumnsDefinitions: Partial<Record<VendorCompanyColumnsNames, ColumnType<VendorCompany>>> = {
  idColumn: createColumnDefinition<VendorCompany>('#', 'id', { width: 80, render: getIdCell }),
  orgNameColumn: createColumnDefinition<VendorCompany>('Orgname', 'orgName'),
  nameColumn: createColumnDefinition<VendorCompany>('Name', 'name'),
  updatedAtColumn: createColumnDefinition<VendorCompany>('Updated At', 'updatedAt', {
    render: getDateFormattedCell,
    width: 160
  }),
  createdAtColumn: createColumnDefinition<VendorCompany>('Created At', 'createdAt', {
    render: getDateFormattedCell,
    width: 160
  }),
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, row: VendorCompany) => {
      return IconButtonRenderer<VendorCompany>(`/vendors/company/${row.id}/edit`);
    },
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell(),
    sorter: false,
    filtered: false,
  }
};

export const vendorsCompaniesColumns: any = [
  vendorCompanyColumnsDefinitions.idColumn,
  vendorCompanyColumnsDefinitions.orgNameColumn,
  vendorCompanyColumnsDefinitions.nameColumn,
  vendorCompanyColumnsDefinitions.updatedAtColumn,
  vendorCompanyColumnsDefinitions.createdAtColumn,
  vendorCompanyColumnsDefinitions.eyeIconColumn,
  vendorCompanyColumnsDefinitions.deleteColumn,
];

export const vendorsCompaniesFiltersConfig: FiltersConfig = {
  [vendorCompanyColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [vendorCompanyColumnsDefinitions.orgNameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [vendorCompanyColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [vendorCompanyColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [vendorCompanyColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function CustomDeleteCell({ record }: { record: VendorCompany }) {
  const { deleteCompany } = useVendorCompanyStore()
  return (
    <DeleteCell
      record={record}
      title="Delete Company"
      deleteAction={deleteCompany}
      onSuccess={() => {
        dispatchRefreshVendorsCompaniesGridEvent()
        message.success('Company has been successfully deleted');
      }}
      hasNotRelationsContent={() => <div>Company has no any relations.</div>}
      hasRelationsContent={() => {
        return (
          <div style={{}}>
            <div>If you really want to continue, enter your password.</div>
          </div>
        );
      }}
    />
  );
}

function getDeleteCell() {
  return (value: any, record: VendorCompany) => {
    return (
      <CustomDeleteCell record={record} />
    );
  };
}
