import { create } from 'zustand';
import produce from 'immer';
import { CreateRoleRequest, UpdateRoleRequest, GetListParams, Params } from '@api/types';
import { initialFormRoleState } from '@zustandStorage/helpers/initialStates';
import { createRole, getPermissions, getRole, updateRole } from '@api';
import { Role } from '@modelTypes/role';
import { Permission } from '@modelTypes/permission';

export interface RoleState {
  form: {
    loading: boolean;
    row: Role | null;
    permissions:  Array<Permission> | null;
  };
  clearFormData: () => void;
  fetchRole: (id: number, params?: Params) => Promise<Role>;
  createRole: (payload: CreateRoleRequest) => Promise<Role>;
  updateRole: (id: number, payload: UpdateRoleRequest) => Promise<Role>;
  fetchPermission: (params?: GetListParams) => Promise<any>;
}

export const useRoleStore = create<RoleState>((set) => ({
  form: initialFormRoleState,
  clearFormData: () => set(() => ({ form: initialFormRoleState })),
  fetchRole: async (id: number, params?: Params): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return getRole(id, params)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response
          state.form.loading = false;
        }));

        return response;
      });
  },
  createRole: async (payload: CreateRoleRequest): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return createRole(payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateRole: async (id: number, payload: UpdateRoleRequest): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateRole(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  fetchPermission: async (params?:GetListParams): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return getPermissions(params)
      .then((response) => {
        set(produce((state) => {
          state.form.permissions = response
          state.form.loading = false;
        }));

        return response;
      });
  },
}));