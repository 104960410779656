import {
  AntGetListParams,
  CreateCountryRequest,
  CreateCountryResponse,
  GetListParams,
  ListResponse,
  Params,
  UpdateCountryRequest,
  UpdateCountryResponse
} from '@api/types';

import { AxiosRequestConfig } from 'axios';
import { Country } from '@modelTypes/country';
import client from "./client";
import { getRestUri } from '@api/helpers';

const getUri = getRestUri('countries');
const ROOT_URI = getUri('');
const GET_COUNTRIES_FOR_SELECT_URI = 'countries/select';
const GET_COUNTRIES_FOR_SELECT2_URI = 'countries/select2';

export async function getCountries(params?: AntGetListParams) {
  return client.get<ListResponse<Country>>(ROOT_URI.GET(), params);
}

export async function getCountriesForSelect(params?: GetListParams) {
  return client.get<ListResponse<Country>>(GET_COUNTRIES_FOR_SELECT_URI, params);

}export async function getCountriesForSelect2(params?: GetListParams) {
  return client.get<ListResponse<Country>>(GET_COUNTRIES_FOR_SELECT2_URI, params);
}

export async function getCountry(id: number, params?: Params) {
  return client.get<Country>(ROOT_URI.SHOW({ id }), params);
}

export async function createCountry(payload: CreateCountryRequest) {
  return client.post<CreateCountryRequest, CreateCountryResponse>(ROOT_URI.POST(), payload);
}

export async function updateCountry(id: number, payload: UpdateCountryRequest) {
  return client.patch<UpdateCountryRequest, UpdateCountryResponse>(ROOT_URI.PATCH({ id }), payload);
}

export async function deleteCountry(id: number, payload?: AxiosRequestConfig) {
  return client.del(ROOT_URI.DELETE({ id }), payload);
}
