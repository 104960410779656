import React, { useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormikHelpers } from 'formik/dist/types';
import { Language } from '../../../modelTypes/language';
import LanguageTabs from '@base/Tabs/LanguageTabs';
import Loader from '@components/Loader';
import { StandardTextFieldProps } from '@mui/material/TextField/TextField';
import TextField from '@mui/material/TextField';
import styles from './ModalWithForm.module.scss';
import { useFormik } from 'formik';

export enum ModalWithFormTypes {
  ADD = 'add',
  EDIT = 'edit',
}

export interface ModalWithFormData<FormData, Record> {
  type: ModalWithFormTypes;
  formData: FormData;
  editRecord?: Record;
}

interface RenderTextField extends StandardTextFieldProps {
  label: string,
  name: string;
}

export interface FormRenders {
  readonly renderTextField: ({ label, name }: RenderTextField) => JSX.Element;
}

export interface Props<FormData> {
  readonly open: boolean;
  readonly loading: boolean;
  readonly onClose: () => void;
  readonly onSubmit: (values: FormData, formikHelpers: FormikHelpers<FormData>) => void | Promise<void>;
  readonly form: ({ renderTextField }: FormRenders) => JSX.Element | null;
  readonly initialValues: FormData;
  readonly validationSchema: any;
  readonly title?: string;
  readonly withLanguagesTab?: boolean;
  readonly onTabChange?: (tab: Language) => void;
  readonly allowToSaveByEnterPress?: boolean;
}

type BaseFormData = Record<string, string | number | boolean | null | any>;

export default function ModalWithForm<FormData extends BaseFormData>(
  {
    open,
    loading,
    title,
    onClose,
    onSubmit,
    form,
    initialValues,
    validationSchema,
    withLanguagesTab,
    onTabChange,
    allowToSaveByEnterPress = true,
  }: Props<FormData>
): JSX.Element | null {
  const formik = useFormik<FormData>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleOnTabChange = (tab: Language) => {
    formik.setFieldValue('languageId', tab.id);
    onTabChange && onTabChange(tab);
  };

  const renderTextField = ({ name, label, ...rest }: RenderTextField): JSX.Element => {
    return (
      <TextField
        fullWidth
        error={Boolean(formik.touched[name] && formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        label={label}
        margin="normal"
        name={name}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values[name]}
        variant="outlined"
        autoComplete="off"
        {...rest}
      />
    );
  };

  const renderForm = (
    <div
      className={styles.form}
      onKeyDown={(e) => {
        if (allowToSaveByEnterPress && e.key === 'Enter') {
          formik.handleSubmit()
        }
      }}
    >
      {form({ renderTextField })}
    </div>
  );

  useEffect(() => {
    formik.resetForm();
    formik.setValues(initialValues);
  }, [open, initialValues]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      sx={{ ml: 30, mt: -15 }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>{title}</DialogTitle>
      <DialogContent>
        <Loader active={loading}>
          {withLanguagesTab && (
            <LanguageTabs
              onInit={handleOnTabChange}
              onChange={handleOnTabChange}
              content={({ label }) => {
                return renderForm;
              }}
            />
          )}
          {!withLanguagesTab && renderForm}
        </Loader>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={formik.isSubmitting}
          onClick={() => formik.handleSubmit()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
