import { Form, Radio, RadioChangeEvent } from 'antd';
import CustomSelect from '@base/CustomSelect';
import { useSelect } from '@zustandStorage/customSelect/customSelect';
import { useEffect, useState } from 'react';

interface Props {
  indexPage: boolean;
}

const doctorParamsForRadioBtn = [
  {
    value: 'specialization',
    label: 'Specialization',
  },
  {
    value: 'disease',
    label: 'Search',
  },
]

export default function Doctor({ indexPage }: Props): JSX.Element {
  const {
    fetchCountriesForSelect,
    fetchDoctorsForSelect,
    fetchCitiesForSelect,
    fetchSpecializationForSelect,
    fetchSectionDisease,
    clearFormData
  } = useSelect();
  const selectCity = useSelect((state) => state.data?.['selectCities']);
  const [paramsForRadioBtn, setParamsForRadioBtn] = useState<string>('');

  useEffect(() => {
    clearFormData('selectCountries')
  }, [indexPage])

  const handleParamsChange = (e: RadioChangeEvent) => {
    setParamsForRadioBtn(e.target.value)
  }

  return (
    <>
      {!indexPage && (
        <Form.Item
          name="doctorId"
          label="Doctor"
          valuePropName="defaultValue"
        >
          <CustomSelect
            placeholder="Select Doctor"
            selectId="selectDoctor"
            onUpdateSelect={fetchDoctorsForSelect}
            defaultQueryParams={{ page: 1, perPage: 50 }}
            onChange={() => null}
            style={{ width: '100%' }}
          />
        </Form.Item>
      )}

      {indexPage &&
        <>
          <div style={{paddingBottom: 8}}>Select Doctor by:</div>
          <Radio.Group options={doctorParamsForRadioBtn} onChange={handleParamsChange} defaultValue={paramsForRadioBtn} style={{ marginBottom: 24 }}>
            <Radio.Button />
            <Radio.Button />
          </Radio.Group>
          {paramsForRadioBtn === doctorParamsForRadioBtn[0].value &&
            <Form.Item
              name="specializationId"
              label="Specialization"
              valuePropName="defaultValue"
            >
              <CustomSelect
                placeholder="Select Specialization"
                selectId="selectSpecialization"
                onUpdateSelect={fetchSpecializationForSelect}
                defaultQueryParams={{ page: 1, perPage: 50 }}
                onChange={(value) => {
                  if (value && value.urlSlug) {
                    const params = {specializationUrlSlug: value.urlSlug, onlyDoctors: true};
                    console.log(value, 'value');
                    fetchCountriesForSelect('selectCountries', params, {
                      clearDataBeforeFetch: true,
                      saveParams: true
                    });
                  }
                }}
                style={{ width: '100%' }}
              />
            </Form.Item>
          }
          {paramsForRadioBtn === doctorParamsForRadioBtn[1].value &&
            <Form.Item
              name="diseaseId"
              label="Search"
              valuePropName="defaultValue"
            >
              <CustomSelect
                placeholder="Search"
                selectId="selectDiseases"
                onUpdateSelect={fetchSectionDisease}
                defaultQueryParams={{ page: 1, perPage: 50 }}
                onChange={(value) => {
                  if (value && value.urlSlug) {
                    const params = { disease: value.urlSlug, onlyDoctors: true};

                    fetchCountriesForSelect('selectCountries', params, {
                      clearDataBeforeFetch: true,
                      saveParams: true
                    });
                  }
                }}
                style={{ width: '100%' }}
              />
            </Form.Item>
          }
          <Form.Item
            name="countryId"
            label="Country"
            valuePropName="defaultValue"
          >
            <CustomSelect
              // disabled={!selectCountry?.opened}
              placeholder={'Select country'}
              defaultQueryParams={{ page: 1, perPage: 50 }}
              selectId={'selectCountries'}
              onUpdateSelect={fetchCountriesForSelect}
              onChange={(value) => {
                if (value && value.urlSlug && indexPage) {
                  const params = { countryUrlSlug: value.urlSlug, onlyDoctors: true };

                  fetchCitiesForSelect('selectCities', params, { clearDataBeforeFetch: true, saveParams: true });
                }
              }}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            name="cityId"
            label="City"
            valuePropName="defaultValue"
          >
            <CustomSelect
              disabled={!selectCity?.opened}
              placeholder={'Select city'}
              defaultQueryParams={{ page: 1, perPage: 50, }}
              selectId={'selectCities'}
              onUpdateSelect={fetchCitiesForSelect}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </>
      }
    </>
  );
}