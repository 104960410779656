export enum TemplateTypes {
  EMAIL = 'emails',
  LEGAL = 'legals',
}

export interface Template {
  id: number;
  isActive: boolean;
  isLanguageActive: boolean;
  type: number;
  title: string;
  code: string;
  content: string;
  metaTitle: string;
  metaDescription: string;
  updatedAt: string;
  createdAt: string;
  translations?: Array<any>;
}

export interface TemplateAccessKey {
  accessKey: string;
  accessKeyCreatedAt: string;
}

export const TemplateTypesByIndex = [
  TemplateTypes.EMAIL, TemplateTypes.LEGAL,
];