import Grid from '@components/AntGrid/Grid';
import { PaginationConfig } from '@components/AntGrid/types';
import { useLogStore } from '@zustandStorage/logs/logs';
import { Log } from '@modelTypes/log';
import { logsColumns, logsFiltersConfig } from '@components/AntGrid/columns/log';

export default function LogsGrid() {
  const { meta, data, loading } = useLogStore((state) => state.grid);
  const { fetchLogs } = useLogStore();

  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
  };

  return (
    <Grid<Log>
      id="logsGrid"
      onFetch={fetchLogs}
      columns={logsColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={logsFiltersConfig}
    />
  );
}