import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg data-id="2" width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
  d="M10.1265 16.1071C9.35648 14.6075 8.69649 14.1254 7.43148 14.0719C7.04648 14.0719 6.71648 14.0719 6.49648 14.1254C6.27648 14.179 5.12148 14.4468 5.12148 14.8217C5.12148 14.8753 5.12148 14.9288 5.28648 15.1431C5.45148 15.4109 5.67148 15.4644 5.78149 15.4109L6.33148 15.2502C7.04648 14.9824 7.81648 15.0359 8.31148 15.3037C8.86148 15.6251 9.79648 17.4461 10.6215 20.3383C10.7865 21.0346 10.8965 21.1417 11.1165 21.1953C11.2815 21.1953 11.6115 21.0882 11.7215 20.981L11.8315 20.8739V20.7668C11.8315 20.6061 11.2815 18.8387 11.1165 18.4102C10.7315 17.4461 10.5115 16.9105 10.1265 16.1071Z"
  fill="currentColor" />
<path
  d="M3.52666 13.9648H3.58166C3.91166 13.9648 3.96666 13.697 3.96666 13.5363C4.13166 12.84 3.58166 12.6793 2.86666 12.5187C2.48166 12.4115 2.42666 12.358 2.37166 12.358C2.37166 12.2509 2.75666 11.8224 3.41666 10.9119L3.47166 10.8048C4.62666 9.25154 4.62666 9.25154 4.62666 8.93018V8.44815H4.07666C3.85666 8.44815 3.58166 8.44815 3.52666 8.82306C3.47166 8.98374 2.86666 9.84069 2.48166 10.3227C2.20666 10.6976 1.38166 11.8224 1.32666 11.9831C1.21666 12.2509 1.21666 12.5722 1.38166 12.84C1.54666 13.1614 1.76666 13.3756 2.20666 13.4827L2.37166 13.5363C2.59166 13.5898 2.75666 13.6434 2.86666 13.697C2.97666 13.9112 3.25166 13.9648 3.52666 13.9648Z"
  fill="currentColor" />
<path
  d="M7.0471 18.2508C6.7171 18.1973 6.4421 18.1973 5.6171 18.2508C4.6271 18.3044 4.3521 18.3044 4.0771 18.1437C3.8571 18.0366 3.5821 17.8224 3.8571 16.9119C4.0221 16.4834 3.9671 16.1085 3.8021 15.7871V15.7336C3.8571 15.7336 3.9121 15.68 3.9671 15.68C4.2421 15.6264 4.7371 15.4658 4.7371 15.0373C4.7371 14.6088 4.3521 14.5017 4.0221 14.3946C3.6371 14.2875 3.4721 14.2339 3.1421 14.2875C2.9221 14.341 2.8121 14.5017 2.8121 14.6624C2.8121 14.7695 2.8671 14.8766 2.9771 15.0373C2.7021 15.2515 2.6471 15.6264 2.8671 16.1085C2.9771 16.3763 2.9771 16.4298 2.8671 16.8047C2.7571 17.2332 2.7021 17.8759 2.8671 18.2508C3.0321 18.6258 3.2521 18.84 3.6371 19.0542C4.1871 19.322 4.6271 19.3756 5.6721 19.2685C7.0471 19.1614 7.3771 19.2685 7.7621 19.6434C8.0371 19.9112 8.2021 20.2325 8.4221 21.0895C8.6421 21.8393 8.6421 21.8393 8.6971 21.8929L8.8071 22H8.9171C9.0821 22 9.4121 21.8929 9.5221 21.7858C9.8521 21.518 8.8621 19.4292 8.8621 19.4292C8.4771 18.7329 7.8721 18.358 7.0471 18.2508Z"
  fill="currentColor" />
<path
  d="M11.9422 15.1983C11.7222 14.4485 11.5572 13.7522 11.5572 13.4308C11.5572 12.9488 11.4472 12.3061 11.3372 11.9847C11.1172 11.3956 10.7322 11.1278 10.0172 10.9135C9.57718 10.8064 9.52218 10.8064 8.97218 10.2173C8.20218 9.46745 7.98218 9.36033 7.15718 9.36033C6.66218 9.36033 6.44218 9.36033 6.05718 9.46745C4.79218 9.7888 4.29718 10.1637 3.58218 11.1813C3.36218 11.5027 3.25218 11.6634 3.30718 11.8776C3.36218 12.0383 3.52718 12.1454 3.63718 12.199L4.02218 12.4132L4.51718 11.6634C5.06718 10.9135 5.28718 10.7529 6.11218 10.4851C6.38718 10.378 6.66218 10.378 6.99218 10.3244C7.59718 10.2708 7.65218 10.3244 8.31218 10.9671C8.91718 11.5563 9.13718 11.7169 9.74218 11.8776C10.2922 12.0383 10.4022 12.0383 10.5122 13.3773C10.5672 14.3949 10.7872 15.1447 11.3922 16.6444C11.9422 18.0905 12.9322 20.5007 13.2622 20.5007C13.3722 20.5007 13.5372 20.4471 13.5922 20.3936L14.0322 20.2329L13.7572 19.5366C12.6572 17.0729 12.2722 16.1088 11.9422 15.1983Z"
  fill="currentColor" />
<path
  d="M13.4261 7.21744C12.7661 7.32456 12.1611 7.69948 11.8861 8.23507C11.6661 8.60999 11.6111 9.14558 11.6661 9.52049C11.7211 9.84185 11.7761 9.89541 11.8311 9.94897C11.9961 10.1096 12.1611 10.0025 12.3811 9.94897L12.8211 9.78829L12.7111 9.35982C12.6011 8.9849 12.8761 8.44931 13.2061 8.28863C13.7011 8.07439 14.3061 8.18151 14.6361 8.50287C14.8561 8.7171 14.9661 8.93134 14.9661 9.41338C14.9661 10.0025 14.8561 10.431 14.5811 10.9666C14.4161 11.3415 14.3061 11.4486 13.9211 11.8235C13.4261 12.3056 13.0411 12.5198 12.8761 12.5198C12.8211 12.5198 12.7661 12.4663 12.7661 12.3591C12.7661 12.0913 12.7111 11.9307 12.3811 11.8771C12.1611 11.8235 11.9961 11.8235 11.8311 11.9307C11.6661 12.0378 11.7211 12.252 11.7211 12.4663C11.7211 12.7341 11.7761 12.8947 11.9411 13.109C12.1611 13.3768 12.5461 13.591 12.9311 13.591C13.3161 13.591 13.9761 13.2161 14.3611 12.8947C15.4611 11.9842 16.0111 10.8059 16.0111 9.46694C16.0111 8.82422 15.9011 8.44931 15.5711 8.02083C15.1861 7.43168 14.3611 7.11033 13.4261 7.21744Z"
  fill="currentColor" />
<path
  d="M13.2063 10.9125C13.0963 10.9125 12.9863 10.9661 12.8763 11.0196C12.6563 10.9661 12.5463 10.9661 12.4363 11.0732C12.3263 11.1803 12.3263 11.341 12.3263 11.3945C12.3263 11.7159 12.6563 11.9837 13.0413 11.9837C13.4263 11.9837 13.8663 11.823 14.1413 11.6088C14.4163 11.341 14.5813 11.0196 14.5813 10.6983C14.5813 10.3769 14.4163 10.0555 14.1413 9.78775C13.9213 9.57351 13.4263 9.41284 13.0963 9.51995C12.9313 9.57351 12.8763 9.68063 12.8763 9.84131C12.8763 10.1091 13.0413 10.484 13.3163 10.484C13.3713 10.484 13.4813 10.6447 13.4813 10.6447C13.4813 10.7518 13.3713 10.9125 13.2063 10.9125Z"
  fill="currentColor" />
<path
  d="M20.3565 4.48559C19.0365 1.86119 16.4515 0.254407 13.3715 0.0401695C12.6565 -0.0133898 12.6015 -0.0133898 11.7215 0.0401695C8.91648 0.200847 6.66148 1.00424 5.28648 2.34322C4.07648 3.52153 3.47148 5.07475 3.47148 7.11V7.96695L4.51648 7.91339L4.57148 6.89576C4.62648 5.98526 4.68148 5.44966 4.95648 4.80695C5.17648 4.16424 5.50648 3.6822 6.00148 3.14661C7.21148 1.91475 9.13648 1.21847 11.9415 1.0578C14.4715 0.897119 16.5615 1.59339 18.1015 3.09305C18.6515 3.62864 19.0915 4.2178 19.4765 4.96763C19.8065 5.61034 19.9715 6.09237 20.1365 6.89576C20.3015 7.64559 20.3015 8.93102 20.1365 9.62729C19.8615 10.9127 19.4215 11.7161 18.3215 13.1622C17.9365 13.5907 17.6065 14.3405 17.4415 14.8225C17.2215 15.7331 17.3865 16.8578 17.8815 18.1968L18.1565 18.8395L18.6515 18.6788C18.9265 18.5717 18.9265 18.5717 18.9265 18.5717C19.0915 18.4646 19.0915 18.411 18.8165 17.6076C18.5415 16.7507 18.4315 16.3758 18.4315 15.7866C18.4315 15.0368 18.6515 14.4476 19.1465 13.8049C20.1365 12.5731 20.5215 11.8768 20.8515 10.9127C21.1815 10.0558 21.2915 9.35949 21.2915 8.34187C21.2365 6.8422 20.9615 5.6639 20.3565 4.48559Z"
  fill="currentColor" />
<path
  d="M8.53191 3.7366C8.58691 3.7366 8.64191 3.7366 8.97191 3.57592C9.68691 3.25457 10.7869 3.04033 11.8319 2.93321C12.1619 2.93321 12.3819 2.87965 12.4369 2.87965C12.7669 2.87965 12.7669 2.50474 12.7669 2.34406V2.2905C12.7669 2.18338 12.7669 2.0227 12.6569 1.91558C12.4919 1.86202 12.3269 1.86202 12.0519 1.86202H11.9419C10.9519 1.91558 9.90691 2.12982 9.19191 2.34406C8.03691 2.71897 8.03691 2.87965 8.03691 3.04033C8.03691 3.20101 8.25691 3.7366 8.53191 3.7366Z"
  fill="currentColor" />
<path
  d="M7.43191 3.36001C7.32191 3.36001 7.26691 3.36001 6.82691 3.78849C6.55191 4.00272 6.38691 4.1634 6.38691 4.37764C6.38691 4.59188 6.55191 4.69899 6.66191 4.80611L6.99191 5.02035L8.09191 4.00272L7.81691 3.62781C7.65191 3.36001 7.48691 3.36001 7.43191 3.36001Z"
  fill="currentColor" />
</svg>`;

export default { id: '2-ear', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
