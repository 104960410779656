import { CreateUserResponse, ListResponse } from '@api/types';

import { User } from '@modelTypes/user';
import { createAction } from '../helpers';

export enum ActionType {
  START_FETCH_USERS = 'USERS__START_FETCH_USERS',
  END_FETCH_USERS = 'USERS__END_FETCH_USERS',

  START_FETCH_USERS_FOR_SELECT = 'USERS__START_FETCH_USERS_FOR_SELECT',
  END_FETCH_USERS_FOR_SELECT = 'USERS__END_FETCH_USERS_FOR_SELECT',

  START_FETCH_USER = 'USERS__START_FETCH_USER',
  END_FETCH_USER = 'USERS__END_FETCH_USER',

  START_POST_USER = 'USERS__START_POST_USER',
  END_POST_USER = 'USERS__END_POST_USER',
  END_POST_USER_ERROR = 'USERS__END_POST_USER_ERROR',

  START_PATCH_USER = 'USERS__START_PATCH_USER',
  END_PATCH_USER = 'USERS__END_PATCH_USER',
  END_PATCH_USER_ERROR = 'USERS__END_PATCH_USER_ERROR',

  START_REQUEST_PERMISSION = 'USERS__START_REQUEST_PERMISSION',
  END_REQUEST_PERMISSION = 'END_REQUEST_PERMISSION',

  START_RESET_GOOGLE_SECRET = 'USERS__START_RESET_GOOGLE_SECRET',
  END_RESET_GOOGLE_SECRET = 'USERS__END_RESET_GOOGLE_SECRET',

  CLEAR_FORM_DATA = 'USERS__CLEAR_FORM_DATA',
}

export const startFetchUsers = createAction<ActionType.START_FETCH_USERS>(ActionType.START_FETCH_USERS);
export const endFetchUsers = createAction<ActionType.END_FETCH_USERS, ListResponse<User>>(ActionType.END_FETCH_USERS);

export const startFetchUsersForSelect = createAction<ActionType.START_FETCH_USERS_FOR_SELECT>(ActionType.START_FETCH_USERS_FOR_SELECT);
export const endFetchUsersForSelect = createAction<ActionType.END_FETCH_USERS_FOR_SELECT, ListResponse<User>>(ActionType.END_FETCH_USERS_FOR_SELECT);

export const startFetchUser = createAction<ActionType.START_FETCH_USER>(ActionType.START_FETCH_USER);
export const endFetchUser = createAction<ActionType.END_FETCH_USER, User>(ActionType.END_FETCH_USER);

export const startPostUser = createAction<ActionType.START_POST_USER>(ActionType.START_POST_USER);
export const endPostUser = createAction<ActionType.END_POST_USER, CreateUserResponse>(ActionType.END_POST_USER);
export const endPostUserError = createAction<ActionType.END_POST_USER_ERROR>(ActionType.END_POST_USER_ERROR);

export const startUpdateUser = createAction<ActionType.START_PATCH_USER>(ActionType.START_PATCH_USER);
export const endUpdateUser = createAction<ActionType.END_PATCH_USER, User>(ActionType.END_PATCH_USER);
export const endUpdateUserError = createAction<ActionType.END_PATCH_USER_ERROR>(ActionType.END_PATCH_USER_ERROR);

export const startRequestPermission = createAction<ActionType.START_REQUEST_PERMISSION>(ActionType.START_REQUEST_PERMISSION);
export const endRequestPermission = createAction<ActionType.END_REQUEST_PERMISSION>(ActionType.END_REQUEST_PERMISSION);

export const startResetGoogleSecret = createAction<ActionType.START_RESET_GOOGLE_SECRET>(ActionType.START_RESET_GOOGLE_SECRET);
export const endResetGoogleSecret = createAction<ActionType.END_RESET_GOOGLE_SECRET, User>(ActionType.END_RESET_GOOGLE_SECRET);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

export type Actions =
  | ReturnType<typeof startFetchUsers>
  | ReturnType<typeof endFetchUsers>
  | ReturnType<typeof startFetchUsersForSelect>
  | ReturnType<typeof endFetchUsersForSelect>
  | ReturnType<typeof startFetchUser>
  | ReturnType<typeof endFetchUser>
  | ReturnType<typeof startPostUser>
  | ReturnType<typeof endPostUser>
  | ReturnType<typeof endPostUserError>
  | ReturnType<typeof startUpdateUser>
  | ReturnType<typeof endUpdateUser>
  | ReturnType<typeof endUpdateUserError>
  | ReturnType<typeof startRequestPermission>
  | ReturnType<typeof endRequestPermission>
  | ReturnType<typeof startResetGoogleSecret>
  | ReturnType<typeof endResetGoogleSecret>
  | ReturnType<typeof clearFormData>;