import { DragOutlined, GatewayOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
import 'cropperjs/dist/cropper.css';
import { createRef } from 'react';
import { Cropper, ReactCropperElement, ReactCropperProps } from 'react-cropper';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  photo: any;
  handleSaveImage: (data: Cropper) => void;
  cropperProps?: ReactCropperProps & { minAspectRatio?: number; maxAspectRatio?: number };
  modalClassName?: string;
}

// const initialDegreeRotate = {
//   right: 45,
//   left: -45,
// };

export default function UploadPhotoModal({
  isOpen,
  closeModal,
  photo,
  handleSaveImage,
  modalClassName,
  cropperProps: cropperPropsCustom = {},
}: ModalProps) {
  const { minAspectRatio, maxAspectRatio, ...cropperProps } = cropperPropsCustom;
  const cropperRef = createRef<ReactCropperElement>();
  // const [degreeRotate, setDegreeRotate] = useState(initialDegreeRotate);

  const onSaveImage = () => {
    if (!cropperRef.current) {
      return;
    }

    handleSaveImage(cropperRef.current.cropper);
    closeModal();
    resetCropper();
  };

  // const handleRotate = (directionRotate: string, value: number) => {
  //   setDegreeRotate((prevState) => {
  //     return { ...prevState, [directionRotate]: value };
  //   });
  // };

  const resetCropper = () => {
    cropperRef.current?.cropper?.reset();
    // setDegreeRotate(initialDegreeRotate);
  };

  const handleCloseModal = () => {
    closeModal();
    resetCropper();
  };

  return (
    <Modal
      className={modalClassName}
      open={isOpen}
      onCancel={handleCloseModal}
      onOk={onSaveImage}
      maskClosable={false}
      title={<div>Cropper image</div>}
      // footer={<CropperControlsButtons cropperRef={cropperRef} degreeRotate={degreeRotate} handleRotate={handleRotate} />}
      footer={null}
    >
      <Cropper
        ref={cropperRef}
        src={photo}
        cropmove={() => {
          const cropBoxData = cropperRef.current?.cropper?.getCropBoxData();

          if (cropBoxData && minAspectRatio && maxAspectRatio) {
            const cropBoxWidth = cropBoxData.width;
            const aspectRatio = cropBoxWidth / cropBoxData.height;

            if (aspectRatio < minAspectRatio) {
              cropperRef.current?.cropper?.setCropBoxData({ height: cropBoxWidth / minAspectRatio });
            } else if (aspectRatio > maxAspectRatio) {
              cropperRef.current?.cropper?.setCropBoxData({ height: cropBoxWidth / maxAspectRatio });
            }
          }
        }}
        {...cropperProps}
      />
      <br />
      <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Space.Compact block>
          <Button
            style={{ fontSize: 16 }}
            icon={<DragOutlined />}
            onClick={() => cropperRef.current?.cropper?.setDragMode('move')}
            type="primary"
          />
          <Button
            style={{ fontSize: 16 }}
            icon={<GatewayOutlined />}
            onClick={() => cropperRef.current?.cropper?.setDragMode('crop')}
            type="primary"
          />
        </Space.Compact>
        <Space.Compact block>
          <Button
            style={{ fontSize: 16 }}
            icon={<ZoomInOutlined />}
            onClick={() => cropperRef.current?.cropper?.zoom(0.1)}
            type="primary"
          />
          <Button
            style={{ fontSize: 16 }}
            icon={<ZoomOutOutlined />}
            onClick={() => cropperRef.current?.cropper?.zoom(-0.1)}
            type="primary"
          />
        </Space.Compact>
        {/* <Space.Compact block>
          <InputNumber
            style={{ width: 64 }}
            min={-360}
            max={0}
            value={degreeRotate.left}
            onChange={(value: number | null) => handleRotate('left', value ? value : 0)}
          />
          <Button
            style={{ fontSize: 16 }}
            icon={<RotateLeftOutlined />}
            onClick={() => cropperRef.current?.cropper?.rotateTo(degreeRotate.left)}
            type="primary"
          />
          <Button
            style={{ fontSize: 16 }}
            icon={<RotateRightOutlined />}
            onClick={() => cropperRef.current?.cropper?.rotateTo(degreeRotate.right)}
            type="primary"
          />
          <InputNumber
            style={{ width: 64 }}
            min={0}
            max={360}
            value={degreeRotate.right}
            onChange={(value: number | null) => handleRotate('right', value ? value : 0)}
          />
        </Space.Compact> */}
        <Button onClick={handleCloseModal}>Cancel</Button>
        <Button onClick={onSaveImage} type={'primary'}>
          Ok
        </Button>
      </Space>
    </Modal>
  );
}
