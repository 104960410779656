import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg data-id="29" width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_5965_332446)">
  <path
    d="M17.0395 3.03448C17.0934 2.65517 16.9317 2.27586 16.6623 1.95074C16.3389 1.62562 15.9078 1.46305 15.4767 1.46305H10.7346V1.24631C10.7346 0.541872 10.1957 0 9.49518 0H8.04021C7.33967 0 6.80079 0.541872 6.80079 1.24631V1.46305H2.16644C1.25035 1.46305 0.549805 2.16749 0.549805 3.08867V20.4286C0.549805 21.2956 1.25035 22 2.16644 22H15.4228C16.3389 22 17.0395 21.2956 17.0395 20.3744V3.03448ZM7.2319 3.57635C7.33967 3.57635 7.50134 3.52217 7.60911 3.41379C7.71689 3.30542 7.77077 3.14286 7.77077 3.03448V1.24631C7.77077 1.19212 7.77077 1.13793 7.82466 1.08374C7.87855 1.02956 7.93244 1.02956 7.98633 1.02956H9.4413C9.49518 1.02956 9.54907 1.02956 9.60296 1.08374C9.65685 1.13793 9.65685 1.19212 9.65685 1.24631V3.03448C9.65685 3.14286 9.71074 3.30542 9.81851 3.41379C9.92629 3.52217 10.088 3.57635 10.1957 3.57635C10.3035 3.57635 10.4652 3.52217 10.5729 3.41379C10.6807 3.30542 10.7346 3.14286 10.7346 3.03448V2.49261H11.8662V4.11823C11.8662 4.2266 11.8124 4.28079 11.7585 4.28079C11.7046 4.33498 11.7046 4.33498 11.6507 4.33498H5.72304C5.66915 4.33498 5.61526 4.33498 5.61526 4.28079C5.61526 4.2266 5.56137 4.17241 5.56137 4.11823V2.49261H6.69302V3.03448C6.69302 3.35961 6.96246 3.57635 7.2319 3.57635ZM2.16644 2.49261H4.48362V3.25123H2.81309C2.3281 3.25123 1.897 3.63054 1.897 4.11823V19.67C1.897 20.1576 2.3281 20.5369 2.81309 20.5369H14.6145C15.0995 20.5369 15.5306 20.1576 15.5306 19.67V4.11823C15.5306 3.63054 15.0995 3.25123 14.6145 3.25123H12.944V2.49261H15.4767C15.8001 2.49261 16.0695 2.70936 16.0695 3.03448V20.2118H16.0156V20.3744C16.0156 20.6995 15.7462 20.9163 15.4228 20.9163H2.16644C1.84311 20.9163 1.57367 20.6995 1.57367 20.3744V3.03448C1.62756 2.76355 1.897 2.49261 2.16644 2.49261ZM14.4529 19.5074H2.97476V4.28079H4.48362C4.5375 4.93103 5.07638 5.36453 5.72304 5.36453H11.5968C12.2435 5.36453 12.7285 4.93103 12.8362 4.28079H14.399V19.5074H14.4529Z"
    fill="currentColor" />
  <path
    d="M8.25625 2.2232L8.31014 2.33157C8.31014 2.33157 8.31014 2.33157 8.36403 2.38576L8.41791 2.43995H8.4718C8.57958 2.49413 8.63346 2.49414 8.68735 2.49414C8.74124 2.49414 8.84902 2.49414 8.95679 2.38576H9.01068L9.11846 2.33157C9.11846 2.33157 9.11846 2.33157 9.17234 2.27739L9.22623 2.2232V2.16901C9.28012 2.11482 9.28012 2.06064 9.28012 2.00645C9.28012 1.95226 9.28012 1.89808 9.22623 1.7897V1.73551C9.22623 1.73551 9.22623 1.73551 9.22623 1.68133L9.17234 1.62714C9.17234 1.62714 9.17234 1.62714 9.11846 1.57295L9.06457 1.51877H9.01068C8.9029 1.46458 8.74124 1.46458 8.63346 1.51877C8.57958 1.57295 8.52569 1.57295 8.4718 1.62714C8.41791 1.68133 8.41791 1.73551 8.41791 1.73551C8.25625 1.84389 8.25625 1.89808 8.25625 1.95226C8.25625 2.00645 8.25625 2.06064 8.25625 2.11482C8.25625 2.16901 8.25625 2.2232 8.25625 2.2232Z"
    fill="currentColor" />
  <path
    d="M6.47803 10.0789H7.39412V11.0001C7.39412 11.4878 7.82522 11.8671 8.31021 11.8671H9.06464C9.54963 11.8671 9.98073 11.4878 9.98073 11.0001V10.0789H10.9507C11.4357 10.0789 11.8668 9.69964 11.8668 9.21195V8.50752C11.8668 8.01983 11.4357 7.64052 10.9507 7.64052H10.0346V6.71934C10.0346 6.23165 9.60352 5.85234 9.11853 5.85234H8.3641C7.87911 5.85234 7.44801 6.23165 7.44801 6.71934V7.64052H6.53191C6.04692 7.64052 5.61582 8.01983 5.61582 8.50752V9.21195C5.61582 9.4287 5.7236 9.64545 5.88526 9.80801C6.04692 9.97057 6.26247 10.0789 6.47803 10.0789ZM9.87296 8.61589H10.7891V8.9952H9.87296C9.38797 8.9952 8.95687 9.37451 8.95687 9.8622V10.7834H8.52576V9.8622C8.52576 9.37451 8.09466 8.9952 7.60967 8.9952H6.63969V8.61589H7.55578C8.04077 8.61589 8.47187 8.23658 8.47187 7.7489V6.82771H8.90298V7.7489C8.90298 8.23658 9.33408 8.61589 9.87296 8.61589Z"
    fill="currentColor" />
  <path
    d="M5.02244 15.5504H4.26801C3.78302 15.5504 3.35191 15.9297 3.35191 16.4174V17.1218C3.35191 17.6095 3.78302 17.9888 4.26801 17.9888H5.02244C5.50743 17.9888 5.93853 17.6095 5.93853 17.1218V16.4174C5.88464 15.9297 5.50743 15.5504 5.02244 15.5504ZM4.86077 16.5799V16.9593H4.42967V16.5799H4.86077Z"
    fill="currentColor" />
  <path
    d="M6.47776 13.3318C6.31609 13.386 6.20832 13.4402 6.15443 13.4944C6.10054 13.5486 6.04666 13.6028 6.04666 13.657V13.5486C6.04666 13.0609 5.61555 12.6816 5.13056 12.6816H4.32225C4.10669 12.6274 3.89114 12.7358 3.72948 12.8442C3.51393 13.0067 3.40615 13.2777 3.40615 13.5486V14.253C3.40615 14.7407 3.83726 15.12 4.32225 15.12H5.07668C5.56167 15.12 5.99277 14.7407 5.99277 14.253V14.0905C6.10054 14.253 6.26221 14.4156 6.47776 14.4156H13.4832C13.8065 14.4156 14.0221 14.1988 14.0221 13.8737C14.0221 13.5486 13.8065 13.3318 13.4832 13.3318H6.47776ZM4.86112 13.7112V14.0905H4.43002V13.7112H4.86112Z"
    fill="currentColor" />
  <path
    d="M13.9144 16.4198C13.8066 16.3114 13.6988 16.2572 13.5372 16.2572H6.53175C6.20842 16.2572 5.99287 16.474 5.99287 16.7991C5.99287 17.1242 6.20842 17.341 6.53175 17.341H13.5372C13.8605 17.341 14.076 17.1242 14.076 16.7991V16.7449C14.0222 16.6365 13.9683 16.5282 13.9144 16.4198Z"
    fill="currentColor" />
</g>
<defs>
  <clipPath id="clip0_5965_332446">
    <rect width="17.6" height="22" fill="white" />
  </clipPath>
</defs>
</svg>`;

export default { id: '29-health-check-up', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
