import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Vendors } from '../../modelTypes/vendor';
import UserForm from '@containers/Vendors/components/UserForm';
import CompanyForm from '@containers/Vendors/components/CompanyForm';


interface RouterParams {
  seller: Vendors;
}

const vendorsScreen = {
  [Vendors.USERS]: <UserForm />,
  [Vendors.COMPANY]: <CompanyForm />,
};

export default function Create() {
  const { seller } = useParams<RouterParams>();
  return vendorsScreen[seller];

}
