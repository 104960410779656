import _, { uniqueId } from 'lodash';

import { BaseRow } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { IconButtonRenderer, } from '@components/AntGrid/renderers/renderers';
import { getUrlFromMask } from '@helpers/url';

export function createColumnDefinition<Row extends BaseRow>(
  title: string,
  key?: keyof Row,
  settings?: {
    render?: (column: string) => ColumnType<Row>['render'];
    width?: number | string;
    sorter?: boolean;
    filtered?: boolean;
  },
): ColumnType<Row> {
  const stringKey = key ? key.toString() : uniqueId();

  return {
    title,
    key: stringKey,
    dataIndex: stringKey,
    render: settings && settings.render ? settings.render(stringKey) : undefined,
    ...(settings && settings.hasOwnProperty('sorter') ? { sorter: settings.sorter } : {}),
    ...(settings && settings.hasOwnProperty('filtered') ? { filtered: settings.filtered } : {}),
    ...(settings && settings.width ? { width: settings.width } : {}),
  };
}

export function createCustomColumnDefinition<Row extends BaseRow>(settings: {
  key?: string;
  title?: string;
  width?: number | string;
  sorter?: boolean;
  fixed?: ColumnType<Row>['fixed'];
  filtered?: boolean;
  render: (column: string) => ColumnType<Row>['render'];
}): ColumnType<Row> {
  return {
    title: settings.title || '',
    width: settings.width || '58px',
    fixed: settings.fixed ? settings.fixed : undefined,
    sorter: settings.sorter || false,
    filtered: settings.filtered || false,
    render: settings.render(settings.key || ''),
  };
}

// eslint-disable-next-line max-len
export function createEyeIconColumn<T extends Record<string, any>>(
  urlMask: string,
  fieldsToReplace: Array<string>
): ColumnType<T> {
  return {
    title: '',
    width: '58px',
    render: (value: any, row: T) => {
      console.log('createEyeIconColumn, ', 'render --- ', value, row);
      return IconButtonRenderer(getUrlFromMask(urlMask, _.pick(row, fieldsToReplace)));
    },
    sorter: false,
    filtered: false,
    fixed: 'right',
  };
}


