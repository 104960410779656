import { getRefactorDoctors, patchRefactorDoctors } from '@api/doctorApi';
import { Button, Col, Form, FormProps, Input, message, Row, Spin } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { pickBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Refactor() {
  const [form] = Form.useForm();
  const firstName = useWatch('firstName', form);
  const middleName = useWatch('middleName', form);
  const secondName = useWatch('secondName', form);
  const [doctors, setDoctors] = useState<{ id: number; name: string }[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const doctors = await getRefactorDoctors();
        setDoctors(doctors as any[]);
      } catch (err: any) {
        messageApi.error(`Failed to load doctors: ${err.message}`);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key !== 's' || (!event.ctrlKey && !event.metaKey)) {
        return;
      }

      event.preventDefault();
      form.submit();
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [form]);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctors[currentIndex]?.id]);

  const handleFinish: FormProps['onFinish'] = async (values) => {
    if (!doctors[currentIndex].id) {
      return;
    }

    setSaving(true);

    try {
      await patchRefactorDoctors(
        doctors[currentIndex].id,
        pickBy(
          {
            firstName: values.firstName,
            middleName: values.middleName,
            secondName: values.secondName,
            title: values.title,
            postNominals: values.postNominals,
          },
          Boolean
        )
      );
      // await new Promise((resolve, reject) =>
      //   setTimeout(() => (Math.random() > 0.5 ? resolve(undefined) : reject(new Error('Invalid doctor id'))), 2500)
      // );
      messageApi.success('Successfully updated doctor');
      setCurrentIndex((prev) => prev + 1);
    } catch (err: any) {
      messageApi.error(`Failed to update doctor: ${err.message}`);
    } finally {
      setSaving(false);
    }
  };

  if (currentIndex >= doctors.length) {
    return <h1>End reached</h1>;
  }

  return (
    <Spin spinning={saving}>
      {contextHolder}
      <Form form={form} onFinish={handleFinish} layout="vertical" style={{ margin: '50px 25px 0 25px' }}>
        <Link
          to={`/doctors/edit/${doctors[currentIndex].id}`}
          style={{ flexShrink: 0, display: 'block', marginBottom: 15 }}
          target="_blank"
        >
          #{doctors[currentIndex].id}
        </Link>
        <Form.Item
          name="name"
          label="Name"
          messageVariables={{ name: 'Name' }}
          rules={[{ type: 'string', max: 255 }]}
          initialValue={doctors[currentIndex].name}
        >
          <Input placeholder="Enter name" readOnly />
        </Form.Item>
        <Row gutter={[16, 0]}>
          <Col span={9}>
            <Form.Item
              name="title"
              label="Title"
              messageVariables={{ name: 'Title' }}
              rules={[{ type: 'string', max: 64 }]}
            >
              <Input placeholder="Enter title" />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              name="firstName"
              label="First Name"
              messageVariables={{ name: 'First name' }}
              rules={[{ required: true, type: 'string', max: 64 }]}
            >
              <Input placeholder="Enter first name" />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              name="middleName"
              label="Middle Name"
              messageVariables={{ name: 'Middle Name' }}
              rules={[{ type: 'string', max: 64 }]}
            >
              <Input placeholder="Enter middle name" />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              name="secondName"
              label="Second Name"
              messageVariables={{ name: 'Second name' }}
              rules={[{ required: true, type: 'string', max: 64 }]}
            >
              <Input placeholder="Enter second name" />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              name="postNominals"
              label="Post Nominals"
              messageVariables={{ name: 'Post Nominals' }}
              rules={[{ type: 'string', max: 64 }]}
            >
              <Input placeholder="Enter post nominals" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ width: 'fit-content', marginLeft: 'auto' }}>
          <Button style={{ marginLeft: 'auto' }} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
}
