import { UploadOutlined } from '@ant-design/icons';
import { CreateGalleriesVideoRequest } from '@api/types';
import { Button } from 'antd';
import { useState } from 'react';
import AddVideoGalleriesModal from './AddVideoGalleriesModal';

interface Props {
  handleSaveForm: (formData: CreateGalleriesVideoRequest) => any;
  updateGrid: () => void;
}

export default function UploadVideoGalleriesButton({ handleSaveForm, updateGrid }: Props) {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleCloseModal = async () => {
    setIsOpenModal(false)
  }

  return (
    <>
      <Button icon={<UploadOutlined />} onClick={() => setIsOpenModal(true)}>Add Video</Button>
      <AddVideoGalleriesModal closeModal={handleCloseModal} isOpen={isOpenModal} handleSaveForm={handleSaveForm} updateGrid={updateGrid}/>
    </>
  )
}