import { useEffect, useState } from 'react';

import LocalStorage from '@base/LocalStorage';
import moment from 'moment';
import { FieldTimeOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface Props {
  // children: ReactNode,
}

export default function SessionTimer(
  {
  }: Props
): JSX.Element {
  const tokensStoredAt = moment(LocalStorage.getObject('tokensStoredAt'));
  const targetTime = tokensStoredAt.add(6, 'hours');

  const [currentTime, setCurrentTime] = useState(moment());
  const timeBetween = moment.duration(targetTime.diff(currentTime));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  
  return (
    <Tooltip title="Session timer">
      <div style={{ width: 140, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <FieldTimeOutlined style={{ fontSize: 22, marginRight: 6 }} />

        {/*<IconButton>*/}
        {/*  <AvTimerIcon fontSize="small" />*/}
        {/*</IconButton>*/}
        <div style={{ color: '#000' }}>
          <span>{timeBetween.hours()}h </span>
          <span>{timeBetween.minutes()}min </span>
          <span>{timeBetween.seconds()}s </span>
        </div>
      </div>
    </Tooltip>
  );
}