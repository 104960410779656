import { useHistory, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import ContentsGrid from '@containers/Contents/components/ContentsGrid';
import { indexPageTitles, indexPageUri } from './helpers';
import useChangeGridOptions from '@components/AntGrid/hooks/useChangeGridOptions';

interface RouterParams {
  page: string;
}

export default function ContentsIndex(): JSX.Element {
  const history = useHistory();
  const { page } = useParams<RouterParams>();
  useChangeGridOptions('contentAntGrid');
  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.push("/")}
        title={`Content / ${indexPageTitles[indexPageUri[page]]}`}
      />
      <ContentsGrid pageName={indexPageUri[page]} />
    </>
  );
}
