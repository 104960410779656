import { CheckCircleFilled } from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import cn from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Handle, Node, NodeProps, Position, XYPosition, useReactFlow } from 'reactflow';
import SelectIconModal from '../SelectIconModal/SelectIconModal';
import styles from './CustomNode.module.scss';

export enum NodeType {
  Default = 0,
  MedicalField = 100,
}

export interface CustomNodeProps {
  id?: number;
  type?: NodeType;
  name: string;
  highlightLevel?: number;
  svg?: string | null;
  createdAt?: string;
  updatedAt?: string;
  position?: XYPosition;
}

export default function CustomNode({
  id,
  selected,
  data: { type, name, highlightLevel, svg },
}: NodeProps<CustomNodeProps>) {
  const [isEditing, setIsEditing] = useState(false);
  const input = useRef<HTMLTextAreaElement>(null);
  const [isContextMenuOpen, setContextMenuOpen] = useState(false);
  const flowState = useReactFlow();
  const [selectingIcon, setSelectingIcon] = useState(false);
  const [draftName, setDraftName] = useState(name);
  const history = useHistory();

  const toggleMain = () => {
    const updatedNodes = JSON.parse(JSON.stringify(flowState.getNodes())) as Node<CustomNodeProps>[];
    const foundNode = updatedNodes.find((i) => i.id === id);

    if (!foundNode) {
      return;
    }

    if (foundNode.data.type === NodeType.MedicalField) {
      foundNode.data.type = NodeType.Default;
    } else {
      foundNode.data.type = NodeType.MedicalField;
    }

    flowState.setNodes(updatedNodes);
  };

  const handleIconSelect = (svg: string | null) => {
    const updatedNodes = JSON.parse(JSON.stringify(flowState.getNodes())) as Node<CustomNodeProps>[];
    const foundNode = updatedNodes.find((i) => i.id === id);

    if (!foundNode) {
      return;
    }

    foundNode.data.svg = svg;
    flowState.setNodes(updatedNodes);
  };

  const contextMenu = useMemo<MenuProps['items']>(
    () => [
      {
        label: 'Select icon',
        key: 'select-icon',
        onClick: () => setSelectingIcon(true),
      },
      {
        label: 'Main',
        key: 'main',
        icon: type === NodeType.MedicalField ? <CheckCircleFilled /> : undefined,
        onClick: toggleMain,
      },
      {
        label: 'Open in Playground',
        key: 'open-in-playground',
        onClick: () => window.open(`/search-engine/playground?selected=${id}`, '_blank'),
      },
      {
        label: 'Delete',
        key: 'delete',
        onClick: () => flowState.setNodes((prev) => [...prev.filter((i) => i.id !== id)]),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type]
  );

  useEffect(() => {
    const handleClick = () => {
      setContextMenuOpen(false);
    };

    window.addEventListener('click', handleClick);

    return () => window.removeEventListener('click', handleClick);
  }, []);

  useEffect(() => {
    setDraftName(name);
  }, [name]);

  useEffect(() => {
    if (!input.current) {
      return;
    }

    const handleCopy = (e: any) => {
      if (isEditing) {
        e.stopPropagation();
      }
    };

    const handlePaste = (e: any) => {
      if (isEditing) {
        e.stopPropagation();
      }
    };

    const textarea = input.current;
    textarea.addEventListener('copy', handleCopy);
    textarea.addEventListener('paste', handlePaste);

    return () => {
      textarea.removeEventListener('copy', handleCopy);
      textarea.removeEventListener('paste', handlePaste);
    };
  }, [isEditing]);

  const handleDoubleClick = () => {
    if (!input.current) {
      return;
    }

    if (!isEditing) {
      input.current.setSelectionRange(0, 0);
      input.current.focus();
    }

    setIsEditing(true);

    // input.current.select();
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    console.log('default, ', 'handleInputKeyDown --- ', event.key);

    if (event.key !== 'Enter') {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.blur();
  };

  const handleInputBlur = () => {
    flowState.setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              name: draftName,
            },
          };
        }
        return node;
      })
    );

    setIsEditing(false);
    getSelection()?.removeAllRanges();
  };

  return (
    <>
      <Dropdown
        menu={{ items: contextMenu }}
        trigger={['contextMenu']}
        open={isContextMenuOpen}
        onOpenChange={(open) => setContextMenuOpen(open)}
      >
        <div
          className={cn(
            styles.node,
            highlightLevel && styles.highlighted,
            highlightLevel && styles[`level-${highlightLevel}`],
            selected && styles.selected,
            type === NodeType.MedicalField && styles.main
          )}
          onDoubleClick={handleDoubleClick}
        >
          <Handle type="source" position={Position.Top} className={cn(styles.handle, isEditing && styles.disabled)} />
          {svg && (
            <div
              className={cn(styles.iconContainer, type === NodeType.MedicalField && styles.main)}
              dangerouslySetInnerHTML={{ __html: svg }}
            />
          )}
          <textarea
            ref={input}
            value={draftName}
            onChange={(e) => setDraftName(e.currentTarget.value)}
            onBlur={handleInputBlur}
            onKeyDown={handleInputKeyDown}
            readOnly={!isEditing}
            className={cn(styles.input, isEditing && 'nodrag', type === NodeType.MedicalField && styles.main)}
            data-nodetextarea={true}
          />
        </div>
      </Dropdown>
      {selectingIcon && (
        <SelectIconModal onClose={() => setSelectingIcon(false)} onSelect={handleIconSelect} selected={svg} />
      )}
    </>
  );
}
