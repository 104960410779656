import { Form, FormInstance } from 'antd';
import CustomSelect from '@base/CustomSelect';
import { useSelect } from '@zustandStorage/customSelect/customSelect';

interface Props {
  indexPage: boolean;
  form: FormInstance;
}

export default function HealthLibrary(
  {
    indexPage,
    form
  }: Props
): JSX.Element | null {
  const { fetchCountriesForSelect, fetchSectionDisease, fetchCitiesForSelect, clearFormData } = useSelect();
  const selectCountry = useSelect((state) => state.data?.['selectCountries']);
  const selectCity = useSelect((state) => state.data?.['selectCities']);
  const resetCountryAndCitySelects = () => {
    clearFormData('selectCountries')
    clearFormData('selectCities')
    form.resetFields(['countryId', 'cityId']);
  };

  if (!indexPage) {
    return (
      <>
        <Form.Item
          name="diseaseId"
          label="Search"
          valuePropName="defaultValue"
        >
          <CustomSelect
            placeholder="Search"
            selectId="selectDiseases"
            onUpdateSelect={fetchSectionDisease}
            defaultQueryParams={{ page: 1, perPage: 50 }}
            onClear={() => resetCountryAndCitySelects()}
            onChange={(value) => {
              if (value && value.urlSlug) {
                const params = { onlyHealthLibrary: true, disease: value.urlSlug };

                resetCountryAndCitySelects();
                fetchCountriesForSelect('selectCountries', params, { clearDataBeforeFetch: true, saveParams: true });
              }
            }}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name="countryId"
          label="Country"
          valuePropName="defaultValue"
        >
          <CustomSelect
            disabled={!selectCountry?.opened}
            placeholder={'Select country'}
            defaultQueryParams={{ page: 1, perPage: 22 }}
            selectId={'selectCountries'}
            onUpdateSelect={fetchCountriesForSelect}
            onChange={(value) => {
              if (value && value.urlSlug) {
                const params = { onlyHealthLibrary: true, disease: selectCountry?.params.disease, countryUrlSlug: value.urlSlug };

                fetchCitiesForSelect('selectCities', params, { clearDataBeforeFetch: true, saveParams: true });
              }
            }}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name="cityId"
          label="City"
          valuePropName="defaultValue"
        >
          <CustomSelect
            disabled={!selectCity?.opened}
            placeholder={'Select city'}
            defaultQueryParams={{ page: 1, perPage: 50 }}
            selectId={'selectCities'}
            onUpdateSelect={fetchCitiesForSelect}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </>
    );
  }

  return null;
}