import { CreateProgramDiscountRequest, CreateProgramDiscountResponse } from '@api/types';
import currency from 'currency.js';

export const prepareDataForBackend = (data: CreateProgramDiscountRequest): CreateProgramDiscountRequest => {
  let newValue = data.value;

  return {
    ...data,
    value: currency(newValue).multiply(100).value,
  };
};

export const prepareDataForFrontend = (data: CreateProgramDiscountResponse): CreateProgramDiscountResponse => {
  let newValue = data.value;

  return {
    ...data,
    value: currency(newValue).divide(100).value,
  };
};
