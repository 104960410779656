import {
  ClearCacheRequest,
  ClearCacheResponse,
} from '@api/types';

import client from "./client";

const CLEAR_CACHE_URI = 'cache/clear';

export async function clearCache(payload: ClearCacheRequest) {
  return client.post<ClearCacheRequest, ClearCacheResponse>(CLEAR_CACHE_URI, payload);
}