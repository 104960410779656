import {
  AntGetListParams,
  CreateDoctorContentRequest,
  CreateDoctorRequest,
  CreateDoctorResponse,
  CreateExcludedWordRequest,
  CreateExcludedWordResponse,
  DoctorContentRequest,
  GetListParams,
  ListResponse,
  Params,
  UpdateDoctorRequest,
  UpdateDoctorResponse,
  UpdateExcludedWordRequest,
  UpdateExcludedWordResponse,
} from '@api/types';

import { AxiosRequestConfig } from 'axios';
import { Doctor } from '../modelTypes/doctor';
import client from './client';
import { getRestUri } from '@api/helpers';
import { ExcludedWord } from '@modelTypes/excludedWord';
import { Skill, SkillOffer } from '@modelTypes/skill';

const getUri = getRestUri('doctors');
const ROOT_URI = getUri('');
const GET_DOCTORS_FOR_SELECT_URI = 'doctors/select';
const UPDATE_DOCTORS_FILTERS_COUNT_URI = 'doctors/update-filters-count';

const GET_DOCTORS_CONTENTS_URI = 'doctors/contents';
const GET_DOCTORS_CONTENTS_BY_URI = 'doctors/contents/relations';

const GET_DOCTORS_SKILLS_STOP_WORDS_URI = 'doctors/skills/stop-words';
const POST_DOCTORS_SKILLS_STOP_WORDS_URI = 'doctors/skills/stop-words';
const PATCH_DOCTORS_SKILLS_STOP_WORDS_URI = (id: number) => `doctors/skills/stop-words/${id}`;
const DELETE_DOCTORS_SKILLS_STOP_WORDS_URI = (id: number) => `doctors/skills/stop-words/${id}`;

const GET_DOCTOR_SKILLS_OFFERS_URI = (id: number) => `doctors/${id}/skills/offers`;
const GET_DOCTOR_SKILLS_URI = (id: number) => `doctors/${id}/skills`;
const POST_DOCTOR_SKILLS_URI = (id: number) => `doctors/${id}/skills`;
const PUT_DOCTOR_SKILLS_URI = (id: number) => `doctors/${id}/skills/multiple`;
const DELETE_DOCTOR_SKILLS_URI = (id: number, essenceId: number) => `doctors/${essenceId}/skills/${id}`;

const POST_PARSE_DATA_URI = (id: number) => `doctors/${id}/parse-data`;
const POST_GENERATE_ABOUT_URI = (id: number) => `doctors/${id}/generate-about`;
const GET_REFACTOR_DOCTORS_URI = `doctors/new-name-task`;
const PATCH_REFACTOR_DOCTORS_URI = (id: number) => `doctors/${id}/new-name-task`;
const GET_DOCTOR_PUBLICATIONS_URI = (id: number) => `doctors/${id}/publications`;

export async function getDoctors(params?: AntGetListParams) {
  return client.get<ListResponse<Doctor>>(ROOT_URI.GET(), params);
}

export async function getDoctorsForSelect(params?: GetListParams) {
  return client.get<ListResponse<Doctor>>(GET_DOCTORS_FOR_SELECT_URI, params);
}

export async function getDoctor(id: number, params?: Params) {
  return client.get<Doctor>(ROOT_URI.SHOW({ id }), params);
}

export async function createDoctor(payload: CreateDoctorRequest) {
  return client.post<CreateDoctorRequest, CreateDoctorResponse>(ROOT_URI.POST(), payload);
}

export async function updateDoctor(id: number, payload: UpdateDoctorRequest) {
  return client.patch<UpdateDoctorRequest, UpdateDoctorResponse>(ROOT_URI.PATCH({ id }), payload);
}

export async function updateDoctorsFiltersCount() {
  return client.post<any, any>(UPDATE_DOCTORS_FILTERS_COUNT_URI);
}

export async function deleteDoctor(id: number, payload?: AxiosRequestConfig) {
  return client.del(ROOT_URI.DELETE({ id }), payload);
}

export async function doctorParseData(id: number) {
  return client.post<void, any>(POST_PARSE_DATA_URI(id));
}

export async function doctorGenerateAbout(id: number) {
  return client.post<void, any>(POST_GENERATE_ABOUT_URI(id));
}

export async function getRefactorDoctors(params?: AntGetListParams) {
  return client.get(GET_REFACTOR_DOCTORS_URI, params);
}

export async function patchRefactorDoctors(id: number, payload?: any) {
  return client.patch(PATCH_REFACTOR_DOCTORS_URI(id), payload);
}

// Content.
export async function getDoctorsContents(payload?: Params) {
  return client.get<any>(GET_DOCTORS_CONTENTS_URI, payload);
}

export async function getDoctorsContentsBy(relations: DoctorContentRequest, payload?: Params) {
  return client.get<any>(GET_DOCTORS_CONTENTS_BY_URI, { relations, ...payload });
}

export async function updateDoctorContentByPlace(payload?: CreateDoctorContentRequest) {
  return client.patch<CreateDoctorContentRequest, any>(GET_DOCTORS_CONTENTS_BY_URI, payload);
}

// Publications.
export async function getDoctorPublications(id: number, payload?: Params) {
  return client.get<any>(GET_DOCTOR_PUBLICATIONS_URI(id), payload);
}

//Stop Words
export async function getStopWords(payload?: Params) {
  return client.get<ListResponse<ExcludedWord>>(GET_DOCTORS_SKILLS_STOP_WORDS_URI, payload);
}

export async function createStopWord(payload: CreateExcludedWordRequest) {
  return client.post<CreateExcludedWordRequest, CreateExcludedWordResponse>(
    POST_DOCTORS_SKILLS_STOP_WORDS_URI,
    payload
  );
}

export async function updateStopWord(id: number, payload?: UpdateExcludedWordRequest) {
  return client.patch<UpdateExcludedWordRequest, UpdateExcludedWordResponse>(
    PATCH_DOCTORS_SKILLS_STOP_WORDS_URI(id),
    payload
  );
}

export async function deleteWord(id: number, payload?: AxiosRequestConfig) {
  return client.del(DELETE_DOCTORS_SKILLS_STOP_WORDS_URI(id), payload);
}

//Skills

export async function getDoctorSkillsOffers(id: number, payload?: Params) {
  return client.get<ListResponse<SkillOffer>>(GET_DOCTOR_SKILLS_OFFERS_URI(id), payload);
}

export async function getDoctorSkills(id: number, payload?: Params) {
  return client.get<ListResponse<Skill>>(GET_DOCTOR_SKILLS_URI(id), payload);
}

export async function createDoctorSkill(id: number, payload?: Params) {
  return client.post<any, any>(POST_DOCTOR_SKILLS_URI(id), payload);
}

export async function updateDoctorSkills(id: number, payload?: Params) {
  return client.put<any, any>(PUT_DOCTOR_SKILLS_URI(id), payload);
}

export async function deleteDoctorSkill(id: number, essenceId: number, payload?: AxiosRequestConfig) {
  return client.del(DELETE_DOCTOR_SKILLS_URI(id, essenceId), payload);
}
