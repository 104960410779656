import { create } from 'zustand';
import { Service } from '@modelTypes/service';
import { ListResponse, Params } from '@api/types';
import produce from 'immer';
import { getHospitalServices } from '@api/hospitalApi';

export interface ServicesState {
  loading: boolean;
  data: Array<Service>;
  resetData: () => void;
  fetchServices: (params?: Params) => Promise<ListResponse<Service>>;
}

export const useHospitalServices = create<ServicesState>((set) => ({
  loading: false,
  data: [],
  resetData: () => set(() => ({ loading: false, data: [] })),
  fetchServices: async (params?) => {
    set(produce((state) => {
      state.loading = true;
    }));

    return getHospitalServices(params)
      .then((response) => {
        set(produce((state) => {
          state.data = response;
          state.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.loading = false;
        }));

        throw e;
      });
  },
}));