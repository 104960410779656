import { ArrowRightOutlined, DeleteOutlined, EditOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { deleteClinicPrice, getClinicPrices, updatePriceDetails } from '@api/hospitalApi';
import { getCurrencyForSelect } from '@api/offerApi';
import SelectStep from '@containers/Hospitals/components/SelectStep';
import { useGridStore } from '@zustandStorage/grid/grid';
import { Button, Dropdown, Modal, Spin, notification } from 'antd';
import cn from 'classnames';
import QueryString from 'query-string';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './Prices.module.scss';
import AddPriceFolderModal from './components/AddPriceFolderModal/AddPriceFolderModal';
import AddPriceTagModal from './components/AddPriceTagModal/AddPriceTagModal';
import PriceFolder from './components/PriceFolder';
import SelectPriceFolderModal from './components/SelectPriceFolderModal/SelectPriceFolderModal';
import SortableList from './components/SortableList/SortableList';
import { Currency, Price, PriceType } from './types';

export default function Prices() {
  const [isAddFolderModalOpen, setIsAddFolderModalOpen] = useState(false);
  const [isAddPriceTagModalOpen, setIsAddPriceTagModalOpen] = useState(false);
  const [editingPrice, setEditingPrice] = useState<Price | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<Price | null>(null);
  const initiallyLoaded = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchFolderModalOpen, setIsSearchFolderModalOpen] = useState(false);
  const params = useParams<{ id: string }>();
  const [priceFolders, setPriceFolders] = useState<Price[]>([]);
  const [priceTags, setPriceTags] = useState<Price[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (isLoading || initiallyLoaded.current) {
      return;
    }

    setIsLoading(true);

    initiallyLoaded.current = true;

    (async () => {
      const data = await Promise.all([
        getClinicPrices(+params.id, { type: PriceType.FOLDER }),
        getClinicPrices(+params.id, { type: PriceType.PRICE, parentId: -1 }),
        getCurrencyForSelect(),
      ]);

      setPriceFolders(data[0].data);
      setPriceTags(data[1].data);
      setCurrencies(data[2].data as Currency[]);
      setIsLoading(false);
    })();
  }, [isLoading, params.id]);

  const handlePricesUpdated = async (
    priceType: PriceType,
    dispatcherHandler: (prices: Price[]) => void,
    errorNotificationMessage: string
  ) => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      const data = await getClinicPrices(+params.id, { type: priceType, parentId: selectedFolder?.id ?? -1 });

      dispatcherHandler(data.data);
    } catch (e: any) {
      console.log(e);
      notification.error({ message: errorNotificationMessage + e.message, duration: 0, placement: 'top' });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePriceFoldersUpdated = async () => {
    handlePricesUpdated(PriceType.FOLDER, setPriceFolders, 'Failed to load price folders');
  };

  const handlePriceTagsUpdated = async () => {
    handlePricesUpdated(PriceType.PRICE, setPriceTags, 'Failed to load price tags');
  };

  const handlePriceFolderEdit = (priceFolder: Price) => {
    setEditingPrice(priceFolder);
    setTimeout(() => setIsAddFolderModalOpen(true), 100);
  };

  const handleAddFolder = () => {
    setEditingPrice(null);
    setTimeout(() => setIsAddFolderModalOpen(true), 100);
  };

  const handleAddPriceTag = () => {
    setEditingPrice(null);
    setTimeout(() => setIsAddPriceTagModalOpen(true), 100);
  };

  const handleDelete = (price: Price) => {
    if (isLoading) return;

    Modal.confirm({
      title: `Are you sure you want to delete ${price.name}?`,
      onOk: async () => {
        try {
          setIsLoading(true);
          await deleteClinicPrice(+params.id, price.id);

          if (price.type === PriceType.FOLDER) {
            handlePriceFoldersUpdated();
          } else {
            handlePriceTagsUpdated();
          }
        } catch (e: any) {
          console.log(e);
          notification.error({
            message: `Can't delete price ${price.type === PriceType.FOLDER ? 'folder' : 'tag'}: ${e.message}`,
            duration: 0,
            placement: 'top',
          });
        } finally {
          setIsLoading(false);
        }
      },
    });
  };

  const handlePriceTagEdit = (priceTag: Price) => {
    setEditingPrice(priceTag);
    setTimeout(() => setIsAddPriceTagModalOpen(true), 100);
  };

  const handleSort = async (from: Price, to: Price & { order: number }) => {
    try {
      setIsLoading(true);
      await updatePriceDetails(+params.id, from.id, {
        order: to.order,
      });

      handlePriceTagsUpdated();
    } catch (error: any) {
      console.error(error);
      notification.error({ message: error.message, duration: 0, placement: 'top' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleMoveToFolder = (price: Price) => {
    setEditingPrice(price);
    setIsSearchFolderModalOpen(true);
  };

  const handleFolderSelected = async (folderId: number | null) => {
    if (!editingPrice) return;

    try {
      setIsLoading(true);
      await updatePriceDetails(+params.id, editingPrice.id, {
        parentId: folderId,
      });

      handlePriceTagsUpdated();
    } catch (e: any) {
      notification.error({ message: `Can't move price: ${e.message}`, duration: 0, placement: 'top' });
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handlePriceTagsUpdated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolder]);

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => {
          if (selectedFolder) {
            return setSelectedFolder(null);
          }

          const params = useGridStore.getState().gridOptions.doctorsAntGrid || {};
          history.push(`/hospitals?${QueryString.stringify(params)}`);
        }}
        title={selectedFolder?.name ?? 'Prices'}
        extra={[<SelectStep key="selectStep" />]}
      />
      <div className={cn(styles.addButtonsGrid, selectedFolder && styles.folderSelected)}>
        <Button className={styles.button} type="dashed" onClick={handleAddPriceTag}>
          <PlusOutlined /> Add price tag
        </Button>
        {!selectedFolder && (
          <Button className={styles.button} type="dashed" onClick={handleAddFolder}>
            <PlusOutlined /> Add folder
          </Button>
        )}
      </div>
      {!selectedFolder && (
        <div className={styles.foldersGrid}>
          {priceFolders.map((folder, i) => {
            return (
              <PriceFolder
                key={folder.id}
                deleteHandler={handleDelete}
                folder={folder}
                editHandler={handlePriceFolderEdit}
                onClick={() => setSelectedFolder(folder)}
              />
            );
          })}
        </div>
      )}
      {!isLoading && (
        <div className={styles.prices}>
          <SortableList
            data={priceTags}
            flow="horizontal"
            getItem={(item) => (
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 'move',
                      label: 'Move to',
                      icon: <ArrowRightOutlined />,
                      onClick: () => handleMoveToFolder(item),
                    },
                    // {
                    //   key: 'upgrade',
                    //   label: 'Upgrade',
                    //   onClick: () => setIsUpgradeConfirmModal(true),
                    // },
                  ],
                }}
                trigger={['contextMenu']}
              >
                <div className={styles.priceContainer}>
                  <span>{item.name}</span>
                  {item.price !== null ? (
                    <span className={styles.price}>
                      {item.currency?.symbol} {item.price?.toLocaleString('en-US')}
                    </span>
                  ) : (
                    <span className={styles.price}>On request</span>
                  )}
                  <Button className={styles.actionBtn} type="text" onClick={() => handlePriceTagEdit(item)}>
                    <EditOutlined />
                  </Button>
                  <Button className={styles.actionBtn} type="text" onClick={() => handleDelete(item)}>
                    <DeleteOutlined />
                  </Button>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: 'move',
                          label: 'Move to',
                          icon: <ArrowRightOutlined />,
                          onClick: () => handleMoveToFolder(item),
                        },
                        // {
                        //   key: 'upgrade',
                        //   label: 'Upgrade',
                        //   onClick: () => setIsUpgradeConfirmModal(true),
                        // },
                      ],
                    }}
                    trigger={['click']}
                  >
                    <Button className={styles.actionBtn} type="text">
                      <MoreOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </Dropdown>
            )}
            getKey={(item) => item.id}
            onSort={handleSort}
          />
        </div>
      )}
      <AddPriceFolderModal
        close={() => setIsAddFolderModalOpen(false)}
        open={isAddFolderModalOpen}
        editingPriceFolder={editingPrice?.type === PriceType.FOLDER ? editingPrice : null}
        handlePriceFoldersUpdated={handlePriceFoldersUpdated}
      />
      {Boolean(currencies.length) && (
        <AddPriceTagModal
          close={() => setIsAddPriceTagModalOpen(false)}
          open={isAddPriceTagModalOpen}
          editingPriceTag={editingPrice?.type === PriceType.PRICE ? editingPrice : null}
          parentId={selectedFolder?.id ?? null}
          handlePriceTagsUpdated={handlePriceTagsUpdated}
          currencies={currencies}
        />
      )}
      <SelectPriceFolderModal
        close={() => setIsSearchFolderModalOpen(false)}
        open={isSearchFolderModalOpen}
        handlePriceFolderSelected={handleFolderSelected}
      />
      {isLoading && <Spin />}
    </>
  );
}
