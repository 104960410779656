export enum FrontendLanguagesTypes {
  LAYOUT,
  MAIN,
  SERVICES,
  BLOGS,
  COMPONENTS,
  HOW_IT_WORKS,
  ABOUT_US,
  SEARCH,
  NOT_FOUND,
  HEALTH_LIBRARY_INDEX,
  HEALTH_LIBRARY_SHOW,
  DOCTOR_INDEX,
  DOCTOR_SHOW,
  HOSPITAL_INDEX,
  HOSPITAL_SHOW,
}

export interface FrontendLanguage {
  id: number;
  key: string;
  messageEn: string;
  messageRu: string;
  link: string;
}