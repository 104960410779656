import { useHistory, useParams } from 'react-router-dom';

import { BlogTypes } from '@modelTypes/blog';
import BlogAntGrid from '@containers/Blog/components/BlogAntGrid';
import { Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

interface RouterParams {
  type: BlogTypes;
}

const titles: Record<BlogTypes, string> = {
  articles: 'Articles',
  'disease-guides': 'Disease Guides',
  'treatment-guides': 'Treatment Guides',
};

const createButtonContents: Record<BlogTypes, string> = {
  articles: 'Add Article',
  'disease-guides': 'Add Disease Guide',
  'treatment-guides': 'Add Treatment Guide',
};

export default function BlogIndex(): JSX.Element {
  const history = useHistory();
  const { type } = useParams<RouterParams>();

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.push("/")}
        title={titles[type]}
        // subTitle="This is a subtitle"
        extra={(
          <Button
            key="1"
            type="primary"
            href={`/blog/${type}/create`}
            onClick={(e) => {
              e.preventDefault();

              history.push(`/blog/${type}/create`);
            }}
          >
            {createButtonContents[type]}
          </Button>
        )}
      />
      <BlogAntGrid />
      {/*<IndexPageLayout*/}
      {/*  title={titles[type]}*/}
      {/*  headerActions={(*/}
      {/*    <Button*/}
      {/*      color="primary"*/}
      {/*      variant="contained"*/}
      {/*      component={Link}*/}
      {/*      to={`/blog/${type}/create`}*/}
      {/*    >*/}
      {/*    </Button>*/}
      {/*  )}*/}
      {/*>*/}
      {/*  /!*<BlogGrid />*!/*/}
      {/*  <BlogAntGrid />*/}
      {/*</IndexPageLayout>*/}
    </>
  );
}
