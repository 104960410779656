import { EditOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";

export interface EditCellProps {
  to: string;
}

export function EditCell({ to }: EditCellProps) {
  return (
    <Tooltip title="Edit item">
      <Link to={to}>
        <EditOutlined style={{ fontSize: 18, color: '#000' }} />
      </Link>
    </Tooltip>
  );
}