import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import CitiesAntGrid from '@containers/Destinations/Cities/components/CitiesAntGrid';
import { PageHeader } from '@ant-design/pro-layout';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import * as React from 'react';

export default function CitiesIndex(): JSX.Element {
  const history = useHistory();

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.push("/")}
        title="Cities"
        extra={[
          <SelectLanguagePerForm
            key="selectLanguage"
            updateParamKey={false}
            queryParamKey="language-id"
          />,
          <Button
            key="createCity"
            type="primary"
            href="/cities/create"
            onClick={(e) => {
              e.preventDefault();

              history.push('/cities/create');
            }}
          >
            Add City
          </Button>
        ]}
      />
      <CitiesAntGrid />
    </>
    // <IndexPageLayout
    //   title="Cities"
    //   headerActions={(
    //     <Button
    //       component={Link}
    //       to="/cities/create"
    //       color="primary"
    //       variant="contained"
    //     >
    //       Add City
    //     </Button>
    //   )}
    // >
    //   <CitiesGrid />
    // </IndexPageLayout>
  );
}