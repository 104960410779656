import { applyMiddleware, createStore } from 'redux';

import appReducers from '@storage/rootReducers';
// import { composeWithDevTools } from 'redux-devtools-extension';
import get from 'lodash/get';
import thunk from 'redux-thunk';

// const appStorage = createStore(appReducers, composeWithDevTools(applyMiddleware(thunk)));
const appStorage = createStore(appReducers, applyMiddleware(thunk));
const getAppState = (key: string, defaultValue?: any) => {
  return get(appStorage.getState(), key, defaultValue);
};

export type RootState = ReturnType<typeof appReducers>;

export {
  appStorage,
  getAppState,
};