import React from 'react';
import { Spin, Tooltip } from 'antd';
import PreviewIcon from '@components/AntGrid/icons/preview';

export interface FrontendPreviewCellProps {
  onGetAccessToken: () => void;
  loading?: boolean;
}

export function FrontendPreviewCell(
  {
    loading,
    onGetAccessToken,
  }: FrontendPreviewCellProps
) {
  return (
    <Spin spinning={loading ? loading : false}>
      <div onClick={onGetAccessToken}>
        <Tooltip title="Preview on frontend">
          <PreviewIcon style={{ fontSize: 24, cursor: 'pointer' }} />
        </Tooltip>
      </div>
    </Spin>
  );
}