import { Language } from '../modelTypes/language';
import client from './client';
import { ExtendedGetLanguagesParams, GetListParams, ListResponse } from '@api/types';

const GET_LANGUAGE_URI = 'languages';
const GET_LANGUAGES_FOR_SELECT_URI = 'languages/select';
const POST_LANGUAGE_DICTIONARY_URI = 'frontend-translations/generate-dictionary';

export async function getLanguages(params?: GetListParams) {
  return client.get<ListResponse<Language>>(GET_LANGUAGE_URI, params);
}

export async function getLanguagesForSelect(params?: ExtendedGetLanguagesParams) {
  return client.get<ListResponse<Language>>(GET_LANGUAGES_FOR_SELECT_URI, params);
}

export async function updateFrontendDictionary() {
  return client.post<void, any>(POST_LANGUAGE_DICTIONARY_URI);
}
