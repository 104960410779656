import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M7.42059 8.42128L5.85129 7.27024V2.77671C6.12966 2.64448 6.31477 2.36403 6.31825 2.0446V0.819789C6.31825 0.367444 5.95011 0 5.49846 0H2.87555C2.4246 0 2.05785 0.366748 2.05785 0.819789V2.0453C2.05924 2.35637 2.23809 2.63613 2.50811 2.77253V7.27093L0.96039 8.42059C0.363294 8.85693 0.00419756 9.55841 2.20612e-05 10.2996V20.565C-0.00206569 20.9464 0.144088 21.3055 0.412016 21.5762C0.679944 21.8469 1.03764 21.9979 1.41483 22H6.94248C7.72887 22 8.36911 21.3598 8.36911 20.5734V10.2989C8.36702 9.56258 8.01281 8.8604 7.42059 8.42059V8.42128ZM3.05859 1.86366V1.00003H5.31056V1.86366H3.05859ZM1.00076 10.3003C1.00076 9.87853 1.20397 9.47699 1.54358 9.22646L3.30355 7.92579C3.4323 7.83254 3.50954 7.68222 3.50884 7.52285V2.86439H4.86031V7.52216C4.86031 7.68152 4.93685 7.83184 5.06421 7.9237L6.82557 9.22576C7.16449 9.47629 7.3677 9.87783 7.36839 10.2996V20.5664C7.36839 20.8009 7.17701 20.9923 6.94248 20.9923H1.42526C1.19143 20.9916 1.00076 20.8002 1.00076 20.5664V10.3003Z"
    fill="currentColor" />
  <path
    d="M4.18457 17.3548C5.7302 17.3548 6.98772 15.9441 6.98772 14.2106C6.98772 12.4771 5.7302 11.0665 4.18457 11.0665C2.63894 11.0665 1.37863 12.4771 1.37863 14.2106C1.37863 15.9441 2.63755 17.3548 4.18457 17.3548ZM2.38006 14.2106C2.38006 13.0289 3.18941 12.0672 4.18527 12.0672C5.18112 12.0672 5.98768 13.0289 5.98768 14.2106C5.98768 15.3923 5.17904 16.354 4.18527 16.354C3.1915 16.354 2.38006 15.3923 2.38006 14.2106Z"
    fill="currentColor" />
  <path
    d="M14.4159 9.67115C14.4083 9.65236 14.4027 9.63983 14.3999 9.63357L14.3957 9.62313C14.3136 9.44845 14.1355 9.33432 13.9406 9.33293H10.7311C10.5376 9.33432 10.3594 9.44845 10.2766 9.62452C10.1959 9.80059 8.31343 13.9538 9.69343 16.1035C10.1416 16.8008 10.8612 17.2044 11.8348 17.3053V19.6492L10.3907 21.1538C10.2557 21.2978 10.2168 21.5101 10.2933 21.6966C10.3727 21.8796 10.5529 21.9986 10.7533 22H13.9274C14.203 22 14.4271 21.7759 14.4278 21.5003C14.4278 21.3695 14.3776 21.2449 14.2872 21.1517L12.8362 19.6492V17.3053C13.8112 17.2044 14.5321 16.8008 14.9803 16.1035C16.2037 14.1981 14.8585 10.6983 14.4166 9.67115H14.4159ZM13.6094 10.3337C13.7506 10.6823 13.8794 11.0386 13.9935 11.3977H10.6789C10.793 11.0386 10.9224 10.6823 11.063 10.3337H13.6094ZM12.3351 16.3234C11.4395 16.3234 10.8674 16.0791 10.5341 15.553C10.1166 14.9107 10.0713 13.8223 10.4026 12.3991H14.2747C14.482 13.2711 14.6929 14.7012 14.139 15.5537C13.8056 16.0715 13.2155 16.3234 12.3358 16.3234H12.3351ZM11.9245 20.9993L12.3351 20.5748L12.7471 20.9993H11.9245Z"
    fill="currentColor" />
</svg>`;

export default { id: '308-alcoholism', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
