import cn from 'classnames';
import { DragEvent, ReactNode } from 'react';
import styles from './SortableItem.module.scss';

interface Props {
  slotAvailable: boolean;
  slotBefore: boolean;
  slotAfter: boolean;
  children: ReactNode;
  draggable?: boolean;
  flow: 'vertical' | 'horizontal';
  beingDragged: boolean;
  className?: string;
  skeletonClass?: string;
  onDragStart(event: DragEvent<HTMLDivElement>): void;
  onDragOver(event: DragEvent<HTMLDivElement>): void;
  onDragLeave(event: DragEvent<HTMLDivElement>): void;
  onDragEnd(event: DragEvent<HTMLDivElement>): void;
  onDrop(event: DragEvent<HTMLDivElement>): void;
}

export default function SortableItem({
  slotAvailable,
  slotBefore,
  slotAfter,
  draggable = true,
  flow,
  beingDragged,
  className,
  skeletonClass,
  ...props
}: Props) {
  return (
    <div className={cn(styles.skeleton, skeletonClass)}>
      <div
        className={cn(
          styles.itemContainer,
          slotAvailable && styles.slotAvailable,
          slotBefore && styles.slotBefore,
          slotAfter && styles.slotAfter,
          styles[flow],
          beingDragged && styles.beingDragged,
          className,
        )}
        {...props}
        draggable={draggable}
      />
    </div>
  );
}
