import { postUserActivity } from '@api/userActivitiesApi';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export enum ActivityType {
  MOUSE = 100,
  NAVIGATION = 200,
  KEYBOARD = 500,
}

export default function useActivityTracker() {
  const location = useLocation();
  const firstNavigation = useRef(true);
  const keyboardTimeout = useRef<number | null>(null);
  const keyboardEventsQueue = useRef<any[]>([]);
  const mouseTimeout = useRef<number | null>(null);
  const mouseEventsQueue = useRef<any[]>([]);

  const dispatchEvent = async (type: ActivityType, payload: any) => {
    const now = new Date();

    const encrypted = window.btoa(
      JSON.stringify({
        type,
        payload,
        dispatchedAt: now.toISOString(),
        timezoneOffset: now.getTimezoneOffset(),
      })
    );

    try {
      await postUserActivity({ data: encrypted });
    } catch {}
  };

  const dispatchMergeableEvent = (type: ActivityType.MOUSE | ActivityType.KEYBOARD, payload: any) => {
    const timeout = { 100: mouseTimeout, 500: keyboardTimeout }[type];
    const eventsQueue = { 100: mouseEventsQueue, 500: keyboardEventsQueue }[type];

    timeout.current && clearTimeout(timeout.current);

    eventsQueue.current.push(payload);

    timeout.current = setTimeout(() => {
      timeout.current = null;

      if (!eventsQueue.current.length) {
        return;
      }

      dispatchEvent(ActivityType.MOUSE, { merged: eventsQueue.current });
      eventsQueue.current = [];
    }, 1000) as unknown as number;
  };

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (!(event.target instanceof HTMLElement)) {
        return;
      }

      const target = event.target as HTMLElement;

      if (['A'].includes(target.tagName)) {
        return;
      }

      dispatchMergeableEvent(ActivityType.MOUSE, { label: target.innerText, button: event.button });
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      dispatchMergeableEvent(ActivityType.KEYBOARD, { key: event.key });
    };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstNavigation.current) {
      firstNavigation.current = false;
      return;
    }

    dispatchEvent(ActivityType.NAVIGATION, { location });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
}
