import { AntGetListParams, CreateRoleRequest, GetListParams, Params, UpdateRoleRequest } from '@api/types';
import {
  endFetchPermissions,
  endFetchRole,
  endFetchRoles,
  endFetchRolesForSelect,
  endPostRole,
  endUpdateRole,
  startDeleteRole,
  startFetchPermissions,
  startFetchRole,
  startFetchRoles,
  startFetchRolesForSelect,
  startPostRole,
  startUpdateRole,
} from './actions';

import { AxiosRequestConfig } from 'axios';
import { Role } from '@modelTypes/role';
import { ThunkAction } from '@storage/types';
import api from '@api';

export function getRoles(params?: AntGetListParams): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchRoles());

    return api.getRoles(params)
      .then((data) => {
        dispatch(endFetchRoles(data));
      });
  };
}

export function getRolesForSelect(): ThunkAction<Promise<void>> {
  const params: AntGetListParams & Params = { page: 1, perPage: 5000 };

  return async (dispatch): Promise<void> => {
    dispatch(startFetchRolesForSelect());

    return api.getRoles(params)
      .then((data) => {
        dispatch(endFetchRolesForSelect(data));
      });
  };
}

export function getRole(id: number, params?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchRole());

    return api.getRole(id, params)
      .then((data) => {
        dispatch(endFetchRole(data));
      });
  };
}

export function createRole(payload: CreateRoleRequest): ThunkAction<Promise<Role>> {
  return async (dispatch): Promise<Role> => {
    dispatch(startPostRole());

    return api.createRole(payload)
      .then((data) => {
        dispatch(endPostRole(data));

        return data;
      });
  };
}

export function updateRole(id: number, payload: UpdateRoleRequest): ThunkAction<Promise<Role>> {
  return async (dispatch): Promise<Role> => {
    dispatch(startUpdateRole());

    return api.updateRole(id, payload)
      .then((data) => {
        dispatch(endUpdateRole(data));

        return data;
      });
  };
}

export function deleteRole(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startDeleteRole());

    return api.deleteRole(id, config);
  };
}

export function getPermissions(params?: GetListParams): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchPermissions());

    return api.getPermissions(params)
      .then((data) => {
        dispatch(endFetchPermissions(data));
      });
  };
}
