import { Blog } from '@modelTypes/blog';
import { City } from '@modelTypes/city';
import { Country } from './country';
import { Doctor } from './doctor';
import { Hospital } from '@modelTypes/hospital';
import { SearchSection } from '@modelTypes/searchSection';

export interface Content {
  id: number;
  page: number;
  key: string;
  name: string;
  updatedAt: string;
  createdAt: string;
  countries?: Array<Country>;
  cities?: Array<City>;
  medicalSpecialities?: Array<SearchSection>;
  diseases?: Array<SearchSection>;
  diagnostics?: Array<SearchSection>;
  treatments?: Array<SearchSection>;
  doctors?: Array<Doctor>;
  hospitals?: Array<Hospital>;
  articles?: Array<Blog>;
}

export type MenuContent = Content & {
  title: string;
}

export interface MenuItem {
  id: number;
  page: number;
  order: number;
  key: string;
  name: string;
  title: string;
  link: Array<any>;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export enum ContentPages {
  MAIN = 0,
  // MENU = 'menu',
  // PAGE_MENU_HEALTH_LIBRARY = 'menu__health-library',
  PAGE_MENU_HEALTH_LIBRARY = 100,
  PAGE_MENU_TREATMENTS = 101,
  PAGE_MENU_HOSPITALS = 102,
  PAGE_MENU_DOCTORS = 103,
  PAGE_MENU_DESTINATIONS = 104,
  PAGE_MENU_BLOGS = 105,
  PAGE_MENU_OFFERS = 106,
}

export enum ContentTypes {
  HOSPITALS = 'hospitals',
  DOCTORS = 'doctors',
  COUNTRIES = 'countries',
  CITIES = 'cities',
}

export const ContentPagesByIndex = [
  ContentPages.MAIN,
  ContentPages.PAGE_MENU_HEALTH_LIBRARY,
//   public static PAGE_MENU_HEALTH_LIBRARY = 100;
// public static PAGE_MENU_TREATMENTS = 101;
// public static PAGE_MENU_HOSPITALS = 102;
// public static PAGE_MENU_DOCTORS = 103;
// public static PAGE_MENU_DESTINATIONS = 104;
// public static PAGE_MENU_BLOGS = 104;
];

export const ContentTypesByIndex = [
  ContentTypes.HOSPITALS, ContentTypes.DOCTORS, ContentTypes.COUNTRIES, ContentTypes.CITIES,
];
