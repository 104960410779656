import { FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import React from 'react';
import { Skill } from '@modelTypes/skill';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import { message } from 'antd';
import { ExcludedWord } from '@modelTypes/excludedWord';
import { dispatchRefreshDoctorSkillsGridEvent } from '@containers/Doctors/FormSteps/SkillsStep/components/SkillsGrid';
import { useDoctorSkillsStore } from '@zustandStorage/doctor/skills';
import getIdCell from '@components/AntGrid/cells/IdCell';

export type SkillColumnsNames = `${keyof Skill}Column` | 'editColumn' | 'deleteColumn';
type CellProps = { record: Skill };

export const skillColumnsDefinitions: Partial<Record<SkillColumnsNames, ColumnType<Skill>>> = {
  idColumn: createColumnDefinition<Skill>('#', 'id', { width: 80, render: getIdCell }),
  nameColumn: createColumnDefinition<Skill>('Name', 'name',),
  // frequencyColumn: createColumnDefinition<Skill>('Frequency', 'frequency',),
  // densityColumn: createColumnDefinition<Skill>('Density', 'density',),
  // updatedAtColumn: createColumnDefinition<Skill>('Updated At', 'updatedAt', {
  //   render: getDateFormattedCell,
  //   width: 160
  // }),
  // createdAtColumn: createColumnDefinition<Setting>('Created At', 'createdAt', { render: getDateFormattedCell, width: 160 }),
  // isActiveColumn: createColumnDefinition<Setting>('Active', 'isActive', { render: getIsActiveCell, width: 80, filtered: false }),
  // editColumn: {
  //   title: '',
  //   width: 104,
  //   fixed: 'right',
  //   render: (value: any, row: Skill) => <EditCell record={row} />,
  //   sorter: false,
  //   filtered: false,
  //   className: 'no-padding',
  // },
  deleteColumn: {
    title: '',
    width: 100,
    fixed: 'right',
    render: getDeleteCell(),
    sorter: false,
    filtered: false,
  }
};

export const skillColumns: any = [
  skillColumnsDefinitions.idColumn,
  skillColumnsDefinitions.nameColumn,
  skillColumnsDefinitions.deleteColumn,

];

export const skillFiltersConfig: FiltersConfig = {
  [skillColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [skillColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
};


function CustomDeleteCell({ record }: CellProps) {
  const { deleteDoctorSkills } = useDoctorSkillsStore();
  return (
    <DeleteCell
      record={record}
      title="Delete Word"
      extendedDeleteAction={deleteDoctorSkills}
      onSuccess={() => {
        dispatchRefreshDoctorSkillsGridEvent();
        message.success('The Words has been successfully deleted');
      }}
      hasNotRelationsContent={() => <div>Words has no any relations.</div>}
      hasRelationsContent={() => {
        return (
          <div style={{}}>
            <div>If you really want to continue, enter your password.</div>
          </div>
        );
      }}
    />
  );

}

function getDeleteCell() {
  return (value: any, record: Skill) => {

    return (
      <CustomDeleteCell record={record} />
    )
  };
}