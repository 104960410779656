import Grid from '@components/AntGrid/Grid';
import { PaginationConfig } from '@components/AntGrid/types';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeGridOptions from '@components/AntGrid/hooks/useChangeGridOptions';
import { useParams } from 'react-router-dom';
import { useReviewsStore } from '@zustandStorage/reviews/rewiews';
import { Review } from '@modelTypes/review';
import { reviewsColumns, reviewsFiltersConfig } from '@components/AntGrid/columns/reviews';

interface RouteParams {
  id: string;
}

interface ReviewsGridProps {
  entityReviews: string
}

export const [
  refreshReviewsGridEvent,
  dispatchRefreshReviewsGridEvent
] = makeRefreshGridEvent('onRefreshReviewsGrid');

export default function ReviewsGrid({ entityReviews }: ReviewsGridProps) {
  const { id } = useParams<RouteParams>();
  // const [languageId, setFirstLoad] = useChangeLanguage((languageId) => fetchDoctorPublications(+id,{ languageId }));
  const { grid: { data, meta, loading }, fetchReviews } = useReviewsStore();

  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
  };

  const onFetchData = async (params?: any) => {
    fetchReviews({ ...params, [entityReviews]: id })
  };

  useRefreshGridEvent(refreshReviewsGridEvent, onFetchData);
  useChangeGridOptions('doctorReviews');

  return (
    <Grid<Review>
      id="reviews"
      onFetch={onFetchData}
      columns={reviewsColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={reviewsFiltersConfig}
    />
  );
}