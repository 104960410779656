import { ActionType, Actions } from './actions';

import { Content } from '@modelTypes/content';
import { Doctor } from '@modelTypes/doctor';
import { ListResponse } from '@api/types';

export interface ContentsState {
  readonly grid: ListResponse<Content> & { loading: boolean }
  readonly form: {
    readonly loading: boolean;
    readonly row: Content | null;
  };
  // readonly selectDoctor: {
  //   readonly loading: boolean;
  //   readonly search: string | null;
  //   readonly data: Array<Doctor>;
  // };
}

const initialState: ContentsState = {
  grid: {
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  form: {
    loading: false,
    row: null,
  },
  // selectDoctor: {
  //   loading: false,
  //   search: null,
  //   data: [],
  // }
};

export default function contents(state = initialState, action: Actions): ContentsState {
  switch (action.type) {
    case ActionType.START_FETCH_CONTENTS:
    // case ActionType.START_DELETE_DOCTOR:
      return {
        ...state,
        grid: {
          ...state.grid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_CONTENTS:
      return {
        ...state,
        grid: {
          ...action.data,
          loading: false,
        },
      }
    // case ActionType.START_FETCH_DOCTORS_FOR_SELECT:
    //   return {
    //     ...state,
    //     selectDoctor: {
    //       ...state.selectDoctor,
    //       loading: true,
    //     }
    //   }
    // case ActionType.END_FETCH_DOCTORS_FOR_SELECT:
    //   return {
    //     ...state,
    //     selectDoctor: {
    //       ...state.selectDoctor,
    //       loading: false,
    //       data: action.data.data,
    //     }
    //   }
    case ActionType.START_PATCH_CONTENT:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        }
      }
    case ActionType.END_FETCH_CONTENT:
    case ActionType.END_PATCH_CONTENT:
      return {
        ...state,
        form: {
          ...state.form,
          row: action.data,
          loading: false,
        },
      }
    // case ActionType.START_POST_DOCTOR:
    // case ActionType.START_PATCH_DOCTOR:
    //   return {
    //     ...state,
    //     form: {
    //       ...state.form,
    //       loading: true,
    //     },
    //   }
    // case ActionType.END_POST_DOCTOR_ERROR:
    //   return {
    //     ...state,
    //     form: {
    //       ...state.form,
    //       loading: false,
    //     },
    //   }
    // case ActionType.END_PATCH_DOCTOR_ERROR:
    //   return {
    //     ...state,
    //     form: {
    //       ...state.form,
    //       loading: false,
    //     },
    //   }
    case ActionType.CLEAR_FORM_DATA:
      return {
        ...state,
        form: {
          ...initialState.form,
        }
      }
    default:
      return state;
  }
}