import { Role } from '../../../modelTypes/role';
import { BaseRow, FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { message } from 'antd';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { getDateFormattedCell } from '../cells/DateFormattedCell';
import { IconButtonRenderer } from '../renderers/renderers';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import React from 'react';
import { deleteRole } from '@storage/roles/thunkActions';
import { dispatchRefreshRolesGridEvent } from '@containers/Roles/components/RolesAntGrid';
import getIdCell from '@components/AntGrid/cells/IdCell';

export type RoleColumnsNames = `${keyof Role}Column` | 'hasDescriptionColumn' | 'previewColumn' | 'eyeIconColumn' | 'deleteColumn';

export const roleColumnsDefinitions: Partial<Record<RoleColumnsNames, ColumnType<Role>>> = {
  idColumn: createColumnDefinition<Role>('#', 'id', { width: 80, render: getIdCell }),
  slugColumn: createColumnDefinition<Role>('Slug', 'slug'),
  nameColumn: createColumnDefinition<Role>('Name', 'name'),
  descriptionColumn: createColumnDefinition<Role>('Description', 'description'),
  updatedAtColumn: createColumnDefinition<Role>('Updated At', 'updatedAt', { render: getDateFormattedCell, width: 160 }),
  createdAtColumn: createColumnDefinition<Role>('Created At', 'createdAt', { render: getDateFormattedCell, width: 160 }),
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, row: Role) => {
      return IconButtonRenderer<Role>(`/roles/${row.id}/edit`);
    },
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell<Role>(),
    sorter: false,
    filtered: false,
  }
};

export const roleColumns: any = [
  roleColumnsDefinitions.idColumn,
  roleColumnsDefinitions.slugColumn,
  roleColumnsDefinitions.nameColumn,
  roleColumnsDefinitions.descriptionColumn,
  roleColumnsDefinitions.updatedAtColumn,
  roleColumnsDefinitions.createdAtColumn,
  roleColumnsDefinitions.eyeIconColumn,
  roleColumnsDefinitions.deleteColumn,
];

export const roleFiltersConfig: FiltersConfig = {
  [roleColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [roleColumnsDefinitions.slugColumn?.key as string]: { filterType: FilterType.SEARCH },
  [roleColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [roleColumnsDefinitions.descriptionColumn?.key as string]: { filterType: FilterType.SEARCH },
  [roleColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [roleColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function getDeleteCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <DeleteCell
        record={record}
        title="Delete Role"
        deleteThunkAction={deleteRole}
        onSuccess={() => {
          dispatchRefreshRolesGridEvent();
          message.success('The role has been successfully deleted');
        }}
        hasNotRelationsContent={() => <div>Role has no any relations.</div>}
        hasRelationsContent={(data: any) => {
          return (
            <div style={{}}>
              <div>If you really want to continue, enter your password.</div>
            </div>
          );
        }}
      />
    );
  };
}
