import { createClinicPrice, updatePriceDetails } from '@api/hospitalApi';
import { Button, Input, Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Price, PriceType } from '../../types';

interface Props {
  close: () => void;
  open: boolean;
  editingPriceFolder: Price | null;
  handlePriceFoldersUpdated: () => void;
}
export default function AddPriceFolderModal(props: Props) {
  const { close, open, editingPriceFolder, handlePriceFoldersUpdated } = props;
  const [folderName, setFolderName] = useState(editingPriceFolder?.name ?? '');
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams<{ id: string }>();

  useEffect(() => {
    setFolderName(editingPriceFolder?.name ?? '');
  }, [editingPriceFolder]);

  const handleSave = async () => {
    if (isLoading) return;
    try {
      if (editingPriceFolder) {
        await updatePriceDetails(+params.id, editingPriceFolder.id, { name: folderName });

        handlePriceFoldersUpdated();
        setFolderName('');
        close();
      } else {
        await createClinicPrice(+params.id, { name: folderName, type: PriceType.FOLDER });

        setFolderName('');
        handlePriceFoldersUpdated();
        close();
      }
    } catch (e: any) {
      console.log(e);
      notification.error({ message: `Failed to create/edit folder: ${e}`, duration: 0, placement: 'top' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={editingPriceFolder ? 'Edit folder' : 'Add folder'}
      open={open}
      onCancel={close}
      footer={
        <Button type="primary" disabled={isLoading || !folderName} onClick={handleSave}>
          Save
        </Button>
      }
    >
      <p>Folder name</p>
      <Input value={folderName} onChange={(e) => setFolderName(e.currentTarget.value)} maxLength={100} />
    </Modal>
  );
}
