import { create } from 'zustand';
import produce from 'immer';
import { CreateAuthorRequest, Params, UpdateAuthorRequest } from '@api/types';
import { initialFormState } from '@zustandStorage/helpers/initialStates';
import { getAuthor, createAuthor, updateAuthor} from '@api';
import { Author } from '@modelTypes/author';

export interface AuthorState {
  form: {
    loading: boolean;
    row: Author | null;
  };
  clearFormData: () => void;
  fetchAuthor: (id: number, params?:Params) => Promise<Author>;
  createAuthor: (payload: CreateAuthorRequest) => Promise<Author>;
  updateAuthor: (id: number, payload: UpdateAuthorRequest) => Promise<Author>;
}

export const useAuthorStore = create<AuthorState>((set) => ({
  form: initialFormState,
  clearFormData: () => set(() => ({ form: initialFormState })),
  fetchAuthor: async (id: number, params?:Params): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return getAuthor(id, params)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response
          state.form.loading = false;
        }));

        return response;
      });
  },
  createAuthor: async (payload: CreateAuthorRequest): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return createAuthor(payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateAuthor: async (id: number, payload: UpdateAuthorRequest): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateAuthor(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
}));