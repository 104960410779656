import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M8.05423 18.6029C7.67883 18.58 7.33145 18.4716 7.02316 18.2813C6.914 18.2142 6.78567 18.1936 6.66102 18.2231C6.53638 18.2526 6.43091 18.3293 6.3638 18.4377C6.29668 18.5468 6.27603 18.6752 6.30553 18.7998C6.33503 18.9245 6.411 19.0299 6.52015 19.097C6.96489 19.3714 7.46125 19.527 7.99671 19.5602C8.0063 19.5602 8.01662 19.5609 8.02621 19.5609C8.27918 19.5609 8.48865 19.3633 8.50413 19.111C8.52036 18.847 8.31827 18.6191 8.05423 18.6029Z"
    fill="currentColor" />
  <path
    d="M12.4839 15.9101C12.2493 15.7885 11.9595 15.8799 11.8378 16.1152C11.6571 16.4633 11.4255 16.7959 11.1504 17.1035C10.9741 17.3004 10.9911 17.6043 11.188 17.7805C11.2758 17.8595 11.3894 17.903 11.5074 17.903C11.6438 17.903 11.7736 17.8447 11.8643 17.7437C12.1933 17.3756 12.4706 16.9766 12.6889 16.557C12.8106 16.3224 12.7191 16.0318 12.4839 15.9101Z"
    fill="currentColor" />
  <path
    d="M10.2418 17.9037C9.8974 18.1397 9.54043 18.3204 9.17978 18.4406C9.05808 18.4812 8.95999 18.5667 8.90246 18.681C8.84493 18.7954 8.83608 18.9259 8.87665 19.0469C8.94229 19.243 9.12446 19.3743 9.33171 19.3743C9.38334 19.3743 9.43423 19.3662 9.48364 19.3493C9.92912 19.2003 10.3665 18.9797 10.7847 18.6936C11.003 18.5439 11.0583 18.2452 10.9086 18.0268C10.7588 17.8085 10.4601 17.754 10.2418 17.9037Z"
    fill="currentColor" />
  <path
    d="M7.69363 5.60077C8.56098 5.86923 9.42464 6.00567 10.261 6.00567C11.0295 6.00567 11.6232 5.88914 11.9861 5.79179C12.11 5.7586 12.2133 5.67895 12.2767 5.56831C12.3409 5.45768 12.3578 5.32788 12.3246 5.20471C12.256 4.94952 11.9927 4.79759 11.7376 4.86618C11.2434 4.99893 10.3274 5.15087 9.10233 4.95173C9.48659 4.61763 9.82291 4.23706 10.0139 4.00842C10.5169 3.40511 11.4271 2.16236 11.8762 0.612788C11.95 0.359075 11.8032 0.0928228 11.5495 0.0190689C11.2958 -0.054685 11.0295 0.0920853 10.9558 0.345799C10.5553 1.72721 9.73293 2.84901 9.27787 3.39405C8.58163 4.22894 8.05871 4.57116 7.8522 4.6486C6.39261 4.24738 4.74495 4.55199 3.43803 5.46727C2.25797 6.29332 1.54034 7.46674 1.46954 8.67999C1.12806 11.5512 0.740851 14.806 0.0704283 15.9005C-0.0674915 16.1262 0.00331223 16.4219 0.228999 16.5599C0.304228 16.6063 0.39052 16.6307 0.479025 16.6307C0.647184 16.6307 0.800592 16.5451 0.888359 16.402C1.61262 15.219 1.98139 12.4975 2.42318 8.78251C2.42465 8.77219 2.42539 8.76186 2.42613 8.75153C2.47628 7.83698 3.06041 6.90252 3.98824 6.25275C5.09676 5.47686 6.48186 5.23273 7.69363 5.60077Z"
    fill="currentColor" />
  <path
    d="M5.70593 16.9434C6.11453 17.2797 6.61089 17.5143 7.17364 17.6279C7.47603 17.6906 7.78432 17.723 8.0904 17.723C9.09124 17.723 10.0677 17.3771 10.8392 16.7495C12.0215 15.7877 12.672 14.2772 12.672 12.4961C12.672 12.232 12.4566 12.0167 12.1926 12.0167C11.9286 12.0167 11.7132 12.232 11.7132 12.4961C11.7132 13.9837 11.1881 15.2301 10.2337 16.006C9.43714 16.6536 8.39205 16.9029 7.3654 16.689C6.37931 16.4891 5.91171 15.8681 5.69266 15.3821C5.31356 14.5398 5.36962 13.3988 5.8291 12.606C6.27679 11.8308 6.67875 10.5549 6.05036 8.87401C5.95743 8.6262 5.68159 8.50008 5.43378 8.59301C5.18597 8.6852 5.05985 8.96252 5.15278 9.21033C5.64988 10.5409 5.34454 11.5306 5.00011 12.1266C4.59962 12.8176 4.43147 13.6813 4.50153 14.511C4.48604 14.556 4.47646 14.6047 4.47498 14.6556C4.47498 14.6748 4.40713 16.5842 3.62017 18.4561C3.51765 18.6995 3.63271 18.9813 3.8761 19.0838C4.12022 19.1863 4.40123 19.0712 4.50374 18.8278C4.6483 18.4834 4.77958 18.1183 4.89464 17.74C5.27521 19.3788 5.85049 20.9306 6.25245 21.7352C6.33432 21.899 6.49879 22 6.6817 22C6.75545 22 6.82921 21.9823 6.89558 21.9491C7.0099 21.8916 7.09546 21.7935 7.13602 21.6718C7.17659 21.5501 7.167 21.4203 7.11021 21.306C6.7525 20.5906 6.08208 18.8286 5.70593 16.9434Z"
    fill="currentColor" />
  <path
    d="M6.9951 13.6554C6.9951 14.2101 7.50769 14.6607 8.13681 14.6607C8.76593 14.6607 9.27852 14.2093 9.27852 13.6554C9.27852 13.1015 8.76593 12.6502 8.13681 12.6502C7.50769 12.6502 6.9951 13.1015 6.9951 13.6554ZM8.12796 13.4939C8.39347 13.4939 8.59408 13.6149 8.69881 13.7166C8.59925 13.8184 8.40749 13.9409 8.12796 13.9409C7.84843 13.9409 7.65667 13.8184 7.55784 13.7166C7.66183 13.6149 7.86318 13.4939 8.12796 13.4939Z"
    fill="currentColor" />
  <path
    d="M20.2088 17.8948C20.0436 17.689 19.7412 17.6551 19.5347 17.8203C18.7101 18.4804 17.5492 18.5077 16.4275 17.8926C14.3365 16.7457 13.8092 14.3583 13.9928 12.662C14.0068 12.5344 13.97 12.4097 13.8896 12.3102C13.8092 12.2106 13.6949 12.1479 13.568 12.1339C13.4412 12.1199 13.3158 12.156 13.2162 12.2364C13.1159 12.3168 13.0532 12.4311 13.0392 12.5587C12.694 15.7419 14.4 17.8742 15.9658 18.7334C16.6281 19.0963 17.3405 19.2888 18.0272 19.2888C18.8171 19.2888 19.5458 19.0395 20.1336 18.5689C20.2332 18.4885 20.2966 18.3749 20.3106 18.2474C20.3246 18.1198 20.2892 17.9951 20.2088 17.8948Z"
    fill="currentColor" />
</svg>`;

export default { id: '89-breast-reduction', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
