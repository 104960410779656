/* eslint-disable max-len */

import { EditOutlined } from '@ant-design/icons/lib/icons';
import { AntGetListParams } from '@api/types';
import { Select, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import Grid from '../Grid';

import { BaseRow, NestedGridProps } from '../types';

import stylesheet from './renderers.module.scss';
import PreviewIcon from '@components/AntGrid/icons/preview';

interface BoolRenderArguments {
  trueFalseValues: [string, string];
  trueFalseLabels: [string, string];
  bordered?: boolean;
  onSelect?: ((value: string, option: any) => void);
}

enum TAG_COLORS {
  GREY = '#EFF0F1',
  RED = '#F8D9DD',
  YELLOW = '#FFF6D9',
  GREEN = '#DDEDDE'
}

export function obligationsCounterpartyRenderer(value: string) {
  return (
    <Tooltip title={value}>
      <div className={stylesheet.tag} style={{ backgroundColor: TAG_COLORS.GREY }}>{value}</div>
    </Tooltip>
  );
}

// todo: refactor tag renderers
export function tagRenderer(content: React.ReactNode, backgroundColor: any): JSX.Element {
  return <span className={stylesheet.tag} style={{ backgroundColor }}>{content}</span>;
}

export function getTagRenderer(backgroundColor: TAG_COLORS = TAG_COLORS.GREY) {
  return (value: any, record: any, index: number) => {
    return <span className={stylesheet.tag} style={{ backgroundColor }}>{value}</span>;
  };
}

export function categoryRenderer(content: React.ReactNode): JSX.Element {
  return tagRenderer(content, TAG_COLORS.GREY);
}

export function getNestedTableRenderer<ParentRow extends BaseRow, Row extends BaseRow>({
  onFetch, data: rootData, ...props
}: NestedGridProps<ParentRow, Row>): (parentRow: ParentRow) => JSX.Element {
  return (parentRow) => {
    const data = rootData[parentRow.id]?.data;
    const loading = rootData[parentRow.id]?.loading;

    const preparedOnFetch = (params: AntGetListParams) => {
      onFetch(parentRow, params);
    };

    const scroll = { y: '240px' };

    return (
      <div className={stylesheet.expandableRow}>
        <Grid<Row>
          loading={loading}
          className={'nested'}
          data={data}
          onFetch={preparedOnFetch}
          scroll={scroll}
          enableFiltering={true}
          {...props}
        />
      </div>
    );
  };
}

export function IconButtonRenderer<Row extends BaseRow>(to: string): JSX.Element {
  return (
    <Tooltip title="Edit item">
      <Link to={to}>
        <EditOutlined style={{ fontSize: 18, color: '#000' }} />
      </Link>
    </Tooltip>
  );
}

export function boolSelectRender<T>({
  trueFalseValues,
  trueFalseLabels,
  bordered = false,
  onSelect,
}: BoolRenderArguments, value?: boolean): (value?: boolean, row?: T) => JSX.Element {
  return (value?: boolean) => {
    return (
      <Select defaultValue={value ? trueFalseValues[0] : trueFalseValues[1]} bordered={bordered} onSelect={onSelect}>
        <Select.Option value={trueFalseValues[0]}>{trueFalseLabels[0]}</Select.Option>
        <Select.Option value={trueFalseValues[1]}>{trueFalseLabels[1]}</Select.Option>
      </Select>
    );
  };
}

export function GroupRenderer<Row extends BaseRow>(value: string, row: Row): JSX.Element {
  return <span className={stylesheet.group}>{value}</span>;
}
