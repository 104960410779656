import { Button, Form, Input, Spin } from 'antd';

import styles from './LoginForm.module.scss';
import { LoginRequest } from '@api/types';
import { Link, useHistory } from 'react-router-dom';
import { login } from '@api';
import Auth from '@base/Auth';
import { useState } from 'react';
import { useAuthStore } from '@zustandStorage/auth/auth';

const actionsUrls: Record<string, string> = {
  enterGoogle2FaCode: '/login/enter-ga-code',
  showQrCode: '/login/show-ga-qr-code',
};

export default function LoginForm(): JSX.Element {
  const history = useHistory();
  const [form] = Form.useForm<LoginRequest>();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);

    await login(values as LoginRequest)
      .then((data) => {
        setLoading(false);

        if (data.action) {
          useAuthStore.setState({ loginForm: { ...values } });
          history.push(actionsUrls[data.action], { qrCodeImg: data.qrCodeImg.length ? data.qrCodeImg : '' });
        } else if (data.token && data.refreshToken) {
          Auth.saveTokens({ access: data.token, refresh: data.refreshToken });
        }
      })
      .catch(() => {
        setLoading(false);

        form.setFields([
          {
            name: 'password',
            value: form.getFieldValue('password'),
            errors: ['The email or password entered is incorrect'],
          },
        ]);
      });
  };

  return (
    <Form
      form={form}
      className={styles.loginForm}
      layout="vertical"
      validateTrigger="onSubmit"
      onFinish={onFinish}
    >
      <Spin spinning={loading}>
        <div className={styles.fields}>
          <Form.Item
            name="email"
            label="Email"
            requiredMark="optional"
            rules={[
              { required: true, message: 'Please enter your email address' },
              { type: 'email', message: 'Invalid email format' },
            ]}
          >
            <Input type="text" placeholder="Email address" size="large" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            requiredMark="optional"
            rules={[
              { required: true, message: 'Please enter a password' },
            ]}
          >
            <Input.Password type="password" placeholder="Password" size="large" />
          </Form.Item>
        </div>
        <div className={styles.resetPasswordLink}>
          <Link to="/reset-password">
            Forgot your password?
          </Link>
        </div>
        <Button className={styles.submitBtn} type="primary" htmlType="submit" size="large">
          Login
        </Button>
      </Spin>
    </Form>
  );
}
