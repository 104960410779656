import { useParams } from 'react-router-dom';
import HospitalInfoStep from '@containers/Hospitals/FormSteps/MainDataStep/HospitalInfoStep';
import ReviewsStep from '@containers/Hospitals/FormSteps/ReviewsStep';
import GalleryStep from '@components/Gallery/GalleryStep';
import SelectStep from '@containers/Hospitals/components/SelectStep';
import PricesStep from './FormSteps/PricesStep';

const stepsScreen = {
  '1': <HospitalInfoStep />,
  '3': (
    <GalleryStep
      entityGallery={'hospitalId'}
      backPath={'/hospitals'}
      selectStep={<SelectStep key={'selectStep'} />}
      hasMainImages
    />
  ),
  '4': <ReviewsStep />,
  '5': <PricesStep />,
};

export default function CreateDoctor() {
  const { step = '1' } = useParams<{ step: string }>();

  return stepsScreen[step as keyof typeof stepsScreen];
}
