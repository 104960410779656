import { ListResponse } from '@api/types';
import { TreatmentType } from '@modelTypes/treatment';
import { createAction } from '@storage/helpers';

export enum ActionType {
  START_FETCH_TYPES = 'TYPES__START_FETCH_TYPES',
  END_FETCH_TYPES = 'TYPES__END_FETCH_TYPES',

  START_FETCH_TYPES_FOR_SELECT = 'TYPES__START_FETCH_TYPES_FOR_SELECT',
  END_FETCH_TYPES_FOR_SELECT = 'TYPES__END_FETCH_TYPES_FOR_SELECT',

  START_FETCH_TYPE = 'TYPES__START_FETCH_TYPE',
  END_FETCH_TYPE = 'TYPES__END_FETCH_TYPE',

  START_POST_TYPE = 'TYPES__START_POST_TYPE',
  END_POST_TYPE = 'TYPES__END_POST_TYPE',
  END_POST_TYPE_ERROR = 'TYPES__END_POST_TYPE_ERROR',

  START_PATCH_TYPE = 'TYPES__START_PATCH_TYPE',
  END_PATCH_TYPE = 'TYPES__END_PATCH_TYPE',
  END_PATCH_TYPE_ERROR = 'TYPES__END_PATCH_TYPE_ERROR',

  START_DELETE_TYPE = 'TYPES__START_DELETE_TYPE',
  END_DELETE_TYPE = 'TYPES__END_DELETE_TYPE',

  CLEAR_FORM_DATA = 'TYPES__CLEAR_FORM_DATA',
}

export const startFetchTypes = createAction<ActionType.START_FETCH_TYPES>(ActionType.START_FETCH_TYPES);
export const endFetchTypes = createAction<ActionType.END_FETCH_TYPES, ListResponse<TreatmentType>>(ActionType.END_FETCH_TYPES);

export const startFetchTypesForSelect = createAction<ActionType.START_FETCH_TYPES_FOR_SELECT>(ActionType.START_FETCH_TYPES_FOR_SELECT);
export const endFetchTypesForSelect = createAction<ActionType.END_FETCH_TYPES_FOR_SELECT, ListResponse<TreatmentType>>(ActionType.END_FETCH_TYPES_FOR_SELECT);

export const startFetchType = createAction<ActionType.START_FETCH_TYPE>(ActionType.START_FETCH_TYPE);
export const endFetchType = createAction<ActionType.END_FETCH_TYPE, TreatmentType>(ActionType.END_FETCH_TYPE);

export const startPostType = createAction<ActionType.START_POST_TYPE>(ActionType.START_POST_TYPE);
export const endPostType = createAction<ActionType.END_POST_TYPE, TreatmentType>(ActionType.END_POST_TYPE);
export const endPostTypeError = createAction<ActionType.END_POST_TYPE_ERROR>(ActionType.END_POST_TYPE_ERROR);

export const startUpdateType = createAction<ActionType.START_PATCH_TYPE>(ActionType.START_PATCH_TYPE);
export const endUpdateType = createAction<ActionType.END_PATCH_TYPE, TreatmentType>(ActionType.END_PATCH_TYPE);
export const endUpdateTypeError = createAction<ActionType.END_PATCH_TYPE_ERROR>(ActionType.END_PATCH_TYPE_ERROR);

export const startDeleteType = createAction<ActionType.START_DELETE_TYPE>(ActionType.START_DELETE_TYPE);
export const endDeleteType = createAction<ActionType.END_DELETE_TYPE>(ActionType.END_DELETE_TYPE);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

export type Actions =
  | ReturnType<typeof startFetchTypes>
  | ReturnType<typeof endFetchTypes>
  | ReturnType<typeof startFetchTypesForSelect>
  | ReturnType<typeof endFetchTypesForSelect>
  | ReturnType<typeof startFetchType>
  | ReturnType<typeof endFetchType>
  | ReturnType<typeof startPostType>
  | ReturnType<typeof endPostType>
  | ReturnType<typeof endPostTypeError>
  | ReturnType<typeof startUpdateType>
  | ReturnType<typeof endUpdateType>
  | ReturnType<typeof endUpdateTypeError>
  | ReturnType<typeof startDeleteType>
  | ReturnType<typeof endDeleteType>
  | ReturnType<typeof clearFormData>;