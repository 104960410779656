import { ActionType, Actions } from './actions';
import { ModalWithFormData, ModalWithFormTypes } from '@components/Modal/ModalWithForm/ModalWithForm';

import { ListResponse } from '@api/types';
import { Tag } from '@modelTypes/tag';

export type ModalData = ModalWithFormData<Omit<Tag, 'id' | 'updatedAt' | 'createdAt'>, Tag>;

export interface TagsState {
  readonly grid: ListResponse<Tag> & { loading: boolean }
  readonly modalWithForm: {
    open: boolean;
    loading: boolean;
    data: ModalData
  };
  readonly selectTags: {
    loading: boolean;
    search: string | null;
    data: Array<Tag>;
  };
}

const initialState: TagsState = {
  grid: {
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  modalWithForm: {
    open: false,
    loading: false,
    data: {
      type: ModalWithFormTypes.ADD,
      formData: {
        name: '',
      },
    },
  },
  selectTags: {
    loading: false,
    search: null,
    data: [],
  }
};

export default function tags(state = initialState, action: Actions): TagsState {
  switch (action.type) {
    case ActionType.START_FETCH_TAGS:
    // case ActionType.START_DELETE_TAG:
      return {
        ...state,
        grid: {
          ...state.grid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_TAGS:
      return {
        ...state,
        grid: {
          ...action.data,
          loading: false,
        },
      }
    case ActionType.START_FETCH_TAGS_FOR_SELECT:
      return {
        ...state,
        selectTags: {
          ...state.selectTags,
          loading: true,
        }
      }
    case ActionType.END_FETCH_TAGS_FOR_SELECT:
      return {
        ...state,
        selectTags: {
          ...state.selectTags,
          loading: false,
          data: action.data.data,
        }
      }
    case ActionType.START_POST_TAG:
    case ActionType.START_PATCH_TAG:
      return {
        ...state,
        modalWithForm: {
          ...state.modalWithForm,
          loading: true,
        },
      }
    case ActionType.OPEN_MODAL_WITH_FORM:
      return {
        ...state,
        modalWithForm: {
          ...state.modalWithForm,
          open: true,
        },
      };
    case ActionType.MODAL_WITH_FORM_SET_DATA:
      return {
        ...state,
        modalWithForm: {
          ...state.modalWithForm,
          data: action.data,
        },
      };
    case ActionType.CLOSE_MODAL_WITH_FORM:
      return {
        ...state,
        modalWithForm: {
          ...initialState.modalWithForm,
        },
      };
    default:
      return state;
  }
}