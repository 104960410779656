import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks';
import { PaginationConfig } from '@components/AntGrid/types';
import { getCountries } from '@storage/countries/thunkActions';
import { Country } from '@modelTypes/country';
import { countryColumns, countryFiltersConfig } from '@components/AntGrid/columns/country';
import { CountriesState } from '@storage/countries/reducer';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeLanguage from '@components/AntGrid/hooks/useChangeLanguage';
import useChangeGridOptions from "@components/AntGrid/hooks/useChangeGridOptions";

export const [
  refreshCountriesGridEvent,
  dispatchRefreshCountriesGridEvent
] = makeRefreshGridEvent('onRefreshCountriesGrid');

export default function CountriesAntGrid() {
  const dispatch = useDispatch();
  const [languageId, setFirstLoad] = useChangeLanguage((languageId) => dispatch(getCountries({ languageId })));
  const { data, meta, loading } = useSelector<CountriesState['grid']>('countries.grid');

  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
    // ...rest.paginationConfig,
  };

  const onFetchData = (params?: AntGetListParams) => {
    dispatch(getCountries({ ...params, languageId }) as unknown as Promise<void>)
      .then(() => {
        setFirstLoad(true);
      });
  };

  useRefreshGridEvent(refreshCountriesGridEvent, onFetchData);
  useChangeGridOptions('countriesAntGrid');

  return (
    <Grid<Country>
      id="countriesGrid"
      onFetch={onFetchData}
      columns={countryColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={countryFiltersConfig}
    />
  );
}