import { PageHeader } from '@ant-design/pro-layout';
import SelectStep from '@containers/Doctors/components/SelectStep';
import { useGridStore } from '@zustandStorage/grid/grid';
import QueryString from 'query-string';
import { useHistory } from 'react-router-dom';
import PublicationsGrid from './components/PublicationsGrid';

export default function PublicationsStep() {
  const history = useHistory();

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.doctorsAntGrid || {};
          history.push(`/doctors?${QueryString.stringify(params)}`)
        }}
        title="Publications"
        extra={[
          <SelectStep key="selectStep" />,
        ]}
      />
      <PublicationsGrid />
    </>
  );
}