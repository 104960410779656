import { create } from 'zustand';
import {
  AntGetListParams,
  CreateOfferRequest,
  CreateOfferResponse,
  ListResponse,
  Params,
  UpdateOfferRequest,
  UpdateOfferResponse
} from '@api/types';
import produce from 'immer';
import { initialFormState, initialGridState } from '@zustandStorage/helpers/initialStates';
import { AxiosRequestConfig } from 'axios';
import { Offer } from '@modelTypes/offer';
import { createOffer, deleteOffer, getOffer, getOffers, updateOffer } from '@api/offerApi';

interface Form {
  loading: boolean;
  row: Offer | null;
}

export interface OfferState {
  grid: ListResponse<Offer> & { loading: boolean }
  form: {
    loading: boolean;
    row: Offer | null;
  };
  clearFormData: () => void;
  setForm: (form: Form) => void;
  fetchOffers: (params: AntGetListParams) => Promise<ListResponse<Offer>>;
  fetchOffer: (id: number, params?: Params) => Promise<Offer>;
  createOffer: (payload: CreateOfferRequest) => Promise<CreateOfferResponse>;
  updateOffer: (id: number, payload: UpdateOfferRequest) => Promise<UpdateOfferResponse>;
  deleteOffer: (id: number, payload?: AxiosRequestConfig) => Promise<any>;
}

export const useOfferStore = create<OfferState>((set) => ({
  grid: initialGridState,
  form: initialFormState,
  clearFormData: () => set(() => ({ form: initialFormState })),
  setForm: (form: Form) => set(() => ({ form })),
  fetchOffers: async (params:AntGetListParams) => {
    set(produce((state) => {
      state.grid.loading = true;
    }));

    return getOffers(params)
      .then((response) => {
        set(produce((state) => {
          state.grid.data = response.data;
          state.grid.meta = response.meta
          state.grid.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.loading = false;
        }));

        throw e;
      });
  },
  fetchOffer: async (id: number, params?: Params): Promise<Offer> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return getOffer(id, params)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  createOffer: async (payload: CreateOfferRequest): Promise<CreateOfferResponse> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return createOffer(payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateOffer: async (id: number, payload: UpdateOfferRequest): Promise<UpdateOfferResponse> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateOffer(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  deleteOffer: async (id, payload?) => {
    return deleteOffer(id, payload)
  },
}));