import { useParams } from 'react-router-dom';
import MainDataStep from './FormSteps/MainDataStep/MainDataStep';
import GalleryStep from '@components/Gallery/GalleryStep';
import SelectStep from './components/SelectStep';
import ReviewsStep from './FormSteps/ReviewsStep';


const stepsScreen = {
  '1': <MainDataStep />,
  '2': <GalleryStep
    entityGallery={'offerId'}
    backPath={'/offers'}
    selectStep={<SelectStep key={'selectStep'} />}
    hasMainImages
  />,
  '3': <ReviewsStep />,
};

export default function CreateProgram() {
  const { step = '1' } = useParams<{ step: string }>();

  return stepsScreen[step as (keyof typeof stepsScreen)]
}
