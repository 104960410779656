import * as React from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';

import { Language } from '@modelTypes/language';
import { useEffect } from 'react';
import {
  Button,
  Button as AntButton,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Spin,
  Tooltip,
  Card,
  Checkbox
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import { CreateProgramDiscountRequest } from '@api/types';
import { SaveOutlined } from '@ant-design/icons';
import { useDiscountStore } from '@zustandStorage/treatment/program/discount/discount';
import { setFormErrors } from '@api/helpers';
import { useGridStore } from "@zustandStorage/grid/grid";
import QueryString from "query-string";

export default function MethodCreate() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { loading, row } = useDiscountStore((state) => state.form);
  const { fetchProgramDiscount, createProgramDiscount, updateProgramDiscount, clearFormData } = useDiscountStore();
  const [messageApi, contextHolder] = message.useMessage();
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);
  const [languageId, setLanguageId] = React.useState<number>(0);
  const [form] = Form.useForm<CreateProgramDiscountRequest>();

  const onSelectLanguage = (language: Language) => {
    setLanguageId(language.id);

    if (id) {
      fetchProgramDiscount(parseInt(id, 10))
        .then(() => {
          resetForm();
        });
    }
  };

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving Program Discount...',
      duration: 0,
    });

    setFormSubmitting(true);
    const formData = { ...form.getFieldsValue(), languageId };

    if (id) {
      await updateProgramDiscount(parseInt(id, 10), formData)
        .then(() => {
          messageApi.destroy('startCreate');
          message.success('The Program Discount has been successfully updated');
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          setFormSubmitting(false);
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    }
    else {
      await createProgramDiscount(formData)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The Program Discount has been successfully created');
          history.push(`/treatments/programs/discounts/${data.id}/edit`);
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          setFormSubmitting(false);
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    }
  };

  useEffect(() => {
    resetForm();
  }, [languageId, id]);

  useEffect(() => {
    return () => {
      clearFormData();
    };
  }, []);
  
  return (
    <>
      <Prompt
        when={!formSubmitting && formChanged}
        message={(location) =>
          `Data not saved!\nAre you sure you want to go to ${location.pathname}?`
        }
      />
      {contextHolder}
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.treatmentDiscountsGrid || {};
          history.push(`/treatments/programs/discounts?${QueryString.stringify(params)}`)
        }}
        title={`${row ? 'Edit' : 'Create'} Discount`}
        // subTitle={<SelectedPlaceValue data={place} />}
        extra={[
          <SelectLanguagePerForm
            key="selectLanguage"
            onInit={onSelectLanguage}
            onChange={onSelectLanguage}
          />,
          <AntButton
            key="save"
            type="primary"
            loading={formSubmitting}
            onClick={() => form.submit()}
          >
            {id ? 'Update' : 'Create'}
          </AntButton>
        ]}
      />
      <Spin spinning={loading || languageId === 0}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          // style={{ marginTop: 32 }}
        >
          <Row gutter={[32, 24]}>
            <Col span={16}>
              <Form.Item
                name="name"
                label="Name"
                messageVariables={{ name: 'Name'}}
                rules={[{ required: true, type: 'string',  max: 255 }]}
                initialValue={row?.name}
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                name="value"
                label="Value"
                messageVariables={{ name: 'Value' }}
                initialValue={row?.value}
                rules={[{ required: true, type: 'number' }]}
              >
                <InputNumber
                  step={.01}
                  min={0}
                  placeholder="Value"
                  prefix="%"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Card title="Statuses" bordered={false} style={{ marginTop: 24 }}>
                <Form.Item
                  name="isActive"
                  valuePropName="checked"
                  initialValue={row ? row?.isActive : false}
                  style={{ margin: 0 }}
                >
                  <Checkbox disabled={languageId > 1}>Active</Checkbox>
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Tooltip title={id ? 'Update' : 'Create'} placement="topRight">
        <Button
          style={{ position: 'fixed', bottom: 20, right: 16 }}
          onClick={() => form.submit()}
          // type="ghost"
          shape="circle"
          icon={<SaveOutlined />}
          size="large"
        />
      </Tooltip>
    </>
  );
}
