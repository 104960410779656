import { Blog, BlogAccessKey, BlogTypes } from '@modelTypes/blog';
import {
  CreateArticleRequest,
  CreateArticleResponse,
  GetListParams,
  ListResponse,
  Params,
  UpdateArticleRequest,
  UpdateArticleResponse,
} from "@api/types";

import { AxiosRequestConfig } from 'axios';
import client from "./client";
import { getRestUri } from '@api/helpers';

const getUri = getRestUri('blog');
const ROOT_URI = getUri('');
const GET_BLOG_FOR_SELECT_URI = 'blog/select';
const GET_BLOG_FOR_SELECT2_URI = 'blog/select2';
const GET_BLOGS_URI = (type: BlogTypes) => `blog/${type}`;
const GET_ACCESS_KEY_URI = (id: number) => `blog/${id}/access-key`;

export async function getArticles(type: BlogTypes, params?: GetListParams) {
  return client.get<ListResponse<Blog>>(GET_BLOGS_URI(type), params);
}

export async function getArticlesForSelect(params?: GetListParams) {
  return client.get<ListResponse<Blog>>(GET_BLOG_FOR_SELECT_URI, params);

}export async function getArticlesForSelect2(params?: GetListParams) {
  return client.get<ListResponse<Blog>>(GET_BLOG_FOR_SELECT2_URI, params);
}

export async function getArticle(id: number, params?: Params) {
  return client.get<Blog>(ROOT_URI.SHOW({ id }), params);
}

export async function getAccessKey(id: number) {
  return client.get<BlogAccessKey>(GET_ACCESS_KEY_URI(id));
}

export async function createArticle(payload: CreateArticleRequest) {
  return client.post<CreateArticleRequest, CreateArticleResponse>(ROOT_URI.POST(), payload);
}

export async function updateArticle(id: number, payload: UpdateArticleRequest) {
  return client.patch<UpdateArticleRequest, UpdateArticleResponse>(ROOT_URI.PATCH({ id }), payload);
}

export async function deleteArticle(id: number, payload?: AxiosRequestConfig) {
  return client.del(ROOT_URI.DELETE({ id }), payload);
}
