import { Button, Form, Input, Spin } from 'antd';

import styles from './LoginForm.module.scss';
import { LoginRequest } from '@api/types';
import Auth from '@base/Auth';
import { useState } from 'react';
import { enter2FaPassword } from '@api/authApi';
import { setFormErrors } from '@api/helpers';
import { useAuthStore } from '@zustandStorage/auth/auth';

export default function EnterQrCodeForm(): JSX.Element {
  const loginForm = useAuthStore((st) => st.loginForm);
  const [form] = Form.useForm<LoginRequest>();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);

    await enter2FaPassword({ ...values, ...loginForm })
      .then((data) => {
        Auth.saveTokens({ access: data.token, refresh: data.refreshToken });
        setLoading(false);
      })
      .catch((e) => {
        setFormErrors(e, form.getFieldsValue(), form.setFields);
        setLoading(false);
      });
  };

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const code = e.target.value;

    if (code.length === 6) {
      form.submit();
    }
  };

  return (
    <Form
      form={form}
      className={styles.loginForm}
      layout="vertical"
      validateTrigger="onSubmit"
      onFinish={onFinish}
    >
      <Spin spinning={loading}>
        <div className={styles.fields}>
          <Form.Item
            name="code"
            label="Code from Google Authenticator"
            requiredMark="optional"
            rules={[
              { required: true, message: 'Please enter code from GA' },
              { pattern: /^[0-9]+$/, message: 'Code must contain only numbers.' },
            ]}
          >
            <Input
              type="text"
              placeholder="Enter the code"
              size="large"
              onChange={handleCodeChange}
            />
          </Form.Item>
        </div>
        <Button className={styles.submitBtn} type="primary" htmlType="submit" size="large">
          Continue
        </Button>
      </Spin>
    </Form>
  );
}
