import { useEffect } from 'react';
import { useSearchSectionSelectStore } from '@zustandStorage/blogs/searchSectionForSelect';

export default function useLoadSearchSection() {
  const { data, loading } = useSearchSectionSelectStore((state) => state.select);
  const { fetchSearchSectionForSelect } = useSearchSectionSelectStore();

  useEffect(() => {
    if (!data.length && !loading) {
      fetchSearchSectionForSelect({ page: 1, perPage: 5000 });
    }
  }, []);

  return {data, loading};
}