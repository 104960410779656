import { useState } from 'react';
import { Button } from 'antd';
import OfferServicesModal from '@containers/Offers/Offers/components/ExtraServicesModal/OfferServicesModal';
import { ButtonProps } from 'antd/es/button/button';


export default function OfferServicesTriggerModal(props: ButtonProps) {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleCloseModal = async () => {
    setIsOpenModal(false)
  }

  return (
    <>
      <Button
        type={'primary'}
        onClick={() => setIsOpenModal(true)}
        {...props}
      >
        Extra Services
      </Button>
      <OfferServicesModal
        closeModal={handleCloseModal}
        isOpen={isOpenModal}
      />
    </>
  )
}