import { omit } from 'lodash';
import { useEffect, useRef } from 'react';
import { Edge, Node, XYPosition, useReactFlow, useStoreApi } from 'reactflow';
import { v4 as uuid } from 'uuid';
import { CustomEdgeProps } from './CustomEdge/CustomEdge';
import { CustomNodeProps } from './CustomNode/CustomNode';

interface Props {
  canvasMousePos: XYPosition;
}

export default function ClipboardManager({ canvasMousePos }: Props) {
  const copiedData = useRef<{ nodes: Node<CustomNodeProps>[]; edges: Edge<CustomEdgeProps>[] }>({
    nodes: [],
    edges: [],
  });
  const flowState = useReactFlow();
  const store = useStoreApi();

  useEffect(() => {
    const handleCopy = (event: ClipboardEvent) => {
      event.preventDefault();

      copiedData.current = {
        nodes: flowState.getNodes().filter((i) => i.selected),
        edges: flowState.getEdges().filter((i) => i.selected),
      };
    };

    const handlePaste = (event: ClipboardEvent) => {
      event.preventDefault();

      const nodeIdsMap: { [key: string]: string } = {};

      let newNodes = JSON.parse(JSON.stringify(copiedData.current.nodes)) as Partial<Node<CustomNodeProps>>[];

      const {
        transform: [transformX, transformY, zoomLevel],
      } = store.getState();

      const zoomMultiplier = 1 / zoomLevel;
      const firstNodeX = -transformX * zoomMultiplier + canvasMousePos.x * zoomMultiplier;
      const firstNodeY = -transformY * zoomMultiplier + canvasMousePos.y * zoomMultiplier;

      newNodes = newNodes.map((i, index) => {
        nodeIdsMap[i.id as string] = uuid();

        const diffNodeX = firstNodeX + (i.position!.x - newNodes[0].position!.x);
        const diffNodeY = firstNodeY + (i.position!.y - newNodes[0].position!.y);

        return {
          id: nodeIdsMap[i.id as string],
          selected: true,
          position: { x: index === 0 ? firstNodeX : diffNodeX, y: index === 0 ? firstNodeY : diffNodeY },
          ...omit(i, [
            'id',
            'height',
            'width',
            'selected',
            'data.id',
            'data.highlighted',
            'data.createdAt',
            'data.updatedAt',
            'position',
            'positionAbsolute',
          ]),
        };
      });

      flowState.setNodes((prev) => [
        ...prev.map((i) => ({ ...i, selected: false })),
        ...(newNodes as Node<CustomNodeProps>[]),
      ]);

      let newEdges = JSON.parse(JSON.stringify(copiedData.current.edges)) as Partial<Edge<CustomEdgeProps>>[];

      newEdges = newEdges.map((i) => ({
        id: uuid(),
        selected: true,
        source: nodeIdsMap[i.source as string],
        target: nodeIdsMap[i.target as string],
        ...omit(i, [
          'id',
          'selected',
          'source',
          'sourceHandle',
          'target',
          'targetHandle',
          'data.id',
          'data.highlighted',
        ]),
      }));

      flowState.setEdges((prev) => [
        ...prev.map((i) => ({ ...i, selected: false })),
        ...(newEdges.filter((i) => i.source && i.target) as Edge<CustomEdgeProps>[]),
      ]);
    };

    window.addEventListener('copy', handleCopy);
    window.addEventListener('paste', handlePaste);

    return () => {
      window.removeEventListener('copy', handleCopy);
      window.removeEventListener('paste', handlePaste);
    };
  }, [canvasMousePos, flowState, store]);

  return null;
}
