import { ActionType, Actions } from './actions';
import { Hospital, HospitalDepartment, HospitalDepartmentForSelect } from '@modelTypes/hospital';

import { ListResponse } from '@api/types';

export interface DepartmentsState {
  readonly hospital: {
    readonly row: Hospital | null;
    readonly loading: boolean;
  }
  readonly grid: ListResponse<HospitalDepartment> & { readonly loading: boolean };
  readonly select: ListResponse<HospitalDepartmentForSelect> & { readonly fetched: boolean; readonly loading: boolean };
  readonly form: {
    readonly loading: boolean,
    readonly row: HospitalDepartment | null;
  };
}

const initialState: DepartmentsState = {
  hospital: {
    loading: false,
    row: null,
  },
  grid: {
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  select: {
    fetched: false,
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 5000,
    },
  },
  form: {
    loading: false,
    row: null,
  },
};

export default function departments(state = initialState, action: Actions): DepartmentsState {
  switch (action.type) {
    case ActionType.START_FETCH_HOSPITAL:
      return {
        ...state,
        hospital: {
          ...state.hospital,
          loading: true,
        }
      }
    case ActionType.END_FETCH_HOSPITAL:
      return {
        ...state,
        hospital: {
          ...state.hospital,
          loading: false,
          row: action.data,
        }
      }
    case ActionType.START_FETCH_DEPARTMENTS:
    // case ActionType.START_DELETE_DEPARTMENT:
      return {
        ...state,
        grid: {
          ...state.grid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_DEPARTMENTS:
      return {
        ...state,
        grid: {
          ...action.data,
          loading: false,
        },
      }
    case ActionType.START_FETCH_DEPARTMENTS_FOR_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          fetched: false,
          loading: true,
        }
      }
    case ActionType.END_FETCH_DEPARTMENTS_FOR_SELECT:
      return {
        ...state,
        select: {
          ...action.data,
          fetched: true,
          loading: false,
        },
      }
    case ActionType.START_FETCH_DEPARTMENT:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        }
      }
    case ActionType.END_FETCH_DEPARTMENT:
    case ActionType.END_POST_DEPARTMENT:
    case ActionType.END_PATCH_DEPARTMENT:
      return {
        ...state,
        form: {
          ...state.form,
          row: action.data,
          loading: false,
        },
      }
    case ActionType.START_POST_DEPARTMENT:
    case ActionType.START_PATCH_DEPARTMENT:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        },
      }
    case ActionType.END_POST_DEPARTMENT_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.END_PATCH_DEPARTMENT_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.CLEAR_FORM_DATA:
      return {
        ...state,
        form: {
          ...initialState.form,
        }
      }
    default:
      return state;
  }
}