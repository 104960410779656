import { Author } from '../../../modelTypes/author';
import { BaseRow, FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { message } from 'antd';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { getIsActiveCell } from '@components/AntGrid/cells/StatusCell';
import { getDateFormattedCell } from '../cells/DateFormattedCell';
import ShowCheckIcon from '../cells/ShowCheckIcon';

import { IconButtonRenderer } from '../renderers/renderers';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import React from 'react';
import { deleteAuthor } from '@storage/authors/thunkActions';
import { dispatchRefreshAuthorsGridEvent } from '@containers/Blog/Authors/components/AuthorsGrid';
import getIdCell from '@components/AntGrid/cells/IdCell';

export type AuthorColumnsNames =
  `${keyof Author}Column`
  | 'twoFactorAuthColumn'
  | 'previewColumn'
  | 'eyeIconColumn'
  | 'deleteColumn';

export const authorColumnsDefinitions: Partial<Record<AuthorColumnsNames, ColumnType<Author>>> = {
  idColumn: createColumnDefinition<Author>('#', 'id', { width: 80, render: getIdCell }),
  nameColumn: createColumnDefinition<Author>('Name', 'name'),
  emailColumn: createColumnDefinition<Author>('Email', 'email'),
  positionColumn: createColumnDefinition<Author>('Position', 'position'),
  descriptionColumn: createColumnDefinition<Author>('Description', 'description'),
  updatedAtColumn: createColumnDefinition<Author>('Updated At', 'updatedAt', {
    render: getDateFormattedCell,
    width: 160
  }),
  createdAtColumn: createColumnDefinition<Author>('Created At', 'createdAt', {
    render: getDateFormattedCell,
    width: 160
  }),
  isActiveColumn: createColumnDefinition<Author>('Active', 'isActive', {
    render: getIsActiveCell,
    width: 80,
    filtered: false
  }),
  hasOwnPageColumn: {
    title: 'Has Own Page',
    key: 'hasOwnPage',
    dataIndex: 'hasOwnPage',
    width: 98,
    render: getHasOwnPageCell<Author>(),
    sorter: true,
    filtered: false,
  },
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, row: Author) => {
      return IconButtonRenderer<Author>(`/authors/edit/${row.id}`);
    },
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell<Author>(),
    sorter: false,
    filtered: false,
  }
};

export const authorColumns: any = [
  authorColumnsDefinitions.idColumn,
  authorColumnsDefinitions.nameColumn,
  authorColumnsDefinitions.emailColumn,
  authorColumnsDefinitions.positionColumn,
  authorColumnsDefinitions.descriptionColumn,
  authorColumnsDefinitions.updatedAtColumn,
  authorColumnsDefinitions.createdAtColumn,
  authorColumnsDefinitions.hasOwnPageColumn,
  authorColumnsDefinitions.isActiveColumn,
  authorColumnsDefinitions.eyeIconColumn,
  authorColumnsDefinitions.deleteColumn,
];

export const authorFiltersConfig: FiltersConfig = {
  [authorColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [authorColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [authorColumnsDefinitions.emailColumn?.key as string]: { filterType: FilterType.SEARCH },
  [authorColumnsDefinitions.positionColumn?.key as string]: { filterType: FilterType.SEARCH },
  [authorColumnsDefinitions.descriptionColumn?.key as string]: { filterType: FilterType.SEARCH },
  [authorColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [authorColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function getHasOwnPageCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <ShowCheckIcon value={record.hasOwnPage} />
    );
  };
}

function getDeleteCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <DeleteCell
        record={record}
        title="Delete Author"
        deleteThunkAction={deleteAuthor}
        onSuccess={() => {
          dispatchRefreshAuthorsGridEvent();
          message.success('The author has been successfully deleted');
        }}
        hasNotRelationsContent={() => <div>Author has no any relations.</div>}
        hasRelationsContent={() => {
          return (
            <div style={{}}>
              {/*{!!data.relations.length && (*/}
              {/*  <div>Author is used by next article ids ({map(data.relations, (i) => i.id).join(', ')})</div>*/}
              {/*)}*/}
              <div>If you really want to continue, enter your password.</div>
            </div>
          );
        }}
      />
    );
  };
}