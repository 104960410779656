import {
  AntGetListParams,
  CreateAuthorRequest,
  CreateAuthorResponse,
  GetListParams,
  ListResponse,
  Params,
  UpdateAuthorCertificateOrderRequest,
  UpdateAuthorCertificateOrderResponse,
  UpdateAuthorCertificateRequest,
  UpdateAuthorCertificateResponse,
  UpdateAuthorRequest,
  UpdateAuthorResponse,
} from '@api/types';

import { Author } from "@modelTypes/author";
import { AxiosRequestConfig } from 'axios';
import client from "./client";
import { getRestUri } from '@api/helpers';

const getUri = getRestUri('authors');
const ROOT_URI = getUri('');
const GET_AUTHORS_FOR_SELECT_URI = 'authors/select';

const GET_CERTIFICATES_URI = (authorId: number) => `/authors/${authorId}/certificates`;
const POST_CERTIFICATES_URI = (authorId: number) => `/authors/${authorId}/certificates`;
const PUT_CERTIFICATES_ORDER_URI = (id: number) => `/authors/certificates/${id}/order`;
const PATCH_CERTIFICATES_URI = (id: number) => `/authors/certificates/${id}`;

export async function getAuthors(params?: AntGetListParams) {
  return client.get<ListResponse<Author>>(ROOT_URI.GET(), params);
}

export async function getAuthorsForSelect(params?: GetListParams) {
  return client.get<ListResponse<Author>>(GET_AUTHORS_FOR_SELECT_URI, params);
}

export async function getAuthor(id: number, params?: Params) {
  return client.get<Author>(ROOT_URI.SHOW({ id }), params);
}

export async function createAuthor(payload: CreateAuthorRequest) {
  return client.post<CreateAuthorRequest, CreateAuthorResponse>(ROOT_URI.POST(), payload);
}

export async function updateAuthor(id: number, payload: UpdateAuthorRequest) {
  return client.patch<UpdateAuthorRequest, UpdateAuthorResponse>(ROOT_URI.PATCH({ id }), payload);
}

export async function deleteAuthor(id: number, payload?: AxiosRequestConfig) {
  return client.del(ROOT_URI.DELETE({ id }), payload);
}

export async function getAuthorCertificates(id: number) {
  return client.get<any>(GET_CERTIFICATES_URI(id));
}

export async function createAuthorCertificates(id: number, payload: FormData) {
  const config = {
    'content-type': 'multipart/form-data',
  }

  return client.post<FormData, any>(POST_CERTIFICATES_URI(id), payload, config);
}

export async function updateAuthorCertificate(id: number, payload: UpdateAuthorCertificateRequest) {
  return client.patch<UpdateAuthorCertificateRequest, UpdateAuthorCertificateResponse>(PATCH_CERTIFICATES_URI(id), payload);
}

export async function updateAuthorCertificateOrder(id: number, payload: UpdateAuthorCertificateOrderRequest) {
  return client.patch<UpdateAuthorCertificateOrderRequest, UpdateAuthorCertificateOrderResponse>(PUT_CERTIFICATES_ORDER_URI(id), payload);
}

export async function deleteAuthorCertificateImage(id: number, payload?: AxiosRequestConfig) {
  return client.del(PATCH_CERTIFICATES_URI(id), payload);
}