import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import * as React from 'react';
import { Language } from '@modelTypes/language';
import { updateQueryParams } from '@helpers/history';
import MethodsGrid from './components/MethodsGrid';

export default function MethodsIndex(): JSX.Element {
  const history = useHistory();
  const onSelectLanguage = (language: Language) => {
    updateQueryParams(history, {
      ['language-id']: language.id > 1 ? language.id : null,
    });
  };

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.push("/offers/programs")}
        title="Methods"
        extra={[
          <SelectLanguagePerForm
            key="selectLanguage"
            updateParamKey={false}
            queryParamKey="language-id"
            onInit={onSelectLanguage}
            onChange={onSelectLanguage}
          />,
          <Button
            key="createMethod"
            type="primary"
            href="/offers/methods/create"
            onClick={(e) => {
              e.preventDefault();

              history.push('/offers/methods/create');
            }}
          >
            Add Method
          </Button>
        ]}
      />
      <MethodsGrid />
    </>
  );
}
