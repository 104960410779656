import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg data-id="59" width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
  d="M20.4892 10.5358C20.4225 9.66338 20.1169 9.14613 20.1043 9.12496L20.0772 9.07962L20.0613 9.06374L15.0926 1.04384L15.0849 1.03189C14.9823 0.877981 14.8273 0.771843 14.6486 0.733161C14.4718 0.694886 14.2874 0.726103 14.1293 0.820976L14.112 0.831834C13.9612 0.932271 13.8561 1.08415 13.8155 1.25937C13.7734 1.44179 13.806 1.62773 13.9071 1.78368L18.8924 9.83073C18.9144 9.87403 19.0789 10.2163 19.0971 10.7655C19.1228 11.5372 18.8622 12.3381 18.3225 13.1462C18.27 13.2134 18.2342 13.2939 18.2188 13.3787L15.845 20.9453C15.7855 21.1081 15.7919 21.2849 15.8634 21.4448C15.9416 21.6195 16.0932 21.7585 16.2796 21.8262L16.3503 21.8518H16.3876C16.4162 21.8582 16.4579 21.8654 16.5069 21.8654C16.7913 21.8654 17.0619 21.6732 17.1646 21.3992L17.169 21.3877L19.5301 13.8592C19.9196 13.2611 20.1957 12.6491 20.3511 12.0394C20.4802 11.5329 20.5267 11.027 20.4892 10.5358Z"
  fill="currentColor" />
<path
  d="M11.252 14.7175L11.2528 14.7145C11.2895 14.5677 11.3687 14.3302 11.4604 14.0552C11.7416 13.2273 11.9304 12.6082 12.0033 12.0812C12.0475 11.761 12.0451 11.4947 11.9955 11.2671C11.9359 10.9931 11.807 10.7733 11.6121 10.6134L1.19907 0.213993C1.07746 0.0822028 0.910381 0.00646775 0.727287 0.000360088C0.534149 -0.0058833 0.344404 0.0693088 0.20705 0.206663C-0.0690166 0.48273 -0.0690166 0.915288 0.20705 1.19135L10.6323 11.6166C10.6364 11.683 10.6336 11.8279 10.5814 12.0999C10.4905 12.5672 10.2851 13.1662 10.1351 13.6038L10.1321 13.6128C10.1054 13.6971 10.0796 13.7769 10.0553 13.8525L10.054 13.8566C9.99193 14.0495 9.93819 14.216 9.89774 14.358L9.89448 14.3699C9.20486 17.0793 8.86717 19.3638 8.9178 20.9765L8.91929 21.0018C8.95336 21.3765 9.24666 21.6485 9.61652 21.6485H9.63009C9.82445 21.6485 10.0105 21.5668 10.1407 21.4244C10.266 21.2873 10.3276 21.1108 10.3151 20.9264C10.2821 19.8682 10.4184 17.9331 11.252 14.7175Z"
  fill="currentColor" />
<path
  d="M8.33622 2.54155L10.0537 5.51408C9.89435 5.74997 9.79554 6.02264 9.7646 6.3154C9.72428 6.6972 9.80368 7.07723 9.99424 7.41438L9.99669 7.41858C10.3245 7.98049 10.9086 8.316 11.5593 8.316C11.8574 8.316 12.1664 8.23185 12.4529 8.07265L12.4566 8.07061C12.7692 7.89199 13.0212 7.62 13.1773 7.30009C13.221 7.30349 13.2644 7.30512 13.3076 7.30512C13.6056 7.30512 13.9145 7.2211 14.2005 7.06217C14.6104 6.83442 14.9177 6.43267 15.0438 5.9598C15.1661 5.50105 15.106 5.01895 14.8744 4.60227L14.8701 4.59467C14.5472 4.04118 13.9485 3.69725 13.3074 3.69725C13.2667 3.69725 13.2279 3.69834 13.1903 3.70064L11.4807 0.74155L11.4777 0.736528C11.3972 0.602295 11.2808 0.504029 11.1318 0.444446L11.0962 0.430194L11.0585 0.422594C10.9236 0.395584 10.7717 0.411736 10.6307 0.468198L10.6028 0.479327L8.57876 1.64793L8.55582 1.66218C8.27392 1.85016 8.17701 2.2234 8.33052 2.53028L8.33622 2.54155ZM12.0453 6.50827C12.0471 6.67426 11.9562 6.83673 11.8054 6.93784C11.7146 6.98087 11.6297 7.00353 11.5592 7.00353C11.3822 7.00353 11.22 6.91233 11.1238 6.75909C11.0669 6.65363 11.0486 6.53216 11.0724 6.41638C11.0958 6.30265 11.1581 6.20112 11.2481 6.12959C11.3793 6.03649 11.4709 5.899 11.5065 5.74115C11.5433 5.57787 11.5165 5.41065 11.4312 5.2695L9.81088 2.46038L10.6687 1.96376L12.2909 4.77641L12.2985 4.78917C12.4693 5.06265 12.8173 5.17354 13.1105 5.04922C13.1777 5.02289 13.2439 5.00958 13.3074 5.00958C13.4744 5.00958 13.6359 5.10296 13.7308 5.25376C13.7951 5.37319 13.813 5.49711 13.784 5.62239C13.741 5.7592 13.6685 5.85217 13.5562 5.91447L13.5348 5.92723C13.4724 5.96699 13.383 5.99265 13.3074 5.99265C13.2165 5.99265 13.1299 5.96699 13.0431 5.91447C12.8431 5.78594 12.5873 5.78078 12.3825 5.89995C12.1568 6.01341 12.0238 6.2531 12.0453 6.50827Z"
  fill="currentColor" />
<path
  d="M16.8683 16.9203L16.8694 16.9161C16.9942 16.4483 16.9266 15.9516 16.6839 15.5522C16.4465 15.1297 16.0616 14.8295 15.5993 14.7062L15.5735 14.7003C15.4432 14.6742 15.2954 14.6446 15.1235 14.6446C14.9328 14.6446 14.733 14.6808 14.5405 14.7488C14.308 14.4807 13.9988 14.2847 13.6454 14.1852L13.6142 14.1777C13.4839 14.1517 13.3361 14.1221 13.1759 14.1221C12.3726 14.1221 11.6437 14.6817 11.442 15.4531C11.3408 15.8238 11.3596 16.2094 11.4965 16.5684C11.6016 16.8442 11.7687 17.0843 11.9859 17.2739L11.1119 20.5623C11.0585 20.7356 11.0791 20.9116 11.1717 21.0726C11.2545 21.2254 11.4095 21.343 11.5806 21.3826L13.7673 21.9673C13.8569 22 13.9327 22 13.9597 22C14.2465 22 14.5014 21.8135 14.5943 21.5353L14.5982 21.5233L15.4781 18.2127C15.7515 18.1581 16.0083 18.041 16.2318 17.8676C16.5421 17.6269 16.7622 17.2994 16.8683 16.9203ZM13.3769 17.1589C13.4699 16.8363 13.303 16.4989 12.9871 16.3725C12.88 16.3296 12.7914 16.2474 12.7377 16.1407C12.6838 16.0338 12.6708 15.9134 12.7009 15.8022C12.7582 15.592 12.9626 15.4336 13.1762 15.4336C13.2097 15.4336 13.2419 15.4368 13.2988 15.4531L13.3087 15.4558C13.4786 15.5 13.6078 15.6377 13.6459 15.8155L13.6527 15.8428C13.7165 16.0661 13.8949 16.2444 14.1175 16.3079L14.1456 16.3161L14.1736 16.32C14.3943 16.3516 14.616 16.2809 14.7662 16.1305L14.7852 16.1102C14.8709 16.0123 14.9942 15.9561 15.1236 15.9561C15.1802 15.9561 15.216 15.966 15.2288 15.9702L15.2596 15.9792C15.3811 16.0096 15.482 16.089 15.5601 16.2154C15.6167 16.3132 15.6308 16.4461 15.598 16.5708C15.541 16.7861 15.3508 16.9365 15.1354 16.9365C15.1237 16.9365 15.1096 16.9365 15.1026 16.9362L15.0768 16.9298L15.0466 16.9268C14.7258 16.8945 14.4364 17.0894 14.343 17.4009L14.3415 17.4057L13.4984 20.5355L12.5369 20.2774L13.3769 17.1589Z"
  fill="currentColor" />
<path
  d="M17.4212 8.67729C17.1341 8.3905 16.7307 8.27323 16.3367 8.36036C16.1196 8.01983 15.7515 7.8176 15.3456 7.8176C14.9398 7.8176 14.5719 8.01983 14.3546 8.36036C13.9603 8.27309 13.5569 8.39036 13.27 8.67742C12.9831 8.96435 12.8659 9.36772 12.9531 9.76187C12.6125 9.97903 12.4103 10.3471 12.4103 10.7528C12.4103 11.1585 12.6127 11.5267 12.9531 11.7437C12.8659 12.138 12.9832 12.5414 13.2701 12.8283C13.557 13.1151 13.9603 13.2324 14.3546 13.1452C14.5717 13.4858 14.9398 13.688 15.3456 13.688C15.7515 13.688 16.1196 13.4856 16.3367 13.1452C16.6675 13.2181 17.0106 13.1466 17.2829 12.9469L17.2978 12.936L17.4556 12.7925L17.4679 12.7788C17.7195 12.497 17.8192 12.1121 17.7381 11.7437C18.0786 11.5266 18.2809 11.1585 18.2809 10.7528C18.2809 10.3471 18.0785 9.9789 17.7381 9.76187C17.8252 9.36759 17.7081 8.96421 17.4212 8.67729ZM16.9236 10.8308L16.9096 10.7744V10.8431C16.8578 10.8888 16.8099 10.9389 16.7667 10.9933L16.7419 11.0245L16.6265 11.2654C16.525 11.4774 16.4927 11.7148 16.5337 11.9404C16.0731 11.8531 15.6099 12.0449 15.3458 12.4323C15.1264 12.1102 14.7691 11.9199 14.377 11.9199C14.3037 11.9199 14.2302 11.9268 14.158 11.9404C14.2452 11.4798 14.0534 11.0167 13.666 10.7527C14.0533 10.4887 14.2452 10.0256 14.158 9.56493C14.2319 9.57891 14.3056 9.5857 14.3787 9.5857C14.7619 9.5857 15.124 9.39826 15.3458 9.07306C15.5652 9.39528 15.9223 9.58556 16.314 9.58556C16.3875 9.58556 16.461 9.57864 16.5335 9.56493C16.4464 10.0256 16.6382 10.4887 17.0255 10.7527C16.99 10.7768 16.956 10.803 16.9236 10.8308Z"
  fill="currentColor" />
<path
  d="M15.8226 11.0229C15.6339 11.0229 15.4452 11.1768 15.4452 11.3615C15.4452 11.5461 15.5961 11.7 15.8226 11.7C16.0113 11.7 16.2 11.5461 16.2 11.3615C16.2 11.1768 16.0113 11.0229 15.8226 11.0229Z"
  fill="currentColor" />
<path
  d="M16.0489 10.0385C16.0489 9.85388 15.898 9.7 15.6715 9.7C15.4829 9.7 15.2942 9.85388 15.2942 10.0385C15.2942 10.2232 15.4451 10.3771 15.6715 10.3771C15.898 10.4079 16.0489 10.254 16.0489 10.0385Z"
  fill="currentColor" />
<path
  d="M14.5773 10.316C14.3886 10.316 14.2 10.4699 14.2 10.6546C14.2 10.8392 14.3509 10.9931 14.5773 10.9931C14.766 10.9931 14.9547 10.8392 14.9547 10.6546C14.917 10.4699 14.766 10.316 14.5773 10.316Z"
  fill="currentColor" />
</svg>`;

export default { id: '59-orthopaedic-oncology', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
