import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M3.76593 4.19345C3.90299 4.27411 4.04004 4.35477 4.17892 4.43664L4.61253 4.69135C4.57736 4.75139 4.53975 4.8187 4.50458 4.89087C4.43787 5.02853 4.41119 5.23836 4.50155 5.39665C4.60283 5.57676 4.80902 5.60405 4.92242 5.60405C4.98004 5.60405 5.02249 5.59738 5.04189 5.59313C5.42335 5.51551 5.80905 5.43364 6.18141 5.35419L6.38639 5.31053C6.60046 5.26505 6.81515 5.21956 7.02983 5.17469C7.19963 5.13951 7.32213 5.06007 7.39551 4.93817C7.46708 4.81749 7.4786 4.67619 7.43008 4.51669C7.38945 4.38448 7.34942 4.25289 7.30879 4.12068L7.22389 3.84232C7.10381 3.44752 6.96736 3.00057 6.83273 2.57242C6.79149 2.44022 6.6508 2.23281 6.43248 2.2037C6.21294 2.17399 6.03101 2.34197 5.95763 2.44567C5.91639 2.5045 5.87697 2.56514 5.83088 2.64156C5.7296 2.58212 5.62833 2.52269 5.52705 2.46265L5.40273 2.38988C5.24991 2.30013 5.09769 2.21037 4.94486 2.12122C4.62163 1.93262 4.37116 1.9963 4.17953 2.31711C4.05884 2.51784 3.93877 2.71918 3.81869 2.91992L3.55489 3.36202C3.47787 3.4918 3.43602 3.59428 3.42389 3.68525C3.38993 3.93571 3.57793 4.08247 3.76593 4.19345ZM4.52035 3.56154C4.61192 3.40871 4.70168 3.25831 4.79264 3.1067L4.82842 3.12793C5.02855 3.24436 5.22504 3.35898 5.42214 3.47421C5.46823 3.50089 5.51371 3.52818 5.5598 3.55547C5.61559 3.58883 5.67139 3.62157 5.72718 3.65372C5.8606 3.73073 5.99341 3.75196 6.12137 3.71739C6.14927 3.71012 6.17595 3.69981 6.20203 3.68707L6.40761 4.35901L5.71444 4.50699C5.71444 4.29473 5.5507 4.16616 5.36877 4.05943L4.52035 3.56154Z"
    fill="currentColor" />
  <path
    d="M4.30511 11.3568C4.37667 11.2361 4.38819 11.0942 4.33968 10.9353C4.29722 10.7971 4.25477 10.6582 4.21232 10.5193L4.1347 10.2646C4.01462 9.87162 3.87878 9.42588 3.74233 8.99045C3.70109 8.85825 3.56039 8.65084 3.34268 8.62173C3.12314 8.59202 2.94121 8.76 2.86783 8.86371C2.82659 8.92253 2.78717 8.98257 2.74108 9.05959C2.6398 9.00016 2.53853 8.94073 2.43725 8.88069L2.30141 8.80124C2.15283 8.71392 2.00364 8.62659 1.85445 8.53926C1.53122 8.35065 1.28076 8.41433 1.08912 8.73514C0.972074 8.92981 0.855636 9.12448 0.739805 9.31976L0.465084 9.78005C0.388066 9.90983 0.346221 10.0123 0.334092 10.1033C0.300131 10.3537 0.487523 10.5011 0.675522 10.6115C0.809546 10.6903 0.942965 10.7692 1.07942 10.8492L1.52273 11.1094C1.48755 11.1694 1.44995 11.2367 1.41478 11.3089C1.34807 11.4466 1.32139 11.6564 1.41175 11.8147C1.51303 11.9948 1.71922 12.0221 1.83262 12.0221C1.89084 12.0221 1.93269 12.0154 1.95209 12.0112C2.3384 11.9323 2.74108 11.8468 3.09585 11.7716L3.32448 11.7231C3.52946 11.6794 3.73505 11.6358 3.94003 11.5933C4.10983 11.5582 4.23173 11.4781 4.30511 11.3568ZM3.31721 10.777L2.62404 10.925C2.62404 10.7128 2.4603 10.5842 2.27836 10.4775L1.43055 9.97957C1.52212 9.82735 1.61188 9.67635 1.70284 9.52474L1.73862 9.54596C1.93875 9.6624 2.13524 9.77702 2.33234 9.89224C2.37843 9.91893 2.42452 9.94622 2.47 9.97351C2.52579 10.0069 2.58159 10.0396 2.63738 10.0717C2.7708 10.1488 2.90361 10.17 3.03157 10.1354C3.05947 10.1281 3.08615 10.1178 3.11223 10.1051L3.31721 10.777Z"
    fill="currentColor" />
  <path
    d="M15.9489 12.0367C15.7348 11.8299 15.3915 11.8359 15.1841 12.05C15.0835 12.1537 15.0295 12.2908 15.0319 12.4351C15.0343 12.5794 15.0932 12.7147 15.1969 12.8148C16.4425 14.0192 16.8664 15.1659 16.391 16.0441C15.8858 16.9774 14.4976 17.4523 13.161 17.1478C13.0415 17.1205 12.9275 17.1139 12.8117 17.1278C12.3496 17.1824 11.8165 17.2685 11.2525 17.3595C9.2276 17.6863 6.16747 18.1806 5.40031 17.1454C5.02856 16.6439 5.17956 15.6693 5.83695 14.3284C7.86794 10.1852 8.9171 9.16151 9.48109 8.61207C10.1173 7.99168 10.2913 7.683 10.4071 6.2912C10.5854 4.15105 11.8984 1.79864 12.5679 0.853795C12.6516 0.736144 12.6843 0.592416 12.6595 0.449901C12.6352 0.307386 12.557 0.183065 12.4393 0.0999813C12.3217 0.0162916 12.1786 -0.0164566 12.0361 0.00780131C11.8935 0.0320592 11.7686 0.110291 11.6849 0.228548C10.9566 1.25708 9.52658 3.82902 9.32887 6.20145C9.23366 7.34217 9.15604 7.41798 8.72546 7.83764C8.11477 8.43317 6.97889 9.54115 4.86542 13.8524C3.99457 15.6299 3.8848 16.9174 4.53066 17.7894C5.0607 18.505 6.05224 18.8525 7.56351 18.8525C8.79338 18.8525 10.1912 18.6269 11.4248 18.4274L11.4332 18.4262C11.7625 18.3728 12.1028 18.3183 12.4169 18.2722V19.4644C12.4169 19.57 12.4066 19.6755 12.386 19.778L12.0712 21.3529C12.013 21.6452 12.2028 21.9309 12.4957 21.9897C12.5309 21.997 12.5667 22 12.6019 22C12.8584 22 13.0816 21.8169 13.1319 21.5652L13.4467 19.9902C13.4812 19.818 13.4988 19.6409 13.4988 19.4644V18.2964C15.1259 18.4547 16.6996 17.7464 17.3425 16.559C17.7639 15.7803 18.1436 14.158 15.9489 12.0367Z"
    fill="currentColor" />
  <path
    d="M13.9149 2.48575C13.947 2.49182 13.9804 2.49485 14.0125 2.49485C14.2733 2.49485 14.497 2.30867 14.5443 2.05214C14.602 1.738 14.6523 1.41234 14.6941 1.08365C14.7317 0.787699 14.5213 0.516617 14.2266 0.479018C14.0834 0.460218 13.9415 0.49903 13.8269 0.586965C13.7123 0.675506 13.6389 0.803467 13.6207 0.947801C13.5819 1.2577 13.5346 1.56274 13.4806 1.85565C13.4546 1.99756 13.4855 2.14129 13.5674 2.26076C13.6492 2.37962 13.7729 2.45967 13.9149 2.48575Z"
    fill="currentColor" />
  <path
    d="M12.3205 5.72232C12.3666 5.85938 12.463 5.97036 12.5922 6.03464C12.6668 6.07164 12.7499 6.09165 12.833 6.09165C13.0398 6.09165 13.2254 5.97703 13.3175 5.79207C13.4643 5.49733 13.6013 5.19411 13.7257 4.89028C13.8385 4.61435 13.7057 4.29778 13.4297 4.18559C13.2963 4.13101 13.1489 4.13161 13.0155 4.18741C12.8821 4.2432 12.7784 4.34811 12.7238 4.48214C12.6098 4.76171 12.4837 5.04007 12.3496 5.30994C12.2854 5.43911 12.2744 5.58527 12.3205 5.72232Z"
    fill="currentColor" />
  <path
    d="M11.1331 9.03901C11.3605 8.76308 11.5588 8.51504 11.7377 8.28095C11.8256 8.16633 11.8639 8.02443 11.8444 7.8807C11.8256 7.73758 11.7523 7.61022 11.6376 7.52229C11.523 7.43435 11.3811 7.39615 11.2374 7.41495C11.0943 7.43375 10.9669 7.50713 10.879 7.62174C10.7061 7.84674 10.5163 8.08507 10.298 8.34948C10.1082 8.57932 10.1409 8.92136 10.3708 9.11118C10.4672 9.19062 10.5897 9.23489 10.7152 9.23489C10.8772 9.23489 11.03 9.16394 11.1331 9.03901Z"
    fill="currentColor" />
  <path
    d="M7.61203 14.3679C7.69997 14.0828 7.53865 13.7796 7.25241 13.6911C6.96738 13.6056 6.66537 13.7663 6.57804 14.0495C6.50284 14.2951 6.43552 14.5486 6.37912 14.8039C6.35972 14.8912 6.35001 14.9804 6.35001 15.0695C6.35001 15.1059 6.35183 15.1441 6.35487 15.1817C6.38034 15.4619 6.612 15.6729 6.89339 15.6729C6.90976 15.6729 6.92675 15.6723 6.94312 15.6705C7.24028 15.6432 7.45981 15.3794 7.43252 15.0823C7.43252 15.078 7.43192 15.0732 7.43192 15.0689C7.43192 15.0586 7.43313 15.0477 7.43555 15.0374C7.48589 14.81 7.54532 14.585 7.61203 14.3679Z"
    fill="currentColor" />
  <path
    d="M8.7867 11.9608C8.9468 11.7231 9.12267 11.4805 9.32401 11.2197C9.41255 11.1051 9.45076 10.9632 9.43196 10.8201C9.41316 10.677 9.34039 10.5496 9.22577 10.4611C9.11115 10.3725 8.96924 10.3343 8.82551 10.3531C8.68239 10.3719 8.55504 10.4453 8.4671 10.5593C8.25181 10.8389 8.0626 11.0997 7.88976 11.3568C7.80911 11.4769 7.78 11.6206 7.80729 11.7625C7.83518 11.9044 7.91584 12.0269 8.03592 12.1076C8.12567 12.1682 8.22998 12.2004 8.33793 12.2004C8.51804 12.2004 8.68603 12.1106 8.7867 11.9608Z"
    fill="currentColor" />
  <path
    d="M8.97965 17.2285C9.32896 17.3146 9.67645 17.3686 10.0118 17.3892C10.0239 17.3898 10.0361 17.3904 10.0482 17.3904C10.3332 17.3904 10.5704 17.1672 10.5879 16.8822C10.6061 16.5844 10.3787 16.3273 10.081 16.3091C9.80866 16.2927 9.52484 16.2485 9.2386 16.1775C9.0979 16.1429 8.95296 16.1654 8.82925 16.24C8.70553 16.3146 8.61881 16.4334 8.58424 16.5735C8.51268 16.8628 8.68976 17.1575 8.97965 17.2285Z"
    fill="currentColor" />
</svg>`;

export default { id: '103-nose-surgery', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
