import { useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks';
import { Button, Form, FormInstance, Space, } from 'antd';
import { getBlogsForSelect } from '@storage/blogs/thunkActions';
import { BlogsState } from '@storage/blogs/reducer';
import SelectArticle from '@base/Select/SelectArticle';
import { DownloadOutlined } from '@ant-design/icons';

interface Props {
  form: FormInstance;
  initialValue?: any;
  withImportBtn?: boolean;
  onClickImportBtn?: () => void;
}

function SelectArticleWrapper(
  {
    withImportBtn,
    onClickImportBtn,
    ...rest
  }: any
) {
  if (!withImportBtn) {
    return <SelectArticle {...rest} />;
  }

  return (
    <Space.Compact block>
      <SelectArticle {...rest} />
      <Button onClick={onClickImportBtn}>
        <DownloadOutlined />
      </Button>
    </Space.Compact>
  )
}

export default function ArticlesField(
  {
    form,
    initialValue,
    onClickImportBtn,
    withImportBtn = false,
  }: Props
) {
  const dispatch = useDispatch();
  const data = useSelector<BlogsState['selectBlogs']>('blogs.selectBlogs');
  const [count, setCount] = useState<number>();
  const filteredData = useMemo(
    () => {
      return data.data.filter((i) => i.isActive);
    },
    [data.fetched, data.loading]
  );

  useEffect(() => {
    if (!data.fetched && !data.loading) {
      dispatch(getBlogsForSelect() as unknown as Promise<void>)
        .then(() => {
          form.resetFields(['articles']);
        });
    }
  }, [data.fetched, data.loading]);

  useEffect(() => {
    if (initialValue && initialValue.length) {
      setCount(initialValue.length);
    }
  }, [initialValue]);

  return (
    <Form.Item
      name="articles"
      label={`Blogs ${count ? `(${count} selected)` : ''}`}
      initialValue={data.fetched ? initialValue : []}
      valuePropName="defaultValue"
    >
      <SelectArticleWrapper
        mode="multiple"
        placeholder="Select articles"
        style={{ width: '100%' }}
        loading={data.loading}
        options={filteredData}
        onChange={() => setCount(form.getFieldValue('articles')?.length)}
        onClickImportBtn={onClickImportBtn}
        withImportBtn={withImportBtn}
      />
    </Form.Item>
  );
}
