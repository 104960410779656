import { CreateRoleResponse, ListResponse } from '@api/types';

import { Permission } from '@modelTypes/permission';
import { Role } from '@modelTypes/role';
import { createAction } from '../helpers';

export enum ActionType {
  START_FETCH_ROLES = 'ROLES__START_FETCH_ROLES',
  END_FETCH_ROLES = 'ROLES__END_FETCH_ROLES',

  START_FETCH_ROLES_FOR_SELECT = 'ROLES__START_FETCH_ROLES_FOR_SELECT',
  END_FETCH_ROLES_FOR_SELECT = 'ROLES__END_FETCH_ROLES_FOR_SELECT',
  CLEAR_SELECT_ROLE_DATA = 'ROLES__CLEAR_SELECT_ROLE_DATA',

  START_FETCH_ROLE = 'ROLES__START_FETCH_ROLE',
  END_FETCH_ROLE = 'ROLES__END_FETCH_ROLE',

  START_POST_ROLE = 'ROLES__START_POST_ROLE',
  END_POST_ROLE = 'ROLES__END_POST_ROLE',
  END_POST_ROLE_ERROR = 'ROLES__END_POST_ROLE_ERROR',

  START_PATCH_ROLE = 'ROLES__START_PATCH_ROLE',
  END_PATCH_ROLE = 'ROLES__END_PATCH_ROLE',
  END_PATCH_ROLE_ERROR = 'ROLES__END_PATCH_ROLE_ERROR',

  START_DELETE_ROLE = 'ROLES__START_DELETE_ROLE',

  START_FETCH_PERMISSIONS = 'ROLES__START_FETCH_PERMISSIONS',
  END_FETCH_PERMISSIONS = 'ROLES__END_FETCH_PERMISSIONS',

  CLEAR_FORM_DATA = 'ROLES__CLEAR_FORM_DATA',
}

export const startFetchRoles = createAction<ActionType.START_FETCH_ROLES>(ActionType.START_FETCH_ROLES);
export const endFetchRoles = createAction<ActionType.END_FETCH_ROLES, ListResponse<Role>>(ActionType.END_FETCH_ROLES);

export const startFetchRolesForSelect = createAction<ActionType.START_FETCH_ROLES_FOR_SELECT>(ActionType.START_FETCH_ROLES_FOR_SELECT);
export const endFetchRolesForSelect = createAction<ActionType.END_FETCH_ROLES_FOR_SELECT, ListResponse<Role>>(ActionType.END_FETCH_ROLES_FOR_SELECT);
export const clearSelectRoleData = createAction<ActionType.CLEAR_SELECT_ROLE_DATA>(ActionType.CLEAR_SELECT_ROLE_DATA);

export const startFetchRole = createAction<ActionType.START_FETCH_ROLE>(ActionType.START_FETCH_ROLE);
export const endFetchRole = createAction<ActionType.END_FETCH_ROLE, Role>(ActionType.END_FETCH_ROLE);

export const startPostRole = createAction<ActionType.START_POST_ROLE>(ActionType.START_POST_ROLE);
export const endPostRole = createAction<ActionType.END_POST_ROLE, CreateRoleResponse>(ActionType.END_POST_ROLE);
export const endPostRoleError = createAction<ActionType.END_POST_ROLE_ERROR>(ActionType.END_POST_ROLE_ERROR);

export const startUpdateRole = createAction<ActionType.START_PATCH_ROLE>(ActionType.START_PATCH_ROLE);
export const endUpdateRole = createAction<ActionType.END_PATCH_ROLE, Role>(ActionType.END_PATCH_ROLE);
export const endUpdateRoleError = createAction<ActionType.END_PATCH_ROLE_ERROR>(ActionType.END_PATCH_ROLE_ERROR);

export const startDeleteRole = createAction<ActionType.START_DELETE_ROLE>(ActionType.START_DELETE_ROLE);

export const startFetchPermissions = createAction<ActionType.START_FETCH_PERMISSIONS>(ActionType.START_FETCH_PERMISSIONS);
export const endFetchPermissions = createAction<ActionType.END_FETCH_PERMISSIONS, Array<Permission>>(ActionType.END_FETCH_PERMISSIONS);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);


export type Actions =
  | ReturnType<typeof startFetchRoles>
  | ReturnType<typeof endFetchRoles>
  | ReturnType<typeof startFetchRolesForSelect>
  | ReturnType<typeof endFetchRolesForSelect>
  | ReturnType<typeof clearSelectRoleData>
  | ReturnType<typeof startFetchRole>
  | ReturnType<typeof endFetchRole>
  | ReturnType<typeof startPostRole>
  | ReturnType<typeof endPostRole>
  | ReturnType<typeof endPostRoleError>
  | ReturnType<typeof startUpdateRole>
  | ReturnType<typeof endUpdateRole>
  | ReturnType<typeof endUpdateRoleError>
  | ReturnType<typeof startDeleteRole>
  | ReturnType<typeof startFetchPermissions>
  | ReturnType<typeof endFetchPermissions>
  | ReturnType<typeof clearFormData>;