import { Button, Card, Col, Descriptions, Divider, Image, List, Row, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useOrderState } from '@zustandStorage/orders/orders';
import { OrderStatuses } from '../../../modelTypes/order';
import { PageHeader } from '@ant-design/pro-layout';
import moment from 'moment/moment';
import { DownloadOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface RouterParams {
  status: OrderStatuses;
  id: string;
}

export default function OrderDetails() {
  const { id, status } = useParams<RouterParams>();
  const history = useHistory();

  const {
    orderDetails: { data },
    fetchOrder,
  } = useOrderState()

  function setTitle(id: string) {
    document.title = `View Order #${id} | Airomedical`;
  }

  useEffect(() => {
    fetchOrder(+id)
    setTitle(id)
  }, [id]);


  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => {
          history.push(`/orders/${status}`)
        }}
        title={`View details and track the status of order #${id}, created on ${moment(data?.createdAt).format('DD/MM/YYYY')}.`}
      />
      <Row gutter={[32, 24]}>
        <Col span={16}>
          <Descriptions layout="vertical">
            <Descriptions.Item label=" Name">{data?.name}</Descriptions.Item>
            <Descriptions.Item label=" Telephone">{data?.phone}</Descriptions.Item>
            <Descriptions.Item label=" Email">{data?.email}</Descriptions.Item>
            <Descriptions.Item label=" Description">{data?.description}</Descriptions.Item>
          </Descriptions>
          <Divider />
          <Space direction={'vertical'} size={'large'}>
            <Text strong style={{ paddingBottom: '16px' }}>Attached files:</Text>
            <List
              grid={{ gutter: 16, column: 4 }}
              dataSource={data?.files}
              renderItem={(item) => (
                <List.Item>
                  <Card
                    bodyStyle={{
                      padding: '16px 16px 0 16px',
                      marginBottom: 'auto',
                      minHeight: 160,
                      textAlign: 'center'
                    }}
                    actions={[
                      <Button href={item.url}>
                        Download
                      </Button>
                    ]}>
                    {item.type === 'image' ? (
                        <Image
                          width={'100%'}
                          src={item.url}
                        />
                      )
                      : (
                        <>
                          <Space direction={'vertical'} size={'large'}>
                            <DownloadOutlined style={{ fontSize: '48px' }} />
                            <Text>File type: {item.path.split('.')[1]}</Text>
                          </Space>
                        </>
                      )
                    }
                  </Card>
                </List.Item>
              )}
            />
          </Space>
        </Col>
      </Row>
    </>
  );
}

