import { message, Tooltip } from 'antd';
import React from 'react';

function IdCell({ id }: { id: string | number }) {
  const value = typeof id === 'number' ? `${id.toString()}` : id;
  const truncatedValue = value.length > 4 ? `${value.slice(0, 2)}..${value.slice(-2)}` : value;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value);
    message.success('ID copied to clipboard');
  };

  return (
    <Tooltip title={value.length > 4 ? `${value}`: ''}>
      <div style={{ cursor: 'pointer' }} onClick={copyToClipboard}>
        {truncatedValue}
      </div>
    </Tooltip>
  );
}


export default function getIdCell() {
  return (value: string | number) => {
    if (value) {
      return <IdCell id={value} />;
    }

    return null;
  };
}
