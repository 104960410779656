import { Hospital, HospitalDepartment } from '@modelTypes/hospital';
import {
  SearchSection,
  SearchSectionExcludedHospital,
  SearchSectionRelation,
  SearchSectionTranslation
} from '@modelTypes/searchSection';

import { ListResponse } from '@api/types';
import { SearchSectionsState } from '@storage/searchSections/reducer';
import { createAction } from '../helpers';

export enum ActionType {
  START_FETCH_SEARCH_SECTIONS = 'SEARCH_SECTIONS__START_FETCH_SEARCH_SECTIONS',
  END_FETCH_SEARCH_SECTIONS = 'SEARCH_SECTIONS__END_FETCH_SEARCH_SECTIONS',

  START_FETCH_SEARCH_SECTIONS_FOR_SELECT = 'SEARCH_SECTIONS__START_FETCH_SEARCH_SECTIONS_FOR_SELECT',
  END_FETCH_SEARCH_SECTIONS_FOR_SELECT = 'SEARCH_SECTIONS__END_FETCH_SEARCH_SECTIONS_FOR_SELECT',
  CLEAR_SEARCH_SECTIONS_SELECT = 'SEARCH_SECTIONS__CLEAR_SEARCH_SECTIONS_SELECT',

  START_FETCH_SEARCH_SECTIONS_FOR_TREE_SELECT = 'SEARCH_SECTIONS__START_FETCH_SEARCH_SECTIONS_FOR_TREE_SELECT',
  END_FETCH_SEARCH_SECTIONS_FOR_TREE_SELECT = 'SEARCH_SECTIONS__END_FETCH_SEARCH_SECTIONS_FOR_TREE_SELECT',
  CLEAR_SEARCH_SECTIONS_TREE_SELECT = 'SEARCH_SECTIONS__CLEAR_SEARCH_SECTIONS_TREE_SELECT',

  START_FETCH_SEARCH_SECTION = 'SEARCH_SECTIONS__START_FETCH_SEARCH_SECTION',
  END_FETCH_SEARCH_SECTION = 'SEARCH_SECTIONS__END_FETCH_SEARCH_SECTION',

  START_FETCH_PARENT_SEARCH_SECTION = 'SEARCH_SECTIONS__START_FETCH_PARENT_SEARCH_SECTION',
  END_FETCH_PARENT_SEARCH_SECTION = 'SEARCH_SECTIONS__END_FETCH_PARENT_SEARCH_SECTION',
  CLEAR_PARENT_SEARCH_SECTION_DATA = 'SEARCH_SECTIONS__CLEAR_PARENT_SEARCH_SECTION_DATA',

  START_POST_SEARCH_SECTION = 'SEARCH_SECTIONS__START_POST_SEARCH_SECTION',
  END_POST_SEARCH_SECTION = 'SEARCH_SECTIONS__END_POST_SEARCH_SECTION',
  END_POST_SEARCH_SECTION_ERROR = 'SEARCH_SECTIONS__END_POST_SEARCH_SECTION_ERROR',

  START_PATCH_SEARCH_SECTION = 'SEARCH_SECTIONS__START_PATCH_SEARCH_SECTION',
  END_PATCH_SEARCH_SECTION = 'SEARCH_SECTIONS__END_PATCH_SEARCH_SECTION',
  END_PATCH_SEARCH_SECTION_ERROR = 'SEARCH_SECTIONS__END_PATCH_SEARCH_SECTION_ERROR',

  START_PATCH_SEARCH_SECTION_TRANSLATION = 'SEARCH_SECTIONS__START_PATCH_SEARCH_SECTION_TRANSLATION',
  END_PATCH_SEARCH_SECTION_TRANSLATION = 'SEARCH_SECTIONS__END_PATCH_SEARCH_SECTION_TRANSLATION',

  START_DELETE_SEARCH_SECTION = 'SEARCH_SECTIONS__START_DELETE_SEARCH_SECTION',
  END_DELETE_SEARCH_SECTION = 'SEARCH_SECTIONS__END_DELETE_SEARCH_SECTION',

  CLEAR_FORM_DATA = 'SEARCH_SECTIONS__CLEAR_FORM_DATA',

  START_FETCH_RELATIONS = 'SEARCH_SECTIONS__START_FETCH_RELATIONS',
  END_FETCH_RELATIONS = 'SEARCH_SECTIONS__END_FETCH_RELATIONS',
  CLEAR_RELATIONS = 'SEARCH_SECTIONS__CLEAR_RELATIONS',

  START_FETCH_ALL_RELATIONS = 'SEARCH_SECTIONS__START_FETCH_ALL_RELATIONS',
  END_FETCH_ALL_RELATIONS = 'SEARCH_SECTIONS__END_FETCH_ALL_RELATIONS',
  CLEAR_ALL_RELATIONS = 'SEARCH_SECTIONS__CLEAR_ALL_RELATIONS',

  START_IMPORT_RELATIONS = 'SEARCH_SECTIONS__START_IMPORT_RELATIONS',
  START_IMPORT_EXCLUDED_HOSPITALS = 'SEARCH_SECTIONS__START_IMPORT_EXCLUDED_HOSPITALS',

  START_FETCH_HOSPITALS_FOR_SELECT = 'SEARCH_SECTIONS__START_FETCH_HOSPITALS_FOR_SELECT',
  END_FETCH_HOSPITALS_FOR_SELECT = 'SEARCH_SECTIONS__END_FETCH_HOSPITALS_FOR_SELECT',
  CLEAR_HOSPITALS_SELECT = 'SEARCH_SECTIONS__CLEAR_HOSPITALS_SELECT',

  START_FETCH_DEPARTMENTS_FOR_SELECT = 'SEARCH_SECTIONS__START_FETCH_DEPARTMENTS_FOR_SELECT',
  END_FETCH_DEPARTMENTS_FOR_SELECT = 'SEARCH_SECTIONS__END_FETCH_DEPARTMENTS_FOR_SELECT',
  CLEAR_DEPARTMENTS_SELECT = 'SEARCH_SECTIONS__CLEAR_DEPARTMENTS_SELECT',

  START_FETCH_EXCLUDED_HOSPITALS = 'SEARCH_SECTIONS__START_FETCH_EXCLUDED_HOSPITALS',
  END_FETCH_EXCLUDED_HOSPITALS = 'SEARCH_SECTIONS__END_FETCH_EXCLUDED_HOSPITALS',

  START_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT = 'SEARCH_SECTIONS__START_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT',
  END_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT = 'SEARCH_SECTIONS__END_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT',
  CLEAR_EXCLUDED_HOSPITALS_SELECT = 'SEARCH_SECTIONS__CLEAR_EXCLUDED_HOSPITALS_SELECT',

  START_POST_EXCLUDED_HOSPITALS = 'SEARCH_SECTIONS__START_POST_EXCLUDED_HOSPITALS',
  END_POST_EXCLUDED_HOSPITALS = 'SEARCH_SECTIONS__END_POST_EXCLUDED_HOSPITALS',

  START_DELETE_EXCLUDED_HOSPITAL = 'SEARCH_SECTIONS__START_DELETE_EXCLUDED_HOSPITAL',
  END_DELETE_EXCLUDED_HOSPITAL = 'SEARCH_SECTIONS__END_DELETE_EXCLUDED_HOSPITAL',
}

interface EndFetchSearchSectionsForSelect {
  data: Array<SearchSection>;
}

interface EndFetchDepartmentsForSelect {
  data: Array<HospitalDepartment>;
}

type AllRelations = Pick<SearchSectionsState['allRelations'], 'hospitalsIds' | 'departmentsIds'> & { excludedHospitalsIds: Array<number> };

export const startFetchSearchSections = createAction<ActionType.START_FETCH_SEARCH_SECTIONS>(ActionType.START_FETCH_SEARCH_SECTIONS);
export const endFetchSearchSections = createAction<ActionType.END_FETCH_SEARCH_SECTIONS, ListResponse<SearchSection>>(ActionType.END_FETCH_SEARCH_SECTIONS);

export const startFetchSearchSectionsForSelect = createAction<ActionType.START_FETCH_SEARCH_SECTIONS_FOR_SELECT>(ActionType.START_FETCH_SEARCH_SECTIONS_FOR_SELECT);
export const endFetchSearchSectionsForSelect = createAction<ActionType.END_FETCH_SEARCH_SECTIONS_FOR_SELECT, EndFetchSearchSectionsForSelect>(ActionType.END_FETCH_SEARCH_SECTIONS_FOR_SELECT);
export const clearSearchSectionsSelect = createAction<ActionType.CLEAR_SEARCH_SECTIONS_SELECT>(ActionType.CLEAR_SEARCH_SECTIONS_SELECT);

export const startFetchSearchSectionsForTreeSelect = createAction<ActionType.START_FETCH_SEARCH_SECTIONS_FOR_TREE_SELECT>(ActionType.START_FETCH_SEARCH_SECTIONS_FOR_TREE_SELECT);
export const endFetchSearchSectionsForTreeSelect = createAction<ActionType.END_FETCH_SEARCH_SECTIONS_FOR_TREE_SELECT, EndFetchSearchSectionsForSelect>(ActionType.END_FETCH_SEARCH_SECTIONS_FOR_TREE_SELECT);
export const clearSearchSectionsTreeSelect = createAction<ActionType.CLEAR_SEARCH_SECTIONS_TREE_SELECT>(ActionType.CLEAR_SEARCH_SECTIONS_TREE_SELECT);

export const startFetchSearchSection = createAction<ActionType.START_FETCH_SEARCH_SECTION>(ActionType.START_FETCH_SEARCH_SECTION);
export const endFetchSearchSection = createAction<ActionType.END_FETCH_SEARCH_SECTION, SearchSection>(ActionType.END_FETCH_SEARCH_SECTION);

export const startFetchParentSearchSection = createAction<ActionType.START_FETCH_PARENT_SEARCH_SECTION>(ActionType.START_FETCH_PARENT_SEARCH_SECTION);
export const endFetchParentSearchSection = createAction<ActionType.END_FETCH_PARENT_SEARCH_SECTION, SearchSection>(ActionType.END_FETCH_PARENT_SEARCH_SECTION);
export const clearParentSearchSectionData = createAction<ActionType.CLEAR_PARENT_SEARCH_SECTION_DATA>(ActionType.CLEAR_PARENT_SEARCH_SECTION_DATA);

export const startPostSearchSection = createAction<ActionType.START_POST_SEARCH_SECTION>(ActionType.START_POST_SEARCH_SECTION);
export const endPostSearchSection = createAction<ActionType.END_POST_SEARCH_SECTION, SearchSection>(ActionType.END_POST_SEARCH_SECTION);
export const endPostSearchSectionError = createAction<ActionType.END_POST_SEARCH_SECTION_ERROR>(ActionType.END_POST_SEARCH_SECTION_ERROR);

export const startUpdateSearchSection = createAction<ActionType.START_PATCH_SEARCH_SECTION>(ActionType.START_PATCH_SEARCH_SECTION);
export const endUpdateSearchSection = createAction<ActionType.END_PATCH_SEARCH_SECTION, SearchSection>(ActionType.END_PATCH_SEARCH_SECTION);
export const endUpdateSearchSectionError = createAction<ActionType.END_PATCH_SEARCH_SECTION_ERROR>(ActionType.END_PATCH_SEARCH_SECTION_ERROR);

export const startUpdateSearchSectionTranslation = createAction<ActionType.START_PATCH_SEARCH_SECTION_TRANSLATION>(ActionType.START_PATCH_SEARCH_SECTION_TRANSLATION);
export const endUpdateSearchSectionTranslation = createAction<ActionType.END_PATCH_SEARCH_SECTION_TRANSLATION, SearchSectionTranslation>(ActionType.END_PATCH_SEARCH_SECTION_TRANSLATION);

export const startDeleteSearchSection = createAction<ActionType.START_DELETE_SEARCH_SECTION>(ActionType.START_DELETE_SEARCH_SECTION);
export const endDeleteSearchSection = createAction<ActionType.END_DELETE_SEARCH_SECTION>(ActionType.END_DELETE_SEARCH_SECTION);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

// Related.
export const startFetchRelations = createAction<ActionType.START_FETCH_RELATIONS>(ActionType.START_FETCH_RELATIONS);
export const endFetchRelations = createAction<ActionType.END_FETCH_RELATIONS, ListResponse<SearchSectionRelation>>(ActionType.END_FETCH_RELATIONS);
export const clearRelations = createAction<ActionType.CLEAR_RELATIONS>(ActionType.CLEAR_RELATIONS);

export const startFetchAllRelations = createAction<ActionType.START_FETCH_ALL_RELATIONS>(ActionType.START_FETCH_ALL_RELATIONS);
export const endFetchAllRelations = createAction<ActionType.END_FETCH_ALL_RELATIONS, AllRelations>(ActionType.END_FETCH_ALL_RELATIONS);
export const clearAllRelations = createAction<ActionType.CLEAR_ALL_RELATIONS>(ActionType.CLEAR_ALL_RELATIONS);

export const startImportRelations = createAction<ActionType.START_IMPORT_RELATIONS>(ActionType.START_IMPORT_RELATIONS);
export const startImportExcludedHospitals = createAction<ActionType.START_IMPORT_EXCLUDED_HOSPITALS>(ActionType.START_IMPORT_EXCLUDED_HOSPITALS);

export const startFetchHospitalsForSelect = createAction<ActionType.START_FETCH_HOSPITALS_FOR_SELECT>(ActionType.START_FETCH_HOSPITALS_FOR_SELECT);
export const endFetchHospitalsForSelect = createAction<ActionType.END_FETCH_HOSPITALS_FOR_SELECT, Array<Hospital>>(ActionType.END_FETCH_HOSPITALS_FOR_SELECT);
export const clearHospitalsSelect = createAction<ActionType.CLEAR_HOSPITALS_SELECT>(ActionType.CLEAR_HOSPITALS_SELECT);

export const startFetchDepartmentsForSelect = createAction<ActionType.START_FETCH_DEPARTMENTS_FOR_SELECT>(ActionType.START_FETCH_DEPARTMENTS_FOR_SELECT);
export const endFetchDepartmentsForSelect = createAction<ActionType.END_FETCH_DEPARTMENTS_FOR_SELECT, EndFetchDepartmentsForSelect>(ActionType.END_FETCH_DEPARTMENTS_FOR_SELECT);
export const clearDepartmentsSelect = createAction<ActionType.CLEAR_DEPARTMENTS_SELECT>(ActionType.CLEAR_DEPARTMENTS_SELECT);

// Excluded.
export const startFetchExcludedHospitals = createAction<ActionType.START_FETCH_EXCLUDED_HOSPITALS>(ActionType.START_FETCH_EXCLUDED_HOSPITALS);
export const endFetchExcludedHospitals = createAction<ActionType.END_FETCH_EXCLUDED_HOSPITALS, ListResponse<SearchSectionExcludedHospital>>(ActionType.END_FETCH_EXCLUDED_HOSPITALS);

export const startFetchExcludedHospitalsForSelect = createAction<ActionType.START_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT>(ActionType.START_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT);
export const endFetchExcludedHospitalsForSelect = createAction<ActionType.END_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT, Array<Hospital>>(ActionType.END_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT);
export const clearExcludedHospitalsSelect = createAction<ActionType.CLEAR_EXCLUDED_HOSPITALS_SELECT>(ActionType.CLEAR_EXCLUDED_HOSPITALS_SELECT);

export const startPostExcludedHospitals = createAction<ActionType.START_POST_EXCLUDED_HOSPITALS>(ActionType.START_POST_EXCLUDED_HOSPITALS);
export const endPostExcludedHospitals = createAction<ActionType.END_POST_EXCLUDED_HOSPITALS>(ActionType.END_POST_EXCLUDED_HOSPITALS);

export const startDeleteExcludedHospital = createAction<ActionType.START_DELETE_EXCLUDED_HOSPITAL>(ActionType.START_DELETE_EXCLUDED_HOSPITAL);
export const endDeleteExcludedHospital = createAction<ActionType.END_DELETE_EXCLUDED_HOSPITAL>(ActionType.END_DELETE_EXCLUDED_HOSPITAL);


export type Actions =
  | ReturnType<typeof startFetchSearchSections>
  | ReturnType<typeof endFetchSearchSections>
  | ReturnType<typeof startFetchSearchSectionsForSelect>
  | ReturnType<typeof endFetchSearchSectionsForSelect>
  | ReturnType<typeof clearSearchSectionsSelect>
  | ReturnType<typeof startFetchSearchSectionsForTreeSelect>
  | ReturnType<typeof endFetchSearchSectionsForTreeSelect>
  | ReturnType<typeof clearSearchSectionsTreeSelect>
  | ReturnType<typeof startFetchSearchSection>
  | ReturnType<typeof endFetchSearchSection>
  | ReturnType<typeof startFetchParentSearchSection>
  | ReturnType<typeof endFetchParentSearchSection>
  | ReturnType<typeof clearParentSearchSectionData>
  | ReturnType<typeof startPostSearchSection>
  | ReturnType<typeof endPostSearchSection>
  | ReturnType<typeof endPostSearchSectionError>
  | ReturnType<typeof startUpdateSearchSection>
  | ReturnType<typeof endUpdateSearchSection>
  | ReturnType<typeof endUpdateSearchSectionError>
  | ReturnType<typeof startUpdateSearchSectionTranslation>
  | ReturnType<typeof endUpdateSearchSectionTranslation>
  | ReturnType<typeof startDeleteSearchSection>
  | ReturnType<typeof endDeleteSearchSection>
  | ReturnType<typeof clearFormData>
  | ReturnType<typeof startFetchRelations>
  | ReturnType<typeof endFetchRelations>
  | ReturnType<typeof clearRelations>
  | ReturnType<typeof startFetchAllRelations>
  | ReturnType<typeof endFetchAllRelations>
  | ReturnType<typeof clearAllRelations>
  | ReturnType<typeof startImportRelations>
  | ReturnType<typeof startImportExcludedHospitals>
  | ReturnType<typeof startFetchHospitalsForSelect>
  | ReturnType<typeof endFetchHospitalsForSelect>
  | ReturnType<typeof clearHospitalsSelect>
  | ReturnType<typeof startFetchDepartmentsForSelect>
  | ReturnType<typeof endFetchDepartmentsForSelect>
  | ReturnType<typeof clearDepartmentsSelect>
  | ReturnType<typeof startFetchExcludedHospitals>
  | ReturnType<typeof endFetchExcludedHospitals>
  | ReturnType<typeof startFetchExcludedHospitalsForSelect>
  | ReturnType<typeof endFetchExcludedHospitalsForSelect>
  | ReturnType<typeof clearExcludedHospitalsSelect>
  | ReturnType<typeof startPostExcludedHospitals>
  | ReturnType<typeof endPostExcludedHospitals>
  | ReturnType<typeof startDeleteExcludedHospital>
  | ReturnType<typeof endDeleteExcludedHospital>;

