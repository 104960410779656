import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import SettingsGrid from './components/SettingsGrid';

export default function SettingsIndex(): JSX.Element {
  const history = useHistory();

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.push("/settings")}
        title="Settings"
        // extra={[
        //   <Button
        //     key="createMethod"
        //     type="primary"
        //     href="/settings/create"
        //     onClick={(e) => {
        //       e.preventDefault();
        //
        //       history.push('settings/create');
        //     }}
        //   >
        //     Add scripts
        //   </Button>
        // ]}
      />
      <SettingsGrid />
    </>
  );
}
