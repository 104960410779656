import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M10.4401 13.9821C10.17 14.154 10.09 14.5139 10.2622 14.7842C11.2732 16.3724 12.8424 17.472 14.6804 17.8802C15.1932 17.9942 15.7095 18.0505 16.2225 18.0505C17.5478 18.0505 18.8486 17.6745 19.9937 16.9456C21.582 15.9346 22.6815 14.3654 23.0898 12.5274C23.498 10.6893 23.1661 8.8024 22.1548 7.21413C21.5198 6.21659 20.6363 5.38882 19.5999 4.81985C18.5634 4.25113 17.3908 3.9505 16.2085 3.9505H16.2081C15.8879 3.9505 15.6271 4.21101 15.6271 4.53146C15.6271 4.8519 15.8876 5.11241 16.2081 5.11241H16.209C17.7812 5.11241 19.2594 5.72468 20.3711 6.83595C21.4831 7.94772 22.0959 9.42592 22.0961 10.9985C22.0964 12.5711 21.4844 14.0496 20.3726 15.1616C19.2608 16.2736 17.7826 16.8864 16.21 16.8866H16.209C15.2215 16.8866 14.2421 16.6354 13.3764 16.1604C12.5105 15.6851 11.7725 14.9933 11.2422 14.1597C11.07 13.8896 10.7101 13.8101 10.4401 13.9823V13.9821Z"
    fill="currentColor" />
  <path
    d="M14.19 10.999C14.19 12.112 15.0953 13.0173 16.2081 13.0173C17.3208 13.0173 18.2261 12.112 18.2261 10.9993C18.2261 9.88652 17.3208 8.98121 16.2078 8.98121C15.0963 8.98243 14.1912 9.88774 14.1898 10.9993L14.19 10.999ZM15.3519 10.9993C15.3519 10.5272 15.7362 10.1429 16.2081 10.1429C16.6797 10.1436 17.064 10.5277 17.0647 10.9995C17.0647 11.4716 16.6804 11.8559 16.2083 11.8559C15.7362 11.8559 15.3519 11.4716 15.3519 10.9995V10.9993Z"
    fill="currentColor" />
  <path
    d="M26.9368 8.56439C26.6114 7.12827 26.0075 5.78877 25.1413 4.58282C24.1276 3.17141 22.7744 2.00607 21.2282 1.21279C19.682 0.419511 17.9463 0 16.2085 0C16.2 0 16.1917 0 16.1831 0C14.6208 0 13.0459 0.34466 11.6267 0.997287C11.3337 1.12375 11.1969 1.46474 11.3224 1.75852C11.4481 2.05304 11.7899 2.19051 12.0846 2.06502C12.0937 2.06111 12.1027 2.05695 12.1115 2.05279C13.3187 1.5007 14.5978 1.20154 15.9133 1.16362C17.1845 1.12693 18.4365 1.33412 19.6339 1.7798C20.8312 2.22549 21.9141 2.88717 22.852 3.74625C23.8229 4.63566 24.5953 5.69899 25.1479 6.90665C25.7005 8.1143 25.9999 9.39412 26.0381 10.7101C26.0748 11.9814 25.8676 13.2333 25.4219 14.4307C24.9762 15.6281 24.3145 16.711 23.4554 17.6488C22.566 18.6197 21.5027 19.3922 20.295 19.9448C19.0035 20.5355 17.6285 20.8356 16.2088 20.8366C14.6548 20.8342 13.1024 20.458 11.7197 19.7486C10.3376 19.0397 9.12675 17.9991 8.21826 16.7394C8.0392 16.4754 7.67865 16.4055 7.41373 16.5835C7.14783 16.7621 7.07714 17.1239 7.25571 17.3898C7.26207 17.3991 7.26867 17.4084 7.27552 17.4174C8.14145 18.6226 9.21751 19.6224 10.4738 20.3887C11.6879 21.1294 13.0183 21.6221 14.4278 21.8532C15.0246 21.9511 15.6229 22 16.2198 22C17.0326 22 17.8428 21.9092 18.6424 21.7282C20.0786 21.4031 21.4181 20.799 22.624 19.9328C23.83 19.0666 24.8304 17.9901 25.597 16.733C26.3377 15.519 26.8304 14.1885 27.0615 12.7791C27.2927 11.3694 27.2506 9.95134 26.9365 8.56439H26.9368Z"
    fill="currentColor" />
  <path
    d="M15.5848 6.10456C15.2435 6.14027 14.905 6.21243 14.5789 6.31835C14.4314 6.3663 14.3113 6.46879 14.2409 6.60724C14.1704 6.74545 14.1582 6.90298 14.2061 7.05048C14.2842 7.29044 14.506 7.45164 14.7585 7.45164C14.8201 7.45164 14.8808 7.44186 14.9383 7.42278C15.1873 7.34206 15.4456 7.28726 15.7051 7.25987C16.0239 7.22856 16.2577 6.94383 16.2266 6.6251C16.2115 6.47075 16.1371 6.33132 16.0173 6.23298C15.8974 6.1344 15.7462 6.08891 15.5924 6.10358L15.5848 6.10432V6.10456Z"
    fill="currentColor" />
  <path
    d="M18.6263 13.7861C18.39 13.9999 18.3707 14.3651 18.5835 14.6044L18.5862 14.6073C18.7007 14.7335 18.8584 14.7979 19.0169 14.7979C19.1559 14.7979 19.2956 14.7485 19.4064 14.6479C19.6605 14.4177 19.8914 14.1602 20.0925 13.8825C20.1835 13.7568 20.2202 13.6032 20.1955 13.45C20.171 13.2969 20.0884 13.1624 19.9626 13.0714C19.7031 12.8835 19.3393 12.9417 19.1515 13.201C18.9976 13.4134 18.8208 13.6103 18.6261 13.7861H18.6263Z"
    fill="currentColor" />
  <path
    d="M20.4433 10.4139C20.1233 10.4168 19.8633 10.6793 19.8638 10.9993C19.8638 11.2588 19.8371 11.5193 19.784 11.7735C19.7523 11.9254 19.7814 12.0805 19.8662 12.2103C19.9492 12.3371 20.0754 12.4251 20.2224 12.4589L20.2356 12.4618C20.274 12.4696 20.3136 12.4735 20.3533 12.4735C20.6275 12.4731 20.8665 12.2776 20.9213 12.0093C20.9902 11.6778 21.0252 11.3381 21.0255 10.9993V10.99C21.0252 10.8359 20.9648 10.6911 20.8557 10.5822C20.7468 10.4738 20.6023 10.4139 20.4489 10.4139H20.4433Z"
    fill="currentColor" />
  <path
    d="M20.5971 8.98928C20.457 8.67667 20.2836 8.37775 20.0813 8.10109C19.8985 7.83911 19.537 7.77404 19.274 7.95604C19.0108 8.13852 18.9448 8.50103 19.1273 8.76448C19.1324 8.77207 19.1378 8.7794 19.1434 8.78674C19.2975 8.99809 19.4299 9.22607 19.5363 9.46359C19.5986 9.60571 19.7126 9.71505 19.8572 9.77131C19.9257 9.79797 19.9971 9.81143 20.0685 9.81143C20.1478 9.81143 20.227 9.79504 20.3017 9.76226C20.4438 9.69988 20.5531 9.58589 20.6094 9.44133C20.6659 9.29676 20.6625 9.13898 20.6001 8.99686L20.5969 8.98977L20.5971 8.98928Z"
    fill="currentColor" />
  <path
    d="M12.3515 12.2368C12.2064 12.1817 12.0487 12.1861 11.9092 12.2487L11.9048 12.2507C11.7634 12.3146 11.6553 12.4295 11.6005 12.5748C11.5462 12.7184 11.5506 12.8742 11.6123 13.0144L11.6142 13.019C11.7551 13.3319 11.9293 13.6308 12.1318 13.9077C12.2236 14.033 12.3588 14.1151 12.5124 14.1389C12.5425 14.1435 12.5726 14.1457 12.6025 14.1457C12.725 14.1457 12.8441 14.1071 12.9449 14.0332C13.0704 13.9415 13.1523 13.8062 13.1761 13.6526C13.1998 13.499 13.1624 13.3453 13.0704 13.2199C12.9153 13.0085 12.7823 12.7803 12.6744 12.5416C12.6108 12.3999 12.4961 12.2916 12.351 12.2365L12.3515 12.2368Z"
    fill="currentColor" />
  <path
    d="M12.8246 7.32811C12.8182 7.3335 12.8118 7.33937 12.8057 7.34499L12.7957 7.35429C12.5462 7.5808 12.3194 7.83349 12.1216 8.10598C12.0281 8.22902 11.988 8.38093 12.0083 8.53406C12.0288 8.68792 12.1079 8.82441 12.2311 8.91859C12.3336 8.99686 12.4562 9.0382 12.5829 9.0382C12.6088 9.0382 12.6347 9.03649 12.6609 9.03307C12.8148 9.01252 12.9513 8.93351 13.0455 8.81023C13.0508 8.80338 13.056 8.79628 13.0609 8.78894C13.215 8.5776 13.3911 8.38142 13.5851 8.20603C13.8263 7.9964 13.8527 7.62997 13.6438 7.38804C13.4344 7.14563 13.067 7.11873 12.8246 7.32787V7.32811Z"
    fill="currentColor" />
  <path
    d="M18.561 6.73126L18.4487 6.66644L18.4463 6.66962C18.1823 6.52676 17.9049 6.40764 17.62 6.31493L17.6121 6.31273C17.3025 6.23054 16.984 6.41595 16.902 6.72563C16.8259 7.01281 16.9779 7.30977 17.255 7.41642L17.2626 7.41911C17.5118 7.49959 17.7533 7.60697 17.9803 7.73784C18.0715 7.79043 18.1713 7.81538 18.2697 7.81538C18.4707 7.81538 18.6664 7.71118 18.7738 7.52478C18.8513 7.39025 18.8719 7.23369 18.8318 7.08375C18.7917 6.9338 18.6953 6.80856 18.561 6.73101V6.73126Z"
    fill="currentColor" />
  <path
    d="M13.442 14.4769V14.4774C13.2822 14.7553 13.3786 15.111 13.6565 15.2702C13.9537 15.4412 14.27 15.5811 14.5965 15.6865C14.6528 15.7046 14.7113 15.7139 14.771 15.7144H14.7729C14.9273 15.7144 15.0728 15.6545 15.1824 15.5456C15.2925 15.4363 15.3534 15.2905 15.3539 15.1354C15.3546 14.8805 15.1919 14.6575 14.9493 14.5802C14.7 14.5002 14.4586 14.3935 14.2304 14.2626C13.9547 14.1068 13.601 14.203 13.4422 14.4769H13.442Z"
    fill="currentColor" />
  <path
    d="M16.1185 14.952C16.0214 15.0731 15.9774 15.2245 15.9943 15.3788C16.0263 15.6711 16.2724 15.8937 16.5676 15.8964C16.5882 15.8964 16.6092 15.8952 16.6293 15.893C16.9703 15.8565 17.3083 15.7841 17.6341 15.6777C17.9397 15.584 18.1124 15.2594 18.0194 14.9534C17.9262 14.6469 17.6011 14.4735 17.2946 14.5667C17.2866 14.5691 17.2785 14.5718 17.2707 14.5745C17.0236 14.6555 16.767 14.7105 16.5082 14.7382C16.3538 14.7551 16.2156 14.8311 16.1185 14.952Z"
    fill="currentColor" />
  <path
    d="M11.2896 9.97703C11.228 10.2706 11.1928 10.5705 11.1849 10.8706H11.1827L11.1832 11.0179C11.1832 11.0179 11.1832 11.0198 11.1832 11.0205V11.1566H11.2008C11.2639 11.4048 11.4895 11.5888 11.7568 11.5888H11.7656C12.0856 11.5832 12.3449 11.3182 12.3439 10.999C12.3444 10.7366 12.3725 10.4733 12.4276 10.2165C12.4936 9.90291 12.2923 9.59396 11.9787 9.52767C11.6651 9.46138 11.3562 9.66294 11.2899 9.97654L11.2896 9.97703Z"
    fill="currentColor" />
  <path
    d="M10.5025 10.9993C10.5025 9.59666 9.95624 8.27795 8.96433 7.28604C7.97243 6.29414 6.65372 5.74792 5.25111 5.74792C3.8485 5.74792 2.52979 6.29414 1.53788 7.28604C0.546221 8.2777 0 9.59641 0 10.9993C0.00146768 12.4011 0.548422 13.7194 1.53984 14.7108C2.53125 15.7022 3.84923 16.2489 5.25135 16.2506C6.65396 16.2506 7.97267 15.7044 8.96458 14.7125C9.95648 13.7206 10.5027 12.4019 10.5027 10.9993H10.5025ZM5.25135 6.90958C7.50619 6.90958 9.34079 8.74418 9.34079 10.999C9.34079 13.2539 7.50619 15.0887 5.25135 15.0887C2.99651 15.0887 1.16167 13.2541 1.16167 10.9993C1.16289 9.90731 1.58852 8.88067 2.36076 8.10868C3.13276 7.33643 4.1594 6.91081 5.25135 6.90958Z"
    fill="currentColor" />
  <path
    d="M8.80166 5.01408C8.95675 5.01457 9.10327 4.95684 9.21433 4.84823C9.22166 4.84089 9.229 4.83355 9.23585 4.82597L9.24197 4.81936C9.60253 4.45245 10.1434 4.28195 10.7669 4.33894C11.3537 4.39252 11.9349 4.64789 12.3216 5.0219L12.4244 5.12195L11.4278 5.28144C11.1113 5.33085 10.8941 5.62854 10.9437 5.94507C10.9883 6.2298 11.2294 6.43626 11.5171 6.43626C11.5178 6.43626 11.5186 6.43626 11.5193 6.43626C11.5501 6.43626 11.5814 6.43381 11.611 6.42867L13.7541 6.08573C14.0075 6.04512 14.2066 5.83891 14.2384 5.58451L14.5092 3.43143V3.42898C14.544 3.1105 14.3128 2.82357 13.9946 2.78908C13.6819 2.75483 13.4021 2.97254 13.3571 3.28368L13.2311 4.28611L13.1303 4.18802L13.1284 4.18631C12.4946 3.59043 11.6678 3.23085 10.7999 3.17336C9.91074 3.08237 9.03649 3.39058 8.40148 4.01875C8.29067 4.12735 8.22878 4.27265 8.22707 4.42774C8.22536 4.58282 8.28431 4.72935 8.39291 4.84016C8.50152 4.95097 8.64682 5.01285 8.80191 5.01457L8.80166 5.01408Z"
    fill="currentColor" />
</svg>`;

export default { id: '235-corneal-transplant', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
