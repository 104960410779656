import * as React from 'react';
import { useParams } from 'react-router-dom';
import { TemplateTypes } from '@modelTypes/template';

import EmailFormCreate from '@containers/Templates/CreateEmailForm';
import LegalFormCreate from '@containers/Templates/CreateLegalForm';


interface RouterParams {
  type: TemplateTypes;
}

export default function TemplateCreate() {
  const { type } = useParams<RouterParams>() ;

  return (
   <>
     {type === 'emails' && <EmailFormCreate/>}
     {type === 'legals' && <LegalFormCreate/>}
   </>
  );
}
