import {
  AntGetListParams, CreateProgramDiscountRequest, CreateProgramDiscountResponse,
  CreateTreatmentFolderRequest,
  CreateTreatmentFolderResponse,
  CreateTreatmentMethodRequest,
  CreateTreatmentMethodResponse,
  CreateTreatmentProgramRelationRequest,
  CreateTreatmentProgramRelationResponse,
  CreateTreatmentProgramRequest,
  CreateTreatmentProgramResponse,
  CreateTreatmentServiceCostRequest,
  CreateTreatmentServiceCostResponse,
  CreateTreatmentTypeRequest,
  CreateTreatmentTypeResponse,
  GetListParams, ImportTreatmentProgramBlogsRelationsRequest,
  ImportTreatmentProgramRelationsRequest,
  ImportTreatmentProgramRelationsResponse,
  ImportTreatmentProgramSSRelationsRequest,
  ImportTreatmentProgramSSRelationsResponse,
  ListResponse,
  Params, UpdateProgramDiscountRequest, UpdateProgramDiscountResponse,
  UpdateProgramPriceRequest, UpdateProgramPriceResponse,
  UpdateTreatmentFolderRequest,
  UpdateTreatmentFolderResponse,
  UpdateTreatmentMethodRequest,
  UpdateTreatmentMethodResponse,
  UpdateTreatmentProgramRequest,
  UpdateTreatmentProgramResponse,
  UpdateTreatmentProgramSSRelationsRequest,
  UpdateTreatmentProgramSSRelationsResponse,
  UpdateTreatmentServiceCostRequest,
  UpdateTreatmentServiceCostResponse,
  UpdateTreatmentTypeRequest,
  UpdateTreatmentTypeResponse,
} from '@api/types';

import { AxiosRequestConfig } from 'axios';
import client from './client';
import { getRestUri } from '@api/helpers';
import {
  ProgramDiscount, ProgramPrice,
  TreatmentFolder,
  TreatmentMethod,
  TreatmentProgram,
  TreatmentServiceCost,
  TreatmentType
} from '@modelTypes/treatment';

// const getUri = getRestUri('doctors');
// const ROOT_URI = getUri('');
// const GET_DOCTORS_FOR_SELECT_URI = 'doctors/select';
// const UPDATE_DOCTORS_FILTERS_COUNT_URI = 'doctors/update-filters-count';
//
// const GET_DOCTORS_CONTENTS_URI = 'doctors/contents';
// const GET_DOCTORS_CONTENTS_BY_URI = 'doctors/contents/relations';

const getMethodsUri = getRestUri('treatment/methods');
const getTypesUri = getRestUri('treatment/types-of-care');
const getServicesCostUri = getRestUri('treatment/services-cost');
const getFolderUri = getRestUri('treatment/folders');
const getProgramUri = getRestUri('treatment/programs');
const getProgramDiscountUri = getRestUri('treatment/programs/discounts');
const getProgramPriceUri = getRestUri('treatment/programs/:programId/prices');
const getProgramRelationUri = getRestUri('treatment/programs/:programId/relations');
const getProgramExcludedHospitalUri = getRestUri('treatment/programs/:programId/excluded-hospitals');


const METHODS_ROOT_URI = getMethodsUri('');
const GET_METHODS_FOR_SELECT_URI = 'treatment/methods/select';

const TYPES_ROOT_URI = getTypesUri('');
const GET_TYPES_FOR_SELECT_URI = 'treatment/types-of-care/select';

const SERVICES_COST_ROOT_URI = getServicesCostUri('');
const GET_SERVICES_COST_FOR_SELECT_URI = 'treatment/services-cost/select';

const FOLDER_ROOT_URI = getFolderUri('');
const GET_FOLDER_FOR_SELECT_URI = 'treatment/folders/select';

const DISCOUNT_ROOT_URI = getProgramDiscountUri('');
const GET_DISCOUNTS_FOR_SELECT_URI = 'treatment/programs/discounts/select';

const PRICE_ROOT_URI = getProgramPriceUri('');
// const GET_PRICE_FOR_SELECT_URI = 'treatment/programs/discounts/select';

const PROGRAMS_ROOT_URI = getProgramUri('');
const PROGRAM_RELATION_ROOT_URI = getProgramRelationUri('');
const PROGRAM_EXCLUDED_HOSPITALS_URI = getProgramExcludedHospitalUri('');
const POST_IMPORT_PROGRAM_EXCLUDED_HOSPITAL_URI = (id: number) => `treatment/programs/${id}/excluded-hospitals/import`;
const PROGRAM_DELETE_EXCLUDED_HOSPITAL_URI = (id: number) => `treatment/programs/excluded-hospitals/${id}`;
const PROGRAM_DELETE_ALL_EXCLUDED_HOSPITAL_URI = (programId: number) => `treatment/programs/${programId}/excluded-hospitals/all`;

const POST_IMPORT_PROGRAM_RELATIONS_URI = (id: number) => `treatment/programs/${id}/relations/import`;
const GET_TPR_HOSPITALS_FOR_SELECT_URI = 'treatment/programs/relations/hospitals/select';
const GET_TPR_DEPARTMENTS_FOR_SELECT_URI = 'treatment/programs/relations/departments/select';
const POST_SYNC_PROGRAM_RELATIONS_URI = (id: number) => `treatment/programs/${id}/relations/sync`;
const POST_SYNC_ALL_PROGRAMS_RELATIONS_URI = 'treatment/programs/relations/sync';
const POST_SYNC_ALL_PROGRAMS_PRICES_URI = 'treatment/programs/relations/sync-prices';
const PROGRAM_DELETE_RELATION_URI = (id: number) => `treatment/programs/relations/${id}`;
const PROGRAM_DELETE_ALL_RELATION_URI = (programId: number) => `treatment/programs/${programId}/relations/all`;

const PUT_PROGRAM_SS_RELATIONS_URI = (id: number) => `treatment/programs/${id}/search-sections`;
const POST_IMPORT_PROGRAM_SS_RELATIONS_URI = (id: number) => `treatment/programs/${id}/search-sections/import`;

const POST_IMPORT_PROGRAM_BLOG_RELATIONS_URI = (id: number) => `treatment/programs/${id}/blogs/import`;

const GET_PROGRAMS_FOR_SELECT_URI = 'treatment/programs/select';

// Methods.
export async function getTreatmentMethods(params?: AntGetListParams) {
  return client.get<ListResponse<TreatmentMethod>>(METHODS_ROOT_URI.GET(), params);
}

export async function getTreatmentMethodsForSelect(params?: GetListParams) {
  return client.get<ListResponse<TreatmentMethod>>(GET_METHODS_FOR_SELECT_URI, params);
}

export async function getTreatmentMethod(id: number, params?: Params) {
  return client.get<TreatmentMethod>(METHODS_ROOT_URI.SHOW({ id }), params);
}

export async function createTreatmentMethod(payload: CreateTreatmentMethodRequest) {
  return client.post<CreateTreatmentMethodRequest, CreateTreatmentMethodResponse>(METHODS_ROOT_URI.POST(), payload);
}

export async function updateTreatmentMethod(id: number, payload: UpdateTreatmentMethodRequest) {
  return client.patch<UpdateTreatmentMethodRequest, UpdateTreatmentMethodResponse>(METHODS_ROOT_URI.PATCH({ id }), payload);
}

export async function deleteTreatmentMethod(id: number, payload?: AxiosRequestConfig) {
  return client.del(METHODS_ROOT_URI.DELETE({ id }), payload);
}

// Types of care.
export async function getTreatmentTypes(params?: AntGetListParams) {
  return client.get<ListResponse<TreatmentType>>(TYPES_ROOT_URI.GET(), params);
}

export async function getTreatmentTypesForSelect(params?: GetListParams) {
  return client.get<ListResponse<TreatmentType>>(GET_TYPES_FOR_SELECT_URI, params);
}

export async function getTreatmentType(id: number, params?: Params) {
  return client.get<TreatmentType>(TYPES_ROOT_URI.SHOW({ id }), params);
}

export async function createTreatmentType(payload: CreateTreatmentTypeRequest) {
  return client.post<CreateTreatmentTypeRequest, CreateTreatmentTypeResponse>(TYPES_ROOT_URI.POST(), payload);
}

export async function updateTreatmentType(id: number, payload: UpdateTreatmentTypeRequest) {
  return client.patch<UpdateTreatmentTypeRequest, UpdateTreatmentTypeResponse>(TYPES_ROOT_URI.PATCH({ id }), payload);
}

export async function deleteTreatmentType(id: number, payload?: AxiosRequestConfig) {
  return client.del(TYPES_ROOT_URI.DELETE({ id }), payload);
}

// Services cost.
export async function getTreatmentServicesCost(params?: AntGetListParams) {
  return client.get<ListResponse<TreatmentServiceCost>>(SERVICES_COST_ROOT_URI.GET(), params);
}

export async function getTreatmentServicesCostForSelect(params?: GetListParams) {
  return client.get<ListResponse<TreatmentServiceCost>>(GET_SERVICES_COST_FOR_SELECT_URI, params);
}

export async function getTreatmentServiceCost(id: number, params?: Params) {
  return client.get<TreatmentServiceCost>(SERVICES_COST_ROOT_URI.SHOW({ id }), params);
}

export async function createTreatmentServiceCost(payload: CreateTreatmentServiceCostRequest) {
  return client.post<CreateTreatmentServiceCostRequest, CreateTreatmentServiceCostResponse>(SERVICES_COST_ROOT_URI.POST(), payload);
}

export async function updateTreatmentServiceCost(id: number, payload: UpdateTreatmentServiceCostRequest) {
  return client.patch<UpdateTreatmentServiceCostRequest, UpdateTreatmentServiceCostResponse>(SERVICES_COST_ROOT_URI.PATCH({ id }), payload);
}

export async function deleteTreatmentServiceCost(id: number, payload?: AxiosRequestConfig) {
  return client.del(SERVICES_COST_ROOT_URI.DELETE({ id }), payload);
}

// Folders.
export async function getTreatmentFolders(params?: AntGetListParams) {
  return client.get<ListResponse<TreatmentFolder>>(FOLDER_ROOT_URI.GET(), params);
}

export async function getTreatmentFoldersForSelect(params?: GetListParams) {
  return client.get<ListResponse<TreatmentFolder>>(GET_FOLDER_FOR_SELECT_URI, params);
}

export async function getTreatmentFolder(id: number, params?: Params) {
  return client.get<TreatmentFolder>(FOLDER_ROOT_URI.SHOW({ id }), params);
}

export async function createTreatmentFolder(payload: CreateTreatmentFolderRequest) {
  return client.post<CreateTreatmentFolderRequest, CreateTreatmentFolderResponse>(FOLDER_ROOT_URI.POST(), payload);
}

export async function updateTreatmentFolder(id: number, payload: UpdateTreatmentFolderRequest) {
  return client.patch<UpdateTreatmentFolderRequest, UpdateTreatmentFolderResponse>(FOLDER_ROOT_URI.PATCH({ id }), payload);
}

export async function deleteTreatmentFolder(id: number, payload?: AxiosRequestConfig) {
  return client.del(FOLDER_ROOT_URI.DELETE({ id }), payload);
}

// Program.
export async function getTreatmentPrograms(params: AntGetListParams & Params) {
  return client.get<ListResponse<TreatmentProgram>>(PROGRAMS_ROOT_URI.GET(), params);
}

export async function getTreatmentProgramsForSelect(params?: GetListParams) {
  return client.get<ListResponse<TreatmentProgram>>(GET_PROGRAMS_FOR_SELECT_URI, params);
}

export async function getTreatmentProgram(id: number, params?: Params) {
  return client.get<TreatmentProgram>(PROGRAMS_ROOT_URI.SHOW({ id }), params);
}

export async function createTreatmentProgram(payload: CreateTreatmentProgramRequest) {
  return client.post<CreateTreatmentProgramRequest, CreateTreatmentProgramResponse>(PROGRAMS_ROOT_URI.POST(), payload);
}

export async function updateTreatmentProgram(id: number, payload: UpdateTreatmentProgramRequest) {
  return client.patch<UpdateTreatmentProgramRequest, UpdateTreatmentProgramResponse>(PROGRAMS_ROOT_URI.PATCH({ id }), payload);
}

export async function updateTreatmentProgramSSRelations(id: number, payload: UpdateTreatmentProgramSSRelationsRequest) {
  return client.put<UpdateTreatmentProgramSSRelationsRequest, UpdateTreatmentProgramSSRelationsResponse>(PUT_PROGRAM_SS_RELATIONS_URI(id), payload);
}

export async function importTreatmentProgramSSRelations(id: number, payload: ImportTreatmentProgramSSRelationsRequest) {
  return client.post<ImportTreatmentProgramSSRelationsRequest, ImportTreatmentProgramSSRelationsResponse>(POST_IMPORT_PROGRAM_SS_RELATIONS_URI(id), payload);
}

export async function deleteTreatmentProgram(id: number, payload?: AxiosRequestConfig) {
  return client.del(PROGRAMS_ROOT_URI.DELETE({ id }), payload);
}

export async function importTreatmentBlogRelation(id: number, payload: ImportTreatmentProgramBlogsRelationsRequest) {
  return client.post<ImportTreatmentProgramBlogsRelationsRequest, ImportTreatmentProgramSSRelationsResponse>(POST_IMPORT_PROGRAM_BLOG_RELATIONS_URI(id), payload);
}

// Program Relation.
export async function getTreatmentProgramRelation(programId: number, params?: AntGetListParams, filter?: any) {
  return client.get<ListResponse<any>>(PROGRAM_RELATION_ROOT_URI.GET({ programId }), { filter, ...params });
}

export async function getTPRHospitalsForSelect(params?: GetListParams) {
  return client.get<ListResponse<any>>(GET_TPR_HOSPITALS_FOR_SELECT_URI, params);
}

export async function getTPRDepartmentsForSelect(params?: GetListParams) {
  return client.get<ListResponse<any>>(GET_TPR_DEPARTMENTS_FOR_SELECT_URI, params);
}

export async function createTreatmentProgramRelation(programId: number, payload: CreateTreatmentProgramRelationRequest) {
  return client.post<CreateTreatmentProgramRelationRequest, CreateTreatmentProgramRelationResponse>(PROGRAM_RELATION_ROOT_URI.POST({ programId }), payload);
}

export async function importTreatmentProgramRelation(id: number, payload: ImportTreatmentProgramRelationsRequest) {
  return client.post<ImportTreatmentProgramRelationsRequest, ImportTreatmentProgramRelationsResponse>(POST_IMPORT_PROGRAM_RELATIONS_URI(id), payload);
}

export async function syncTreatmentProgramRelation(id: number) {
  return client.post<any, any>(POST_SYNC_PROGRAM_RELATIONS_URI(id));
}

export async function syncAllTreatmentProgramsRelation() {
  return client.post<any, any>(POST_SYNC_ALL_PROGRAMS_RELATIONS_URI);
}

export async function syncAllTreatmentProgramsPrices() {
  return client.post<any, any>(POST_SYNC_ALL_PROGRAMS_PRICES_URI);
}

export async function deleteTreatmentProgramRelation(id: number, config?: AxiosRequestConfig) {
  return client.del<any>(PROGRAM_DELETE_RELATION_URI(id), config);
}

export async function deleteTreatmentProgramAllRelation(programId: number, config?: AxiosRequestConfig) {
  return client.del<any>(PROGRAM_DELETE_ALL_RELATION_URI(programId), config);
}

// Program Excluded Hospitals.
export async function getTreatmentProgramExcludedHospitals(programId: number, params?: AntGetListParams) {
  return client.get<ListResponse<any>>(PROGRAM_EXCLUDED_HOSPITALS_URI.GET({ programId }), params);
}

export async function createTreatmentProgramExcludedHospitals(programId: number, payload: any) {
  return client.post<any, any>(PROGRAM_EXCLUDED_HOSPITALS_URI.POST({ programId }), payload);
}

export async function importTreatmentProgramExcludedHospital(id: number, payload: ImportTreatmentProgramSSRelationsRequest) {
  return client.post<ImportTreatmentProgramSSRelationsRequest, any>(POST_IMPORT_PROGRAM_EXCLUDED_HOSPITAL_URI(id), payload);
}

export async function deleteTreatmentProgramExcludedHospital(id: number, config?: AxiosRequestConfig) {
  return client.del<any>(PROGRAM_DELETE_EXCLUDED_HOSPITAL_URI(id), config);
}

export async function deleteTreatmentProgramAllExcludedHospital(programId: number, config?: AxiosRequestConfig) {
  return client.del<any>(PROGRAM_DELETE_ALL_EXCLUDED_HOSPITAL_URI(programId), config);
}

// Discounts.
export async function getProgramDiscounts(params?: AntGetListParams) {
  return client.get<ListResponse<ProgramDiscount>>(DISCOUNT_ROOT_URI.GET(), params);
}

export async function getProgramDiscountsForSelect(params?: AntGetListParams) {
  return client.get<ListResponse<TreatmentFolder>>(GET_DISCOUNTS_FOR_SELECT_URI, params);
}

export async function getProgramDiscount(id: number, params?: Params) {
  return client.get<ProgramDiscount>(DISCOUNT_ROOT_URI.SHOW({ id }), params);
}

export async function createProgramDiscount(payload: CreateProgramDiscountRequest) {
  return client.post<CreateProgramDiscountRequest, CreateProgramDiscountResponse>(DISCOUNT_ROOT_URI.POST(), payload);
}

export async function updateProgramDiscount(id: number, payload: UpdateProgramDiscountRequest) {
  return client.patch<UpdateProgramDiscountRequest, UpdateProgramDiscountResponse>(DISCOUNT_ROOT_URI.PATCH({ id }), payload);
}

export async function deleteProgramDiscount(id: number, payload?: AxiosRequestConfig) {
  return client.del(DISCOUNT_ROOT_URI.DELETE({ id }), payload);
}

// Prices.
export async function getProgramPrices(programId: number, params?: AntGetListParams) {
  return client.get<ListResponse<ProgramPrice>>(PRICE_ROOT_URI.GET({ programId }), params);
}

export async function updateProgramPrice(programId: number, id: number, payload: UpdateProgramPriceRequest) {
  return client.patch<UpdateProgramPriceRequest, UpdateProgramPriceResponse>(PRICE_ROOT_URI.PATCH({ programId, id }), payload);
}