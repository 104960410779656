import { BaseRow, FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { getDateFormattedCell } from '../cells/DateFormattedCell';

import { IconButtonRenderer } from '../renderers/renderers';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import { message } from 'antd';
import React from 'react';
import getIdCell from '@components/AntGrid/cells/IdCell';
import { VendorUser } from '@modelTypes/vendor';
import { dispatchRefreshVendorsUsersGridEvent } from '@containers/Vendors/components/UsersVendorsGrid';
import { useVendorUserStore } from '@zustandStorage/vendor/user';

export type VendorUserColumnsNames = `${keyof VendorUser}Column` | 'eyeIconColumn' | 'deleteColumn' | 'companyColumn';

export const vendorUserColumnsDefinitions: Partial<Record<VendorUserColumnsNames, ColumnType<VendorUser>>> = {
  idColumn: createColumnDefinition<VendorUser>('#', 'id', { width: 80, render: getIdCell }),
  usernameColumn: createColumnDefinition<VendorUser>('User Name', 'username'),
  firstNameColumn: createColumnDefinition<VendorUser>('First Name', 'firstName'),
  lastNameColumn: createColumnDefinition<VendorUser>('Last Name', 'lastName'),
  emailColumn: createColumnDefinition<VendorUser>('Email', 'email'),
  companyColumn: {
    title: 'Company',
    render: getVendorCompanyCell<VendorUser>(),
    sorter: false,
    filtered: false,
  },
  updatedAtColumn: createColumnDefinition<VendorUser>('Updated At', 'updatedAt', { render: getDateFormattedCell, width: 160 }),
  createdAtColumn: createColumnDefinition<VendorUser>('Created At', 'createdAt', { render: getDateFormattedCell, width: 160 }),
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, row: VendorUser) => {
      return IconButtonRenderer<VendorUser>(`/vendors/users/${row.id}/edit`);
    },
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell(),
    sorter: false,
    filtered: false,
  }
};

export const vendorsUserColumns: any = [
  vendorUserColumnsDefinitions.idColumn,
  vendorUserColumnsDefinitions.usernameColumn,
  vendorUserColumnsDefinitions.firstNameColumn,
  vendorUserColumnsDefinitions.lastNameColumn,
  vendorUserColumnsDefinitions.emailColumn,
  vendorUserColumnsDefinitions.companyColumn,
  vendorUserColumnsDefinitions.updatedAtColumn,
  vendorUserColumnsDefinitions.createdAtColumn,
  vendorUserColumnsDefinitions.eyeIconColumn,
  vendorUserColumnsDefinitions.deleteColumn,
];

export const vendorsUserFiltersConfig: FiltersConfig = {
  [vendorUserColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [vendorUserColumnsDefinitions.usernameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [vendorUserColumnsDefinitions.firstNameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [vendorUserColumnsDefinitions.lastNameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [vendorUserColumnsDefinitions.emailColumn?.key as string]: { filterType: FilterType.SEARCH },
  [vendorUserColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [vendorUserColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function CompanyCell<T extends BaseRow>({ record }: { record: T }) {
  return (
    <>{`${ record.company ? record.company.name : ''}`}</>  );
}
function getVendorCompanyCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return <CompanyCell record={record} />
  };
}

function CustomDeleteCell({ record }: { record: VendorUser }) {
  const { deleteUser } = useVendorUserStore()
  return (
    <DeleteCell
      record={record}
      title="Delete Vendor"
      deleteAction={deleteUser}
      onSuccess={() => {
        dispatchRefreshVendorsUsersGridEvent();
        message.success('Vendor has been successfully deleted');
      }}
      hasNotRelationsContent={() => <div>Vendor has no any relations.</div>}
      hasRelationsContent={() => {
        return (
          <div style={{}}>
            <div>If you really want to continue, enter your password.</div>
          </div>
        );
      }}
    />
  );
}

function getDeleteCell() {
  return (value: any, record: VendorUser) => {
    return (
      <CustomDeleteCell record={record} />
    );
  };
}
