import { create } from 'zustand';
import { initialSelectState } from '@zustandStorage/helpers/initialStates';
import produce from 'immer';
import { getAuthorsForSelect } from '@api';
import { Author } from '@modelTypes/author';

export interface AuthorsForSelectState {
  select: {
    loading: boolean;
    search: string;
    data: Array<Author>;
  },
  fetchAuthorForSelect : any,
}

export const useAuthorSelectStore = create<AuthorsForSelectState>((set) => ({
  select: initialSelectState,
  fetchAuthorForSelect: async (params?: any): Promise<any> => {
    set(produce((state) => {
      state.select.loading = true;
    }));

    return getAuthorsForSelect(params)
      .then((response) => {
        set(produce((state) => {
          state.select.data = response.data;
          state.select.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
}));