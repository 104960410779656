import React from 'react';
import { Select } from 'antd';
import { Language } from '@modelTypes/language';
import { toLower } from 'lodash';

interface Props {
  options: Array<Language>;
  onChange: (option: any) => void;
  onClear: () => void;
  value: any;
  loading?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
  excludedIds?: Array<number>;
}

export default function SelectLanguage(
  {
    options,
    onChange,
    onClear,
    placeholder,
    value,
    loading = false,
    multiple = false,
    disabled = false,
    style = { width: 180 },
  }: Props
) {
  const placeholderValue = !placeholder ? multiple ? 'Select languages' : 'Select language' : placeholder;

  const handleChange = (value: string[], option: any) => {
    onChange(option);
  };

  // useEffect(() => {
  //   if (!data.length) {
  //     dispatch(getCountriesForSelect());
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!isEqual(prevDefaultValue, defaultValue)) {
  //     setValue(find(data, ['id', defaultValue]) || null);
  //   }
  // }, [data, defaultValue]);

  return (
    <Select
      allowClear
      // mode="tags"
      style={style}
      placeholder={placeholderValue}
      fieldNames={{
        label: 'name',
        value: 'id',
      }}
      // defaultValue={['a10', 'c12']}
      value={value}
      onClear={onClear}
      onChange={handleChange}
      loading={loading}
      options={options}
      disabled={disabled}
      filterOption={(inputValue: string, option?: Language) => {
        if (inputValue && inputValue.length && option && option.name) {
          return Boolean(toLower(option.name).indexOf(toLower(inputValue)) > -1);
        }

        return true;
      }}
    />
  );
}
