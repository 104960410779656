import { Card, Checkbox, Form, Image, Input, message, Modal, Skeleton, Spin } from 'antd';
import 'cropperjs/dist/cropper.css';
import UploadPhotoModal from '@components/UploadPhotoModal';
import * as React from 'react';
import { useState } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { CreateDoctorRequest } from '@api/types';
import styles from './UploadImagesModal.module.scss';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  handleSaveForm: (formData: FormData) => any;
  updateGrid: () => void;
}

export default function UploadImagesModal({ isOpen, closeModal, handleSaveForm, updateGrid }: ModalProps) {
  const { confirm } = Modal;
  const [prevImage, setPrevImage] = useState<Blob | null>(null);
  const [uploadImage, setUploadImage] = useState<{ image: Blob | null; crop: Cropper.CropBoxData }>({
    image: null,
    crop: { height: 0, left: 0, top: 0, width: 0 },
  });
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isUploading, setIsUploading] = useState(false);

  const [form] = Form.useForm<CreateDoctorRequest>();

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const handleSaveImage = (cropperRef: Cropper, type: string, image: File) => {
    const crop = cropperRef.getData();

    if (image) {
      setUploadImage({ image, crop: { height: crop.height, width: crop.width, left: crop.x, top: crop.y } });
    }

    const canvas = cropperRef.getCroppedCanvas();

    canvas.toBlob(
      (blob: Blob | null) => {
        if (blob) {
          setPrevImage(blob);
        } else {
          console.error('Failed to create a Blob from the canvas.');
        }
      },
      type,
      type === 'image/jpeg' || type === 'image/jpg' ? 0.95 : undefined
    );
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
    setPrevImage(null);
    setUploadImage({ image: null, crop: { height: 0, left: 0, top: 0, width: 0 } });
  };

  const showConfirm = () => {
    confirm({
      title: 'Data not saved!',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to close modal window?',
      onOk() {
        closeModal();
        resetForm();
      },
      // onCancel() {
      //
      // },
    });
  };

  const handleCloseModal = () => {
    if (formChanged || prevImage) {
      showConfirm();
    } else {
      closeModal();
      resetForm();
    }
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving image...',
      duration: 0,
    });

    if (uploadImage.image) {
      const data = form.getFieldsValue(true);
      const formData = new FormData();

      formData.append('image', uploadImage.image);
      formData.append('imageBounds', JSON.stringify(uploadImage.crop));
      formData.append('isSensitive', JSON.stringify(data.isSensitive));

      if (data.name && data.name.length) {
        formData.append('name', data.name);
      }

      setIsUploading(true);

      await handleSaveForm(formData)
        .then((data: any) => {
          messageApi.destroy('startCreate');
          message.success('The images has been successfully created');
          resetForm();
          closeModal();
          updateGrid();
        })
        .catch((e: any) => {
          messageApi.destroy('startCreate');
          message.error(e.response.data.errors?.[0]?.message ?? 'An error occurred, please try again now', 7);
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
  };

  return (
    <Modal
      okButtonProps={{ disabled: prevImage === null || isUploading }}
      cancelButtonProps={{ disabled: isUploading }}
      title={<div>Add image for gallery</div>}
      open={isOpen}
      onCancel={handleCloseModal}
      onOk={form.submit}
      bodyStyle={{ maxHeight: '70vh' }}
      width={510}
      okText={'Save'}
    >
      <Spin spinning={isUploading}>
        {contextHolder}
        <Card
          className={styles.imageCard}
          style={{ width: 460 }}
          bodyStyle={{ padding: 0 }}
          cover={
            <>
              <UploadPhotoModal
                cropperProps={{
                  initialAspectRatio: 16 / 9,
                  viewMode: 0,
                  minCropBoxHeight: 50,
                  minCropBoxWidth: 50,
                  // maxAspectRatio: 16/9,
                  // minAspectRatio: 1,
                  // background: false,
                  responsive: true,
                  // aspectRatio: 1.6,
                  guides: true,
                }}
                preview={
                  !prevImage ? (
                    <Skeleton.Image style={{ width: 460, height: 274 }} active={false} />
                  ) : (
                    <Image
                      style={{ width: 460, height: 274 }}
                      src={prevImage ? URL.createObjectURL(prevImage) : ''}
                      preview={false}
                    />
                  )
                }
                handleSaveImage={handleSaveImage}
                key={'upload'}
              />
            </>
          }
        ></Card>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          id={'test'}
        >
          <Form.Item
            name="name"
            label="Caption"
            messageVariables={{ name: 'Name' }}
            rules={[{ type: 'string', max: 100 }]}
            initialValue={''}
            style={{ width: 460, margin: '16px 0 0 0' }}
          >
            <Input placeholder="Enter image caption" />
          </Form.Item>
          <Form.Item name="isSensitive" valuePropName="checked" initialValue={false} style={{ margin: '16px 0 0 0' }}>
            <Checkbox>Sensitive content</Checkbox>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}
