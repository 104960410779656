export const valueOnly = (
  styles: { [key:string]: any },
  value: boolean | string | undefined,
  key?: string | undefined
): string | boolean => {
  if (typeof key !== 'string' && typeof value === 'string') {
    return styles[value!] ? styles[value!] : false;
  }

  return value ? styles[key!] : false;
};

