import { closeModalWidthForm, openModalWithForm } from '@storage/frontendTranslations/actions';
import snakeCase from 'lodash/snakeCase';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useSuccessAlert from '@hooks/Alert/useSuccessAlert';
import { updateFrontendDictionary } from '@storage/frontendTranslations/thunkActions';
import { useSelector } from '@hooks';
import { FrontendTranslationsState } from '@storage/frontendTranslations/reducer';
import { Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import SelectTranslationSection from '@base/Select/SelectTranslationSection';
import FrontendLanguagesAntGrid from '@containers/Settings/FrontendLanguages/components/FrontendLanguagesAntGrid';
import { FrontendLanguagesTypes } from '../../../modelTypes/frontendLanguage';
import KeyModalForm from '@containers/Settings/FrontendLanguages/components/KeyModalForm';

interface RouterParams {
  type: string;
}

interface LinkOptions {
  [key: string]: string;
}

const titles = [
  'Layout',
  'Main',
  'Services',
  'Blog',
  'Components',
  'How it works',
  'About us',
  'Search',
  '404',
  'Health Library / Index',
  'Health Library / Show',
  'Doctor / Index',
  'Doctor / Show',
  'Hospital / Index',
  'Hospital / Show',
];

const linkOptions: LinkOptions = {
  'languages__layout': '/frontend-languages/layout',
  'languages__home': '/frontend-languages/main',
  'languages__services': '/frontend-languages/services',
  'languages__search': '/frontend-languages/search',

  'languages__health-library__index': '/frontend-languages/health-library-index',
  'languages__health-library__show': '/frontend-languages/health-library-show',

  'languages__doctor__index': '/frontend-languages/doctor-index',
  'languages__doctor__show': '/frontend-languages/doctor-show',

  'languages__hospital__index': '/frontend-languages/hospital-index',
  'languages__hospital__show': '/frontend-languages/hospital-show',

  'languages__blogs': '/frontend-languages/blogs',
  'languages__how-it-works': '/frontend-languages/how-it-works',
  'languages__about-us': '/frontend-languages/about-us',
  'languages__components': '/frontend-languages/components',
  'languages__404': '/frontend-languages/not-found',
}

export default function FrontendLanguagesIndex(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openSuccessAlert] = useSuccessAlert();
  const { frontendDictionaryLoading } = useSelector<FrontendTranslationsState['grid']>('frontendTranslations.grid');
  const { type } = useParams<RouterParams>();
  const intType = FrontendLanguagesTypes[snakeCase(type).toUpperCase() as keyof typeof FrontendLanguagesTypes];
  const valueTranslationSection = history.location.pathname

  const handleOnOpenModal = () => dispatch(openModalWithForm());
  const handleOnCloseModal = () => dispatch(closeModalWidthForm());

  const handleOnUpdateFrontend = () => {
    openSuccessAlert('Frontend dictionary update process is starting');

    dispatch(updateFrontendDictionary() as unknown as Promise<any>)
      .then(() => {
        openSuccessAlert('Frontend dictionary update process end');
      });
  };

  const handleChangeSectionSelect = (option: { value: string, label: string }) => {
    history.push(linkOptions[option.value]);
  }

  const getKeyByValue = (object: Record<string, string>, value: string): string | undefined => {
    return Object.keys(object).find(key => object[key] === value);
  }

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.push('/')}
        title={`Dictionary (${titles[intType]} page)`}
        extra={[
          <SelectTranslationSection
            onChange={handleChangeSectionSelect}
            value={getKeyByValue(linkOptions, valueTranslationSection)}
            onClear={() => history.push(linkOptions.languages__layout)}
          />,
          <Button
            key="updateLanguages"
            type="primary"
            onClick={handleOnUpdateFrontend}
            loading={frontendDictionaryLoading}
          >
            Update Frontend
          </Button>,
          <Button
            key="createFrontendLanguage"
            type="primary"
            onClick={handleOnOpenModal}
          >
            Add Key
          </Button>
        ]}
      />
      <FrontendLanguagesAntGrid type={intType} />
      <KeyModalForm
        type={intType}
        closeModal={handleOnCloseModal}
      />
    </>
  );
}
