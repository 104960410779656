import {
  AntGetListParams,
  CreateCountryRequest,
  CreateCountryResponse,
  GetListParams,
  Params,
  UpdateCountryRequest,
  UpdateCountryResponse
} from '@api/types';
import {
  clearSelectCountry,
  endFetchCountries,
  endFetchCountriesForSelect,
  endFetchCountry,
  endPostCountry,
  endUpdateCountry,
  startDeleteCountry,
  startFetchCountries,
  startFetchCountriesForSelect,
  startFetchCountry,
  startPostCountry,
  startUpdateCountry
} from './actions';

import { AxiosRequestConfig } from 'axios';
import { ThunkAction } from '@storage/types';
import api from '@api';

export function getCountries(params?: AntGetListParams): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchCountries());

    return api.getCountries(params)
      .then((data) => {
        dispatch(endFetchCountries(data));
      });
  };
}

export function getCountriesForSelect(params?: Params): ThunkAction<Promise<void>> {
  const allParams: GetListParams = { page: 1, perPage: 5000, ...params };

  return async (dispatch): Promise<void> => {
    dispatch(startFetchCountriesForSelect());

    return api.getCountriesForSelect(allParams)
      .then((data) => {
        dispatch(endFetchCountriesForSelect(data));
      });
  };
}

export function getCountry(id: number, params?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchCountry());

    return api.getCountry(id, params)
      .then((data) => {
        dispatch(endFetchCountry(data));
      });
  };
}

export function createCountry(payload: CreateCountryRequest): ThunkAction<Promise<CreateCountryResponse>> {
  return async (dispatch, getState): Promise<CreateCountryResponse> => {
    dispatch(startPostCountry());

    return api.createCountry(payload)
      .then((data) => {
        dispatch(clearSelectCountry());
        dispatch(endPostCountry(data));

        return data;
      });
  };
}

export function updateCountry(id: number, payload: UpdateCountryRequest): ThunkAction<Promise<UpdateCountryResponse>> {
  return async (dispatch, getState): Promise<UpdateCountryResponse> => {
    dispatch(startUpdateCountry());

    return api.updateCountry(id, payload)
      .then((data) => {
        dispatch(clearSelectCountry());
        dispatch(endUpdateCountry(data));

        return data;
      });
  };
}

export function deleteCountry(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (dispatch, getState): Promise<void> => {
    dispatch(startDeleteCountry());

    return api.deleteCountry(id, config);
  };
}