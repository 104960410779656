import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { PaginationConfig } from '@components/AntGrid/types';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeLanguage from '@components/AntGrid/hooks/useChangeLanguage';
import { Content, ContentPages } from '@modelTypes/content';
import useChangeGridOptions from "@components/AntGrid/hooks/useChangeGridOptions";
import { useEffect } from 'react';
import { contentMenuColumns, contentMenuFiltersConfig } from '@components/AntGrid/columns/contentMenu';
import { useMenuStore } from '@zustandStorage/menu/menu';

export const [
  refreshContentsMenuGridEvent,
  dispatchRefreshContentsMenuGridEvent
] = makeRefreshGridEvent('onRefreshContentsMenuGrid');

interface Props {
  pageName: ContentPages;
}

interface RouterParams {
  id: string;
}

export default function ContentsMenuGrid(
  {
    pageName,
  }: Props
) {
  const { menuGrid, fetchMenuItems, clearMenuGridData } = useMenuStore();
  const [languageId, setFirstLoad] = useChangeLanguage((languageId) => onFetchData({ languageId }));

  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: menuGrid.meta.total,
  };

  const onFetchData = async (params?: AntGetListParams) => {
    console.log('ContentsMenuGrid, ', 'onFetchData --- ');
    fetchMenuItems({ languageId, ...params  }, { pageIndex: pageName })
      .then(() => {
        setFirstLoad(true);
      });
  };

  useEffect(() => {
    onFetchData();
  }, [pageName]);

  useRefreshGridEvent(refreshContentsMenuGridEvent, onFetchData);
  useChangeGridOptions('contentsMenuGrid');

  return (
    <Grid<Content>
      id="contentsMenuGrid"
      onFetch={onFetchData}
      columns={contentMenuColumns}
      data={menuGrid.data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={menuGrid.loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={contentMenuFiltersConfig}
    />
  );
}