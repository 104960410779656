import { FormikErrors, FormikHelpers } from 'formik/dist/types';
import { GetListParams, ListResponse, RestApi, RestUri } from './types';

import { FieldData } from 'rc-field-form/es/interface';
import axios from 'axios';
import client from './client';
import curry from 'lodash/curry';
import { getUrlFromMask } from '@helpers/url';
import reduce from 'lodash/reduce';

export const getRestUri = curry((focus: string, action: string): RestUri => {
  const uri = `/${focus}${action.length ? `/${action}` : ''}`;

  return {
    GET: (params?: Record<string, string | number>) => getUrlFromMask(uri, params),
    POST: (params?: Record<string, string | number>) => getUrlFromMask(uri, params),
    SHOW: (params?: Record<string, string | number>) => getUrlFromMask(`${uri}/:id`, params),
    PATCH: (params?: Record<string, string | number>) => getUrlFromMask(`${uri}/:id`, params),
    DELETE: (params?:Record<string, string | number>) => getUrlFromMask(`${uri}/:id`, params),
  };
});

// export const getRestApi = <Item>(restUri: RestUri): RestApi<Item> => {
//   return [
//     async (params: GetListParams): Promise<ListResponse<Item>> => {
//       return client.get<ListResponse<Item>>(restUri.GET, params);
//     },
//     async (formData: Omit<Item, 'id'>): Promise<Item> => {
//       return client.post<Omit<Item, 'id'>, Item>(restUri.POST, formData);
//     },
//   ];
// };

export const setFormErrors = (e: Error, prevFields: any, setFields: (fields: FieldData[]) => void): void => {
  if (axios.isAxiosError(e)) {
    if (e.response?.status === 422 && e.response?.data.errors) {
      const fields: Array<FieldData> = [];

      e.response?.data.errors.forEach((i: any) => {
        fields.push({
          name: i.field,
          value: prevFields[i.field],
          errors: [i.message],
        });
      });

      setFields(fields);
    }
  }
};

export const setFormikErrors = <Values>(e: Error, setErrors: FormikHelpers<Values>['setErrors']): void => {
  if (axios.isAxiosError(e)) {
    if (e.response?.status === 422 && e.response?.data.errors) {
      setErrors(reduce(e.response?.data.errors, (r: any, i) => {
        r[i.field] = i.message;

        return r;
      }, {}));
    }
  }
};
