import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useSelector } from '@hooks';
import {
  Form, FormInstance,
} from 'antd';
import SelectHospital from '@base/Select/SelectHospital';
import { HospitalsState } from '@storage/hospitals/reducer';
import { getHospitalsForSelect } from '@storage/hospitals/thunkActions';

interface Props {
  form: FormInstance;
  initialValue?: any;
}

export default function HospitalsField(
  {
    form,
    initialValue,
  }: Props
) {
  const dispatch = useDispatch();
  const data = useSelector<HospitalsState['select']>('hospitals.select');
  const [count, setCount] = React.useState<number>();
  const filteredData = useMemo(
    () => {
      return data.data.filter((i: any) => i?.isActive);
    },
    [data.fetched, data.loading]
  );

  useEffect(() => {
    if (!data.fetched && !data.loading) {
      dispatch(getHospitalsForSelect() as unknown as Promise<void>)
        .then(() => {
          form.resetFields(['hospitals']);
        });
    }
  }, [data.fetched]);

  useEffect(() => {
    if (initialValue && initialValue.length) {
      setCount(initialValue.length);
    }
  }, [initialValue]);
  
  return (
    <Form.Item
      name="hospitals"
      label={`Hospitals ${count ? `(${count} selected)` : ''}`}
      initialValue={data.fetched ? initialValue : []}
      valuePropName="defaultValue"
    >
      <SelectHospital
        mode="multiple"
        // disabled={languageId > 1}
        loading={data.loading}
        options={filteredData}
        onChange={() => setCount(form.getFieldValue('hospitals')?.length)}
        style={{ width: '100%' }}
      />
    </Form.Item>
  );
}
