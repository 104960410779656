import {
  AntGetListParams,
  CreateMenuItemRequest,
  CreateMenuItemResponse,
  GetMenuItemResponse,
  ListResponse,
  Params,
  UpdateMenuItemRequest,
  UpdateMenuItemResponse,
} from '@api/types';

import { Content, MenuContent } from '@modelTypes/content';
import client from './client';
import { getRestUri } from '@api/helpers';
import { AxiosRequestConfig } from 'axios';

const getUri = getRestUri('contents');
const getMenuUri = getRestUri('contents/menu');
const ROOT_URI = getUri('');
const MENU_ROOT_URI = getMenuUri('');

// const GET_DOCTORS_FOR_SELECT_URI = 'doctors/select';
const UPDATE_CONTENT_RELATIONS_URI = (id: number) => `contents/${id}/relations`;

const GET_MENU_ITEM_URI = (id: number) => `contents/menu/${id}`
const CREATE_MENU_ITEM_URI = `contents/menu`
const UPDATE_MENU_ITEM_URI = (id: number) => `contents/menu/${id}`
const DELETE_MENU_ITEM_URI = (id: number) => `contents/menu/${id}`

const GET_MENU_SUB_ITEMS_URI = (id: number) => `contents/menu/${id}/items`;
const CREATE_MENU_SUB_ITEM_URI = (id: number) => `contents/menu/${id}/items`;
const UPDATE_MENU_SUB_ITEM_URI = (id: number) => `contents/menu/items/${id}`
const UPDATE_MENU_SUB_ITEM_LINK_URI = (id: number) => `contents/menu/items/links/${id}`
const DELETE_MENU_SUB_ITEM_LINK_URI = (id: number) => `contents/menu/items/links/${id}`

export async function getContents(params?: AntGetListParams, payload?: Params) {
  return client.get<ListResponse<Content>>(ROOT_URI.GET(), { ...params, ...payload });
}

export async function getContent(id: number, params?: Params) {
  return client.get<Content>(ROOT_URI.SHOW({ id }), params);
}

// export async function createDoctor(payload: CreateDoctorRequest) {
//   return client.post<CreateDoctorRequest, CreateDoctorResponse>(ROOT_URI.POST(), payload);
// }

// export async function updateContent(id: number, payload: UpdateContentRequest) {
//   return client.patch<UpdateContentRequest, UpdateContentResponse>(ROOT_URI.PATCH({ id }), payload);
// }

export async function updateContentRelation(id: number, payload: any) {
  return client.patch<any, any>(UPDATE_CONTENT_RELATIONS_URI(id), payload);
}

// export async function updateDoctorsFiltersCount() {
//   return client.post<any, any>(UPDATE_DOCTORS_FILTERS_COUNT_URI);
// }
//
// export async function deleteDoctor(id: number, payload?: AxiosRequestConfig) {
//   return client.del(ROOT_URI.DELETE({ id }), payload);
// }

// Menu

// export async function getMenuItem(id: number, params?: Params) {
//   return client.get<any>(GET_MENU_ITEM_URI( id ), params);
// }
//
// export async function createMenuItem(payload: any) {
//   return client.post<any, any>(CREATE_MENU_ITEM_URI, payload);
// }
//
// export async function updateMenuItem(id: number, payload: any) {
//   return client.patch<any, any>(UPDATE_MENU_ITEM_URI(id), payload);
// }

export async function getMenuItem(id: number, params?: Params): Promise<GetMenuItemResponse> {
  return client.get<GetMenuItemResponse>(GET_MENU_ITEM_URI(id), params);
}

export async function createMenuItem(payload: CreateMenuItemRequest){
  return client.post<CreateMenuItemRequest, CreateMenuItemResponse>(CREATE_MENU_ITEM_URI, payload);
}

export async function updateMenuItem(id: number, payload: UpdateMenuItemRequest){
  return client.patch<UpdateMenuItemRequest, UpdateMenuItemResponse>(UPDATE_MENU_ITEM_URI(id), payload);
}

export async function getMenuContents(params?: AntGetListParams, payload?: Params) {
  return client.get<ListResponse<MenuContent>>(MENU_ROOT_URI.GET(), { ...params, ...payload });
}

export async function deleteMenuItem(id: number, payload?: AxiosRequestConfig) {
  return client.del(DELETE_MENU_ITEM_URI(id), payload);
}

// Sub item.
export async function getMenuSubItems(id: number, params?: AntGetListParams, payload?: Params) {
  return client.get<ListResponse<any>>(GET_MENU_SUB_ITEMS_URI(id), { ...params, ...payload });
}

export async function createMenuSubItem(id: number, payload: any){
  return client.post<any, any>(CREATE_MENU_SUB_ITEM_URI(id), payload);
}

export async function updateMenuSubItem(id: number, payload: any){
  return client.patch<any, any>(UPDATE_MENU_SUB_ITEM_URI(id), payload);
}

export async function updateMenuSubItemLink(id: number, payload: any){
  return client.patch<any, any>(UPDATE_MENU_SUB_ITEM_LINK_URI(id), payload);
}

export async function deleteSubMenuItemLink(id: number, payload?: AxiosRequestConfig) {
  return client.del(DELETE_MENU_SUB_ITEM_LINK_URI(id), payload);
}
