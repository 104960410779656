import { Checkbox, Form, Input, Modal, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';
import TreatmentProgram from '@base/Modal/CreateLinkMenuModal/TreatmentProgram';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Hospital from '@base/Modal/CreateLinkMenuModal/Hospital';
import Doctor from '@base/Modal/CreateLinkMenuModal/Doctor';
import Destinations from '@base/Modal/CreateLinkMenuModal/Destinations';
import HealthLibrary from '@base/Modal/CreateLinkMenuModal/HealthLibrary';
import Blogs from '@base/Modal/CreateLinkMenuModal/Blogs';
import { getSelectValue } from '@helpers/antForm';
import _ from 'lodash';

interface Props {
  open: boolean;
  onCancel: () => void;
  onFinish?: (payload: any) => void;
  loading?: boolean;
  title?: string;
  okText?: string;
}

const sectionForSelect = [
  {
    value: 0,
    label: 'Health Library',
  },
  {
    value: 1,
    label: 'Treatments & Packages',
  },
  {
    value: 2,
    label: 'Hospitals',
  },
  {
    value: 3,
    label: 'Doctors',
  },
  {
    value: 4,
    label: 'Destinations',
  },
  {
    value: 5,
    label: 'Blogs',
  },
];

const selectFieldNames = [
  'treatmentProgramId', 'diseaseId', 'countryId', 'cityId', 'doctorId', 'hospitalId', 'articleId',
  'specializationId',  'blogType',
];

export default function CreateLinkMenuModal(
  {
    open,
    onCancel,
    onFinish,
    loading = false,
    title = 'Create link for Menu',
    okText = 'Create',
  }: Props
): JSX.Element {
  const [form] = Form.useForm();
  const [checked, setChecked] = useState(false);
  const [section, setSection] = useState<number>();

  const handleSectionChange = (value: number) => {
    setSection(value);
  };

  const handleCancelModal = () => {
    onCancel()
  }

  const handleOnFinish = () => {
    const formData = form.getFieldsValue(true);

    _.map(selectFieldNames, (key) => formData[key] = getSelectValue(formData[key]));

    onFinish && onFinish(formData);
  };

  const resetFields = () => {
    setChecked(false);
    setSection(undefined);
    form.resetFields();
  };

  useEffect(() => {
    // Reset all fields except sectionId.
    form.resetFields(selectFieldNames);
  }, [checked, section])

  useEffect(() => {
    resetFields();
  }, [open]);

  return (
    <Modal
      keyboard={false}
      maskClosable={false}
      title={title}
      open={open}
      // okButtonProps={{ style: { display: 'none' } }}
      okText={okText}
      onOk={() => form.submit()}
      onCancel={handleCancelModal}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          // onFinishFailed={handleOnFinishFailed}
          autoComplete="off"
          style={{ marginTop: 26 }}
          initialValues={{
            isIndexPage: false,
            section: section,
          }}
        >
          <Form.Item
            name="sectionId"
            label="Select Section"
            valuePropName="defaultValue"
            rules={[{ required: true }]}
            messageVariables={{ name: 'Section' }}
            // initialValue={section}
          >
            <Select
              placeholder="Select Section"
              onChange={handleSectionChange}
              style={{ width: '100%' }}
              // filterOption={(input, option) =>
              //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              // }
              options={sectionForSelect}
            />
          </Form.Item>
          <Form.Item
            name="isIndexPage"
            valuePropName="checked"
            // initialValue={checked}
          >
            <Checkbox
              onChange={(e: CheckboxChangeEvent) => setChecked(e.target.checked)}
            >
              Index page
            </Checkbox>
          </Form.Item>
          {section === sectionForSelect[0].value && <HealthLibrary indexPage={checked} form={form} />}
          {section === sectionForSelect[1].value && <TreatmentProgram indexPage={checked} />}
          {section === sectionForSelect[2].value && <Hospital indexPage={checked} />}
          {section === sectionForSelect[3].value && <Doctor indexPage={checked} />}
          {section === sectionForSelect[4].value && <Destinations indexPage={checked} />}
          {section === sectionForSelect[5].value && <Blogs indexPage={checked} />}
          <Form.Item
            name="name"
            label="Name"
            messageVariables={{ name: 'Name' }}
            rules={[{ required: true, type: 'string', max: 128 }]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}