import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M8.90731 10.5203C8.90731 10.2255 8.67665 9.99486 8.38189 9.99486C8.08714 9.99486 7.85647 10.2354 7.85647 10.5203C7.85647 10.8051 8.09703 11.0457 8.38189 11.0457C8.66676 11.0457 8.90731 10.8051 8.90731 10.5203Z"
    fill="currentColor" />
  <path
    d="M7.6907 11.5743C7.40583 11.5743 7.16528 11.8148 7.16528 12.0997C7.16528 12.3846 7.40583 12.6251 7.6907 12.6251C7.97556 12.6251 8.21612 12.3846 8.21612 12.0997C8.21612 11.8148 7.98545 11.5743 7.6907 11.5743Z"
    fill="currentColor" />
  <path
    d="M8.91997 11.3025C8.63511 11.3025 8.39455 11.543 8.39455 11.8279C8.39455 12.1128 8.63511 12.3533 8.91997 12.3533C9.20484 12.3533 9.44539 12.1128 9.44539 11.8279C9.44539 11.543 9.21473 11.3025 8.91997 11.3025Z"
    fill="currentColor" />
  <path
    d="M10.7498 9.48012C10.4626 8.72799 9.75716 8.24411 8.94411 8.24411C8.39574 8.24411 7.89604 8.4637 7.53046 8.8633C6.58921 8.55233 5.75519 9.07458 5.37378 9.70049C4.95954 10.3359 4.85628 11.2954 5.54549 12.0257C5.31048 12.6908 5.47388 13.4247 5.98822 13.9992L5.99139 14.0024C6.38704 14.398 6.86894 14.6069 7.38565 14.6069C7.60365 14.6069 7.85806 14.5583 8.10534 14.4696C8.65014 14.6872 9.24994 14.6402 9.7995 14.3347C10.2984 14.0411 10.6276 13.5763 10.7305 13.0227C11.3944 12.7414 11.807 12.1167 11.8375 11.3393C11.8387 10.5005 11.4232 9.79149 10.7506 9.48091L10.7498 9.48012ZM10.6976 11.2281C10.7229 11.5656 10.5409 11.9521 10.0776 12.0546L10.0721 12.0562C9.82799 12.1258 9.65786 12.3407 9.65786 12.5792C9.65786 12.9132 9.48852 13.2131 9.21632 13.3622C8.90375 13.5331 8.57378 13.5347 8.33362 13.367L8.32927 13.3642C8.15598 13.2601 7.97279 13.2597 7.80029 13.3638C7.28318 13.6681 6.88001 13.3539 6.73085 13.2047C6.50573 12.9796 6.33639 12.5567 6.60187 12.106C6.73995 11.8639 6.67507 11.5494 6.45904 11.4053C6.25489 11.2625 5.82324 10.8597 6.22482 10.2057C6.40128 9.94303 6.67388 9.78635 6.954 9.78635C7.09643 9.78635 7.24836 9.82908 7.43155 9.92087C7.58268 9.99644 7.71721 10.0174 7.84263 9.98536C7.97833 9.95094 8.08911 9.85401 8.16112 9.70841C8.31978 9.418 8.6086 9.23759 8.91483 9.23759C9.33264 9.23759 9.66656 9.51968 9.74569 9.94105C9.77893 10.1045 9.91147 10.2896 10.101 10.3308C10.5769 10.4918 10.721 10.8934 10.6968 11.2162V11.2281H10.6976Z"
    fill="currentColor" />
  <path
    d="M6.22245 0C5.91701 0 5.66854 0.248467 5.66854 0.553907V5.7274C5.66854 6.35727 5.85647 6.95391 6.21176 7.45282C6.31463 7.59763 6.41829 7.74164 6.52195 7.88526C6.59411 7.98532 6.67639 8.09994 6.758 8.21428C6.88508 8.39232 7.10387 8.48107 7.31879 8.44034C7.71377 8.3655 7.90061 7.91108 7.66769 7.58342C7.58038 7.4606 7.49598 7.3427 7.42086 7.23838C7.31839 7.09634 7.21592 6.95351 7.11384 6.81029C6.89267 6.4997 6.77596 6.12542 6.77596 5.727V0.553907C6.77596 0.248467 6.52749 0 6.22205 0"
    fill="currentColor" />
  <path
    d="M7.94617 14.7836C7.68112 14.7882 7.43524 14.9175 7.26675 15.1221C7.03806 15.3999 6.77562 15.6843 6.48318 15.9711C5.61711 16.8202 5.19099 18.076 5.24994 19.6036C5.29307 20.7134 5.57596 21.5834 5.58822 21.6198C5.6634 21.8473 5.87467 22 6.11404 22C6.1726 22 6.23076 21.9905 6.28694 21.9723C6.57655 21.877 6.73521 21.5636 6.64065 21.2736C6.63115 21.2439 5.69386 18.2959 7.25865 16.7616C7.65121 16.3768 8.02611 15.9428 8.34383 15.5231C8.58368 15.2062 8.3435 14.7766 7.94617 14.7836Z"
    fill="currentColor" />
  <path
    d="M3.38407 0C3.07863 0 2.83016 0.248467 2.83016 0.553907V5.7274C2.83016 6.12542 2.71345 6.5001 2.49228 6.81029C2.3902 6.95351 2.28773 7.09594 2.18526 7.23838C1.24757 8.54164 0.277838 9.88962 0.0503403 11.4113C-0.158562 12.8071 0.281399 14.1678 1.39475 15.5707C1.43669 15.6728 1.50909 15.7614 1.60128 15.8228C1.82601 16.0874 2.07685 16.3561 2.34787 16.6216C3.91305 18.1563 2.97576 21.1035 2.96627 21.1327C2.87052 21.4228 3.02878 21.7365 3.31879 21.8322C3.37497 21.8508 3.43353 21.8603 3.49208 21.8603C3.73145 21.8603 3.94312 21.7072 4.0179 21.4797C4.02977 21.4433 4.31305 20.5745 4.35618 19.4631C4.41552 17.9355 3.98901 16.6793 3.12294 15.8307C2.8903 15.6028 2.67586 15.3757 2.48516 15.1553C2.56152 14.9037 2.85469 14.8075 3.71918 14.7379C4.38268 14.6845 5.13442 14.6235 5.35242 13.9458C5.39752 13.8049 5.38526 13.655 5.318 13.5232C5.25034 13.3915 5.1356 13.2942 4.99475 13.2491C4.73125 13.1644 4.44401 13.2902 4.32571 13.5371C4.19515 13.5881 3.8177 13.6186 3.63016 13.634C3.18506 13.67 2.68061 13.7108 2.25173 13.9086C2.0721 13.9913 1.91701 14.0973 1.78842 14.2247C1.27764 13.4089 1.05687 12.6208 1.1174 11.8255C1.26775 12.1341 1.46478 12.3858 1.70494 12.5753C2.05311 12.8499 2.47408 12.9891 2.95637 12.9891C3.35281 12.9891 3.79673 12.8946 4.27626 12.7082C4.56073 12.5974 4.70237 12.2758 4.59159 11.9913C4.53778 11.8532 4.43372 11.7448 4.29841 11.6851C4.1631 11.6253 4.01236 11.6222 3.87467 11.676C3.19851 11.9391 2.6992 11.949 2.3906 11.7056C2.05509 11.4409 1.77932 10.6916 1.91622 9.61108C2.25291 9.04174 2.65647 8.48032 3.08377 7.88645C3.18704 7.74283 3.2907 7.59881 3.39396 7.45401C3.74925 6.9547 3.93719 6.35806 3.93719 5.72859V0.553907C3.93719 0.248467 3.68872 0 3.38328 0"
    fill="currentColor" />
</svg>`;

export default { id: '142-bone-cancer', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
