import { Form, Input, message, Modal } from 'antd';
import { useDoctorSkillsStore } from '@zustandStorage/doctor/skills';
import { Prompt, useParams } from 'react-router-dom';
import { data } from 'browserslist';
import * as React from 'react';
import { prepareFormDataForBackend } from '@helpers/antForm';
import { setFormErrors } from '@api/helpers';
import { dispatchRefreshDoctorSkillsGridEvent } from '@containers/Doctors/FormSteps/SkillsStep/components/SkillsGrid';
import { ExclamationCircleFilled } from '@ant-design/icons';

interface ModalProps {
  isOpen: boolean;
  handleCancel: () => void;
}

interface RouteParams {
  id: string;
}

export default function AddCustomSkillsModal({ isOpen, handleCancel }: ModalProps) {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { confirm } = Modal;
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);

  const { id } = useParams<RouteParams>();

  const { createDoctorSkills } = useDoctorSkillsStore()

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving skill...',
      duration: 0,
    });

    setFormSubmitting(true);
    const formData = { ...form.getFieldsValue(), frequency: 0 }

    await createDoctorSkills(+id, formData)
      .then((data) => {
        messageApi.destroy('startCreate');
        message.success('The skill has been successfully created');
        setFormSubmitting(false);
        dispatchRefreshDoctorSkillsGridEvent()
        resetForm();
        handleCancel()
      })
      .catch((e) => {
        messageApi.destroy('startCreate');
        setFormSubmitting(false);
        message.error('An error occurred, please try again now')
        setFormErrors(e, form.getFieldsValue(), form.setFields);
      })
  };

  const showConfirm = () => {
    confirm({
      title: 'Data not saved!',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to close modal window?',
      onOk() {
        handleCancel()
        resetForm()
      },
      // onCancel() {
      //
      // },
    });
  };

  const handleCloseModal = () => {
    if (formChanged) {
      showConfirm()
    } else {
      handleCancel()
      resetForm()
    }
  }

  return (
    <Modal
      title="Add skill & expertise"
      open={isOpen}
      onCancel={handleCloseModal}
      width={'400px'}
      onOk={form.submit}>
      <>
        {contextHolder}
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          autoComplete="off"
        >
          <Form.Item
            name="name"
            label="Name"
            messageVariables={{ name: 'Name' }}
            rules={[{ required: true, type: 'string' }]}
            initialValue={''}
          >
            <Input placeholder="Enter word" />
          </Form.Item>
        </Form>
      </>
    </Modal>
  )
}