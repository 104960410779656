import { ActionType, Actions } from './actions';

import { ListResponse } from '@api/types';
import { User } from '@modelTypes/user';

export interface UsersState {
  readonly grid: ListResponse<User> & { loading: boolean };
  readonly form: {
    readonly loading: boolean;
    readonly row: User | null;
  };
  readonly requestPermissionModal: {
    readonly loading: boolean
  };
  readonly selectUsers: {
    readonly loading: boolean;
    readonly search: string | null;
    readonly data: Array<User>;
  };
}

const initialState: UsersState = {
  grid: {
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  form: {
    loading: false,
    row: null,
  },
  requestPermissionModal: {
    loading: false,
  },
  selectUsers: {
    loading: false,
    search: null,
    data: [],
  }
};

export default function users(state = initialState, action: Actions): UsersState {
  switch (action.type) {
    case ActionType.START_FETCH_USERS:
      // case ActionType.START_DELETE_COUNTRY:
      return {
        ...state,
        grid: {
          ...state.grid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_USERS:
      return {
        ...state,
        grid: {
          ...action.data,
          loading: false,
        },
      }
    case ActionType.START_FETCH_USERS_FOR_SELECT:
      return {
        ...state,
        selectUsers: {
          ...state.selectUsers,
          loading: true,
        }
      }
    case ActionType.END_FETCH_USERS_FOR_SELECT:
      return {
        ...state,
        selectUsers: {
          ...state.selectUsers,
          loading: false,
          data: action.data.data,
        }
      }
    case ActionType.START_FETCH_USER:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        }
      }
    case ActionType.END_FETCH_USER:
    case ActionType.END_POST_USER:
    case ActionType.END_PATCH_USER:
      return {
        ...state,
        form: {
          ...state.form,
          row: action.data,
          loading: false,
        },
      }
    case ActionType.START_POST_USER:
    case ActionType.START_PATCH_USER:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        },
      }
    case ActionType.END_POST_USER_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.END_PATCH_USER_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.START_REQUEST_PERMISSION:
      return {
        ...state,
        requestPermissionModal: {
          ...state.requestPermissionModal,
          loading: true,
        },
      }
    case ActionType.END_REQUEST_PERMISSION:
      return {
        ...state,
        requestPermissionModal: {
          ...state.requestPermissionModal,
          loading: false,
        },
      }
    case ActionType.START_RESET_GOOGLE_SECRET:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        },
      }
    case ActionType.END_RESET_GOOGLE_SECRET:
      return {
        ...state,
        form: {
          ...state.form,
          row: action.data,
          loading: false,
        },
      }
    case ActionType.CLEAR_FORM_DATA:
      return {
        ...state,
        form: {
          ...initialState.form,
        }
      }
    default:
      return state;
  }
}