import { create } from 'zustand';
import produce from 'immer';
import { GetListParams, ListResponse, Params } from '@api/types';
import {
  getArticlesForSelect2,
  getCertificatesForSelect,
  getCitiesForSelect2,
  getCountriesForSelect2,
  getDoctorsForSelect,
  getHospitalsForSelect,
  getPhoneCountriesForSelect,
  getSearchSectionDiseaseForSelect,
  getSearchSynonymsForSelect,
  getSpecializationsForSelect2,
  getStatusesForSelect,
  getTagsForSelect,
  getTreatmentMethodsForSelect,
  getTreatmentProgramsForSelect,
  getTreatmentTypesForSelect,
  getUsersForSelect,
} from '@api';
import { TreatmentMethod, TreatmentProgram, TreatmentType } from '@modelTypes/treatment';
import { Country } from '@modelTypes/country';
import { City } from '@modelTypes/city';
import { SearchSection } from '@modelTypes/searchSection';
import { Hospital } from '@modelTypes/hospital';
import { Doctor } from '@modelTypes/doctor';
import { Specialization } from '@modelTypes/specialization';
import { Blog } from '@modelTypes/blog';
import { getTPRDepartmentsForSelect, getTPRHospitalsForSelect } from '@api/treatmentApi';
import { getSSDepartmentsForSelect, getSSHospitalsForSelect } from '@api/searchSectionApi';
import { SearchSynonym } from '@modelTypes/searchSynonym';
import { Certificate } from '@modelTypes/certificate';
import { Tag } from '@modelTypes/tag';
import { PhoneCountry, Status } from '@modelTypes/order';
import { User } from '@modelTypes/user';
import { getCurrencyForSelect } from '@api/offerApi';
import { VendorCompany, VendorUser } from '@modelTypes/vendor';
import { getCompaniesForSelect, getVendorsUsersForSelect } from '@api/vendorsApi';
import { getLanguagesForSelect } from '@api/languageApi';
import { Language } from '@modelTypes/language';

interface SelectData<T> {
  loading: boolean;
  opened: boolean;
  search: string;
  data: Array<T>;
  meta: Record<string, number | string>;
  params: Record<string, number | string | boolean>;
}

interface FetchOptions {
  clearBeforeFetch?: boolean;
  clearDataBeforeFetch?: boolean;
  saveParams?: boolean;
}

type BaseFetchFunction = (getDataForSelect: (params?: GetListParams) => Promise<any>, data: any) => Promise<any>;
export type FetchFunction<T> = (id: string, params?: Params, opts?: FetchOptions) => Promise<ListResponse<T>>;

interface Select {
  data: Record<string, SelectData<any>>
  clearFormData: (id: string) => void;
  // clearDataBeforeFetch:(id:string)=>void;
  setOpened: (id: string, status: boolean) => void;
  setData: (id: string, data: Array<any>) => void;
  setQueryParams: (id: string, options: Record<string, number | string | boolean>) => void;
  fetchForSelect: BaseFetchFunction;
  fetchTreatmentProgram: FetchFunction<TreatmentProgram>;
  fetchCountriesForSelect: FetchFunction<Country>;
  fetchCitiesForSelect: FetchFunction<City>;
  fetchSectionDisease: FetchFunction<SearchSection>;
  fetchHospitalsForSelect: FetchFunction<Hospital>;
  fetchDoctorsForSelect: FetchFunction<Doctor>;
  fetchSpecializationForSelect: FetchFunction<Specialization>;
  fetchArticlesForSelect: FetchFunction<Blog>;
  fetchTPHospitalsForRelations: FetchFunction<any>;
  fetchTPDepartmentsForRelations: FetchFunction<any>;
  fetchSSHospitalsForRelations: FetchFunction<any>;
  fetchSSDepartmentsForRelations: FetchFunction<any>;
  fetchSSSynonyms: FetchFunction<SearchSynonym>;
  fetchCertificatesForSelect: FetchFunction<Certificate>;
  fetchTagsForSelect: FetchFunction<Tag>;
  fetchStatusForSelect: FetchFunction<Status>;
  fetchUserForSelect: FetchFunction<User>
  fetchPhoneCountriesForSelect: FetchFunction<PhoneCountry>
  fetchOffersMethods: FetchFunction<TreatmentMethod>;
  fetchOffersTypesOfCare: FetchFunction<TreatmentType>;
  fetchCurrency: FetchFunction<TreatmentType>;
  fetchCompany: FetchFunction<VendorCompany>;
  fetchVendor: FetchFunction<VendorUser>;
  fetchLanguages: FetchFunction<Language>;
}

const initialSelectData: SelectData<any> = {
  loading: false,
  opened: false,
  search: '',
  data: [],
  meta: {},
  params: { page: 1, perPage: 50 },
}

export const useSelect = create<Select>((set, get) => ({
  data: {},
  clearFormData: (id: string) => set(produce((state) => {
    state.data[id] = initialSelectData;
  })),
  // clearDataBeforeFetch: (id: string) => set(produce((state) => {
  //   state.data[id] = {
  //
  //     data: [],
  //   };
  // })),
  setOpened: (id, status) => {
    set(produce((state) => {
      if (!state.data[id]) {
        state.data[id] = { ...initialSelectData, opened: status };
      } else {
        state.data[id].opened = status;
      }
    }))
  },
  setData: (id, data) => {
    set(produce((state) => {
      if (!state.data[id]) {
        state.data[id] = { ...initialSelectData, data };
      } else {
        state.data[id].data = data;
      }
    }))
  },
  setQueryParams: (id, options) => {
    set(produce((state) => {
      if (!state.data[id]) {
        state.data[id] = { ...initialSelectData, params: { ...initialSelectData.params, ...options } };
      } else {
        state.data[id].params = options;
      }
    }))
  },
  fetchForSelect: async (getDataForSelect, data) => {
    set(produce((state) => {
      if (!state.data[data.id]) {
        state.data[data.id] = initialSelectData;
      }
    }));
    set(produce((state) => {
      state.data[data.id].loading = true;
    }));
    // if (data.options.clearDataBeforeFetch) get().clearFormData(data.id);
    if (data.options.saveParams) get().setQueryParams(data.id, { ...get().data[data.id].params, ...data.params });

    return getDataForSelect({ ...get().data[data.id].params, ...data.params })
      .then((response) => {
        set(produce((state) => {
          if (data.options.clearDataBeforeFetch) {
            state.data[data.id].data = response.data;
          } else {
            state.data[data.id].data.push(...response.data);
          }
          state.data[data.id].meta = response.meta
          state.data[data.id].loading = false;
          state.data[data.id].opened = true;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.data[data.id].loading = false;
        }));

        throw e;
      });
  },
  fetchTreatmentProgram: async (id, params = {}, options = {}) => {
    return await get().fetchForSelect(getTreatmentProgramsForSelect, { id, options, params });
  },
  fetchSectionDisease: async (id, params = {}, options = {}) => {
    return get().fetchForSelect(getSearchSectionDiseaseForSelect, { id, options, params });
  },
  fetchCountriesForSelect: async (id, params = {}, options = {}) => {
    return get().fetchForSelect(getCountriesForSelect2, { id, options, params });
  },
  fetchCitiesForSelect: async (id, params = {}, options = {}) => {
    return get().fetchForSelect(getCitiesForSelect2, { id, options, params });
  },
  fetchHospitalsForSelect: async (id, params = {}, options = {}) => {
    return get().fetchForSelect(getHospitalsForSelect, { id, options, params });
  },
  fetchDoctorsForSelect: async (id, params = {}, options = {}) => {
    return get().fetchForSelect(getDoctorsForSelect, { id, options, params });
  },
  fetchSpecializationForSelect: async (id, params = {}, options = {}) => {
    return get().fetchForSelect(getSpecializationsForSelect2, { id, options, params });
  },
  fetchArticlesForSelect: async (id, params = {}, options = {}) => {
    return get().fetchForSelect(getArticlesForSelect2, { id, options, params });
  },
  // Treatment program.
  fetchTPHospitalsForRelations: async (id, params = {}, options = {}) => {
    return await get().fetchForSelect(getTPRHospitalsForSelect, { id, options, params });
  },
  fetchTPDepartmentsForRelations: async (id, params = {}, options = {}) => {
    return await get().fetchForSelect(getTPRDepartmentsForSelect, { id, options, params });
  },
  // Search engine.
  fetchSSHospitalsForRelations: async (id, params = {}, options = {}) => {
    return await get().fetchForSelect(getSSHospitalsForSelect, { id, options, params });
  },
  fetchSSDepartmentsForRelations: async (id, params = {}, options = {}) => {
    return await get().fetchForSelect(getSSDepartmentsForSelect, { id, options, params });
  },
  fetchSSSynonyms: async (id, params = {}, options = {}) => {
    return await get().fetchForSelect(getSearchSynonymsForSelect, { id, options, params });
  },
  fetchCertificatesForSelect: async (id, params = {}, options = {}) => {
    return await get().fetchForSelect(getCertificatesForSelect, { id, options, params });
  },
  fetchTagsForSelect: async (id, params = {}, options = {}) => {
    return await get().fetchForSelect(getTagsForSelect, { id, options, params });
  },
  fetchStatusForSelect: async (id, params = {}, options = {}) => {
    return await get().fetchForSelect(getStatusesForSelect, { id, options, params });
  },
  fetchUserForSelect: async (id, params = {}, options = {}) => {
    return await get().fetchForSelect(getUsersForSelect, { id, options, params });
  },
  fetchPhoneCountriesForSelect: async (id, params = {}, options = {}) => {
    return await get().fetchForSelect(getPhoneCountriesForSelect, { id, options, params });
  },
  fetchOffersMethods: async (id, params = {}, options = {}) => {
    return await get().fetchForSelect(getTreatmentMethodsForSelect, { id, options, params });
  },
  fetchOffersTypesOfCare: async (id, params = {}, options = {}) => {
    return await get().fetchForSelect(getTreatmentTypesForSelect, { id, options, params });
  },
  fetchCurrency: async (id, params = {}, options = {}) => {
    return await get().fetchForSelect(getCurrencyForSelect, { id, options, params });
  },
  fetchCompany: async (id, params = {}, options = {}) => {
    return await get().fetchForSelect(getCompaniesForSelect, { id, options, params });
  },
  fetchVendor: async (id, params = {}, options = {}) => {
    return await get().fetchForSelect(getVendorsUsersForSelect, { id, options, params });
  },
  fetchLanguages: async (id, params = {}, options = {}) => {
    return await get().fetchForSelect(getLanguagesForSelect, { id, options, params });
  },
}));