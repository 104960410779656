import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks';
import { PaginationConfig } from '@components/AntGrid/types';
import { Doctor } from '@modelTypes/doctor';
import { getDoctors } from '@storage/doctors/thunkActions';
import { doctorColumns, doctorFiltersConfig } from '@components/AntGrid/columns/doctor';
import { DoctorsState } from '@storage/doctors/reducer';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeLanguage from '@components/AntGrid/hooks/useChangeLanguage';
import useChangeGridOptions from "@components/AntGrid/hooks/useChangeGridOptions";

export const [
  refreshDoctorsGridEvent,
  dispatchRefreshDoctorsGridEvent
] = makeRefreshGridEvent('onRefreshDoctorsGrid');

export default function DoctorsAntGrid() {
  const dispatch = useDispatch();
  const [languageId, setFirstLoad] = useChangeLanguage((languageId) => dispatch(getDoctors({ languageId })));
  const { data, meta, loading } = useSelector<DoctorsState['grid']>('doctors.grid');

  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
    // ...rest.paginationConfig,
  };

  const onFetchData = (params?: AntGetListParams) => {
    dispatch(getDoctors({ ...params, languageId }) as unknown as Promise<void>)
      .then(() => {
        setFirstLoad(true);
      });
  };

  useRefreshGridEvent(refreshDoctorsGridEvent, onFetchData);
  useChangeGridOptions('doctorsAntGrid');
  useChangeGridOptions('gridOptions')

  return (
    <Grid<Doctor>
      id="doctorsGrid"
      onFetch={onFetchData}
      columns={doctorColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={doctorFiltersConfig}
    />
  );
}