import { useEffect, useRef } from 'react';
import { getHistorySearchParam } from '@helpers/history';
import { useHistory } from 'react-router-dom';

type SetFirstLoad = (status: boolean) => void;

export default function useChangeLanguage(onChange: (languageId: string) => void): [string, SetFirstLoad, boolean] {
  const history = useHistory();
  const firstLoad = useRef<boolean>(false);
  const languageId = getHistorySearchParam(history, 'language-id', 1);
  const setFirstLoad: SetFirstLoad = (status) => firstLoad.current = status;

  useEffect(() => {
    if (firstLoad.current) {
      onChange(languageId);
    }
  }, [firstLoad, languageId]);

  return [languageId, setFirstLoad, firstLoad.current];
}