import { Country } from '../../../modelTypes/country';
import { BaseRow, FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { getIsActiveCell } from '@components/AntGrid/cells/StatusCell';
import { getDateFormattedCell } from '../cells/DateFormattedCell';
import { IconButtonRenderer } from '../renderers/renderers';
import React from 'react';
import ShowCheckIcon from '../cells/ShowCheckIcon';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import { message } from 'antd';
import { map } from 'lodash';
import { deleteCountry } from '@storage/countries/thunkActions';
import { dispatchRefreshCountriesGridEvent } from '@containers/Destinations/Countries/components/CountriesAntGrid';
import { Doctor } from '@modelTypes/doctor';
import useLanguageTabParam from '@components/AntGrid/hooks/useLanguageTabParam';
import getIdCell from '@components/AntGrid/cells/IdCell';

export type CountryColumnsNames = `${keyof Country}Column` | 'hasDescriptionColumn' | 'previewColumn' | 'eyeIconColumn' | 'deleteColumn';

export const countryColumnsDefinitions: Partial<Record<CountryColumnsNames, ColumnType<Country>>> = {
  idColumn: createColumnDefinition<Country>('#', 'id', { width: 80, render: getIdCell }),
  nameColumn: createColumnDefinition<Country>('Name', 'name'),
  urlSlugColumn: createColumnDefinition<Country>('Url Slug', 'urlSlug'),
  codeColumn: createColumnDefinition<Country>('Code', 'code'),
  citiesCountColumn: createColumnDefinition<Country>('Active cities', 'citiesCount'),
  hospitalsCountColumn: createColumnDefinition<Country>('Active hospitals', 'hospitalsCount'),
  isActiveColumn: createColumnDefinition<Country>('Active', 'isActive', { render: getIsActiveCell, width: 80, filtered: false }),
  updatedAtColumn: createColumnDefinition<Country>('Updated At', 'updatedAt', { render: getDateFormattedCell, width: 160 }),
  createdAtColumn: createColumnDefinition<Country>('Created At', 'createdAt', { render: getDateFormattedCell, width: 160 }),
  hasDescriptionColumn: {
    title: 'Has Description',
    width: 110,
    render: getHasDescriptionCell<Country>(),
    sorter: false,
    filtered: false,
  },
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getEditCell<Country>(),
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell<Country>(),
    sorter: false,
    filtered: false,
  }
};

export const countryColumns: any = [
  countryColumnsDefinitions.idColumn,
  countryColumnsDefinitions.nameColumn,
  countryColumnsDefinitions.urlSlugColumn,
  countryColumnsDefinitions.codeColumn,
  countryColumnsDefinitions.citiesCountColumn,
  countryColumnsDefinitions.hospitalsCountColumn,
  countryColumnsDefinitions.updatedAtColumn,
  countryColumnsDefinitions.createdAtColumn,
  countryColumnsDefinitions.hasDescriptionColumn,
  countryColumnsDefinitions.isActiveColumn,
  countryColumnsDefinitions.eyeIconColumn,
  countryColumnsDefinitions.deleteColumn,
];

export const countryFiltersConfig: FiltersConfig = {
  [countryColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [countryColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [countryColumnsDefinitions.urlSlugColumn?.key as string]: { filterType: FilterType.SEARCH },
  [countryColumnsDefinitions.codeColumn?.key as string]: { filterType: FilterType.SEARCH },
  [countryColumnsDefinitions.citiesCountColumn?.key as string]: { filterType: FilterType.SEARCH },
  [countryColumnsDefinitions.hospitalsCountColumn?.key as string]: { filterType: FilterType.SEARCH },
  [countryColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [countryColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function getEditCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    const langParam = useLanguageTabParam();

    return IconButtonRenderer<Doctor>(`/countries/${record.id}/edit${langParam}`);
  };
}

function getHasDescriptionCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <ShowCheckIcon value={record.description.length} />
    );
  };
}

function getDeleteCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <DeleteCell
        record={record}
        title="Delete Country"
        deleteThunkAction={deleteCountry}
        onSuccess={() => {
          dispatchRefreshCountriesGridEvent();
          message.success('The country has been successfully deleted');
        }}
        hasNotRelationsContent={() => <div>Country has no any relations.</div>}
        hasRelationsContent={(data: any) => {
          return (
            <div style={{}}>
              {!!data.relations.hospitals.length && (
                <div>Country is used by next hospital ids ({map(data.relations.hospitals, (i) => i.id).join(', ')})</div>
              )}
              {!!data.relations.cities.length && (
                <div>Country is used by next cities ids ({map(data.relations.cities, (i) => i.id).join(', ')})</div>
              )}
              <div>If you really want to continue, enter your password.</div>
            </div>
          );
        }}
      />
    );
  };
}

// function BlogPreviewCell<T extends BaseRow>({ record }: { record: T }) {
//   const dispatch = useDispatch();
//   const [loading, setLoading] = useState(false);
//   const { type } = useParams<{ type: BlogTypes }>();
//
//   const handleGetAccessToken = () => {
//     setLoading(true);
//
//     dispatch(getAccessKey(record.id) as unknown as Promise<BlogAccessKey>)
//       .then((data) => {
//         const url = `${process.env.REACT_APP_CLIENT_URL}/blogs/${type}/${record.urlSlug}?token=${data.accessKey}`;
//
//         setLoading(false);
//         if (window !== null) {
//           // @ts-ignore
//           window.open(url, '_blank', 'noopener,noreferrer').focus();
//         }
//       });
//   };
//
//   return (
//     <FrontendPreviewCell
//       loading={loading}
//       onGetAccessToken={handleGetAccessToken}
//     />
//   );
// }
//
// function getPreviewCell<T extends BaseRow>() {
//   return (value: any, record: T) => {
//     return (
//       <BlogPreviewCell
//         record={record}
//       />
//     );
//   };
// }
//
