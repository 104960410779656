import { useRef, useState } from 'react';
import cn from 'classnames';
import styles from './ActionsPlugin.module.scss';
import { valueOnly } from '@helpers/className';
import useOnClickOutside from '@hooks/useOnClickOutside';

interface Props {
  editor: any;
}

export default function ActionsPlugin(
  {
    editor
  }: Props
) {
  const [open, setOpen] = useState(false);
  const ref = useRef<any>();

  const onOpenClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setOpen(!open);
  };

  const onActionClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    editor.model.change((writer: any) => {
      writer.insertText('<dl>', editor.model.document.selection.getFirstPosition());
    });
    editor.model.change((writer: any) => {
      writer.insertText('</dl>', editor.model.document.selection.getLastPosition());
    });

    setOpen(false);
  };

  useOnClickOutside(ref, () => setOpen(false));

  return (
    <div className={cn(styles.iconsPlugin, valueOnly(styles, open, 'open'))} ref={ref}>
     <div
       className={styles.trigger}
       onMouseDown={onOpenClick}
     >
       T
     </div>
     {open && (
       <div className={styles.icons}>
         <div onMouseDown={onActionClick} className={styles.item}>
           Definition list
         </div>
       </div>
     )}
    </div>
  );
}
