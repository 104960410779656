import { useEffect, useState } from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { Button, Card, Checkbox, Col, Form, Input, message, Row, Spin, Tooltip } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { CreateUserRequest } from '@api/types';

import { setFormErrors } from '@api/helpers';
import { SaveOutlined } from '@ant-design/icons';
import { useGridStore } from '@zustandStorage/grid/grid';
import QueryString from 'query-string';
import { useUserStore } from '@zustandStorage/user/user';
import SelectUserRole from '@base/Select/SelectUserRole';
import useLoadUserRoles from '@base/Select/hooks/useLoadUserRoles';
import { getSelectValue } from '@helpers/antForm';

export default function UserCreate() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [messageApi, contextHolder] = message.useMessage();
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
  const [form] = Form.useForm<CreateUserRequest>();
  const {
    createUser,
    updateUser,
    fetchUser,
    clearFormData,
    resetGoogleSecret,
    form: { loading, row }
  } = useUserStore();
  const roleS = useLoadUserRoles();

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving user...',
      duration: 0,
    });

    setFormSubmitting(true);
    const fieldValue = form.getFieldsValue()
    const formData = { ...fieldValue, roleId: getSelectValue(fieldValue.roleId) };

    if (id) {
      await updateUser(parseInt(id, 10), formData)
        .then(() => {
          messageApi.destroy('startCreate');
          message.success('The user has been successfully updated');
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          message.error('An error occurred, please try again now')
          setFormSubmitting(false);
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    } else {
      await createUser(formData)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The user has been successfully created');
          history.push(`/users/${data.id}/edit`);
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          message.error('An error occurred, please try again now');
          setFormSubmitting(false);
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    }
  };

  const resetGoogleSecretKey = async () => {
    await resetGoogleSecret(+id)
      .then(() => {
        message.success('The google key is successfully reset');
      })
      .catch(() => {
        message.error('An error occurred, please try again now');
      })
  }

  useEffect(() => {
    if (id) {
      fetchUser(+id)
        .then(() => {
          resetForm()
        })
    }
  }, [id])

  useEffect(() => {
    form.resetFields(['roleId'])
  }, [roleS.data])

  useEffect(() => {
    return () => {
      clearFormData();
    }
  }, [])

  return (
    <>
      <Prompt
        when={!formSubmitting && formChanged}
        message={(location) =>
          `Data not saved!\nAre you sure you want to go to ${location.pathname}?`
        }
      />
      {contextHolder}
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.usersAntGrid || {};
          history.push(`/users?${QueryString.stringify(params)}`)
        }}
        title={`${row ? 'Edit' : 'Create'} User`}
        extra={[
          <Button
            key="save"
            type="primary"
            loading={formSubmitting}
            onClick={() => form.submit()}
          >
            {id ? 'Update' : 'Create'}
          </Button>
        ]}
      />
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          // style={{ marginTop: 32 }}
        >
          <Row gutter={[32, 24]}>
            <Col span={16}>
              <Form.Item
                name="name"
                label="Name"
                messageVariables={{ name: 'Name' }}
                rules={[{ required: true, type: 'string', max: 255 }]}
                initialValue={row?.name}
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                messageVariables={{ name: 'Email' }}
                rules={[{ type: 'email', required: true, max: 255 }]}
                initialValue={row?.email}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                initialValue={row && row?.roleId ? row.roleId : undefined}
                name="roleId"
                label="Select Role"
                valuePropName="defaultValue"
                rules={[{ required: true }]}
              >
                <SelectUserRole
                  options={roleS.data}
                  loading={roleS.loading}
                  onChange={() => null}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                messageVariables={{ name: 'Password' }}
                rules={[{ type: 'string', max: 255, required: !row }]}
              >
                <Input placeholder="Create new password" type="password" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Card title="Statuses" bordered={false} style={{ marginTop: 24 }}>
                <Form.Item
                  name="isTwoFactorAuth"
                  valuePropName="checked"
                  initialValue={row?.isTwoFactorAuth}
                  style={{ margin: 0 }}
                >
                  <Checkbox>Enable 2-fa auth</Checkbox>
                </Form.Item>
                {(id && row?.hasGoogleSecret) && (
                  <Button
                    key="reset"
                    type="primary"
                    loading={formSubmitting}
                    onClick={resetGoogleSecretKey}
                    style={{ background: 'red', marginTop: 24 }}

                  >
                    Reset Google Secret key
                  </Button>
                )}
              </Card>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Tooltip title={id ? 'Update' : 'Create'} placement="topRight">
        <Button
          style={{ position: 'fixed', bottom: 20, right: 16 }}
          onClick={() => form.submit()}
          shape="circle"
          icon={<SaveOutlined />}
          size="large"
        />
      </Tooltip>
    </>
  );
}
