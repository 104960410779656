import { AntGetListParams, CreateFrontendLanguage, GetListParams, ListResponse, UpdateFrontendLanguage } from '@api/types';

import { AxiosRequestConfig } from 'axios';
import { FrontendLanguage } from "@modelTypes/frontendLanguage";
import client from "./client";
import { getRestUri } from '@api/helpers';

const getUri = getRestUri('frontend-translations');
const ROOT_URI = getUri('');

export async function getFrontendLanguages(type: number, params?: AntGetListParams) {
  return client.get<ListResponse<FrontendLanguage>>(ROOT_URI.GET(), { type, ...params });
}

export async function createFrontendLanguages(payload: CreateFrontendLanguage) {
  return client.post<CreateFrontendLanguage, FrontendLanguage>(ROOT_URI.POST(), payload);
}

export async function updateFrontendLanguages(id: number, payload: UpdateFrontendLanguage) {
  return client.patch<UpdateFrontendLanguage, FrontendLanguage>(ROOT_URI.PATCH({ id }), payload);
}

export async function deleteFrontendLanguages(id: number, payload?: AxiosRequestConfig) {
  return client.del(ROOT_URI.DELETE({ id }), payload);
}
