import { User } from './user';

export enum OrderStatuses {
  ALL = 'all',
  MY = 'my',
  ARCHIVED = 'archived',
  PAID = 'paid',
  CLOSED = 'closed',
}

export enum ContactMethods {
  PHONE = 1,
  WHATSAPP = 2,
  TELEGRAM = 3,
  MAIL = 4,
}

export interface Order {
  id: number;
  saleManagerId: number | null;
  doctorId: number | null;
  status: number;
  name: string;
  email: string;
  phone: string;
  description: string;
  url: string;
  contactMethods: Array<ContactMethods>;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  phoneCountry?: PhoneCountry;
  saleManager?: User;
  doctor?: User;
  files?: Array<OrderFile>
}

export interface OrderFile {
  id: number;
  orderId: number;
  name: string;
  type: string;
  subtype: string;
  path: string;
  url: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface PhoneCountry {
  id: number;
  code: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface Status {
  id: number;
  name: string;
}
