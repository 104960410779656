import React from "react";
import Stack from "@mui/material/Stack";

type ProviderContext = readonly [(option: DialogOption) => void, () => void];

const EMPTY_FUNC = () => {};
const AlertContext = React.createContext<ProviderContext>([
  EMPTY_FUNC,
  EMPTY_FUNC
]);
export const useAlert = () => React.useContext(AlertContext);

type DialogParams = {
  children: React.ReactNode;
  open: boolean;
  onClose?: Function;
  onExited?: Function;
};
type DialogOption = Omit<DialogParams, "open">;

interface AlertProviderProps {
  children: React.ReactNode;
}


export default function AlertProvider({ children }: AlertProviderProps) {
  const [alerts, setAlerts] = React.useState<DialogParams[]>([]);
  const createAlert = (option: DialogOption) => {
    const dialog = { ...option, open: true };
    setAlerts((alerts) => [...alerts, dialog]);

    setTimeout(() => {
      setAlerts((dialogs) => dialogs.slice(0, dialogs.length - 1));
    }, 3000);
  };
  const closeAlert = () => {
    setAlerts((alerts) => {
      const latestDialog = alerts.pop();
      if (!latestDialog) return alerts;
      if (latestDialog.onClose) latestDialog.onClose();
      return [...alerts].concat({ ...latestDialog, open: false });
    });
  };
  const contextValue = React.useRef([createAlert, closeAlert] as const);

  return (
    <AlertContext.Provider value={contextValue.current}>
      {children}
      <Stack sx={{ position: "fixed", bottom: 24, right: 24 }} spacing={2}>
        {alerts.map((alert, i) => {
          return (
            <div key={i}>{alert.children}</div>
          );

          // const { onClose, ...dialogParams } = dialog;
          // const handleKill = () => {
          //   if (dialog.onExited) dialog.onExited();
          //   setAlerts((dialogs) => dialogs.slice(0, dialogs.length - 1));
          // };
          //
          // return (
          //   <DialogContainer
          //     key={i}
          //     onClose={closeDialog}
          //     onKill={handleKill}
          //     {...dialogParams}
          //   />
          // );
        })}
      </Stack>
    </AlertContext.Provider>
  );
}
