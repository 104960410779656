import * as React from 'react';

import { Prompt, useHistory, useParams } from 'react-router-dom';
import { Language } from '@modelTypes/language';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSelector } from '@hooks';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin, Tooltip
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import { SaveOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import urlSlug from 'url-slug';
import { isEqual, pick } from 'lodash';
import { setFormErrors } from '@api/helpers';
import { createCity, getCity, updateCity } from '@storage/cities/thunkActions';
import { clearFormData, endPostCityError, endUpdateCityError } from '@storage/cities/actions';
import { City } from '@modelTypes/city';
import Editor from '@components/Editor';
import { CitiesState } from '@storage/cities/reducer';
import { CreateCityRequest } from '@api/types';
import SelectCountry from '@base/Select/SelectCountry';
import { getCountriesForSelect } from '@storage/countries/thunkActions';
import { CountriesState } from '@storage/countries/reducer';
import { useGridStore } from "@zustandStorage/grid/grid";
import QueryString from "query-string";

export default function CityCreate() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { loading, row } = useSelector<CitiesState['form']>('cities.form');
  const { data: countriesData, loading: countriesLoading } = useSelector<CountriesState['selectCountry']>('countries.selectCountry')
  const [messageApi, contextHolder] = message.useMessage();
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);
  const [languageId, setLanguageId] = React.useState<number>(0);
  const [form] = Form.useForm<CreateCityRequest>();

  const onSelectLanguage = (language: Language) => {
    setLanguageId(language.id);

    if (id) {
      dispatch(getCity(parseInt(id, 10), { languageId: language.id }) as unknown as Promise<any>)
        .then((data) => {
          resetForm();
        });
    }
  };

  const handleOnBlur = () => {
    if (isEmpty(form.getFieldValue('urlSlug')) && !isEmpty(form.getFieldValue('name'))) {
      const value = urlSlug(form.getFieldValue('name'));
      form.setFieldValue('urlSlug', value);
    }
  };

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving city...',
      duration: 0,
    });

    setFormSubmitting(true);
    console.log('default, ', 'handleOnFinish --- ', form.getFieldsValue());
    const formData = { ...form.getFieldsValue(), languageId };
    // console.log('default, ', 'handleOnFinish --- ', formData, form.getFieldsValue());
    // formData.countryId = typeof formData.countryId === 'number' ? formData.countryId : (formData.countryId as any)?.id;

    if (id) {
      await dispatch(updateCity(parseInt(id, 10), formData as any) as unknown as Promise<City>)
        .then(() => {
          messageApi.destroy('startCreate');
          message.success('The City has been successfully updated');
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          dispatch(endUpdateCityError());
          setFormSubmitting(false);
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    }
    else {
      await dispatch(createCity(formData as any) as unknown as Promise<City>)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The City has been successfully created');
          history.push(`/cities/${data.id}/edit`);
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          dispatch(endPostCityError());
          setFormSubmitting(false);
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    }
  };

  useEffect(() => {
    resetForm();
  }, [languageId, id]);

  useEffect(() => {
    if (formChanged) {
      const fieldsToCompare = ['description'];

      if (isEqual(pick(row, fieldsToCompare), pick(form.getFieldsValue(), fieldsToCompare))) {
        return setFormChanged(false);
      }
    }
  }, [formChanged]);

  useEffect(() => {
    if (!countriesData.length && !countriesLoading) {
      dispatch(getCountriesForSelect());
    }

    return () => {
      dispatch(clearFormData());
    };
  }, []);

  return (
    <>
      {contextHolder}
      <Prompt
        when={!formSubmitting && formChanged}
        message={(location) =>
          `Data not saved!\nAre you sure you want to go to ${location.pathname}?`
        }
      />
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.citiesAntGrid || {};
          history.push(`/cities?${QueryString.stringify(params)}`)
        }}
        title={`${row ? 'Edit' : 'Create'} City`}
        extra={[
          <SelectLanguagePerForm
            onInit={onSelectLanguage}
            onChange={onSelectLanguage}
            disabled={!row}
          />,
          <Button
            key="save"
            type="primary"
            loading={formSubmitting}
            onClick={() => form.submit()}
          >
            {id ? 'Update' : 'Create'}
          </Button>
        ]}
      />
      <Spin spinning={loading || languageId === 0}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={[32, 24]}>
            <Col span={16}>
              <Form.Item
                name="name"
                label="Name"
                messageVariables={{ name: 'Name' }}
                rules={[{ required: true, type: 'string',  max: 255 }]}
                initialValue={row?.name}
              >
                <Input onBlur={handleOnBlur} />
              </Form.Item>
              <Form.Item
                name="urlSlug"
                label="Url Slug"
                messageVariables={{ name: 'Url Slug' }}
                rules={[{ required: true, type: 'string', max: 128 }]}
                initialValue={row?.urlSlug}
              >
                <Input disabled={languageId > 1} placeholder="Enter url slug" />
              </Form.Item>
              <Form.Item
                name="countryId"
                label="Country"
                initialValue={row?.countryId}
                rules={[{ required: true }]}
                valuePropName="defaultValue"
              >
                <SelectCountry
                  disabled={languageId > 1}
                  loading={countriesLoading}
                  options={countriesData}
                  onChange={(country) => form.setFieldValue('countryId', country.id)}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                initialValue={row ? row.description : ''}
                valuePropName="defaultValue"
              >
                <Editor
                  entityName="cities"
                  onChange={(value) => form.setFieldValue('description', value)}
                  sx={{ mb: 2 }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Card title="Statuses" bordered={false} style={{ marginTop: 24 }}>
                <Form.Item
                  name="isActive"
                  valuePropName="checked"
                  initialValue={row ? row?.isActive : false}
                  style={{ margin: 0 }}
                >
                  <Checkbox disabled={languageId > 1}>Active</Checkbox>
                </Form.Item>
                <Form.Item
                  name="isLanguageActive"
                  valuePropName="checked"
                  initialValue={languageId === 1 ? true : row?.isLanguageActive}
                  style={{ margin: 0 }}
                >
                  <Checkbox disabled={languageId === 1}>Active Language</Checkbox>
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Tooltip title={id ? 'Update' : 'Create'} placement="topRight">
        <Button
          style={{ position: 'fixed', bottom: 20, right: 16 }}
          onClick={() => form.submit()}
          shape="circle"
          icon={<SaveOutlined />}
          size="large"
        />
      </Tooltip>
    </>
  );
}
