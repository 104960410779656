import { City } from '@modelTypes/city';
import { ListResponse } from '@api/types';
import { createAction } from '../helpers';

export enum ActionType {
  START_FETCH_CITIES = 'CITIES__START_FETCH_CITIES',
  END_FETCH_CITIES = 'CITIES__END_FETCH_CITIES',

  START_FETCH_CITIES_FOR_SELECT = 'CITIES__START_FETCH_CITIES_FOR_SELECT',
  END_FETCH_CITIES_FOR_SELECT = 'CITIES__END_FETCH_CITIES_FOR_SELECT',

  START_FETCH_CITY = 'CITIES__START_FETCH_CITY',
  END_FETCH_CITY = 'CITIES__END_FETCH_CITY',

  START_POST_CITY = 'CITIES__START_POST_CITY',
  END_POST_CITY = 'CITIES__END_POST_CITY',
  END_POST_CITY_ERROR = 'CITIES__END_POST_CITY_ERROR',

  START_UPDATE_CITY = 'CITIES__START_UPDATE_CITY',
  END_UPDATE_CITY = 'CITIES__END_UPDATE_CITY',
  END_UPDATE_CITY_ERROR = 'CITIES__END_UPDATE_CITY_ERROR',

  START_DELETE_CITY = 'CITIES__START_DELETE_CITY',
  END_DELETE_CITY = 'CITIES__END_DELETE_CITY',

  CLEAR_FORM_DATA = 'CITIES__CLEAR_FORM_DATA',
}

export const startFetchCities = createAction<ActionType.START_FETCH_CITIES>(ActionType.START_FETCH_CITIES);
export const endFetchCities = createAction<ActionType.END_FETCH_CITIES, ListResponse<City>>(ActionType.END_FETCH_CITIES);

export const startFetchCitiesForSelect = createAction<ActionType.START_FETCH_CITIES_FOR_SELECT, number>(ActionType.START_FETCH_CITIES_FOR_SELECT);
export const endFetchCitiesForSelect = createAction<ActionType.END_FETCH_CITIES_FOR_SELECT, ListResponse<City>>(ActionType.END_FETCH_CITIES_FOR_SELECT);

export const startFetchCity = createAction<ActionType.START_FETCH_CITY>(ActionType.START_FETCH_CITY);
export const endFetchCity = createAction<ActionType.END_FETCH_CITY, City>(ActionType.END_FETCH_CITY);

export const startPostCity = createAction<ActionType.START_POST_CITY>(ActionType.START_POST_CITY);
export const endPostCity = createAction<ActionType.END_POST_CITY, City>(ActionType.END_POST_CITY);
export const endPostCityError = createAction<ActionType.END_POST_CITY_ERROR>(ActionType.END_POST_CITY_ERROR);

export const startUpdateCity = createAction<ActionType.START_UPDATE_CITY>(ActionType.START_UPDATE_CITY);
export const endUpdateCity = createAction<ActionType.END_UPDATE_CITY, City>(ActionType.END_UPDATE_CITY);
export const endUpdateCityError = createAction<ActionType.END_UPDATE_CITY_ERROR>(ActionType.END_UPDATE_CITY_ERROR);

export const startDeleteCity = createAction<ActionType.START_DELETE_CITY>(ActionType.START_DELETE_CITY);
export const endDeleteCity = createAction<ActionType.END_DELETE_CITY>(ActionType.END_DELETE_CITY);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

export type Actions =
  | ReturnType<typeof startFetchCities>
  | ReturnType<typeof endFetchCities>
  | ReturnType<typeof startFetchCitiesForSelect>
  | ReturnType<typeof endFetchCitiesForSelect>
  | ReturnType<typeof startFetchCity>
  | ReturnType<typeof endFetchCity>
  | ReturnType<typeof startPostCity>
  | ReturnType<typeof endPostCity>
  | ReturnType<typeof startUpdateCity>
  | ReturnType<typeof endUpdateCity>
  | ReturnType<typeof endUpdateCityError>
  | ReturnType<typeof startDeleteCity>
  | ReturnType<typeof endDeleteCity>
  | ReturnType<typeof clearFormData>;