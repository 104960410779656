import { ListResponse } from '@api/types';
import { TreatmentFolder } from '@modelTypes/treatment';
import { createAction } from '@storage/helpers';

export enum ActionType {
  START_FETCH_FOLDERS = 'FOLDERS__START_FETCH_FOLDERS',
  END_FETCH_FOLDERS = 'FOLDERS__END_FETCH_FOLDERS',

  START_FETCH_FOLDERS_FOR_SELECT = 'FOLDERS__START_FETCH_FOLDERS_FOR_SELECT',
  END_FETCH_FOLDERS_FOR_SELECT = 'FOLDERS__END_FETCH_FOLDERS_FOR_SELECT',

  START_FETCH_FOLDER = 'FOLDERS__START_FETCH_FOLDER',
  END_FETCH_FOLDER = 'FOLDERS__END_FETCH_FOLDER',

  START_POST_FOLDER = 'FOLDERS__START_POST_FOLDER',
  END_POST_FOLDER = 'FOLDERS__END_POST_FOLDER',
  END_POST_FOLDER_ERROR = 'FOLDERS__END_POST_FOLDER_ERROR',

  START_PATCH_FOLDER = 'FOLDERS__START_PATCH_FOLDER',
  END_PATCH_FOLDER = 'FOLDERS__END_PATCH_FOLDER',
  END_PATCH_FOLDER_ERROR = 'FOLDERS__END_PATCH_FOLDER_ERROR',

  START_DELETE_FOLDER = 'FOLDERS__START_DELETE_FOLDER',
  END_DELETE_FOLDER = 'FOLDERS__END_DELETE_FOLDER',

  CLEAR_FORM_DATA = 'FOLDERS__CLEAR_FORM_DATA',
}

export const startFetchFolders = createAction<ActionType.START_FETCH_FOLDERS>(ActionType.START_FETCH_FOLDERS);
export const endFetchFolders = createAction<ActionType.END_FETCH_FOLDERS, ListResponse<TreatmentFolder>>(ActionType.END_FETCH_FOLDERS);

export const startFetchFoldersForSelect = createAction<ActionType.START_FETCH_FOLDERS_FOR_SELECT>(ActionType.START_FETCH_FOLDERS_FOR_SELECT);
export const endFetchFoldersForSelect = createAction<ActionType.END_FETCH_FOLDERS_FOR_SELECT, ListResponse<TreatmentFolder>>(ActionType.END_FETCH_FOLDERS_FOR_SELECT);

export const startFetchFolder = createAction<ActionType.START_FETCH_FOLDER>(ActionType.START_FETCH_FOLDER);
export const endFetchFolder = createAction<ActionType.END_FETCH_FOLDER, TreatmentFolder>(ActionType.END_FETCH_FOLDER);

export const startPostFolder = createAction<ActionType.START_POST_FOLDER>(ActionType.START_POST_FOLDER);
export const endPostFolder = createAction<ActionType.END_POST_FOLDER, TreatmentFolder>(ActionType.END_POST_FOLDER);
export const endPostFolderError = createAction<ActionType.END_POST_FOLDER_ERROR>(ActionType.END_POST_FOLDER_ERROR);

export const startUpdateFolder = createAction<ActionType.START_PATCH_FOLDER>(ActionType.START_PATCH_FOLDER);
export const endUpdateFolder = createAction<ActionType.END_PATCH_FOLDER, TreatmentFolder>(ActionType.END_PATCH_FOLDER);
export const endUpdateFolderError = createAction<ActionType.END_PATCH_FOLDER_ERROR>(ActionType.END_PATCH_FOLDER_ERROR);

export const startDeleteFolder = createAction<ActionType.START_DELETE_FOLDER>(ActionType.START_DELETE_FOLDER);
export const endDeleteFolder = createAction<ActionType.END_DELETE_FOLDER>(ActionType.END_DELETE_FOLDER);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

export type Actions =
  | ReturnType<typeof startFetchFolders>
  | ReturnType<typeof endFetchFolders>
  | ReturnType<typeof startFetchFoldersForSelect>
  | ReturnType<typeof endFetchFoldersForSelect>
  | ReturnType<typeof startFetchFolder>
  | ReturnType<typeof endFetchFolder>
  | ReturnType<typeof startPostFolder>
  | ReturnType<typeof endPostFolder>
  | ReturnType<typeof endPostFolderError>
  | ReturnType<typeof startUpdateFolder>
  | ReturnType<typeof endUpdateFolder>
  | ReturnType<typeof endUpdateFolderError>
  | ReturnType<typeof startDeleteFolder>
  | ReturnType<typeof endDeleteFolder>
  | ReturnType<typeof clearFormData>;