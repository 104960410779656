import * as React from 'react';
import { useEffect, useState } from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { Avatar, Button, Card, Col, Form, Input, message, Row, Spin, Tooltip } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { CreateVendorCompanyRequest } from '@api/types';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { useGridStore } from '@zustandStorage/grid/grid';
import QueryString from 'query-string';
import { prepareFormDataForBackend } from '@helpers/antForm';
import { Vendors } from '@modelTypes/vendor';
import { setFormErrors } from '@api/helpers';
import { useVendorCompanyStore } from '@zustandStorage/vendor/company';
import TextArea from 'antd/es/input/TextArea';
import styles from '@containers/Doctors/FormSteps/MainDataStep/MainDataStep.module.scss';
import UploadPhotoModal from '@components/UploadPhotoModal/UploadPhotoButton';


interface RouterParams {
  seller: Vendors;
}

export default function CompanyForm() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const {
    form: { loading, row },
    fetchCompany,
    createCompany,
    updateCompany,
    clearFormData,
  } = useVendorCompanyStore()
  const [messageApi, contextHolder] = message.useMessage();
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);
  const [form] = Form.useForm<CreateVendorCompanyRequest>();
  const { seller } = useParams<RouterParams>();
  const [imgPreview, setImgPreview] = useState<string>('');

  const onPhotoSave = async (data: Cropper, type: string) => {
    data.getCroppedCanvas().toBlob(
      (blob: Blob | null) => {
        if (!blob) {
          return;
        }
        
        // await formik.setFieldValue('photoToSave', blob);
        form.setFields([
          {
            name: 'photoToSave',
            value: blob,
          }
        ]);

        setImgPreview(URL.createObjectURL(blob));

        if (id) {
          form.submit();
        }
      },
      type,
      type === 'image/jpeg' || type === 'image/jpg' ? .95 : undefined
    );
  };

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving vendor...',
      duration: 0,
    });

    setFormSubmitting(true);
    // const data = form.getFieldsValue(true);
    // const formData = {
    //   ...data,
    //   companyId: getSelectValue(data.companyId),
    // };
    const formData = prepareFormDataForBackend(form.getFieldsValue(true), );

    if (id) {
      await updateCompany(parseInt(id, 10), formData as any)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The Organization has been successfully updated');
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          message.error('An error occurred, please try again now')
          setFormSubmitting(false);
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    } else {
      await createCompany(formData as any)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The Organization has been successfully created');
          history.push(`/vendors/company/${data.id}/edit`);
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          message.error('An error occurred, please try again now')
          setFormSubmitting(false);
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    }
  };

  useEffect(() => {
    resetForm();
  }, [id]);

  useEffect(() => {

    return () => {
      clearFormData();
    };
  }, []);

  useEffect(() => {
    if (row && row.imgPath) {
      setImgPreview(row.imgPath);
    }
  }, [row]);

  useEffect(() => {
    if (id) {
      fetchCompany(parseInt(id, 10))
        .then((data) => {
          resetForm();
        });
    }
  }, [id]);

  return (
    <>
      <Prompt
        when={!formSubmitting && formChanged}
        message={(location) =>
          `Data not saved!\nAre you sure you want to go to ${location.pathname}?`
        }
      />
      {contextHolder}
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.vendorsCompanyAntGrid || {};

          history.push(`/vendors/${seller}?${QueryString.stringify(params)}`)
        }}
        title={`${row ? 'Edit' : 'Create'} organization`}
        extra={[
          <Button
            key="save"
            type="primary"
            loading={formSubmitting}
            onClick={() => form.submit()}
          >
            {id ? 'Update' : 'Create'}
          </Button>
        ]}
      />
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={[32, 24]}>
            <Col span={16}>
              <Form.Item
                name="name"
                label="Company Name"
                messageVariables={{ name: 'Company Name' }}
                rules={[{ type: 'string', max: 254, required: true }]}
                initialValue={row?.name}
              >
                <Input placeholder="Input company name" />
              </Form.Item>
              <Form.Item
                name="orgName"
                label="Orgname"
                messageVariables={{ name: 'Orgname' }}
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: 'string', min: 3,
                    message: 'Orgname must be at least 3 characters'
                  },
                  {
                    type: 'string',
                    max: 30, message: 'Orgname cannot exceed 30 characters'
                  },
                  {
                    pattern: /^[a-zA-Z][a-zA-Z0-9.-_]*$/,
                    message: 'Please enter a valid Orgname. Example: company123, company.name, company-name12345',
                  },
                ]}
                initialValue={row?.orgName}
              >
                <Input placeholder="Input Orgname" />
              </Form.Item>
              <Form.Item
                name="description"
                label="About company"
                initialValue={row?.description}
              >
                <TextArea
                  placeholder="About company"
                  autoSize={{ minRows: 3, maxRows: 8 }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Card title="Organization logo" bordered={false}>
                <div className={styles.photoPreview}>
                  <div>
                    <Form.Item
                      name="photoToSave"
                      style={{ display: 'none' }}
                      initialValue=""
                    >
                      <Input style={{ display: 'none' }} />
                    </Form.Item>
                    <Avatar
                      className={styles.avatar}
                      src={imgPreview}
                    />
                  </div>
                  <div className={styles.actions}>
                    {!!imgPreview.length && (
                      <Tooltip title="Delete item">
                        <Button
                          danger
                          type="primary"
                          // shape="round"
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            form.setFieldValue('imgPath', false);
                            setImgPreview('');
                          }}
                          style={{ marginRight: 8 }}
                        />
                      </Tooltip>
                    )}
                    <UploadPhotoModal
                      handleSaveImage={onPhotoSave}
                      uploadButtonProps={
                        {
                          style: { width: 32, height: 32 },
                          type: 'primary',
                        }
                      }
                      cropperProps={
                        {
                          initialAspectRatio: 1,
                          viewMode: 0,
                          minCropBoxHeight: 10,
                          minCropBoxWidth: 10,
                          responsive: true,
                          aspectRatio: 1,
                          guides: true,
                        }
                      }
                      modalClassName={styles.cropperContainer}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Tooltip title={id ? 'Update' : 'Create'} placement="topRight">
        <Button
          style={{ position: 'fixed', bottom: 20, right: 16 }}
          onClick={() => form.submit()}
          shape="circle"
          icon={<SaveOutlined />}
          size="large"
        />
      </Tooltip>
    </>
  );
}
