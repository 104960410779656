import { Card, Divider, Image, List, message, Skeleton, Spin, Typography } from 'antd';

import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useEffect } from 'react';
import { useGalleriesDataStore } from '@zustandStorage/galleries/galleries';
import { useParams } from 'react-router-dom';
import MainImagesList from '@components/Gallery/components/MainImagesList/MainImagesList';
import { DescriptionImage } from '@components/Gallery/components/DescriptionImage';
import { CustomDeleteCell } from '@components/Gallery/components/CustomDeleteCell';
import { EditCell } from '@components/Gallery/components/EditCell';

interface GalleryGridProps {
  entityGallery: string;
  hasMainImages?: boolean;
}

export default function GalleryGrid({ entityGallery, hasMainImages }: GalleryGridProps) {
  const { id } = useParams<{ id: string }>();
  const {
    clearGridData,
    showMoreGalleriesItem,
    fetchGalleriesItem,
    grid: { loading, data, meta, mainItem },
  } = useGalleriesDataStore();

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    showMoreGalleriesItem({ [entityGallery]: +id, page: meta.currentPage + 1 }).catch((e: any) => {
      message.error('An error occurred, please try again now');
    });
  };

  useEffect(() => {
    fetchGalleriesItem({ [entityGallery]: +id });

    return () => clearGridData();
  }, []);

  return (
    <InfiniteScroll
      style={{ overflow: 'initial' }}
      dataLength={data.length}
      next={loadMoreData}
      hasMore={meta.nextPageUrl !== null}
      loader={<Skeleton avatar active />}
      endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
      scrollableTarget="scrollableDiv"
    >
      <Spin spinning={loading}>
        {hasMainImages && (
          <>
            <Divider orientation="left">Main images</Divider>
            <MainImagesList entityGallery={entityGallery} />
            <Divider orientation="left">All images</Divider>
          </>
        )}
        <List
          grid={{ gutter: 16, column: 3, xl: 4, xxl: 6 }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Card
                bodyStyle={{ padding: '16px 16px 0 16px', marginBottom: 'auto', height: 120 }}
                actions={[
                  <EditCell record={item} key="editButton" entityGallery={entityGallery} />,
                  <CustomDeleteCell record={item} key="deleteButton" entityGallery={entityGallery} />,
                ]}
                cover={
                  {
                    0: (
                      <Image
                        width={'100%'}
                        src={item.path}
                        style={{
                          aspectRatio: '16/9',
                          objectFit: 'contain',
                          background: 'black',
                        }}
                      />
                    ),
                    1: (
                      <iframe
                        style={{ width: '100%', aspectRatio: '16/9' }}
                        src={`https://www.youtube.com/embed/${item.path}`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      />
                    ),
                    2: <video style={{ width: '100%', aspectRatio: '16/9' }} src={item.path} controls />,
                  }[item.entityType]
                }
              >
                <DescriptionImage record={item} hasMainImages={hasMainImages} />
              </Card>
            </List.Item>
          )}
        />
      </Spin>
    </InfiniteScroll>
  );
}
