import { ActionType, Actions } from './actions';
import { Hospital, HospitalForSelect, HospitalTranslation } from '@modelTypes/hospital';

import { ListResponse } from '@api/types';

export interface HospitalsState {
  readonly grid: ListResponse<Hospital> & { readonly loading: boolean };
  readonly select: ListResponse<HospitalForSelect> & { readonly fetched: boolean; readonly loading: boolean };
  readonly form: {
    loading: boolean,
    row: Hospital | null;
  };
  readonly translations: {
    grid: ListResponse<HospitalTranslation> & { readonly loading: boolean };
    form: {
      loading: boolean;
      rows: Array<HospitalTranslation>;
    },
  }
}

const initialState: HospitalsState = {
  grid: {
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  select: {
    fetched: false,
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 5000,
    },
  },
  form: {
    loading: false,
    row: null,
  },
  translations: {
    grid: {
      loading: false,
      data: [],
      meta: {
        currentPage: 1,
        perPage: 25,
      },
    },
    form: {
      loading: false,
      rows: [],
    },
  }
};

export default function user(state = initialState, action: Actions): HospitalsState {
  switch (action.type) {
    case ActionType.START_FETCH_HOSPITALS:
    // case ActionType.START_DELETE_HOSPITAL:
      return {
        ...state,
        grid: {
          ...state.grid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_HOSPITALS:
      return {
        ...state,
        grid: {
          ...action.data,
          loading: false,
        },
      }
    case ActionType.START_FETCH_HOSPITALS_FOR_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          fetched: false,
          loading: true,
        }
      }
    case ActionType.END_FETCH_HOSPITALS_FOR_SELECT:
      return {
        ...state,
        select: {
          ...action.data,
          fetched: true,
          loading: false,
        },
      }
    case ActionType.START_FETCH_HOSPITAL:
    case ActionType.START_POST_HOSPITAL:
    case ActionType.START_PATCH_HOSPITAL:
    case ActionType.START_UPLOAD_HOSPITAL_IMAGES:
    case ActionType.START_FETCH_HOSPITAL_ACCESS_KEY:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        }
      }
    case ActionType.END_FETCH_HOSPITAL:
    case ActionType.END_PATCH_HOSPITAL:
    case ActionType.END_POST_HOSPITAL:
      return {
        ...state,
        form: {
          ...state.form,
          row: action.data,
          loading: false,
        },
      }
    case ActionType.END_UPLOAD_HOSPITAL_IMAGES:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        }
      }
    case ActionType.END_POST_HOSPITAL_ERROR:
    case ActionType.END_PATCH_HOSPITAL_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.CLEAR_FORM_DATA:
      return {
        ...state,
        form: {
          ...initialState.form,
        }
      }
    case ActionType.START_FETCH_TRANSLATIONS:
      return {
        ...state,
        translations: {
          ...state.translations,
          grid: {
            ...state.translations.grid,
            loading: true,
          }
        }
      }
    case ActionType.END_FETCH_TRANSLATIONS:
      return {
        ...state,
        translations: {
          ...state.translations,
          grid: {
            ...action.data,
            loading: false,
          }
        }
      }
    case ActionType.START_FETCH_TRANSLATION:
    case ActionType.START_PATCH_TRANSLATION:
    case ActionType.END_PATCH_TRANSLATION_ERROR:
      return {
        ...state,
        translations: {
          ...state.translations,
          form: {
            ...state.translations.form,
            loading: true,
          }
        }
      }
    case ActionType.END_FETCH_TRANSLATION:
    case ActionType.END_PATCH_TRANSLATION:
      return {
        ...state,
        translations: {
          ...state.translations,
          form: {
            ...state.translations.form,
            rows: action.data,
            loading: false,
          }
        }
      }
    default:
      return state;
  }
}