import { GalleriesItem } from '@modelTypes/galleries';
import { useGalleriesDataStore } from '@zustandStorage/galleries/galleries';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import React, { ChangeEvent } from 'react';
import { Checkbox, Input, Popover, Space, Typography } from 'antd';
import { CheckCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import styles from '@components/Gallery/GalleryGrid.module.scss';

const { Title } = Typography;

interface Props {
  record: GalleriesItem;
  mainImageBlock?: boolean;
  hasMainImages?: boolean;
}

export function DescriptionImage({ record, mainImageBlock, hasMainImages }: Props) {
  const { status, rowId, form } = useGalleriesDataStore((state) => state.grid.editable);
  const { setGridEditable, grid: { mainItem } } = useGalleriesDataStore();

  const onChangeCheckbox = (checkedValues: CheckboxValueType[]) => {
    setGridEditable(
      {
        status,
        rowId,
        form: {
          ...form,
          isSensitive: checkedValues.includes('isSensitive'),
          isShowInTop: checkedValues.includes('isShowInTop'),
        }
      });
  }

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setGridEditable({ status, rowId, form: { ...form, name: e.target.value } });
  }

  if (rowId === record.id && status) {
    return (
      <div>
        <Title
          level={5}
        >
          <Input
            onChange={onChangeInput}
            maxLength={100}
            defaultValue={record.name}
          />
        </Title>
        <Checkbox.Group
          style={{ width: '100%' }}
          onChange={onChangeCheckbox}
          defaultValue={[
            record.isSensitive ? 'isSensitive' : '',
            record.isShowInTop ? 'isShowInTop' : ''
          ]}
        >
          <Space direction={'vertical'}>
            <Space>
              <Checkbox
                defaultChecked={record.isSensitive}
                value="isSensitive"
                // onChange={onChangeCheckbox}
              />
              Sensitive content
            </Space>
            {(!record.entityType && hasMainImages) && (
              <Space>
                <Checkbox
                  defaultChecked={record.isShowInTop}
                  value="isShowInTop"
                  disabled={mainItem.length >= 5 && !mainImageBlock}
                />
                Main image
                <Popover content={<div>You can choose only 5 images</div>}>
                  <InfoCircleTwoTone />
                </Popover>
              </Space>
            )}
          </Space>
        </Checkbox.Group>
      </div>
    )
  }

  return (
    <div>
      <Title
        level={5}
        className={styles.title}
      >
        {record.name}
      </Title>
      <Space direction={'vertical'}>
        <Space>
          {record.isSensitive && (
            <>
              <CheckCircleOutlined
                style={{ fontSize: 16, color: '#1f8f27' }}
              />
              <span>
            Sensitive content
        </span>
            </>
          )}
        </Space>
        {/*<Space>*/}
        {/*  {record.isShowInTop && (*/}
        {/*    <>*/}
        {/*      <CheckCircleOutlined*/}
        {/*        style={{ fontSize: 16, color: '#1f8f27' }}*/}
        {/*      />*/}
        {/*      <span>*/}
        {/*    Main image*/}
        {/*</span>*/}
        {/*    </>*/}
        {/*  )}*/}
        {/*</Space>*/}
      </Space>
    </div>
  )
}
