import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import CountriesAntGrid from './components/CountriesAntGrid';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import * as React from 'react';

export default function CountriesIndex(): JSX.Element {
  const history = useHistory();

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.push("/")}
        title="Countries"
        extra={[
          <SelectLanguagePerForm
            key="selectLanguage"
            updateParamKey={false}
            queryParamKey="language-id"
          />,
          <Button
            key="createCountry"
            type="primary"
            href="/countries/create"
            onClick={(e) => {
              e.preventDefault();

              history.push('/countries/create');
            }}
          >
            Add Country
          </Button>
        ]}
      />
      <CountriesAntGrid />
    </>
  );
}