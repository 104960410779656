import { useHistory, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import { indexPageTitles, indexPageUri } from './../../helpers';
import { Button, message } from 'antd';
import CreateLinkMenuModal from '@base/Modal/CreateLinkMenuModal/CreateLinkMenuModal';
import { useEffect, useState } from 'react';
import { useMenuStore } from '@zustandStorage/menu/menu';
import { Language } from '@modelTypes/language';
import { getHistorySearchParam, updateQueryParams } from '@helpers/history';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import ContentsMenuItemsGrid, { dispatchRefreshContentsMenuItemsGridEvent } from '@containers/Contents/components/ContentsMenuItemsGrid';

interface RouterParams {
  id: string;
  page: string;
}

export default function MenuItemsIndex(): JSX.Element {
  const history = useHistory();
  const { page, id } = useParams<RouterParams>();
  const [messageApi, contextHolder] = message.useMessage();
  const languageId = getHistorySearchParam(history, 'language-id', 1);
  const [linkMenuModalOpen, setLinkMenuModalOpen] = useState<boolean>(false);
  const { fetchMenuItem, createMenuSubItem, form, subMenuForm, clearFormData, clearSubMenuFormData } = useMenuStore();

  const onSelectLanguage = (language: Language) => {
    updateQueryParams(history, {
      ['language-id']: language.id > 1 ? language.id : null,
    });
  };

  const onFinish = async (payload: any) => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Creating link...',
      duration: 0,
    });

    await createMenuSubItem(+id, payload)
      .then((data) => {
        messageApi.destroy('startCreate');
        message.success('The link has been successfully created');
        setLinkMenuModalOpen(false);
        dispatchRefreshContentsMenuItemsGridEvent();
      })
      .catch(() => {
        message.error('An error occurred, please try again now')
      })
  };

  useEffect(() => {
    if (id) {
      fetchMenuItem(parseInt(id, 10), { languageId });
    }

    return () => {
      clearFormData();
      clearSubMenuFormData();
    };
  }, [id, languageId]);

  return (
    <>
      {contextHolder}
      <PageHeader
        ghost={true}
        onBack={() => history.push(history.location.pathname.split('/items')[0])}
        title={`${indexPageTitles[indexPageUri[page]]} - ${form.loading ? 'Loading...' : form.row?.title}`}
        extra={[
          <SelectLanguagePerForm
            key="selectLanguage"
            updateParamKey={false}
            queryParamKey="language-id"
            onInit={onSelectLanguage}
            onChange={onSelectLanguage}
          />,
          <Button
            key="createContent"
            type="primary"
            onClick={()=> setLinkMenuModalOpen(true)}
          >
            Add link
          </Button>,
        ]}
      />
      <ContentsMenuItemsGrid pageName={indexPageUri[page]} />
      <CreateLinkMenuModal
        open={linkMenuModalOpen}
        loading={subMenuForm.loading}
        onFinish={onFinish}
        onCancel={() => setLinkMenuModalOpen(false)}
      />
    </>
  );
}
