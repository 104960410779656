export default function compareObjects(a: { [key: string]: any }, b: { [key: string]: any }) {
  const aKeys = Object.keys(a);

  if (aKeys.length !== Object.keys(b).length) {
    return false;
  }

  for (let key of aKeys) {
    if (a[key] === b[key]) {
      continue;
    }

    if (typeof a[key] !== 'object' || typeof b[key] !== 'object') {
      return false;
    }

    if (compareObjects(a[key], b[key])) {
      continue;
    }

    return false;
  }

  return true;
}
