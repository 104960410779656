import { BellOutlined } from '@ant-design/icons';
import { useProcessStore } from '@zustandStorage/auth/process';
import { Badge, Button } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Process } from '.';
import { useSelector } from '@hooks';
import { AppState } from '@storage/app/reducer';

export default function ProcessNotification(): JSX.Element | null {
  const { getProcesses } = useProcessStore();
  const [showDot, setShowDot] = useState(false);
  const user = useSelector<AppState['user']>('app.user');

  const fetchProcesses = async () => {
    const data = (await getProcesses()) as Process[];

    if (!data.length) {
      return;
    }

    setShowDot(true);

    const audio = new Audio(user?.id === 10 ? '/user10notification.mp3' : '/notification.mp3');
    audio.play();
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    setTimeout(() => {
      fetchProcesses();
      setInterval(fetchProcesses, 30000);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!user]);

  return (
    <Link to="/processes">
      <Badge dot={showDot}>
        <Button onClick={() => setShowDot(false)} shape="circle" icon={<BellOutlined />} />
      </Badge>
    </Link>
  );
}
