import FormContainer from './components/FormContainer';
import EnterQrCodeForm from '@containers/Auth/components/LoginForm/EnterQrCodeForm';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuthStore } from '@zustandStorage/auth/auth';

export default function EnterGACode() {
  const history = useHistory();
  const loginForm = useAuthStore((st) => st.loginForm);

  useEffect(() => {
    if (!loginForm.email || !loginForm.password) {
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginForm]);

  return (
    <FormContainer
      title="2FA"
    >
      <EnterQrCodeForm />
    </FormContainer>
  );
}
