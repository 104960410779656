import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { PaginationConfig } from '@components/AntGrid/types';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { getHistorySearchParam } from '@helpers/history';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeGridOptions from '@components/AntGrid/hooks/useChangeGridOptions';
import { useSettingStore } from '@zustandStorage/settings/settings/settings';
import { settingColumns, settingFiltersConfig } from '@components/AntGrid/columns/settings';
import { Setting } from '@modelTypes/setting';

export const [
  refreshSettingsGridEvent,
  dispatchRefreshSettingsGridEvent
] = makeRefreshGridEvent('onRefreshSettingsGrid');

export default function SettingsGrid() {
  const history = useHistory();
  const { meta, data, loading } = useSettingStore((state) => state.grid);
  const { fetchSettings, clearGridData } = useSettingStore();
  const languageId = getHistorySearchParam(history, 'language-id', 1);
  const firstLoad = useRef<boolean>(false);
  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
  };
  
  const onFetchData = (params?: AntGetListParams) => {
    fetchSettings(params)
      .then(() => {
        firstLoad.current = true;
      });
  };

  useEffect(() => {
    if (firstLoad.current) {
      fetchSettings({ languageId });
    }
  }, [firstLoad, languageId]);

  useEffect(() => {
    return () => {
      clearGridData();
    };
  }, []);

  useRefreshGridEvent(refreshSettingsGridEvent, onFetchData);
  useChangeGridOptions('settingsGrid');

  return (
    <Grid<Setting>
      id="settingsGrid"
      onFetch={onFetchData}
      columns={settingColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={settingFiltersConfig}
    />
  );
}