import { Box, useMediaQuery } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';

import AlertProvider from '@hooks/Alert/useAlert';
import { CssBaseline } from '@mui/material';
import { DashboardNavbar } from '@layouts/AppLayout/Navbar';
// import { DashboardNavbar } from './dashboard-navbar';
import DashboardSidebar from './Sidebar';
import DialogProvider from '@hooks/useDialog';
import ForbiddenModal from '@base/ForbiddenModal';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { theme } from '@layouts/theme';
import 'antd/dist/reset.css';

interface Props {
  children: ReactNode,
}

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up(1920)]: {
    paddingLeft: 250
  }
}));

export default function AppLayout({
 children
}: Props): JSX.Element {
  // @ts-ignore
  // const lgUp = useMediaQuery((theme) => theme.breakpoints.up(1920), {
  //   defaultMatches: true,
  //   noSsr: true
  // });
  //
  // console.log('default, ', 'AppLayout --- ', lgUp);
  const [isSidebarOpen, setSidebarOpen] = useState(false);


  const handleOnClose = (e: any, r: any) => {
    console.log('default, ', 'handleOnClose --- ', e, r);
  };

  const onMouseMove = (e: MouseEvent) => {
    // console.log('default, ', 'onMouseMove --- ', e.clientX, e.clientY, e.pageX, e.pageY);
    if (e.clientX === 0) {
      setSidebarOpen(true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove)

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DashboardLayoutRoot>
          <Box
            sx={{
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
              width: '100%'
            }}
          >
            <DialogProvider>
              <AlertProvider>
                {children}
              </AlertProvider>
            </DialogProvider>
          </Box>
        </DashboardLayoutRoot>
        <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} />
        <DashboardSidebar
          onClose={() => setSidebarOpen(false)}
          open={isSidebarOpen}
        />
        <ForbiddenModal />
        {/*<Stack sx={{ position: "absolute", bottom: 24, right: 24 }} spacing={2}>*/}
        {/*  <Alert severity="success" sx={{ width: '100%' }}>*/}
        {/*    This is a success message!*/}
        {/*  </Alert>*/}
        {/*  <Alert severity="success" sx={{ width: '100%' }}>*/}
        {/*    This is a success message!*/}
        {/*  </Alert>*/}
        {/*</Stack>*/}
      </ThemeProvider>
    </>
  );
}