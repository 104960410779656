import {
  AntGetListParams,
  CreateSearchSectionRelationRequest,
  CreateSearchSectionRequest,
  GetListParams,
  ImportSearchSectionRelationsRequest,
  Params,
  UpdateSearchSectionRequest,
} from '@api/types';
import {
  endDeleteExcludedHospital,
  endFetchAllRelations,
  endFetchDepartmentsForSelect,
  endFetchExcludedHospitals,
  endFetchExcludedHospitalsForSelect,
  endFetchHospitalsForSelect,
  endFetchParentSearchSection,
  endFetchRelations,
  endFetchSearchSection,
  endFetchSearchSections,
  endFetchSearchSectionsForSelect,
  endFetchSearchSectionsForTreeSelect,
  endPostExcludedHospitals,
  endPostSearchSection,
  endUpdateSearchSection,
  startDeleteExcludedHospital,
  startDeleteSearchSection,
  startFetchAllRelations,
  startFetchDepartmentsForSelect,
  startFetchExcludedHospitals,
  startFetchExcludedHospitalsForSelect,
  startFetchHospitalsForSelect,
  startFetchParentSearchSection,
  startFetchRelations,
  startFetchSearchSection,
  startFetchSearchSections,
  startFetchSearchSectionsForSelect,
  startFetchSearchSectionsForTreeSelect,
  startImportExcludedHospitals,
  startImportRelations,
  startPostExcludedHospitals,
  startPostSearchSection,
  startUpdateSearchSection,
  startUpdateSearchSectionTranslation,
} from './actions';

import { AxiosRequestConfig } from 'axios';
import { FilterSearchSections } from '@api/searchSectionApi';
// import { Filters } from '@containers/SearchEngine/components/RelatedHospitals/RelatedHospitalsFilters';
import { GridLinkOperator } from '@mui/x-data-grid';
import { SearchSection } from '@modelTypes/searchSection';
import { ThunkAction } from '@storage/types';
import api from '@api';
import { uniq } from 'lodash';

export function getSearchSections(filter: FilterSearchSections, params?: AntGetListParams): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchSearchSections());

    return api.getSearchSections(filter, params)
      .then((data) => {
        dispatch(endFetchSearchSections(data));
      });
  };
}

export function getSearchSectionRelationsForSelect(): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    const params: GetListParams & Params = { page: 1, perPage: 5000 };

    dispatch(startFetchSearchSectionsForSelect());

    return api.getSearchSectionRelationsForSelect(params)
      .then((data) => {
        dispatch(endFetchSearchSectionsForSelect({ data: data.data }));
      });
  };
}

export function getSearchSectionForTreeSelect(): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    const params: GetListParams & Params = { page: 1, perPage: 5000 };

    dispatch(startFetchSearchSectionsForTreeSelect());

    return api.getSearchSectionRelationsForTreeSelect(params)
      .then((data) => {
        dispatch(endFetchSearchSectionsForTreeSelect({ data: data.data }));
      });
  };
}

export function getSearchSection(id: number, params?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchSearchSection());

    return api.getSearchSection(id, params)
      .then((data) => {
        dispatch(endFetchSearchSection(data));
      });
  };
}

export function getParentSearchSection(id: number, params?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchParentSearchSection());

    return api.getSearchSection(id, params)
      .then((data) => {
        dispatch(endFetchParentSearchSection(data));
      });
  };
}

export function createSearchSection(payload: CreateSearchSectionRequest): ThunkAction<Promise<SearchSection>> {
  return async (dispatch): Promise<SearchSection> => {
    dispatch(startPostSearchSection());

    return api.createSearchSection(payload)
      .then((data) => {
        dispatch(endPostSearchSection(data));

        return data;
      });
  };
}

export function updateSearchSection(id: number, payload: UpdateSearchSectionRequest): ThunkAction<Promise<SearchSection>> {
  return async (dispatch): Promise<SearchSection> => {
    dispatch(startUpdateSearchSection());

    return api.updateSearchSection(id, payload)
      .then((data) => {
        dispatch(endUpdateSearchSection(data));

        return data;
      });
  };
}

export function updateSearchSectionTranslation(id: number, payload: any): ThunkAction<Promise<SearchSection>> {
  return async (dispatch): Promise<SearchSection> => {
    dispatch(startUpdateSearchSectionTranslation());

    return api.updateSearchSectionTranslation(id, payload)
      .then((data) => {
        // dispatch(endUpdateSearchSection(data));

        return data;
      });
  };
}

export function deleteSearchSection(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startDeleteSearchSection());

    return api.deleteSearchSection(id, config);
  };
}

// Related.
export function getHospitalsForSelect(): ThunkAction<Promise<void>> {
  const params: GetListParams & Params = {
    page: 1,
    perPage: 5000,
    filter: {
      items: [
        {
          id: 'isActiveFilter',
          columnField: 'isActive',
          value: 1,
          operatorValue: 'equal',
        }
      ],
      linkOperator: GridLinkOperator.And,
    }
  };

  return async (dispatch): Promise<void> => {
    dispatch(startFetchHospitalsForSelect());

    return api.getHospitalsForSelect(params)
      .then((data) => {
        dispatch(endFetchHospitalsForSelect(data.data));
      });
  };
}

export function getDepartmentsForSelect(id: number): ThunkAction<Promise<void>> {
  const params: GetListParams & Params = { page: 1, perPage: 5000 };

  return async (dispatch): Promise<void> => {
    dispatch(startFetchDepartmentsForSelect());

    return api.getSearchSectionDepartmentsForSelect(id, params)
      .then((data) => {
        dispatch(endFetchDepartmentsForSelect({ data: data.data }));
      });
  };
}

export function getSearchSectionRelations(sectionId: number, params?: Params): ThunkAction<Promise<void>> {
  // const params: GetListParams & Params = { page: 1, perPage: 5000 };

  return async (dispatch): Promise<void> => {
    dispatch(startFetchRelations());

    return api.getSearchSectionRelations(sectionId, params)
      .then((data) => {
        dispatch(endFetchRelations(data));
      });
  };
}

export function getSearchSectionAllRelations(sectionId: number): ThunkAction<Promise<void>> {
  const params: GetListParams & Params = { page: 1, perPage: 5000 };

  return async (dispatch): Promise<void> => {
    dispatch(startFetchAllRelations());

    return api.getSearchSectionRelations(sectionId, params, { forSelect: true })
      .then((data: any) => {
        // const hospitalsIds: Array<number> = [];
        // const departmentsIds: Array<number> = [];
        //
        // data.data.forEach((i) => {
        //   hospitalsIds.push(i.hospitalId);
        //   departmentsIds.push(...i.departmentIdsArr);
        // });

        dispatch(endFetchAllRelations(data));
      });
  };
}

export function createSearchSectionRelation(sectionId: number, payload: CreateSearchSectionRelationRequest): ThunkAction<Promise<SearchSection>> {
  return async (dispatch, getState): Promise<SearchSection> => {
    // dispatch(startUpdateSearchSection());

    return api.createSearchSectionRelation(sectionId, payload)
      .then((data) => {
        // const { hospitalsIds, departmentsIds, excludedHospitalsIds } = getState().searchSections.allRelations;
        // const newHospitalsIds: Array<number> = [...hospitalsIds];
        // const newDepartmentsIds: Array<number> = [...departmentsIds.filter((i) => {
        //   return data.departmentIdsArrBeforeEdit.indexOf(i) === -1;
        // })];
        //
        // newHospitalsIds.push(data.hospitalId);
        // newDepartmentsIds.push(...data.departmentIdsArr);
        //
        // dispatch(endFetchAllRelations({
        //   hospitalsIds: uniq(newHospitalsIds),
        //   departmentsIds: uniq(newDepartmentsIds),
        //   excludedHospitalsIds: uniq(excludedHospitalsIds)
        // }));
        // dispatch(endUpdateSearchSection(data));

        return data;
      });
  };
}

export function importSearchSectionRelations(sectionId: number, payload: ImportSearchSectionRelationsRequest): ThunkAction<Promise<SearchSection>> {
  return async (dispatch, getState): Promise<SearchSection> => {
    dispatch(startImportRelations());

    return api.importSearchSectionRelation(sectionId, payload)
      .then((data) => {
        // const { hospitalsIds, departmentsIds, excludedHospitalsIds } = getState().searchSections.allRelations;
        // const newHospitalsIds: Array<number> = hospitalsIds.concat(data.hospitalsIds);
        // const newDepartmentsIds: Array<number> = departmentsIds.concat(data.departmentsIds);
        //
        // dispatch(endFetchAllRelations({
        //   hospitalsIds: uniq(newHospitalsIds),
        //   departmentsIds: uniq(newDepartmentsIds),
        //   excludedHospitalsIds: uniq(excludedHospitalsIds)
        // }));

        return data;
      });
  };
}

export function importSearchSectionExcludedHospitals(sectionId: number, payload: ImportSearchSectionRelationsRequest): ThunkAction<Promise<SearchSection>> {
  return async (dispatch, getState): Promise<SearchSection> => {
    dispatch(startImportExcludedHospitals());

    return api.importSearchSectionExcludedHospitals(sectionId, payload)
      .then((data) => {
        // const { hospitalsIds, departmentsIds, excludedHospitalsIds } = getState().searchSections.allRelations;
        // const newExcludedHospitalsIds: Array<number> = excludedHospitalsIds.concat(data.excludedHospitalsIds);
        //
        // dispatch(endFetchAllRelations({
        //   hospitalsIds,
        //   departmentsIds,
        //   excludedHospitalsIds: uniq(newExcludedHospitalsIds)
        // }));

        return data;
      });
  };
}

export function deleteSearchSectionRelation(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (dispatch, getState): Promise<void> => {
    // dispatch(startDeleteSearchSection());

    return api.deleteSearchSectionRelation(id, config)
      .then((response) => {
        // const { hospitalsIds, departmentsIds, excludedHospitalsIds } = getState().searchSections.allRelations;
        // const { data } = getState().searchSections.relations;
        // const deletedItem = data.find((i) => i.searchSectionId === sectionId && i.hospitalId === id);
        //
        // if (deletedItem) {
        //   const newHospitalsIds: Array < number > = hospitalsIds.filter((i) => i !== deletedItem.hospitalId);
        //   const newDepartmentsIds: Array<number> = departmentsIds.filter((i) => deletedItem.departmentIdsArr.indexOf(i) > -1);
        //
        //   dispatch(endFetchAllRelations({
        //     hospitalsIds: newHospitalsIds,
        //     departmentsIds: newDepartmentsIds,
        //     excludedHospitalsIds
        //   }));
        // }

        return response;
      });
  };
}

export function deleteAllSearchSectionRelation(sectionId: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    // dispatch(startDeleteExcludedHospital());

    return api.deleteSearchSectionAllRelation(sectionId, config)
      .then((response) => {
        // dispatch(endDeleteExcludedHospital());

        return response;
      });
  };
}

// Excluded Hospital.
export function getExcludedHospitals(id: number, params?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchExcludedHospitals());

    return api.getSearchSectionExcludedHospitals(id, params)
      .then((data) => {
        dispatch(endFetchExcludedHospitals(data));
      });
  };
}

// export function getExcludedHospitalsForSelect(id: number, params?: Params): ThunkAction<Promise<void>> {
//   return async (dispatch): Promise<void> => {
//     dispatch(startFetchExcludedHospitalsForSelect());
//
//     return api.getSearchSectionExcludedHospitalsForSelect(id, params)
//       .then((data) => {
//         dispatch(endFetchExcludedHospitalsForSelect(data));
//       });
//   };
// }

export function createExcludedHospitals(sectionId: number, payload: any): ThunkAction<Promise<SearchSection>> {
  return async (dispatch, getState): Promise<SearchSection> => {
    dispatch(startPostExcludedHospitals());

    return api.createSearchSectionExcludedHospitals(sectionId, payload)
      .then((data) => {
        const { hospitalsIds, departmentsIds, excludedHospitalsIds } = getState().searchSections.allRelations;

        dispatch(endFetchAllRelations({
          hospitalsIds,
          departmentsIds,
          excludedHospitalsIds: excludedHospitalsIds.concat(payload.hospitalsIds),
        }));

        dispatch(endPostExcludedHospitals());

        return data;
      });
  };
}

export function deleteExcludedHospital(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startDeleteExcludedHospital());

    return api.deleteExcludedHospital(id, config)
      .then((response) => {
        dispatch(endDeleteExcludedHospital());

        return response;
      });
  };
}

export function deleteAllExcludedHospital(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startDeleteExcludedHospital());

    return api.deleteExcludedHospitals(id, config)
      .then((response) => {
        dispatch(endDeleteExcludedHospital());

        return response;
      });
  };
}

