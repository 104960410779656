import client from './client';
import {
  CreateOfferRequest,
  CreateOfferResponse,
  ListResponse,
  Params,
  UpdateOfferRequest,
  UpdateOfferResponse
} from '@api/types';
import { AxiosRequestConfig } from 'axios';
import { Currency, Offer } from '@modelTypes/offer';
import { Service } from '@modelTypes/service';

const GET_OFFERS_URI = '/offers';
const GET_OFFER_URI = (id: number) => `/offers/${id}`;
const POST_OFFERS_URI = '/offers';
const PUT_OFFERS_URI = (id: number) => `/offers/${id}`
const DELETE_OFFERS_URI = (id: number) => `/offers/${id}`

const GET_CURRENCY_URI = '/currencies/select';

const GET_SERVICES_URI = `/offers/services`;
const PATCH_SERVICES_URI = (id: number) => `/offers/${id}/services`;

export async function getOffers(params?: Params) {
  return client.get<ListResponse<Offer>>(GET_OFFERS_URI, params);
}

export async function getOffer(id: number, params?: Params) {
  return client.get<Offer>(GET_OFFER_URI(id), params);
}

export async function createOffer(payload?: CreateOfferRequest) {
  return client.post<CreateOfferRequest, CreateOfferResponse>(POST_OFFERS_URI, payload);
}

export async function updateOffer(id: number, payload?: UpdateOfferRequest) {
  return client.put<UpdateOfferRequest, UpdateOfferResponse>(PUT_OFFERS_URI(id), payload);
}

export async function deleteOffer(id: number, payload?: AxiosRequestConfig) {
  return client.del(DELETE_OFFERS_URI(id), payload);
}

//Currency for select

export async function getCurrencyForSelect(params?: Params) {
  return client.get<ListResponse<Currency>>(GET_CURRENCY_URI, params);
}

export async function getOfferServices(params?: Params) {
  return client.get<ListResponse<Service>>(GET_SERVICES_URI, params);
}

export async function updateOfferServices(id: number, params?: Params) {
  return client.patch<any, any>(PATCH_SERVICES_URI(id), params);
}