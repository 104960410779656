import { ModalData, OrdersState } from '@storage/orders/reducer';
import { Order, PhoneCountry, Status } from '@modelTypes/order';

import { ListResponse } from '@api/types';
import { createAction } from '../helpers';

export enum ActionType {
  START_FETCH_ORDERS = 'ORDERS__START_FETCH_ORDERS',
  END_FETCH_ORDERS = 'ORDERS__END_FETCH_ORDERS',

  SET_SELECTED_ROWS_IDS = 'ORDERS__SET_SELECTED_ROWS_IDS',

  START_FETCH_PHONE_COUNTRIES_FOR_SELECT = 'ORDERS__START_FETCH_PHONE_COUNTRIES_FOR_SELECT',
  END_FETCH_PHONE_COUNTRIES_FOR_SELECT = 'ORDERS__END_FETCH_PHONE_COUNTRIES_FOR_SELECT',

  START_FETCH_STATUSES_FOR_SELECT = 'ORDERS__START_FETCH_STATUSES_FOR_SELECT',
  END_FETCH_STATUSES_FOR_SELECT = 'ORDERS__END_FETCH_STATUSES_FOR_SELECT',

  // START_FETCH_BLOGS_FOR_SELECT = 'BLOGS__START_FETCH_BLOGS_FOR_SELECT',
  // END_FETCH_BLOGS_FOR_SELECT = 'BLOGS__END_FETCH_BLOGS_FOR_SELECT',
  // CLEAR_BLOGS_FOR_SELECT = 'BLOGS__CLEAR_BLOGS_FOR_SELECT',
  //
  // START_FETCH_BLOG = 'BLOGS__START_FETCH_BLOG',
  // END_FETCH_BLOG = 'BLOGS__END_FETCH_BLOG',
  //
  // START_FETCH_ACCESS_KEY = 'BLOGS__START_FETCH_ACCESS_KEY',
  // END_FETCH_ACCESS_KEY = 'BLOGS__END_FETCH_ACCESS_KEY',

  START_POST_ORDER = 'ORDERS__START_POST_ORDER',
  END_POST_ORDER = 'ORDERS__END_POST_ORDER',
  END_POST_ORDER_ERROR = 'ORDERS__END_POST_ORDER_ERROR',

  START_PATCH_ORDER_FROM_LIST = 'ORDERS__START_PATCH_ORDER_FROM_LIST',
  END_PATCH_ORDER_FROM_LIST = 'ORDERS__END_PATCH_ORDER_FROM_LIST',
  END_PATCH_ORDER_FROM_LIST_ERROR = 'ORDERS__END_PATCH_ORDER_FROM_LIST_ERROR',

  OPEN_MODAL_WITH_FORM = 'ORDERS__OPEN_MODAL_WITH_FORM',
  CLOSE_MODAL_WITH_FORM = 'ORDERS__CLOSE_MODAL_WITH_FORM',
  MODAL_WITH_FORM_SET_DATA = 'ORDERS__MODAL_WITH_FORM_SET_DATA',

  START_DELETE_ORDERS = 'BLOGS__START_DELETE_ORDERS',
  // END_DELETE_BLOG = 'BLOGS__END_DELETE_BLOG',
  //
  // CLEAR_FORM_DATA = 'BLOGS__CLEAR_FORM_DATA',
}

type EndFetchOrders = Omit<OrdersState['list'], 'loading' | 'selectedRowsIds'>;

export const startFetchOrders = createAction<ActionType.START_FETCH_ORDERS>(ActionType.START_FETCH_ORDERS);
export const endFetchOrders = createAction<ActionType.END_FETCH_ORDERS, EndFetchOrders>(ActionType.END_FETCH_ORDERS);

export const setSelectedRowsIds = createAction<ActionType.SET_SELECTED_ROWS_IDS, Array<number>>(ActionType.SET_SELECTED_ROWS_IDS);

export const startFetchCountriesForSelect = createAction<ActionType.START_FETCH_PHONE_COUNTRIES_FOR_SELECT>(ActionType.START_FETCH_PHONE_COUNTRIES_FOR_SELECT);
export const endFetchCountriesForSelect = createAction<ActionType.END_FETCH_PHONE_COUNTRIES_FOR_SELECT, ListResponse<PhoneCountry>>(ActionType.END_FETCH_PHONE_COUNTRIES_FOR_SELECT);

export const startFetchStatusesForSelect = createAction<ActionType.START_FETCH_STATUSES_FOR_SELECT>(ActionType.START_FETCH_STATUSES_FOR_SELECT);
export const endFetchStatusesForSelect = createAction<ActionType.END_FETCH_STATUSES_FOR_SELECT, Array<Status>>(ActionType.END_FETCH_STATUSES_FOR_SELECT);

// export const startFetchBlogsForSelect = createAction<ActionType.START_FETCH_BLOGS_FOR_SELECT>(ActionType.START_FETCH_BLOGS_FOR_SELECT);
// export const endFetchBlogsForSelect = createAction<ActionType.END_FETCH_BLOGS_FOR_SELECT, ListResponse<Blog>>(ActionType.END_FETCH_BLOGS_FOR_SELECT);
// export const clearBlogsForSelect = createAction<ActionType.CLEAR_BLOGS_FOR_SELECT>(ActionType.CLEAR_BLOGS_FOR_SELECT);
//
// export const startFetchBlog = createAction<ActionType.START_FETCH_BLOG>(ActionType.START_FETCH_BLOG);
// export const endFetchBlog = createAction<ActionType.END_FETCH_BLOG, Blog>(ActionType.END_FETCH_BLOG);
//
// export const startFetchAccessKey = createAction<ActionType.START_FETCH_ACCESS_KEY>(ActionType.START_FETCH_ACCESS_KEY);
// export const endFetchAccessKey = createAction<ActionType.END_FETCH_ACCESS_KEY, BlogAccessKey>(ActionType.END_FETCH_ACCESS_KEY);

export const startPostOrder = createAction<ActionType.START_POST_ORDER>(ActionType.START_POST_ORDER);
export const endPostOrder = createAction<ActionType.END_POST_ORDER, Order>(ActionType.END_POST_ORDER);
export const endPostOrderError = createAction<ActionType.END_POST_ORDER_ERROR>(ActionType.END_POST_ORDER_ERROR);

export const startUpdateOrderFromList = createAction<ActionType.START_PATCH_ORDER_FROM_LIST>(ActionType.START_PATCH_ORDER_FROM_LIST);
export const endUpdateOrderFromList = createAction<ActionType.END_PATCH_ORDER_FROM_LIST, Order>(ActionType.END_PATCH_ORDER_FROM_LIST);
export const endUpdateOrderFromListError = createAction<ActionType.END_PATCH_ORDER_FROM_LIST_ERROR>(ActionType.END_PATCH_ORDER_FROM_LIST_ERROR);

export const openModalWithForm = createAction<ActionType.OPEN_MODAL_WITH_FORM>(ActionType.OPEN_MODAL_WITH_FORM);
export const closeModalWidthForm = createAction<ActionType.CLOSE_MODAL_WITH_FORM>(ActionType.CLOSE_MODAL_WITH_FORM);
export const setModalWithFormData = createAction<ActionType.MODAL_WITH_FORM_SET_DATA, ModalData>(ActionType.MODAL_WITH_FORM_SET_DATA);

export const startDeleteOrders = createAction<ActionType.START_DELETE_ORDERS>(ActionType.START_DELETE_ORDERS);
// export const endDeleteBlog = createAction<ActionType.END_DELETE_BLOG>(ActionType.END_DELETE_BLOG);
//
// export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

export type Actions =
  | ReturnType<typeof startFetchOrders>
  | ReturnType<typeof endFetchOrders>
  | ReturnType<typeof setSelectedRowsIds>
  | ReturnType<typeof startFetchCountriesForSelect>
  | ReturnType<typeof endFetchCountriesForSelect>
  | ReturnType<typeof startFetchStatusesForSelect>
  | ReturnType<typeof endFetchStatusesForSelect>
  // | ReturnType<typeof startFetchBlogsForSelect>
  // | ReturnType<typeof endFetchBlogsForSelect>
  // | ReturnType<typeof clearBlogsForSelect>
  // | ReturnType<typeof startFetchBlog>
  // | ReturnType<typeof endFetchBlog>
  // | ReturnType<typeof startFetchAccessKey>
  // | ReturnType<typeof endFetchAccessKey>
  | ReturnType<typeof startPostOrder>
  | ReturnType<typeof endPostOrder>
  | ReturnType<typeof endPostOrderError>
  | ReturnType<typeof startUpdateOrderFromList>
  | ReturnType<typeof endUpdateOrderFromList>
  | ReturnType<typeof endUpdateOrderFromListError>
  | ReturnType<typeof openModalWithForm>
  | ReturnType<typeof closeModalWidthForm>
  | ReturnType<typeof setModalWithFormData>
  | ReturnType<typeof startDeleteOrders>
  // | ReturnType<typeof endDeleteBlog>
  // | ReturnType<typeof clearFormData>;