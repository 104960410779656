import { useParams } from 'react-router-dom';
import AuthorDataForm from './formSteps/AuthorDataForm';
import AuthorCertificatesForm from './formSteps/AuthorCertificatesForm';

const stepsScreen = {
  '1': <AuthorDataForm />,
  '2': <AuthorCertificatesForm />,
};

export default function Create() {
  const { step = '1' } = useParams<{ step: string }>();

  return stepsScreen[step as (keyof typeof stepsScreen)]
}
