import { create } from 'zustand';
import produce from 'immer';
import {
  AntGetListParams,
  CreateVendorCompanyRequest,
  CreateVendorCompanyResponse,
  ListResponse,
  Params,
  UpdateVendorCompanyRequest,
  UpdateVendorCompanyResponse
} from '@api/types';
import { initialFormState, initialGridState } from '@zustandStorage/helpers/initialStates';
import { VendorCompany } from '@modelTypes/vendor';
import { createCompany, deleteCompany, getCompanies, getCompany, updateCompany } from '@api/vendorsApi';
import { AxiosRequestConfig } from 'axios';

export interface UserState {
  grid: ListResponse<VendorCompany> & { loading: boolean }
  form: {
    loading: boolean;
    row: VendorCompany | null;
  };
  clearFormData: () => void;
  fetchCompanies: (params?: AntGetListParams) => Promise<ListResponse<VendorCompany>>;
  fetchCompany: (id: number) => Promise<VendorCompany>;
  createCompany: (payload: CreateVendorCompanyRequest) => Promise<VendorCompany>;
  updateCompany: (id: number, payload: UpdateVendorCompanyRequest) => Promise<VendorCompany>;
  deleteCompany: (id: number, payload?: AxiosRequestConfig) => Promise<any>;
}

export const useVendorCompanyStore = create<UserState>((set) => ({
  grid: initialGridState,
  form: initialFormState,
  clearFormData: () => set(() => ({ form: initialFormState })),

  fetchCompanies: async (params?: AntGetListParams) => {
    set(produce((state) => {
      state.grid.loading = true;
    }));

    return getCompanies(params)
      .then((response) => {
        set(produce((state) => {
          state.grid.data = response.data;
          state.grid.meta = response.meta
          state.grid.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.loading = false;
        }));

        throw e;
      });
  },
  fetchCompany: async (id: number, params?: Params): Promise<VendorCompany> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return getCompany(id, params)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  createCompany: async (payload: CreateVendorCompanyRequest): Promise<CreateVendorCompanyResponse> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return createCompany(payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateCompany: async (id: number, payload: UpdateVendorCompanyRequest): Promise<UpdateVendorCompanyResponse> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateCompany(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  deleteCompany: async (id, payload?) => {
    return deleteCompany(id, payload)
  },
}));