import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { PaginationConfig } from '@components/AntGrid/types';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { getHistorySearchParam } from '@helpers/history';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import { TreatmentMethod } from '@modelTypes/treatment';
import { treatmentMethodColumns, treatmentMethodFiltersConfig } from '@components/AntGrid/columns/treatmentMethod';
import useChangeGridOptions from '@components/AntGrid/hooks/useChangeGridOptions';
import { useMethodState } from '@zustandStorage/offers/method';

export const [
  refreshTreatmentMethodsGridEvent,
  dispatchRefreshTreatmentMethodsGridEvent
] = makeRefreshGridEvent('onRefreshTreatmentMethodsGrid');

export default function MethodsGrid() {
  const history = useHistory();
  const { grid: { data, meta, loading }, fetchMethods } = useMethodState();
  const languageId = getHistorySearchParam(history, 'language-id', 1);
  const firstLoad = useRef<boolean>(false);
  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
    // ...rest.paginationConfig,
  };


  const onFetchData = (params?: AntGetListParams) => {
    fetchMethods({ ...params, languageId })
      .then(() => {
        firstLoad.current = true;
      });
  };

  useEffect(() => {
    if (firstLoad.current) {
      fetchMethods({ languageId });
    }
  }, [firstLoad, languageId]);

  useRefreshGridEvent(refreshTreatmentMethodsGridEvent, onFetchData);
  useChangeGridOptions('treatmentMethodsGrid');

  return (
    <Grid<TreatmentMethod>
      id="treatmentMethodsGrid"
      onFetch={onFetchData}
      columns={treatmentMethodColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={treatmentMethodFiltersConfig}
    />
  );
}