import { create } from 'zustand';
import produce from 'immer';
import { ListResponse, Params } from '@api/types';
import { initialGridState } from '@zustandStorage/helpers/initialStates';
import { Skill, SkillOffer } from '@modelTypes/skill';
import {
  createDoctorSkill,
  deleteDoctorSkill,
  getDoctorSkills,
  getDoctorSkillsOffers,
  updateDoctorSkills
} from '@api/doctorApi';

export interface DoctorSkillsOffersState {
  loading: boolean;
  updateLoading: boolean;
  data: Array<SkillOffer>;
}

export interface DoctorSkillsState {
  grid: ListResponse<Skill> & { loading: boolean; };
  skillsOffer: DoctorSkillsOffersState;
  fetchDoctorSkillsOffers: (id: number, params?: Params) => Promise<Skill>;
  fetchDoctorSkills: (id: number, params?: Params) => Promise<Skill>;
  createDoctorSkills: (id: number, params?: Params) => Promise<Skill>;
  updateDoctorSkills: (id: number, params?: Params) => Promise<Skill>;
  deleteDoctorSkills: (id: number, essenceId: number, params?: Params) => Promise<any>;
}

const initialDoctorSkillsOffersState = {
  loading: false,
  updateLoading: false,
  data: [],
}

export const useDoctorSkillsStore = create<DoctorSkillsState>((set) => ({
  grid: initialGridState,
  skillsOffer: initialDoctorSkillsOffersState,
  fetchDoctorSkillsOffers: async (id: number, params?: Params): Promise<any> => {
    set(produce((state) => {
      state.skillsOffer.loading = true;
    }));

    return getDoctorSkillsOffers(id, params)
      .then((response) => {
        set(produce((state) => {
          state.skillsOffer.data = response;
          // state.grid.meta = response.meta;
          state.skillsOffer.loading = false;
        }));

        return response;
      });
  },
  fetchDoctorSkills: async (id: number, params?: Params): Promise<any> => {
    set(produce((state) => {
      state.grid.loading = true;
    }));

    return getDoctorSkills(id, params)
      .then((response) => {

        set(produce((state) => {
          state.grid.data = response;
          // state.grid.meta = response.meta;
          state.grid.loading = false;
        }));

        return response;
      });
  },

  createDoctorSkills: async (id: number, params?: Params): Promise<any> => {
    // set(produce((state) => {
    //   state.grid.loading = true;
    // }));

    return createDoctorSkill(id, params)
    // .then((response) => {
    //   set(produce((state) => {
    //     state.grid.data = response.data;
    //     state.grid.meta = response.meta;
    //     state.grid.loading = false;
    //   }));
    //
    //   return response;
    // });
  },
  updateDoctorSkills: async (id, payload) => {
    set(produce((state) => {
      state.skillsOffer.updateLoading = true;
    }));

    return updateDoctorSkills(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.skillsOffer.updateLoading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.skillsOffer.updateLoading = false;
        }));

        throw e;
      });
  },
  deleteDoctorSkills: async (id, essenceId, payload) => {
    return deleteDoctorSkill(id, essenceId, payload)
  }
}));