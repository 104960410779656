import { DragEvent, Key, ReactNode, useState } from 'react';
import SortableItem from './SortableItem/SortableItem';

interface Props<T> {
  data: T[];
  sortable?: boolean;
  flow: 'vertical' | 'horizontal';
  getItem(item: T): ReactNode;
  getKey(item: T): Key;
  onDragStart?(item: T): void;
  onDragEnd?(item: T): void;
  onSort(from: T, to: T): void;
  itemClass?: string;
  itemSkeletonClass?: string;
}

export default function SortableList<T>({
  data,
  sortable = true,
  flow,
  getItem,
  getKey,
  onDragStart,
  onDragEnd,
  onSort,
  itemClass,
  itemSkeletonClass,
}: Props<T>) {
  const [draggingItem, setDraggingItem] = useState<T | null>(null);
  const [dragOverItem, setDragOverItem] = useState<T | null>(null);
  const [itemBeingDragged, setItemBeingDragged] = useState<T | null>(null);

  const handleDragStart = (item: T) => {
    setDraggingItem(item);
    onDragStart?.(item);

    setTimeout(() => setItemBeingDragged(item));
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>, item: T) => {
    if (item === draggingItem) {
      return;
    }

    event.preventDefault();
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.dropEffect = 'move';

    setDragOverItem(item);
  };

  const handleDrop = (item: T) => {
    if (draggingItem === null) {
      return;
    }

    onSort(draggingItem, item);
  };

  const handleDragEnd = (item: T) => {
    setDraggingItem(null);
    onDragEnd?.(item);
    setItemBeingDragged(null);
  };

  return (
    <>
      {data.map((item) => (
        <SortableItem
          key={getKey(item)}
          draggable={sortable}
          flow={flow}
          onDragStart={() => handleDragStart(item)}
          onDragOver={(e) => handleDragOver(e, item)}
          onDragLeave={() => setDragOverItem(null)}
          onDragEnd={() => handleDragEnd(item)}
          onDrop={() => handleDrop(item)}
          slotAvailable={draggingItem !== null && draggingItem !== item}
          slotBefore={
            !!draggingItem &&
            dragOverItem === item &&
            data.findIndex((item) => item === dragOverItem) < data.findIndex((item) => item === draggingItem)
          }
          slotAfter={
            !!draggingItem &&
            dragOverItem === item &&
            data.findIndex((item) => item === dragOverItem) > data.findIndex((item) => item === draggingItem)
          }
          beingDragged={itemBeingDragged === item}
          className={itemClass}
          skeletonClass={itemSkeletonClass}
        >
          {getItem(item)}
        </SortableItem>
      ))}
    </>
  );
}
