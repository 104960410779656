import { useHistory } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import { useGridStore } from '@zustandStorage/grid/grid';
import QueryString from 'query-string';
import SelectStep from '@containers/Doctors/components/SelectStep';
import SkillsGrid from '@containers/Doctors/FormSteps/SkillsStep/components/SkillsGrid';
import AddCustomSkillsModal from '@containers/Doctors/FormSteps/SkillsStep/components/AddCustomSkillsModal';
import AddSkillsModal from './components/AddSkillsModal/AddSkillsModalTrigger';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import * as React from 'react';
import { useState } from 'react';
import { Language } from '@modelTypes/language';
import { updateQueryParams } from '@helpers/history';

export default function SkillsStep() {
  const history = useHistory();
  const [languageId, setLanguageId] = useState(1)
  const onSelectLanguage = (language: Language) => {
    setLanguageId(language.id)
    updateQueryParams(history, {
      ['language-id']: language.id > 1 ? language.id : null,
    });
  };

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.doctorsAntGrid || {};
          history.push(`/doctors?${QueryString.stringify(params)}`)
        }}
        title="Skills & Expertise"
        extra={[
          <SelectStep key="selectStep" />,
          <SelectLanguagePerForm
            key="selectLanguage"
            updateParamKey={false}
            queryParamKey="language-id"
            onInit={onSelectLanguage}
            onChange={onSelectLanguage}
          />,
          <AddSkillsModal key="addSkills" disabled={+languageId > 1} />,
          <AddCustomSkillsModal key="addCustomSkill" disabled={+languageId > 1} />
        ]}
      />
      <SkillsGrid />
    </>
  );
}