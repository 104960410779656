import { FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { DoctorTranslation } from '@modelTypes/doctor';
import { IconButtonRenderer } from '../renderers/renderers';
import { getDateFormattedCell } from '@components/AntGrid/cells/DateFormattedCell';
import ShowCheckIcon from '../cells/ShowCheckIcon';
import QueryString from 'query-string';
import { CopyOutlined } from '@ant-design/icons';
import { Tooltip, message } from 'antd';
import IdCell from '@components/AntGrid/cells/IdCell';
import getIdCell from '@components/AntGrid/cells/IdCell';

export type DoctorTranslationColumnsNames = `${keyof DoctorTranslation}Column` | 'eyeIconColumn' | 'deleteColumn';

export const doctorTranslationColumnsDefinitions: Partial<Record<DoctorTranslationColumnsNames, ColumnType<DoctorTranslation>>> = {
  idColumn: createColumnDefinition<DoctorTranslation>('#', 'id', { width: 80, render: getIdCell }),
  countryColumn: {
    title: 'Country',
    dataIndex: 'countryId',
    render: (value: any, row: DoctorTranslation) => {
      return <CountryCell record={row} />
    },
    sorter: true,
    filtered: false,
  },
  cityColumn: {
    title: 'City',
    dataIndex: 'cityId',
    render: (value: any, row: DoctorTranslation) => {
      return <CityCell record={row} />
    },
    sorter: true,
    filtered: false,
  },
  specializationColumn: {
    title: 'Specialization',
    dataIndex: 'specializationId',
    render: (value: any, row: DoctorTranslation) => {
      return <SpecializationCell record={row} />
    },
    sorter: true,
    filtered: false,
  },
  searchSectionColumn: {
    title: 'Search Section',
    dataIndex: 'searchSectionId',
    render: (value: any, row: DoctorTranslation) => {
      return <SearchSectionCell record={row} />
    },
    sorter: true,
    filtered: false,
  },
  isLanguageActiveColumn: {
    title: 'Active',
    dataIndex: 'isLanguageActive',
    width: 100,
    render: (value: any, row: DoctorTranslation) => {
      return <ShowCheckIcon value={row.value === '1'} />
    },
    sorter: true,
    filtered: false,
  },
  updatedAtColumn: createColumnDefinition<DoctorTranslation>('Updated At', 'updatedAt', {
    render: getDateFormattedCell,
    width: 160
  }),
  createdAtColumn: createColumnDefinition<DoctorTranslation>('Created At', 'createdAt', {
    render: getDateFormattedCell,
    width: 160
  }),
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, row: DoctorTranslation) => {
      const params = QueryString.stringify(
        {
          countryId: row.countryId > 0 ? row.countryId : null,
          cityId: row.cityId > 0 ? row.cityId : null,
          specializationId: row.specializationId > 0 ? row.specializationId : null,
          searchSectionId: row.searchSectionId > 0 ? row.searchSectionId : null,
        },
        { skipNull: true }
      );

      return IconButtonRenderer<DoctorTranslation>(`/doctors/contents/edit${params ? `?${params}` : ''}`);
    },
    sorter: false,
    filtered: false,
  },
};

export const doctorTranslationColumns: any = [
  doctorTranslationColumnsDefinitions.idColumn,
  doctorTranslationColumnsDefinitions.countryColumn,
  doctorTranslationColumnsDefinitions.cityColumn,
  doctorTranslationColumnsDefinitions.specializationColumn,
  doctorTranslationColumnsDefinitions.searchSectionColumn,
  doctorTranslationColumnsDefinitions.updatedAtColumn,
  doctorTranslationColumnsDefinitions.createdAtColumn,
  doctorTranslationColumnsDefinitions.isLanguageActiveColumn,
  doctorTranslationColumnsDefinitions.eyeIconColumn,
];

export const doctorTranslationFiltersConfig: FiltersConfig = {
  [doctorTranslationColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [doctorTranslationColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [doctorTranslationColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};


function CountryCell({ record }: { record: DoctorTranslation }) {
  if (!record.country) {
    return <>N/A</>;
  }

  return (
    <>{record.country.name}</>
  );
}

function CityCell({ record }: { record: DoctorTranslation }) {
  if (!record.city) {
    return <>N/A</>;
  }

  return (
    <>{record.city.name}</>
  );
}

function SpecializationCell({ record }: { record: DoctorTranslation }) {
  if (!record.specialization) {
    return <>N/A</>;
  }

  return (
    <>{record.specialization.name}</>
  );
}

function SearchSectionCell({ record }: { record: DoctorTranslation }) {
  if (!record.searchSection) {
    return <>N/A</>;
  }

  return (
    <>{record.searchSection.name}</>
  );
}
