import { UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useRef } from 'react';

interface Props {
  onChange(event: React.FormEvent<HTMLInputElement>): void;
}

export default function UploadVideoAction({ onChange }: Props) {
  const fileInput = useRef<HTMLInputElement>(null);

  return (
    <>
      <Button onClick={() => fileInput.current?.click()} type="link" icon={<UploadOutlined />} style={{ width: 460 }} />
      <input ref={fileInput} style={{ display: 'none' }} onChange={onChange} type="file" accept="video/*" />
    </>
  );
}
