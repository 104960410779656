import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { PaginationConfig } from '@components/AntGrid/types';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeGridOptions from '@components/AntGrid/hooks/useChangeGridOptions';
import { VendorCompany } from '@modelTypes/vendor';
import { vendorsCompaniesColumns, vendorsCompaniesFiltersConfig } from '@components/AntGrid/columns/vendorCompany';
import { useVendorCompanyStore } from '@zustandStorage/vendor/company';

export const [
  refreshVendorsCompaniesGridEvent,
  dispatchRefreshVendorsCompaniesGridEvent
] = makeRefreshGridEvent('onRefreshUsersGrid');

export default function CompanyVendorsGrid() {
  const {
    grid: {
      data, meta, loading
    }, fetchCompanies,
  } = useVendorCompanyStore();
  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
    // ...rest.paginationConfig,
  };

  const onFetchData = (params?: AntGetListParams) => {
    fetchCompanies(params);
  };

  useRefreshGridEvent(refreshVendorsCompaniesGridEvent, onFetchData);
  useChangeGridOptions('vendorsCompanyAntGrid');

  return (
    <Grid<VendorCompany>
      id="vendorsUser"
      onFetch={onFetchData}
      columns={vendorsCompaniesColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={vendorsCompaniesFiltersConfig}
    />
  );
}