import { message, Modal } from 'antd';
import 'cropperjs/dist/cropper.css';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useHospitalStore } from '@zustandStorage/hospital/hospital';
import map from 'lodash/map';
import { updateHospitalServices } from '@api/hospitalApi';
import ExtraServicesModal from '@components/ExtraServicesModal/ExtraServicesModal';
import { useHospitalServices } from '@zustandStorage/hospital/services';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export default function HospitalExtraServicesModal({ isOpen, closeModal }: ModalProps) {
  const { confirm } = Modal;
  const { loading, data, resetData, fetchServices } = useHospitalServices()
  const [hospital, setForm] = useHospitalStore((st) => [st.form, st.setForm]);
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedItems, setSelectedItems] = useState<Array<number>>([]);

  useEffect(() => {
    if (isOpen) {
      handleFetchData();
    }
  }, [isOpen]);

  // const handleFieldsChange = () => {
  //   setFormChanged(true);
  // };

  const resetForm = () => {
    setFormChanged(false);
    resetData();
  };

  const showConfirm = () => {
    confirm({
      title: 'Data not saved!',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to close modal window?',
      onOk() {
        closeModal()
        resetForm()
      },
      // onCancel() {
      //
      // },
    });
  };

  const handleCloseModal = () => {
    if (formChanged) {
      showConfirm()
    } else {
      closeModal()
      resetForm()
    }
  }

  const handleItemClick = (itemId: number) => {
    setFormChanged(true)
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(itemId)) {
        return prevSelected.filter((id: any) => id !== itemId);
      } else {
        return [...prevSelected, itemId];
      }
    });
  };

  const handleFetchData = async () => {
    fetchServices()
      .then(() => {
        if (hospital.row && hospital.row?.services) {
          setSelectedItems(map(hospital.row.services, 'id'));
        }
      });
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving services...',
      duration: 0,
    });

    if (hospital.row) {
      updateHospitalServices(hospital.row?.id, { servicesIds: selectedItems })
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The services has been successfully updated');
          setForm({ loading: false, row: { ...hospital.row as any, services: data.services } });
          resetForm();
          closeModal();
        })
        .catch(() => {
          messageApi.destroy('startCreate');
          message.error('An error occurred, please try again now')
        });

    } else {
      messageApi.destroy('startCreate');
    }
  };

  return (
    <ExtraServicesModal
      handleCloseModal={handleCloseModal}
      isOpen={isOpen}
      data={data}
      contextHolder={contextHolder}
      selectedItems={selectedItems}
      loading={loading}
      handleItemClick={handleItemClick}
      handleOnFinish={handleOnFinish}
    />
  )
}
