import { useEffect, useState } from 'react';

import { Prompt, useHistory, useParams } from 'react-router-dom';
import { Language } from '@modelTypes/language';
import { setFormErrors } from '@api/helpers';
import { Button, Col, Form, Input, InputNumber, message, Row, Space, Spin, Tooltip } from 'antd';

import { PageHeader } from '@ant-design/pro-layout';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import { RedoOutlined, SaveOutlined } from '@ant-design/icons';
import { CreateArticleRequest } from '@api/types';
import { useGridStore } from '@zustandStorage/grid/grid';
import QueryString from 'query-string';
import { BlogTypes } from '@modelTypes/blog';
import { useMenuStore } from '@zustandStorage/menu/menu';
import { indexPageTitles, indexPageUri } from '../helpers';
import _ from 'lodash';
import styles from './Create.module.scss';

interface RouterParams {
  page: string;
  id: string
}

interface RouterParams {
  type: BlogTypes;
  id: string;
}

export default function MenuCreate() {
  const history = useHistory();
  const { page, id } = useParams<RouterParams>();
  const [messageApi, contextHolder] = message.useMessage();
  const [formChanged, setFormChanged] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [languageId, setLanguageId] = useState(0);
  const [form] = Form.useForm<CreateArticleRequest>();
  const { updateMenuItem, createMenu, fetchMenuItem, clearFormData, form: { row, loading } } = useMenuStore();

  const onSelectLanguage = async (language: Language) => {
    setLanguageId(language.id);

    if (id) {
      await fetchMenuItem(parseInt(id, 10), { languageId: language.id })
        .then(() => {
          resetForm();
        });
    }
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const fillKey = () => {
    form.setFieldValue('key', `menu__${_.snakeCase(page)}__${_.snakeCase(form.getFieldValue('title'))}`)
  };

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving blog...',
      duration: 0,
    });

    setFormSubmitting(true);
    const fieldValue = form.getFieldsValue();
    const pageName = indexPageUri[page];
    const formData = { ...fieldValue, languageId, page: pageName };

    if (id) {
      await updateMenuItem(parseInt(id, 10), formData as any)
        .then(() => {
          messageApi.destroy('startCreate');
          message.success('The link has been successfully updated');
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          setFormSubmitting(false);
          message.error('An error occurred, please try again now')
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    } else {
      await createMenu(formData as any)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The link has been successfully created');
          history.push(`/contents/menu/${page}/${data.id}/edit`);
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          setFormSubmitting(false);
          message.error('An error occurred, please try again now')
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    }
  };

  useEffect(() => {
    resetForm();
  }, [languageId, page, row]);

  useEffect(() => {
    return () => {
      clearFormData();
    }
  }, [])

  return (
    <>
      {contextHolder}
      <Prompt
        when={!formSubmitting && formChanged}
        message={(location) =>
          `Data not saved!\nAre you sure you want to go to ${location.pathname}?`
        }
      />
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.contentAntGrid || {};
          history.push(`/contents/menu/${page}?${QueryString.stringify(params)}`)
        }}
        title={`${row ? 'Edit' : 'Create'} ${indexPageTitles[indexPageUri[page]]}`}
        extra={[
          <SelectLanguagePerForm
            key="selectLanguage"
            onInit={onSelectLanguage}
            onChange={onSelectLanguage}
            disabled={!row}
          />,
          <Button
            key="save"
            type="primary"
            loading={formSubmitting}
            onClick={() => form.submit()}
          >
            {id ? 'Update' : 'Create'}
          </Button>
        ]}
      />
      <Spin spinning={loading || languageId === 0}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          autoComplete="off"
        >
          <Row gutter={[32, 24]}>
            <Col span={16}>
              <Form.Item
                name="title"
                label="Name"
                messageVariables={{ name: 'Name' }}
                rules={[{ required: true, type: 'string', max: 255 }]}
                initialValue={row?.title}
              >
                <Input placeholder="Enter name" />
              </Form.Item>
              <Form.Item
                name="link"
                label="Link"
                messageVariables={{ name: 'Link' }}
                rules={[{ type: 'string', max: 255 }]}
                initialValue={row?.link && row?.link[0] ? row?.link[0].value : undefined}
              >
                <Input
                  disabled={languageId > 1}
                  placeholder="Enter link"
                />
              </Form.Item>
              <Space className={styles.keyFieldSpace}>
                <Form.Item
                  name="key"
                  label="Key"
                  messageVariables={{ name: 'Key' }}
                  rules={[{ required: true, type: 'string', max: 128 }]}
                  initialValue={row?.key}
                >
                  <Input
                    disabled={languageId > 1}
                    placeholder="Enter key"
                    suffix={(
                      <Tooltip title="Fill key value">
                        <RedoOutlined onClick={fillKey} />
                      </Tooltip>
                    )}
                  />
                </Form.Item>
              </Space>
              <Form.Item
                name="order"
                label="Order"
                messageVariables={{ name: 'Order' }}
                rules={[{ type: 'number' }]}
                initialValue={row?.order}
              >
                <InputNumber
                  disabled={languageId > 1}
                  step={1}
                  min={1}
                  placeholder="Order"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Tooltip
        title={id ? 'Update' : 'Create'}
        placement="topRight"
      >
        <Button
          style={{ position: 'fixed', bottom: 20, right: 16 }}
          onClick={() => form.submit()}
          shape="circle"
          icon={<SaveOutlined />}
          size="large"
        />
      </Tooltip>
    </>
  );
}
