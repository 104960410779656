import { create } from 'zustand';
import produce from 'immer';
import {
  createProgramDiscount,
  getProgramDiscount,
  getProgramDiscounts, getProgramDiscountsForSelect,
  updateProgramDiscount
} from '@api/treatmentApi';
import { AntGetListParams, CreateProgramDiscountRequest, ListResponse, UpdateProgramDiscountRequest } from '@api/types';
import { ProgramDiscount } from '@modelTypes/treatment';
import { initialFormState, initialGridState, initialSelectState } from '@zustandStorage/helpers/initialStates';
import { prepareDataForBackend, prepareDataForFrontend } from '@zustandStorage/treatment/program/discount/prepareData';

export interface ProgramDiscountGridState {
  grid: ListResponse<ProgramDiscount> & {
    loading: boolean;
  };
  form: {
    loading: boolean;
    row: ProgramDiscount | null;
  };
  select: {
    loading: boolean;
    search: string;
    data: Array<ProgramDiscount>;
  }
  clearGridData: () => void;
  clearFormData: () => void;
  fetchProgramDiscounts: (params?: AntGetListParams) => Promise<any>;
  fetchProgramDiscountsForSelect: (params?: AntGetListParams) => Promise<any>;
  fetchProgramDiscount: (id: number) => Promise<any>;
  createProgramDiscount: (payload: CreateProgramDiscountRequest) => Promise<any>;
  updateProgramDiscount: (id: number, payload: UpdateProgramDiscountRequest) => Promise<any>;
}

export const useDiscountStore = create<ProgramDiscountGridState>((set) => ({
  grid: initialGridState,
  form: initialFormState,
  select: initialSelectState,
  clearGridData: () => set(() => ({ grid: initialGridState })),
  clearFormData: () => set(() => ({ form: initialFormState })),
  fetchProgramDiscounts: async (params?: AntGetListParams): Promise<any> => {
    set(produce((state) => {
      state.grid.loading = true;
    }));

    return getProgramDiscounts(params)
      .then((response) => {
        set(produce((state) => {
          state.grid.data = response.data;
          state.grid.meta = response.meta;
          state.grid.loading = false;
        }));

        return response;
      });
  },
  fetchProgramDiscountsForSelect: async (params?: AntGetListParams): Promise<any> => {
    set(produce((state) => {
      state.select.loading = true;
    }));

    return getProgramDiscountsForSelect(params)
      .then((response) => {
        set(produce((state) => {
          state.select.data = response.data;
          state.select.loading = false;
        }));

        return response;
      });
  },
  fetchProgramDiscount: async (id: number): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return getProgramDiscount(id)
      .then((response) => {
        set(produce((state) => {
          state.form.row = prepareDataForFrontend(response);
          state.form.loading = false;
        }));

        return response;
      });
  },
  createProgramDiscount: async (payload: CreateProgramDiscountRequest): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return createProgramDiscount(prepareDataForBackend(payload))
      .then((response) => {
        set(produce((state) => {
          state.form.row = prepareDataForFrontend(response);
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateProgramDiscount: async (id: number, payload: UpdateProgramDiscountRequest): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateProgramDiscount(id, prepareDataForBackend(payload as CreateProgramDiscountRequest))
      .then((response) => {
        set(produce((state) => {
          state.form.row = prepareDataForFrontend(response);
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  // // Search mobile menu.
  // showSearchMenu: false,
  // toggleSearchMenu: () => set((state) => ({ showSearchMenu: !state.showSearchMenu })),
  //
  // // Show detail mobile menu.
  // showDetailsMenu: false,
  // toggleDetailsMenu: () => set((state) => ({ showDetailsMenu: !state.showDetailsMenu })),
  //
  // // Search results.
  // results: {
  //   loading: false,
  //   setLoading: (data: boolean) => set(
  //     produce((state) => {
  //       state.results.loading = data;
  //     })
  //   ),
  // },
  //
  // // Checkout.
  // checkout: {
  //   bookedTickets: [],
  //   setBookedTickets: (data: Array<any>) => set(
  //     produce((state) => {
  //       state.checkout.bookedTickets = data;
  //     })
  //   ),
  // }
}));