import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { Col, DatePicker, Input, Row, Space, Typography } from 'antd';
import SelectStatuses from '@containers/Orders/Ant/components/SelectStatuses';
import { Status } from '@modelTypes/order';
import { useSelect } from '@zustandStorage/customSelect/customSelect';
import CustomSelect from '@base/CustomSelect';

export interface OrderListFiltersProps {
  onChange: (filters: Partial<Filters>) => void;
  statuses: Array<Status>;
}

export interface Filters extends Record<string, string | number | null> {
  id: string;
  startDate: string;
  endDate: string;
  saleManager: string;
  doctor: string;
  countryId: number | null;
  statusId: number | null;
}

type NonEmptyFields<T> = {
  [K in keyof T]: Exclude<T[K], null | undefined | ''>;
};

const initialFilters: Filters = {
  id: '',
  startDate: '',
  endDate: '',
  saleManager: '',
  doctor: '',
  countryId: null,
  statusId: null,
}

const { Text } = Typography;

export default function OrderListFilters(
  {
    onChange,
    statuses,
  }: OrderListFiltersProps
) {
  // const history = useHistory();

  const [filters, setFilters] = useState<Filters>(initialFilters);
  const isFirstUpdate = useRef<any>(true);
  const { fetchUserForSelect, fetchPhoneCountriesForSelect } = useSelect();


  const handleChange: StandardInputProps['onChange'] = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onChangeDate = (dateString: string, range: string) => {
    if (dateString) {
      setFilters({ ...filters, [range]: moment(dateString).format('DD/MM/YYYY') })
    } else setFilters({ ...filters, [range]: '' })
  };

  const debouncedOnChange = useMemo(
    () => debounce((filters: Partial<Filters>) => {
      onChange(filters);
    }, 400),
    []
  );

  useEffect(() => {
    if (!isFirstUpdate.current) {
      const filteredObject: NonEmptyFields<typeof filters> = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value !== null && value !== undefined && value !== '')
      ) as NonEmptyFields<typeof filters>;
      debouncedOnChange(filteredObject);
    }

    isFirstUpdate.current = false;

  }, [filters]);

  return (
    <Row gutter={[32, 16]}>
      <Col span={2}>
        <Space size={0} direction={'vertical'} style={{width: '100%'}}>
          <Text style={{ fontSize: '12px' }}>ID</Text>
          <Input name={'id'} onChange={handleChange} />
        </Space>
      </Col>
      <Col span={3}>
        <Space size={0} direction={'vertical'} style={{width: '100%'}}>
          <Text style={{ fontSize: '12px' }}>From date</Text>
          <DatePicker onChange={(date, dateString) => onChangeDate(dateString, 'startDate')} />
        </Space>
      </Col>
      <Col span={3}>
        <Space size={0} direction={'vertical'} style={{width: '100%'}}>
          <Text style={{ fontSize: '12px' }}>To date</Text>
          <DatePicker onChange={(date, dateString) => onChangeDate(dateString, 'endDate')} />
        </Space>
      </Col>
      <Col span={4}>
        <Space size={0} direction={'vertical'} style={{width: '100%'}}>
          <Text style={{ fontSize: '12px' }}>Status</Text>
          <SelectStatuses
            options={statuses}
            onChange={(value: any) => {
              if (value !== undefined) {
                setFilters({ ...filters, statusId: value.id });
              } else {
                setFilters({ ...filters, statusId: null });
              }
            }} />
        </Space>
      </Col>
      <Col span={4}>
        <Space size={0} direction={'vertical'} style={{width: '100%'}}>
          <Text style={{ fontSize: '12px' }}>Sale country</Text>
          <CustomSelect
            placeholder="Select country"
            selectId="selectCountry"
            onUpdateSelect={fetchPhoneCountriesForSelect}
            defaultQueryParams={{ page: 1, perPage: 20 }}
            onChange={(value: any) => {
              if (value && value.id) {
                setFilters({ ...filters, countryId: value.id });
              } else {
                setFilters({ ...filters, countryId: null });
              }
            }}
            style={{ width: '100%' }}
          />
        </Space>
      </Col>
      <Col span={4}>
        <Space size={0} direction={'vertical'} style={{width: '100%'}}>
          <Text style={{ fontSize: '12px' }}>Sale manager</Text>
          <CustomSelect
            placeholder="Select manager"
            selectId="selectUser"
            onUpdateSelect={fetchUserForSelect}
            defaultQueryParams={{ page: 1, perPage: 50 }}
            onChange={(value: any) => {
              if (value && value.id) {
                setFilters({ ...filters, saleManagerId: value.id });
              } else {
                setFilters({ ...filters, saleManagerId: null });
              }
            }}
            style={{ width: '100%' }}
          />
        </Space>
      </Col>
      <Col span={4}>
        <Space size={0} direction={'vertical'} style={{width: '100%'}}>
          <Text style={{ fontSize: '12px' }}>Doctor</Text>
          <CustomSelect
            placeholder="Select doctor"
            selectId="selectDoctor"
            onUpdateSelect={fetchUserForSelect}
            defaultQueryParams={{ page: 1, perPage: 50 }}
            onChange={(value: any) => {
              if (value && value.id) {
                setFilters({ ...filters, doctorId: value.id });
              } else {
                setFilters({ ...filters, doctorId: null });
              }
            }}
            style={{ width: '100%' }}
          />
        </Space>
      </Col>
    </Row>
  );
}