import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { PaginationConfig } from '@components/AntGrid/types';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeLanguage from '@components/AntGrid/hooks/useChangeLanguage';
import useChangeGridOptions from "@components/AntGrid/hooks/useChangeGridOptions";
import { useDoctorPublicationsStore } from '@zustandStorage/doctor/publication';
import { useParams } from 'react-router-dom';
import { Publication } from '@modelTypes/publication';
import {
  doctorPublicationColumns,
  doctorPublicationFiltersConfig
} from '@components/AntGrid/columns/doctorPublication';

interface RouteParams {
  id: string;
}

export const [
  refreshDoctorPublicationsGridEvent,
  dispatchRefreshDoctorPublicationsGridEvent
] = makeRefreshGridEvent('onRefreshDoctorPublicationsGrid');

export default function PublicationsGrid() {
  const { id } = useParams<RouteParams>();
  const [languageId, setFirstLoad] = useChangeLanguage((languageId) => fetchDoctorPublications(+id,{ languageId }));
  const { grid: { data, meta, loading }, fetchDoctorPublications } = useDoctorPublicationsStore();

  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
  };

  const onFetchData = async (params?: AntGetListParams) => {
    fetchDoctorPublications(+id, { ...params, languageId })
      .then(() => {
        setFirstLoad(true);
      });
  };

  useRefreshGridEvent(refreshDoctorPublicationsGridEvent, onFetchData);
  useChangeGridOptions('doctorPublicationsGrid');

  return (
    <Grid<Publication>
      id="doctorPublicationsGrid"
      onFetch={onFetchData}
      columns={doctorPublicationColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={doctorPublicationFiltersConfig}
    />
  );
}