import { ReactNode } from 'react';
import icon1 from './1';
import icon2 from './2';
import icon3 from './3';
import icon4 from './4';
import icon5 from './5';
import icon6 from './6';
import icon7 from './7';
import icon8 from './8';
import icon9 from './9';
import icon10 from './10';
import icon11 from './11';
import icon12 from './12';
import icon13 from './13';
import icon14 from './14';
import icon15 from './15';
import icon16 from './16';
import icon17 from './17';
import icon18 from './18';
import icon19 from './19';
import icon20 from './20';
import icon21 from './21';
import icon22 from './22';
import icon23 from './23';
import icon24 from './24';
import icon25 from './25';
import icon26 from './26';
import icon27 from './27';
import icon28 from './28';
import icon29 from './29';
import icon30 from './30';
import icon31 from './31';
import icon32 from './32';
import icon33 from './33';
import icon34 from './34';
import icon35 from './35';
import icon36 from './36';
import icon37 from './37';
import icon38 from './38';
import icon39 from './39';
import icon40 from './40';
import icon41 from './41';
import icon42 from './42';
import icon43 from './43';
import icon44 from './44';
import icon45 from './45';
import icon46 from './46';
import icon47 from './47';
import icon48 from './48';
import icon49 from './49';
import icon50 from './50';
import icon51 from './51';
import icon52 from './52';
import icon53 from './53';
import icon54 from './54';
import icon55 from './55';
import icon56 from './56';
import icon57 from './57';
import icon58 from './58';
import icon59 from './59';
import icon60 from './60';
import icon61 from './61';
import icon62 from './62';
import icon63 from './63';
import icon64 from './64';
import icon65 from './65';
import icon66 from './66';
import icon67 from './67';
import icon68 from './68';
import icon69 from './69';
import icon70 from './70';
import icon71 from './71';
import icon72 from './72';
import icon73 from './73';
import icon74 from './74';
import icon75 from './75';
import icon76 from './76';
import icon77 from './77';
import icon78 from './78';
import icon79 from './79';
import icon80 from './80';
import icon81 from './81';
import icon82 from './82';
import icon83 from './83';
import icon84 from './84';
import icon85 from './85';
import icon86 from './86';
import icon87 from './87';
import icon88 from './88';
import icon89 from './89';
import icon90 from './90';
import icon91 from './91';
import icon92 from './92';
import icon93 from './93';
import icon94 from './94';
import icon95 from './95';
import icon96 from './96';
import icon97 from './97';
import icon98 from './98';
import icon99 from './99';
import icon100 from './100';
import icon101 from './101';
import icon102 from './102';
import icon103 from './103';
import icon104 from './104';
import icon105 from './105';
import icon106 from './106';
import icon107 from './107';
import icon108 from './108';
import icon109 from './109';
import icon110 from './110';
import icon111 from './111';
import icon112 from './112';
import icon113 from './113';
import icon114 from './114';
import icon115 from './115';
import icon116 from './116';
import icon117 from './117';
import icon118 from './118';
import icon119 from './119';
import icon120 from './120';
import icon121 from './121';
import icon122 from './122';
import icon123 from './123';
import icon124 from './124';
import icon125 from './125';
import icon126 from './126';
import icon127 from './127';
import icon128 from './128';
import icon129 from './129';
import icon130 from './130';
import icon131 from './131';
import icon132 from './132';
import icon133 from './133';
import icon134 from './134';
import icon135 from './135';
import icon136 from './136';
import icon137 from './137';
import icon138 from './138';
import icon139 from './139';
import icon140 from './140';
import icon141 from './141';
import icon142 from './142';
import icon143 from './143';
import icon144 from './144';
import icon145 from './145';
import icon146 from './146';
import icon147 from './147';
import icon148 from './148';
import icon149 from './149';
import icon150 from './150';
import icon151 from './151';
import icon152 from './152';
import icon153 from './153';
import icon154 from './154';
import icon155 from './155';
import icon156 from './156';
import icon157 from './157';
import icon158 from './158';
import icon159 from './159';
import icon160 from './160';
import icon161 from './161';
import icon162 from './162';
import icon163 from './163';
import icon164 from './164';
import icon165 from './165';
import icon166 from './166';
import icon167 from './167';
import icon168 from './168';
import icon169 from './169';
import icon170 from './170';
import icon171 from './171';
import icon172 from './172';
import icon173 from './173';
import icon174 from './174';
import icon175 from './175';
import icon176 from './176';
import icon177 from './177';
import icon178 from './178';
import icon179 from './179';
import icon180 from './180';
import icon181 from './181';
import icon182 from './182';
import icon183 from './183';
import icon184 from './184';
import icon185 from './185';
import icon186 from './186';
import icon187 from './187';
import icon188 from './188';
import icon189 from './189';
import icon190 from './190';
import icon191 from './191';
import icon192 from './192';
import icon193 from './193';
import icon194 from './194';
import icon195 from './195';
import icon196 from './196';
import icon197 from './197';
import icon198 from './198';
import icon199 from './199';
import icon200 from './200';
import icon201 from './201';
import icon202 from './202';
import icon203 from './203';
import icon204 from './204';
import icon205 from './205';
import icon206 from './206';
import icon207 from './207';
import icon208 from './208';
import icon209 from './209';
import icon210 from './210';
import icon211 from './211';
import icon212 from './212';
import icon213 from './213';
import icon214 from './214';
import icon215 from './215';
import icon216 from './216';
import icon217 from './217';
import icon218 from './218';
import icon219 from './219';
import icon220 from './220';
import icon221 from './221';
import icon222 from './222';
import icon223 from './223';
import icon224 from './224';
import icon225 from './225';
import icon226 from './226';
import icon227 from './227';
import icon228 from './228';
import icon229 from './229';
import icon230 from './230';
import icon231 from './231';
import icon232 from './232';
import icon233 from './233';
import icon234 from './234';
import icon235 from './235';
import icon236 from './236';
import icon237 from './237';
import icon238 from './238';
import icon239 from './239';
import icon240 from './240';
import icon241 from './241';
import icon242 from './242';
import icon243 from './243';
import icon244 from './244';
import icon245 from './245';
import icon246 from './246';
import icon247 from './247';
import icon248 from './248';
import icon249 from './249';
import icon250 from './250';
import icon251 from './251';
import icon252 from './252';
import icon253 from './253';
import icon254 from './254';
import icon255 from './255';
import icon256 from './256';
import icon257 from './257';
import icon258 from './258';
import icon259 from './259';
import icon260 from './260';
import icon261 from './261';
import icon262 from './262';
import icon263 from './263';
import icon264 from './264';
import icon265 from './265';
import icon266 from './266';
import icon267 from './267';
import icon268 from './268';
import icon269 from './269';
import icon270 from './270';
import icon271 from './271';
import icon272 from './272';
import icon273 from './273';
import icon274 from './274';
import icon275 from './275';
import icon276 from './276';
import icon277 from './277';
import icon278 from './278';
import icon279 from './279';
import icon280 from './280';
import icon281 from './281';
import icon282 from './282';
import icon283 from './283';
import icon284 from './284';
import icon285 from './285';
import icon286 from './286';
import icon287 from './287';
import icon288 from './288';
import icon289 from './289';
import icon290 from './290';
import icon291 from './291';
import icon292 from './292';
import icon293 from './293';
import icon294 from './294';
import icon295 from './295';
import icon296 from './296';
import icon297 from './297';
import icon298 from './298';
import icon299 from './299';
import icon300 from './300';
import icon301 from './301';
import icon302 from './302';
import icon303 from './303';
import icon304 from './304';
import icon305 from './305';
import icon306 from './306';
import icon307 from './307';
import icon308 from './308';
import icon309 from './309';
import icon310 from './310';
import icon311 from './311';
import icon312 from './312';
import icon313 from './313';
import icon314 from './314';
import icon315 from './315';
import icon316 from './316';
import icon317 from './317';
import icon318 from './318';
import icon319 from './319';
import icon320 from './320';

export interface FieldIcon {
  id: string;
  svg: string;
  component: ReactNode;
}

export const fieldIcons = [
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  icon7,
  icon8,
  icon9,
  icon10,
  icon11,
  icon12,
  icon13,
  icon14,
  icon15,
  icon16,
  icon17,
  icon18,
  icon19,
  icon20,
  icon21,
  icon22,
  icon23,
  icon24,
  icon25,
  icon26,
  icon27,
  icon28,
  icon29,
  icon30,
  icon31,
  icon32,
  icon33,
  icon34,
  icon35,
  icon36,
  icon37,
  icon38,
  icon39,
  icon40,
  icon41,
  icon42,
  icon43,
  icon44,
  icon45,
  icon46,
  icon47,
  icon48,
  icon49,
  icon50,
  icon51,
  icon52,
  icon53,
  icon54,
  icon55,
  icon56,
  icon57,
  icon58,
  icon59,
  icon60,
  icon61,
  icon62,
  icon63,
  icon64,
  icon65,
  icon66,
  icon67,
  icon68,
  icon69,
  icon70,
  icon71,
  icon72,
  icon73,
  icon74,
  icon75,
  icon76,
  icon77,
  icon78,
  icon79,
  icon80,
  icon81,
  icon82,
  icon83,
  icon84,
  icon85,
  icon86,
  icon87,
  icon88,
  icon89,
  icon90,
  icon91,
  icon92,
  icon93,
  icon94,
  icon95,
  icon96,
  icon97,
  icon98,
  icon99,
  icon100,
  icon101,
  icon102,
  icon103,
  icon104,
  icon105,
  icon106,
  icon107,
  icon108,
  icon109,
  icon110,
  icon111,
  icon112,
  icon113,
  icon114,
  icon115,
  icon116,
  icon117,
  icon118,
  icon119,
  icon120,
  icon121,
  icon122,
  icon123,
  icon124,
  icon125,
  icon126,
  icon127,
  icon128,
  icon129,
  icon130,
  icon131,
  icon132,
  icon133,
  icon134,
  icon135,
  icon136,
  icon137,
  icon138,
  icon139,
  icon140,
  icon141,
  icon142,
  icon143,
  icon144,
  icon145,
  icon146,
  icon147,
  icon148,
  icon149,
  icon150,
  icon151,
  icon152,
  icon153,
  icon154,
  icon155,
  icon156,
  icon157,
  icon158,
  icon159,
  icon160,
  icon161,
  icon162,
  icon163,
  icon164,
  icon165,
  icon166,
  icon167,
  icon168,
  icon169,
  icon170,
  icon171,
  icon172,
  icon173,
  icon174,
  icon175,
  icon176,
  icon177,
  icon178,
  icon179,
  icon180,
  icon181,
  icon182,
  icon183,
  icon184,
  icon185,
  icon186,
  icon187,
  icon188,
  icon189,
  icon190,
  icon191,
  icon192,
  icon193,
  icon194,
  icon195,
  icon196,
  icon197,
  icon198,
  icon199,
  icon200,
  icon201,
  icon202,
  icon203,
  icon204,
  icon205,
  icon206,
  icon207,
  icon208,
  icon209,
  icon210,
  icon211,
  icon212,
  icon213,
  icon214,
  icon215,
  icon216,
  icon217,
  icon218,
  icon219,
  icon220,
  icon221,
  icon222,
  icon223,
  icon224,
  icon225,
  icon226,
  icon227,
  icon228,
  icon229,
  icon230,
  icon231,
  icon232,
  icon233,
  icon234,
  icon235,
  icon236,
  icon237,
  icon238,
  icon239,
  icon240,
  icon241,
  icon242,
  icon243,
  icon244,
  icon245,
  icon246,
  icon247,
  icon248,
  icon249,
  icon250,
  icon251,
  icon252,
  icon253,
  icon254,
  icon255,
  icon256,
  icon257,
  icon258,
  icon259,
  icon260,
  icon261,
  icon262,
  icon263,
  icon264,
  icon265,
  icon266,
  icon267,
  icon268,
  icon269,
  icon270,
  icon271,
  icon272,
  icon273,
  icon274,
  icon275,
  icon276,
  icon277,
  icon278,
  icon279,
  icon280,
  icon281,
  icon282,
  icon283,
  icon284,
  icon285,
  icon286,
  icon287,
  icon288,
  icon289,
  icon290,
  icon291,
  icon292,
  icon293,
  icon294,
  icon295,
  icon296,
  icon297,
  icon298,
  icon299,
  icon300,
  icon301,
  icon302,
  icon303,
  icon304,
  icon305,
  icon306,
  icon307,
  icon308,
  icon309,
  icon310,
  icon311,
  icon312,
  icon313,
  icon314,
  icon315,
  icon316,
  icon317,
  icon318,
  icon319,
  icon320,
];
