import { GalleriesItem } from '@modelTypes/galleries';
import { useParams } from 'react-router-dom';
import { useGalleriesDataStore } from '@zustandStorage/galleries/galleries';
import { message, Popconfirm, Space, Tooltip } from 'antd';
import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import React from 'react';

export function EditCell({ record, entityGallery }: { record: GalleriesItem, entityGallery: string }) {
  const { id } = useParams<{ id: string }>();
  const { status, rowId, form } = useGalleriesDataStore((state) => state.grid.editable);
  const { setGridEditable, updateGalleriesItem, fetchGalleriesItem } = useGalleriesDataStore();
  const [messageApi, contextHolder] = message.useMessage();

  const onClick = () => {
    setGridEditable({ status: true, rowId: record.id, form: {} })
  };
  const onSave = () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Update image...',
      duration: 0,
    });

    const formData = {
      isSensitive: form.hasOwnProperty('isSensitive') ? form.isSensitive : record.isSensitive,
      isShowInTop: form.hasOwnProperty('isShowInTop') ? form.isShowInTop : record.isShowInTop,
      name: form.hasOwnProperty('name') ? form.name : record.name,
    }

    updateGalleriesItem(record.id, formData as any)
      .then(() => {
        setGridEditable({ status: false, rowId: null, form: {} });
        fetchGalleriesItem({ [entityGallery]: +id, }).then(()=> {
          messageApi.destroy('startCreate');
          message.success('Image has been successfully updated');
        });
      })
      .catch((e) => {
        messageApi.destroy('startCreate');
        const errorMessage = e.response?.data?.errors?.[0]?.message || 'An unknown error occurred';
        message.error(errorMessage);
      })
  };
  const onCancel = () => {
    setGridEditable({ status: false, rowId: null, form: {} })
  };

  if (status && rowId === record.id) {
    return (
      <Space size={'large'}>
        {contextHolder}
        <Tooltip title="Save" placement="bottomRight">
          <SaveOutlined
            onClick={onSave}
            style={{ fontSize: 16, color: '#000' }}
          />
        </Tooltip>
        <Popconfirm title="Sure to cancel?" onConfirm={onCancel}>
          <Tooltip title="Cancel" placement="bottomRight">
            <CloseOutlined
              onClick={onClick}
              style={{ fontSize: 16, color: '#000' }}
            />
          </Tooltip>
        </Popconfirm>
      </Space>
    );
  }

  if (status) {
    return (
      <EditOutlined
        style={{ fontSize: 16, color: '#000' }}
      />
    );
  }

  return (
    <Tooltip title="Edit item">
      <EditOutlined
        style={{ fontSize: 16, color: '#000' }}
        onClick={onClick}
      />
    </Tooltip>
  );
}
