import { AppBar, Box, Button, IconButton, Menu, MenuItem, Toolbar, useMediaQuery } from '@mui/material';

import { AppState } from '@storage/app/reducer';
import Auth from '@base/Auth';
import MenuIcon from '@mui/icons-material/Menu';
import SessionTimer from '@layouts/AppLayout/SessionTimer';
import styled from '@emotion/styled';
import { useSelector } from '@hooks';
import { useState } from 'react';
import ClearCache from '@layouts/ClearCache';
import hasPermission from '@helpers/hasPermission';

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  // @ts-ignore
  backgroundColor: theme.palette.background.paper,
  // @ts-ignore
  boxShadow: theme.shadows[3]
}));

interface Props {
  onSidebarOpen: () => void;
}

export const DashboardNavbar = (props: Props): JSX.Element => {
  const user = useSelector<AppState['user']>('app.user');
  const { onSidebarOpen, ...other } = props;
  // @ts-ignore
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up(1920), {
    defaultMatches: true,
    noSsr: true
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: lgUp ? 250 : 0,
          width: lgUp ? 'calc(100% - 250px)' : '100%',
        }}
        {...other}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2
          }}
        >
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: lgUp ? 'none' :  'inline-flex',
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          {/*<Tooltip title="Search">*/}
          {/*  <IconButton sx={{ ml: 1 }}>*/}
          {/*    <SearchIcon fontSize="small" />*/}
          {/*  </IconButton>*/}
          {/*</Tooltip>*/}
          <Box sx={{ flexGrow: 1 }} />
          {/*<Tooltip title="Contacts">*/}
          {/*  <IconButton sx={{ ml: 1 }}>*/}
          {/*    <UsersIcon fontSize="small" />*/}
          {/*  </IconButton>*/}
          {/*</Tooltip>*/}
          {/*<Tooltip title="Notifications">*/}
          {/*  <IconButton sx={{ ml: 1 }}>*/}
          {/*    <Badge*/}
          {/*      badgeContent={4}*/}
          {/*      color="primary"*/}
          {/*      variant="dot"*/}
          {/*    >*/}
          {/*      <BellIcon fontSize="small" />*/}
          {/*    </Badge>*/}
          {/*  </IconButton>*/}
          {/*</Tooltip>*/}
          {hasPermission(user, 'cache_cache_clear') && (
            <ClearCache />
          )}
          <SessionTimer />
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            {user?.name}
          </Button>
          <Menu
            id="userMenu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {/*<MenuItem onClick={handleClose}>Profile</MenuItem>*/}
            {/*<MenuItem onClick={handleClose}>My account</MenuItem>*/}
            <MenuItem onClick={() => Auth.logout()}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};