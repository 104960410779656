import { Country } from '@modelTypes/country';
import { ListResponse } from '@api/types';
import { createAction } from '../helpers';

export enum ActionType {
  START_FETCH_COUNTRIES = 'COUNTRIES__START_FETCH_COUNTRIES',
  END_FETCH_COUNTRIES = 'COUNTRIES__END_FETCH_COUNTRIES',

  START_FETCH_COUNTRIES_FOR_SELECT = 'COUNTRIES__START_FETCH_COUNTRIES_FOR_SELECT',
  END_FETCH_COUNTRIES_FOR_SELECT = 'COUNTRIES__END_FETCH_COUNTRIES_FOR_SELECT',
  CLEAR_SELECT_COUNTRY = 'COUNTRIES__CLEAR_SELECT_COUNTRY',

  START_FETCH_COUNTRY = 'COUNTRIES__START_FETCH_COUNTRY',
  END_FETCH_COUNTRY = 'COUNTRIES__END_FETCH_COUNTRY',

  START_POST_COUNTRY = 'COUNTRIES__START_POST_COUNTRY',
  END_POST_COUNTRY = 'COUNTRIES__END_POST_COUNTRY',
  END_POST_COUNTRY_ERROR = 'COUNTRIES__END_POST_COUNTRY_ERROR',

  START_UPDATE_COUNTRY = 'COUNTRIES__START_UPDATE_COUNTRY',
  END_UPDATE_COUNTRY = 'COUNTRIES__END_UPDATE_COUNTRY',
  END_UPDATE_COUNTRY_ERROR = 'COUNTRIES__END_UPDATE_COUNTRY_ERROR',

  START_DELETE_COUNTRY = 'COUNTRIES__START_DELETE_COUNTRY',
  END_DELETE_COUNTRY = 'COUNTRIES__END_DELETE_COUNTRY',

  CLEAR_FORM_DATA = 'COUNTRIES__CLEAR_FORM_DATA',
}

export const startFetchCountries = createAction<ActionType.START_FETCH_COUNTRIES>(ActionType.START_FETCH_COUNTRIES);
export const endFetchCountries = createAction<ActionType.END_FETCH_COUNTRIES, ListResponse<Country>>(ActionType.END_FETCH_COUNTRIES);

export const startFetchCountriesForSelect = createAction<ActionType.START_FETCH_COUNTRIES_FOR_SELECT>(ActionType.START_FETCH_COUNTRIES_FOR_SELECT);
export const endFetchCountriesForSelect = createAction<ActionType.END_FETCH_COUNTRIES_FOR_SELECT, ListResponse<Country>>(ActionType.END_FETCH_COUNTRIES_FOR_SELECT);
export const clearSelectCountry = createAction<ActionType.CLEAR_SELECT_COUNTRY>(ActionType.CLEAR_SELECT_COUNTRY);

export const startFetchCountry = createAction<ActionType.START_FETCH_COUNTRY>(ActionType.START_FETCH_COUNTRY);
export const endFetchCountry = createAction<ActionType.END_FETCH_COUNTRY, Country>(ActionType.END_FETCH_COUNTRY);

export const startPostCountry = createAction<ActionType.START_POST_COUNTRY>(ActionType.START_POST_COUNTRY);
export const endPostCountry = createAction<ActionType.END_POST_COUNTRY, Country>(ActionType.END_POST_COUNTRY);
export const endPostCountryError = createAction<ActionType.END_POST_COUNTRY_ERROR>(ActionType.END_POST_COUNTRY_ERROR);

export const startUpdateCountry = createAction<ActionType.START_UPDATE_COUNTRY>(ActionType.START_UPDATE_COUNTRY);
export const endUpdateCountry = createAction<ActionType.END_UPDATE_COUNTRY, Country>(ActionType.END_UPDATE_COUNTRY);
export const endUpdateCountryError = createAction<ActionType.END_UPDATE_COUNTRY_ERROR>(ActionType.END_UPDATE_COUNTRY_ERROR);

export const startDeleteCountry = createAction<ActionType.START_DELETE_COUNTRY>(ActionType.START_DELETE_COUNTRY);
export const endDeleteCountry = createAction<ActionType.END_DELETE_COUNTRY>(ActionType.END_DELETE_COUNTRY);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

export type Actions =
  | ReturnType<typeof startFetchCountries>
  | ReturnType<typeof endFetchCountries>
  | ReturnType<typeof startFetchCountriesForSelect>
  | ReturnType<typeof endFetchCountriesForSelect>
  | ReturnType<typeof clearSelectCountry>
  | ReturnType<typeof startFetchCountry>
  | ReturnType<typeof endFetchCountry>
  | ReturnType<typeof startPostCountry>
  | ReturnType<typeof endPostCountry>
  | ReturnType<typeof endPostCountryError>
  | ReturnType<typeof startUpdateCountry>
  | ReturnType<typeof endUpdateCountry>
  | ReturnType<typeof endUpdateCountryError>
  | ReturnType<typeof startDeleteCountry>
  | ReturnType<typeof endDeleteCountry>
  | ReturnType<typeof clearFormData>;