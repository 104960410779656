import { useState } from 'react';
import { Button } from 'antd';
import TagModalAnt from '@containers/Tags/components/TagMoadalAnt/TagModalAnt';
import { BaseButtonProps } from 'antd/es/button/button';
import { Tag } from '@modelTypes/tag';

interface Props {
  buttonProps?: BaseButtonProps;
  data?: Tag;
}


export default function TagModalButton({buttonProps, data}: Props) {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleCloseModal = async () => {
    setIsOpenModal(false)
  }

  return (
    <>
      <Button
        type={'primary'}
        onClick={() => setIsOpenModal(true)}
        {...buttonProps}
      />
      <TagModalAnt closeModal={handleCloseModal} isOpen={isOpenModal} data={data} />
    </>
  )
}