import { AntGetListParams, CreateTemplateRequest, ListResponse, Params, UpdateTemplateRequest } from '@api/types';
import { Template, TemplateTypes } from '@modelTypes/template';
import {
  endFetchTemplate,
  endFetchTemplates,
  endPostTemplate,
  endUpdateTemplate,
  startDeleteTemplate,
  startFetchTemplate,
  startFetchTemplates,
  startPostTemplate,
  startUpdateTemplate
} from '@storage/templates/actions';

import { AxiosRequestConfig } from 'axios';
import { ThunkAction } from '@storage/types';
import api from '@api';

export function getTemplates(type: TemplateTypes, params?: AntGetListParams): ThunkAction<Promise<ListResponse<Template>>> {
  return async (dispatch): Promise<ListResponse<Template>> => {
    dispatch(startFetchTemplates());

    return api.getTemplates(type, params)
      .then((data) => {
        dispatch(endFetchTemplates({ ...data, type }));

        return data;
      });
  };
}

export function getTemplate(id: number, params?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchTemplate());

    return api.getTemplate(id, params)
      .then((data) => {
        dispatch(endFetchTemplate(data));
      });
  };
}

export function createTemplate(payload: CreateTemplateRequest): ThunkAction<Promise<Template>> {
  return async (dispatch): Promise<Template> => {
    dispatch(startPostTemplate());

    return api.createTemplate(payload)
      .then((data) => {
        dispatch(endPostTemplate(data));

        return data;
      });
  };
}

export function updateTemplate(id: number, payload: UpdateTemplateRequest): ThunkAction<Promise<Template>> {
  return async (dispatch): Promise<Template> => {
    dispatch(startUpdateTemplate());

    return api.updateTemplate(id, payload)
      .then((data) => {
        dispatch(endUpdateTemplate(data));

        return data;
      });
  };
}

export function deleteTemplate(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startDeleteTemplate());

    return api.deleteTemplate(id, config);
  };
}

// export function getAccessKey(id: number, params?: Params): ThunkAction<Promise<BlogAccessKey>> {
//   return async (dispatch): Promise<BlogAccessKey> => {
//     dispatch(startFetchAccessKey());
//
//     return api.getAccessKey(id)
//       .then((data) => {
//         dispatch(endFetchAccessKey(data));
//
//         return data;
//       });
//   };
// }
//
// export function getBlogsForSelect(payload?: Params): ThunkAction<Promise<void>> {
//   const params: GetListParams & Params = { page: 1, perPage: 5000, ...payload };
//
//   return async (dispatch): Promise<void> => {
//     dispatch(startFetchBlogsForSelect());
//
//     return api.getArticlesForSelect(params)
//       .then((data) => {
//         dispatch(endFetchBlogsForSelect(data));
//       });
//   };
// }