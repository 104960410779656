import { create } from 'zustand';
import produce from 'immer';
import {
  AntGetListParams,
  CreateCertificateRequest,
  CreateCertificateResponse,
  ListResponse,
  Params,
  UpdateCertificateRequest,
  UpdateCertificateResponse
} from '@api/types';
import { initialFormState, initialGridState } from '@zustandStorage/helpers/initialStates';
import { createCertificate, deleteCertificate, getCertificate, getCertificates, updateCertificate } from '@api';
import { AxiosRequestConfig } from 'axios';
import { Certificate } from '@modelTypes/certificate';

export interface CertificatesState {
  grid: ListResponse<Certificate> & { loading: boolean; };
  form: {
    loading: boolean;
    row: Certificate | null;
  };
  clearFormData: () => void;
  fetchCertificates: (params?: AntGetListParams) => Promise<ListResponse<Certificate>>;
  deleteCertificate: (id: number, payload?: AxiosRequestConfig) => Promise<any>;
  fetchCertificate: (id: number, params?: Params) => Promise<Certificate>;
  createCertificate: (payload: CreateCertificateRequest) => Promise<CreateCertificateResponse>;
  updateCertificate: (id: number, payload: UpdateCertificateRequest) => Promise<UpdateCertificateResponse>;
}

export const useCertificatesStore = create<CertificatesState>((set) => ({
  grid: initialGridState,
  form: initialFormState,

  // Grid
  fetchCertificates: async (params?: AntGetListParams): Promise<ListResponse<Certificate>> => {
    set(produce((state) => {
      state.grid.loading = true;
    }));

    return getCertificates(params)
      .then((response) => {
        set(produce((state) => {
          state.grid.data = response.data;
          state.grid.meta = response.meta;
          state.grid.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.grid.loading = false;
        }));

        throw e;
      })
  },
  deleteCertificate: async (id, payload?) => {
    return deleteCertificate(id, payload)
  },

  // Form
  clearFormData: () => set(() => ({ form: initialFormState })),
  fetchCertificate: async (id: number, params?: Params): Promise<Certificate> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return getCertificate(id, params)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  createCertificate: async (payload: CreateCertificateRequest): Promise<CreateCertificateResponse> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return createCertificate(payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateCertificate: async (id: number, payload: UpdateCertificateRequest): Promise<UpdateCertificateResponse> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateCertificate(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
}));