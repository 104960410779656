import { UploadOutlined } from '@ant-design/icons';
import UploadPhotoModal from '@components/UploadPhotoModal/UploadPhotoModal';
import { Button, Upload } from 'antd';
import { ButtonProps } from 'antd/es/button/button';
import { ReactNode, useState } from 'react';
import { ReactCropperProps } from 'react-cropper';

interface Props {
  handleSaveImage: (data: Cropper, type: string, image: File) => void;
  cropperProps?: ReactCropperProps & { minAspectRatio?: number; maxAspectRatio?: number };
  uploadButtonProps?: ButtonProps;
  modalClassName?: string;
  minAspectRatio?: number;
  maxAspectRatio?: number;
  preview: ReactNode;
}

export default function UploadPhotoButton({
  handleSaveImage,
  cropperProps,
  uploadButtonProps,
  modalClassName,
  preview,
}: Props) {
  const [image, setImage] = useState<File | null>(null);
  const [imgType, setImgType] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => setIsOpenModal(false);
  const onSaveImage = (data: Cropper) => {
    if (!image) {
      return;
    }

    handleSaveImage(data, imgType, image);
  };

  return (
    <>
      <Upload
        accept={'image/*'}
        showUploadList={false}
        maxCount={1}
        beforeUpload={(file) => {
          setLoading(true);
          setImage(file);

          let UploadFile = file;
          const reader = new FileReader();

          reader.onload = () => {
            setImgType(file.type);
            setIsOpenModal(true);
          };

          reader.onabort = () => {
            setLoading(false);
          };

          reader.onloadend = () => {
            setLoading(false);
          };

          reader.readAsDataURL(UploadFile);
          return false;
        }}
      >
        <>
          {preview}
          <Button
            loading={loading}
            icon={<UploadOutlined />}
            type={'link'}
            style={{ width: 460 }}
            {...uploadButtonProps}
          />
        </>
      </Upload>
      <UploadPhotoModal
        photo={image ? URL.createObjectURL(image) : ''}
        isOpen={isOpenModal}
        closeModal={handleCloseModal}
        handleSaveImage={onSaveImage}
        cropperProps={cropperProps}
        modalClassName={modalClassName}
      />
    </>
  );
}
