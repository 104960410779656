import { useEffect } from 'react';
import { useUserRolesStore } from '@zustandStorage/user/roles';

export default function useLoadUserRoles() {
  const { data, loading } = useUserRolesStore((state) => state.select);
  const { fetchUserRolesForSelect } = useUserRolesStore();

  useEffect(() => {
    if (!data.length && !loading) {
      fetchUserRolesForSelect();
    }
  }, []);

  return {data, loading};
}