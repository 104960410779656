import { Certificate } from '../../../modelTypes/certificate';
import { FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { Avatar, message, Tooltip } from 'antd';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { getIsActiveCell } from '@components/AntGrid/cells/StatusCell';
import { getDateFormattedCell } from '../cells/DateFormattedCell';
import React from 'react';
import styles from '@containers/Certificates/Index.module.scss';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import map from 'lodash/map';
import { dispatchRefreshCertificatesGridEvent } from '@containers/Certificates/components/CertificatesAntGrid';
import getIdCell from '@components/AntGrid/cells/IdCell';
import { useCertificatesStore } from '@zustandStorage/certificates/certificates';
import { EditOutlined } from '@ant-design/icons';
import CreateButton from '@containers/Certificates/components/CreateModal';

export type CertificateColumnsNames =
  `${keyof Certificate}Column`
  | 'hasDescriptionColumn'
  | 'previewColumn'
  | 'eyeIconColumn'
  | 'deleteColumn';

export const certificateColumnsDefinitions: Partial<Record<CertificateColumnsNames, ColumnType<Certificate>>> = {
  idColumn: createColumnDefinition<Certificate>('#', 'id', { width: 80, render: getIdCell }),
  nameColumn: createColumnDefinition<Certificate>('Name', 'name', { render: getNameCell }),
  isActiveColumn: createColumnDefinition<Certificate>('Active', 'isActive', {
    render: getIsActiveCell,
    width: 80,
    filtered: false
  }),
  updatedAtColumn: createColumnDefinition<Certificate>('Updated At', 'updatedAt', {
    render: getDateFormattedCell,
    width: 160
  }),
  createdAtColumn: createColumnDefinition<Certificate>('Created At', 'createdAt', {
    render: getDateFormattedCell,
    width: 160
  }),
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, row: Certificate) => {
      return <EditCell record={row} />
    },
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell(),
    sorter: false,
    filtered: false,
  }
};

export const certificateColumns: any = [
  certificateColumnsDefinitions.idColumn,
  certificateColumnsDefinitions.nameColumn,
  certificateColumnsDefinitions.updatedAtColumn,
  certificateColumnsDefinitions.createdAtColumn,
  certificateColumnsDefinitions.isActiveColumn,
  certificateColumnsDefinitions.eyeIconColumn,
  certificateColumnsDefinitions.deleteColumn,
];

export const certificateFiltersConfig: FiltersConfig = {
  [certificateColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [certificateColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [certificateColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [certificateColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function NameCell({ record }: { record: Certificate }) {
  return (
    <div className={styles.nameCell}>
      <Avatar
        // sx={{
        //   height: 50,
        //   width: 50,
        //   mr: 2,
        //   background: '#FFFFFF',
        //   borderRadius: '6px !important',
        //   img: {
        //     objectFit: 'contain',
        //   },
        // }}
        style={{ marginRight: 12, borderRadius: '6px !important' }}
        size={50}
        shape="square"
        src={record.imgPath}
      />
      {record.name}
    </div>
  );
}

function getNameCell() {
  return (value: any, record: Certificate) => {
    return (
      <NameCell record={record} />
    );
  };
}

function EditCell({ record }: { record: Certificate }) {
  return (
    <>
      <CreateButton
        data={record}
        buttonProps={{
          icon: <>
            <Tooltip title="Edit item">
              <EditOutlined />
            </Tooltip>
          </>,
          type: 'text',
        }}
      />
    </>
  )

}

// function getDeleteCell<T extends BaseRow>() {
//   return (value: any, record: T) => {
//     return (
//       <DeleteCell
//         record={record}
//         title="Delete Certificate"
//         deleteThunkAction={deleteCertificate}
//         onSuccess={() => {
//           dispatchRefreshCertificatesGridEvent();
//           message.success('The certificate has been successfully deleted');
//         }}
//         hasNotRelationsContent={() => <div>This certificate has no any relations.</div>}
//         hasRelationsContent={(data: any) => {
//           return (
//             <div style={{}}>
//               <div>This certificate is used by next hospital`s id ({map(data.relations, (i) => i.hospitalId).join(', ')})</div>
//               <div>If you really want to continue, enter your password.</div>
//             </div>
//           );
//         }}
//       />
//     );
//   };
// }


function CustomDeleteCell({ record }: { record: Certificate }) {
  const { deleteCertificate } = useCertificatesStore()
  return (
    <DeleteCell
      record={record}
      title="Delete Certificate"
      deleteAction={deleteCertificate}
      onSuccess={() => {
        dispatchRefreshCertificatesGridEvent();
        message.success('The certificate has been successfully deleted');
      }}
      hasNotRelationsContent={() => <div>This certificate has no any relations.</div>}
      hasRelationsContent={(data: any) => {
        return (
          <div style={{}}>
            <div>This certificate is used by next hospital`s id
              ({map(data.relations, (i) => i.hospitalId).join(', ')})
            </div>
            <div>If you really want to continue, enter your password.</div>
          </div>
        );
      }}
    />
  );
}

function getDeleteCell() {
  return (value: any, record: Certificate) => {
    return (
      <CustomDeleteCell record={record} />
    );
  };
}
