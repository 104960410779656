import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from '@hooks';
import { AppState } from '@storage/app/reducer';
import { getHistorySearchParam, replaceQueryParams, updateQueryParams } from '@helpers/history';
import SelectLanguage from '@base/Select/SelectLanguage';
import { Language } from '@modelTypes/language';
import { findIndex } from 'lodash';

interface Props {
  onChange?: (language: Language) => void;
  onInit?: (language: Language) => void;
  queryParamKey?: string;
  updateParamKey?: boolean;
  disabled?: boolean;
}

export default function SelectLanguagePerForm(
  {
    onInit,
    onChange,
    updateParamKey = true,
    disabled = false,
    queryParamKey = 'language-tab',
  }: Props
) {
  const history = useHistory();
  const languages = useSelector<AppState['languages']>('app.languages');
  const [value, setValue] = useState<any>(null);
  const defaultOnChange = (language: Language) => {
    updateQueryParams(history, {
      ['language-id']: language.id > 1 ? language.id : null,
    });
  };

  const handleClear = () => {
    const defaultLanguage = languages[0];

    if (defaultLanguage) {
      if (updateParamKey) {
        replaceQueryParams(history, { [queryParamKey]: findIndex(languages, (i) => i.id === defaultLanguage.id) });
      }

      setValue({ label: defaultLanguage.name, value: defaultLanguage.id });
      onChange ? onChange(defaultLanguage) : defaultOnChange(defaultLanguage);
    }
  };

  const handleSelectLanguage = (option: any) => {
    if (option && option.id) {
      if (updateParamKey) {
        replaceQueryParams(history, { [queryParamKey]: findIndex(languages, (i) => i.id === option.id) });
      }
      setValue({ label: option.name, value: option.id })
      onChange ? onChange(option) : defaultOnChange(option);
    }
  };

  useEffect(() => {
    const activeTab = parseInt(getHistorySearchParam(history, queryParamKey, updateParamKey ? 0 : 1), 10);

    if (languages.length) {
      const lang = updateParamKey ? languages[activeTab] : languages.find((i) => i.id === activeTab);

      if (lang) {
        setValue({ label: lang.name, value: lang.id })
        onInit ? onInit(lang) : defaultOnChange(lang);
      }
    }
  }, [languages]);

  // console.log('default, ', 'SelectLanguagePerForm --- ', languages, activeTab, value);

  // const placeholderValue = !placeholder ? multiple ? 'Select languages' : 'Select language' : placeholder;
  //
  // const handleChange = (value: string[], option: any) => {
  //   onChange(option);
  // };

  // useEffect(() => {
  //   if (!data.length) {
  //     dispatch(getCountriesForSelect());
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!isEqual(prevDefaultValue, defaultValue)) {
  //     setValue(find(data, ['id', defaultValue]) || null);
  //   }
  // }, [data, defaultValue]);


  return (
    <SelectLanguage
      disabled={disabled}
      loading={!languages.length}
      options={languages}
      // disabled={form.cityId > 0}
      value={value}
      onChange={handleSelectLanguage}
      onClear={handleClear}
      style={{ width: 150 }}
    />
  );
}
