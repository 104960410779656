export const initialFormState = {
  loading: false,
  row: null,
};

export const initialGridState = {
  loading: false,
  data: [],
  meta: {
    currentPage: 1,
    perPage: 20,
  },
};

export const initialSelectState = {
  loading: false,
  search: '',
  data: [],
};

export const initialFormRoleState = {
  loading: false,
  row: null,
  permissions: null,
};