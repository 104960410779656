import { useHistory } from 'react-router-dom';
import TagsAntGrid from '@containers/Tags/components/TagsAntGrid';
import { PageHeader } from '@ant-design/pro-layout';
import TagModalAnt from '@containers/Tags/components/TagMoadalAnt';

export default function TagsIndex(): JSX.Element {
  const history = useHistory();

  // const handleOnOpenModal = () => dispatch(openModalWithForm());
  // const handleOnCloseModal = () => dispatch(closeModalWidthForm());

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.push("/hospitals")}
        title="Tags"
        extra={(
          <TagModalAnt buttonProps={{children: 'Add Tag'}}/>
          // <Button
          //   key="createTag"
          //   type="primary"
          //   onClick={handleOnOpenModal}
          // >
          //   Add Tag
          // </Button>
        )}
      />
      <TagsAntGrid />
      {/*<TagModalForm*/}
      {/*  onClose={handleOnCloseModal}*/}
      {/*/>*/}
    </>
  );
}
