import { BaseRow } from '@components/AntGrid/types';
import React from 'react';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';

export function getIsActiveCell<T extends BaseRow>() {
  return (value: any) => {
    if (value) {
      return <CheckOutlined />;
    }

    return null;
  };
}
