import * as React from 'react';

import { Prompt, useHistory, useParams } from 'react-router-dom';
import { Language } from '@modelTypes/language';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSelector } from '@hooks';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin, Tooltip
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import { SaveOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import urlSlug from 'url-slug';
import { CreateCountryRequest } from '@api/types';
import { isEqual, pick } from 'lodash';
import { createCountry, getCountry, updateCountry } from '@storage/countries/thunkActions';
import { CountriesState } from '@storage/countries/reducer';
import { clearFormData, endPostCountryError, endUpdateCountryError } from '@storage/countries/actions';
import { Country } from '@modelTypes/country';
import { setFormErrors } from '@api/helpers';
import { useGridStore } from "@zustandStorage/grid/grid";
import QueryString from "query-string";

export default function CountryCreate() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { loading, row } = useSelector<CountriesState['form']>('countries.form');
  const [messageApi, contextHolder] = message.useMessage();
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);
  const [languageId, setLanguageId] = React.useState<number>(0);
  const [form] = Form.useForm<CreateCountryRequest>();

  const onSelectLanguage = (language: Language) => {
    setLanguageId(language.id);

    if (id) {
      dispatch(getCountry(parseInt(id, 10), { languageId: language.id }) as unknown as Promise<any>)
        .then(() => {
          resetForm();
        });
    }
  };

  const handleOnBlur = () => {
    if (isEmpty(form.getFieldValue('urlSlug')) && !isEmpty(form.getFieldValue('name'))) {
      const value = urlSlug(form.getFieldValue('name'));
      form.setFieldValue('urlSlug', value);
    }

    if (!isEmpty(form.getFieldValue('code'))) {
      form.setFieldValue('code', form.getFieldValue('code').toUpperCase());
    }
  };

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving country...',
      duration: 0,
    });

    setFormSubmitting(true);
    const formData = { ...form.getFieldsValue(), languageId };

    if (id) {
      await dispatch(updateCountry(parseInt(id, 10), formData as any) as unknown as Promise<Country>)
        .then(() => {
          messageApi.destroy('startCreate');
          message.success('The Country has been successfully updated');
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          dispatch(endUpdateCountryError());
          setFormSubmitting(false);
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    }
    else {
      await dispatch(createCountry(formData as any) as unknown as Promise<Country>)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The Country has been successfully created');
          history.push(`/countries/${data.id}/edit`);
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          dispatch(endPostCountryError());
          setFormSubmitting(false);
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    }
  };

  useEffect(() => {
    resetForm();
  }, [languageId, id]);

  useEffect(() => {
    if (formChanged) {
      const fieldsToCompare = ['name', 'urlSlug', 'isActive'];

      if (isEqual(pick(row, fieldsToCompare), pick(form.getFieldsValue(), fieldsToCompare))) {
        return setFormChanged(false);
      }
    }
  }, [formChanged]);

  useEffect(() => {
    return () => {
      dispatch(clearFormData());
    };
  }, []);

  return (
    <>
      {contextHolder}
      <Prompt
        when={!formSubmitting && formChanged}
        message={(location) =>
          `Data not saved!\nAre you sure you want to go to ${location.pathname}?`
        }
      />
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.countriesAntGrid || {};
          history.push(`/countries?${QueryString.stringify(params)}`)
        }
      }
        title={`${row ? 'Edit' : 'Create'} Country`}
        extra={[
          <SelectLanguagePerForm
            onInit={onSelectLanguage}
            onChange={onSelectLanguage}
            disabled={!row}
          />,
          <Button
            key="save"
            type="primary"
            loading={formSubmitting}
            onClick={() => form.submit()}
          >
            {id ? 'Update' : 'Create'}
          </Button>
        ]}
      />
      <Spin spinning={loading || languageId === 0}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          id={'test'}
        >
          <Row gutter={[32, 24]}>
            <Col span={16}>
              <Form.Item
                name="name"
                label="Name"
                messageVariables={{ name: 'Name'}}
                rules={[{ required: true, type: 'string',  max: 255 }]}
                initialValue={row?.name}
              >
                <Input onBlur={handleOnBlur} placeholder="Enter name" />
              </Form.Item>
              <Form.Item
                name="urlSlug"
                label="Url Slug"
                messageVariables={{ name: 'Url Slug'}}
                rules={[{ required: true, type: 'string', max: 128 }]}
                initialValue={row?.urlSlug}
              >
                <Input disabled={languageId > 1} placeholder="Enter url slug" />
              </Form.Item>
              <Form.Item
                name="code"
                label="Code"
                messageVariables={{ name: 'Code'}}
                help="Uppercase only. Example: UA, US, UM"
                rules={[{ required: true, type: 'string',  max: 2 }]}
                initialValue={row?.code}
              >
                <Input onBlur={handleOnBlur} placeholder="Enter code" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Card title="Statuses" bordered={false} style={{ marginTop: 24 }}>
                <Form.Item
                  name="isActive"
                  valuePropName="checked"
                  initialValue={row ? row?.isActive : false}
                  style={{ margin: 0 }}
                >
                  <Checkbox disabled={languageId > 1}>Active</Checkbox>
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Tooltip title={id ? 'Update' : 'Create'} placement="topRight">
        <Button
          style={{ position: 'fixed', bottom: 20, right: 16 }}
          onClick={() => form.submit()}
          shape="circle"
          icon={<SaveOutlined />}
          size="large"
        />
      </Tooltip>
    </>
  );
}
