import { create } from 'zustand';
import {
  AntGetListParams,
  CreateTreatmentMethodRequest,
  CreateTreatmentMethodResponse,
  CreateTreatmentTypeRequest,
  CreateTreatmentTypeResponse,
  ListResponse,
  Params,
  UpdateTreatmentMethodRequest,
  UpdateTreatmentMethodResponse,
  UpdateTreatmentTypeRequest,
  UpdateTreatmentTypeResponse
} from '@api/types';
import produce from 'immer';
import { TreatmentMethod, TreatmentType } from '@modelTypes/treatment';
import { initialFormState, initialGridState } from '@zustandStorage/helpers/initialStates';
import {
  createTreatmentType,
  deleteTreatmentType,
  getTreatmentType,
  getTreatmentTypes,
  updateTreatmentType
} from '@api';
import { AxiosRequestConfig } from 'axios';

export interface TypesState {
   grid: ListResponse<TreatmentType> & { loading: boolean }
   form: {
     loading: boolean;
     row: TreatmentType | null;
  };
  clearFormData: () => void;
  fetchTypes: (params?: AntGetListParams) => Promise<ListResponse<TreatmentType>>;
  fetchType: (id: number, params?: Params) => Promise<TreatmentType>;
  createType: (payload: CreateTreatmentTypeRequest) => Promise<CreateTreatmentTypeResponse>;
  updateType: (id: number, payload: UpdateTreatmentTypeRequest) => Promise<UpdateTreatmentTypeResponse>;
  deleteType: (id: number, payload?: AxiosRequestConfig) => Promise<any>;
}

export const useTypeOfCareState = create<TypesState>((set) => ({
  grid: initialGridState,
  form: initialFormState,
  clearFormData: () => set(() => ({ form: initialFormState })),

  fetchTypes: async (params) => {
    set(produce((state) => {
      state.grid.loading = true;
    }));

    return getTreatmentTypes(params)
      .then((response) => {
        set(produce((state) => {
          state.grid.data = response.data;
          state.grid.meta = response.meta
          state.grid.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.loading = false;
        }));

        throw e;
      });
  },
  fetchType: async (id: number, params?: Params): Promise<TreatmentMethod> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return getTreatmentType(id, params)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  createType: async (payload: CreateTreatmentMethodRequest): Promise<CreateTreatmentMethodResponse> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return createTreatmentType(payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateType: async (id: number, payload: UpdateTreatmentMethodRequest): Promise<UpdateTreatmentMethodResponse> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateTreatmentType(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  deleteType: async (id, payload?) => {
    return deleteTreatmentType(id, payload)
  },
}));