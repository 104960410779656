import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="31" height="22" viewBox="0 0 31 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M26.4943 13.456C26.177 13.8837 26.0455 14.4089 26.1248 14.934C26.2042 15.4615 26.4853 15.9277 26.916 16.2466C27.2598 16.499 27.6678 16.6327 28.0963 16.6327C28.7303 16.6327 29.3302 16.3289 29.7028 15.8204C30.0179 15.3912 30.1471 14.8645 30.0677 14.3371C29.9892 13.8157 29.7149 13.357 29.2955 13.0442L29.2804 13.0329C28.9366 12.7774 28.5285 12.6422 28.1016 12.6422C27.4684 12.6422 26.8676 12.9467 26.4959 13.456H26.4943ZM28.3797 13.5361L27.1313 15.231C27.0475 15.095 26.9938 14.9522 26.9727 14.8063C26.9266 14.5079 27.0014 14.2094 27.1835 13.9653C27.3966 13.6736 27.7396 13.4998 28.1008 13.4998C28.1923 13.4998 28.2837 13.5119 28.3789 13.5369L28.3797 13.5361ZM29.0136 15.3156C28.8021 15.6043 28.459 15.7773 28.0978 15.7773C28.0049 15.7773 27.9119 15.7652 27.8205 15.7418L29.0658 14.0499C29.3053 14.4429 29.2864 14.9446 29.0136 15.3156Z"
    fill="currentColor" />
  <path
    d="M27.9369 18.1553H25.5717V11.18C25.5717 10.5551 25.2672 10.0292 24.739 9.73827L24.0438 9.33325C23.8768 9.23804 23.7793 9.0922 23.7582 8.90707H23.9395C24.238 8.90707 24.4806 8.66451 24.4806 8.36604V6.85779C24.4806 6.55855 24.238 6.31524 23.9395 6.31524H18.0856C17.7871 6.31524 17.5446 6.55855 17.5446 6.85779V8.36604C17.5446 8.66451 17.7871 8.90707 18.0856 8.90707H18.267C18.2458 9.0922 18.1468 9.23955 17.976 9.33703L17.2945 9.7345C16.7685 10.0315 16.4542 10.5717 16.4542 11.18V19.3031C16.458 19.5291 16.6363 20.1253 16.6567 20.1925L16.6597 20.2008C16.9182 20.9119 17.6201 20.9935 18.1257 20.9935L23.0796 20.995C23.4151 21.6086 24.0672 22 24.7685 22H27.9391C28.997 22 29.8577 21.1356 29.8577 20.0731V20.0225C29.8305 18.9873 28.9789 18.1546 27.9376 18.1546L27.9369 18.1553ZM25.8097 19.2389V20.9149H24.767C24.3098 20.9149 23.9237 20.5295 23.9237 20.0731C23.9237 19.6167 24.3015 19.2389 24.767 19.2389H25.8097ZM24.4889 18.0616V18.1765C23.6101 18.3049 22.924 19.0303 22.8484 19.9099H18.1241C17.8914 19.9099 17.6813 19.7724 17.5876 19.5593L17.5786 19.5336C17.5514 19.4633 17.537 19.3923 17.537 19.3228V18.0631L24.4889 18.0616ZM17.537 16.9795V12.2636H24.4881V16.9795H17.537ZM19.3536 8.90707H22.6731C22.698 9.48362 23.0056 9.9899 23.5043 10.2718L24.204 10.6806C24.3906 10.7826 24.4889 10.9556 24.4889 11.18H17.5378C17.5378 10.9586 17.6398 10.7833 17.8332 10.673L18.5148 10.2755C19.0188 9.99141 19.3279 9.48438 19.3536 8.90707ZM23.3977 7.82273H18.6274V7.39958H23.3977V7.82273ZM28.7726 20.0739C28.7726 20.5378 28.3985 20.9157 27.9384 20.9157H26.8941V19.2397H27.9384C28.3985 19.2397 28.7726 19.6137 28.7726 20.0739Z"
    fill="currentColor" />
  <path
    d="M14.4248 20.5847C14.4158 20.2887 14.1734 20.0538 13.8774 20.0506C10.3016 20.0115 6.5564 19.5793 3.13835 18.7644C2.98571 18.7258 2.82249 18.7583 2.69177 18.8535C2.07668 19.3031 1.73815 19.4573 1.57343 19.5102C1.5772 19.3386 1.62859 18.9706 1.88248 18.2551C1.91648 18.1561 1.92177 18.0518 1.89911 17.9566C0.809476 13.1243 0.809476 8.01693 1.89911 3.18614C1.92253 3.08564 1.91648 2.98137 1.88173 2.88389C1.62632 2.16603 1.57645 1.79879 1.57343 1.62878C1.72984 1.67865 2.06459 1.83053 2.69177 2.28845C2.81872 2.38215 2.98118 2.41539 3.13684 2.37837C10.3955 0.651737 18.069 0.651737 25.3261 2.37837C25.4788 2.41691 25.6412 2.38441 25.7727 2.28996C26.3999 1.83355 26.7377 1.68091 26.8963 1.63029C26.8918 1.80031 26.8404 2.16603 26.5828 2.88389C26.5472 2.98363 26.5412 3.08867 26.5639 3.1869C26.8713 4.54159 27.1072 6.08945 27.2452 7.54962C27.2727 7.84117 27.5237 8.06155 27.8161 8.0464C28.12 8.03067 28.3514 7.76752 28.3231 7.46462C28.1863 6.00127 27.9561 4.45834 27.655 3.09925C28.1069 1.78973 28.088 1.07112 27.5945 0.701609C27.1102 0.336636 26.4331 0.505899 25.339 1.26834C18.072 -0.422779 10.3947 -0.422779 3.12702 1.26834C2.03663 0.50741 1.36185 0.338148 0.876728 0.699342C0.382541 1.06885 0.361384 1.78746 0.808721 3.09849C-0.269574 7.98898 -0.269574 13.1545 0.808721 18.0435C0.360628 19.356 0.381786 20.0746 0.876728 20.4426C1.36185 20.8046 2.03663 20.6353 3.1255 19.8744C6.5742 20.6788 10.3186 21.105 13.8774 21.1459C14.1864 21.1495 14.4342 20.8936 14.4248 20.5847Z"
    fill="currentColor" />
  <path
    d="M4.21513 8.88894L4.26878 8.98641L4.28087 8.98415C4.38213 9.10203 4.53175 9.17155 4.69194 9.17155H8.72855C9.02703 9.17155 9.26959 8.92899 9.26959 8.63051C9.26959 8.33203 9.02703 8.08947 8.72855 8.08947H5.69694L7.99785 4.59843C8.10666 4.43143 8.11573 4.21986 8.02203 4.04379C7.92682 3.86848 7.74396 3.75967 7.54523 3.75967H3.51541C3.21618 3.75967 2.97287 4.00299 2.97287 4.30222C2.97287 4.60145 3.21618 4.84326 3.51541 4.84326H6.53872L4.2378 8.3343C4.12899 8.50205 4.11992 8.71439 4.21513 8.88894Z"
    fill="currentColor" />
  <path
    d="M21.9227 13.7847V13.0623C21.9227 12.856 21.7557 12.689 21.5502 12.689H20.6208C20.4152 12.689 20.2482 12.8568 20.2482 13.0623V13.7847H19.5274C19.3218 13.7847 19.1548 13.9517 19.1548 14.1572V15.0867C19.1548 15.2922 19.3218 15.4592 19.5274 15.4592H20.2498V16.1816C20.2498 16.3871 20.4167 16.5541 20.6223 16.5541H21.5517C21.7573 16.5541 21.9242 16.3871 21.9242 16.1816V15.4592H22.6398C22.8454 15.4592 23.0124 15.2922 23.0124 15.0867V14.1572C23.0124 14.0567 22.9731 13.963 22.9028 13.8928C22.8325 13.8225 22.7403 13.7855 22.6391 13.7847H21.9227ZM20.623 14.7134H19.9007V14.5298H20.623C20.8286 14.5298 20.9956 14.3628 20.9956 14.1572V13.4349H21.1792V14.1572C21.1792 14.3628 21.3462 14.5298 21.5517 14.5298H22.2673V14.7134H21.5517C21.3462 14.7134 21.1792 14.8811 21.1792 15.0867V15.8091H20.9956V15.0867C20.9956 14.8804 20.8286 14.7134 20.623 14.7134Z"
    fill="currentColor" />
  <path
    d="M9.77662 7.25752C9.87108 7.43283 10.0539 7.54239 10.2534 7.54239H12.5883C12.8876 7.54239 13.1309 7.29908 13.1309 6.99985C13.1309 6.70061 12.8876 6.45881 12.5883 6.45881H11.2599L12.3601 4.79338C12.4705 4.62714 12.4803 4.41405 12.3851 4.23875C12.2906 4.06268 12.1078 3.95387 11.9083 3.95387H9.57336C9.27488 3.95387 9.03232 4.19719 9.03232 4.49642C9.03232 4.79565 9.27488 5.03746 9.57336 5.03746H10.9018L9.80156 6.70212C9.68972 6.86836 9.6799 7.08145 9.77587 7.25827L9.77662 7.25752Z"
    fill="currentColor" />
  <path
    d="M14.154 10.0587H13.8072L14.2115 9.44433C14.3218 9.27809 14.3316 9.065 14.2364 8.88894C14.1412 8.71363 13.9583 8.60482 13.7596 8.60482H12.4062C12.1078 8.60482 11.8652 8.84738 11.8652 9.14585C11.8652 9.44433 12.1078 9.6884 12.4062 9.6884H12.7531L12.3488 10.3005C12.2385 10.4652 12.2287 10.6783 12.3224 10.8566V10.8581C12.4191 11.0334 12.6012 11.1422 12.7999 11.1422H14.1533C14.4525 11.1422 14.6958 10.8989 14.6958 10.5997C14.6958 10.3005 14.4533 10.0587 14.154 10.0587Z"
    fill="currentColor" />
</svg>`;

export default { id: '274-sleep-medicine', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
