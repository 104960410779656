import { AntGetListParams, CreateCityRequest, GetListParams, Params, UpdateCityRequest } from '@api/types';
import {
  endFetchCities,
  endFetchCitiesForSelect,
  endFetchCity,
  endPostCity,
  endUpdateCity,
  startDeleteCity,
  startFetchCities,
  startFetchCitiesForSelect,
  startFetchCity,
  startPostCity,
  startUpdateCity,
} from './actions';

import { AxiosRequestConfig } from 'axios';
import { City } from '@modelTypes/city';
import { ThunkAction } from '@storage/types';
import api from '@api';

export function getCities(params?: AntGetListParams): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchCities());

    return api.getCities(params)
      .then((data) => {
        dispatch(endFetchCities(data));
      });
  };
}

export function getCitiesForSelect(countryId?: number, params?: Params): ThunkAction<Promise<void>> {
  const allParams: GetListParams & Params = { page: 1, perPage: 5000, countryId, ...params };

  return async (dispatch): Promise<void> => {
    dispatch(startFetchCitiesForSelect(countryId));

    return api.getCitiesForSelect(allParams)
      .then((data) => {
        dispatch(endFetchCitiesForSelect(data));
      });
  };
}

export function getCity(id: number, params?: Params): ThunkAction<Promise<City>> {
  return async (dispatch): Promise<City> => {
    dispatch(startFetchCity());

    return api.getCity(id, params)
      .then((data) => {
        dispatch(endFetchCity(data));

        return data;
      });
  };
}

export function createCity(payload: CreateCityRequest): ThunkAction<Promise<City>> {
  return async (dispatch): Promise<City> => {
    dispatch(startPostCity());

    return api.createCity(payload)
      .then((data) => {
        dispatch(endPostCity(data));

        return data;
      });
  };
}

export function updateCity(id: number, payload: UpdateCityRequest): ThunkAction<Promise<City>> {
  return async (dispatch): Promise<City> => {
    dispatch(startUpdateCity());

    return api.updateCity(id, payload)
      .then((data) => {
        dispatch(endUpdateCity(data));

        return data;
      });
  };
}

export function deleteCity(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startDeleteCity());

    return api.deleteCity(id, config);
  };
}
