import { Template } from '@modelTypes/template';
import { TemplatesState } from '@storage/templates/reducer';
import { createAction } from '../helpers';

export enum ActionType {
  START_FETCH_TEMPLATES = 'TEMPLATES__START_FETCH_TEMPLATES',
  END_FETCH_TEMPLATES = 'TEMPLATES__END_FETCH_TEMPLATES',

  // START_FETCH_BLOGS_FOR_SELECT = 'BLOGS__START_FETCH_BLOGS_FOR_SELECT',
  // END_FETCH_BLOGS_FOR_SELECT = 'BLOGS__END_FETCH_BLOGS_FOR_SELECT',
  // CLEAR_BLOGS_FOR_SELECT = 'BLOGS__CLEAR_BLOGS_FOR_SELECT',

  START_FETCH_TEMPLATE = 'TEMPLATES__START_FETCH_TEMPLATE',
  END_FETCH_TEMPLATE = 'TEMPLATES__END_FETCH_TEMPLATE',

  // START_FETCH_ACCESS_KEY = 'TEMPLATES__START_FETCH_ACCESS_KEY',
  // END_FETCH_ACCESS_KEY = 'TEMPLATES__END_FETCH_ACCESS_KEY',

  START_POST_TEMPLATE = 'TEMPLATES__START_POST_TEMPLATE',
  END_POST_TEMPLATE = 'TEMPLATES__END_POST_TEMPLATE',
  END_POST_TEMPLATE_ERROR = 'TEMPLATES__END_POST_TEMPLATE_ERROR',

  START_PATCH_TEMPLATE = 'TEMPLATES__START_PATCH_TEMPLATE',
  END_PATCH_TEMPLATE = 'TEMPLATES__END_PATCH_TEMPLATE',
  END_PATCH_TEMPLATE_ERROR = 'TEMPLATES__END_PATCH_TEMPLATE_ERROR',

  START_DELETE_TEMPLATE = 'TEMPLATES__START_DELETE_TEMPLATE',
  END_DELETE_TEMPLATE = 'TEMPLATES__END_DELETE_TEMPLATE',

  CLEAR_FORM_DATA = 'TEMPLATES__CLEAR_FORM_DATA',
}

type EndFetchTemplates = Omit<TemplatesState['grid'], 'loading'>;

export const startFetchTemplates = createAction<ActionType.START_FETCH_TEMPLATES>(ActionType.START_FETCH_TEMPLATES);
export const endFetchTemplates = createAction<ActionType.END_FETCH_TEMPLATES, EndFetchTemplates>(ActionType.END_FETCH_TEMPLATES);

// export const startFetchBlogsForSelect = createAction<ActionType.START_FETCH_BLOGS_FOR_SELECT>(ActionType.START_FETCH_BLOGS_FOR_SELECT);
// export const endFetchBlogsForSelect = createAction<ActionType.END_FETCH_BLOGS_FOR_SELECT, ListResponse<Blog>>(ActionType.END_FETCH_BLOGS_FOR_SELECT);
// export const clearBlogsForSelect = createAction<ActionType.CLEAR_BLOGS_FOR_SELECT>(ActionType.CLEAR_BLOGS_FOR_SELECT);

export const startFetchTemplate = createAction<ActionType.START_FETCH_TEMPLATE>(ActionType.START_FETCH_TEMPLATE);
export const endFetchTemplate = createAction<ActionType.END_FETCH_TEMPLATE, Template>(ActionType.END_FETCH_TEMPLATE);

// export const startFetchAccessKey = createAction<ActionType.START_FETCH_ACCESS_KEY>(ActionType.START_FETCH_ACCESS_KEY);
// export const endFetchAccessKey = createAction<ActionType.END_FETCH_ACCESS_KEY, TemplateAccessKey>(ActionType.END_FETCH_ACCESS_KEY);

export const startPostTemplate = createAction<ActionType.START_POST_TEMPLATE>(ActionType.START_POST_TEMPLATE);
export const endPostTemplate = createAction<ActionType.END_POST_TEMPLATE, Template>(ActionType.END_POST_TEMPLATE);
export const endPostTemplateError = createAction<ActionType.END_POST_TEMPLATE_ERROR>(ActionType.END_POST_TEMPLATE_ERROR);

export const startUpdateTemplate = createAction<ActionType.START_PATCH_TEMPLATE>(ActionType.START_PATCH_TEMPLATE);
export const endUpdateTemplate = createAction<ActionType.END_PATCH_TEMPLATE, Template>(ActionType.END_PATCH_TEMPLATE);
export const endUpdateTemplateError = createAction<ActionType.END_PATCH_TEMPLATE_ERROR>(ActionType.END_PATCH_TEMPLATE_ERROR);

export const startDeleteTemplate = createAction<ActionType.START_DELETE_TEMPLATE>(ActionType.START_DELETE_TEMPLATE);
export const endDeleteTemplate = createAction<ActionType.END_DELETE_TEMPLATE>(ActionType.END_DELETE_TEMPLATE);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

export type Actions =
  | ReturnType<typeof startFetchTemplates>
  | ReturnType<typeof endFetchTemplates>
  // | ReturnType<typeof startFetchBlogsForSelect>
  // | ReturnType<typeof endFetchBlogsForSelect>
  // | ReturnType<typeof clearBlogsForSelect>
  | ReturnType<typeof startFetchTemplate>
  | ReturnType<typeof endFetchTemplate>
  // | ReturnType<typeof startFetchAccessKey>
  // | ReturnType<typeof endFetchAccessKey>
  | ReturnType<typeof startPostTemplate>
  | ReturnType<typeof endPostTemplate>
  | ReturnType<typeof endPostTemplateError>
  | ReturnType<typeof startUpdateTemplate>
  | ReturnType<typeof endUpdateTemplate>
  | ReturnType<typeof endUpdateTemplateError>
  | ReturnType<typeof startDeleteTemplate>
  | ReturnType<typeof endDeleteTemplate>
  | ReturnType<typeof clearFormData>;