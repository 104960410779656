import { ActionType, Actions } from './actions';

import { Author } from '@modelTypes/author';
import { ListResponse } from '@api/types';

export interface AuthorsState {
  readonly grid: ListResponse<Author> & { loading: boolean }
  readonly form: {
    readonly loading: boolean;
    readonly row: Author | null;
  };
  readonly selectAuthor: {
    readonly loading: boolean;
    readonly search: string | null;
    readonly data: Array<Author>;
  };
}

const initialState: AuthorsState = {
  grid: {
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  form: {
    loading: false,
    row: null,
  },
  selectAuthor: {
    loading: false,
    search: null,
    data: [],
  }
};

export default function doctors(state = initialState, action: Actions): AuthorsState {
  switch (action.type) {
    case ActionType.START_FETCH_AUTHORS:
    // case ActionType.START_DELETE_AUTHOR:
      return {
        ...state,
        grid: {
          ...state.grid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_AUTHORS:
      return {
        ...state,
        grid: {
          ...action.data,
          loading: false,
        },
      }
    case ActionType.START_FETCH_AUTHORS_FOR_SELECT:
      return {
        ...state,
        selectAuthor: {
          ...state.selectAuthor,
          loading: true,
        }
      }
    case ActionType.END_FETCH_AUTHORS_FOR_SELECT:
      return {
        ...state,
        selectAuthor: {
          ...state.selectAuthor,
          loading: false,
          data: action.data.data,
        }
      }
    case ActionType.CLEAR_SELECT_AUTHOR_DATA:
      return {
        ...state,
        selectAuthor: initialState.selectAuthor,
      }
    case ActionType.START_FETCH_AUTHOR:
    case ActionType.START_UPLOAD_AUTHOR_CERTIFICATES:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        }
      }
    case ActionType.END_UPLOAD_AUTHOR_CERTIFICATES:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        }
      }
    case ActionType.END_FETCH_AUTHOR:
    case ActionType.END_POST_AUTHOR:
    case ActionType.END_PATCH_AUTHOR:
      return {
        ...state,
        form: {
          ...state.form,
          row: action.data,
          loading: false,
        },
      }
    case ActionType.START_POST_AUTHOR:
    case ActionType.START_PATCH_AUTHOR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        },
      }
    case ActionType.END_POST_AUTHOR_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.END_PATCH_AUTHOR_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.CLEAR_FORM_DATA:
      return {
        ...state,
        form: {
          ...initialState.form,
        }
      }
    default:
      return state;
  }
}