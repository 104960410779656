import { AntGetListParams, CreateFrontendLanguage, GetListParams, UpdateFrontendLanguage } from "@api/types";
import {
  closeModalWidthForm,
  endFetchFrontendDictionary,
  endPostFrontendDictionary,
  endPostFrontendTranslation,
  endUpdateFrontendTranslation,
  startDeleteFrontendTranslation,
  startFetchFrontendDictionary,
  startPostFrontendDictionary,
  startPostFrontendTranslation,
  startUpdateFrontendTranslation
} from './actions';

import { AxiosRequestConfig } from 'axios';
import { ThunkAction } from '@storage/types';
import api from '@api';
import { startFetchLanguages } from '@storage/app/actions';

export function getFrontendLanguages(type: number, params?: AntGetListParams): ThunkAction<Promise<void>> {
  return async (dispatch) => {
    dispatch(startFetchFrontendDictionary());

    api.getFrontendLanguages(type, params)
      .then((data) => {
        dispatch(endFetchFrontendDictionary(data));
      });
  };
}

export function createFrontendLanguage(payload: CreateFrontendLanguage): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startPostFrontendTranslation());

    return api.createFrontendLanguages(payload)
      .then((data) => {
        dispatch(endPostFrontendTranslation(data));
        dispatch(closeModalWidthForm());
      });
  };
}

export function updateFrontendLanguage(id: number, payload: UpdateFrontendLanguage): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startUpdateFrontendTranslation());

    return api.updateFrontendLanguages(id, payload)
      .then((data) => {
        dispatch(endUpdateFrontendTranslation(data));
        dispatch(closeModalWidthForm());
      });
  };
}

export function updateFrontendDictionary(): ThunkAction<Promise<any>> {
  return async (dispatch) => {
    dispatch(startPostFrontendDictionary());

    return api.updateFrontendDictionary()
      .then((data) => {
        dispatch(endPostFrontendDictionary());

        return data;
      });
  };
}

export function deleteFrontendLanguage(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startDeleteFrontendTranslation());

    return api.deleteFrontendLanguages(id, config)
      .then();
  };
}