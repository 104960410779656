import {
  AntGetListParams,
  CreateSearchSectionRelationRequest,
  CreateSearchSectionRelationResponse,
  CreateSearchSectionRequest,
  CreateSearchSectionResponse,
  GetListParams,
  ImportSearchSectionRelationsRequest,
  ListResponse,
  Params,
  UpdateSearchSectionRequest,
  UpdateSearchSectionResponse,
} from '@api/types';
import { SearchSection, SearchSectionRelation } from '@modelTypes/searchSection';

import { AxiosRequestConfig } from 'axios';
import { HospitalDepartment } from '@modelTypes/hospital';
import { SearchSectionsState } from '@storage/searchSections/reducer';
import client from "./client";
import { getRestUri } from '@api/helpers';

const getUri = getRestUri('search-engine/sections');
const getTranslationUri = getRestUri('search-engine/sections/translations');
const getRelationUri = getRestUri('search-engine/sections/:sectionId/relations');
const getExcludedHospitalUri = getRestUri('search-engine/sections/:sectionId/excluded-hospitals');

const ROOT_URI = getUri('');
const RELATION_URI = getRelationUri('');
const EXCLUDED_HOSPITAL_URI = getExcludedHospitalUri('');
const TRANSLATION_URI = getTranslationUri('');

const DELETE_HOSPITAL_RELATIONS_URI = (id: number) => `search-engine/sections/relations/${id}`;
const DELETE_ALL_HOSPITAL_RELATIONS_URI = (sectionId: number) => `search-engine/sections/${sectionId}/relations/all`;

const IMPORT_RELATIONS_URI = (sectionId: number) => `search-engine/sections/${sectionId}/relations/import`;
const IMPORT_EXCLUDED_HOSPITALS_URI = (sectionId: number) => `search-engine/sections/${sectionId}/excluded-hospitals/import`;
const GET_RELATIONS_FOR_SELECT_URI = 'search-engine/sections/select';
const GET_RELATIONS_FOR_TREE_SELECT_URI = 'search-engine/sections/tree-select';
const GET_DEPARTMENTS_FOR_SELECT_URI = (sectionId: number) => `search-engine/sections/${sectionId}/departments/select`;
// const GET_EXCLUDED_HOSPITAL_FOR_SELECT_URI = (sectionId: number) => `search-engine/sections/${sectionId}/excluded-hospitals/select`;
const DELETE_EXCLUDED_HOSPITAL_URI = (id: number) => `search-engine/sections/excluded-hospitals/${id}`;
const DELETE_EXCLUDED_HOSPITALS_URI = (sectionId: number) => `search-engine/sections/${sectionId}/excluded-hospitals/all`;
const GET_SEARCH_COUNTRIES_URI = 'client/search-engine/countries';
const GET_SEARCH_CITIES_URI = 'client/search-engine/cities';
const GET_SEARCH_DISEASE_FOR_SELECT_URI = '/search-engine/sections/select2';

const GET_SS_HOSPITALS_FOR_SELECT_URI = `search-engine/relations/hospitals/select`;
const GET_SS_DEPARTMENTS_FOR_SELECT_URI = `search-engine/relations/departments/select`;
const GET_SEARCH_SECTION_FIELDS_FOR_SELECT_URI = (id: number) => `search-engine/sections/${id}/select-fields`;


export interface FilterSearchSections {
  type: number;
  parentId?: number;
}

export async function getSearchSections(filter: FilterSearchSections, params?: AntGetListParams) {
  return client.get<ListResponse<SearchSection>>(ROOT_URI.GET(), { ...filter, ...params });
}

export async function getSearchSection(id: number, params?: Params) {
  return client.get<SearchSection>(ROOT_URI.SHOW({ id }), params);
}

export async function createSearchSection(payload: CreateSearchSectionRequest) {
  return client.post<CreateSearchSectionRequest, CreateSearchSectionResponse>(ROOT_URI.POST(), payload);
}

export async function updateSearchSection(id: number, payload: UpdateSearchSectionRequest) {
  return client.patch<UpdateSearchSectionRequest, UpdateSearchSectionResponse>(ROOT_URI.PATCH({ id }), payload);
}

export async function updateSearchSectionTranslation(id: number, payload: any) {
  return client.patch<any, any>(TRANSLATION_URI.PATCH({ id }), payload);
}

export async function deleteSearchSection(id: number, config?: AxiosRequestConfig) {
  return client.del(ROOT_URI.DELETE({ id }), config);
}

// Relations.
export async function getSearchSectionRelations(sectionId: number, params?: GetListParams, filters?: any) {
  return client.get<ListResponse<SearchSectionRelation>>(RELATION_URI.GET({ sectionId }), { filter: filters as any, ...params });
}

export async function getSearchSectionRelationsForSelect(payload?: GetListParams) {
  return client.get<SearchSectionsState['select']['searchSections']>(GET_RELATIONS_FOR_SELECT_URI, payload);
}

export async function getSearchSectionRelationsForTreeSelect(payload?: GetListParams) {
  return client.get<SearchSectionsState['select']['tree']>(GET_RELATIONS_FOR_TREE_SELECT_URI, payload);
}

export async function createSearchSectionRelation(sectionId: number, payload: CreateSearchSectionRelationRequest) {
  return client.post<CreateSearchSectionRelationRequest, CreateSearchSectionRelationResponse>(RELATION_URI.POST({ sectionId }), payload);
}

export async function importSearchSectionRelation(sectionId: number, payload: ImportSearchSectionRelationsRequest) {
  return client.post<ImportSearchSectionRelationsRequest, any>(IMPORT_RELATIONS_URI(sectionId), payload);
}

export async function importSearchSectionExcludedHospitals(sectionId: number, payload: ImportSearchSectionRelationsRequest) {
  return client.post<ImportSearchSectionRelationsRequest, any>(IMPORT_EXCLUDED_HOSPITALS_URI(sectionId), payload);
}

export async function deleteSearchSectionRelation(id: number, config?: AxiosRequestConfig) {
  return client.del(DELETE_HOSPITAL_RELATIONS_URI(id), config);
}

export async function deleteSearchSectionAllRelation(sectionId: number, config?: AxiosRequestConfig) {
  return client.del(DELETE_ALL_HOSPITAL_RELATIONS_URI(sectionId), config);
}

// Departments
export async function getSearchSectionDepartmentsForSelect(sectionId: number, payload?: GetListParams) {
  return client.get<ListResponse<HospitalDepartment>>(GET_DEPARTMENTS_FOR_SELECT_URI(sectionId), payload);
}

// Excluded Hospitals.
export async function getSearchSectionExcludedHospitals(sectionId: number, params?: GetListParams) {
  return client.get<any>(EXCLUDED_HOSPITAL_URI.GET({ sectionId }), params);
}

// export async function getSearchSectionExcludedHospitalsForSelect(sectionId: number, params?: GetListParams) {
//   return client.get<any>(GET_EXCLUDED_HOSPITAL_FOR_SELECT_URI(sectionId), params);
// }

export async function createSearchSectionExcludedHospitals(sectionId: number, payload: any) {
  return client.post<any, any>(EXCLUDED_HOSPITAL_URI.POST({ sectionId }), payload);
}

export async function deleteExcludedHospital(id: number, config?: AxiosRequestConfig) {
  return client.del<any>(DELETE_EXCLUDED_HOSPITAL_URI(id), config);
}

export async function deleteExcludedHospitals(sectionId: number, config?: AxiosRequestConfig) {
  return client.del<any>(DELETE_EXCLUDED_HOSPITALS_URI(sectionId), config);
}

export async function getSearchSectionDiseaseForSelect(params?: Params) {
  return client.get<any>(GET_SEARCH_DISEASE_FOR_SELECT_URI, params);
}

// New.
export async function getSSHospitalsForSelect(params?: GetListParams) {
  return client.get<ListResponse<any>>(GET_SS_HOSPITALS_FOR_SELECT_URI, params);
}

export async function getSSDepartmentsForSelect(params?: GetListParams) {
  return client.get<ListResponse<any>>(GET_SS_DEPARTMENTS_FOR_SELECT_URI, params);
}

export async function getSearchSectionFieldsForSelect(id: number, params?: GetListParams) {
  return client.get<any>(GET_SEARCH_SECTION_FIELDS_FOR_SELECT_URI(id), params);
}
