import {
  AntGetListParams,
  CreateAuthorRequest,
  GetListParams,
  Params,
  UpdateAuthorCertificateOrderRequest,
  UpdateAuthorCertificateOrderResponse,
  UpdateAuthorCertificateRequest,
  UpdateAuthorCertificateResponse,
  UpdateAuthorRequest,
} from '@api/types';
import {
  endFetchAuthor,
  endFetchAuthors,
  endFetchAuthorsForSelect,
  endPostAuthor,
  endUpdateAuthor,
  endUploadAuthorCertificates,
  startDeleteAuthor,
  startFetchAuthor,
  startFetchAuthors,
  startFetchAuthorsForSelect,
  startPostAuthor,
  startUpdateAuthor,
  startUploadAuthorCertificates,
} from './actions';
import { endUploadHospitalImages, startUploadHospitalImages } from '@storage/hospitals/actions';

import { Author } from '@modelTypes/author';
import { AxiosRequestConfig } from 'axios';
import { ThunkAction } from '@storage/types';
import api from '@api';

export function getAuthors(params?: AntGetListParams): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchAuthors());

    return api.getAuthors(params)
      .then((data) => {
        dispatch(endFetchAuthors(data));
      });
  };
}

export function getAuthorsForSelect(): ThunkAction<Promise<void>> {
  const params: GetListParams & Params = { page: 1, perPage: 5000 };

  return async (dispatch): Promise<void> => {
    dispatch(startFetchAuthorsForSelect());

    return api.getAuthorsForSelect(params)
      .then((data) => {
        dispatch(endFetchAuthorsForSelect(data));
      });
  };
}

export function getAuthor(id: number, params?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchAuthor());

    return api.getAuthor(id, params)
      .then((data) => {
        dispatch(endFetchAuthor(data));
      });
  };
}

export function createAuthor(payload: CreateAuthorRequest): ThunkAction<Promise<Author>> {
  return async (dispatch): Promise<Author> => {
    dispatch(startPostAuthor());

    return api.createAuthor(payload)
      .then((data) => {
        dispatch(endPostAuthor(data));

        return data;
      });
  };
}

export function updateAuthor(id: number, payload: UpdateAuthorRequest): ThunkAction<Promise<Author>> {
  return async (dispatch): Promise<Author> => {
    dispatch(startUpdateAuthor());

    return api.updateAuthor(id, payload)
      .then((data) => {
        dispatch(endUpdateAuthor(data));

        return data;
      });
  };
}

export function deleteAuthor(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startDeleteAuthor());

    return api.deleteAuthor(id, config);
  };
}

// Certificates.
export function uploadAuthorCertificates(id: number, payload: FormData): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startUploadAuthorCertificates());

    return api.createAuthorCertificates(id, payload)
      .then((data) => {
        dispatch(endUploadAuthorCertificates());

        return data;
      });
  };
}

export function updateAuthorCertificate(id: number, payload: UpdateAuthorCertificateRequest): ThunkAction<Promise<UpdateAuthorCertificateResponse>> {
  return async (dispatch): Promise<UpdateAuthorCertificateResponse> => {
    dispatch(startUploadAuthorCertificates());

    return api.updateAuthorCertificate(id, payload)
      .then((data) => {
        return data;
      });
  };
}

export function updateAuthorCertificateOrder(id: number, payload: UpdateAuthorCertificateOrderRequest): ThunkAction<Promise<UpdateAuthorCertificateOrderResponse>> {
  return async (dispatch): Promise<UpdateAuthorCertificateOrderResponse> => {
    dispatch(startUploadAuthorCertificates());

    return api.updateAuthorCertificateOrder(id, payload)
      .then((data) => {
        return data;
      });
  };
}

export function deleteAuthorCertificateImage(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startUploadAuthorCertificates());

    return api.deleteAuthorCertificateImage(id, config);
  };
}