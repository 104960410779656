import { ReactNode } from 'react';

interface Props {
  children: ReactNode,
}

export default function Main(): JSX.Element {

  return (
    <div>
      Main page
    </div>
  );
}