import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { getProcesses } from '@api/authApi';
import getIdCell from '@components/AntGrid/cells/IdCell';
import { Process, ProcessStatus, ProcessType } from '@layouts/Notification';
import { message, Spin, Table, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Index() {
  const perPage = 100;
  const [page, setPage] = useState(1);
  const [processes, setProcesses] = useState<Process[]>();
  const [totalProcesses, setTotalProcesses] = useState(0);
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    (async () => {
      setFetching(true);

      try {
        const data = await getProcesses({ perPage, page });
        setProcesses(data.data);
        setTotalProcesses(data.meta.total);
      } catch (err: any) {
        message.error(`Failed to fetch processes: ${err.message}`);
      } finally {
        setFetching(false);
      }
    })();
  }, [page]);

  return (
    <Table
      loading={isFetching}
      style={{ marginTop: 20, flex: 1 }}
      columns={[
        { title: 'ID', dataIndex: 'id', render: (value: number) => getIdCell()(value) },
        {
          title: 'Type',
          dataIndex: 'type',
          render: (value: ProcessType) => (
            <Tag>
              {
                {
                  1: 'Parse Pubmed',
                  2: 'Parse Researchgate',
                  3: 'Parse Semanticscholar',
                  4: 'Parse Google Reviews',
                  99: 'Find Unique Publications',
                  100: null,
                }[value]
              }
            </Tag>
          ),
        },
        {
          title: 'Entity ID',
          dataIndex: 'entityId',
          render: (value: number, record) => (
            <Link
              to={`/${
                { 1: 'doctors', 2: 'doctors', 3: 'doctors', 4: 'doctors', 99: 'doctors', 100: null }[record.type]
              }/edit/${value}`}
              target="_blank"
            >
              {value}
            </Link>
          ),
        },
        {
          title: 'Status',
          dataIndex: 'status',
          render: (value: ProcessStatus) => (
            <Tag
              icon={
                {
                  1: <SyncOutlined spin />,
                  2: <CloseCircleOutlined />,
                  3: <CheckCircleOutlined />,
                  100: <CheckCircleOutlined />,
                }[value]
              }
              color={{ 1: 'processing', 2: 'error', 3: 'success', 100: 'success' }[value]}
            >
              {{ 1: 'In progress', 2: 'Failure', 3: 'Success', 100: 'Finished' }[value]}
            </Tag>
          ),
        },
        { title: 'Name', dataIndex: 'name' },
        { title: 'Created at', dataIndex: 'createdAt', render: (value) => moment(value).format('HH:mm DD.MM.YYYY') },
        {
          title: 'Finished at',
          dataIndex: 'finishedAt',
          render: (value) => moment(value).format('HH:mm DD.MM.YYYY'),
        },
      ]}
      dataSource={processes}
      pagination={{
        pageSize: perPage,
        total: totalProcesses,
        showSizeChanger: false,
        style: { position: 'sticky', bottom: 0, background: 'white', paddingBlock: 10 },
      }}
      onChange={(cfg) => setPage(cfg.current ?? 1)}
      scroll={{ y: 'max-content' }}
    />
  );
}
