import { Form, Input, message, Modal } from 'antd';
import 'cropperjs/dist/cropper.css';
import * as React from 'react';
import { useEffect } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useSelector } from '@hooks';
import { useDispatch } from 'react-redux';
import { FrontendTranslationsState } from '@storage/frontendTranslations/reducer';
import { ModalWithFormTypes } from '@components/Modal/ModalWithForm/ModalWithForm';
import { FrontendLanguagesTypes } from '@modelTypes/frontendLanguage';
import { createFrontendLanguage, updateFrontendLanguage } from '@storage/frontendTranslations/thunkActions';
import {
  dispatchRefreshFrontendTranslationsGridOnlyEvent
} from '@containers/Settings/FrontendLanguages/components/FrontendLanguagesAntGrid';
import { closeModalWidthForm } from '@storage/frontendTranslations/actions';

interface ModalProps {
  type: FrontendLanguagesTypes;
  closeModal: () => void;
}

type ModalState = FrontendTranslationsState['modalWithForm'];
type ModalFormState = ModalState['data']['formData'];

const modalTitles: Record<ModalWithFormTypes, string> = {
  add: 'Add New Key',
  edit: 'Edit Key',
};

export default function KeyModalForm({ type, closeModal, }: ModalProps) {
  const { confirm } = Modal;
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { open, loading, data } = useSelector<ModalState>('frontendTranslations.modalWithForm');
  const dispatch = useDispatch();

  const [form] = Form.useForm<ModalFormState>();

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const showConfirm = () => {
    confirm({
      title: 'Data not saved!',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to close modal window?',
      onOk() {
        closeModal()
        resetForm()
      },
    });
  };

  const handleCloseModal = () => {
    if (formChanged) {
      showConfirm()
    } else {
      closeModal()
      dispatch(closeModalWidthForm())
      resetForm()
    }
  }

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving key...',
      duration: 0,
    });

    const dataToSave = form.getFieldsValue(true);

    if (dataToSave.link.length && typeof process.env.REACT_APP_CLIENT_URL === 'string') {
      dataToSave.link = dataToSave.link.replaceAll(process.env.REACT_APP_CLIENT_URL, '');
    }

    if (data.type === ModalWithFormTypes.ADD) {
      dispatch(createFrontendLanguage({
        type,
        ...dataToSave,
        // key: `${prefixes[type]}.${}`,
        // key: `${prefixes[type]}.${values.key}`,
      }) as unknown as Promise<void>)
        .then(() => {
          dispatchRefreshFrontendTranslationsGridOnlyEvent();
          messageApi.destroy('startCreate');
          message.success('Key successfully added');
          resetForm();
          closeModal()
        })
        .catch(() => {
          messageApi.destroy('startCreate');
          message.error('An error occurred, please try again now');
        })
    }

    if (data.type === ModalWithFormTypes.EDIT && data.editRecord) {
      dispatch(updateFrontendLanguage(data.editRecord.id, dataToSave) as unknown as Promise<void>)
        .then(() => {
          dispatchRefreshFrontendTranslationsGridOnlyEvent();
          message.success('Key successfully updated');
          messageApi.destroy('startCreate');
          resetForm();
          closeModal()
        })
        .catch(() => {
          messageApi.destroy('startCreate');
          message.error('An error occurred, please try again now');
        })
    }
  };

  useEffect(() => {
    if (!open) {
      return
    }
    form.setFieldsValue(data.formData)
  }, [open])

  return (
    <Modal
      open={open}
      title={modalTitles[data.type]}
      onCancel={handleCloseModal}
      onOk={form.submit}
      okText={'Save'}
    >
      {contextHolder}
      <Form
        form={form}
        layout="vertical"
        onFinish={handleOnFinish}
        onFieldsChange={handleFieldsChange}
        initialValues={data.formData}
        autoComplete="off"
        id={'test'}
      >
        <Form.Item
          name="key"
          label="Translate key"
          messageVariables={{ name: 'Translate key' }}
          rules={[{ required: true, type: 'string' }]}
        >
          <Input
            placeholder="Enter translate key"
          />
        </Form.Item>
        <Form.Item
          name="messageEn"
          label="English message"
          messageVariables={{ name: 'English message' }}
          rules={[{ required: true, type: 'string' }]}
        >
          <Input.TextArea placeholder="Enter english message" autoSize={{ minRows: 2 }} />
        </Form.Item>
        <Form.Item
          name="messageRu"
          label="Russian message"
        >
          <Input.TextArea placeholder="Enter russian message" autoSize={{ minRows: 2 }} />
        </Form.Item>
        <Form.Item
          name="link"
          label="Link"
        >
          <Input
            placeholder="Enter link"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}