export interface VendorUser {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  vendorCompanyId: string;
  imgPath: string;
  createdAt: string;
  updatedAt: string;
  company?: VendorCompany;
  description?: string;

}

export interface VendorCompany {
  id: number;
  name: string;
  orgName: string;
  imgPath: string;
  createdAt: string;
  updatedAt: string;
  description?: string;
}

export enum Vendors {
  USERS = 'users',
  COMPANY = 'company',
}