import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M8.85322 2.17478L8.63004 2.62114C8.5621 2.7567 8.55131 2.91098 8.59957 3.05511C8.6475 3.19893 8.74909 3.31576 8.88624 3.38433C8.96465 3.42274 9.05164 3.44306 9.13799 3.44306C9.35577 3.44306 9.55006 3.32211 9.64499 3.12813L10.3101 1.7989C10.3406 1.73477 10.3587 1.66588 10.3634 1.59381L10.3641 1.58397C10.3647 1.57476 10.365 1.56556 10.365 1.55603C10.365 1.54556 10.3644 1.53508 10.3637 1.52524L10.3631 1.51317C10.3593 1.46428 10.3494 1.41635 10.3333 1.36936L10.3323 1.36682C10.3145 1.31698 10.2898 1.26904 10.2609 1.22872L9.5761 0.243938C9.48975 0.119173 9.35958 0.0356794 9.21006 0.00901214C9.06085 -0.0176551 8.91037 0.0153615 8.78624 0.101395C8.52941 0.279177 8.46528 0.633789 8.64401 0.891572L8.75036 1.04427C7.7132 1.1538 6.70904 1.42555 5.76394 1.85286C4.689 2.33859 3.71564 3.01353 2.87086 3.85831C1.01907 5.70883 0 8.1692 0 10.7864C0 11.0991 0.254609 11.3537 0.567315 11.354C0.880338 11.354 1.13463 11.0994 1.13463 10.7867C1.13463 8.62763 1.93751 6.5571 3.395 4.95643C4.82329 3.38814 6.75794 2.40335 8.85322 2.17509V2.17478Z"
    fill="currentColor" />
  <path
    d="M19.7824 10.647C19.4693 10.647 19.2151 10.9016 19.2151 11.2144C19.2151 13.3735 18.4125 15.4437 16.955 17.0443C15.527 18.6126 13.5927 19.5971 11.4974 19.825L11.7196 19.3803C11.7873 19.2447 11.7984 19.0907 11.7504 18.9466C11.7025 18.8025 11.6015 18.686 11.4663 18.618C11.186 18.4777 10.8441 18.5917 10.7041 18.8714L10.0444 20.1901C10.0399 20.199 10.0361 20.2079 10.0332 20.2152C10.0304 20.2219 10.0275 20.2288 10.025 20.2349C10.0199 20.2476 10.0152 20.2603 10.0098 20.2771C10.0085 20.2809 10.0075 20.285 10.0069 20.2873C9.99865 20.3165 9.99261 20.3463 9.98912 20.3749C9.98849 20.3793 9.98785 20.3835 9.98753 20.3879C9.98626 20.4019 9.98531 20.4162 9.98499 20.4308V20.4438C9.98468 20.4485 9.98499 20.4536 9.98499 20.4555C9.98499 20.4711 9.98595 20.4866 9.98753 20.5009L9.98849 20.5082C9.99198 20.5381 9.99801 20.5682 10.0063 20.5974C10.0079 20.6041 10.0098 20.6108 10.0117 20.6162C10.0155 20.6282 10.0199 20.64 10.0244 20.6511C10.0282 20.6603 10.032 20.6695 10.0377 20.6819C10.0425 20.692 10.0472 20.7022 10.0517 20.7101C10.0596 20.7254 10.0688 20.7406 10.079 20.7565L10.7736 21.7559C10.8793 21.9089 11.0539 22 11.2403 22H11.3876L11.3987 21.9778C11.4558 21.9613 11.5101 21.9362 11.5609 21.9025L11.5644 21.9003C11.6892 21.814 11.7727 21.6838 11.7993 21.5343C11.826 21.3854 11.793 21.2349 11.7066 21.1098L11.5993 20.9558C12.6365 20.8466 13.6406 20.5749 14.5864 20.1476C15.6613 19.6619 16.6347 18.9872 17.4798 18.1425C19.3303 16.2916 20.3497 13.8312 20.3497 11.214C20.3497 10.901 20.0951 10.6467 19.7824 10.6467V10.647Z"
    fill="currentColor" />
  <path
    d="M10.5336 17.9244H10.5529C10.7028 17.9247 10.8491 17.8643 10.9542 17.7593L11.879 16.8345C12.4546 17.2459 13.1571 17.472 13.8635 17.472C15.7458 17.472 17.2772 15.9411 17.2772 14.0592C17.2772 13.3522 17.0509 12.6493 16.6388 12.0737L16.8655 11.8471C17.6782 11.0334 18.1258 9.95274 18.1258 8.80414C18.1258 7.65554 17.6788 6.57456 16.8671 5.76184C16.0534 4.94913 14.9727 4.5015 13.8241 4.5015C12.6755 4.5015 11.5939 4.94913 10.7812 5.76184L10.5539 5.98915L10.3263 5.76153C9.5126 4.94881 8.43194 4.50118 7.28334 4.50118C6.13475 4.50118 5.05313 4.94881 4.24042 5.76153C3.4277 6.57456 2.98007 7.65522 2.98007 8.80446C2.98007 9.95369 3.4277 11.0347 4.24042 11.8464L10.1504 17.7564C10.252 17.8596 10.3882 17.919 10.5339 17.9234L10.5336 17.9244ZM13.8222 5.63676C14.668 5.63676 15.4635 5.96629 16.0616 6.56472C16.6601 7.16315 16.9896 7.9584 16.9896 8.80446C16.9896 9.65051 16.6601 10.4464 16.0616 11.0442L15.8327 11.2731C15.26 10.8683 14.5629 10.6461 13.8632 10.6461C11.9809 10.6461 10.4494 12.1769 10.4494 14.0589C10.4494 14.7592 10.672 15.4567 11.0774 16.0297L10.5526 16.5545L5.04266 11.0455C4.44487 10.4467 4.11533 9.65114 4.11533 8.80573C4.11533 7.96031 4.44487 7.16378 5.04329 6.56504C5.64172 5.96661 6.43729 5.63708 7.28303 5.63708C8.12876 5.63708 8.92433 5.96661 9.52276 6.56504L10.1513 7.19362C10.2587 7.30093 10.4009 7.35998 10.5526 7.35998C10.7044 7.35998 10.8482 7.29934 10.9539 7.19362L11.5825 6.56504C12.1809 5.96661 12.9762 5.63708 13.8222 5.63708V5.63676ZM13.8632 16.3367C12.607 16.3367 11.5853 15.3148 11.5853 14.0589C11.5853 12.803 12.6073 11.781 13.8632 11.781C15.1191 11.781 16.141 12.803 16.141 14.0589C16.141 15.3148 15.1191 16.3367 13.8632 16.3367Z"
    fill="currentColor" />
  <path
    d="M14.4308 13.4922V12.9919C14.4308 12.6788 14.1762 12.4245 13.8635 12.4245C13.5508 12.4245 13.2962 12.6791 13.2962 12.9919V13.4922H12.7959C12.4828 13.4922 12.2285 13.7468 12.2285 14.0595C12.2285 14.3722 12.4831 14.6268 12.7959 14.6268H13.2962V15.1262C13.2962 15.4392 13.5508 15.6935 13.8635 15.6935C14.1762 15.6935 14.4308 15.4389 14.4308 15.1262V14.6268H14.9302C15.0826 14.6268 15.2254 14.5674 15.3331 14.4595C15.44 14.3522 15.4985 14.21 15.4978 14.0595C15.4978 13.7465 15.2432 13.4922 14.9305 13.4922H14.4308Z"
    fill="currentColor" />
</svg>`;

export default { id: '197-cardiorehabilitation', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
