import { Hospital, HospitalAccessKey, HospitalForSelect, HospitalTranslation } from '@modelTypes/hospital';

import { BlogAccessKey } from '@modelTypes/blog';
import { ListResponse } from '@api/types';
import { createAction } from '../helpers';

export enum ActionType {
  START_FETCH_HOSPITALS = 'HOSPITALS__START_FETCH_HOSPITALS',
  END_FETCH_HOSPITALS = 'HOSPITALS__END_FETCH_HOSPITALS',

  START_FETCH_HOSPITALS_FOR_SELECT = 'HOSPITALS__START_FETCH_HOSPITALS_FOR_SELECT',
  END_FETCH_HOSPITALS_FOR_SELECT = 'HOSPITALS__END_FETCH_HOSPITALS_FOR_SELECT',

  START_FETCH_HOSPITAL = 'HOSPITALS__START_FETCH_HOSPITAL',
  END_FETCH_HOSPITAL = 'HOSPITALS__END_FETCH_HOSPITAL',

  START_FETCH_HOSPITAL_ACCESS_KEY = 'HOSPITALS__START_FETCH_HOSPITAL_ACCESS_KEY',
  END_FETCH_HOSPITAL_ACCESS_KEY = 'HOSPITALS__END_FETCH_HOSPITAL_ACCESS_KEY',

  START_POST_HOSPITAL = 'HOSPITALS__START_POST_HOSPITAL',
  END_POST_HOSPITAL = 'HOSPITALS__END_POST_HOSPITAL',
  END_POST_HOSPITAL_ERROR = 'HOSPITALS__END_POST_HOSPITAL_ERROR',

  START_PATCH_HOSPITAL = 'HOSPITALS__START_PATCH_HOSPITAL',
  END_PATCH_HOSPITAL = 'HOSPITALS__END_PATCH_HOSPITAL',
  END_PATCH_HOSPITAL_ERROR = 'HOSPITALS__END_PATCH_HOSPITAL_ERROR',

  START_DELETE_HOSPITAL = 'HOSPITALS__START_DELETE_HOSPITAL',
  END_DELETE_HOSPITAL = 'HOSPITALS__END_DELETE_HOSPITAL',

  START_UPLOAD_HOSPITAL_IMAGES = 'HOSPITALS__START_UPLOAD_HOSPITAL_IMAGES',
  END_UPLOAD_HOSPITAL_IMAGES = 'HOSPITALS__END_UPLOAD_HOSPITAL_IMAGES',

  CLEAR_FORM_DATA = 'HOSPITALS__CLEAR_FORM_DATA',

  START_FETCH_TRANSLATIONS = 'HOSPITALS__START_FETCH_TRANSLATIONS',
  END_FETCH_TRANSLATIONS = 'HOSPITALS__END_FETCH_TRANSLATIONS',

  START_FETCH_TRANSLATION = 'HOSPITALS__START_FETCH_TRANSLATION',
  END_FETCH_TRANSLATION = 'HOSPITALS__END_FETCH_TRANSLATION',

  START_PATCH_TRANSLATION = 'HOSPITALS__START_PATCH_TRANSLATION',
  END_PATCH_TRANSLATION = 'HOSPITALS__END_PATCH_TRANSLATION',
  END_PATCH_TRANSLATION_ERROR = 'HOSPITALS__END_PATCH_TRANSLATION_ERROR',
}

export const startFetchHospitals = createAction<ActionType.START_FETCH_HOSPITALS>(ActionType.START_FETCH_HOSPITALS);
export const endFetchHospitals = createAction<ActionType.END_FETCH_HOSPITALS, ListResponse<Hospital>>(ActionType.END_FETCH_HOSPITALS);

export const startFetchHospitalsForSelect = createAction<ActionType.START_FETCH_HOSPITALS_FOR_SELECT>(ActionType.START_FETCH_HOSPITALS_FOR_SELECT);
export const endFetchHospitalsForSelect = createAction<ActionType.END_FETCH_HOSPITALS_FOR_SELECT, ListResponse<HospitalForSelect>>(ActionType.END_FETCH_HOSPITALS_FOR_SELECT);

export const startFetchHospital = createAction<ActionType.START_FETCH_HOSPITAL>(ActionType.START_FETCH_HOSPITAL);
export const endFetchHospital = createAction<ActionType.END_FETCH_HOSPITAL, Hospital>(ActionType.END_FETCH_HOSPITAL);

export const startFetchAccessKey = createAction<ActionType.START_FETCH_HOSPITAL_ACCESS_KEY>(ActionType.START_FETCH_HOSPITAL_ACCESS_KEY);
export const endFetchAccessKey = createAction<ActionType.END_FETCH_HOSPITAL_ACCESS_KEY, HospitalAccessKey>(ActionType.END_FETCH_HOSPITAL_ACCESS_KEY);

export const startPostHospital = createAction<ActionType.START_POST_HOSPITAL>(ActionType.START_POST_HOSPITAL);
export const endPostHospital = createAction<ActionType.END_POST_HOSPITAL, Hospital>(ActionType.END_POST_HOSPITAL);
export const endPostHospitalError = createAction<ActionType.END_POST_HOSPITAL_ERROR, Hospital>(ActionType.END_POST_HOSPITAL_ERROR);

export const startUpdateHospital = createAction<ActionType.START_PATCH_HOSPITAL>(ActionType.START_PATCH_HOSPITAL);
export const endUpdateHospital = createAction<ActionType.END_PATCH_HOSPITAL, Hospital>(ActionType.END_PATCH_HOSPITAL);
export const endUpdateHospitalError = createAction<ActionType.END_PATCH_HOSPITAL_ERROR, Hospital>(ActionType.END_PATCH_HOSPITAL_ERROR);

export const startDeleteHospital = createAction<ActionType.START_DELETE_HOSPITAL>(ActionType.START_DELETE_HOSPITAL);
export const endDeleteHospital = createAction<ActionType.END_DELETE_HOSPITAL>(ActionType.END_DELETE_HOSPITAL);

export const startUploadHospitalImages = createAction<ActionType.START_UPLOAD_HOSPITAL_IMAGES>(ActionType.START_UPLOAD_HOSPITAL_IMAGES);
export const endUploadHospitalImages = createAction<ActionType.END_UPLOAD_HOSPITAL_IMAGES>(ActionType.END_UPLOAD_HOSPITAL_IMAGES);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

export const startFetchTranslations = createAction<ActionType.START_FETCH_TRANSLATIONS>(ActionType.START_FETCH_TRANSLATIONS);
export const endFetchTranslations = createAction<ActionType.END_FETCH_TRANSLATIONS, ListResponse<HospitalTranslation>>(ActionType.END_FETCH_TRANSLATIONS);

export const startFetchTranslation = createAction<ActionType.START_FETCH_TRANSLATION>(ActionType.START_FETCH_TRANSLATION);
export const endFetchTranslation = createAction<ActionType.END_FETCH_TRANSLATION, Array<HospitalTranslation>>(ActionType.END_FETCH_TRANSLATION);

export const startPatchTranslation = createAction<ActionType.START_PATCH_TRANSLATION>(ActionType.START_PATCH_TRANSLATION);
export const endPatchTranslation = createAction<ActionType.END_PATCH_TRANSLATION, Array<HospitalTranslation>>(ActionType.END_PATCH_TRANSLATION);
export const endPatchTranslationError = createAction<ActionType.END_PATCH_TRANSLATION_ERROR>(ActionType.END_PATCH_TRANSLATION_ERROR);


export type Actions =
  | ReturnType<typeof startFetchHospitals>
  | ReturnType<typeof endFetchHospitals>
  | ReturnType<typeof startFetchHospitalsForSelect>
  | ReturnType<typeof endFetchHospitalsForSelect>
  | ReturnType<typeof startFetchHospital>
  | ReturnType<typeof endFetchHospital>
  | ReturnType<typeof startFetchAccessKey>
  | ReturnType<typeof endFetchAccessKey>
  | ReturnType<typeof startPostHospital>
  | ReturnType<typeof endPostHospital>
  | ReturnType<typeof endPostHospitalError>
  | ReturnType<typeof startUpdateHospital>
  | ReturnType<typeof endUpdateHospital>
  | ReturnType<typeof endUpdateHospitalError>
  | ReturnType<typeof startDeleteHospital>
  | ReturnType<typeof endDeleteHospital>
  | ReturnType<typeof startUploadHospitalImages>
  | ReturnType<typeof endUploadHospitalImages>
  | ReturnType<typeof clearFormData>
  | ReturnType<typeof startFetchTranslations>
  | ReturnType<typeof endFetchTranslations>
  | ReturnType<typeof startFetchTranslation>
  | ReturnType<typeof endFetchTranslation>
  | ReturnType<typeof startPatchTranslation>
  | ReturnType<typeof endPatchTranslation>
  | ReturnType<typeof endPatchTranslationError>;