import { AntGetListParams, Params, UpdateContentRequest, } from '@api/types';
import {
  endFetchContent,
  endFetchContents,
  endUpdateContent,
  startFetchContent,
  startFetchContents,
  startUpdateContent
} from '@storage/contents/actions';

import { AxiosRequestConfig } from 'axios';
import { Content } from '@modelTypes/content';
import { ThunkAction } from '@storage/types';
import api from '@api';

export function getContents(params?: AntGetListParams, payload?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchContents());

    return api.getContents(params, payload)
      .then((data) => {
        dispatch(endFetchContents(data));
      });
  };
}

export function getMenuContents(params?: AntGetListParams, payload?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchContents());

    return api.getMenuContents(params, payload)
      .then((data) => {
        dispatch(endFetchContents(data));
      });
  };
}


export function getContent(id: number, params?: Params): ThunkAction<Promise<Content>> {
  return async (dispatch): Promise<Content> => {
    dispatch(startFetchContent());

    return api.getContent(id, params)
      .then((data) => {
        dispatch(endFetchContent(data));

        return data;
      });
  };
}

export function updateContentRelation(id: number, payload: UpdateContentRequest): ThunkAction<Promise<Content>> {
  return async (dispatch): Promise<Content> => {
    dispatch(startUpdateContent());

    return api.updateContentRelation(id, payload)
      .then((data) => {
        dispatch(endUpdateContent(data));

        return data;
      });
  };
}


export function deleteMenuItem(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (): Promise<void> => {
    return api.deleteMenuItem(id, config);
  };
}

export function deleteSubMenuItemLink(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (): Promise<void> => {
    return api.deleteSubMenuItemLink(id, config);
  };
}

// Temp.
export function deleteContentNavigation(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (): Promise<void> => {
    return api.deleteContentNavigation(id, config);
  };
}

