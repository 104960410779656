import { ListResponse } from '@api/types';
import { TreatmentServiceCost } from '@modelTypes/treatment';
import { createAction } from '@storage/helpers';

export enum ActionType {
  START_FETCH_SERVICES_COST = 'SERVICES_COST__START_FETCH_SERVICES_COST',
  END_FETCH_SERVICES_COST = 'SERVICES_COST__END_FETCH_SERVICES_COST',

  START_FETCH_SERVICE_COST = 'SERVICES_COST__START_FETCH_SERVICE_COST',
  END_FETCH_SERVICE_COST = 'SERVICES_COST__END_FETCH_SERVICE_COST',

  START_PATCH_SERVICE_COST = 'SERVICES_COST__START_PATCH_SERVICE_COST',
  END_PATCH_SERVICE_COST = 'SERVICES_COST__END_PATCH_SERVICE_COST',
  END_PATCH_SERVICE_COST_ERROR = 'SERVICES_COST__END_PATCH_SERVICE_COST_ERROR',

  START_DELETE_SERVICE_COST = 'SERVICES_COST__START_DELETE_SERVICE_COST',
  END_DELETE_SERVICE_COST = 'SERVICES_COST__END_DELETE_SERVICE_COST',

  CLEAR_FORM_DATA = 'SERVICES_COST__CLEAR_FORM_DATA',
}

export const startFetchServicesCost = createAction<ActionType.START_FETCH_SERVICES_COST>(ActionType.START_FETCH_SERVICES_COST);
export const endFetchServicesCost = createAction<ActionType.END_FETCH_SERVICES_COST, ListResponse<TreatmentServiceCost>>(ActionType.END_FETCH_SERVICES_COST);

export const startFetchServiceCost = createAction<ActionType.START_FETCH_SERVICE_COST>(ActionType.START_FETCH_SERVICE_COST);
export const endFetchServiceCost = createAction<ActionType.END_FETCH_SERVICE_COST, TreatmentServiceCost>(ActionType.END_FETCH_SERVICE_COST);

export const startUpdateServiceCost = createAction<ActionType.START_PATCH_SERVICE_COST>(ActionType.START_PATCH_SERVICE_COST);
export const endUpdateServiceCost = createAction<ActionType.END_PATCH_SERVICE_COST, TreatmentServiceCost>(ActionType.END_PATCH_SERVICE_COST);
export const endUpdateServiceError = createAction<ActionType.END_PATCH_SERVICE_COST_ERROR>(ActionType.END_PATCH_SERVICE_COST_ERROR);

export const startDeleteServiceCost = createAction<ActionType.START_DELETE_SERVICE_COST>(ActionType.START_DELETE_SERVICE_COST);
export const endDeleteServiceCost = createAction<ActionType.END_DELETE_SERVICE_COST>(ActionType.END_DELETE_SERVICE_COST);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

export type Actions =
  | ReturnType<typeof startFetchServicesCost>
  | ReturnType<typeof endFetchServicesCost>
  | ReturnType<typeof startFetchServiceCost>
  | ReturnType<typeof endFetchServiceCost>
  | ReturnType<typeof startUpdateServiceCost>
  | ReturnType<typeof endUpdateServiceCost>
  | ReturnType<typeof endUpdateServiceError>
  | ReturnType<typeof startDeleteServiceCost>
  | ReturnType<typeof endDeleteServiceCost>
  | ReturnType<typeof clearFormData>;