import get from 'lodash/get';
import omit from 'lodash/omit';

export default class LocalStorage {

  public static setObject = (key: string, data: Record<string, any>): void => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  public static getObject = (key: string, path?: string, defaultValue?: any): any => {
    const data = localStorage.getItem(key);
    const object = data !== null ? JSON.parse(data) as Record<string, any> : {};

    return !path ? object : get(object, path, defaultValue);
  };

  public static removeKey = (key: string, paths?: string | string[]): void => {
    const data = localStorage.getItem(key);
    const object = data !== null ? JSON.parse(data) as Record<string, any> : {};

    if (!paths) {
      localStorage.removeItem(key);

      return;
    }

    LocalStorage.setObject(key, omit(object, paths));
  };

}