import {
  AntGetListParams,
  CreateSearchSynonymRequest,
  CreateSearchSynonymResponse,
  GetListParams,
  ListResponse,
  Params,
  UpdateSearchSynonymRequest,
  UpdateSearchSynonymResponse,
} from '@api/types';

import { AxiosRequestConfig } from 'axios';
import { SearchSynonym } from '@modelTypes/searchSynonym';
import client from "./client";
import { getRestUri } from '@api/helpers';

const getUri = getRestUri('search-engine/synonyms');
const ROOT_URI = getUri('');
const GET_SEARCH_SYNONYMS_FOR_SELECT_URI = 'search-engine/synonyms/select';

export async function getSearchSynonyms(params?: AntGetListParams) {
  return client.get<ListResponse<SearchSynonym>>(ROOT_URI.GET(), params);
}

export async function getSearchSynonymsForSelect(params?: GetListParams) {
  return client.get<ListResponse<SearchSynonym>>(GET_SEARCH_SYNONYMS_FOR_SELECT_URI, params);
}

export async function getSearchSynonym(id: number, params?: Params) {
  return client.get<SearchSynonym>(ROOT_URI.SHOW({ id }), params);
}

export async function createSearchSynonym(payload: CreateSearchSynonymRequest) {
  return client.post<CreateSearchSynonymRequest, CreateSearchSynonymResponse>(ROOT_URI.POST(), payload);
}

export async function updateSearchSynonym(id: number, payload: UpdateSearchSynonymRequest) {
  return client.patch<UpdateSearchSynonymRequest, UpdateSearchSynonymResponse>(ROOT_URI.PATCH({ id }), payload);
}

export async function deleteSearchSynonym(id: number, config?: AxiosRequestConfig) {
  return client.del(ROOT_URI.DELETE({ id }), config);
}
