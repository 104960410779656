import { useState } from 'react';
import UploadImagesModal from '@components/UploadImageGalleries/UploadImagesModal';
import { Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

interface Props {
  handleSaveForm: (formData: FormData) => any;
  updateGrid: () => void;
}

export default function UploadImageButton({ handleSaveForm, updateGrid }: Props) {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleCloseModal = async () => {
    setIsOpenModal(false)
  }

  return (
    <>
      <Button icon={<UploadOutlined />} onClick={() => setIsOpenModal(true)}>Upload Image</Button>
      <UploadImagesModal
        updateGrid={updateGrid}
        closeModal={handleCloseModal}
        isOpen={isOpenModal}
        handleSaveForm={handleSaveForm}
      />
    </>
  )
}