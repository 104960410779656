import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'antd';
import * as React from 'react';
import { useEffect } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import DepartmentsAntGrid from '@containers/Hospitals/components/DepartmentsAntGrid';
import { useGridStore } from '@zustandStorage/grid/grid';
import QueryString from 'query-string';
import { Language } from '@modelTypes/language';
import { getHistorySearchParam, updateQueryParams } from '@helpers/history';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import { useHospitalStore } from '@zustandStorage/hospital/hospital';

export default function DepartmentsIndex(): JSX.Element {
  const history = useHistory();
  const { hospitalId } = useParams<{ hospitalId: string }>();
  const languageId = getHistorySearchParam(history, 'language-id', 1);
  const {fetchHospital, form:{row: hospital, loading: hospitalLoading}} = useHospitalStore()
  const title = hospital && !hospitalLoading  ? `Departments - ${hospital.title}` : 'Departments - Loading...';

  useEffect(() => {
    // if (((!hospital && !hospitalLoading) || (hospital && hospital.id !== parseInt(hospitalId, 10))) && languageId.length) {
    //
    // }
    fetchHospital(parseInt(hospitalId), { languageId });
  }, [languageId]);
  console.log(languageId, hospital)
  const onSelectLanguage = (language: Language) => {
    updateQueryParams(history, {
      ['language-id']: language.id > 1 ? language.id : null,
    });
  };

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.hospitalsAntGrid || {};
          history.push(`/hospitals?${QueryString.stringify(params)}`)
        }}
        title={title}
        extra={[
          <SelectLanguagePerForm
            key="selectLanguage"
            updateParamKey={false}
            queryParamKey="language-id"
            onInit={onSelectLanguage}
            onChange={onSelectLanguage}
          />,
          <Button
            key="createDepartment"
            type="primary"
            href={`/hospitals/${hospitalId}/departments/create`}
            onClick={(e) => {
              e.preventDefault();

              history.push(`/hospitals/${hospitalId}/departments/create`);
            }}
          >
            Add Department
          </Button>
        ]}
      />
      <DepartmentsAntGrid />
    </>
  );
}