import React from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import styles from './MainImagesList.module.scss';
import { Card, Image, message, Spin } from 'antd';
import { useGalleriesDataStore } from '@zustandStorage/galleries/galleries';
import { DescriptionImage } from '@components/Gallery/components/DescriptionImage';
import { CustomDeleteCell } from '@components/Gallery/components/CustomDeleteCell';
import { EditCell } from '@components/Gallery/components/EditCell';

const SortableAvatar = SortableHandle(({ value }: any) => {

  return (
    <Image
      preview={false}
      width={'100%'}
      src={value.path}
      style={{
        aspectRatio: '16/9',
        objectFit: 'contain',
        background: 'black',
      }}
    />
  );
});

const SortableItem = SortableElement(({ value, entityGallery }: any) => {

  return (
    <Card
      className={styles.card}
      bodyStyle={{ padding: '16px 16px 0 16px', marginBottom: 'auto', height: 120 }}
      actions={[
        <EditCell record={value} key="editButton" entityGallery={entityGallery}/>,
        <CustomDeleteCell record={value} key="deleteButton" entityGallery={entityGallery} />
      ]}
      cover={
        <SortableAvatar value={value} />
      }
    >
      <DescriptionImage record={value} mainImageBlock hasMainImages />
    </Card>
  );
});


const SortableList = SortableContainer(({ items, entityGallery }: any) => {

  return (
    <div className={styles.images}>
      {items.map((value: any, index: number) => (
        <SortableItem
          key={`item-${value.id}`}
          index={index}
          value={value}
          entityGallery={entityGallery}
        />
      ))}
    </div>
  );
});

export default function MainImagesList({ entityGallery }: { entityGallery: string }) {
  const { updateImageOrder, grid: { mainItem, loading } } = useGalleriesDataStore()

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if ((oldIndex !== newIndex) && mainItem) {
      const imgToUpdate = mainItem[oldIndex];
      const newOrder = mainItem[newIndex].order;

      // updateImageOrder(imgToUpdate.id, { order: (newIndex + 1) })
      updateImageOrder(imgToUpdate.id, { order: newOrder })
        .catch(() => {
          message.error('An error occurred, please try again now')
        })
    }
  };

  return (
      <SortableList
        useDragHandle
        axis={'xy'}
        items={mainItem}
        onSortEnd={onSortEnd}
        entityGallery={entityGallery}
      />
  );
}

