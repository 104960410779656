import Alert from '@mui/material/Alert';
import { useAlert } from '@hooks/Alert/useAlert';

export default function useSuccessAlert() {
  const [onOpen, onClose] = useAlert();
  const handleOpen = (text: string) => {
    return onOpen({
      children: (
        <Alert severity="success" sx={{ width: '100%' }}>
          {text}
        </Alert>
      ),
    })
  };

  return [handleOpen, onClose]
}