import { ActionType, Actions } from './actions';

import { ListResponse } from '@api/types';
import { SearchSynonym } from '@modelTypes/searchSynonym';

export interface SearchSynonymsState {
  readonly grid: ListResponse<SearchSynonym> & { loading: boolean }
  readonly form: {
    readonly loading: boolean;
    readonly row: SearchSynonym | null;
  };
  readonly selectSynonyms: {
    readonly loading: boolean;
    readonly search: string | null;
    readonly data: Array<SearchSynonym>;
  };
}

const initialState: SearchSynonymsState = {
  grid: {
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  form: {
    loading: false,
    row: null,
  },
  selectSynonyms: {
    loading: false,
    search: null,
    data: [],
  }
};

export default function searchSections(state = initialState, action: Actions): SearchSynonymsState {
  switch (action.type) {
    case ActionType.START_FETCH_SEARCH_SYNONYMS:
    // case ActionType.START_DELETE_AUTHOR:
      return {
        ...state,
        grid: {
          ...state.grid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_SEARCH_SYNONYMS:
      return {
        ...state,
        grid: {
          ...action.data,
          loading: false,
        },
      }
    case ActionType.START_FETCH_SEARCH_SYNONYMS_FOR_SELECT:
      return {
        ...state,
        selectSynonyms: {
          ...state.selectSynonyms,
          loading: true,
        }
      }
    case ActionType.END_FETCH_SEARCH_SYNONYMS_FOR_SELECT:
      return {
        ...state,
        selectSynonyms: {
          ...state.selectSynonyms,
          loading: false,
          data: action.data.data,
        }
      }
    case ActionType.CLEAR_SYNONYMS_FOR_SELECT:
      return {
        ...state,
        selectSynonyms: {
          ...initialState.selectSynonyms,
        },
      };
    case ActionType.START_FETCH_SEARCH_SYNONYM:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        }
      }
    case ActionType.END_FETCH_SEARCH_SYNONYM:
    case ActionType.END_POST_SEARCH_SYNONYM:
    case ActionType.END_PATCH_SEARCH_SYNONYM:
      return {
        ...state,
        form: {
          ...state.form,
          row: action.data,
          loading: false,
        },
      }
    case ActionType.START_POST_SEARCH_SYNONYM:
    case ActionType.START_PATCH_SEARCH_SYNONYM:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        },
      }
    case ActionType.END_POST_SEARCH_SYNONYM_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.END_PATCH_SEARCH_SYNONYM_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.CLEAR_FORM_DATA:
      return {
        ...state,
        form: {
          ...initialState.form,
        }
      }
    default:
      return state;
  }
}