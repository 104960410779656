import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg data-id="28" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
  d="M10.2044 18.6324C10.066 18.5202 9.8583 18.4641 9.65063 18.4641C9.44296 18.4641 9.23529 18.5202 9.09685 18.6324C8.9584 18.8008 8.61229 19.1376 8.3354 19.4743C8.19696 19.6427 8.05851 19.8672 7.98929 20.0356C7.92007 20.204 7.85084 20.4285 7.85084 20.5968C7.85084 21.3265 8.68151 21.8877 9.65063 21.8877C10.6197 21.8877 11.4504 21.3265 11.4504 20.5968C11.4504 20.4285 11.3812 20.2601 11.312 20.0356C11.2427 19.8672 11.1043 19.6427 10.9659 19.4743C10.7582 19.1376 10.4813 18.8569 10.3429 18.6886C10.2736 18.7447 10.2044 18.6886 10.2044 18.6324ZM9.65063 21.2704C9.16607 21.2704 8.75074 20.9897 8.75074 20.5968C8.75074 20.5407 8.75074 20.4285 8.81996 20.3162C8.88918 20.204 8.9584 20.0917 9.02763 19.9795C9.16607 19.7549 9.44296 19.4743 9.65063 19.2498C9.8583 19.5304 10.1352 19.755 10.2736 20.0356C10.3429 20.1478 10.4121 20.2601 10.4813 20.3723C10.5505 20.4846 10.5505 20.5407 10.5505 20.5968C10.5505 20.9897 10.1352 21.2704 9.65063 21.2704Z"
  fill="currentColor" />
<path
  d="M9.65097 22C8.61263 22 7.78196 21.3826 7.78196 20.653C7.78196 20.4846 7.85118 20.3162 7.9204 20.0917C7.98962 19.9233 8.12807 19.6988 8.26651 19.5304C8.5434 19.1376 8.88952 18.7447 9.02796 18.6886C9.16641 18.5763 9.37407 18.4641 9.58174 18.4641C9.78941 18.4641 9.99708 18.5202 10.2047 18.6886L10.274 18.8008C10.4124 18.9692 10.6893 19.2498 10.897 19.5866C11.0354 19.8111 11.1739 19.9795 11.2431 20.1478C11.3815 20.3723 11.3815 20.5407 11.3815 20.7091C11.52 21.3826 10.6893 22 9.65097 22ZM9.65097 18.5202C9.4433 18.5202 9.30485 18.5763 9.16641 18.6886C9.09718 18.8008 8.75107 19.1376 8.40496 19.5304C8.26651 19.755 8.12807 19.9233 8.05885 20.0917C7.98962 20.3162 7.9204 20.4846 7.9204 20.5968C7.9204 21.2704 8.68185 21.8316 9.65097 21.8316C10.6201 21.8316 11.3815 21.2704 11.3815 20.5968C11.3815 20.4846 11.3123 20.2601 11.2431 20.0917C11.1739 19.9233 11.0354 19.755 10.897 19.5304C10.6893 19.1937 10.4124 18.9131 10.274 18.7447L10.2044 18.6324C9.99674 18.5763 9.78941 18.5202 9.65097 18.5202ZM9.65097 21.3265C9.09719 21.3265 8.68185 20.9897 8.68185 20.5968C8.68185 20.5407 8.68185 20.4285 8.75107 20.3162C8.82029 20.204 8.88952 20.0917 8.95874 19.9795C9.09718 19.7549 9.30485 19.4743 9.58174 19.1937L9.65097 19.1376L9.72019 19.1937C9.92786 19.4743 10.2047 19.6988 10.3432 19.9795C10.4124 20.0917 10.4816 20.2601 10.5509 20.3162C10.6201 20.4285 10.6201 20.5407 10.6201 20.5968C10.6201 21.0459 10.1355 21.3265 9.65097 21.3265ZM9.65097 19.3621C9.4433 19.5866 9.23563 19.8111 9.09719 20.0356C9.02796 20.1478 8.95874 20.2601 8.88952 20.3723C8.82029 20.4846 8.82029 20.5407 8.82029 20.5968C8.82029 20.9336 9.16641 21.2142 9.65097 21.2142C10.1355 21.2142 10.4816 20.9336 10.4816 20.5968C10.4816 20.5407 10.4816 20.4846 10.4124 20.3723C10.3432 20.2601 10.274 20.1478 10.2047 20.0356C10.0663 19.8672 9.85863 19.5866 9.65097 19.3621Z"
  fill="currentColor" />
<path
  d="M6.05018 18.0696C5.91174 17.9573 5.70407 17.9012 5.4964 17.9012C5.28873 17.9012 5.08107 17.9573 4.94262 18.0696C4.80418 18.238 4.45806 18.5747 4.18117 18.9115C4.04273 19.0799 3.90428 19.3044 3.83506 19.4727C3.76584 19.6411 3.69661 19.8656 3.69661 20.034C3.69661 20.7637 4.52728 21.3249 5.4964 21.3249C6.46552 21.3249 7.29619 20.7637 7.29619 20.034C7.29619 19.8656 7.22696 19.6973 7.15774 19.4727C7.08852 19.3044 6.95007 19.0799 6.81163 18.9115C6.60396 18.5747 6.32707 18.2941 6.18863 18.1257C6.1194 18.1818 6.05018 18.1257 6.05018 18.0696ZM5.4964 20.7075C5.01184 20.7075 4.59651 20.4269 4.59651 20.034C4.59651 19.9779 4.59651 19.8656 4.66573 19.7534C4.73495 19.6411 4.80417 19.5289 4.8734 19.4166C5.01184 19.1921 5.28873 18.9115 5.4964 18.687C5.70407 18.9676 5.98096 19.1921 6.1194 19.4727C6.18863 19.585 6.25785 19.6973 6.32707 19.8095C6.39629 19.9218 6.39629 19.9779 6.39629 20.034C6.39629 20.4269 5.98096 20.7075 5.4964 20.7075Z"
  fill="currentColor" />
<path
  d="M5.49674 21.4372C4.4584 21.4372 3.62773 20.8198 3.62773 20.0901C3.62773 19.9218 3.69695 19.7534 3.76617 19.5289C3.83539 19.3605 3.97384 19.136 4.11228 18.9676C4.38918 18.5747 4.73529 18.1818 4.87373 18.1257C5.01218 18.0135 5.21985 17.9012 5.42751 17.9012C5.63518 17.9012 5.84285 17.9573 6.05052 18.1257L6.11974 18.238C6.25818 18.4063 6.53507 18.687 6.74274 19.0237C6.88119 19.2482 7.01963 19.4166 7.08885 19.585C7.2273 19.8095 7.2273 19.9779 7.2273 20.1463C7.36575 20.8198 6.53507 21.4372 5.49674 21.4372ZM5.49674 17.9573C5.28907 17.9573 5.15062 18.0135 5.01218 18.1257C4.94296 18.238 4.59684 18.5747 4.25073 18.9676C4.11229 19.1921 3.97384 19.3605 3.90462 19.5289C3.83539 19.7534 3.76617 19.9218 3.76617 20.034C3.76617 20.7075 4.52762 21.2688 5.49674 21.2688C6.46585 21.2688 7.2273 20.7075 7.2273 20.034C7.2273 19.9218 7.15808 19.6973 7.08885 19.5289C7.01963 19.3605 6.88119 19.1921 6.74274 18.9676C6.53507 18.6309 6.25818 18.3502 6.11974 18.1818L6.05018 18.0696C5.84251 18.0135 5.63518 17.9573 5.49674 17.9573ZM5.49674 20.7636C4.94296 20.7636 4.52762 20.4269 4.52762 20.034C4.52762 19.9779 4.52762 19.8656 4.59684 19.7534C4.66607 19.6411 4.73529 19.5289 4.80451 19.4166C4.94296 19.1921 5.21985 18.9115 5.42751 18.6309L5.49674 18.5747L5.56596 18.6309C5.77363 18.9115 6.05052 19.136 6.18896 19.4166C6.25818 19.5289 6.32741 19.6973 6.39663 19.7534C6.46585 19.8656 6.46585 19.9779 6.46585 20.034C6.46585 20.483 5.98129 20.7636 5.49674 20.7636ZM5.49674 18.7992C5.28907 19.0237 5.0814 19.2482 4.94296 19.4727C4.87373 19.585 4.80451 19.6973 4.73529 19.8095C4.66606 19.9218 4.66606 19.9779 4.66606 20.034C4.66606 20.3708 5.01218 20.6514 5.49674 20.6514C5.98129 20.6514 6.32741 20.3708 6.32741 20.034C6.32741 19.9779 6.32741 19.9218 6.25818 19.8095C6.18896 19.6973 6.11974 19.585 6.05052 19.4727C5.91207 19.3044 5.7044 19.0237 5.49674 18.7992Z"
  fill="currentColor" />
<path
  d="M15.3282 9.36949C11.9363 6.11417 8.4059 0.389296 8.4059 0.33317C8.26746 -0.00358738 7.7829 -0.0597136 7.36756 0.0525388C6.95223 0.164791 6.88301 0.557675 7.02145 0.894432C7.22912 1.11894 10.7595 6.78768 14.1514 10.0991C16.4357 12.3442 17.5433 14.028 17.4048 15.0382C17.4048 15.4311 17.1279 15.7679 16.6434 16.0485C14.7744 17.171 12.0747 16.6659 10.3441 16.1608C11.5901 15.824 12.2824 15.824 12.2824 15.824C12.6977 15.824 13.0438 15.5434 13.0438 15.2066C13.0438 14.8699 12.6285 14.6454 12.2131 14.6454C12.1439 14.6454 10.0672 14.7015 6.67534 16.1046C6.05234 16.3853 5.42933 16.4414 5.014 16.2169C4.66788 15.9924 4.46022 15.5995 4.52944 15.1505C4.52944 14.8137 4.80633 13.9718 6.19078 13.4667C6.60612 13.3545 6.74456 12.9616 6.60612 12.6248C6.46767 12.2881 5.98311 12.1758 5.56778 12.2881C4.04488 12.8493 3.14499 13.8596 3.00654 14.9821C2.93732 15.8801 3.42188 16.722 4.25255 17.171C4.52944 17.2833 5.014 17.5078 5.70622 17.5078C6.19078 17.5078 6.81378 17.3955 7.43679 17.1149C7.71368 17.0027 7.99057 16.8904 8.33668 16.7782C9.09813 17.0588 11.3825 17.9007 13.8745 17.9007C15.1897 17.9007 16.5049 17.6762 17.6125 17.0027C18.374 16.4975 18.8585 15.8801 18.997 15.1505C19.0662 13.8035 17.9586 11.9513 15.3282 9.36949Z"
  fill="currentColor" />
</svg>`;

export default { id: '28-allergy-immunology', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
