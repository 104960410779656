import { BaseRow, FiltersConfig, FilterType } from '@components/AntGrid/types';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { useHistory } from 'react-router-dom';
import { getHistorySearchParam } from '@helpers/history';
import { getIsActiveCell } from '@components/AntGrid/cells/StatusCell';
import React from 'react';
import { FrontendPreviewCell } from '@components/AntGrid/cells/FrontendPreviewCell';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import { message } from 'antd';
import { EditCell } from '../cells/EditCell';
import { ColumnType } from 'antd/es/table';
import getIdCell from '@components/AntGrid/cells/IdCell';
import { dispatchRefreshOfferGridEvent } from '@containers/Offers/Offers/components/OffersGrid';
import { useOfferStore } from '@zustandStorage/offers/offer';
import { Offer } from '@modelTypes/offer';
import { getDateFormattedCell } from '@components/AntGrid/cells/DateFormattedCell';

export type OfferColumnsNames = `${keyof Offer}Column`| 'editColumn' | 'deleteColumn' | 'previewColumn' | 'companyColumn';

export const OfferDefinitions: Partial<Record<OfferColumnsNames, ColumnType<Offer>>> = {
  idColumn: createColumnDefinition<Offer>('#', 'id', { width: 80, render: getIdCell }),
  nameColumn: createColumnDefinition<Offer>('Name', 'name'),
  hospitalColumn: {
    title: 'Hospital',
    render: getHospitalCell<Offer>(),
    sorter: false,
    filtered: false,
  },
  doctorColumn: {
    title: 'Doctor',
    render: getDoctorCell<Offer>(),
    sorter: false,
    filtered: false,
  },
  priceColumn: createColumnDefinition<Offer>('Price', 'price'),
  vendorColumn: {
    title: 'Vendor',
    render: getVendorCell<Offer>(),
    sorter: false,
    filtered: false,
  },
  companyColumn: {
    title: 'Vendor company',
    render: getVendorCompanyCell<Offer>(),
    sorter: false,
    filtered: false,
  },
  updatedAtColumn: createColumnDefinition<Offer>('Updated At', 'updatedAt', { render: getDateFormattedCell, width: 160 }),
  createdAtColumn: createColumnDefinition<Offer>('Created At', 'createdAt', { render: getDateFormattedCell, width: 160 }),
  descriptionColumn: createColumnDefinition<Offer>('Has desc', 'description', { render: getIsActiveCell, width: 80, filtered: false }),
  isActiveColumn: createColumnDefinition<Offer>('Active', 'isActive', { render: getIsActiveCell, width: 80, filtered: false }),
  isLanguageActiveColumn: createColumnDefinition<Offer>('Lang Active', 'isLanguageActive', { render: getIsActiveCell, width: 80, filtered: false }),
  previewColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getPreviewCell<Offer>(),
    sorter: false,
    filtered: false,
  },
  editColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getEditCell<Offer>(),
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell(),
    sorter: false,
    filtered: false,
  }
};

export const offerColumns: any = [
  OfferDefinitions.idColumn,
  OfferDefinitions.nameColumn,
  OfferDefinitions.hospitalColumn,
  OfferDefinitions.doctorColumn,
  OfferDefinitions.priceColumn,
  OfferDefinitions.vendorColumn,
  OfferDefinitions.companyColumn,
  OfferDefinitions.updatedAtColumn,
  OfferDefinitions.createdAtColumn,
  OfferDefinitions.descriptionColumn,
  OfferDefinitions.isActiveColumn,
  OfferDefinitions.isLanguageActiveColumn,
  OfferDefinitions.previewColumn,
  OfferDefinitions.editColumn,
  OfferDefinitions.deleteColumn,
];

export const offerFiltersConfig: FiltersConfig = {
  [OfferDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [OfferDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [OfferDefinitions.hospitalColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [OfferDefinitions.doctorColumn?.key as string]: { filterType: FilterType.SEARCH },
  [OfferDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [OfferDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function EditProgramCell({ record }: { record: any }) {
  const history = useHistory();
  const langId = +getHistorySearchParam(history, 'language-id', 1);
  const params = langId > 1 ? `?language-tab=${langId - 1}` : '';

  return (
    <EditCell to={`/offers/${record.id}/edit/${params}`} />
  );
}

function OfferPreviewCell<T extends BaseRow>({ record }: { record: T }) {


  const handlePreview = () => {
    const url = `${process.env.REACT_APP_CLIENT_URL}/offer/${record.uuid}`;

    if (window !== null) {
      // @ts-ignore
      window.open(url, '_blank', 'noopener,noreferrer').focus();
    }
  };

  return (
    <FrontendPreviewCell
      onGetAccessToken={handlePreview}
    />
  );
}

function CustomDeleteCell({ record }: { record: Offer }) {
  const { deleteOffer } = useOfferStore()
  return (
    <DeleteCell
      record={record}
      title="Delete Offer"
      deleteAction={deleteOffer}
      onSuccess={() => {
        dispatchRefreshOfferGridEvent();
        message.success('The Offer has been successfully deleted');
      }}
      hasNotRelationsContent={() => <div>Offer has no any relations.</div>}
      hasRelationsContent={() => {
        return (
          <div style={{}}>
            <div>If you really want to continue, enter your password.</div>
          </div>
        );
      }}
    />
  );
}

function HospitalCell<T extends BaseRow>({ record }: { record: T }) {
  return (
    <>{record.hospital ? record.hospital.title : ''}</>
  );
}

function DoctorCell<T extends BaseRow>({ record }: { record: T }) {
  return (
    <>{record.doctor ? record.doctor.name : ''}</>
  );
}

function CompanyCell<T extends BaseRow>({ record }: { record: T }) {
  return (
    <>{`${(record.vendor && record.vendor.company) ? record.vendor.company.name : ''}`}</>  );
}

function VendorCell<T extends BaseRow>({ record }: { record: T }) {
  return (
    <>{`${record.vendor ? record.vendor.firstName : ''} ${record.vendor ? record.vendor.lastName : ''}`}</>
  );
}

function getVendorCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return <VendorCell record={record} />
  };
}

function getHospitalCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return <HospitalCell record={record} />
  };
}

function getDoctorCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return <DoctorCell record={record} />
  };
}

function getVendorCompanyCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return <CompanyCell record={record} />
  };
}

function getEditCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return <EditProgramCell record={record} />
  };
}

function getPreviewCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    if(record.isActive) {

    return (
      <OfferPreviewCell record={record} />
    );
    } else return null
  };
}


function getDeleteCell() {
  return (value: any, record: Offer) => {
    return (
      <CustomDeleteCell record={record} />
    );
  };
}
