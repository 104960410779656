import { create } from 'zustand';
import produce from 'immer';
import {
  AntGetListParams,
  CreateTagRequest,
  CreateTagResponse,
  ListResponse,
  UpdateTagRequest,
  UpdateTagResponse
} from '@api/types';
import { initialFormState, initialGridState } from '@zustandStorage/helpers/initialStates';
import { createTag, deleteTag, getTags, updateTag } from '@api';
import { AxiosRequestConfig } from 'axios';
import { Certificate } from '@modelTypes/certificate';
import { Tag } from '@modelTypes/tag';

export interface TagsState {
  grid: ListResponse<Tag> & { loading: boolean; };
  form: {
    loading: boolean;
    row: Certificate | null;
  };
  clearFormData: () => void;
  fetchTags: (params?: AntGetListParams) => Promise<ListResponse<Tag>>;
  deleteTag: (id: number, payload?: AxiosRequestConfig) => Promise<any>;
  // fetchTag: (id: number, params?: Params) => Promise<Tag>;
  createTag: (payload: CreateTagRequest) => Promise<CreateTagResponse>;
  updateTag: (id: number, payload: UpdateTagRequest) => Promise<UpdateTagResponse>;
}

export const useTagsStore = create<TagsState>((set) => ({
  grid: initialGridState,
  form: initialFormState,

  // Grid
  fetchTags: async (params?: AntGetListParams): Promise<ListResponse<Tag>> => {
    set(produce((state) => {
      state.grid.loading = true;
    }));

    return getTags(params)
      .then((response) => {
        set(produce((state) => {
          state.grid.data = response.data;
          state.grid.meta = response.meta;
          state.grid.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.grid.loading = false;
        }));

        throw e;
      })
  },
  deleteTag: async (id, payload?) => {
    return deleteTag(id, payload)
  },

  // Form
  clearFormData: () => set(() => ({ form: initialFormState })),
  // fetchTag: async (id: number, params?: Params): Promise<Tag> => {
  //   set(produce((state) => {
  //     state.form.loading = true;
  //   }));
  //
  //   return getTag(id, params)
  //     .then((response) => {
  //       set(produce((state) => {
  //         state.form.row = response
  //         state.form.loading = false;
  //       }));
  //
  //       return response;
  //     })
  //     .catch((e) => {
  //       set(produce((state) => {
  //         state.form.loading = false;
  //       }));
  //
  //       throw e;
  //     })
  // },
  createTag: async (payload: CreateTagRequest): Promise<CreateTagResponse> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return createTag(payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateTag: async (id: number, payload: UpdateTagRequest): Promise<UpdateTagResponse> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateTag(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
}));