import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import QueryString from 'query-string';
import { useGridStore } from '@zustandStorage/grid/grid';

export default function useChangeGridOptions(gridId: string) {
  const history = useHistory();
  const locationParams = QueryString.parse(history.location.search.slice(1));

  useEffect(() => {
    const setGridOptions = useGridStore.getState().setGridOptions;
    setGridOptions(gridId, locationParams);
  }, [gridId, locationParams]);
}