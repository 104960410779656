import { GetListParams, ListResponse, UpdateOrderRequest } from '@api/types';
import { Order, OrderStatuses, Status } from '@modelTypes/order';

import produce from 'immer';

import { getOrders, getStatusesForSelect, updateOrder } from '@api';
import { create } from 'zustand';
import { Filters } from '@containers/Orders/Ant/components/OrderListFilters';
import { getOrder } from '@api/orderApi';


export interface OrdersState {
  list: ListResponse<Order> & {
    selectedRowsIds: Array<number>;
    status: OrderStatuses | null;
    loading: boolean;
  };
  // modalWithForm: {
  //   open: boolean;
  //   loading: boolean;
  //   data: ModalData
  // };
  // selectPhoneCountry: {
  //   loading: boolean;
  //   search: string | null;
  //   data: Array<PhoneCountry>;
  // };
  selectStatus: {
    loading: boolean;
    search: string | null;
    data: Array<Status>;
  };
  orderDetails: {
    loading: boolean;
    data: Order | null;
  }
  fetchOrders: (status: OrderStatuses, params?: GetListParams, filters?: Partial<Filters>) => Promise<ListResponse<Order>>;
  fetchOrder: (id: number) => Promise<Order>;
  fetchStatuses: () => Promise<Array<Status>>;
  updateOrder: (id: number, payload: UpdateOrderRequest) => Promise<Order>
  setSelectedRow: (id: number) => void;

}

const initialState: Omit<OrdersState, 'fetchOrders' | 'fetchOrder' | 'fetchStatuses' | 'updateOrder' | 'setSelectedRow'> = {
  list: {
    selectedRowsIds: [],
    status: null,
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 10,
    },
  },
  orderDetails: {
    loading: false,
    data: null,
  },
  // modalWithForm: {
  //   open: false,
  //   loading: false,
  //   data: {
  //     type: ModalWithFormTypes.ADD,
  //     formData: {
  //       name: '',
  //       email: '',
  //       phone: '',
  //       description: '',
  //     },
  //   },
  // },
  // selectPhoneCountry: {
  //   loading: false,
  //   search: null,
  //   data: [],
  // },
  selectStatus: {
    loading: false,
    search: null,
    data: [],
  }
};

export const useOrderState = create<OrdersState>((set) => ({
  list: initialState.list,
  selectStatus: initialState.selectStatus,
  orderDetails: initialState.orderDetails,
  setSelectedRow: (id: number) => {
    set(produce((state) => {
      const index = state.list.selectedRowsIds.indexOf(id);
      if (index !== -1) {
        state.list.selectedRowsIds.splice(index, 1);
      } else {
        state.list.selectedRowsIds.push(id);
      }
    }));
  },
  fetchOrders: async (status: OrderStatuses, params?: GetListParams, filters?: Partial<Filters>): Promise<ListResponse<Order>> => {
    set(produce((state) => {
      state.list.loading = true;
    }));

    return getOrders(status, params, filters)
      .then((response) => {
        set(produce((state) => {
          state.list.data = response.data;
          state.list.meta = response.meta;
          state.list.loading = false
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.list.loading = false;
        }));

        throw e;
      });
  },

  fetchOrder: async (id: number): Promise<Order> => {
    set(produce((state) => {
      state.orderDetails.loading = true;
    }));

    return getOrder(id)
      .then((response) => {
        set(produce((state) => {
          state.orderDetails.data = response;
          state.orderDetails.loading = false
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.orderDetails.loading = false;
        }));

        throw e;
      });
  },

  fetchStatuses: async () => {
    set(produce((state) => {
      state.selectStatus.loading = true;
    }));

    return getStatusesForSelect()
      .then((response) => {
        set(produce((state) => {
          state.selectStatus.data = response;
          state.selectStatus.loading = false
        }));
        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.selectStatus.loading = false;
        }));

        throw e;
      });
  },

  updateOrder: async (id: number, payload: UpdateOrderRequest): Promise<Order> => {
    set(produce((state) => {
      state.list.loading = true;
    }));

    return updateOrder(id, payload)
      .then((res) => {
        set(produce((state) => {
          state.list.loading = false;
        }));
        return res
      })
      .catch((e) => {
        set(produce((state) => {
          state.list.loading = false;
        }));

        throw e;
      });
  }
}))