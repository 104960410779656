import { useState } from 'react';
import { Button } from 'antd';
import AddCustomSkillsModal
  from '@containers/Doctors/FormSteps/SkillsStep/components/AddCustomSkillsModal/AddCustomSkillsModal';


export default function AddCustomSkillsModalTrigger({...props}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button type="primary" onClick={() => setIsModalOpen(true)} {...props}>
        Create custom
      </Button>
      <AddCustomSkillsModal handleCancel={handleCancel} isOpen={isModalOpen} />
    </>
  );
};

