import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M3.35884 6.74644C3.42129 6.77046 3.48679 6.78269 3.55361 6.78269C3.77459 6.78269 3.97635 6.64425 4.05627 6.43812C4.15628 6.17958 4.26284 5.92498 4.37332 5.68129C4.49604 5.41053 4.37551 5.09042 4.10474 4.9677C3.97373 4.90831 3.82743 4.9035 3.69249 4.9546C3.55754 5.00526 3.45055 5.1057 3.39159 5.23672C3.27324 5.49831 3.15882 5.77125 3.05139 6.049C2.94396 6.32632 3.0824 6.63901 3.35927 6.74644H3.35884Z"
    fill="currentColor" />
  <path
    d="M3.03829 14.5531C3.30861 14.7291 3.61562 14.8685 3.95058 14.9676C4.00037 14.9824 4.0519 14.9899 4.10343 14.9899C4.34057 14.9899 4.55281 14.8309 4.62007 14.6034C4.70435 14.3182 4.54102 14.0182 4.25585 13.9339C4.0209 13.8644 3.80909 13.7688 3.62611 13.65C3.50557 13.5714 3.36146 13.5443 3.2204 13.5745C3.07978 13.6042 2.95881 13.6871 2.8802 13.8077C2.80159 13.9282 2.77495 14.0723 2.80465 14.2134C2.83434 14.3544 2.91732 14.475 3.03785 14.5536L3.03829 14.5531Z"
    fill="currentColor" />
  <path
    d="M7.52597 14.3405C7.49802 14.199 7.41679 14.0771 7.29756 13.9972C7.1779 13.9169 7.03423 13.888 6.89317 13.916C6.77569 13.9391 6.65909 13.9601 6.54641 13.978C6.25294 14.0256 6.05293 14.3029 6.10053 14.5959C6.14289 14.8584 6.36649 15.0493 6.63245 15.0493C6.66083 15.0493 6.68966 15.0471 6.71848 15.0423C6.84251 15.0222 6.97134 14.999 7.10192 14.9733C7.39364 14.9156 7.58405 14.6318 7.5264 14.3405H7.52597Z"
    fill="currentColor" />
  <path
    d="M2.92125 8.88328C2.98894 8.62606 3.06667 8.36185 3.15314 8.09763C3.24529 7.81508 3.09026 7.51025 2.8077 7.4181C2.52515 7.32596 2.22032 7.48099 2.12818 7.76355C2.03603 8.04697 1.95174 8.33127 1.87881 8.60946C1.80326 8.89682 1.97576 9.19204 2.26312 9.26803C2.3081 9.27982 2.35396 9.28593 2.40025 9.28593C2.64481 9.28593 2.8588 9.12085 2.92125 8.88416V8.88328Z"
    fill="currentColor" />
  <path
    d="M2.03472 10.5773C1.73732 10.5773 1.49581 10.8192 1.49581 11.1171C1.49581 11.442 1.51765 11.7538 1.56088 12.0429C1.60019 12.3049 1.82903 12.5023 2.09367 12.5023C2.11988 12.5023 2.14652 12.5002 2.17359 12.4962C2.31596 12.4748 2.4413 12.3993 2.5269 12.2835C2.61249 12.1678 2.64787 12.0254 2.6269 11.8835C2.59153 11.6464 2.57362 11.3883 2.57362 11.1158C2.57362 10.8188 2.33168 10.5773 2.03472 10.5773Z"
    fill="currentColor" />
  <path
    d="M16.5053 13.978C16.3913 13.9597 16.2747 13.9387 16.1589 13.916C16.0179 13.888 15.8746 13.9169 15.755 13.9972C15.6353 14.0776 15.5537 14.1994 15.5261 14.3405C15.4685 14.6322 15.6589 14.9161 15.9506 14.9737C16.0795 14.999 16.2083 15.0222 16.3332 15.0423C16.3616 15.0471 16.3908 15.0493 16.4201 15.0493C16.6861 15.0493 16.9097 14.8588 16.952 14.5959C16.9992 14.3025 16.7992 14.0252 16.5057 13.978H16.5053Z"
    fill="currentColor" />
  <path
    d="M20.478 11.1171C20.478 11.3887 20.4601 11.6468 20.4248 11.8839C20.4034 12.0263 20.4387 12.1687 20.5243 12.2844C20.6099 12.4001 20.7357 12.4757 20.8789 12.4971C20.9052 12.501 20.9318 12.5028 20.958 12.5028C21.2226 12.5028 21.4515 12.3054 21.4908 12.0433C21.534 11.7542 21.5559 11.442 21.5559 11.1158C21.5559 10.8188 21.3139 10.5773 21.0169 10.5773C20.72 10.5773 20.478 10.8192 20.478 11.1171Z"
    fill="currentColor" />
  <path
    d="M19.868 5.23672C19.8086 5.1057 19.7016 5.00526 19.5671 4.9546C19.4321 4.90394 19.2858 4.90874 19.1548 4.96814C18.884 5.09085 18.7635 5.41096 18.8862 5.68129C18.9963 5.92498 19.1033 6.17958 19.2033 6.43812C19.2832 6.64425 19.485 6.78269 19.7059 6.78269C19.7728 6.78269 19.8383 6.77046 19.9007 6.74644C20.1776 6.63901 20.316 6.32632 20.2086 6.049C20.1012 5.77213 19.9867 5.49874 19.8684 5.23672H19.868Z"
    fill="currentColor" />
  <path
    d="M20.2435 7.41767C19.961 7.50981 19.8064 7.81464 19.8985 8.0972C19.9846 8.36097 20.0627 8.62562 20.1304 8.88284C20.1929 9.11954 20.4069 9.28462 20.6514 9.28462C20.6977 9.28462 20.744 9.27851 20.7885 9.26672C21.0759 9.19116 21.2484 8.89551 21.1729 8.60815C21.0995 8.3304 21.0156 8.04566 20.9235 7.76224C20.8313 7.47968 20.5261 7.32465 20.244 7.41679L20.2435 7.41767Z"
    fill="currentColor" />
  <path
    d="M18.4748 14.1933C18.4058 14.3199 18.3906 14.4654 18.4312 14.6034C18.4984 14.8309 18.7107 14.9899 18.9478 14.9899C18.9993 14.9899 19.0509 14.9824 19.1006 14.9676C19.4352 14.8689 19.7422 14.7291 20.0125 14.5531C20.1335 14.4745 20.216 14.354 20.2462 14.2129C20.2758 14.0719 20.2492 13.9282 20.1706 13.8077C20.092 13.6867 19.971 13.6037 19.8304 13.574C19.6893 13.5443 19.5457 13.571 19.4251 13.6496C19.2417 13.7688 19.0299 13.8644 18.7954 13.9339C18.6574 13.9745 18.5438 14.0666 18.4748 14.1933Z"
    fill="currentColor" />
  <path
    d="M5.7612 0.959534C5.99266 0.773493 6.02978 0.433293 5.84374 0.201834C5.6577 -0.0296245 5.31794 -0.0667452 5.08604 0.119295C2.84526 1.92031 0.341576 5.37384 0.0328189 9.66587C-0.26109 13.7509 1.42026 17.7857 5.02883 21.6581C5.13059 21.7672 5.27427 21.8297 5.42319 21.8297C5.55988 21.8297 5.69045 21.7782 5.79046 21.6851C5.89571 21.5869 5.95641 21.4537 5.96165 21.31C5.9669 21.1663 5.91536 21.0287 5.81754 20.9235C2.42121 17.2787 0.83681 13.5173 1.10801 9.74317C1.39187 5.79658 3.69773 2.61774 5.7612 0.959097V0.959534Z"
    fill="currentColor" />
  <path
    d="M16.0947 15.1519C14.1959 14.73 12.9884 13.8806 12.5054 12.6281C12.1573 11.7245 12.1774 10.5629 12.5639 9.26846C12.8875 8.18541 13.4557 7.0386 14.1636 6.03896C14.3352 5.79658 14.2776 5.45944 14.0352 5.28781C13.9177 5.2044 13.7749 5.17208 13.633 5.1961C13.491 5.22012 13.367 5.29829 13.2836 5.41577C12.6246 6.34597 12.0678 7.41941 11.6992 8.46404C11.3306 7.41898 10.7738 6.34553 10.1148 5.41533C10.0314 5.29786 9.90737 5.21968 9.76587 5.19566C9.62394 5.17164 9.48114 5.20396 9.36366 5.28737C9.12128 5.459 9.06364 5.79615 9.23527 6.03896C9.94318 7.0386 10.5113 8.18541 10.835 9.26846C11.2214 10.5629 11.242 11.7245 10.8935 12.6281C10.4105 13.881 9.20295 14.73 7.30455 15.1519C7.16393 15.1829 7.04383 15.2672 6.96653 15.3886C6.88924 15.51 6.86391 15.6545 6.89491 15.7947C6.9495 16.0393 7.17048 16.217 7.42072 16.217C7.46002 16.217 7.49976 16.2127 7.53776 16.2039C9.13788 15.8484 10.3463 15.206 11.1341 14.2929V21.4611C11.1341 21.7585 11.376 22 11.673 22C11.97 22 12.2119 21.7581 12.2119 21.4611V14.2326C12.9984 15.1781 14.2234 15.8406 15.8589 16.2039C15.8973 16.2127 15.9371 16.217 15.9768 16.217C16.2271 16.217 16.4485 16.0393 16.5026 15.7947C16.5336 15.6541 16.5083 15.51 16.431 15.3886C16.3537 15.2672 16.2336 15.1829 16.0934 15.1519H16.0947Z"
    fill="currentColor" />
  <path
    d="M23.9115 9.66587C23.6027 5.37384 21.0991 1.91987 18.8587 0.118858C18.7469 0.0288953 18.6063 -0.0121559 18.4626 0.00312911C18.3194 0.0188508 18.191 0.0891619 18.1006 0.200961C18.0106 0.313196 17.9696 0.453818 17.9853 0.597061C18.001 0.740303 18.0713 0.868697 18.1831 0.95866C20.2466 2.6173 22.5524 5.79615 22.8363 9.74274C23.1075 13.5168 21.5231 17.2782 18.1263 20.9231C18.0281 21.0283 17.977 21.1654 17.9822 21.3096C17.9875 21.4537 18.0482 21.5869 18.1534 21.6847C18.2534 21.7782 18.384 21.8297 18.5211 21.8297C18.67 21.8297 18.8142 21.7672 18.9155 21.6581C22.5245 17.7853 24.2054 13.7505 23.9119 9.66544L23.9115 9.66587Z"
    fill="currentColor" />
</svg>`;

export default { id: '179-bbl-surgery', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
