import * as React from 'react';
import { useEffect, useState } from 'react';

import { isEqual, omit } from 'lodash';

import { Permission } from '@modelTypes/permission';
import usePrevious from '@hooks/usePrevious';
import { useRoleStore } from '@zustandStorage/role/role';
import { Card, Checkbox, Col, Row, Divider } from 'antd';

interface Props {
  defaultValue: Array<Permission>;
  onChange: (values: Array<number>) => void;
}

export default function RolesPermissions(
  {
    defaultValue,
    onChange
  }: Props
) {

  const permissions = useRoleStore.getState().form.permissions || [];

  const [selected, setSelected] = useState<Record<number, boolean>>({});
  const prevDefaultValue = usePrevious(defaultValue);

  const getDataToSetCheckboxes = (permission: Permission): Record<string, boolean> => {
    let values: Record<string, boolean> = {};
    permission.children.forEach((i) => {
      values[i.id] = true;

      if (i.children.length) {
        i.children.forEach((j) => {
          values = { ...values, ...getDataToSetCheckboxes(j) };
        })
      }
    });

    return { [permission.id]: true, ...values };
  }

  const getDataToResetCheckboxes = (permission: Permission): Array<number> => {
    let values: Array<number> = [];

    values.push(permission.id);
    permission.children.forEach((i) => {
      values.push(i.id);

      if (i.children.length) {
        i.children.forEach((j) => {
          values = values.concat(getDataToResetCheckboxes(j));
        })
      }
    });

    return values;
  }

  const handleChange = (checked: boolean, permission: Permission) => {
    const children = permission.children;

    if (children.length) {
      if (checked) {
        setSelected({ ...selected, ...getDataToSetCheckboxes(permission) });
      } else {
        setSelected(omit(selected, getDataToResetCheckboxes(permission)));
      }
    } else {
      updateSelected(permission.id, checked);
    }
  };

  const updateSelected = (id: number, value: boolean) => {
    if (!value) {
      setSelected(omit(selected, id));
    } else {
      setSelected({ ...selected, [id]: value });
    }
  }

  const renderSubPermissions = (permissions: Array<Permission>, isHidden = false) => {
    return (
      <ul style={{ display: isHidden ? 'none' : 'block', listStyle: 'none' }}>
        {permissions.map((i, k) => {
          return (
            <li key={k}>

              <Checkbox
                name={i.slug}
                checked={getValue(i.id)}
                onChange={(e) => handleChange(e.target.checked, i)}
              >
                {i.name}
              </Checkbox>
              {!!(i.children && i.children.length) && (
                renderSubPermissions(i.children, true)
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  const renderPermissions = (permissions: Array<Permission>) => {
    return permissions.map((permission, key) => {
      return (
        <>
          <Col span={8}>
            <Card title={permission.name} bordered={false} style={{ minHeight: '100%' }}>
              <Checkbox
                key={key}
                name={permission.slug}
                checked={getValue(permission.id)}
                onChange={(e) => handleChange(e.target.checked, permission)}
                style={{ marginBottom: 16, fontWeight: 'bold' }}
              >
                Check all
              </Checkbox>
              {renderSubPermissions(permission.children)}
            </Card>
          </Col>

        </>
      );
    })
  };

  const getValue = (id: number) => {

    return typeof selected[id] !== 'undefined' ? selected[id] : false;
  }

  useEffect(() => {
    onChange(Object.keys(selected).map(Number) as unknown as Array<number>);
  }, [selected]);

  useEffect(() => {
    if (!isEqual(prevDefaultValue, defaultValue)) {
      const values: Record<number, boolean> = {};
      defaultValue.forEach((i) => values[i.id] = true);

      setSelected(values);
    }
  }, [defaultValue]);

  return (
    <>
      <Divider orientation="left" style={{ fontWeight: 'bold', fontSize: 20 }}>Permissions</Divider>
      {!!permissions.length && (
        <Row gutter={[16, 16]}>
          {renderPermissions(permissions)}
        </Row>
      )}
    </>
  );
}
