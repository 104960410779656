import { FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { getDateFormattedCell } from '../cells/DateFormattedCell';
import React from 'react';
import { Review } from '@modelTypes/review';
import { Checkbox, message, Popconfirm, Select, Tooltip } from 'antd';
import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { useReviewsStore } from '@zustandStorage/reviews/rewiews';
import { dispatchRefreshReviewsGridEvent } from '@components/ReviewsGrid/ReviewsGrid';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import getIdCell from '@components/AntGrid/cells/IdCell';

export type ReviewsColumnsNames = `${keyof Review}Column` | 'editColumn' | 'deleteColumn';

interface SelectStatusProps {
  onClear?: () => void;
  loading?: boolean;
  multiple?: boolean;
  mode?: 'multiple' | 'tags';
  defaultValue?: any;
  disabled?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
  record: Review
}

const reviewsStatuses: Record<number, string> = {
  0: 'New',
  1: 'Verified',
  2: 'Unverified',
};

export const reviewsColumnsDefinitions: Partial<Record<ReviewsColumnsNames, ColumnType<Review>>> = {
  idColumn: createColumnDefinition<Review>('#', 'id', { width: 80, render: getIdCell }),
  firstNameColumn: createColumnDefinition<Review>('First name', 'firstName', { width: 200 }),
  lastNameColumn: createColumnDefinition<Review>('Last name', 'lastName', { width: 200 }),
  textColumn: createColumnDefinition<Review>('Text', 'text', {width: 300}),
  ratingColumn: createColumnDefinition<Review>('Rating', 'rating', { width: 100 }),
  createdAtColumn: createColumnDefinition<Review>('Created At', 'createdAt', {
    render: getDateFormattedCell,
    width: 160
  }),
  statusColumn: createColumnDefinition<Review>('Status', 'status', { render: getStatusCell, width: 170 }),
  isActiveColumn: createColumnDefinition<Review>('isActive', 'isActive', { render: getIsActiveCell, width: 170 }),
  editColumn: {
    title: '',
    width: 140,
    fixed: 'right',
    render: (value: any, row: Review) => {
      return <EditCell record={row} />
    }
  },
  deleteColumn: {
    title: '',
    width: 100,
    fixed: 'right',
    render: getDeleteCell(),
    sorter: false,
    filtered: false,
  }
};

export const reviewsColumns: any = [
  reviewsColumnsDefinitions.idColumn,
  reviewsColumnsDefinitions.firstNameColumn,
  reviewsColumnsDefinitions.lastNameColumn,
  reviewsColumnsDefinitions.textColumn,
  reviewsColumnsDefinitions.ratingColumn,
  reviewsColumnsDefinitions.createdAtColumn,
  reviewsColumnsDefinitions.statusColumn,
  reviewsColumnsDefinitions.isActiveColumn,
  reviewsColumnsDefinitions.editColumn,
  reviewsColumnsDefinitions.deleteColumn,
]

export const reviewsFiltersConfig: FiltersConfig = {
  [reviewsColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [reviewsColumnsDefinitions.lastNameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [reviewsColumnsDefinitions.firstNameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [reviewsColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },

};

function getOptionsForSelect(): Array<any> {
  const options: Array<any> = [];
  for (const key in reviewsStatuses) {
    if (reviewsStatuses.hasOwnProperty(key)) {
      options.push({ value: parseInt(key), label: reviewsStatuses[key] });
    }
  }
  return options;
}


function getStatusCell() {
  return (value: any, record: Review) => {
    return <StatusCell record={record} />
  };
}

function StatusCell({ record }: { record: Review }) {
  const { status, rowId } = useReviewsStore((state) => state.grid.editable);

  if (rowId === record.id && status) {
    return <SelectStep record={record}/>
  }

  return (
    <>{reviewsStatuses[record.status]}</>
  );
}

function SelectStep(
  {
    record,
    onClear,
    loading = false,
    disabled = false,
    style = { width: 100 },
  }: SelectStatusProps
) {
  const { status, rowId, form } = useReviewsStore((state) => state.grid.editable);
  const { setGridEditable } = useReviewsStore();
  const onChange = (value: any) => {
    setGridEditable({ status, rowId, form: { ...form, status: value } });
  }
  return (
    <Select
      allowClear
      style={style}
      placeholder="Select step"
      value={form.status}
      defaultValue={record.status}
      onChange={onChange}
      onClear={onClear}
      loading={loading}
      options={getOptionsForSelect()}
      disabled={disabled}
    />
  );
}

function getIsActiveCell() {
  return (value: any, record: Review) => {
    return <IsActiveCell record={record} />
  };
}

function IsActiveCell({ record }: { record: Review }) {
  const { status, rowId, form } = useReviewsStore((state) => state.grid.editable);
  const { setGridEditable } = useReviewsStore();

  const onChange = (e: CheckboxChangeEvent) => {
    setGridEditable({ status, rowId, form: { ...form, isActive: e.target.checked } });
  }
  if (rowId === record.id && status) {
    return <Checkbox defaultChecked={record.isActive} onChange={onChange}></Checkbox>;
  }

  if (record.isActive) {
    return <CheckOutlined />;
  }
  return null
}

function EditCell({ record }: { record: Review }) {
  const { status, rowId, form } = useReviewsStore((state) => state.grid.editable);
  const { setGridEditable, updateReviewsStatus } = useReviewsStore();
  const [messageApi, contextHolder] = message.useMessage();

  const onClick = () => {
    setGridEditable({ status: true, rowId: record.id, form: {} })
  };
  const onSave = () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving word...',
      duration: 0,
    });

    const formData = {
      status: form.hasOwnProperty('status') ? form.status : record.status,
      isActive: form.hasOwnProperty('isActive') ? form.isActive : record.isActive,
    }

    updateReviewsStatus(record.id, formData as any)
      .then(() => {
        dispatchRefreshReviewsGridEvent();
        setGridEditable({ status: false, rowId: null, form: {} });
        messageApi.destroy('startCreate');
        message.success('Review has been successfully updated');
      })
      .catch((e) => {
        messageApi.destroy('startCreate');
        const errorMessage = e.response?.data?.errors?.[0]?.message || 'An unknown error occurred';
        message.error(errorMessage);
      })

  };
  const onCancel = () => {
    setGridEditable({ status: false, rowId: null, form: {} })
  };

  if (status && rowId === record.id) {
    return (
      <>
        {contextHolder}
        <Tooltip title="Save" placement="bottomRight">
          <SaveOutlined
            onClick={onSave}
            style={{ fontSize: 18, color: '#000', cursor: 'pointer', padding: 16 }}
          />
        </Tooltip>
        <Popconfirm title="Sure to cancel?" onConfirm={onCancel}>
          <Tooltip title="Cancel" placement="bottomRight">
            <CloseOutlined
              onClick={onClick}
              style={{ fontSize: 18, color: '#000', cursor: 'pointer', padding: 16 }}
            />
          </Tooltip>
        </Popconfirm>
      </>
    );
  }

  if (status) {
    return (
      <EditOutlined
        style={{ fontSize: 18, color: 'gray', cursor: 'default', padding: 16 }}
      />
    );
  }

  return (
    <Tooltip title="Edit item">
      <EditOutlined
        onClick={onClick}
        style={{ fontSize: 18, color: '#000', cursor: 'pointer', padding: 16 }}
      />
    </Tooltip>
  );
}

function CustomDeleteCell({ record }: {record : Review}) {
  const { deleteReview } = useReviewsStore();

  return (
    <DeleteCell
      record={record}
      title="Delete Review"
      deleteAction={deleteReview}
      onSuccess={() => {
        dispatchRefreshReviewsGridEvent();
        message.success('Review has been successfully deleted');
      }}
      hasNotRelationsContent={() => <div>Words has no any relations.</div>}
      hasRelationsContent={() => {
        return (
          <div style={{}}>
            <div>If you really want to continue, enter your password.</div>
          </div>
        );
      }}
    />
  );
}

function getDeleteCell() {
  return (value: any, record: Review) => {

    return (
      <CustomDeleteCell record={record} />
    )
  };
}