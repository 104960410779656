import { Form, Select } from 'antd';
import CustomSelect from '@base/CustomSelect';

import { useSelect } from '@zustandStorage/customSelect/customSelect';
import { useEffect } from 'react';
import { BlogTypesByIndex, BlogTypes } from '../../../modelTypes/blog' ;


interface Props {
  indexPage: boolean;
}

const blogTypeForSelect: Record<BlogTypes, string> = {
  'articles': 'Article',
  'disease-guides': 'Disease Guide',
  'treatment-guides': 'Treatment Guide',
};
const { Option } = Select;

export default function Blogs({ indexPage }: Props): JSX.Element {
  const { clearFormData, fetchArticlesForSelect } = useSelect();
  const selectBlog = useSelect((state) => state.data?.['selectBlog']);

  useEffect(() => {
    console.log('change index page')
    clearFormData('selectCountries')
  }, [indexPage])

  return (
    <>
      <Form.Item
        name="blogType"
        label="Blog type"
        valuePropName="defaultValue"
      >
        <Select
          style={{ width: '100%' }}
          placeholder={"Select blog type"}
          onChange={(value) => {
            if (value !== undefined && !indexPage) {
              console.log(value)
              const params = { type: value };
              fetchArticlesForSelect('selectBlog', params, { clearDataBeforeFetch: true, saveParams: true });
            }
          }}
        >
          {BlogTypesByIndex.map((blogType, index) => (
            <Option key={blogType} value={index}>
              {blogTypeForSelect[blogType]}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {!indexPage &&
        <Form.Item
          name="articleId"
          label="Blog"
          valuePropName="defaultValue"
        >
          <CustomSelect
            disabled={!selectBlog?.opened}
            fieldNames={{
              label: 'title',
              value: 'id'
            }}
            placeholder="Select Blog"
            selectId="selectBlog"
            onUpdateSelect={fetchArticlesForSelect}
            defaultQueryParams={{ page: 1, perPage: 50, onlyTreatments: true }}
            style={{ width: '100%' }}
          />
        </Form.Item>
      }
    </>
  );
}