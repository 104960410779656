import {
  AntGetListParams,
  CreateCertificateRequest,
  CreateCertificateResponse,
  GetListParams,
  ListResponse,
  Params,
  UpdateCertificateRequest,
  UpdateCertificateResponse,
} from "@api/types";

import { AxiosRequestConfig } from "axios";
import { Certificate } from '../modelTypes/certificate';
import client from "./client";
import { getRestUri } from '@api/helpers';

const getUri = getRestUri('certificates');
const ROOT_URI = getUri('');
const GET_CERTIFICATES_FOR_SELECT_URI = 'certificates/select';

export async function getCertificates(params?: AntGetListParams) {
  return client.get<ListResponse<Certificate>>(ROOT_URI.GET(), params);
}

export async function getCertificatesForSelect(params?: GetListParams) {
  return client.get<ListResponse<Certificate>>(GET_CERTIFICATES_FOR_SELECT_URI, params);
}

export async function getCertificate(id: number, params?: Params) {
  return client.get<Certificate>(ROOT_URI.SHOW({ id }), params);
}

export async function createCertificate(payload: CreateCertificateRequest) {
  return client.post<CreateCertificateRequest, CreateCertificateResponse>(ROOT_URI.POST(), payload);
}

export async function updateCertificate(id: number, payload: UpdateCertificateRequest) {
  return client.patch<UpdateCertificateRequest, UpdateCertificateResponse>(ROOT_URI.PATCH({ id }), payload);
}

export async function deleteCertificate(id: number, payload?: AxiosRequestConfig) {
  return client.del(ROOT_URI.DELETE({ id }), payload);
}
