import { BaseRow, FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { Content, ContentPages } from '@modelTypes/content';
import { getDateFormattedCell } from '../cells/DateFormattedCell';
import { Doctor } from '@modelTypes/doctor';
import { IconButtonRenderer } from '@components/AntGrid/renderers/renderers';
import useLanguageTabParam from '@components/AntGrid/hooks/useLanguageTabParam';
import { useParams } from 'react-router-dom';
import getIdCell from '@components/AntGrid/cells/IdCell';

export type ContentColumnsNames = `${keyof Content}Column` | 'editColumn';

export const contentColumnsDefinitions: Partial<Record<ContentColumnsNames, ColumnType<Content>>> = {
  idColumn: createColumnDefinition<Content>('#', 'id', { width: 80, render: getIdCell }),
  keyColumn: createColumnDefinition<Content>('Key', 'key'),
  nameColumn: createColumnDefinition<Content>('Name', 'name'),
  updatedAtColumn: createColumnDefinition<Content>('Updated At', 'updatedAt', { render: getDateFormattedCell, width: 160 }),
  createdAtColumn: createColumnDefinition<Content>('Created At', 'createdAt', { render: getDateFormattedCell, width: 160 }),
  editColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getEditCell<Content>(),
    sorter: false,
    filtered: false,
  },
};

export const contentColumns: any = [
  contentColumnsDefinitions.idColumn,
  contentColumnsDefinitions.nameColumn,
  contentColumnsDefinitions.keyColumn,
  contentColumnsDefinitions.updatedAtColumn,
  contentColumnsDefinitions.createdAtColumn,
  contentColumnsDefinitions.editColumn,
];

export const contentFiltersConfig: FiltersConfig = {
  [contentColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [contentColumnsDefinitions.keyColumn?.key as string]: { filterType: FilterType.SEARCH },
  [contentColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [contentColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [contentColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function getEditCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    const { page } = useParams<{ page: string }>();
    const langParam = useLanguageTabParam();

    return IconButtonRenderer<Doctor>(`/contents/menu/${page}/${record.id}/edit${langParam}`);
  };
}