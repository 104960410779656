import { City } from '../../../modelTypes/city';
import { BaseRow, FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { getIsActiveCell } from '@components/AntGrid/cells/StatusCell';
import { getDateFormattedCell } from '../cells/DateFormattedCell';
import { IconButtonRenderer } from '../renderers/renderers';
import React from 'react';
import ShowCheckIcon from '../cells/ShowCheckIcon';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import { message } from 'antd';
import { deleteCity } from '@storage/cities/thunkActions';
import { map } from 'lodash';
import { dispatchRefreshCitiesGridEvent } from '@containers/Destinations/Cities/components/CitiesAntGrid';
import useLanguageTabParam from '@components/AntGrid/hooks/useLanguageTabParam';
import { Doctor } from '@modelTypes/doctor';
import getIdCell from '@components/AntGrid/cells/IdCell';

export type CityColumnsNames = `${keyof City}Column` | 'hasDescriptionColumn' | 'previewColumn' | 'eyeIconColumn' | 'deleteColumn';

export const cityColumnsDefinitions: Partial<Record<CityColumnsNames, ColumnType<City>>> = {
  idColumn: createColumnDefinition<City>('#', 'id', { width: 80, render: getIdCell }),
  nameColumn: createColumnDefinition<City>('Name', 'name'),
  urlSlugColumn: createColumnDefinition<City>('Url Slug', 'urlSlug'),
  hospitalsCountColumn: createColumnDefinition<City>('Active hospitals', 'hospitalsCount'),
  isActiveColumn: createColumnDefinition<City>('Active', 'isActive', { render: getIsActiveCell, width: 80, filtered: false }),
  updatedAtColumn: createColumnDefinition<City>('Updated At', 'updatedAt', { render: getDateFormattedCell, width: 160 }),
  createdAtColumn: createColumnDefinition<City>('Created At', 'createdAt', { render: getDateFormattedCell, width: 160 }),
  hasDescriptionColumn: {
    title: 'Has Description',
    width: 110,
    render: getHasDescriptionCell<City>(),
    sorter: false,
    filtered: false,
  },
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getEditCell<City>(),
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell<City>(),
    sorter: false,
    filtered: false,
  }
};

export const cityColumns: any = [
  cityColumnsDefinitions.idColumn,
  cityColumnsDefinitions.nameColumn,
  cityColumnsDefinitions.urlSlugColumn,
  cityColumnsDefinitions.hospitalsCountColumn,
  cityColumnsDefinitions.updatedAtColumn,
  cityColumnsDefinitions.createdAtColumn,
  cityColumnsDefinitions.hasDescriptionColumn,
  cityColumnsDefinitions.isActiveColumn,
  cityColumnsDefinitions.eyeIconColumn,
  cityColumnsDefinitions.deleteColumn,
];

export const cityFiltersConfig: FiltersConfig = {
  [cityColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [cityColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [cityColumnsDefinitions.urlSlugColumn?.key as string]: { filterType: FilterType.SEARCH },
  [cityColumnsDefinitions.hospitalsCountColumn?.key as string]: { filterType: FilterType.SEARCH },
  [cityColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [cityColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function getEditCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    const langParam = useLanguageTabParam();

    return IconButtonRenderer<Doctor>(`/cities/${record.id}/edit${langParam}`);
  };
}

function getHasDescriptionCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <ShowCheckIcon value={record.description.length} />
    );
  };
}

function getDeleteCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <DeleteCell
        record={record}
        title="Delete City"
        deleteThunkAction={deleteCity}
        onSuccess={() => {
          dispatchRefreshCitiesGridEvent();
          message.success('The city has been successfully deleted');
        }}
        hasNotRelationsContent={() => <div>City has no any relations.</div>}
        hasRelationsContent={(data: any) => {
          return (
            <div style={{}}>
              {!!data.relations.length && (
                <div>City is used by next hospital ids ({map(data.relations, (i) => i.id).join(', ')})</div>
              )}
              <div>If you really want to continue, enter your password.</div>
            </div>
          );
        }}
      />
    );
  };
}

// function BlogPreviewCell<T extends BaseRow>({ record }: { record: T }) {
//   const dispatch = useDispatch();
//   const [loading, setLoading] = useState(false);
//   const { type } = useParams<{ type: BlogTypes }>();
//
//   const handleGetAccessToken = () => {
//     setLoading(true);
//
//     dispatch(getAccessKey(record.id) as unknown as Promise<BlogAccessKey>)
//       .then((data) => {
//         const url = `${process.env.REACT_APP_CLIENT_URL}/blogs/${type}/${record.urlSlug}?token=${data.accessKey}`;
//
//         setLoading(false);
//         if (window !== null) {
//           // @ts-ignore
//           window.open(url, '_blank', 'noopener,noreferrer').focus();
//         }
//       });
//   };
//
//   return (
//     <FrontendPreviewCell
//       loading={loading}
//       onGetAccessToken={handleGetAccessToken}
//     />
//   );
// }
//
// function getPreviewCell<T extends BaseRow>() {
//   return (value: any, record: T) => {
//     return (
//       <BlogPreviewCell
//         record={record}
//       />
//     );
//   };
// }
//
