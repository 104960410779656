import { Blog, BlogAccessKey, BlogTypes, BlogTypesByIndex } from '../../../modelTypes/blog';
import { BaseRow, FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { IconButtonRenderer } from '@components/AntGrid/renderers/renderers';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { getDateFormattedCell } from '@components/AntGrid/cells/DateFormattedCell';
import { getIsActiveCell } from '@components/AntGrid/cells/StatusCell';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { deleteBlog, getAccessKey } from '@storage/blogs/thunkActions';
import { useDispatch } from 'react-redux';
import { FrontendPreviewCell } from '@components/AntGrid/cells/FrontendPreviewCell';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import { message } from 'antd';
import upperFirst from 'lodash/upperFirst';
import { dispatchRefreshBlogsGridEvent } from '@containers/Blog/components/BlogAntGrid';
import getIdCell from '@components/AntGrid/cells/IdCell';

export type BlogColumnsNames = `${keyof Blog}Column` | 'previewColumn' | 'eyeIconColumn' | 'deleteColumn';

export const blogColumnsDefinitions: Partial<Record<BlogColumnsNames, ColumnType<Blog>>> = {
  idColumn: createColumnDefinition<Blog>('#', 'id', { width: 80, render: getIdCell }),
  titleColumn: createColumnDefinition<Blog>('Title', 'title'),
  urlSlugColumn: createColumnDefinition<Blog>('Url Slug', 'urlSlug'),
  numberOfViewsColumn: createColumnDefinition<Blog>('Number of Views', 'numberOfViews'),
  numberOfRequestsColumn: createColumnDefinition<Blog>('Number of Requests', 'numberOfRequests'),
  publishedAtColumn: createColumnDefinition<Blog>('Publish At', 'publishedAt', { render: getDateFormattedCell, width: 140 }),
  updatedAtColumn: createColumnDefinition<Blog>('Updated At', 'updatedAt', { render: getDateFormattedCell }),
  createdAtColumn: createColumnDefinition<Blog>('Created At', 'createdAt', { render: getDateFormattedCell }),
  isActiveColumn: createColumnDefinition<Blog>('Active', 'isActive', { render: getIsActiveCell, width: 80, filtered: false }),
  previewColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getPreviewCell<Blog>(),
    sorter: false,
    filtered: false,
  },
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, row: Blog) => {
      return IconButtonRenderer<Blog>(`/blog/${BlogTypesByIndex[row.type!]}/${row.id}/edit`);
    },
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell<Blog>(),
    sorter: false,
    filtered: false,
  }
};

export const blogColumns: any = [
  blogColumnsDefinitions.idColumn,
  blogColumnsDefinitions.titleColumn,
  blogColumnsDefinitions.urlSlugColumn,
  blogColumnsDefinitions.numberOfViewsColumn,
  blogColumnsDefinitions.numberOfRequestsColumn,
  blogColumnsDefinitions.publishedAtColumn,
  blogColumnsDefinitions.updatedAtColumn,
  blogColumnsDefinitions.createdAtColumn,
  blogColumnsDefinitions.isActiveColumn,
  blogColumnsDefinitions.previewColumn,
  blogColumnsDefinitions.eyeIconColumn,
  blogColumnsDefinitions.deleteColumn,
];

export const blogFiltersConfig: FiltersConfig = {
  [blogColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [blogColumnsDefinitions.titleColumn?.key as string]: { filterType: FilterType.SEARCH },
  [blogColumnsDefinitions.urlSlugColumn?.key as string]: { filterType: FilterType.SEARCH },
  [blogColumnsDefinitions.numberOfViewsColumn?.key as string]: { filterType: FilterType.SEARCH },
  [blogColumnsDefinitions.numberOfRequestsColumn?.key as string]: { filterType: FilterType.SEARCH },
  [blogColumnsDefinitions.publishedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [blogColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [blogColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

const titles: Record<BlogTypes, string> = {
  articles: 'Article',
  'disease-guides': 'Disease Guide',
  'treatment-guides': 'Treatment Guide',
};

function BlogPreviewCell<T extends BaseRow>({ record }: { record: T }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { type } = useParams<{ type: BlogTypes }>();

  const handleGetAccessToken = () => {
    setLoading(true);

    dispatch(getAccessKey(record.id) as unknown as Promise<BlogAccessKey>)
      .then((data) => {
        const url = `${process.env.REACT_APP_CLIENT_URL}/blogs/${type}/${record.urlSlug}?token=${data.accessKey}`;

        setLoading(false);
        if (window !== null) {
          // @ts-ignore
          window.open(url, '_blank', 'noopener,noreferrer').focus();
        }
      });
  };

  return (
    <FrontendPreviewCell
      loading={loading}
      onGetAccessToken={handleGetAccessToken}
    />
  );
}

function getPreviewCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <BlogPreviewCell
        record={record}
      />
    );
  };
}

function getDeleteCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    const { type } = useParams<{ type: BlogTypes }>();

    return (
      <DeleteCell
        record={record}
        title={`Delete ${upperFirst(titles[type])}`}
        deleteThunkAction={deleteBlog}
        onSuccess={() => {
          dispatchRefreshBlogsGridEvent();
          message.success(`The ${upperFirst(titles[type])} has been successfully deleted`);
        }}
        hasNotRelationsContent={() => <div>{upperFirst(titles[type])} has no any relations.</div>}
        hasRelationsContent={() => {
          return (
            <div style={{}}>
              <div>If you really want to continue, enter your password.</div>
            </div>
          );
        }}
      />
    );
  };
}