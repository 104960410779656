import { Tag } from '../../../modelTypes/tag';
import { FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { getDateFormattedCell } from '../cells/DateFormattedCell';
import { message, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons/lib/icons';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import { map } from 'lodash';
import React from 'react';
import { dispatchRefreshTagsGridEvent } from '@containers/Tags/components/TagsAntGrid';
import getIdCell from '@components/AntGrid/cells/IdCell';
import TagModalAnt from '@containers/Tags/components/TagMoadalAnt';
import { useTagsStore } from '@zustandStorage/tags/tags';

export type TagColumnsNames = `${keyof Tag}Column` | 'hasDescriptionColumn' | 'previewColumn' | 'eyeIconColumn' | 'deleteColumn';

export const tagColumnsDefinitions: Partial<Record<TagColumnsNames, ColumnType<Tag>>> = {
  idColumn: createColumnDefinition<Tag>('#', 'id', { width: 80, render: getIdCell }),
  nameColumn: createColumnDefinition<Tag>('Name', 'name'),
  // isActiveColumn: createColumnDefinition<Tag>('Active', 'isActive', { render: getIsActiveCell, width: 80, filtered: false }),
  updatedAtColumn: createColumnDefinition<Tag>('Updated At', 'updatedAt', { render: getDateFormattedCell, width: 160 }),
  createdAtColumn: createColumnDefinition<Tag>('Created At', 'createdAt', { render: getDateFormattedCell, width: 160 }),
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, record: Tag) => {
      return (
        <EditCell record={record} />
      );
    },
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell(),
    sorter: false,
    filtered: false,
  }
};

export const tagColumns: any = [
  tagColumnsDefinitions.idColumn,
  tagColumnsDefinitions.nameColumn,
  tagColumnsDefinitions.updatedAtColumn,
  tagColumnsDefinitions.createdAtColumn,
  // tagColumnsDefinitions.isActiveColumn,
  tagColumnsDefinitions.eyeIconColumn,
  tagColumnsDefinitions.deleteColumn,
];

export const tagFiltersConfig: FiltersConfig = {
  [tagColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [tagColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [tagColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [tagColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function EditCell({ record }: { record: Tag }) {
  // const dispatch = useDispatch();
  //
  // const handleOnClick = (e: any) => {
  //   console.log(e, record);
  //   const formData = pick(record, ['name']);
  //
  //   dispatch(setModalWithFormData({ type: ModalWithFormTypes.EDIT, formData, editRecord: record }));
  //   dispatch(openModalWithForm());
  // };

  return (
    <>
      <TagModalAnt
        data={record}
        buttonProps={{
          icon: <>
            <Tooltip title="Edit item">
              <EditOutlined style={{ fontSize: 16, color: '#000' }} />
            </Tooltip>
          </>,
          type: 'text',
        }}
      />
    </>
    // <Tooltip title="Edit item">
    //   <EditOutlined onClick={handleOnClick} style={{ fontSize: 18, color: '#000', cursor: 'pointer' }} />
    // </Tooltip>
  );
}
//
// function getDeleteCell<T extends BaseRow>() {
//   return (value: any, record: T) => {
//     return (
//       <DeleteCell
//         record={record}
//         title="Delete Hospital Tag"
//         deleteThunkAction={deleteTag}
//         onSuccess={() => {
//           dispatchRefreshTagsGridEvent();
//           message.success('The Hospital Tag has been successfully deleted');
//         }}
//         hasNotRelationsContent={() => <div>The Hospital Tag has no any relations.</div>}
//         hasRelationsContent={(data: any) => {
//           return (
//             <div style={{}}>
//               <div>This tag is used by next hospital`s id ({map(data.relations.slice(0, 30), (i) => i.hospitalId).join(', ')})</div>
//               <div>If you really want to continue, enter your password.</div>
//             </div>
//           );
//         }}
//       />
//     );
//   };
// }

function CustomDeleteCell({ record }: { record: Tag }) {
  const { deleteTag } = useTagsStore()
  return (
    <DeleteCell
      record={record}
      title="Delete Hospital Tag"
      deleteAction={deleteTag}
      onSuccess={() => {
        dispatchRefreshTagsGridEvent();
        message.success('The Hospital Tag has been successfully deleted');
      }}
      hasNotRelationsContent={() => <div>The Hospital Tag has no any relations.</div>}
      hasRelationsContent={(data: any) => {
        return (
          <div style={{}}>
            <div>This tag is used by next hospital`s id ({map(data.relations.slice(0, 30), (i) => i.hospitalId).join(', ')})</div>
            <div>If you really want to continue, enter your password.</div>
          </div>
        );
      }}
    />
  );
}

function getDeleteCell() {
  return (value: any, record: Tag) => {
    return (
      <CustomDeleteCell record={record} />
    );
  };
}
