import Grid from '@components/AntGrid/Grid';
import { PaginationConfig } from '@components/AntGrid/types';
import { useEffect } from 'react';
import { Language } from '@modelTypes/language';
import { useLanguagesStore } from '@zustandStorage/languages/languages';
import { languagesColumns, languagesFiltersConfig } from '@components/AntGrid/columns/languages';

export default function LanguagesGrid() {
  const { meta, data, loading } = useLanguagesStore((state) => state.grid);
  const { fetchLanguages, clearGridData } = useLanguagesStore();
  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
  };

  useEffect(() => {
    return () => {
      clearGridData();
    };
  }, []);

  return (
    <Grid<Language>
      id="languageGrid"
      onFetch={fetchLanguages}
      columns={languagesColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={languagesFiltersConfig}
    />
  );
}