import { ListResponse } from '@api/types';
import { Specialization } from '@modelTypes/specialization';
import { createAction } from '../helpers';

export enum ActionType {
  START_FETCH_SPECIALIZATIONS = 'SPECIALIZATIONS__START_FETCH_SPECIALIZATIONS',
  END_FETCH_SPECIALIZATIONS = 'SPECIALIZATIONS__END_FETCH_SPECIALIZATIONS',

  START_FETCH_SPECIALIZATIONS_FOR_SELECT = 'SPECIALIZATIONS__START_FETCH_SPECIALIZATIONS_FOR_SELECT',
  END_FETCH_SPECIALIZATIONS_FOR_SELECT = 'SPECIALIZATIONS__END_FETCH_SPECIALIZATIONS_FOR_SELECT',

  START_FETCH_SPECIALIZATION = 'SPECIALIZATIONS__START_FETCH_SPECIALIZATION',
  END_FETCH_SPECIALIZATION = 'SPECIALIZATIONS__END_FETCH_SPECIALIZATION',

  START_POST_SPECIALIZATION = 'SPECIALIZATIONS__START_POST_SPECIALIZATION',
  END_POST_SPECIALIZATION = 'SPECIALIZATIONS__END_POST_SPECIALIZATION',
  END_POST_SPECIALIZATION_ERROR = 'SPECIALIZATIONS__END_POST_SPECIALIZATION_ERROR',

  START_PATCH_SPECIALIZATION = 'SPECIALIZATIONS__START_PATCH_SPECIALIZATION',
  END_PATCH_SPECIALIZATION = 'SPECIALIZATIONS__END_PATCH_SPECIALIZATION',
  END_PATCH_SPECIALIZATION_ERROR = 'SPECIALIZATIONS__END_PATCH_SPECIALIZATION_ERROR',

  START_DELETE_SPECIALIZATION = 'SPECIALIZATIONS__START_DELETE_SPECIALIZATION',
  END_DELETE_SPECIALIZATION = 'SPECIALIZATIONS__END_DELETE_SPECIALIZATION',

  CLEAR_FORM_DATA = 'SPECIALIZATIONS__CLEAR_FORM_DATA',
}

export const startFetchSpecializations = createAction<ActionType.START_FETCH_SPECIALIZATIONS>(ActionType.START_FETCH_SPECIALIZATIONS);
export const endFetchSpecializations = createAction<ActionType.END_FETCH_SPECIALIZATIONS, ListResponse<Specialization>>(ActionType.END_FETCH_SPECIALIZATIONS);

export const startFetchSpecializationsForSelect = createAction<ActionType.START_FETCH_SPECIALIZATIONS_FOR_SELECT>(ActionType.START_FETCH_SPECIALIZATIONS_FOR_SELECT);
export const endFetchSpecializationsForSelect = createAction<ActionType.END_FETCH_SPECIALIZATIONS_FOR_SELECT, ListResponse<Specialization>>(ActionType.END_FETCH_SPECIALIZATIONS_FOR_SELECT);

export const startFetchSpecialization = createAction<ActionType.START_FETCH_SPECIALIZATION>(ActionType.START_FETCH_SPECIALIZATION);
export const endFetchSpecialization = createAction<ActionType.END_FETCH_SPECIALIZATION, Specialization>(ActionType.END_FETCH_SPECIALIZATION);

export const startPostSpecialization = createAction<ActionType.START_POST_SPECIALIZATION>(ActionType.START_POST_SPECIALIZATION);
export const endPostSpecialization = createAction<ActionType.END_POST_SPECIALIZATION, Specialization>(ActionType.END_POST_SPECIALIZATION);
export const endPostSpecializationError = createAction<ActionType.END_POST_SPECIALIZATION_ERROR>(ActionType.END_POST_SPECIALIZATION_ERROR);

export const startUpdateSpecialization = createAction<ActionType.START_PATCH_SPECIALIZATION>(ActionType.START_PATCH_SPECIALIZATION);
export const endUpdateSpecialization = createAction<ActionType.END_PATCH_SPECIALIZATION, Specialization>(ActionType.END_PATCH_SPECIALIZATION);
export const endUpdateSpecializationError = createAction<ActionType.END_PATCH_SPECIALIZATION_ERROR>(ActionType.END_PATCH_SPECIALIZATION_ERROR);

export const startDeleteSpecialization = createAction<ActionType.START_DELETE_SPECIALIZATION>(ActionType.START_DELETE_SPECIALIZATION);
export const endDeleteSpecialization = createAction<ActionType.END_DELETE_SPECIALIZATION>(ActionType.END_DELETE_SPECIALIZATION);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

export type Actions =
  | ReturnType<typeof startFetchSpecializations>
  | ReturnType<typeof endFetchSpecializations>
  | ReturnType<typeof startFetchSpecializationsForSelect>
  | ReturnType<typeof endFetchSpecializationsForSelect>
  | ReturnType<typeof startFetchSpecialization>
  | ReturnType<typeof endFetchSpecialization>
  | ReturnType<typeof startPostSpecialization>
  | ReturnType<typeof endPostSpecialization>
  | ReturnType<typeof endPostSpecializationError>
  | ReturnType<typeof startUpdateSpecialization>
  | ReturnType<typeof endUpdateSpecialization>
  | ReturnType<typeof endUpdateSpecializationError>
  | ReturnType<typeof startDeleteSpecialization>
  | ReturnType<typeof endDeleteSpecialization>
  | ReturnType<typeof clearFormData>;