import { Blog, BlogAccessKey } from '@modelTypes/blog';

import { BlogsState } from '@storage/blogs/reducer';
import { ListResponse } from '@api/types';
import { createAction } from '../helpers';

export enum ActionType {
  START_FETCH_BLOGS = 'BLOGS__START_FETCH_BLOGS',
  END_FETCH_BLOGS = 'BLOGS__END_FETCH_BLOGS',

  START_FETCH_BLOGS_FOR_SELECT = 'BLOGS__START_FETCH_BLOGS_FOR_SELECT',
  END_FETCH_BLOGS_FOR_SELECT = 'BLOGS__END_FETCH_BLOGS_FOR_SELECT',
  CLEAR_BLOGS_FOR_SELECT = 'BLOGS__CLEAR_BLOGS_FOR_SELECT',

  START_FETCH_BLOG = 'BLOGS__START_FETCH_BLOG',
  END_FETCH_BLOG = 'BLOGS__END_FETCH_BLOG',

  START_FETCH_ACCESS_KEY = 'BLOGS__START_FETCH_ACCESS_KEY',
  END_FETCH_ACCESS_KEY = 'BLOGS__END_FETCH_ACCESS_KEY',

  START_POST_BLOG = 'BLOGS__START_POST_BLOG',
  END_POST_BLOG = 'BLOGS__END_POST_BLOG',
  END_POST_BLOG_ERROR = 'BLOGS__END_POST_BLOG_ERROR',

  START_PATCH_BLOG = 'BLOGS__START_PATCH_BLOG',
  END_PATCH_BLOG = 'BLOGS__END_PATCH_BLOG',
  END_PATCH_BLOG_ERROR = 'BLOGS__END_PATCH_BLOG_ERROR',

  START_DELETE_BLOG = 'BLOGS__START_DELETE_BLOG',
  END_DELETE_BLOG = 'BLOGS__END_DELETE_BLOG',

  CLEAR_FORM_DATA = 'BLOGS__CLEAR_FORM_DATA',
}

type EndFetchBlogs = Omit<BlogsState['grid'], 'loading'>;

export const startFetchBlogs = createAction<ActionType.START_FETCH_BLOGS>(ActionType.START_FETCH_BLOGS);
export const endFetchBlogs = createAction<ActionType.END_FETCH_BLOGS, EndFetchBlogs>(ActionType.END_FETCH_BLOGS);

export const startFetchBlogsForSelect = createAction<ActionType.START_FETCH_BLOGS_FOR_SELECT>(ActionType.START_FETCH_BLOGS_FOR_SELECT);
export const endFetchBlogsForSelect = createAction<ActionType.END_FETCH_BLOGS_FOR_SELECT, ListResponse<Blog>>(ActionType.END_FETCH_BLOGS_FOR_SELECT);
export const clearBlogsForSelect = createAction<ActionType.CLEAR_BLOGS_FOR_SELECT>(ActionType.CLEAR_BLOGS_FOR_SELECT);

export const startFetchBlog = createAction<ActionType.START_FETCH_BLOG>(ActionType.START_FETCH_BLOG);
export const endFetchBlog = createAction<ActionType.END_FETCH_BLOG, Blog>(ActionType.END_FETCH_BLOG);

export const startFetchAccessKey = createAction<ActionType.START_FETCH_ACCESS_KEY>(ActionType.START_FETCH_ACCESS_KEY);
export const endFetchAccessKey = createAction<ActionType.END_FETCH_ACCESS_KEY, BlogAccessKey>(ActionType.END_FETCH_ACCESS_KEY);

export const startPostBlog = createAction<ActionType.START_POST_BLOG>(ActionType.START_POST_BLOG);
export const endPostBlog = createAction<ActionType.END_POST_BLOG, Blog>(ActionType.END_POST_BLOG);
export const endPostBlogError = createAction<ActionType.END_POST_BLOG_ERROR>(ActionType.END_POST_BLOG_ERROR);

export const startUpdateBlog = createAction<ActionType.START_PATCH_BLOG>(ActionType.START_PATCH_BLOG);
export const endUpdateBlog = createAction<ActionType.END_PATCH_BLOG, Blog>(ActionType.END_PATCH_BLOG);
export const endUpdateBlogError = createAction<ActionType.END_PATCH_BLOG_ERROR>(ActionType.END_PATCH_BLOG_ERROR);

export const startDeleteBlog = createAction<ActionType.START_DELETE_BLOG>(ActionType.START_DELETE_BLOG);
export const endDeleteBlog = createAction<ActionType.END_DELETE_BLOG>(ActionType.END_DELETE_BLOG);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

export type Actions =
  | ReturnType<typeof startFetchBlogs>
  | ReturnType<typeof endFetchBlogs>
  | ReturnType<typeof startFetchBlogsForSelect>
  | ReturnType<typeof endFetchBlogsForSelect>
  | ReturnType<typeof clearBlogsForSelect>
  | ReturnType<typeof startFetchBlog>
  | ReturnType<typeof endFetchBlog>
  | ReturnType<typeof startFetchAccessKey>
  | ReturnType<typeof endFetchAccessKey>
  | ReturnType<typeof startPostBlog>
  | ReturnType<typeof endPostBlog>
  | ReturnType<typeof endPostBlogError>
  | ReturnType<typeof startUpdateBlog>
  | ReturnType<typeof endUpdateBlog>
  | ReturnType<typeof endUpdateBlogError>
  | ReturnType<typeof startDeleteBlog>
  | ReturnType<typeof endDeleteBlog>
  | ReturnType<typeof clearFormData>;