import { Template, TemplateTypes, TemplateTypesByIndex } from '../../../modelTypes/template';
import { BaseRow, FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { getIsActiveCell } from '@components/AntGrid/cells/StatusCell';
import { getDateFormattedCell } from '../cells/DateFormattedCell';
import { IconButtonRenderer } from '../renderers/renderers';
import { BlogAccessKey } from '@modelTypes/blog';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FrontendPreviewCell } from '@components/AntGrid/cells/FrontendPreviewCell';
import { getAccessKey } from '@storage/blogs/thunkActions';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import { message } from 'antd';
import upperFirst from 'lodash/upperFirst';
import { dispatchRefreshTemplatesGridEvent } from '@containers/Templates/components/TemplatesAntGrid';
import { deleteTemplate } from '@storage/templates/thunkActions';
import getIdCell from '@components/AntGrid/cells/IdCell';

export type TemplateColumnsNames = `${keyof Template}Column` | 'hasDescriptionColumn' | 'previewColumn' | 'eyeIconColumn' | 'deleteColumn';

export const templateColumnsDefinitions: Partial<Record<TemplateColumnsNames, ColumnType<Template>>> = {
  idColumn: createColumnDefinition<Template>('#', 'id', { width: 80, render: getIdCell }),
  titleColumn: createColumnDefinition<Template>('Title', 'title'),
  codeColumn: createColumnDefinition<Template>('Url Slug', 'code'),
  isActiveColumn: createColumnDefinition<Template>('Active', 'isActive', { render: getIsActiveCell, width: 80, filtered: false }),
  updatedAtColumn: createColumnDefinition<Template>('Updated At', 'updatedAt', { render: getDateFormattedCell, width: 160 }),
  createdAtColumn: createColumnDefinition<Template>('Created At', 'createdAt', { render: getDateFormattedCell, width: 160 }),
  previewColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getPreviewCell<Template>(),
    sorter: false,
    filtered: false,
  },
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, row: Template) => {
      return IconButtonRenderer<Template>(`/templates/${TemplateTypesByIndex[row.type!]}/${row.id}/edit`);
    },
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell<Template>(),
    sorter: false,
    filtered: false,
  }
};

export const templateColumns: any = [
  templateColumnsDefinitions.idColumn,
  templateColumnsDefinitions.titleColumn,
  templateColumnsDefinitions.codeColumn,
  templateColumnsDefinitions.updatedAtColumn,
  templateColumnsDefinitions.createdAtColumn,
  templateColumnsDefinitions.isActiveColumn,
  templateColumnsDefinitions.previewColumn,
  templateColumnsDefinitions.eyeIconColumn,
  templateColumnsDefinitions.deleteColumn,
];

export const templateFiltersConfig: FiltersConfig = {
  [templateColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [templateColumnsDefinitions.titleColumn?.key as string]: { filterType: FilterType.SEARCH },
  [templateColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [templateColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

const titles: Record<TemplateTypes, string> = {
  emails: 'Emails',
  legals: 'Legals',
};

function PreviewCell<T extends BaseRow>({ record }: { record: T }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleGetAccessToken = () => {
    setLoading(true);

    dispatch(getAccessKey(record.id) as unknown as Promise<BlogAccessKey>)
      .then((data) => {
        const url = `${process.env.REACT_APP_CLIENT_URL}/preview/template/${record.code}?token=${data.accessKey}`;

        setLoading(false);
        if (window !== null) {
          // @ts-ignore
          window.open(url, '_blank', 'noopener,noreferrer').focus();
        }
      });
  };

  return (
    <FrontendPreviewCell
      loading={loading}
      onGetAccessToken={handleGetAccessToken}
    />
  );
}

function getPreviewCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <PreviewCell
        record={record}
      />
    );
  };
}

function getDeleteCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    const { type } = useParams<{ type: TemplateTypes }>();

    return (
      <DeleteCell
        record={record}
        title={`Delete ${upperFirst(titles[type])}`}
        deleteThunkAction={deleteTemplate}
        onSuccess={() => {
          dispatchRefreshTemplatesGridEvent();
          message.success(`The ${upperFirst(titles[type])} has been successfully deleted`);
        }}
        hasNotRelationsContent={() => <div>{upperFirst(titles[type])} has no any relations.</div>}
        hasRelationsContent={(data: any) => {
          return (
            <div style={{}}>
              <div>If you really want to continue, enter your password.</div>
            </div>
          );
        }}
      />
    );
  };
}
