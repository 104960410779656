import * as React from 'react';
import { useEffect } from 'react';

import { CreateHospitalRequest } from '@api/types';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { useGridStore } from '@zustandStorage/grid/grid';
import QueryString from 'query-string';
import { PageHeader } from '@ant-design/pro-layout';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import { Button, Card, Checkbox, Col, Form, Input, message, Row, Spin, Tooltip } from 'antd';
import CustomSelect from '@base/CustomSelect';
import { SaveOutlined } from '@ant-design/icons';
import { useSelect } from '@zustandStorage/customSelect/customSelect';
import { Language } from '@modelTypes/language';
import { HospitalDepartment } from '@modelTypes/hospital';
import isEmpty from 'lodash/isEmpty';
import urlSlug from 'url-slug';
import { useHospitalDepartmentStore } from '@zustandStorage/department/department';
import { getSelectValue } from '@helpers/antForm';

// interface FormData extends Omit<CreateDepartmentRequest, 'mainDoctor' | 'doctors' | 'hospitalId' | 'updatedAt' | 'createdAt'> {
//   readonly languageId: number;
//   readonly mainDoctorId: number;
//   readonly doctorsIds: Array<number>;
// }

export default function DepartmentsCreate() {
  const history = useHistory();
  const [messageApi, contextHolder] = message.useMessage();
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);
  const [languageId, setLanguageId] = React.useState<number>(0);
  const [form] = Form.useForm<CreateHospitalRequest>();
  const { id, hospitalId } = useParams<{ hospitalId: string, id: string }>();
  const { fetchDoctorsForSelect, setData } = useSelect()
  const {
    fetchDepartment,
    createDepartment,
    updateDepartment,
    clearFormData,
    form: { row, loading }
  } = useHospitalDepartmentStore()

  const onSelectLanguage = async (language: Language) => {
    setLanguageId(language.id);
    if (id && hospitalId) {
      await fetchDepartment({ hospitalId: parseInt(hospitalId, 10), id: parseInt(id, 10) }, { languageId: language.id })
        .then((response: HospitalDepartment) => {
          resetForm();
          setSelectsInitialDate(response)
        });
    }
  };

  const setSelectsInitialDate = (response: HospitalDepartment) => {
    setData('selectDoctor', response && response.mainDoctor ? [response.mainDoctor] : []);
    setData('selectDoctors', response && response.doctors ? response.doctors : []);
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const handleOnBlur = () => {
    if (isEmpty(form.getFieldValue('urlSlug')) && !isEmpty(form.getFieldValue('name'))) {
      const value = urlSlug(form.getFieldValue('name'));
      form.setFieldValue('urlSlug', value);
    }
  };

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving hospital...',
      duration: 0,
    });

    setFormSubmitting(true);

    const data = form.getFieldsValue(true);
    const formData = {
      ...data,
      languageId,
      mainDoctorId: data.mainDoctorId ? getSelectValue(data.mainDoctorId) : 0,
      doctorsIds: data.doctorsIds.length ? getSelectValue(data.doctorsIds).split(',').map(Number) : [],
    };
    // console.log(data.mainDoctorId, data, data.mainDoctorId )
    if (id) {
      await updateDepartment(
        {
          hospitalId: parseInt(hospitalId, 10),
          id: parseInt(id, 10)
        },
        formData)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The department has been successfully updated');
          setFormSubmitting(false);
          resetForm();
          setSelectsInitialDate(data)
        })
        .catch(() => {
          messageApi.destroy('startCreate');
          setFormSubmitting(false);
          message.error('An error occurred, please try again now')
        })
    } else {
      await createDepartment(parseInt(hospitalId, 10), formData)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The department has been successfully created');
          history.push(`/hospitals/${hospitalId}/departments/${data.id}/edit`);
          setFormSubmitting(false);
          resetForm();
          setSelectsInitialDate(data)
        })
        .catch(() => {
          messageApi.destroy('startCreate');
          setFormSubmitting(false);
          message.error('An error occurred, please try again now')
        })
    }
  };

  useEffect(() => {
    return () => {
      clearFormData();
    };
  }, []);


  return (
    <>
      {contextHolder}
      <Prompt
        when={!formSubmitting && formChanged}
        message={(location) =>
          `Data not saved!\nAre you sure you want to go to ${location.pathname}?`
        }
      />
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.departmentsAntGrid || {};
          history.push(`/hospitals/${hospitalId}/departments?${QueryString.stringify(params)}`)
        }}
        title={`${row ? 'Edit' : 'Create'} Department`}
        extra={[
          <SelectLanguagePerForm
            key="selectLanguage"
            onInit={onSelectLanguage}
            onChange={onSelectLanguage}
            disabled={!row}
          />,
          <Button
            key="save"
            type="primary"
            loading={formSubmitting}
            onClick={() => form.submit()}
          >
            {id ? 'Update' : 'Create'}
          </Button>
        ]}
      />
      <Spin spinning={loading || languageId === 0}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          autoComplete="off"
          id={'test'}
        >
          <Row gutter={[32, 24]}>
            <Col span={16}>
              <Form.Item
                name="name"
                label="Name"
                messageVariables={{ name: 'Title' }}
                rules={[{ required: true, type: 'string', max: 255 }]}
                initialValue={row?.name}
              >
                <Input onBlur={handleOnBlur} placeholder="Enter name" />
              </Form.Item>
              <Form.Item
                name="shortName"
                label="Short name"
                messageVariables={{ name: 'Short name' }}
                initialValue={row?.shortName}
              >
                <Input placeholder="Enter short name" />
              </Form.Item>
              {/*<Form.Item*/}
              {/*  name="urlSlug"*/}
              {/*  label="Url Slug"*/}
              {/*  messageVariables={{ name: 'Url Slug' }}*/}
              {/*  rules={[{ required: true, type: 'string', max: 128 }]}*/}
              {/*  initialValue={row?.urlSlug}*/}
              {/*>*/}
              {/*  <Input disabled={languageId > 1} placeholder="Enter url slug" />*/}
              {/*</Form.Item>*/}
            </Col>
            <Col span={8}>
              <Card
                title="Doctors"
                bordered={false} style={{ marginTop: 24 }}
              >
                <Form.Item
                  name="mainDoctorId"
                  label="Select main doctor"
                  valuePropName="defaultValue"
                  initialValue={row && row.mainDoctor ? row.mainDoctor.id : null}
                >
                  <CustomSelect
                    placeholder="Select Doctor"
                    selectId="selectDoctor"
                    onUpdateSelect={fetchDoctorsForSelect}
                    defaultQueryParams={{ page: 1, perPage: 50 }}
                    onChange={() => null}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  name="doctorsIds"
                  label="Select doctors"
                  valuePropName="defaultValue"
                  initialValue={row && row.doctors ? row.doctors.map((doctor => doctor.id)) : []}
                >
                  <CustomSelect
                    mode={'multiple'}
                    placeholder="Select Doctors"
                    selectId="selectDoctors"
                    onUpdateSelect={fetchDoctorsForSelect}
                    defaultQueryParams={{ page: 1, perPage: 50 }}
                    onChange={() => null}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Card>
              <Card title="Statuses" bordered={false} style={{ marginTop: 24 }}>
                <Form.Item
                  name="isActive"
                  valuePropName="checked"
                  initialValue={row ? row?.isActive : false}
                  style={{ margin: 0 }}
                >
                  <Checkbox disabled={languageId > 1}>Active</Checkbox>
                </Form.Item>
                <Form.Item
                  name="isLanguageActive"
                  valuePropName="checked"
                  initialValue={languageId === 1 ? true : row?.isLanguageActive}
                  style={{ margin: 0 }}
                >
                  <Checkbox disabled={languageId === 1}>Active Language</Checkbox>
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Tooltip title={id ? 'Update' : 'Create'} placement="topRight">
        <Button
          style={{ position: 'fixed', bottom: 20, right: 16 }}
          onClick={() => form.submit()}
          // type="ghost"
          shape="circle"
          icon={<SaveOutlined />}
          size="large"
        />
      </Tooltip>
    </>
  );
}
