import { Form } from 'antd';
import CustomSelect from '@base/CustomSelect';
import { useSelect } from '@zustandStorage/customSelect/customSelect';
import { useEffect } from 'react';

interface Props {
  indexPage: boolean;
}

export default function TreatmentProgram({ indexPage }: Props): JSX.Element {
  const { fetchCountriesForSelect, fetchSectionDisease, fetchCitiesForSelect, fetchTreatmentProgram, clearFormData } = useSelect();
  const selectCountry = useSelect((state) => state.data?.['selectCountries']);
  const selectCity = useSelect((state) => state.data?.['selectCities']);

  useEffect(() => {
    clearFormData('selectCountries')
  }, [indexPage])

  return (
    <>
      {!indexPage && (
        <Form.Item
          name="treatmentProgramId"
          label="Treatment Program"
          valuePropName="defaultValue"
        >
          <CustomSelect
            placeholder="Select Treatment Program"
            selectId="selectTreatmentProgram"
            onUpdateSelect={fetchTreatmentProgram}
            defaultQueryParams={{ page: 1, perPage: 50, onlyTreatments: true }}
            // onChange={value => {
            //   console.log(value)
            // }}
            onChange={(value) => {
              if (value && value.id) {
                const params = { treatmentId: value.id };

                fetchCountriesForSelect('selectCountries', params, { clearDataBeforeFetch: true, saveParams: true });
              }
            }}
            style={{ width: '100%' }}
          />
        </Form.Item>
      )}
      {indexPage && (
        <Form.Item
          name="diseaseId"
          label="Disease"
          valuePropName="defaultValue"
        >
          <CustomSelect
            placeholder="Select Disease"
            selectId="selectDiseases"
            onUpdateSelect={fetchSectionDisease}
            defaultQueryParams={{ page: 1, perPage: 50, onlyTreatments: true }}
            onChange={(value) => {
              if (value && value.urlSlug) {
                const params = { disease: value.urlSlug };

                fetchCountriesForSelect('selectCountries', params, { clearDataBeforeFetch: true, saveParams: true });
              }
            }}
            style={{ width: '100%' }}
          />
        </Form.Item>
      )}
      <Form.Item
        name="countryId"
        label="Country"
        valuePropName="defaultValue"
      >
        <CustomSelect
          // disabled={!selectCountry?.opened}
          placeholder={'Select country'}
          defaultQueryParams={{ page: 1, perPage: 50, onlyTreatments: true }}
          selectId={'selectCountries'}
          onUpdateSelect={fetchCountriesForSelect}
          onChange={(value) => {
            if (value && value.urlSlug && indexPage) {
              const params = { disease: selectCountry?.params.disease, countryUrlSlug: value.urlSlug };

              fetchCitiesForSelect('selectCities', params, { clearDataBeforeFetch: true, saveParams: true });
            }
          }}
          style={{ width: '100%' }}
        />
      </Form.Item>
      {indexPage && (
        <Form.Item
          name="cityId"
          label="City"
          valuePropName="defaultValue"
        >
          <CustomSelect
            disabled={!selectCity?.opened}
            placeholder={'Select city'}
            defaultQueryParams={{ page: 1, perPage: 50, onlyTreatments: true }}
            selectId={'selectCities'}
            onUpdateSelect={fetchCitiesForSelect}
            style={{ width: '100%' }}
          />
        </Form.Item>
      )}
    </>
  );
}