import { ActionType, Actions } from './actions';

import { Doctor, DoctorTranslation } from '@modelTypes/doctor';
import { ListResponse } from '@api/types';

export interface DoctorsState {
  readonly grid: ListResponse<Doctor> & { loading: boolean }
  readonly form: {
    readonly loading: boolean;
    readonly row: Doctor | null;
  };
  readonly selectDoctor: {
    readonly fetched: boolean;
    readonly loading: boolean;
    readonly search: string | null;
    readonly data: Array<Doctor>;
  };
  readonly translations: {
    grid: ListResponse<DoctorTranslation> & { readonly loading: boolean };
    form: {
      loading: boolean;
      rows: Array<DoctorTranslation>;
    },
  }
}

const initialState: DoctorsState = {
  grid: {
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  form: {
    loading: false,
    row: null,
  },
  selectDoctor: {
    loading: false,
    fetched: false,
    search: null,
    data: [],
  },
  translations: {
    grid: {
      loading: false,
      data: [],
      meta: {
        currentPage: 1,
        perPage: 25,
      },
    },
    form: {
      loading: false,
      rows: [],
    },
  }
};

export default function doctors(state = initialState, action: Actions): DoctorsState {
  switch (action.type) {
    case ActionType.START_FETCH_DOCTORS:
    // case ActionType.START_DELETE_DOCTOR:
      return {
        ...state,
        grid: {
          ...state.grid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_DOCTORS:
      return {
        ...state,
        grid: {
          ...action.data,
          loading: false,
        },
      }
    case ActionType.START_FETCH_DOCTORS_FOR_SELECT:
      return {
        ...state,
        selectDoctor: {
          ...state.selectDoctor,
          fetched: false,
          loading: true,
        }
      }
    case ActionType.END_FETCH_DOCTORS_FOR_SELECT:
      return {
        ...state,
        selectDoctor: {
          ...state.selectDoctor,
          data: action.data.data,
          fetched: true,
          loading: false,
        }
      }
    case ActionType.START_FETCH_DOCTOR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        }
      }
    case ActionType.END_FETCH_DOCTOR:
    case ActionType.END_POST_DOCTOR:
    case ActionType.END_PATCH_DOCTOR:
      return {
        ...state,
        form: {
          ...state.form,
          row: action.data,
          loading: false,
        },
      }
    case ActionType.START_POST_DOCTOR:
    case ActionType.START_PATCH_DOCTOR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        },
      }
    case ActionType.END_POST_DOCTOR_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.END_PATCH_DOCTOR_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.CLEAR_FORM_DATA:
      return {
        ...state,
        form: {
          ...initialState.form,
        }
      }
    case ActionType.START_FETCH_TRANSLATIONS:
      return {
        ...state,
        translations: {
          ...state.translations,
          grid: {
            ...state.translations.grid,
            loading: true,
          }
        }
      }
    case ActionType.END_FETCH_TRANSLATIONS:
      return {
        ...state,
        translations: {
          ...state.translations,
          grid: {
            ...action.data,
            loading: false,
          }
        }
      }
    case ActionType.START_FETCH_TRANSLATION:
    case ActionType.START_PATCH_TRANSLATION:
    case ActionType.END_PATCH_TRANSLATION_ERROR:
      return {
        ...state,
        translations: {
          ...state.translations,
          form: {
            ...state.translations.form,
            loading: true,
          }
        }
      }
    case ActionType.END_FETCH_TRANSLATION:
    case ActionType.END_PATCH_TRANSLATION:
      return {
        ...state,
        translations: {
          ...state.translations,
          form: {
            ...state.translations.form,
            rows: action.data,
            loading: false,
          }
        }
      }
    default:
      return state;
  }
}