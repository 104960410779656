import React from 'react';
import { Select } from 'antd';
import { SearchSection } from '@modelTypes/searchSection';
import { toLower } from 'lodash';
import { FieldNames } from 'rc-select/lib/Select';
import { Blog } from '@modelTypes/blog';

interface Props {
  options: Array<SearchSection>;
  onChange: (option: any) => void;
  onClear?: () => void;
  loading?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  placeholder?: string;
  mode?: 'multiple' | 'tags';
  style?: React.CSSProperties;
  excludedIds?: Array<number>;
  defaultValue?: any;
  fieldNames?: FieldNames;
}

export default function SelectSearchSection(
  {
    options,
    onChange,
    onClear,
    placeholder,
    mode,
    defaultValue = undefined,
    loading = false,
    disabled = false,
    style = { width: 180 },
    fieldNames = {
      label: 'name',
      value: 'id',
    },
  }: Props
) {
  const placeholderValue = !placeholder ? mode ? 'Select search sections' : 'Select search section' : placeholder;
  const labelName: any = fieldNames && fieldNames.label ? fieldNames.label : 'label';

  const handleChange = (value: string[], option: any) => {
    onChange(option);
  };

  return (
    <Select
      showSearch
      allowClear
      mode={mode}
      style={style}
      placeholder={placeholderValue}
      fieldNames={fieldNames}
      // labelInValue={true}
      defaultValue={defaultValue}
      onChange={handleChange}
      onClear={onClear}
      loading={loading}
      options={options}
      disabled={disabled}
      filterOption={(inputValue: string, option?: SearchSection) => {
        // @ts-ignore
        if (inputValue && inputValue.length && option && option[labelName]) {
          // @ts-ignore
          return Boolean(toLower(option[labelName]).indexOf(toLower(inputValue)) > -1);
        }

        return true;
      }}
    />
  );
}
