import { useHistory, useParams } from 'react-router-dom';

import { TaskStatuses } from '@modelTypes/task';
import { PageHeader } from '@ant-design/pro-layout';

interface RouterParams {
  status: TaskStatuses;
}

const titles: Record<TaskStatuses, string> = {
  all: 'Task list',
  my: 'My tasks',
};

export default function OrdersIndex(): JSX.Element {
  const history = useHistory();
  const { status } = useParams<RouterParams>();

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.goBack()}
        title={titles[status]}
        // subTitle="This is a subtitle"
      />
    </>
  );
}
