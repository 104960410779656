import * as React from 'react';
import { useEffect } from 'react';

import { Prompt, useHistory, useParams } from 'react-router-dom';

import Editor from '@components/Editor';
import { Language } from '@modelTypes/language';
import { setFormErrors } from '@api/helpers';
import { Button, Card, Checkbox, Col, Form, Input, message, Row, Spin, Tooltip } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import { SaveOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import urlSlug from 'url-slug';
import { CreateAuthorRequest } from '@api/types';
import { useGridStore } from '@zustandStorage/grid/grid';
import QueryString from 'query-string';
import { TemplateTypes } from '@modelTypes/template';
import { useEmailStore } from '@zustandStorage/template/emai';

interface RouterParams {
  type: TemplateTypes;
  id: string;
}

export default function EmailFormCreate() {
  const history = useHistory();
  const { id, type } = useParams<RouterParams>();
  const [messageApi, contextHolder] = message.useMessage();
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);
  const [languageId, setLanguageId] = React.useState<number>(0);
  const [form] = Form.useForm<CreateAuthorRequest>();
  const {
    clearFormData,
    fetchEmailTemplate,
    createEmailTemplate,
    updateEmailTemplate,
    form: { row, loading }
  } = useEmailStore();

  const onSelectLanguage = async (language: Language) => {
    setLanguageId(language.id);

    if (id) {
      await fetchEmailTemplate(parseInt(id, 10), { languageId: language.id })
        .then(() => {
          resetForm();

        });
    }
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const handleOnBlur = () => {
    if (isEmpty(form.getFieldValue('code')) && !isEmpty(form.getFieldValue('title'))) {
      const value = urlSlug(form.getFieldValue('title'));
      form.setFieldValue('code', value);
    }
  };

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving email template...',
      duration: 0,
    });

    setFormSubmitting(true);
    const fieldsValue = form.getFieldsValue()
    const formData = { ...fieldsValue, languageId, type }
    if (id) {
      await updateEmailTemplate(parseInt(id, 10), formData as any)
        .then(() => {
          messageApi.destroy('startCreate');
          message.success('The email template has been successfully updated');
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          setFormSubmitting(false);
          message.error('An error occurred, please try again now')
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    } else {
      await createEmailTemplate(formData as any)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The email template has been successfully created');
          history.push(`/templates/${type}/${data.id}/edit`);
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          setFormSubmitting(false);
          message.error('An error occurred, please try again now')
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    }
  };

  useEffect(() => {
    resetForm();
  }, [languageId, id, row]);

  useEffect(() => {

    return () => {
      clearFormData();
    }
  }, [])

  return (
    <>
      {contextHolder}
      <Prompt
        when={!formSubmitting && formChanged}
        message={(location) =>
          `Data not saved!\nAre you sure you want to go to ${location.pathname}?`
        }
      />
      <PageHeader
        ghost={true}
        onBack={() => {
          console.log(!formSubmitting, '&& ', formChanged);
          const params = useGridStore.getState().gridOptions.authorsAntGrid || {};
          history.push(`/templates/${type}?${QueryString.stringify(params)}`)
        }}
        title={`${row ? 'Edit' : 'Create'} Email`}
        extra={[
          <SelectLanguagePerForm
            key="selectLanguage"
            onInit={onSelectLanguage}
            onChange={onSelectLanguage}
            disabled={!row}
          />,
          <Button
            key="save"
            type="primary"
            loading={formSubmitting}
            onClick={() => form.submit()}
          >
            {id ? 'Update' : 'Create'}
          </Button>
        ]}
      />
      <Spin spinning={loading || languageId === 0}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          autoComplete="off"
        >
          <Row gutter={[32, 24]}>
            <Col span={16}>
              <Form.Item
                name="title"
                label="Title"
                messageVariables={{ name: 'Title' }}
                rules={[{ required: true, type: 'string', max: 255 }]}
                initialValue={row?.title}
              >
                <Input onBlur={handleOnBlur} placeholder="Enter title" />
              </Form.Item>
              <Form.Item
                name="code"
                label="Url Slug"
                messageVariables={{ name: 'Url Slug' }}
                rules={[{ required: true, type: 'string', max: 128 }]}
                initialValue={row?.code}
              >
                <Input disabled={languageId > 1} placeholder="Enter url slug" />
              </Form.Item>
              <Form.Item
                name="metaTitle"
                label="Meta Title"
                messageVariables={{ name: 'Meta Title has been string' }}
                rules={[{ type: 'string' }]}
                initialValue={row ? row?.metaTitle : ''}
              >
                <Input placeholder="Enter Meta Title" />
              </Form.Item>
              <Form.Item
                label="Meta Description"
                name="metaDescription"
                initialValue={row ? row?.metaDescription : ''}
              >
                <Input placeholder="Enter Meta Description" />
              </Form.Item>
              <Form.Item
                name="content"
                label="Content"
                initialValue={row ? row?.content : ''}
                valuePropName="defaultValue"
              >
                <Editor
                  entityName="content"
                  sx={{ mb: 2 }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Card title="Statuses" bordered={false} style={{ marginTop: 24 }}>
                <Form.Item
                  name="isActive"
                  valuePropName="checked"
                  initialValue={row ? row?.isActive : false}
                  style={{ margin: 0 }}
                >
                  <Checkbox disabled={languageId > 1}>Active</Checkbox>
                </Form.Item>
                <Form.Item
                  name="isLanguageActive"
                  valuePropName="checked"
                  initialValue={languageId === 1 ? true : row?.isLanguageActive}
                  style={{ margin: 0 }}
                >
                  <Checkbox disabled={languageId === 1}>Active Language</Checkbox>
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Tooltip title={id ? 'Update' : 'Create'} placement="topRight">
        <Button
          style={{ position: 'fixed', bottom: 20, right: 16 }}
          onClick={() => form.submit()}
          shape="circle"
          icon={<SaveOutlined />}
          size="large"
        />
      </Tooltip>

    </>
  );
}
