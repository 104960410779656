
export enum EVENT_TYPE {
  ERROR,
  WARNING,
  INFO,
  DEBUG,
  SUCCESS,
}

export const EVENT_TYPES = ['ERROR', 'WARNING', 'INFO', 'DEBUG', 'SUCCESS'];

export interface Log {
  id: number,
  eventType: EVENT_TYPE,
  message: string,
  info: { [key: string]: string }
}