import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg data-id="44" width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
  d="M19.7133 2.74412L17.0855 0.139531C16.9692 0.0465105 16.8529 0 16.7134 0C16.5738 0 16.4343 0.0697657 16.3413 0.162787C16.1552 0.372084 16.1552 0.674402 16.3413 0.883699L17.2715 1.81391L16.3645 2.72086L14.9925 1.3488C14.7832 1.13951 14.4576 1.13951 14.2483 1.3488L5.57411 10.0463C5.48109 10.1393 5.41133 10.2788 5.41133 10.4183C5.41133 10.5579 5.45784 10.6974 5.57411 10.7904L7.01594 12.2323L3.17882 16.0694C2.96953 16.2787 2.96953 16.6042 3.17882 16.8135C3.27184 16.9066 3.41138 16.9763 3.55091 16.9763C3.69044 16.9763 3.82997 16.9298 3.92299 16.8135L7.7601 12.9764L9.20193 14.4182C9.29495 14.5113 9.43448 14.581 9.57401 14.581C9.71354 14.581 9.85308 14.5345 9.9461 14.4182L18.6436 5.72079C18.8529 5.51149 18.8529 5.18592 18.6436 4.97662L17.132 3.46503L18.0389 2.55808L18.9691 3.48829C19.0622 3.58131 19.2017 3.65107 19.3412 3.65107C19.4807 3.65107 19.6203 3.58131 19.7133 3.48829C19.9226 3.27899 19.9226 2.95342 19.7133 2.74412ZM8.34149 10.6276C8.48102 10.6276 8.62055 10.5811 8.71357 10.4649C8.92287 10.2556 8.92287 9.92999 8.71357 9.72069L8.03917 9.04629L8.78333 8.30212L10.0159 9.53465C10.1089 9.62767 10.2484 9.69743 10.3879 9.69743C10.667 9.69743 10.8996 9.46488 10.8996 9.16256C10.8996 9.02303 10.8531 8.8835 10.7368 8.79048L9.50425 7.55795L10.2484 6.81379L10.9228 7.48819C11.0158 7.58121 11.1554 7.65097 11.2949 7.65097C11.4344 7.65097 11.5507 7.60446 11.667 7.48819C11.76 7.39516 11.8298 7.25563 11.8298 7.1161C11.8298 6.97657 11.7833 6.83704 11.667 6.74402L10.9926 6.06962L11.7368 5.32545L12.9693 6.58123C13.0856 6.69751 13.2018 6.74402 13.3414 6.74402C13.4809 6.74402 13.6204 6.69751 13.7134 6.58123C13.9227 6.37194 13.9227 6.04636 13.7134 5.83706L12.4809 4.60454L13.2251 3.86037L13.8995 4.53477C14.1088 4.74407 14.4344 4.74407 14.6437 4.53477C14.853 4.32547 14.853 3.9999 14.6437 3.7906L13.9925 3.1162L14.6204 2.48831L17.504 5.37196L9.55076 13.3253L6.66711 10.4416L7.295 9.81371L7.9694 10.4881C8.06242 10.5811 8.20195 10.6509 8.34149 10.6509V10.6276Z"
  fill="currentColor" />
<path
  d="M8.45778 18.4654C7.82989 17.8375 6.15551 17.6049 5.20205 17.5352C4.92298 17.5352 4.66718 17.7445 4.64392 18.0235C4.64392 18.0468 4.64392 18.07 4.64392 18.0933C4.66718 18.6747 4.82996 20.6514 5.52762 21.349C5.8997 21.7444 6.41132 21.9769 6.96944 22.0002H7.01595C7.55083 22.0002 8.03919 21.7909 8.43452 21.442C8.82986 21.07 9.06242 20.5583 9.08567 20.0002C9.08567 19.4421 8.89963 18.9305 8.52755 18.5351C8.52755 18.5351 8.48103 18.4886 8.45778 18.4654ZM7.71361 20.6048C7.31827 21.0002 6.66713 21.0002 6.27179 20.6048C6.06249 20.3955 5.85319 19.6049 5.73692 18.6514C6.69038 18.7677 7.50431 18.977 7.69036 19.1863C8.0857 19.5816 8.0857 20.2328 7.69036 20.6281L7.71361 20.6048Z"
  fill="currentColor" />
<path
  d="M0.424084 15.375C0.848168 15.7969 1.97906 15.9531 2.62304 16C2.81152 16 2.98429 15.8594 3 15.6719C3 15.6562 3 15.6406 3 15.625C2.98429 15.2344 2.87435 13.9063 2.40314 13.4375C2.15183 13.1719 1.80628 13.0156 1.42932 13H1.39791C1.03665 13 0.706806 13.1406 0.43979 13.375C0.172775 13.625 0.0157068 13.9688 0 14.3438C0 14.7188 0.125654 15.0625 0.376963 15.3281C0.376963 15.3281 0.408377 15.3594 0.424084 15.375ZM0.926701 13.9375C1.19372 13.6719 1.63351 13.6719 1.90052 13.9375C2.04188 14.0781 2.18325 14.6094 2.26178 15.25C1.6178 15.1719 1.06806 15.0312 0.942408 14.8906C0.675393 14.625 0.675393 14.1875 0.942408 13.9219L0.926701 13.9375Z"
  fill="currentColor" />
<path
  d="M3.32989 20.3292C3.05895 20.3292 2.78802 20.6002 2.78802 20.9253C2.78802 21.2504 3.00477 21.5213 3.32989 21.5213C3.60083 21.5213 3.87176 21.2504 3.87176 20.9253C3.87176 20.6002 3.60083 20.3292 3.32989 20.3292Z"
  fill="currentColor" />
<path
  d="M3.6549 18.5961C3.6549 18.2709 3.43815 18 3.11303 18C2.84209 18 2.57116 18.2709 2.57116 18.5961C2.57116 18.9212 2.7879 19.1921 3.11303 19.1921C3.43815 19.2463 3.6549 18.9754 3.6549 18.5961Z"
  fill="currentColor" />
<path
  d="M1.54187 19.0846C1.27094 19.0846 1 19.3555 1 19.6807C1 20.0058 1.21675 20.2767 1.54187 20.2767C1.81281 20.2767 2.08374 20.0058 2.08374 19.6807C2.02956 19.3555 1.81281 19.0846 1.54187 19.0846Z"
  fill="currentColor" />
</svg>`;

export default { id: '44-anesthesiology', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
