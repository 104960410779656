import React from 'react';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon, { HomeOutlined } from '@ant-design/icons';

const BlogSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 92 92"
    xmlSpace="preserve"
  >
    <path d="M76 2H16c-2.2 0-4 1.8-4 4v80c0 2.2 1.8 4 4 4h60c2.2 0 4-1.8 4-4V6c0-2.2-1.8-4-4-4zm-4 80H20V10h52v72zM28.5 65c0-2.2 1.8-4 4-4h27c2.2 0 4 1.8 4 4s-1.8 4-4 4h-27c-2.2 0-4-1.8-4-4zm.6-19c0-2.2 1.8-4 4-4h26.3c2.2 0 4 1.8 4 4s-1.8 4-4 4H33.1c-2.2 0-4-1.8-4-4zm0-19c0-2.2 1.8-4 4-4h26.3c2.2 0 4 1.8 4 4s-1.8 4-4 4H33.1c-2.2 0-4-1.8-4-4z"/>
  </svg>
);

export default function BlogIcon(props: Partial<CustomIconComponentProps>) {
  return (
    <Icon
      component={BlogSvg}
      {...props}
    />
  );
}
