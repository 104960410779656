import { Button } from 'antd';
import styles from './ShowGAQRCodeForm.module.scss';
import { useHistory } from 'react-router-dom';

interface Props {
  qrCode: string | null;
}

export default function ShowGAQRCodeForm(
  {
    qrCode
  }: Props
): JSX.Element | null {
  const history = useHistory();

  if (!qrCode) {
    return null;
  }

  return (
    <div className={styles.showQrCode}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={qrCode} alt="Qr Code" />
      </div>
      <Button
        className={styles.submitBtn}
        type="primary"
        size="large"
        onClick={() => history.push('/login/enter-ga-code')}
      >
        Enter code
      </Button>
    </div>
  );
}
