import client from './client';
import { Params } from './types';

const POST_USER_ACTIVITIES_URI = '/ua';
const GET_USER_ACTIVITIES_URI = (id: string | number) => `/ua/${id}`;

export async function postUserActivity(payload: { data: string }) {
  return client.post(POST_USER_ACTIVITIES_URI, payload);
}

export async function getUserActivities(id: string | number, params: Params) {
  return client.get<{ totalActiveTime: number }>(GET_USER_ACTIVITIES_URI(id), params);
}
