import React, { useEffect } from 'react';

import { AppState } from '@storage/app/reducer';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Loader from '@components/Loader';
import { ModalProps } from '@mui/material/Modal';
import { UsersState } from '@storage/users/reducer';
import { appStorage } from '@storage/appStorage';
import { forbiddenModalSetVisibility } from '@storage/app/actions';
import { requestPermission } from '@storage/users/thunkActions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from '@hooks';
import useSuccessAlert from '@hooks/Alert/useSuccessAlert';

export const showForbiddenModal = (data = {}): void => {
  appStorage.dispatch(forbiddenModalSetVisibility({ visible: true, data }));
};

export default function ForbiddenModal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openSuccessAlert] = useSuccessAlert();
  const { visible } = useSelector<AppState['forbiddenModal']>('app.forbiddenModal');
  const { loading } = useSelector<UsersState['requestPermissionModal']>('users.requestPermissionModal');

  const handleOnClose: ModalProps['onClose'] = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }

    closeModal();
  };

  const closeModal = () => {
    dispatch(forbiddenModalSetVisibility({ visible: false, data: {} }))
  };

  const handleRequestPermission = () => {
    dispatch(requestPermission({ url: window.location.href }) as unknown as Promise<any>)
      .then(() => {
        closeModal();
        openSuccessAlert('Sent request');
      })
  };

  useEffect(() => {
    if (visible) {
      history.push('/');
    }
  }, [visible]);
  
  return (
    <Dialog
      fullWidth
      disableEscapeKeyDown={true}
      open={visible}
      onClose={handleOnClose}
      sx={{ ml: 30, mt: 10 }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>Forbidden</DialogTitle>
      <DialogContent sx={{ minHeight: 80 }}>
        <div style={{ textAlign: 'center', marginTop: 15, position: 'relative', display: 'flex', alignItems: 'center' }}>
          <Loader active={loading}>
            You do not have permission to view this page.
          </Loader>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeModal()}>Cancel</Button>
        <Button
          disabled={loading}
          onClick={handleRequestPermission}
        >
          Request permission
        </Button>
      </DialogActions>
    </Dialog>
  );
}
