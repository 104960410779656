import { ActionType, Actions } from './actions';
import { Hospital, HospitalDepartment } from '@modelTypes/hospital';
import { SearchSection, SearchSectionExcludedHospital, SearchSectionRelation } from '@modelTypes/searchSection';

import { ListResponse } from '@api/types';
import { findIndex } from 'lodash';

export interface SearchSectionsState {
  readonly parentSection: {
    readonly loading: boolean;
    readonly row: SearchSection | null;
  }
  readonly grid: ListResponse<SearchSection> & { loading: boolean };
  readonly excludedHospitalsGrid: ListResponse<SearchSectionExcludedHospital> & { loading: boolean };
  readonly form: {
    readonly loading: boolean;
    readonly row: SearchSection | null;
  };
  readonly relations: ListResponse<SearchSectionRelation> & Readonly<{ loading: boolean; fetched: boolean }>;
  readonly allRelations: {
    loading: boolean;
    fetched: boolean;
    hospitalsIds: Array<number>;
    departmentsIds: Array<number>;
    excludedHospitalsIds: Array<number>;
  };
  readonly select: {
    readonly excludedHospitals: {
      readonly loading: boolean;
      readonly fetched: boolean;
      readonly data: Array<Hospital>;
    }
    readonly relatedHospitals: {
      readonly loading: boolean;
      readonly fetched: boolean;
      readonly data: Array<Hospital>;
    }
    readonly relatedDepartments: {
      readonly loading: boolean;
      readonly fetched: boolean;
      readonly hospitalId: number | null;
      readonly search: string;
      readonly data: Array<HospitalDepartment>;
    }
    readonly searchSections: {
      readonly loading: boolean;
      readonly fetched: boolean;
      readonly data: Array<SearchSection>;
    }
    readonly tree: {
      readonly loading: boolean;
      readonly fetched: boolean;
      readonly data: Array<SearchSection>;
    }
  }
}

const initialState: SearchSectionsState = {
  parentSection: {
    loading: false,
    row: null,
  },
  grid: {
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  form: {
    loading: false,
    row: null,
  },
  relations: {
    loading: false,
    fetched: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 30,
    },
  },
  allRelations: {
    loading: false,
    fetched: false,
    hospitalsIds: [],
    departmentsIds: [],
    excludedHospitalsIds: [],
  },
  excludedHospitalsGrid: {
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  select: {
    excludedHospitals: {
      loading: false,
      fetched: false,
      data: [],
    },
    relatedHospitals: {
      loading: false,
      fetched: false,
      data: [],
    },
    relatedDepartments: {
      loading: false,
      fetched: false,
      hospitalId: null,
      search: '',
      data: [],
    },
    searchSections: {
      loading: false,
      fetched: false,
      data: [],
    },
    tree: {
      loading: false,
      fetched: false,
      data: [],
    }
  }
};

export default function searchSections(state = initialState, action: Actions): SearchSectionsState {
  switch (action.type) {
    case ActionType.START_FETCH_SEARCH_SECTIONS:
    // case ActionType.START_DELETE_AUTHOR:
      return {
        ...state,
        grid: {
          ...state.grid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_SEARCH_SECTIONS:
      return {
        ...state,
        grid: {
          ...action.data,
          loading: false,
        },
      }
    case ActionType.START_FETCH_EXCLUDED_HOSPITALS:
    case ActionType.START_POST_EXCLUDED_HOSPITALS:
    case ActionType.START_DELETE_EXCLUDED_HOSPITAL:
      return {
        ...state,
        excludedHospitalsGrid: {
          ...state.excludedHospitalsGrid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_EXCLUDED_HOSPITALS:
      return {
        ...state,
        excludedHospitalsGrid: {
          ...action.data,
          loading: false,
        },
      }
    case ActionType.END_POST_EXCLUDED_HOSPITALS:
      return {
        ...state,
        excludedHospitalsGrid: {
          ...state.excludedHospitalsGrid,
          loading: false,
        },
      }
    case ActionType.END_DELETE_EXCLUDED_HOSPITAL:
      return {
        ...state,
        excludedHospitalsGrid: {
          ...state.excludedHospitalsGrid,
          loading: false,
        },
      }
    case ActionType.START_FETCH_PARENT_SEARCH_SECTION:
      return {
        ...state,
        parentSection: {
          ...state.parentSection,
          loading: true,
        }
      }
    case ActionType.END_FETCH_PARENT_SEARCH_SECTION:
      return {
        ...state,
        parentSection: {
          ...state.parentSection,
          row: action.data,
          loading: false,
        },
      }
    case ActionType.CLEAR_PARENT_SEARCH_SECTION_DATA:
      return {
        ...state,
        parentSection: initialState.parentSection,
      }
    case ActionType.START_FETCH_SEARCH_SECTION:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        }
      }
    case ActionType.END_FETCH_SEARCH_SECTION:
    case ActionType.END_POST_SEARCH_SECTION:
    case ActionType.END_PATCH_SEARCH_SECTION:
      return {
        ...state,
        form: {
          ...state.form,
          row: action.data,
          loading: false,
        },
      }
    case ActionType.START_POST_SEARCH_SECTION:
    case ActionType.START_PATCH_SEARCH_SECTION:
    case ActionType.START_PATCH_SEARCH_SECTION_TRANSLATION:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        },
      }
    case ActionType.END_PATCH_SEARCH_SECTION_TRANSLATION:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.END_POST_SEARCH_SECTION_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.END_PATCH_SEARCH_SECTION_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.CLEAR_FORM_DATA:
      return {
        ...state,
        form: {
          ...initialState.form,
        }
      }
    case ActionType.START_FETCH_RELATIONS:
      return {
        ...state,
        relations: {
          ...state.relations,
          loading: true,
          fetched: false,
        }
      }
    case ActionType.START_IMPORT_RELATIONS:
      return {
        ...state,
        relations: {
          ...state.relations,
          loading: true,
          fetched: false,
        }
      }
    case ActionType.START_IMPORT_EXCLUDED_HOSPITALS:
      return {
        ...state,
        excludedHospitalsGrid: {
          ...state.excludedHospitalsGrid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_RELATIONS:
      return {
        ...state,
        relations: {
          ...action.data,
          loading: false,
          fetched: true,
          // data: action.data,
        }
      }
    case ActionType.CLEAR_RELATIONS:
      return {
        ...state,
        relations: {
          ...initialState.relations,
        }
      }
    case ActionType.START_FETCH_ALL_RELATIONS:
      return {
        ...state,
        allRelations: {
          ...state.allRelations,
          loading: true,
          fetched: false,
        }
      }
    case ActionType.END_FETCH_ALL_RELATIONS:
      return {
        ...state,
        allRelations: {
          ...action.data,
          loading: false,
          fetched: true,
        }
      }
    case ActionType.CLEAR_ALL_RELATIONS:
      return {
        ...state,
        allRelations: {
          ...initialState.allRelations,
        }
      }
    case ActionType.START_FETCH_HOSPITALS_FOR_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          relatedHospitals: {
            ...state.select.relatedHospitals,
            loading: true,
            fetched: false,
          }
        }
      }
    case ActionType.END_FETCH_HOSPITALS_FOR_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          relatedHospitals: {
            ...state.select.relatedHospitals,
            loading: false,
            fetched: true,
            data: action.data,
          }
        }
      }
    case ActionType.CLEAR_HOSPITALS_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          relatedHospitals: {
            ...initialState.select.relatedHospitals,
          }
        }
      }
    case ActionType.START_FETCH_DEPARTMENTS_FOR_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          relatedDepartments: {
            ...state.select.relatedDepartments,
            loading: true,
            fetched: false,
          }
        }
      }
    case ActionType.END_FETCH_DEPARTMENTS_FOR_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          relatedDepartments: {
            ...state.select.relatedDepartments,
            loading: false,
            fetched: true,
            // hospitalId: action.data.hospitalId,
            data: action.data.data,
          }
        }
      }
    case ActionType.CLEAR_DEPARTMENTS_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          relatedDepartments: {
            ...initialState.select.relatedDepartments,
          }
        }
      }
    case ActionType.START_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          excludedHospitals: {
            ...state.select.excludedHospitals,
            loading: true,
            fetched: false,
          }
        }
      }
    case ActionType.END_FETCH_EXCLUDED_HOSPITALS_FOR_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          excludedHospitals: {
            ...state.select.excludedHospitals,
            loading: false,
            fetched: true,
            data: action.data,
          }
        }
      }
    case ActionType.CLEAR_EXCLUDED_HOSPITALS_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          excludedHospitals: {
            ...initialState.select.excludedHospitals,
          }
        }
      }
    case ActionType.START_FETCH_SEARCH_SECTIONS_FOR_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          searchSections: {
            ...state.select.searchSections,
            loading: true,
            fetched: false,
          }
        }
      }
    case ActionType.END_FETCH_SEARCH_SECTIONS_FOR_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          searchSections: {
            ...state.select.searchSections,
            loading: false,
            fetched: true,
            data: action.data.data,
          }
        }
      }
    case ActionType.START_FETCH_SEARCH_SECTIONS_FOR_TREE_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          tree: {
            ...state.select.tree,
            loading: true,
            fetched: false,
          }
        }
      }
    case ActionType.END_FETCH_SEARCH_SECTIONS_FOR_TREE_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          tree: {
            ...state.select.tree,
            loading: false,
            fetched: true,
            data: action.data.data,
          }
        }
      }
    case ActionType.CLEAR_SEARCH_SECTIONS_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          searchSections: {
            ...initialState.select.searchSections,
          }
        }
      }
    default:
      return state;
  }
}