import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M21.3515 14.5977C20.7563 13.4504 19.9975 12.3035 19.0322 11.0905L18.7831 10.7777C17.6495 9.35504 16.4772 7.88399 15.4963 6.31692C14.2526 4.32974 13.3831 2.75032 12.6769 1.19625C12.5922 1.00954 12.5122 0.814153 12.4352 0.625104C12.3935 0.523078 12.3518 0.421051 12.3091 0.319692C12.1971 0.0532888 11.9714 -0.0580735 11.7206 0.0292826C11.6303 0.0606241 11.3445 0.195659 11.4619 0.610767C11.4906 0.712127 11.5296 0.804818 11.5673 0.894508L11.5816 0.928517C12.1831 2.36822 12.916 3.70757 13.5531 4.82786C14.7258 6.88873 16.0508 8.82857 17.4915 10.5927C18.4597 11.7787 19.4687 13.0737 20.2895 14.5084C20.8353 15.4626 21.1014 16.3102 21.1268 17.1747C21.1644 18.4694 20.777 19.3717 19.9068 20.0142C19.4803 20.3289 18.9732 20.5643 18.356 20.7337C17.5512 20.9544 16.6626 21.0791 15.639 21.1151C15.572 21.1151 15.5046 21.1141 15.4373 21.1128C15.2509 21.1098 15.0585 21.1068 14.8648 21.1248C14.6897 21.1411 14.4647 21.3392 14.4614 21.5385C14.4584 21.7109 14.5934 21.8236 14.6921 21.906C14.7018 21.914 14.7111 21.9216 14.7198 21.9293C14.7881 21.988 14.8675 22 14.9298 22C14.9548 22 14.9772 21.998 14.9952 21.9967C15.0018 21.996 15.0085 21.9953 15.0145 21.9953C15.2229 21.9847 15.4356 21.976 15.641 21.9677C16.1241 21.948 16.6236 21.9276 17.1154 21.8773C18.2613 21.7596 19.2136 21.4735 20.0261 21.0024C21.0921 20.3846 21.7326 19.487 21.929 18.335C22.1524 17.0254 21.9583 15.7684 21.3511 14.5987L21.3515 14.5977Z"
    fill="currentColor" />
  <path
    d="M0.447709 6.95509C0.573075 6.98109 0.675101 6.92274 0.742785 6.8844C0.751121 6.87973 0.759456 6.87473 0.767458 6.87073C1.25859 6.61033 1.74905 6.34893 2.23984 6.08753L2.47857 5.96016C2.92769 5.72076 3.38347 5.47637 3.82425 5.23964C4.81751 4.7065 5.84445 4.15536 6.86204 3.62955C7.62624 3.23445 8.44646 3.11442 9.23366 3.28213C10.0212 3.44984 10.722 3.89462 11.2602 4.5678C11.5173 4.88955 11.7473 5.25064 11.9694 5.5994C12.0784 5.77078 12.1914 5.94816 12.3071 6.12053C13.3894 7.73129 14.8158 9.85351 16.2478 11.9811L16.2925 12.0477C16.4475 12.2788 16.6082 12.5175 16.788 12.7413C16.798 12.7538 16.8122 12.766 16.8297 12.7778C17.1218 12.9755 17.5604 12.7108 17.5372 12.3588C17.5292 12.2351 17.4588 12.1441 17.4075 12.0778C17.3951 12.0621 17.3838 12.0471 17.3758 12.0354L16.5769 10.8444C15.1225 8.6762 13.6678 6.50764 12.2124 4.34007C11.3696 3.08508 10.1299 2.3849 8.6275 2.31554H8.6135C7.78862 2.32688 7.12745 2.47792 6.53329 2.79067C4.99156 3.60221 3.42882 4.4361 1.91742 5.24231C1.41663 5.50938 0.916164 5.77644 0.415367 6.04318C0.40203 6.05018 0.38836 6.05685 0.374356 6.06385C0.301671 6.09953 0.202311 6.14854 0.133627 6.24257C0.0639421 6.33726 -0.040085 6.56198 0.0642755 6.73236C0.131626 6.84239 0.256325 6.91507 0.447709 6.95509Z"
    fill="currentColor" />
  <path
    d="M8.97359 12.2685C9.3707 12.8289 10.0989 13.0553 10.7857 12.8309C10.8001 12.8263 10.8097 12.8239 10.8157 12.8229C10.8204 12.8269 10.8274 12.8339 10.8371 12.8453C11.1965 13.2631 11.654 13.4774 12.1964 13.4828C12.2038 13.4828 12.2111 13.4828 12.2184 13.4828C12.6716 13.4828 13.062 13.3327 13.4444 13.0117C13.4941 12.97 13.5751 12.9366 13.6371 12.933C14.4297 12.8853 15.0522 12.3408 15.1865 11.5783C15.3156 10.8448 14.8975 10.1289 14.1696 9.83717C14.1359 9.82383 14.1259 9.81483 14.1259 9.81483C14.1259 9.81483 14.1199 9.80216 14.1173 9.76682C14.0739 9.20167 13.8329 8.71954 13.4391 8.40946C13.0433 8.09772 12.5152 7.97568 11.952 8.06637C11.927 8.07071 11.8683 8.0547 11.8503 8.03903C11.1648 7.4082 10.1639 7.34352 9.47072 7.88566C8.75187 8.44781 8.56415 9.40906 9.0136 10.2236C9.03761 10.2669 9.04294 10.285 9.04395 10.291C9.04228 10.2953 9.03628 10.309 9.01694 10.3336C8.56115 10.9211 8.54448 11.6626 8.97359 12.2685ZM13.6988 10.562C13.9492 10.6104 14.1516 10.7401 14.2693 10.9275C14.3773 11.0998 14.406 11.3122 14.3497 11.5253C14.2533 11.89 13.9069 12.1321 13.5438 12.0894C13.2927 12.0594 13.1083 12.1328 12.946 12.3268C12.7582 12.5512 12.4828 12.6729 12.1911 12.6606C11.8777 12.6472 11.5903 12.4862 11.4032 12.2191C11.2762 12.0377 11.1995 11.928 11.0615 11.8917C11.0325 11.884 11.0048 11.8804 10.9771 11.8804C10.8691 11.8804 10.7637 11.9347 10.61 12.0141C10.2683 12.1904 9.85349 12.0804 9.62376 11.7527C9.41037 11.4486 9.46239 11.0211 9.74513 10.7587C9.90617 10.609 10.0035 10.5187 10.0182 10.3753C10.0329 10.2306 9.95518 10.1246 9.82648 9.9492L9.81348 9.93119C9.56141 9.58744 9.52207 9.25068 9.69378 8.90192C9.86583 8.55183 10.1539 8.35745 10.5267 8.33911C10.8854 8.34611 11.1402 8.46481 11.3275 8.71221C11.5093 8.95227 11.718 9.01595 12.004 8.9196C12.3428 8.80557 12.6862 8.86458 12.947 9.08164C13.2067 9.29769 13.3314 9.63812 13.2804 9.99187C13.235 10.3076 13.3757 10.4993 13.6988 10.562Z"
    fill="currentColor" />
  <path
    d="M16.969 19.8128C17.1024 19.8251 17.5091 19.8628 17.5612 19.4293C17.5805 19.268 17.5268 19.1623 17.4785 19.1019C17.3614 18.9559 17.1704 18.9369 17.0787 18.9275C15.8377 18.8038 14.7864 19.0142 13.8645 19.5717C13.3761 19.8671 12.916 20.2162 12.4712 20.5536C12.4095 20.6006 12.3475 20.6473 12.2858 20.694C11.9157 20.9737 11.5359 21.1098 11.1255 21.1098C11.1205 21.1098 11.1155 21.1098 11.1101 21.1098C10.2986 21.0878 9.74246 20.7497 9.41004 20.0765C8.97926 19.2036 8.95159 18.2634 9.32802 17.2814C9.66677 16.3975 10.2296 15.5887 11.0485 14.8085C11.1205 14.7398 11.2008 14.6574 11.2432 14.5494C11.2779 14.4607 11.3119 14.285 11.1492 14.1056C11.0391 13.9846 10.9228 13.9556 10.8444 13.9529C10.6504 13.9466 10.5047 14.0853 10.4343 14.1523C9.76747 14.7865 9.22699 15.485 8.82756 16.2285C8.45746 16.9173 8.2414 17.5999 8.18505 18.2577C8.12371 18.9739 8.24907 19.6854 8.55749 20.3719C8.99593 21.3485 9.77247 21.8923 10.8648 21.988C10.9514 21.9957 11.0368 21.9993 11.1218 21.9993C11.8157 21.9993 12.4518 21.7453 13.0597 21.2271C13.6371 20.735 14.1426 20.3982 14.6504 20.1672C15.3503 19.8491 16.1084 19.7331 16.969 19.8128Z"
    fill="currentColor" />
  <path
    d="M8.50977 14.5C8.66055 14.5494 8.91925 14.384 8.97559 14.3123C9.16614 14.0699 8.93892 13.7432 8.93892 13.7432C8.66085 13.1887 8.38277 12.6342 8.10437 12.0801C7.58823 11.0522 7.05476 9.98921 6.53229 8.94294C6.19787 8.27276 5.70041 7.81664 5.09425 7.62393C4.48542 7.43054 3.81192 7.51723 3.14674 7.87466C2.75664 8.08438 2.35654 8.29277 1.96977 8.49415C1.8104 8.57717 1.65102 8.6602 1.49165 8.74388C1.34561 8.82024 1.19957 8.89659 1.05353 8.97261C0.802134 9.10364 0.541733 9.23935 0.286333 9.37438C0.144296 9.4494 0.0539395 9.54776 0.0172633 9.66679C-0.00540931 9.74081 -0.017079 9.85784 0.0562734 9.99588C0.202978 10.2723 0.48005 10.2646 0.690772 10.1656C0.793132 10.1172 0.903494 10.0622 1.0382 9.99221C1.3296 9.84017 1.62035 9.6878 1.91142 9.53509C2.51758 9.21701 3.14408 8.88859 3.76324 8.57217C4.45775 8.21741 5.2773 8.47515 5.62839 9.15933C6.05583 9.99121 6.48161 10.8424 6.89339 11.6656C7.0691 12.0174 7.24514 12.3688 7.42152 12.7203C7.48587 12.8486 7.54989 12.977 7.61391 13.1054C7.79895 13.4774 7.99067 13.8622 8.19206 14.2356C8.30442 14.4437 8.50977 14.5 8.50977 14.5Z"
    fill="currentColor" />
  <path
    d="M9.74613 5.97916C10.1509 5.76711 10.389 5.39868 10.383 4.99324C10.381 4.5698 10.2059 4.1927 9.9025 3.95864C9.61243 3.73491 9.23233 3.66156 8.86023 3.75759C8.76921 3.78126 8.64851 3.78059 8.52948 3.75559C8.06302 3.65889 7.69193 3.77126 7.42619 4.09034C7.15579 4.41476 7.10177 4.80819 7.27415 5.19829C7.44286 5.58006 7.77161 5.79412 8.2254 5.81746C8.31609 5.82212 8.41678 5.85347 8.48847 5.89948C8.71919 6.04718 8.95592 6.1212 9.19065 6.1212C9.37837 6.1212 9.56475 6.07419 9.74613 5.97916ZM9.55941 4.92489C9.55708 5.07126 9.46372 5.2053 9.32669 5.25864C9.20232 5.30699 9.03228 5.31099 8.92758 5.17696C8.80422 5.01858 8.66285 4.94123 8.49247 4.94123C8.42278 4.94123 8.3481 4.95423 8.26774 4.9799C8.2284 4.99257 8.12371 5.02625 8.03502 4.86587C7.98234 4.77118 7.99467 4.6935 8.07403 4.61448C8.15971 4.52945 8.24407 4.51578 8.32476 4.57413C8.59883 4.77185 8.83089 4.6945 9.02161 4.59247C9.13997 4.52912 9.27167 4.53179 9.38303 4.59981C9.4954 4.66849 9.56141 4.79019 9.55941 4.92489Z"
    fill="currentColor" />
  <path
    d="M8.08103 6.53664C8.01568 6.48796 7.95433 6.41094 7.91699 6.33126C7.72694 5.92582 7.40619 5.70043 6.98974 5.67975C6.56697 5.65875 6.22521 5.84546 6.02683 6.20556C5.82211 6.57699 5.84278 6.97542 6.08618 7.35719C6.13652 7.43588 6.1692 7.51957 6.1762 7.58658C6.24355 8.23642 6.74668 8.71154 7.37284 8.71621C7.37584 8.71621 7.37884 8.71621 7.38185 8.71621C7.90231 8.71621 8.36143 8.38312 8.52648 7.88533C8.68785 7.3982 8.50881 6.85606 8.08103 6.53664ZM6.75335 6.84639C6.69167 6.79371 6.687 6.72102 6.73935 6.63C6.8137 6.5013 6.89339 6.4943 6.96107 6.50463C7.02375 6.51397 7.12978 6.54531 7.13878 6.64901C7.16679 6.98376 7.37051 7.11246 7.54489 7.18681C7.71527 7.2595 7.79962 7.44721 7.74127 7.62359C7.68592 7.79097 7.50121 7.91167 7.3385 7.88666C7.15845 7.85932 7.02375 7.70595 7.00708 7.51523C7.12645 7.16514 6.84771 6.92708 6.75335 6.84639Z"
    fill="currentColor" />
  <path
    d="M11.7927 10.4993C12.0304 10.1966 11.9844 9.76015 11.6883 9.50508C11.6493 9.47174 11.6133 9.42139 11.5909 9.37005C11.4262 8.98895 11.0405 8.7889 10.631 8.87325C10.2399 8.95361 9.97719 9.28302 9.97786 9.69347C9.97852 10.0932 10.2132 10.3973 10.6064 10.507C10.672 10.5253 10.7441 10.562 10.8141 10.6134C10.9451 10.7091 11.0985 10.7551 11.2495 10.7551C11.4572 10.7551 11.6606 10.6674 11.7927 10.4993ZM11.3182 10.0846C11.3089 10.0986 11.2762 10.1252 11.2542 10.1432L11.2499 10.1469L11.2465 10.1456C11.2342 10.1412 11.2182 10.1356 11.2085 10.1316C11.0835 9.95853 10.9154 9.92286 10.7664 9.91086C10.6537 9.90185 10.587 9.79316 10.5877 9.69347C10.5883 9.57944 10.664 9.49241 10.7807 9.47141C10.8748 9.45474 10.9915 9.49942 11.0218 9.61545C11.0685 9.79282 11.1505 9.90752 11.2949 9.99754C11.3075 10.0155 11.3202 10.0629 11.3182 10.0846Z"
    fill="currentColor" />
  <path
    d="M12.6485 10.606C12.5949 10.607 12.5412 10.608 12.4875 10.6084C12.1448 10.575 11.8373 10.7774 11.721 11.1138C11.6139 11.4236 11.7176 11.805 11.9617 12.0011C12.1114 12.1214 12.2935 12.1828 12.4785 12.1824C12.6245 12.1824 12.7722 12.1444 12.9076 12.0671C12.999 12.0147 13.0953 11.9687 13.1944 11.93C13.4921 11.8137 13.6738 11.5149 13.6361 11.2035C13.5985 10.8901 13.3437 10.6354 13.044 10.6114C12.9106 10.6007 12.7776 10.6037 12.6485 10.606ZM12.3131 11.4503C12.2981 11.4166 12.3098 11.3226 12.3478 11.2475C12.3565 11.2305 12.3768 11.1899 12.4768 11.1899C12.4958 11.1899 12.5175 11.1912 12.5425 11.1945C12.6009 11.2022 12.6572 11.2032 12.7119 11.2042C12.7762 11.2052 12.8373 11.2062 12.8896 11.2185C12.9363 11.2295 12.9823 11.2542 13.0026 11.2709C12.9986 11.2899 12.9886 11.3169 12.9786 11.3342C12.8193 11.3736 12.6782 11.4483 12.5372 11.5666C12.5282 11.5696 12.5088 11.574 12.4928 11.5776C12.4835 11.5796 12.4738 11.582 12.4628 11.5843C12.4542 11.578 12.4458 11.5716 12.4372 11.5656C12.3865 11.5289 12.3295 11.4876 12.3131 11.4503Z"
    fill="currentColor" />
</svg>`;

export default { id: '144-nasopharyngeal-cancer', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
