import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M2.21522 10.7145H4.5737C4.88621 10.7145 5.14252 10.4601 5.14477 10.1489C5.14991 10.0011 5.09338 9.85465 4.98996 9.74641C4.88364 9.6356 4.73493 9.572 4.58141 9.572H2.21522C1.9056 9.572 1.64415 9.83345 1.64415 10.1434C1.64415 10.4533 1.90046 10.7145 2.21522 10.7145Z"
    fill="currentColor" />
  <path
    d="M2.21522 14.4097H4.5737C4.88621 14.4097 5.14252 14.1553 5.14477 13.8444C5.14991 13.697 5.09627 13.5566 4.99349 13.4494C4.8875 13.3386 4.73718 13.275 4.58141 13.275H2.21522C1.9056 13.275 1.64415 13.5332 1.64415 13.8386C1.64415 14.1441 1.90046 14.4097 2.21522 14.4097Z"
    fill="currentColor" />
  <path
    d="M2.21522 12.5584H4.5737C4.88621 12.5584 5.14252 12.304 5.14477 11.9928C5.14991 11.845 5.09338 11.6986 4.98996 11.5903C4.88364 11.4795 4.73493 11.4159 4.58141 11.4159H2.21522C1.9056 11.4159 1.64415 11.6774 1.64415 11.9873C1.64415 12.2973 1.90046 12.5587 2.21522 12.5587V12.5584Z"
    fill="currentColor" />
  <path
    d="M2.87591 0C2.56532 0 2.31222 0.252774 2.31222 0.563682C2.31222 0.874591 2.565 1.12704 2.87591 1.12704H5.94292L2.66489 3.89471H1.86963C0.838618 3.89503 0 4.73686 0 5.77205V16.358C0 17.389 0.838618 18.2277 1.86963 18.2277H5.08567V19.3926C5.08567 19.7077 5.33844 19.964 5.64935 19.964H6.9296V21.4286C6.9296 21.7437 7.18591 22 7.50067 22C7.81543 22 8.06435 21.7437 8.06435 21.4286V19.964H9.3446C9.65519 19.964 9.90829 19.7077 9.90829 19.3926V18.2277H13.1166C14.1476 18.2277 14.9862 17.389 14.9862 16.358V5.77205C14.9862 4.73686 14.1476 3.89471 13.1166 3.89471H12.3214L9.04301 1.12704H17.6849V21.4209C17.6849 21.736 17.9376 21.992 18.2485 21.992C18.5594 21.992 18.8196 21.7357 18.8196 21.4209V0.571391C18.8196 0.251168 18.572 0 18.2559 0H2.87591ZM8.78092 18.8215H6.21271V18.2277H8.78092V18.8215ZM13.8589 16.358C13.8589 16.7675 13.5258 17.1006 13.1163 17.1006H1.86963C1.46461 17.1006 1.13475 16.7711 1.13475 16.3657V8.60652C1.51889 8.51016 1.98204 8.55416 2.27432 8.717C2.99603 9.12074 4.08453 9.12074 4.80656 8.717C5.19166 8.50149 5.85587 8.50085 6.22877 8.71572L6.23263 8.71797C6.57501 8.90554 7.02532 9.00864 7.50067 9.00864C7.97603 9.00864 8.41926 8.90522 8.76293 8.71668C9.14772 8.50149 9.81322 8.50117 10.1871 8.71668C10.9088 9.12042 11.9966 9.12074 12.7174 8.71765C13.0161 8.55416 13.4802 8.50952 13.8589 8.60523V16.358ZM13.1163 5.03717C13.3158 5.03717 13.5033 5.11586 13.644 5.25847C13.7802 5.39658 13.8537 5.57869 13.8512 5.77205V7.44735C13.247 7.3632 12.6188 7.46406 12.1598 7.72133C11.7834 7.93556 11.1227 7.93524 10.7456 7.72101C10.3926 7.52155 9.94008 7.41202 9.47147 7.41202C9.00286 7.41202 8.5532 7.52187 8.20503 7.72068C7.82571 7.93267 7.16086 7.93331 6.78282 7.72165C6.05983 7.3086 4.97036 7.30828 4.24866 7.72068C3.86934 7.93299 3.20352 7.93267 2.82355 7.72004C2.36779 7.46759 1.74051 7.36738 1.13475 7.44896V5.77205C1.13475 5.36703 1.46429 5.03717 1.86963 5.03717H13.1163ZM10.5696 3.90242H4.42499L7.50035 1.30145L10.5696 3.90242Z"
    fill="currentColor" />
  <path
    d="M1.64415 6.44814C1.64415 6.76323 1.90046 7.01921 2.21522 7.01921H4.5737C4.88621 7.01921 5.14252 6.76483 5.14477 6.45392C5.14991 6.3065 5.09627 6.16614 4.99349 6.05886C4.8875 5.94806 4.73718 5.88446 4.58141 5.88446H2.21522C1.9056 5.88446 1.64415 6.14269 1.64415 6.44814Z"
    fill="currentColor" />
  <path
    d="M4.5737 15.1109H2.21522C1.90014 15.1109 1.64415 15.3672 1.64415 15.6823C1.64415 15.9973 1.90046 16.2536 2.21522 16.2536H4.58141C4.89649 16.2536 5.15248 15.9973 5.15248 15.6823C5.15248 15.3672 4.8981 15.1109 4.5737 15.1109Z"
    fill="currentColor" />
</svg>`;

export default { id: '222-transfusion-medicine', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
