import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M14.2024 17.1215C14.2785 17.0289 14.3125 16.9193 14.3017 16.8046C14.2899 16.6791 14.2245 16.5628 14.1124 16.4702V16.4691C14.0861 16.4475 14.0568 16.4259 14.0213 16.4028C13.3679 15.9768 12.6923 15.9433 12.0106 16.3066C11.7472 16.1538 11.4431 16.0668 11.083 16.04L11.0459 16.037L11.0094 16.0452C10.9569 16.0575 10.9034 16.0673 10.8504 16.0771C10.7141 16.1023 10.5741 16.1296 10.4332 16.182C10.2459 16.251 10.0782 16.3565 9.92433 16.4594C9.80754 16.5381 9.73397 16.6528 9.71545 16.7809C9.69847 16.9044 9.73448 17.0269 9.81732 17.1262C9.97578 17.316 10.2155 17.3458 10.428 17.2044C10.4429 17.1946 10.4574 17.1843 10.4712 17.175L10.4985 17.1555C10.8782 16.8993 11.2955 16.9029 11.6747 17.1653C11.8028 17.2538 11.9046 17.2939 12.006 17.2939C12.1074 17.2939 12.2113 17.2538 12.3409 17.1647C12.6568 16.9476 12.9763 16.9106 13.3169 17.049C13.3771 17.0732 13.4415 17.1143 13.5099 17.1586C13.5387 17.1771 13.568 17.1956 13.5979 17.2136C13.8155 17.3464 14.0475 17.3109 14.2024 17.1215Z"
    fill="currentColor" />
  <path
    d="M12.8796 17.2501H12.8786C12.7458 17.3345 12.6259 17.4076 12.5061 17.4534C12.0219 17.6345 11.5645 17.5635 11.1087 17.2352C10.9512 17.122 10.8123 17.1174 10.7238 17.1339C10.6086 17.156 10.5108 17.2234 10.4419 17.3294C10.3004 17.5449 10.3621 17.8115 10.5911 17.9766C11.0444 18.3038 11.5213 18.4561 12.0924 18.4561H12.096C12.523 18.4505 12.9794 18.2925 13.3802 18.0106C13.7012 17.7847 13.6853 17.5017 13.5742 17.3273C13.5186 17.2409 13.3046 16.98 12.8796 17.2501Z"
    fill="currentColor" />
  <path
    d="M23.7695 0.560804C23.7669 0.25159 23.5128 0 23.1989 0C22.8872 0.0025725 22.6356 0.258793 22.6381 0.57058C22.6793 5.31838 21.1811 14.4271 16.9874 18.6573C15.5329 20.1246 13.8716 20.8686 12.0497 20.8686C7.84627 20.8686 5.26709 16.891 3.84398 13.554C1.95268 9.12053 1.28178 3.98017 1.28178 0.925585C1.28178 0.613798 1.02813 0.36015 0.71634 0.36015C0.404553 0.36015 0.150391 0.613283 0.150391 0.925585C0.150391 4.04242 0.826443 9.30833 2.73215 13.8905C4.2705 17.5902 7.13627 22 12.0497 22C14.1839 22 16.1153 21.1434 17.7905 19.4537C20.274 16.9486 21.6951 13.0904 22.4498 10.2951C23.3013 7.14177 23.7947 3.50271 23.7695 0.560804Z"
    fill="currentColor" />
  <path
    d="M10.1281 12.9916C10.7696 13.1342 11.3814 13.164 11.7817 13.164C11.8069 13.164 11.8311 13.164 11.8532 13.164C12.3291 13.1599 12.751 12.9819 13.0736 12.6495C13.4373 12.2744 13.639 11.7219 13.6261 11.1338C13.6076 10.2638 13.604 8.69454 13.6946 6.1632C13.728 5.2263 14.0841 4.47358 14.7524 3.92513C16.3684 2.60029 19.4112 2.67181 20.9542 2.92288C21.2618 2.9733 21.553 2.76287 21.6029 2.4552C21.6271 2.306 21.5921 2.15628 21.5036 2.03383C21.4152 1.91138 21.2845 1.8306 21.1358 1.8059C18.9862 1.45656 15.8364 1.57232 14.0347 3.04996C13.1044 3.81297 12.6095 4.8466 12.5637 6.12204C12.4721 8.68167 12.4757 10.2735 12.4948 11.158C12.5009 11.4456 12.416 11.7023 12.2612 11.8613C12.1511 11.9745 12.0142 12.0306 11.8413 12.0321C11.5563 12.0367 10.9862 12.0233 10.3709 11.8865C10.0668 11.8222 9.76483 12.0146 9.69794 12.3166C9.66502 12.4643 9.69177 12.6155 9.77306 12.7431C9.85435 12.8707 9.9804 12.9587 10.1281 12.9916Z"
    fill="currentColor" />
  <path
    d="M20.2323 5.59056C20.4237 5.34411 20.3789 4.98808 20.1335 4.7972C20.0147 4.70408 19.8665 4.66292 19.7168 4.68144C19.5665 4.69996 19.4323 4.77611 19.3397 4.89598C18.5875 5.86376 16.319 5.72742 15.0657 5.41409C14.7632 5.33897 14.4555 5.52316 14.3799 5.82569C14.3433 5.97232 14.366 6.12409 14.4437 6.25375C14.5214 6.3834 14.6448 6.47498 14.7915 6.51151C15.4809 6.68387 16.2866 6.78317 17.0012 6.78317C18.0524 6.78317 19.4652 6.57634 20.2323 5.59056Z"
    fill="currentColor" />
  <path
    d="M6.32541 6.89276C7.11568 6.89276 8.00628 6.78317 8.76876 6.59177C9.07129 6.51614 9.25548 6.20847 9.18036 5.90595C9.10525 5.60393 8.79758 5.41923 8.49402 5.49486C7.08687 5.84781 4.53649 5.99701 3.68139 4.8965C3.58878 4.77713 3.45501 4.70099 3.30478 4.68195C3.15506 4.66291 3.00637 4.70407 2.88752 4.79617C2.76816 4.88878 2.69201 5.02255 2.67297 5.17227C2.65394 5.3225 2.6951 5.47068 2.78771 5.58953C3.62428 6.66638 5.17293 6.89276 6.32541 6.89276Z"
    fill="currentColor" />
  <path
    d="M8.32214 13.4536C8.01035 12.9283 7.61316 12.4159 7.14136 11.9302C7.03641 11.8216 6.89543 11.7604 6.74468 11.7579C6.59291 11.7558 6.44988 11.8124 6.34132 11.9179C6.23276 12.0228 6.17205 12.1643 6.16999 12.3156C6.16793 12.4668 6.22453 12.6093 6.33 12.7174C6.74005 13.1398 7.08323 13.5818 7.34974 14.0304C7.43977 14.1817 7.59155 14.281 7.76597 14.3026C7.78963 14.3057 7.81382 14.3072 7.83748 14.3072C7.93832 14.3072 8.03659 14.2804 8.12457 14.228C8.39263 14.069 8.48164 13.7212 8.32265 13.4526L8.32214 13.4536Z"
    fill="currentColor" />
  <path
    d="M5.69777 10.6959C5.16372 10.3142 4.59777 9.97923 4.06115 9.72764C3.92429 9.66333 3.77045 9.65664 3.62845 9.70809C3.48645 9.75954 3.37326 9.86296 3.30895 9.99981C3.17672 10.2823 3.29866 10.6193 3.58112 10.752C3.91297 10.9074 4.43519 11.1837 5.03972 11.6159C5.11638 11.6704 5.20539 11.7054 5.298 11.7172C5.32167 11.7203 5.34534 11.7213 5.36849 11.7213C5.54959 11.7213 5.72144 11.6344 5.82845 11.4847C5.91643 11.3617 5.9509 11.212 5.92621 11.0628C5.90151 10.9136 5.82073 10.7839 5.69777 10.6959Z"
    fill="currentColor" />
  <path
    d="M7.98361 17.1014C7.95943 17.2506 7.99493 17.4004 8.08342 17.5228C8.1714 17.6447 8.30054 17.725 8.44872 17.7497C8.45695 17.7512 8.46518 17.7523 8.47341 17.7533C8.49708 17.7564 8.52075 17.7574 8.5439 17.7574C8.81813 17.7574 9.05531 17.5599 9.10059 17.282C9.11191 17.2126 9.12168 17.1426 9.13043 17.0742C9.20555 16.4763 9.17931 15.8754 9.05377 15.2863C8.98843 14.9817 8.68693 14.7862 8.38235 14.8515C8.23469 14.8829 8.10812 14.9704 8.0258 15.0975C7.94348 15.2245 7.9157 15.3753 7.9476 15.5235C8.04689 15.9875 8.06747 16.4619 8.00831 16.9337C8.0011 16.9893 7.99287 17.0454 7.98361 17.1014Z"
    fill="currentColor" />
  <path
    d="M16.1899 14.4708C16.0407 14.4492 15.892 14.4873 15.7717 14.5773C15.6502 14.6684 15.5715 14.8011 15.5504 14.9519C15.4671 15.5477 15.4835 16.1491 15.6003 16.7392C15.6137 16.8072 15.6286 16.8761 15.6451 16.9445C15.7063 17.2049 15.9363 17.3808 16.1941 17.3808C16.2306 17.3808 16.2681 17.3772 16.3057 17.37H16.3088C16.3155 17.368 16.3222 17.3664 16.3289 17.3649C16.6304 17.2923 16.8176 16.9883 16.7466 16.6852C16.7338 16.6302 16.7214 16.5751 16.7106 16.5195C16.6185 16.0529 16.6051 15.5785 16.671 15.1088C16.7142 14.8001 16.4976 14.514 16.1899 14.4708Z"
    fill="currentColor" />
  <path
    d="M18.9322 11.1353C18.9687 11.1353 19.0058 11.1317 19.0428 11.1245C19.1344 11.1065 19.2208 11.0653 19.2934 11.0051C19.8681 10.5292 20.3687 10.2174 20.6872 10.0394C20.9593 9.88714 21.0571 9.54191 20.9053 9.26974C20.753 8.99757 20.4078 8.89981 20.1356 9.05159C19.6185 9.34074 19.0778 9.71478 18.572 10.1336C18.3318 10.3322 18.2983 10.6892 18.4974 10.93C18.606 11.0612 18.7655 11.1353 18.9322 11.1353Z"
    fill="currentColor" />
  <path
    d="M16.7646 13.8802C16.9374 13.8457 17.082 13.7356 17.1607 13.5782C17.3943 13.1115 17.7051 12.6464 18.0843 12.1962C18.1815 12.081 18.2283 11.9343 18.2155 11.7836C18.2026 11.6328 18.1321 11.4965 18.0163 11.3993C17.9001 11.3015 17.7534 11.2552 17.6027 11.2675C17.4524 11.2804 17.3161 11.3519 17.2194 11.4672C16.7826 11.9858 16.4224 12.5255 16.1492 13.0719C16.0818 13.2072 16.0705 13.3605 16.1184 13.5036C16.1662 13.6471 16.267 13.7629 16.4018 13.8303C16.4816 13.8699 16.567 13.8905 16.6539 13.8905C16.6905 13.8905 16.728 13.8874 16.7646 13.8802Z"
    fill="currentColor" />
</svg>`;

export default { id: '119-facial-volumization', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
