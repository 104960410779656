import { Content, MenuContent } from '@modelTypes/content';
import { ListResponse } from '@api/types';
import { createAction } from '../helpers';

export enum ActionType {
  START_FETCH_CONTENTS = 'CONTENTS__START_FETCH_CONTENTS',
  END_FETCH_CONTENTS = 'CONTENTS__END_FETCH_CONTENTS',

  START_FETCH_MENU_CONTENTS = 'CONTENTS__START_FETCH_MENU_CONTENTS',
  END_FETCH_MENU_CONTENTS = 'CONTENTS__END_FETCH_MENU_CONTENTS',

  // START_FETCH_CONTENTS_FOR_SELECT = 'CONTENTS__START_FETCH_CONTENTS_FOR_SELECT',
  // END_FETCH_CONTENTS_FOR_SELECT = 'CONTENTS__END_FETCH_CONTENTS_FOR_SELECT',

  START_FETCH_CONTENT = 'CONTENTS__START_FETCH_CONTENT',
  END_FETCH_CONTENT = 'CONTENTS__END_FETCH_CONTENT',

  START_PATCH_CONTENT = 'CONTENTS__START_PATCH_CONTENT',
  END_PATCH_CONTENT = 'CONTENTS__END_PATCH_CONTENT',
  END_PATCH_CONTENT_ERROR = 'CONTENTS__END_PATCH_CONTENT_ERROR',

  // START_DELETE_DOCTOR = 'CONTENTS__START_DELETE_DOCTOR',
  // END_DELETE_DOCTOR = 'CONTENTS__END_DELETE_DOCTOR',

  CLEAR_FORM_DATA = 'CONTENTS__CLEAR_FORM_DATA',
}

export const startFetchContents = createAction<ActionType.START_FETCH_CONTENTS>(ActionType.START_FETCH_CONTENTS);
export const endFetchContents = createAction<ActionType.END_FETCH_CONTENTS, ListResponse<Content>>(ActionType.END_FETCH_CONTENTS);

export const startFetchMenuContents = createAction<ActionType.START_FETCH_MENU_CONTENTS>(ActionType.START_FETCH_MENU_CONTENTS);
export const endFetchMenuContents = createAction<ActionType.END_FETCH_MENU_CONTENTS, ListResponse<MenuContent>>(ActionType.END_FETCH_MENU_CONTENTS);

// export const startFetchDoctorsForSelect = createAction<ActionType.START_FETCH_DOCTORS_FOR_SELECT>(ActionType.START_FETCH_DOCTORS_FOR_SELECT);
// export const endFetchDoctorsForSelect = createAction<ActionType.END_FETCH_DOCTORS_FOR_SELECT, ListResponse<Doctor>>(ActionType.END_FETCH_DOCTORS_FOR_SELECT);

export const startFetchContent = createAction<ActionType.START_FETCH_CONTENT>(ActionType.START_FETCH_CONTENT);
export const endFetchContent = createAction<ActionType.END_FETCH_CONTENT, Content>(ActionType.END_FETCH_CONTENT);

export const startUpdateContent = createAction<ActionType.START_PATCH_CONTENT>(ActionType.START_PATCH_CONTENT);
export const endUpdateContent = createAction<ActionType.END_PATCH_CONTENT, Content>(ActionType.END_PATCH_CONTENT);
export const endUpdateContentError = createAction<ActionType.END_PATCH_CONTENT_ERROR>(ActionType.END_PATCH_CONTENT_ERROR);

// export const startDeleteDoctor = createAction<ActionType.START_DELETE_DOCTOR>(ActionType.START_DELETE_DOCTOR);
// export const endDeleteDoctor = createAction<ActionType.END_DELETE_DOCTOR>(ActionType.END_DELETE_DOCTOR);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

export type Actions =
  | ReturnType<typeof startFetchContents>
  | ReturnType<typeof endFetchContents>
  | ReturnType<typeof startFetchMenuContents>
  | ReturnType<typeof endFetchMenuContents>
  // | ReturnType<typeof startFetchDoctorsForSelect>
  // | ReturnType<typeof endFetchDoctorsForSelect>
  | ReturnType<typeof startFetchContent>
  | ReturnType<typeof endFetchContent>
  | ReturnType<typeof startUpdateContent>
  | ReturnType<typeof endUpdateContent>
  | ReturnType<typeof endUpdateContentError>
  // | ReturnType<typeof startDeleteDoctor>
  // | ReturnType<typeof endDeleteDoctor>
  | ReturnType<typeof clearFormData>;