import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { PaginationConfig } from '@components/AntGrid/types';
import { Hospital } from '@modelTypes/hospital';
import { hospitalColumns, hospitalFiltersConfig } from '@components/AntGrid/columns/hospital';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import { getHistorySearchParam } from '@helpers/history';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import useChangeGridOptions from '@components/AntGrid/hooks/useChangeGridOptions';
import { useHospitalStore } from '@zustandStorage/hospital/hospital';

export const [refreshHospitalsGridEvent, dispatchRefreshHospitalsGridEvent] = makeRefreshGridEvent('onRefreshHospitalsGrid');

export default function HospitalsAntGrid() {
  // const dispatch = useDispatch();
  const history = useHistory();
  // const { type } = useParams<RouterParams>();
  const firstLoad = useRef<boolean>(false);
  const languageId = getHistorySearchParam(history, 'language-id', 1);
  const { fetchHospitals, grid: { data, meta, loading} } = useHospitalStore()
  // const { data, meta, loading } = useSelector<HospitalsState['grid']>('hospitals.grid');

  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
    // ...rest.paginationConfig,
  };

  const onFetchData = (params?: AntGetListParams) => {
    // dispatch(getHospitals({ ...params, languageId } as any) as unknown as Promise<void>)
    fetchHospitals({...params, languageId} )
      .then(() => {
        firstLoad.current = true;
      });
  };

  useRefreshGridEvent(refreshHospitalsGridEvent, onFetchData);
  useChangeGridOptions('hospitalsAntGrid');
  useChangeGridOptions('gridOptions')

  useEffect(() => {
    if (firstLoad.current) {
      // dispatch(getHospitals({ languageId } as any));
      fetchHospitals({languageId} )
    }
  }, [firstLoad, languageId]);

  return (
    <Grid<Hospital>
      id="hospitalsGrid"
      onFetch={onFetchData}
      columns={hospitalColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={hospitalFiltersConfig}
    />
  );
}