import { Form, Input, message, Modal } from 'antd';
import 'cropperjs/dist/cropper.css';
import * as React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { CreateTagRequest } from '@api/types';
import { useTagsStore } from '@zustandStorage/tags/tags';
import { Tag } from '@modelTypes/tag';
import { dispatchRefreshTagsGridEvent } from '@containers/Tags/components/TagsAntGrid';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  data?: Tag;
}

export default function TagModalAnt({ isOpen, closeModal, data }: ModalProps) {
  const { confirm } = Modal;
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm<CreateTagRequest>();
  const { createTag, updateTag } = useTagsStore()

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const showConfirm = () => {
    confirm({
      title: 'Data not saved!',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to close modal window?',
      onOk() {
        closeModal()
        resetForm()
      },
      // onCancel() {
      //
      // },
    });
  };

  const handleCloseModal = () => {
    if (formChanged) {
      showConfirm()
    } else {
      closeModal()
      resetForm()
    }
  }

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving image...',
      duration: 0,
    });

    const dataForm = form.getFieldsValue(true);

    if (!data) {
      await createTag(dataForm)
        .then(() => {
          message.success('The tag has been successfully created');
          resetForm();
          closeModal()
          dispatchRefreshTagsGridEvent()
        })
        .catch((e: any) => {
          message.error('An error occurred, please try again now')
        })
        .finally(() => messageApi.destroy('startCreate'))

    } else if (data && data.id) {
      await updateTag(data.id, dataForm)
        .then(() => {
          message.success('The tag has been successfully update');
          resetForm();
          closeModal()
          dispatchRefreshTagsGridEvent()
        })
        .catch((e: any) => {
          message.error('An error occurred, please try again now')
        })
        .finally(() => messageApi.destroy('startCreate'))
    }
  }

  return (
    <Modal
      title={<div>{data && data.id ? 'Edit' : 'Add New'} Tag</div>}
      open={isOpen}
      onCancel={handleCloseModal}
      onOk={form.submit}
      bodyStyle={{ maxHeight: '70vh' }}
      width={510}
      okText={'Save'}
    >
      {contextHolder}

      <Form
        form={form}
        layout="vertical"
        onFinish={handleOnFinish}
        onFieldsChange={handleFieldsChange}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        id={'test'}>
        <Form.Item
          name="name"
          label="Name"
          messageVariables={{ name: 'Name' }}
          rules={[{ required: true, type: 'string', max: 100 }]}
          initialValue={data ? data.name : ''}
          style={{ width: 460, margin: '16px 0 0 0' }}
        >
          <Input
            placeholder="Enter tag"
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}