import {
  AntGetListParams,
  CreateSpecializationRequest,
  CreateSpecializationResponse,
  GetListParams,
  ListResponse,
  Params,
  UpdateSpecializationRequest,
  UpdateSpecializationResponse,
} from '@api/types';

import { AxiosRequestConfig } from 'axios';
import { Specialization } from "../modelTypes/specialization";
import client from "./client";
import { getRestUri } from '@api/helpers';

const getUri = getRestUri('specializations');
const ROOT_URI = getUri('');
const GET_SPECIALIZATIONS_FOR_SELECT_URI = 'specializations/select';
const GET_SPECIALIZATIONS_FOR_SELECT2_URI = 'specializations/select2';


export async function getSpecializations(params?: AntGetListParams) {
  return client.get<ListResponse<Specialization>>(ROOT_URI.GET(), params);
}

export async function getSpecializationsForSelect2(params?: GetListParams) {
  return client.get<ListResponse<Specialization>>(GET_SPECIALIZATIONS_FOR_SELECT2_URI, params);
}

export async function getSpecializationsForSelect(params?: GetListParams) {
  return client.get<ListResponse<Specialization>>(GET_SPECIALIZATIONS_FOR_SELECT_URI, params);
}

export async function getSpecialization(id: number, params?: Params) {
  return client.get<Specialization>(ROOT_URI.SHOW({ id }), params);
}

export async function createSpecialization(payload: CreateSpecializationRequest) {
  return client.post<CreateSpecializationRequest, CreateSpecializationResponse>(ROOT_URI.POST(), payload);
}

export async function updateSpecialization(id: number, payload: UpdateSpecializationRequest) {
  return client.patch<UpdateSpecializationRequest, UpdateSpecializationResponse>(ROOT_URI.PATCH({ id }), payload);
}

export async function deleteSpecialization(id: number, payload?: AxiosRequestConfig) {
  return client.del(ROOT_URI.DELETE({ id }), payload);
}
