import { create } from 'zustand';
import {
  AntGetListParams,
  CreateTreatmentMethodRequest,
  CreateTreatmentMethodResponse,
  ListResponse,
  Params,
  UpdateTreatmentMethodRequest,
  UpdateTreatmentMethodResponse
} from '@api/types';
import produce from 'immer';
import { TreatmentMethod } from '@modelTypes/treatment';
import { initialFormState, initialGridState } from '@zustandStorage/helpers/initialStates';
import {
  createTreatmentMethod,
  deleteTreatmentMethod,
  getTreatmentMethod,
  getTreatmentMethods,
  updateTreatmentMethod
} from '@api';
import { AxiosRequestConfig } from 'axios';

export interface MethodsState {
  grid: ListResponse<TreatmentMethod> & { loading: boolean }
  form: {
    loading: boolean;
    row: TreatmentMethod | null;
  };
  clearFormData: () => void;
  fetchMethods: (params?: AntGetListParams) => Promise<ListResponse<TreatmentMethod>>;
  fetchMethod: (id: number, params?: Params) => Promise<TreatmentMethod>;
  createMethod: (payload: CreateTreatmentMethodRequest) => Promise<CreateTreatmentMethodResponse>;
  updateMethod: (id: number, payload: UpdateTreatmentMethodRequest) => Promise<UpdateTreatmentMethodResponse>;
  deleteMethod: (id: number, payload?: AxiosRequestConfig) => Promise<any>;
}

export const useMethodState = create<MethodsState>((set) => ({
  grid: initialGridState,
  form: initialFormState,
  clearFormData: () => set(() => ({ form: initialFormState })),

  fetchMethods: async (params) => {
    set(produce((state) => {
      state.grid.loading = true;
    }));

    return getTreatmentMethods(params)
      .then((response) => {
        set(produce((state) => {
          state.grid.data = response.data;
          state.grid.meta = response.meta
          state.grid.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.loading = false;
        }));

        throw e;
      });
  },
  fetchMethod: async (id: number, params?: Params): Promise<TreatmentMethod> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return getTreatmentMethod(id, params)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  createMethod: async (payload: CreateTreatmentMethodRequest): Promise<CreateTreatmentMethodResponse> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return createTreatmentMethod(payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateMethod: async (id: number, payload: UpdateTreatmentMethodRequest): Promise<UpdateTreatmentMethodResponse> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateTreatmentMethod(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  deleteMethod: async (id, payload?) => {
    return deleteTreatmentMethod(id, payload)
  },
}));