import { FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { getDateFormattedCell } from '../cells/DateFormattedCell';
import { IconButtonRenderer } from '../renderers/renderers';
import { useHistory } from 'react-router-dom';
import { getHistorySearchParam } from '@helpers/history';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import { message } from 'antd';
import React from 'react';
import { TreatmentMethod } from '@modelTypes/treatment';
import getIdCell from '@components/AntGrid/cells/IdCell';
import { useMethodState } from '@zustandStorage/offers/method';
import { dispatchRefreshTreatmentMethodsGridEvent } from '@containers/Offers/Methods/components/MethodsGrid';

export type TreatmentMethodsColumnsNames = `${keyof TreatmentMethod}Column` | 'editColumn' | 'deleteColumn';

export const treatmentMethodColumnsDefinitions: Partial<Record<TreatmentMethodsColumnsNames, ColumnType<TreatmentMethod>>> = {
  idColumn: createColumnDefinition<TreatmentMethod>('#', 'id', { width: 80, render: getIdCell }),
  nameColumn: createColumnDefinition<TreatmentMethod>('Name', 'name'),
  urlSlugColumn: createColumnDefinition<TreatmentMethod>('Url Slug', 'urlSlug'),
  updatedAtColumn: createColumnDefinition<TreatmentMethod>('Updated At', 'updatedAt', {
    render: getDateFormattedCell,
    width: 160
  }),
  createdAtColumn: createColumnDefinition<TreatmentMethod>('Created At', 'createdAt', {
    render: getDateFormattedCell,
    width: 160
  }),
  editColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, row: TreatmentMethod) => {
      return <EditCell record={row} />
    },
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell(),
    sorter: false,
    filtered: false,
  }
};

export const treatmentMethodColumns: any = [
  treatmentMethodColumnsDefinitions.idColumn,
  treatmentMethodColumnsDefinitions.nameColumn,
  treatmentMethodColumnsDefinitions.urlSlugColumn,
  treatmentMethodColumnsDefinitions.updatedAtColumn,
  treatmentMethodColumnsDefinitions.createdAtColumn,
  treatmentMethodColumnsDefinitions.editColumn,
  treatmentMethodColumnsDefinitions.deleteColumn,
];

export const treatmentMethodFiltersConfig: FiltersConfig = {
  [treatmentMethodColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [treatmentMethodColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [treatmentMethodColumnsDefinitions.urlSlugColumn?.key as string]: { filterType: FilterType.SEARCH },
  [treatmentMethodColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [treatmentMethodColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function EditCell({ record }: { record: TreatmentMethod }) {
  const history = useHistory();
  const langId = +getHistorySearchParam(history, 'language-id', 1);
  const params = langId > 1 ? `?language-tab=${langId - 1}` : '';

  return IconButtonRenderer<TreatmentMethod>(`/offers/methods/${record.id}/edit${params}`);
}

function CustomDeleteCell({ record }: { record: TreatmentMethod }) {
  const { deleteMethod } = useMethodState()
  return (
    <DeleteCell
      record={record}
      title="Delete Treatment method"
      deleteAction={deleteMethod}
      onSuccess={() => {
        dispatchRefreshTreatmentMethodsGridEvent();
        message.success('The TreatmentMethod has been successfully deleted');
      }}
      hasNotRelationsContent={() => <div>TreatmentMethod has no any relations.</div>}
      hasRelationsContent={() => {
        return (
          <div style={{}}>
            <div>If you really want to continue, enter your password.</div>
          </div>
        );
      }}
    />
  );
}

function getDeleteCell() {
  return (value: any, record: TreatmentMethod) => {
    return (
      <CustomDeleteCell record={record} />
    );
  };
}
