import { AntGetListParams, CreateUserRequest, GetListParams, Params, UpdateUserRequest } from '@api/types';
import { endFetchDoctorsForSelect, startFetchDoctorsForSelect } from '@storage/doctors/actions';
import {
  endFetchUser,
  endFetchUsers,
  endFetchUsersForSelect,
  endPostUser,
  endRequestPermission,
  endResetGoogleSecret,
  endUpdateUser,
  startFetchUser,
  startFetchUsers,
  startFetchUsersForSelect,
  startPostUser,
  startRequestPermission,
  startResetGoogleSecret,
  startUpdateUser
} from '@storage/users/actions';

import { AxiosRequestConfig } from 'axios';
import { ThunkAction } from '@storage/types';
import { User } from '@modelTypes/user';
import api from '@api';

export function getUsers(params?: AntGetListParams): ThunkAction<Promise<void>> {
  return async (dispatch) => {
    dispatch(startFetchUsers());

    api.getUsers(params)
      .then((data) => {
        dispatch(endFetchUsers(data));
      });
  };
}

export function getUsersForSelect(): ThunkAction<Promise<void>> {
  const params: GetListParams & Params = { page: 1, perPage: 5000 };

  return async (dispatch): Promise<void> => {
    dispatch(startFetchUsersForSelect());

    return api.getUsersForSelect(params)
      .then((data) => {
        dispatch(endFetchUsersForSelect(data));
      });
  };
}

export function getUser(id: number, params?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchUser());

    return api.getUser(id, params)
      .then((data) => {
        dispatch(endFetchUser(data));
      });
  };
}

export function createUser(payload: CreateUserRequest): ThunkAction<Promise<User>> {
  return async (dispatch): Promise<User> => {
    dispatch(startPostUser());

    return api.createUser(payload)
      .then((data) => {
        dispatch(endPostUser(data));

        return data;
      });
  };
}

export function updateUser(id: number, payload: UpdateUserRequest): ThunkAction<Promise<User>> {
  return async (dispatch): Promise<User> => {
    dispatch(startUpdateUser());

    return api.updateUser(id, payload)
      .then((data) => {
        dispatch(endUpdateUser(data));

        return data;
      });
  };
}

export function deleteUser(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (): Promise<void> => {
    return api.deleteUser(id, config);
  };
}

export function resetGoogleSecret(id: number): ThunkAction<Promise<any>> {
  return async (dispatch): Promise<any> => {
    dispatch(startResetGoogleSecret());

    return api.resetGoogleSecret(id)
      .then((data) => {
        dispatch(endResetGoogleSecret(data));

        return data;
      });
  };
}

export function requestPermission(payload: any): ThunkAction<Promise<any>> {
  return async (dispatch): Promise<any> => {
    dispatch(startRequestPermission());

    return api.requestPermission(payload)
      .then((data) => {
        dispatch(endRequestPermission());

        return data;
      });
  };
}
