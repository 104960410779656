import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks';
import { PaginationConfig } from '@components/AntGrid/types';
import { roleColumns, roleFiltersConfig } from '@components/AntGrid/columns/role';
import { RolesState } from '@storage/roles/reducer';
import { getRoles } from '@storage/roles/thunkActions';
import { Role } from '@modelTypes/role';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeGridOptions from "@components/AntGrid/hooks/useChangeGridOptions";

export const [
  refreshRolesGridEvent,
  dispatchRefreshRolesGridEvent
] = makeRefreshGridEvent('onRefreshRolesGrid');

export default function RolesAntGrid() {
  const dispatch = useDispatch();
  const { data, meta, loading } = useSelector<RolesState['grid']>('roles.grid');
  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
    // ...rest.paginationConfig,
  };

  const onFetchData = (params?: AntGetListParams) => {
    dispatch(getRoles(params));
  };

  useRefreshGridEvent(refreshRolesGridEvent, onFetchData);
  useChangeGridOptions('roleAntGrid');

  return (
    <Grid<Role>
      id="rolesGrid"
      onFetch={onFetchData}
      columns={roleColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={roleFiltersConfig}
    />
  );
}