import { ActionType, Actions } from './actions';

import { City } from '@modelTypes/city';
import { ListResponse } from '@api/types';

export interface CitiesState {
  readonly grid: ListResponse<City> & { loading: boolean }
  readonly form: {
    readonly loading: boolean;
    readonly row: City | null;
  };
  readonly selectCity: {
    readonly fetched: boolean;
    readonly loading: boolean;
    readonly countryId: number;
    readonly data: Array<City>;
  };
}

const initialState: CitiesState = {
  grid: {
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  form: {
    loading: false,
    row: null,
  },
  selectCity: {
    fetched: false,
    loading: false,
    countryId: 0,
    data: [],
  }
};

export default function cities(state = initialState, action: Actions): CitiesState {
  switch (action.type) {
    case ActionType.START_FETCH_CITIES:
    // case ActionType.START_DELETE_CITY:
      return {
        ...state,
        grid: {
          ...state.grid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_CITIES:
      return {
        ...state,
        grid: {
          ...action.data,
          loading: false,
        },
      }
    case ActionType.START_FETCH_CITIES_FOR_SELECT:
      return {
        ...state,
        selectCity: {
          ...state.selectCity,
          countryId: action.data,
          fetched: false,
          loading: true,
        }
      }
    case ActionType.END_FETCH_CITIES_FOR_SELECT:
      return {
        ...state,
        selectCity: {
          ...state.selectCity,
          data: action.data.data,
          fetched: true,
          loading: false,
        }
      }
    case ActionType.START_FETCH_CITY:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        }
      }
    case ActionType.END_FETCH_CITY:
    case ActionType.END_POST_CITY:
    case ActionType.END_UPDATE_CITY:
      return {
        ...state,
        form: {
          ...state.form,
          row: action.data,
          loading: false,
        },
      }
    case ActionType.START_POST_CITY:
    case ActionType.START_UPDATE_CITY:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        },
      }
    case ActionType.END_UPDATE_CITY_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.CLEAR_FORM_DATA:
      return {
        ...state,
        form: {
          ...initialState.form,
        }
      }
    default:
      return state;
  }
}