import { clearData, setHasToken } from '@storage/app/actions';

import LocalStorage from '@base/LocalStorage';
import { User } from '../modelTypes/user';
import { appStorage } from '@storage/appStorage';
import moment from 'moment';

interface Tokens {
  access: string;
  refresh: string;
}

export default class Auth {

  public static logout(): void {
    LocalStorage.removeKey('user');
    LocalStorage.removeKey('tokens');
    appStorage.dispatch(setHasToken(false));
    appStorage.dispatch(clearData());
  }

  public static saveTokens(tokens: Tokens): void {
    LocalStorage.setObject('tokens', tokens);
    LocalStorage.setObject('tokensStoredAt', moment());
    appStorage.dispatch(setHasToken(true));
  }

  public static getAccessToken(): string {
    return LocalStorage.getObject('tokens', 'access', '');
  }

  public static getRefreshToken(): string {
    return LocalStorage.getObject('tokens', 'refresh', '');
  }

  public static getUserFromLS = (): User => {
    return LocalStorage.getObject('user');
  };

  public static updateUserInLS = (data: User): void => {
    LocalStorage.setObject('user', data);
  };

}