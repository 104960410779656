import { ActionType, Actions } from './actions';

import { Country } from '@modelTypes/country';
import { ListResponse } from '@api/types';

export interface CountriesState {
  readonly grid: ListResponse<Country> & { loading: boolean }
  readonly form: {
    readonly loading: boolean;
    readonly row: Country | null;
  };
  readonly selectCountry: {
    readonly fetched: boolean;
    readonly loading: boolean;
    readonly search: string | null;
    readonly data: Array<Country>;
  };
}

const initialState: CountriesState = {
  grid: {
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  form: {
    loading: false,
    row: null,
  },
  selectCountry: {
    fetched: false,
    loading: false,
    search: null,
    data: [],
  }
};

export default function countries(state = initialState, action: Actions): CountriesState {
  switch (action.type) {
    case ActionType.START_FETCH_COUNTRIES:
    // case ActionType.START_DELETE_COUNTRY:
      return {
        ...state,
        grid: {
          ...state.grid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_COUNTRIES:
      return {
        ...state,
        grid: {
          ...action.data,
          loading: false,
        },
      }
    case ActionType.START_FETCH_COUNTRIES_FOR_SELECT:
      return {
        ...state,
        selectCountry: {
          ...state.selectCountry,
          fetched: false,
          loading: true,
        }
      }
    case ActionType.END_FETCH_COUNTRIES_FOR_SELECT:
      return {
        ...state,
        selectCountry: {
          ...state.selectCountry,
          data: action.data.data,
          fetched: true,
          loading: false,
        }
      }
    case ActionType.START_POST_COUNTRY:
    case ActionType.START_UPDATE_COUNTRY:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        },
      }
    case ActionType.END_FETCH_COUNTRY:
    case ActionType.END_POST_COUNTRY:
    case ActionType.END_UPDATE_COUNTRY:
      return {
        ...state,
        form: {
          ...state.form,
          row: action.data,
          loading: false,
        },
      }
    case ActionType.END_POST_COUNTRY_ERROR:
    case ActionType.END_UPDATE_COUNTRY_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.CLEAR_SELECT_COUNTRY:
      return {
        ...state,
        selectCountry: {
          ...initialState.selectCountry,
        },
      };
    case ActionType.CLEAR_FORM_DATA:
      return {
        ...state,
        form: {
          ...initialState.form,
        }
      }
    default:
      return state;
  }
}