import {
  endClearCache,
  endFetchLanguages,
  endFetchUser,
  startClearCache,
  startFetchLanguages,
  startFetchUser
} from './actions';

import Auth from '@base/Auth';
import { ClearCacheRequest } from '@api/types';
import { ThunkAction } from '@storage/types';
import api from '@api';
import { getLanguagesForSelect } from '@api/languageApi';

export function getProfile(): ThunkAction<Promise<void>> {
  return async (dispatch) => {
    dispatch(startFetchUser());

    api.getProfile()
      .then((data) => {
        dispatch(endFetchUser(data));
        Auth.updateUserInLS(data);
      });
  };
}

export function clearCache(payload: ClearCacheRequest): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<any> => {
    dispatch(startClearCache());

    return api.clearCache(payload)
      .then((data) => {
        dispatch(endClearCache(data));

        return data;
      })
      .catch((e) => {
        dispatch(endClearCache());

        return e;
      });
  };
}

export function getLanguages(): ThunkAction<Promise<void>> {
  return async (dispatch) => {
    dispatch(startFetchLanguages());

    getLanguagesForSelect({onlyForTranslation: true})
      .then((data) => {
        dispatch(endFetchLanguages(data.data));
      });
  };
}
