import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { PaginationConfig } from '@components/AntGrid/types';
import { HospitalDepartment } from '@modelTypes/hospital';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import {
  hospitalDepartmentColumns,
  hospitalDepartmentFiltersConfig
} from '@components/AntGrid/columns/hospitalDepartment';
import { useHistory, useParams } from 'react-router-dom';
import useChangeGridOptions from '@components/AntGrid/hooks/useChangeGridOptions';
import { useHospitalDepartmentStore } from '@zustandStorage/department/department';
import { getHistorySearchParam } from '@helpers/history';
import { useEffect, useRef } from 'react';

export const [
  refreshDepartmentsGridEvent,
  dispatchRefreshDepartmentsGridEvent
] = makeRefreshGridEvent('onRefreshDepartmentsGrid');

export default function DepartmentsAntGrid() {
  const history = useHistory();
  const firstLoad = useRef<boolean>(false);
  const { hospitalId } = useParams<{ hospitalId: string }>();
  const languageId = getHistorySearchParam(history, 'language-id', 1);
  const { fetchDepartments, grid: { data, meta, loading } } = useHospitalDepartmentStore()

  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
  };

  const onFetchData = (params?: AntGetListParams) => {
    fetchDepartments(parseInt(hospitalId, 10), { ...params, languageId })
      .then(() => {
        firstLoad.current = true;
      })
  };

  useRefreshGridEvent(refreshDepartmentsGridEvent, onFetchData);
  useChangeGridOptions('departmentsAntGrid');

  useEffect(() => {
    if (firstLoad.current) {
      fetchDepartments(parseInt(hospitalId, 10), { languageId })
    }
  }, [firstLoad, languageId]);

  return (
    <Grid<HospitalDepartment>
      id="hospitalsGrid"
      onFetch={onFetchData}
      columns={hospitalDepartmentColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={hospitalDepartmentFiltersConfig}
    />
  );
}