import { GalleriesItem } from '@modelTypes/galleries';
import { useGalleriesDataStore } from '@zustandStorage/galleries/galleries';
import { useParams } from 'react-router-dom';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import { DeleteOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React from 'react';

interface Props {
  record: GalleriesItem;
  entityGallery: string;
}

export function CustomDeleteCell({ record, entityGallery }: Props) {
  const { deleteGalleriesItem, fetchGalleriesItem } = useGalleriesDataStore();
  const { id } = useParams<{ id: string }>();
  return (
    <DeleteCell
      customDeleteIcon={<DeleteOutlined style={{ fontSize: 16, color: '#000' }} />}
      record={record}
      title="Delete Image"
      deleteAction={deleteGalleriesItem}
      onSuccess={() => {
        fetchGalleriesItem({ [entityGallery]: id })
        message.success('Review has been successfully deleted');
      }}
      hasNotRelationsContent={() => <div>Words has no any relations.</div>}
      hasRelationsContent={() => {
        return (
          <div style={{}}>
            <div>If you really want to continue, enter your password.</div>
          </div>
        );
      }}
    />
  );
}