import {
  AntGetListParams, CreateDoctorContentRequest,
  CreateDoctorRequest,
  CreateHospitalContentRequest, DoctorContentRequest,
  GetListParams,
  Params,
  UpdateDoctorRequest
} from '@api/types';
import {
  endFetchDoctor,
  endFetchDoctors,
  endFetchDoctorsForSelect,
  endFetchTranslation,
  endFetchTranslations,
  endPatchTranslation,
  endPostDoctor,
  endUpdateDoctor,
  startDeleteDoctor,
  startFetchDoctor,
  startFetchDoctors,
  startFetchDoctorsForSelect,
  startFetchTranslation,
  startFetchTranslations, startPatchTranslation,
  startPostDoctor,
  startUpdateDoctor,
} from './actions';

import { AxiosRequestConfig } from 'axios';
import { Doctor } from '@modelTypes/doctor';
import { ThunkAction } from '@storage/types';
import api from '@api';

export function getDoctors(params?: AntGetListParams): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchDoctors());

    return api.getDoctors(params)
      .then((data) => {
        dispatch(endFetchDoctors(data));
      });
  };
}

export function getDoctorsForSelect(): ThunkAction<Promise<void>> {
  const params: GetListParams & Params = { page: 1, perPage: 5000 };

  return async (dispatch): Promise<void> => {
    dispatch(startFetchDoctorsForSelect());

    return api.getDoctorsForSelect(params)
      .then((data) => {
        dispatch(endFetchDoctorsForSelect(data));
      });
  };
}

export function getDoctor(id: number, params?: Params): ThunkAction<Promise<Doctor>> {
  return async (dispatch): Promise<Doctor> => {
    dispatch(startFetchDoctor());

    return api.getDoctor(id, params)
      .then((data) => {
        dispatch(endFetchDoctor(data));

        return data;
      });
  };
}

export function createDoctor(payload: CreateDoctorRequest): ThunkAction<Promise<Doctor>> {
  return async (dispatch): Promise<Doctor> => {
    dispatch(startPostDoctor());

    return api.createDoctor(payload)
      .then((data) => {
        dispatch(endPostDoctor(data));

        return data;
      });
  };
}

export function updateDoctor(id: number, payload: UpdateDoctorRequest): ThunkAction<Promise<Doctor>> {
  return async (dispatch): Promise<Doctor> => {
    dispatch(startUpdateDoctor());

    return api.updateDoctor(id, payload)
      .then((data) => {
        dispatch(endUpdateDoctor(data));

        return data;
      });
  };
}

export function deleteDoctor(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startDeleteDoctor());

    return api.deleteDoctor(id, config);
  };
}

export function updateFiltersCache(): ThunkAction<Promise<any>> {
  return async (dispatch) => {
    // dispatch(startPostFrontendDictionary());

    return api.updateDoctorsFiltersCount()
      .then((data) => {
        // dispatch(endPostFrontendDictionary());

        return data;
      });
  };
}

// Doctors Content.
export function getDoctorsContents(params?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchTranslations());

    return api.getDoctorsContents(params)
      .then((data) => {
        dispatch(endFetchTranslations(data));
      });
  };
}

export function getDoctorsContentsBy(relations: DoctorContentRequest, params?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchTranslation());

    return api.getDoctorsContentsBy(relations, params)
      .then((data) => {
        dispatch(endFetchTranslation(data));
      });
  };
}

export function updateOrCreateDoctorsContentsBy(payload?: CreateDoctorContentRequest): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startPatchTranslation());

    return api.updateDoctorContentByPlace(payload)
      .then((data) => {
        dispatch(endPatchTranslation(data));
      });
  };
}