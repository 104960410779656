import { Button, Divider, List } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useOrderState } from '@zustandStorage/orders/orders';
import { OrderStatuses } from '../../../modelTypes/order';
import OrderCard from '@containers/Orders/Ant/components/OrderCard';
import { GetListParams, UpdateOrderRequest } from '@api/types';
import { PageHeader } from '@ant-design/pro-layout';
import QueryString from 'query-string';
import { updateQueryParams } from '@helpers/history';
import OrderListFilters, { Filters, OrderListFiltersProps } from '@containers/Orders/Ant/components/OrderListFilters';

interface RouterParams {
  status: OrderStatuses;
}

const titles: Record<OrderStatuses, string> = {
  all: 'All Orders',
  my: 'My Orders',
  archived: 'Archived Orders',
  paid: 'Paid Orders',
  closed: 'Closed Orders',
};

export default function OrdersIndex() {
  const { id } = useParams<{ id: string }>();
  const { status } = useParams<RouterParams>();
  const history = useHistory();
  const [pagination, setPagination] = useState({ page: 1, perPage: 10 })

  const {
    list: { data, loading, meta, selectedRowsIds },
    selectStatus: { data: statuses },
    fetchOrders,
    fetchStatuses,
    updateOrder,
    setSelectedRow,
  } = useOrderState()

  const fetchData = (filters?: Partial<Filters>) => {
    const params: GetListParams = {};
    params.page = pagination.page;
    params.perPage = pagination.perPage;

    fetchOrders(status, params, filters)
  }

  const updateData = (id: number, data: UpdateOrderRequest & Partial<{ status: number }>) => {
    updateOrder(id, data)
      .then(() => {
        fetchData()
      });
  };

  const handleFilterChange: OrderListFiltersProps['onChange'] = (filters) => {
    updateQueryParams(history, { filters: QueryString.stringify(filters) } as any);
    fetchData(filters);
  };

  const onchangeId = (id: number) => {
    setSelectedRow(id)
  }

  const showDetails = (id: number) => {
    return `/orders/${status}/${id}`
  }

  useEffect(() => {
    fetchData()
    fetchStatuses()
  }, [status, pagination]);


  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => {
          history.push('/')
        }}
        title={`${titles[status]}`}
        extra={[
          <Button
            key="deleteOrder"
            type="primary"
            disabled={!Boolean(selectedRowsIds.length)}
            onClick={() => console.log(selectedRowsIds)}
          >
            DELETE
          </Button>,
          <Button
            key="addOrder"
            type="primary"
            // loading={formSubmitting}
            // onClick={() => form.submit()}
          >
            ADD ORDER
          </Button>
        ]}
      />
      <OrderListFilters onChange={handleFilterChange} statuses={statuses} />
      <Divider />
      <List
        grid={{ gutter: 16, column: 1, xl: 1, xxl: 1 }}
        pagination={{
          onChange: (page, pageSize) => {
            setPagination({ page: page, perPage: pageSize })
          },
          total: meta.total,
          showSizeChanger: true,
        }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <OrderCard
              item={item}
              statusesForSelect={statuses}
              onChange={updateData}
              onChangeId={onchangeId}
              showDetails={showDetails}
            />
          </List.Item>
        )}
      />
    </>

    // <InfiniteScroll
    //   style={{ overflow: 'initial' }}
    //   dataLength={data.length}
    //   next={loadMoreData}
    //   hasMore={meta.nextPageUrl !== null}
    //   loader={<Skeleton avatar active />}
    //   endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
    //   scrollableTarget="scrollableDiv"
    // >
    //   <Spin spinning={loading}>
    //
    //   </Spin>
    // </InfiniteScroll>
  );
}