import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M18.622 14.5386C18.3107 13.6809 17.5167 13.1273 16.5979 13.1273C15.9668 13.1273 15.3941 13.3879 14.9839 13.8613C13.9155 13.4755 12.9626 14.0644 12.5285 14.7765C12.0588 15.4972 11.9485 16.5874 12.7571 17.4042C12.4673 18.158 12.6449 18.9991 13.2338 19.6569C13.6756 20.0987 14.213 20.3321 14.7877 20.3321C15.0403 20.3321 15.3374 20.273 15.6251 20.166C16.24 20.4233 16.9213 20.3743 17.5439 20.0286C18.1146 19.6926 18.4858 19.1567 18.59 18.5184C19.3544 18.2156 19.8311 17.5103 19.8655 16.6292C19.8667 15.6767 19.3905 14.8762 18.622 14.5386ZM17.9338 17.5501C17.6898 17.6198 17.5196 17.8334 17.5196 18.0697C17.5196 18.4908 17.3047 18.8702 16.959 19.0599C16.5646 19.2755 16.1456 19.2755 15.8375 19.0599C15.6649 18.9561 15.4898 18.9561 15.3175 19.0599C14.6617 19.4457 14.1526 19.0493 13.9642 18.8609C13.6792 18.5759 13.4652 18.0421 13.7996 17.4747C13.9362 17.2347 13.8742 16.9239 13.661 16.782C12.8625 16.2235 13.2322 15.4785 13.3643 15.2637C13.586 14.9326 13.9317 14.7348 14.2884 14.7348C14.4688 14.7348 14.6581 14.7875 14.8842 14.9005C15.039 14.9779 15.1744 15.0002 15.298 14.9686C15.429 14.9354 15.5368 14.8393 15.6101 14.6918C15.8104 14.325 16.1768 14.0972 16.5663 14.0972C17.0972 14.0972 17.5212 14.4551 17.6213 14.9877C17.6541 15.1494 17.7842 15.3326 17.9678 15.3699C18.5746 15.5725 18.7578 16.082 18.727 16.4934C18.759 16.9251 18.5268 17.4192 17.9354 17.5501H17.9338Z"
    fill="currentColor" />
  <path
    d="M16.5699 16.6786C16.289 16.6786 16.0515 16.9158 16.0515 17.1966C16.0515 17.4775 16.2886 17.7146 16.5699 17.7146C16.8512 17.7146 17.0879 17.4775 17.0879 17.1966C17.0879 16.9158 16.8601 16.6786 16.5699 16.6786Z"
    fill="currentColor" />
  <path
    d="M15.1424 16.9944C14.8615 16.9944 14.624 17.2315 14.624 17.5124C14.624 17.7933 14.8611 18.0308 15.1424 18.0308C15.4237 18.0308 15.6604 17.7937 15.6604 17.5124C15.6604 17.2311 15.4326 16.9944 15.1424 16.9944Z"
    fill="currentColor" />
  <path
    d="M16.4633 15.6787C16.4633 15.3881 16.2355 15.1607 15.9449 15.1607C15.6543 15.1607 15.4269 15.3979 15.4269 15.6787C15.4269 15.9596 15.664 16.1971 15.9449 16.1971C16.2258 16.1971 16.4633 15.96 16.4633 15.6787Z"
    fill="currentColor" />
  <path
    d="M12.7673 10.7834C12.9022 10.9406 13.0988 11.0306 13.3059 11.0306C13.4753 11.0306 13.6399 10.9698 13.7684 10.8592C13.9123 10.7355 13.9994 10.5633 14.014 10.374C14.0282 10.1847 13.9678 10.0015 13.8446 9.85763C13.5977 9.57067 13.1296 9.5354 12.8426 9.78183C12.5459 10.0372 12.5123 10.4867 12.7673 10.7834ZM13.222 10.2228C13.2459 10.2021 13.2743 10.1916 13.3055 10.1916C13.3432 10.1916 13.3789 10.2078 13.4032 10.2366C13.4255 10.2629 13.4364 10.2962 13.434 10.3302C13.4316 10.3643 13.4157 10.3955 13.3898 10.4178C13.3659 10.4384 13.3375 10.449 13.3063 10.449C13.2686 10.449 13.233 10.4328 13.2082 10.4044C13.162 10.3505 13.1681 10.2694 13.222 10.2228Z"
    fill="currentColor" />
  <path
    d="M12.6773 13.4592C12.8467 13.4592 13.0113 13.3985 13.1398 13.2878C13.2836 13.1642 13.3708 12.9919 13.385 12.8026C13.3992 12.6134 13.3392 12.4302 13.2156 12.2863C12.9691 11.9993 12.501 11.9636 12.2136 12.2105C11.9169 12.4658 11.8833 12.9153 12.1382 13.212C12.2736 13.3693 12.4702 13.4592 12.6773 13.4592ZM12.805 12.7589C12.8025 12.7929 12.7867 12.8241 12.7608 12.8464C12.7369 12.8671 12.7085 12.8776 12.6773 12.8776C12.6396 12.8776 12.6039 12.8614 12.5792 12.833C12.533 12.7791 12.5391 12.6981 12.593 12.6515C12.6169 12.6308 12.6453 12.6202 12.6765 12.6202C12.7142 12.6202 12.7498 12.6365 12.7742 12.6652C12.7965 12.6916 12.8074 12.7248 12.805 12.7589Z"
    fill="currentColor" />
  <path
    d="M10.5939 13.2278C10.403 13.2132 10.2146 13.2724 10.0776 13.3903C9.78087 13.6457 9.74723 14.0952 10.0022 14.3919C10.1376 14.5491 10.3337 14.6391 10.5408 14.6391C10.7103 14.6391 10.8748 14.5783 11.0033 14.4677C11.1472 14.344 11.2343 14.1718 11.2485 13.9825C11.2627 13.7932 11.2027 13.61 11.0791 13.4661C10.9615 13.3291 10.7848 13.242 10.5939 13.2278ZM10.6693 13.9391C10.6669 13.9732 10.6511 14.0044 10.6251 14.0267C10.6012 14.0474 10.5728 14.0579 10.5416 14.0579C10.5039 14.0579 10.4683 14.0417 10.4436 14.0133C10.3974 13.9594 10.4034 13.8783 10.4573 13.8317C10.4813 13.8111 10.5096 13.8005 10.5408 13.8005C10.5785 13.8005 10.6142 13.8167 10.6385 13.8455C10.6608 13.8719 10.6718 13.9051 10.6693 13.9391Z"
    fill="currentColor" />
  <path
    d="M15.8043 9.91194C15.9737 9.91194 16.1383 9.85115 16.2668 9.7405C16.4106 9.61687 16.4978 9.44462 16.512 9.25534C16.5262 9.06605 16.4662 8.88285 16.3426 8.73897C16.225 8.60197 16.0479 8.51523 15.857 8.50064C15.6661 8.48646 15.4776 8.54523 15.3406 8.66317C15.0439 8.91852 15.0103 9.36801 15.2652 9.6647C15.4006 9.82196 15.5972 9.91194 15.8043 9.91194ZM15.9324 9.21156C15.9299 9.24561 15.9141 9.27682 15.8882 9.29911C15.8643 9.31978 15.8359 9.33032 15.8047 9.33032C15.767 9.33032 15.7313 9.31411 15.7066 9.28573C15.6604 9.23183 15.6665 9.15076 15.7204 9.10415C15.7443 9.08348 15.7727 9.07294 15.8039 9.07294C15.8416 9.07294 15.8773 9.08916 15.9016 9.11793C15.9239 9.14428 15.9348 9.17752 15.9324 9.21156Z"
    fill="currentColor" />
  <path
    d="M13.7262 8.66561C13.9702 8.66561 14.2065 8.57806 14.3914 8.41918C14.5985 8.24124 14.7237 7.9936 14.7444 7.72163C14.7651 7.44967 14.6783 7.18581 14.5004 6.9791C14.3062 6.75293 14.0237 6.62323 13.7258 6.62323C13.4818 6.62323 13.2455 6.71078 13.0603 6.87007C12.6335 7.23728 12.5845 7.88335 12.9517 8.31055C13.1458 8.53631 13.4279 8.66561 13.7262 8.66561ZM13.571 7.46466C13.614 7.42778 13.6691 7.40711 13.7254 7.40711C13.7947 7.40711 13.8604 7.4371 13.9054 7.48979C13.9467 7.53803 13.967 7.59923 13.9621 7.66246C13.9573 7.72528 13.9285 7.78284 13.8803 7.82377C13.8373 7.86106 13.7826 7.88133 13.7262 7.88133C13.6569 7.88133 13.5913 7.85133 13.5463 7.79905C13.4612 7.69975 13.4721 7.54978 13.571 7.46466Z"
    fill="currentColor" />
  <path
    d="M5.30055 12.4451C5.88663 13.0175 6.61295 12.8168 6.97166 12.5327C7.36238 12.2628 7.82768 12.6539 7.85605 12.6782C7.87834 12.6981 7.90266 12.7159 7.92577 12.7297C8.54833 13.1139 9.52027 12.8805 9.98111 12.6016C11.1103 11.9174 11.9116 10.6569 12.0725 9.31167C12.2192 8.08398 11.8378 6.94869 10.9988 6.11456C10.0293 5.15032 9.67672 4.23917 9.61795 3.83913V0.513532C9.61795 0.230218 9.38732 0 9.10441 0H8.52309C8.24622 0 8.01916 0.219406 8.00966 0.496112C7.99969 0.786577 8.23246 1.02747 8.52309 1.02747H8.59048V3.87682L8.5921 3.90033C8.60304 4.04138 8.74288 5.32095 10.2741 6.84372C10.8841 7.45007 11.1606 8.28339 11.0523 9.19048C10.9283 10.2289 10.3139 11.1992 9.44893 11.7233C9.16156 11.8972 8.67235 11.9495 8.48833 11.868C8.06843 11.5247 7.17836 11.1263 6.36125 11.7071L6.3495 11.716C6.29721 11.7569 6.15859 11.8664 5.99322 11.684C5.95999 11.6479 5.9227 11.6175 5.88257 11.5932C5.78165 11.5316 4.86969 11.003 3.93545 11.4007C3.24722 11.6933 2.77787 12.3884 2.54036 13.4665C2.30974 14.5135 2.37702 15.5636 2.44228 16.5789L2.45605 16.7934C2.46254 16.8939 2.47024 17.0009 2.47875 17.1131C2.57157 18.355 2.59022 19.4935 1.81323 19.7777C1.49952 19.8924 1.0018 19.9232 0.600941 19.8531C0.322896 19.806 0.0566 19.9933 0.0079624 20.2705C-0.0159511 20.4055 0.0144514 20.5421 0.0930822 20.6544C0.171713 20.7666 0.289657 20.8416 0.425032 20.8655C0.626067 20.9008 0.838454 20.9182 1.05611 20.9182C1.46223 20.9182 1.85619 20.8558 2.16585 20.7427C3.73725 20.168 3.59093 18.2079 3.50339 17.0365C3.49528 16.9287 3.48758 16.8258 3.4815 16.7285L3.46772 16.5125C3.40693 15.5685 3.3445 14.5921 3.54392 13.687C3.65497 13.1828 3.88276 12.5408 4.3359 12.3467C4.71487 12.1841 5.16315 12.3771 5.30055 12.4451Z"
    fill="currentColor" />
  <path
    d="M11.9049 16.4704C11.9078 16.205 11.7076 15.9832 11.4455 15.9416C10.1586 15.7375 8.68584 15.1838 7.61044 13.8398C7.49452 13.6951 7.31496 13.621 7.12852 13.642C6.76414 13.6846 6.52582 14.1317 6.46097 14.2682C6.13509 14.9605 6.19062 15.7841 6.2352 16.4464L6.23683 16.4731C6.25669 16.7694 6.2952 17.0568 6.33249 17.3365C6.41801 17.9761 6.49907 18.5804 6.33492 19.1681C6.11362 19.9605 5.60941 20.6094 4.87701 21.0447C4.75906 21.1148 4.67557 21.2267 4.64192 21.3596C4.60828 21.4925 4.62814 21.6308 4.69826 21.7487C4.79026 21.9035 4.95969 22 5.14006 22C5.23287 22 5.32366 21.9749 5.40229 21.9279C6.36613 21.3547 7.03043 20.4963 7.32429 19.4449C7.54518 18.6541 7.44669 17.9153 7.35063 17.1995C7.31577 16.9376 7.27929 16.6673 7.26146 16.4046L7.25943 16.3767C7.23025 15.9458 7.1958 15.4315 7.28619 15.0169C8.29308 16.0212 9.74627 16.6909 11.3397 16.958C11.6381 17.008 11.9015 16.7729 11.9049 16.4704Z"
    fill="currentColor" />
  <path
    d="M20.7171 9.59944C20.6688 7.97819 20.1533 6.70064 19.1846 5.80206C17.6833 4.409 15.6851 4.45561 15.4622 4.46574L15.4435 4.46696C14.8238 4.53303 14.35 4.43048 14.0351 4.16257C13.6764 3.85777 13.6099 3.39734 13.601 3.32114V1.02747C13.8823 1.02747 14.1127 0.801152 14.1161 0.519815C14.1194 0.23373 13.8885 0 13.6024 0H13.087C12.8037 0 12.5735 0.230623 12.5735 0.513937V3.34708L12.5739 3.36207C12.5788 3.45854 12.6408 4.32186 13.3631 4.94077C13.8985 5.39918 14.6264 5.58441 15.5282 5.49119C15.6474 5.48835 17.3132 5.46727 18.4858 6.55554C19.2462 7.26079 19.6515 8.30285 19.6912 9.65213L19.6924 9.67362C19.7047 9.79548 19.8545 11.5398 18.7697 13.1927C18.6087 13.4379 18.6645 13.7763 18.9147 13.9294C19.1357 14.0648 19.4238 14.0023 19.5625 13.7834C19.7551 13.4794 20.008 13.0608 20.1338 12.7702C20.8184 11.1907 20.7312 9.77494 20.7171 9.59944Z"
    fill="currentColor" />
  <path
    d="M18.0915 11.2847C18.1364 11.2973 18.1835 11.3038 18.2305 11.3038C18.4591 11.3038 18.6625 11.1498 18.7246 10.9297C19.2977 8.89907 17.9751 7.18865 17.9188 7.11691C17.7441 6.89439 17.4203 6.85589 17.1973 7.03098C16.9748 7.20608 16.9363 7.52992 17.1106 7.75163C17.1211 7.76541 18.1636 9.13577 17.736 10.6508C17.659 10.9232 17.8183 11.2073 18.0915 11.2847Z"
    fill="currentColor" />
</svg>`;

export default { id: '251-gastrointestinal-oncology', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
