import { Box, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';

import ApartmentIcon from '@mui/icons-material/Apartment';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BadgeIcon from '@mui/icons-material/Badge';
import { ChartBar as ChartBarIcon } from '@components/icons/chart-bar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NavItem from './NavItem';
import PeopleIcon from '@mui/icons-material/People';
import TagIcon from '@mui/icons-material/Tag';
import { Users as UsersIcon } from '@components/icons/users';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';

const items = [
  {
    key: 'dashboard',
    href: '/',
    icon: (<ChartBarIcon fontSize="small" />),
    title: 'Dashboard'
  },
  {
    key: 'all-orders',
    icon: (<FormatListNumberedIcon fontSize="small" />),
    title: 'Orders',
    items: [
      {
        key: 'orders',
        href: '/orders/all',
        // allowedHrefs: ['/blog/articles/create', '/blog/articles/:id/edit'],
        icon: '-',
        title: 'Orders',
      },
      {
        key: 'my-orders',
        href: '/orders/my',
        // allowedHrefs: ['/blog/articles/create', '/blog/articles/:id/edit'],
        icon: '-',
        title: 'My orders',
      },
      {
        key: 'archived-orders',
        href: '/orders/archived',
        // allowedHrefs: ['/blog/articles/create', '/blog/articles/:id/edit'],
        icon: '-',
        title: 'Archived orders',
      },
      {
        key: 'paid-orders',
        href: '/orders/paid',
        // allowedHrefs: ['/blog/articles/create', '/blog/articles/:id/edit'],
        icon: '-',
        title: 'Paid orders',
      },
      {
        key: 'closed-orders',
        href: '/orders/closed',
        // allowedHrefs: ['/blog/articles/create', '/blog/articles/:id/edit'],
        icon: '-',
        title: 'Closed orders',
      },
    ],
  },
  {
    key: 'tasks',
    icon: (<FactCheckIcon fontSize="small" />),
    title: 'Tasks',
    items: [
      {
        key: 'orders',
        href: '/tasks/all',
        // allowedHrefs: ['/blog/articles/create', '/blog/articles/:id/edit'],
        icon: '-',
        title: 'Task list',
      },
      {
        key: 'my-orders',
        href: '/tasks/my',
        // allowedHrefs: ['/blog/articles/create', '/blog/articles/:id/edit'],
        icon: '-',
        title: 'My tasks',
      },
    ],
  },
  {
    key: 'blog',
    icon: (<ContentCopyIcon fontSize="small" />),
    title: 'Content (Blog)',
    items: [
      {
        key: 'articles',
        href: '/blog/articles',
        allowedHrefs: ['/blog/articles/create', '/blog/articles/:id/edit'],
        icon: (<ContentPasteIcon fontSize="small" />),
        title: 'Articles'
      },
      {
        key: 'disease-guides',
        href: '/blog/disease-guides',
        allowedHrefs: ['/blog/disease-guides/create', '/blog/disease-guides/:id/edit'],
        icon: (<AutoStoriesIcon fontSize="small" />),
        title: 'Disease guides'
      },
      {
        key: 'treatment-guides',
        href: '/blog/treatment-guides',
        allowedHrefs: ['/blog/treatment-guides/create', '/blog/treatment-guides/:id/edit'],
        icon: (<EventAvailableIcon fontSize="small" />),
        title: 'Treatment guides'
      },
    ],
  },
  {
    key: 'content',
    icon: (<FormatSizeIcon fontSize="small" />),
    title: 'Content (Hospitals)',
    items: [
      {
        key: 'hospitals',
        href: '/hospitals',
        allowedHrefs: [
          '/hospitals/create', '/hospitals/:id/edit', '/hospitals/:hospitalId/departments',
          '/hospitals/:hospitalId/departments/create', '/hospitals/:hospitalId/departments/:id/edit'
        ],
        icon: (<ApartmentIcon fontSize="small" />),
        title: 'Hospitals'
      },
      {
        key: 'doctors',
        href: '/doctors',
        allowedHrefs: ['/doctors/create', '/doctors/:id/edit'],
        icon: (<PeopleIcon fontSize="small" />),
        title: 'Doctors'
      },
      {
        key: 'tags',
        href: '/tags',
        // allowedHrefs: ['/hospitals/create'],
        icon: (<TagIcon fontSize="small" />),
        title: 'Hospital Tags'
      },
      {
        key: 'certificates',
        href: '/certificates',
        allowedHrefs: ['/certificates/create', '/certificates/:id/edit'],
        icon: (<HealthAndSafetyIcon fontSize="small" />),
        title: 'Certificates'
      },
      {
        key: 'countries',
        href: '/countries',
        // allowedHrefs: ['/hospitals/create'],
        icon: (<LocationOnIcon fontSize="small" />),
        title: 'Countries'
      },
      {
        key: 'cities',
        href: '/cities',
        allowedHrefs: ['/cities/create', '/cities/:id/edit'],
        icon: (<LocationCityIcon fontSize="small" />),
        title: 'Cities'
      },
    ]
  },
  {
    key: 'search_engine',
    icon: (<ContentPasteSearchIcon fontSize="small" />),
    title: 'Search Engine',
    items: [
      {
        key: 'diseases',
        href: '/search-engine/diseases',
        allowedHrefs: [
          '/search-engine/disease/create', '/search-engine/disease/:id/edit',
        ],
        icon: '-',
        title: 'Diseases',
      },
      {
        key: 'treatments',
        href: '/search-engine/treatments',
        allowedHrefs: [
          '/search-engine/treatment/create', '/search-engine/treatment/:id/edit',
        ],
        icon: '-',
        title: 'Treatments',
      },
      {
        key: 'diagnostics',
        href: '/search-engine/diagnostics',
        allowedHrefs: [
          '/search-engine/diagnostics/create', '/search-engine/diagnostics/:id/edit',
        ],
        icon: '-',
        title: 'Diagnostics',
      },
      {
        key: 'synonyms',
        href: '/search-engine/synonyms',
        allowedHrefs: [
          '/search-engine/synonyms/create', '/search-engine/synonyms/:id/edit',
        ],
        icon: '-',
        title: 'Synonyms',
      },
    ],
  },
  {
    key: 'users',
    // href: '/users',
    icon: (<UsersIcon fontSize="small" />),
    title: 'Users',
    items: [
      {
        key: 'roles',
        href: '/roles',
        allowedHrefs: ['/roles/create', '/roles/:id/edit'],
        icon: '-',
        title: 'Roles'
      },
      // {
      //   key: 'permissions',
      //   href: '/permissions',
      //   allowedHrefs: ['/permissions/create', '/permissions/:id/edit'],
      //   icon: '-',
      //   title: 'Permissions'
      // },
      {
        key: 'users',
        href: '/users',
        allowedHrefs: ['/users/create', '/users/:id/edit'],
        icon: '-',
        title: 'Users list'
      },
    ],
  },
  {
    key: 'templates',
    // href: '/users',
    icon: (<AutoAwesomeMosaicIcon fontSize="small" />),
    title: 'Templates',
    items: [
      {
        key: 'emails',
        href: '/templates/emails',
        // allowedHrefs: ['/roles/create', '/roles/:id/edit'],
        icon: '-',
        title: 'Emails'
      },
      {
        key: 'legal',
        href: '/templates/legals',
        // allowedHrefs: ['/users/create', '/users/:id/edit'],
        icon: '-',
        title: 'Legal'
      },
    ],
  },
  {
    key: 'authors',
    href: '/authors',
    allowedHrefs: ['/authors/create', '/authors/:id/edit'],
    icon: (<BadgeIcon fontSize="small" />),
    title: 'AiroMedical team',
  },
  {
    // href: '/frontend-languages',
    key: 'fronted_languages',
    icon: (<ListAltIcon fontSize="small" />),
    title: 'Fronted Languages',
    items: [
      {
        key: 'fronted_languages__layout',
        href: '/frontend-languages/layout',
        icon: '-',
        title: 'Layout'
      },
      {
        key: 'fronted_languages__home',
        href: '/frontend-languages/main',
        icon: '-',
        title: 'Main'
      },
      {
        key: 'fronted_languages__services',
        href: '/frontend-languages/services',
        icon: '-',
        title: 'Services'
      },
      {
        key: 'fronted_languages__search',
        href: '/frontend-languages/search',
        icon: '-',
        title: 'Search'
      },
      {
        key: 'fronted_languages__blogs',
        href: '/frontend-languages/blogs',
        icon: '-',
        title: 'Blog'
      },
      {
        key: 'fronted_languages__how-it-works',
        href: '/frontend-languages/how-it-works',
        icon: '-',
        title: 'How it works'
      },
      {
        key: 'fronted_languages__about-us',
        href: '/frontend-languages/about-us',
        icon: '-',
        title: 'About us'
      },
      {
        key: 'fronted_languages__components',
        href: '/frontend-languages/components',
        icon: '-',
        title: 'Components'
      },
      {
        key: 'fronted_languages__404',
        href: '/frontend-languages/not-found',
        icon: '-',
        title: '404'
      },
    ]
  },
];

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function DashboardSidebar({
  open,
  onClose
}: Props) {
  // // const router = useRouter();
  // @ts-ignore
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up(1920), {
    defaultMatches: true,
    noSsr: true
  });

  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <div>
          <Box sx={{ p: 3 }}>
            {/*<NextLink*/}
            {/*  href="/"*/}
            {/*  passHref*/}
            {/*>*/}
            {/*  <a>*/}
            {/*    <Logo*/}
            {/*      sx={{*/}
            {/*        height: 42,*/}
            {/*        width: 42*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </a>*/}
            {/*</NextLink>*/}
          </Box>
          <Box sx={{ px: 2 }}>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.04)',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                px: 3,
                py: '11px',
                borderRadius: 1
              }}
            >
              <div>
                <Typography
                  color="inherit"
                  variant="subtitle1"
                >
                  AiroCRM
                </Typography>
              </div>
            </Box>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: '#2D3748',
            my: 3
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          {items.map((item) => (
            <NavItem uniqKey={item.key as string} onClose={onClose} {...item} />
          ))}
        </Box>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color: '#FFFFFF',
            width: 270
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 270
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};