import 'react-image-crop/dist/ReactCrop.css';

import React, { useEffect, useRef, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';

import { Modal, Spin } from 'antd';

export interface UploadPhotoModalProps {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onSave: (canvas: HTMLCanvasElement) => void;
  readonly loading?: boolean;
  readonly title?: string;
  readonly circularCrop?: boolean;
  readonly aspect?: number;
  readonly minWidth?: number;
  readonly minHeight?: number;
  readonly maskClosable?: boolean;
}

export interface UploadPhotoModalScale {
  readonly x: number;
  readonly y: number;
}

export default function UploadPhotoModal(
  {
    open,
    onClose,
    onSave,
    title = 'Upload Image',
    loading = false,
    circularCrop = false,
    aspect,
    minWidth,
    minHeight,
    maskClosable = false,
  }: UploadPhotoModalProps
): JSX.Element | null {
  const [upImg, setUpImg] = useState<string>();
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState<Partial<Crop>>({ unit: 'px', width: minWidth ? minWidth / 2 : 100, aspect });
  const [scale, setScale] = useState<UploadPhotoModalScale>({ x: 1, y: 1 });
  // const [crop, setCrop] = useState<Partial<Crop>>({ unit: 'px', width: 560 / 2, aspect: 560 / 330 });
  const [completedCrop, setCompletedCrop] = useState<Crop>();

  const onSelectFile = (e: any) => {
    if (e && e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result as string));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = (img: HTMLImageElement) => {
    // @ts-ignore
    imgRef.current = img;

    return true;
  }
  
  const handleOnSave = () => {
    const canvas = previewCanvasRef.current as any;

    if (!completedCrop || !canvas) {
      return;
    }

    onSave(canvas);
    onClose();

    // canvas.toBlob(
    //   (blob: Blob) => {
    //     console.log('default, ', 'blob --- ', blob);
    //     // var newImg = document.createElement('img'),
    //     //   url = URL.createObjectURL(blob);
    //     //
    //     // newImg.onload = function() {
    //     //   // no longer need to read the blob so it's revoked
    //     //   URL.revokeObjectURL(url);
    //     // };
    //     //
    //     // const previewUrl = window.URL.createObjectURL(blob);
    //     //
    //     // const anchor = document.createElement('a');
    //     // anchor.download = 'cropPreview.jpeg';
    //     // anchor.href = URL.createObjectURL(blob);
    //     // anchor.click();
    //     //
    //     // window.URL.revokeObjectURL(previewUrl);
    //   },
    //   'image/jpeg',
    //   1
    // );
    
      console.log('default, ', 'handleOnSave --- ', previewCanvasRef.current);
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current as HTMLCanvasElement;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    setScale({ x: scaleX, y: scaleY });
    
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    if (ctx) {
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
    }
  }, [completedCrop]);

  return (
    <Modal
      title={title}
      open={open}
      okText="Save"
      cancelText="Cancel"
      onOk={() => handleOnSave()}
      onCancel={onClose}
      maskClosable={maskClosable}
    >
      <Spin spinning={loading}>
        {!upImg && (
          <div>
            <input type="file" accept="image/*" onChange={onSelectFile} />
          </div>
        )}
        <ReactCrop
          src={upImg as string}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(c, p) => setCrop(p)}
          onComplete={(c) => setCompletedCrop(c)}
          circularCrop={circularCrop}
          {...(!!minWidth && { minWidth: minWidth / scale.x })}
          {...(!!minHeight && { minHeight: minHeight / scale.x })}
        />
        <div>
          <canvas
            ref={previewCanvasRef}
            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
            style={{
              display: 'none',
              width: Math.round(completedCrop?.width ?? 0),
              height: Math.round(completedCrop?.height ?? 0)
            }}
          />
        </div>
        {/*<button*/}
        {/*  type="button"*/}
        {/*  disabled={!completedCrop?.width || !completedCrop?.height}*/}
        {/*  onClick={() =>*/}
        {/*    generateDownload(previewCanvasRef.current, completedCrop)*/}
        {/*  }*/}
        {/*>*/}
        {/*  Download cropped image*/}
        {/*</button>*/}
      </Spin>
    </Modal>
  );
}
