import { ActionType, Actions } from './actions';
import { Blog, BlogTypes } from '@modelTypes/blog';

import { ListResponse } from '@api/types';

export interface BlogsState {
  // readonly hospital: {
  //   readonly row: Hospital | null;
  //   readonly loading: boolean;
  // }
  readonly grid: ListResponse<Blog> & {
    readonly type: BlogTypes | null;
    readonly loading: boolean;
  }
  readonly form: {
    readonly type: BlogTypes | null;
    readonly loading: boolean;
    readonly row: Blog | null;
  };
  readonly selectBlogs: {
    readonly fetched: boolean,
    readonly loading: boolean;
    readonly search: string | null;
    readonly data: Array<Blog>;
  };
}

const initialState: BlogsState = {
  // hospital: {
  //   loading: false,
  //   row: null,
  // },
  grid: {
    type: null,
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  form: {
    type: null,
    loading: false,
    row: null,
  },
  selectBlogs: {
    fetched: false,
    loading: false,
    search: null,
    data: [],
  }
};

export default function blogs(state = initialState, action: Actions): BlogsState {
  switch (action.type) {
    case ActionType.START_FETCH_BLOGS:
      return {
        ...state,
        grid: {
          ...state.grid,
          type: null,
          loading: true,
        }
      }
    case ActionType.END_FETCH_BLOGS:
      return {
        ...state,
        grid: {
          ...action.data,
          loading: false,
        },
      }
    case ActionType.START_FETCH_BLOG:
    case ActionType.START_FETCH_ACCESS_KEY:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        }
      }
    case ActionType.END_FETCH_BLOG:
    case ActionType.END_POST_BLOG:
    case ActionType.END_PATCH_BLOG:
      return {
        ...state,
        form: {
          ...state.form,
          row: action.data,
          loading: false,
        },
      }
    case ActionType.START_POST_BLOG:
    case ActionType.START_PATCH_BLOG:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        },
      }
    case ActionType.END_POST_BLOG_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.END_PATCH_BLOG_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.CLEAR_FORM_DATA:
      return {
        ...state,
        form: {
          ...initialState.form,
        }
      }
    case ActionType.START_FETCH_BLOGS_FOR_SELECT:
      return {
        ...state,
        selectBlogs: {
          ...state.selectBlogs,
          fetched: false,
          loading: true,
        }
      }
    case ActionType.END_FETCH_BLOGS_FOR_SELECT:
      return {
        ...state,
        selectBlogs: {
          ...state.selectBlogs,
          data: action.data.data,
          fetched: true,
          loading: false,
        }
      }
    case ActionType.CLEAR_BLOGS_FOR_SELECT:
      return {
        ...state,
        selectBlogs: {
          ...initialState.selectBlogs,
        },
      };
    default:
      return state;
  }
}