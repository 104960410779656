import { Modal, Radio, RadioChangeEvent } from 'antd';
import { EdgeType } from '../CustomEdge/CustomEdge';

interface Props {
  open: boolean;
  onClose(): void;
  onSelect(edgeType: EdgeType): void;
}

export default function SelectEdgeTypeModal({ open, onClose, onSelect }: Props) {
  const handleSelect = (e: RadioChangeEvent) => {
    onSelect(e.target.value);
    onClose();
  };

  return (
    <Modal open={open} onCancel={onClose} title="Edge type" footer={null}>
      <p>Select edge type:</p>
      <Radio.Group value={undefined} onChange={handleSelect}>
        <Radio.Button value={EdgeType.straight}>Straight</Radio.Button>
        <Radio.Button value={EdgeType.step}>Step</Radio.Button>
        <Radio.Button value={EdgeType.bezier}>Bezier</Radio.Button>
      </Radio.Group>
    </Modal>
  );
}
