import client from './client';
import { ListResponse, Params, UpdateReviewRequest, UpdateReviewResponse } from '@api/types';
import { Review } from '@modelTypes/review';
import { AxiosRequestConfig } from 'axios';

const GET_REVIEWS_URI = '/reviews';
const PATCH_REVIEWS_URI= (id : number) =>  `/reviews/${id}`
const DELETE_REVIEWS_URI= (id : number) =>  `/reviews/${id}`

export async function getReviews(params?: Params) {
  return client.get<ListResponse<Review>>(GET_REVIEWS_URI, params);
}

export async function updateReviewsStatus(id: number, payload?: UpdateReviewRequest) {
  return client.patch<UpdateReviewRequest, UpdateReviewResponse>(PATCH_REVIEWS_URI(id), payload);
}

export async function deleteReview(id: number, payload?: AxiosRequestConfig) {
  return client.del(DELETE_REVIEWS_URI(id), payload);
}