export interface GridDoctor {
  id: number;
  pubmedUrl: string | null;
  researchgateUrl: string | null;
  semanticscholarUrl: string | null;
  name: string;
  firstName: string;
  secondName: string;
  title: string;
  postNominals: string;
  specialization: string;
  imgPath: string;
}

export interface Service {
  id: number;
  name?: string;
  urlSlug?: string;
  svg?: string;
}

export interface Currency {
  id: number;
  code: string;
  symbol: string;
  rate: number;
  name: string;
  countryCode: string;
}

export enum PriceType {
  FOLDER = 100,
  PRICE = 200,
}

export interface GridClinic {
  id: number;
  title: string;
  imgPath: string;
}

export interface Clinic extends GridClinic {
  googleReviewUrl: string | null;
  site: string | null;
  foundedIn: number | null;
  patientsCount: number | null;
  bedsCount: number | null;
  services: Service[] | null;
  description?: string;
}

export interface Department {
  id: number;
  name: string;
  shortName: string;
  doctors?: GridDoctor[];
  mainDoctor?: Partial<GridDoctor>;
  href?: string;
}

export interface Price {
  id: number;
  name: string;
  type: PriceType;
  price?: number | null;
  parentId?: number | null;
  currency?: Currency;
  order?: number;
}

export interface Certificate {
  id: number;
  name: string;
  imgPath?: string;
}