import { Certificate } from '@modelTypes/certificate';
import { ListResponse } from '@api/types';
import { createAction } from '../helpers';

export enum ActionType {
  START_FETCH_CERTIFICATES = 'CERTIFICATES__START_FETCH_CERTIFICATES',
  END_FETCH_CERTIFICATES = 'CERTIFICATES__END_FETCH_CERTIFICATES',

  START_FETCH_CERTIFICATES_FOR_SELECT = 'CERTIFICATES__START_FETCH_CERTIFICATES_FOR_SELECT',
  END_FETCH_CERTIFICATES_FOR_SELECT = 'CERTIFICATES__END_FETCH_CERTIFICATES_FOR_SELECT',

  START_FETCH_CERTIFICATE = 'CERTIFICATES__START_FETCH_CERTIFICATE',
  END_FETCH_CERTIFICATE = 'CERTIFICATES__END_FETCH_CERTIFICATE',

  START_POST_CERTIFICATE = 'CERTIFICATES__START_POST_CERTIFICATE',
  END_POST_CERTIFICATE = 'CERTIFICATES__END_POST_CERTIFICATE',
  END_POST_CERTIFICATE_ERROR = 'CERTIFICATES__END_POST_CERTIFICATE_ERROR',

  START_UPDATE_CERTIFICATE = 'CERTIFICATES__START_UPDATE_CERTIFICATE',
  END_UPDATE_CERTIFICATE = 'CERTIFICATES__END_UPDATE_CERTIFICATE',
  END_UPDATE_CERTIFICATE_ERROR = 'CERTIFICATES__END_UPDATE_CERTIFICATE_ERROR',

  START_DELETE_CERTIFICATE = 'CERTIFICATES__START_DELETE_CERTIFICATE',
  END_DELETE_CERTIFICATE = 'CERTIFICATES__END_DELETE_CERTIFICATE',

  CLEAR_FORM_DATA = 'CERTIFICATES__CLEAR_FORM_DATA',
}

export const startFetchCertificates = createAction<ActionType.START_FETCH_CERTIFICATES>(ActionType.START_FETCH_CERTIFICATES);
export const endFetchCertificates = createAction<ActionType.END_FETCH_CERTIFICATES, ListResponse<Certificate>>(ActionType.END_FETCH_CERTIFICATES);

export const startFetchCertificatesForSelect = createAction<ActionType.START_FETCH_CERTIFICATES_FOR_SELECT>(ActionType.START_FETCH_CERTIFICATES_FOR_SELECT);
export const endFetchCertificatesForSelect = createAction<ActionType.END_FETCH_CERTIFICATES_FOR_SELECT, ListResponse<Certificate>>(ActionType.END_FETCH_CERTIFICATES_FOR_SELECT);

export const startFetchCertificate = createAction<ActionType.START_FETCH_CERTIFICATE>(ActionType.START_FETCH_CERTIFICATE);
export const endFetchCertificate = createAction<ActionType.END_FETCH_CERTIFICATE, Certificate>(ActionType.END_FETCH_CERTIFICATE);

export const startPostCertificate = createAction<ActionType.START_POST_CERTIFICATE>(ActionType.START_POST_CERTIFICATE);
export const endPostCertificate = createAction<ActionType.END_POST_CERTIFICATE, Certificate>(ActionType.END_POST_CERTIFICATE);
export const endPostCertificateError = createAction<ActionType.END_POST_CERTIFICATE_ERROR>(ActionType.END_POST_CERTIFICATE_ERROR);

export const startUpdateCertificate = createAction<ActionType.START_UPDATE_CERTIFICATE>(ActionType.START_UPDATE_CERTIFICATE);
export const endUpdateCertificate = createAction<ActionType.END_UPDATE_CERTIFICATE, Certificate>(ActionType.END_UPDATE_CERTIFICATE);
export const endUpdateCertificateError = createAction<ActionType.END_UPDATE_CERTIFICATE_ERROR>(ActionType.END_UPDATE_CERTIFICATE_ERROR);

export const startDeleteCertificate = createAction<ActionType.START_DELETE_CERTIFICATE>(ActionType.START_DELETE_CERTIFICATE);
export const endDeleteCertificate = createAction<ActionType.END_DELETE_CERTIFICATE>(ActionType.END_DELETE_CERTIFICATE);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

export type Actions =
  | ReturnType<typeof startFetchCertificates>
  | ReturnType<typeof endFetchCertificates>
  | ReturnType<typeof startFetchCertificatesForSelect>
  | ReturnType<typeof endFetchCertificatesForSelect>
  | ReturnType<typeof startFetchCertificate>
  | ReturnType<typeof endFetchCertificate>
  | ReturnType<typeof startPostCertificate>
  | ReturnType<typeof endPostCertificate>
  | ReturnType<typeof endPostCertificateError>
  | ReturnType<typeof startUpdateCertificate>
  | ReturnType<typeof endUpdateCertificate>
  | ReturnType<typeof endUpdateCertificateError>
  | ReturnType<typeof startDeleteCertificate>
  | ReturnType<typeof endDeleteCertificate>
  | ReturnType<typeof clearFormData>;