export function getUrlFromMask(mask: string, params?: Record<string, string | number>): string {
  try {
    let match = '';
    let paramsLength = params ? Object.keys(params).length : 0;

    if (!paramsLength) {
      return mask;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const key in params) {
      if (params[key]) {
        match += `:${key}\\??`;
        paramsLength -= 1;
        if (paramsLength) match += '|';
      }
    }
    
    // @ts-ignore
    const url = mask.replace(new RegExp(match, 'gi'), (i) => params[i.replace(/[:?]/g, '')]);

    return url.replace(new RegExp(/\/+/g, 'g'), '/');
  } catch (e) {
    console.warn(e);
  }

  return '';
}
