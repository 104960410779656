import {
  AntGetListParams,
  CreateUserRequest,
  CreateUserResponse,
  GetListParams,
  ListResponse,
  Params,
  UpdateUserRequest,
  UpdateUserResponse
} from '@api/types';

import { AxiosRequestConfig } from 'axios';
import { User } from "../modelTypes/user";
import client from "./client";
import { getRestUri } from '@api/helpers';

const getUri = getRestUri('users');
const ROOT_URI = getUri('');
const REQUEST_PERMISSION_URI = 'users/request-permission';
const RESET_GOOGLE_SECRET_URI = (id: number) => `users/${id}/reset-google-secret`;
const GET_USERS_FOR_SELECT_URI = 'users/select';

export async function getUsers(params?: AntGetListParams) {
  return client.get<ListResponse<User>>(ROOT_URI.GET(), params);
}

export async function getUsersForSelect(params?: GetListParams) {
  return client.get<ListResponse<User>>(GET_USERS_FOR_SELECT_URI, params);
}

export async function getUser(id: number, params?: Params) {
  return client.get<User>(ROOT_URI.SHOW({ id }), params);
}

export async function createUser(payload: CreateUserRequest) {
  return client.post<CreateUserRequest, CreateUserResponse>(ROOT_URI.POST(), payload);
}

export async function updateUser(id: number, payload: UpdateUserRequest) {
  return client.patch<UpdateUserRequest, UpdateUserResponse>(ROOT_URI.PATCH({ id }), payload);
}

export async function deleteUser(id: number, payload?: AxiosRequestConfig) {
  return client.del(ROOT_URI.DELETE({ id }), payload);
}

export async function resetGoogleSecret(id: number) {
  return client.post<any, any>(RESET_GOOGLE_SECRET_URI(id));
}

export async function requestPermission(payload: any) {
  return client.post<any, any>(REQUEST_PERMISSION_URI, payload);
}
