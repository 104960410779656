import { useCallback, useEffect, useState } from 'react';
import styles from './SelectPriceFolderModal.module.scss';
import { Price, PriceType } from '../../types';
import { getClinicPrices } from '@api/hospitalApi';
import { useParams } from 'react-router-dom';
import { Input, Modal, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';

interface Props {
  close: () => void;
  open: boolean;
  handlePriceFolderSelected: (folderId: number | null) => void;
}

export default function SelectPriceFolderModal(props: Props) {
  const { open, close, handlePriceFolderSelected } = props;

  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [folders, setFolders] = useState<Price[]>([]);
  const params = useParams<{ id: string }>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadFolders = useCallback(
    debounce(async (query: string) => {
      if (isLoading || !open) return;

      try {
        setIsLoading(true);
        const data = await getClinicPrices(+params.id, { type: PriceType.FOLDER, search: query });

        setFolders(data.data);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }, 350),
    [isLoading, open]
  );

  const handleSelectFolder = (folderId: number | null) => {
    handlePriceFolderSelected(folderId);
    close();
  };

  useEffect(() => {
    if (!open) {
      return;
    }

    loadFolders(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, query]);

  return (
    <Modal
      title="Select folder"
      open={open}
      onCancel={close}
      footer={<Input value={query} onChange={(e) => setQuery(e.currentTarget.value)} prefix={<SearchOutlined />} />}
    >
      <div className={styles.container}>
        {folders.map((folder) => (
          <div key={folder.id} className={styles.element} onClick={() => handleSelectFolder(folder.id)}>
            {folder.name}
          </div>
        ))}
        <div className={styles.element} onClick={() => handleSelectFolder(null)}>
          Move outside folder
        </div>
        {isLoading && <Spin />}
      </div>
    </Modal>
  );
}
