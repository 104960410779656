import { ActionType, Actions } from './actions';

import { ListResponse } from '@api/types';
import { TreatmentProgram, TreatmentProgramExcludedHospital, TreatmentProgramRelation } from '@modelTypes/treatment';

export interface ProgramsState {
  readonly grid: ListResponse<TreatmentProgram> & { loading: boolean }
  readonly relationsGrid: ListResponse<TreatmentProgramRelation> & Readonly<{ loading: boolean; fetched: boolean }>;
  readonly excludedHospitalsGrid: ListResponse<TreatmentProgramExcludedHospital> & { loading: boolean };
  readonly form: {
    readonly loading: boolean;
    readonly row: TreatmentProgram | null;
  };
  readonly select: {
    readonly loading: boolean;
    readonly search: string | null;
    readonly data: Array<TreatmentProgram>;
  };
  readonly allRelations: {
    loading: boolean;
    fetched: boolean;
    hospitalsIds: Array<number>;
    departmentsIds: Array<number>;
    excludedHospitalsIds: Array<number>;
  };
}

const initialState: ProgramsState = {
  grid: {
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  relationsGrid: {
    loading: false,
    fetched: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  excludedHospitalsGrid: {
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 25,
    },
  },
  form: {
    loading: false,
    row: null,
  },
  select: {
    loading: false,
    search: null,
    data: [],
  },
  allRelations: {
    loading: false,
    fetched: false,
    hospitalsIds: [],
    departmentsIds: [],
    excludedHospitalsIds: [],
  },
};

export default function treatmentPrograms(state = initialState, action: Actions): ProgramsState {
  switch (action.type) {
    case ActionType.START_FETCH_PROGRAMS:
    // case ActionType.START_DELETE_PROGRAM:
      return {
        ...state,
        grid: {
          ...state.grid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_PROGRAMS:
      return {
        ...state,
        grid: {
          ...action.data,
          loading: false,
        },
      }
    case ActionType.START_FETCH_PROGRAMS_FOR_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          loading: true,
        }
      }
    case ActionType.END_FETCH_PROGRAMS_FOR_SELECT:
      return {
        ...state,
        select: {
          ...state.select,
          loading: false,
          data: action.data.data,
        }
      }
    case ActionType.CLEAR_PROGRAMS_SELECT_DATA:
      return {
        ...state,
        select: initialState.select,
      }
    case ActionType.START_FETCH_PROGRAM:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        }
      }
    case ActionType.END_FETCH_PROGRAM:
    case ActionType.END_POST_PROGRAM:
    case ActionType.END_PATCH_PROGRAM:
      return {
        ...state,
        form: {
          ...state.form,
          row: action.data,
          loading: false,
        },
      }
    case ActionType.START_POST_PROGRAM:
    case ActionType.START_PATCH_PROGRAM:
      return {
        ...state,
        form: {
          ...state.form,
          loading: true,
        },
      }
    case ActionType.END_POST_PROGRAM_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.END_PATCH_PROGRAM_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          loading: false,
        },
      }
    case ActionType.CLEAR_FORM_DATA:
      return {
        ...state,
        form: {
          ...initialState.form,
        }
      }
    case ActionType.START_FETCH_ALL_RELATIONS:
      return {
        ...state,
        allRelations: {
          ...state.allRelations,
          loading: true,
          fetched: false,
        }
      }
    case ActionType.END_FETCH_ALL_RELATIONS:
      return {
        ...state,
        allRelations: {
          ...action.data,
          loading: false,
          fetched: true,
        }
      }
    case ActionType.CLEAR_ALL_RELATIONS:
      return {
        ...state,
        allRelations: {
          ...initialState.allRelations,
        }
      }
    case ActionType.START_FETCH_RELATIONS:
      return {
        ...state,
        relationsGrid: {
          ...state.relationsGrid,
          loading: true,
          fetched: false,
        }
      }
    case ActionType.END_FETCH_RELATIONS:
      return {
        ...state,
        relationsGrid: {
          ...action.data,
          loading: false,
          fetched: true,
        }
      }
    case ActionType.START_FETCH_EXCLUDED_HOSPITALS:
    case ActionType.START_POST_EXCLUDED_HOSPITALS:
    case ActionType.START_DELETE_EXCLUDED_HOSPITAL:
      return {
        ...state,
        excludedHospitalsGrid: {
          ...state.excludedHospitalsGrid,
          loading: true,
        }
      }
    case ActionType.END_FETCH_EXCLUDED_HOSPITALS:
      return {
        ...state,
        excludedHospitalsGrid: {
          ...action.data,
          loading: false,
        },
      }
    case ActionType.END_POST_EXCLUDED_HOSPITALS:
      return {
        ...state,
        excludedHospitalsGrid: {
          ...state.excludedHospitalsGrid,
          loading: false,
        },
      }
    case ActionType.END_DELETE_EXCLUDED_HOSPITAL:
      return {
        ...state,
        excludedHospitalsGrid: {
          ...state.excludedHospitalsGrid,
          loading: false,
        },
      }
    default:
      return state;
  }
}