import React from 'react';
import cn from 'classnames';
import styles from './Loader.module.scss';
import { valueOnly } from '@helpers/className';

interface ILoaderProps {
  active: boolean;
  children?: React.ReactNode;
  className?: string;
  content?: React.ReactNode;
  size?: 'medium' | 'small';
}

export default class Loader extends React.PureComponent<ILoaderProps> {
  
  render() {
    const { active, children, content, className, size = 'medium' } = this.props;
    const classes = cn(
      styles.loader,
      className,
      {
        [styles[size]]: true,
      }
    );

    return (
      <div className={classes}>
        {active && (
          <div className={styles.spinnerContainer}>
            <div className={styles.spinner} />
            <div className={styles.content}>{content}</div>
          </div>
        )}
        <div className={cn(styles.container, valueOnly(styles, active, 'blur'))}>{children}</div>
      </div>
    );
  }
  
}
