import { useHistory } from 'react-router-dom';
import UsersAntGrid from './components/UsersAntGrid';
import { Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

export default function UsersIndex(): JSX.Element {
  const history = useHistory();

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.push("/")}
        title="Users"
        extra={(
          <Button
            key="createUser"
            type="primary"
            href="/users/create"
            onClick={(e) => {
              e.preventDefault();

              history.push("/users/create");
            }}
          >
            Add User
          </Button>
        )}
      />
      <UsersAntGrid />
    </>
  );
}