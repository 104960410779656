import { FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { HospitalTranslation } from '@modelTypes/hospital';
import { IconButtonRenderer } from '../renderers/renderers';
import { getDateFormattedCell } from '@components/AntGrid/cells/DateFormattedCell';
import ShowCheckIcon from '../cells/ShowCheckIcon';
import getIdCell from '@components/AntGrid/cells/IdCell';
import QueryString from 'query-string';

export type HospitalTranslationColumnsNames = `${keyof HospitalTranslation}Column` | 'eyeIconColumn' | 'deleteColumn';

export const hospitalTranslationColumnsDefinitions: Partial<Record<HospitalTranslationColumnsNames, ColumnType<HospitalTranslation>>> = {
  idColumn: createColumnDefinition<HospitalTranslation>('#', 'id', { width: 80, render: getIdCell }),
  countryColumn: {
    title: 'Country',
    dataIndex: 'countryId',
    render: (value: any, row: HospitalTranslation) => {
      return <CountryCell record={row} />
    },
    sorter: true,
    filtered: false,
  },
  cityColumn: {
    title: 'City',
    dataIndex: 'cityId',
    render: (value: any, row: HospitalTranslation) => {
      return <CityCell record={row} />
    },
    sorter: true,
    filtered: false,
  },
  searchSectionColumn: {
    title: 'Search Section',
    dataIndex: 'searchSectionId',
    render: (value: any, row: HospitalTranslation) => {
      return <SearchSectionCell record={row} />
    },
    sorter: true,
    filtered: false,
  },
  isLanguageActiveColumn: {
    title: 'Active',
    dataIndex: 'isLanguageActive',
    width: 100,
    render: (value: any, row: HospitalTranslation) => {
      return <ShowCheckIcon value={row.value === '1'} />
    },
    sorter: true,
    filtered: false,
  },
  updatedAtColumn: createColumnDefinition<HospitalTranslation>('Updated At', 'updatedAt', { render: getDateFormattedCell, width: 160 }),
  createdAtColumn: createColumnDefinition<HospitalTranslation>('Created At', 'createdAt', { render: getDateFormattedCell, width: 160 }),
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, row: HospitalTranslation) => {
      const params = QueryString.stringify(
        {
          countryId: row.countryId > 0 ? row.countryId : null,
          cityId: row.cityId > 0 ? row.cityId : null,
          searchSectionId: row.searchSectionId > 0 ? row.searchSectionId : null,
        },
        { skipNull: true }
      );

      return IconButtonRenderer<HospitalTranslation>(`/hospitals/contents/edit${params ? `?${params}` : ''}`);
    },
    sorter: false,
    filtered: false,
  },
};

export const hospitalTranslationColumns: any = [
  hospitalTranslationColumnsDefinitions.idColumn,
  hospitalTranslationColumnsDefinitions.countryColumn,
  hospitalTranslationColumnsDefinitions.cityColumn,
  hospitalTranslationColumnsDefinitions.searchSectionColumn,
  hospitalTranslationColumnsDefinitions.updatedAtColumn,
  hospitalTranslationColumnsDefinitions.createdAtColumn,
  hospitalTranslationColumnsDefinitions.isLanguageActiveColumn,
  hospitalTranslationColumnsDefinitions.eyeIconColumn,
];

export const hospitalTranslationFiltersConfig: FiltersConfig = {
  [hospitalTranslationColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [hospitalTranslationColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [hospitalTranslationColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function CountryCell({ record }: { record: HospitalTranslation }) {
  if (!record.country) {
    return <>N/A</>;
  }

  return (
    <>{record.country.name}</>
  );
}

function CityCell({ record }: { record: HospitalTranslation }) {
  if (!record.city) {
    return <>N/A</>;
  }

  return (
    <>{record.city.name}</>
  );
}

function SearchSectionCell({ record }: { record: HospitalTranslation }) {
  if (!record.searchSection) {
    return <>N/A</>;
  }

  return (
    <>{record.searchSection.name}</>
  );
}