import { Blog, BlogAccessKey, BlogTypes } from '@modelTypes/blog';
import { CreateArticleRequest, GetListParams, ListResponse, Params, UpdateArticleRequest } from '@api/types';
import {
  endFetchAccessKey,
  endFetchBlog,
  endFetchBlogs,
  endFetchBlogsForSelect,
  endPostBlog,
  endUpdateBlog,
  startDeleteBlog,
  startFetchAccessKey,
  startFetchBlog,
  startFetchBlogs,
  startFetchBlogsForSelect,
  startPostBlog,
  startUpdateBlog
} from './actions';
import { endFetchSearchSynonymsForSelect, startFetchSearchSynonymsForSelect } from '@storage/searchSynonyms/actions';

import { AxiosRequestConfig } from 'axios';
import { ThunkAction } from '@storage/types';
import api from '@api';

export function getBlogs(type: BlogTypes, params?: Params): ThunkAction<Promise<ListResponse<Blog>>> {
  return async (dispatch): Promise<ListResponse<Blog>> => {
    dispatch(startFetchBlogs());

    return api.getArticles(type, params)
      .then((data) => {
        dispatch(endFetchBlogs({ ...data, type }));

        return data;
      });
  };
}

export function getBlog(id: number, params?: Params): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startFetchBlog());

    return api.getArticle(id, params)
      .then((data) => {
        dispatch(endFetchBlog(data));
      });
  };
}

export function createBlog(payload: CreateArticleRequest): ThunkAction<Promise<Blog>> {
  return async (dispatch): Promise<Blog> => {
    dispatch(startPostBlog());

    return api.createArticle(payload)
      .then((data) => {
        dispatch(endPostBlog(data));

        return data;
      });
  };
}

export function updateBlog(id: number, payload: UpdateArticleRequest): ThunkAction<Promise<Blog>> {
  return async (dispatch): Promise<Blog> => {
    dispatch(startUpdateBlog());

    return api.updateArticle(id, payload)
      .then((data) => {
        dispatch(endUpdateBlog(data));

        return data;
      });
  };
}

export function deleteBlog(id: number, config?: AxiosRequestConfig): ThunkAction<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(startDeleteBlog());

    return api.deleteArticle(id, config);
  };
}

export function getAccessKey(id: number, params?: Params): ThunkAction<Promise<BlogAccessKey>> {
  return async (dispatch): Promise<BlogAccessKey> => {
    dispatch(startFetchAccessKey());

    return api.getAccessKey(id)
      .then((data) => {
        dispatch(endFetchAccessKey(data));

        return data;
      });
  };
}

export function getBlogsForSelect(payload?: Params): ThunkAction<Promise<void>> {
  const params: GetListParams & Params = { page: 1, perPage: 5000, ...payload };

  return async (dispatch): Promise<void> => {
    dispatch(startFetchBlogsForSelect());

    return api.getArticlesForSelect(params)
      .then((data) => {
        dispatch(endFetchBlogsForSelect(data));
      });
  };
}