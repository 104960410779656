import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { showForbiddenModal } from '@base/ForbiddenModal';
import { Headers, Params } from './types';

import Auth from '@base/Auth';

const API_PROTOCOL = process.env.REACT_APP_API_PROTOCOL;
const API_HOST = process.env.REACT_APP_API_HOST;
export const HOST_URL = `${API_PROTOCOL}://${API_HOST}`;
export const BASE_URL = `${HOST_URL}/api/v1/`;

const axios = Axios.create({
  baseURL: BASE_URL,
  timeout: 180000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axios.interceptors.request.use(
  (config) => {
    const accessToken = Auth.getAccessToken();

    if (accessToken) {
      if (!config.headers) config.headers = {};

      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error: AxiosError) => {
    if ([401].indexOf(error.request.status) > -1) {
      Auth.logout();
    }

    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  async (error) => {
    if ([403].indexOf(error.request.status) > -1) {
      showForbiddenModal();
    }

    if ([401].indexOf(error.request.status) > -1) {
      Auth.logout();
    }

    return Promise.reject(error);
  }
);

async function get<Resp>(url: string, params?: Params): Promise<Resp> {
  return new Promise<Resp>((resolve, reject) => {
    axios
      .get<Resp>(url, { params })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}

async function post<Req, Resp>(
  url: string,
  payload?: Req,
  headers?: Headers,
  onUploadProgress?: AxiosRequestConfig<any>['onUploadProgress']
): Promise<Resp> {
  return new Promise<Resp>((resolve, reject) => {
    axios
      .post<Resp>(url, payload, { onUploadProgress })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}

async function patch<Req, Resp>(url: string, payload?: Req): Promise<Resp> {
  return new Promise<Resp>((resolve, reject) => {
    axios
      .patch<Resp>(url, payload)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}

async function put<Req, Resp>(url: string, payload?: Req): Promise<Resp> {
  return new Promise<Resp>((resolve, reject) => {
    axios
      .put<Resp>(url, payload)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}

async function del<Resp = void>(url: string, config?: AxiosRequestConfig): Promise<Resp> {
  return new Promise<Resp>((resolve, reject) => {
    axios
      .delete<Resp>(url, config)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}

export default { get, post, patch, put, del };
