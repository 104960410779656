import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks';
import { PaginationConfig } from '@components/AntGrid/types';
import { TemplatesState } from '@storage/templates/reducer';
import { useEffect, useRef } from 'react';
import { getTemplates } from '@storage/templates/thunkActions';
import { useParams } from 'react-router-dom';
import { Template, TemplateTypes } from '@modelTypes/template';
import { templateColumns, templateFiltersConfig } from '@components/AntGrid/columns/template';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeGridOptions from "@components/AntGrid/hooks/useChangeGridOptions";

interface RouterParams {
  type: TemplateTypes;
}

export const [
  refreshTemplatesGridEvent,
  dispatchRefreshTemplatesGridEvent
] = makeRefreshGridEvent('onRefreshTemplatesGrid');


export default function TemplatesAntGrid() {
  const dispatch = useDispatch();
  const { type } = useParams<RouterParams>();
  const firstLoad = useRef<boolean>(false);
  const { data, meta, loading } = useSelector<TemplatesState['grid']>('templates.grid');
  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
    // ...rest.paginationConfig,
  };

  const onFetchData = (params?: AntGetListParams) => {
    dispatch(getTemplates(type, params) as unknown as Promise<void>)
      .then(() => {
        firstLoad.current = true;
      });
  };

  useEffect(() => {
    if (firstLoad.current) {
      dispatch(getTemplates(type));
    }
  }, [firstLoad, type]);

  useRefreshGridEvent(refreshTemplatesGridEvent, onFetchData);
  useChangeGridOptions('templatesAntGrid');

  return (
    <Grid<Template>
      id="templatesGrid"
      onFetch={onFetchData}
      columns={templateColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={templateFiltersConfig}
    />
  );
}