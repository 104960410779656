import {
  CreateVendorCompanyRequest,
  CreateVendorCompanyResponse,
  CreateVendorUserRequest,
  CreateVendorUserResponse,
  ListResponse,
  Params,
  UpdateVendorCompanyRequest,
  UpdateVendorCompanyResponse,
  UpdateVendorUserRequest,
  UpdateVendorUserResponse
} from '@api/types';

import { AxiosRequestConfig } from 'axios';
import client from './client';
import { VendorCompany, VendorUser } from '@modelTypes/vendor';

const GET_USERS_URI = '/app-users';
const GET_USER_URI = (id: number) => `/app-users/${id}`;
const POST_USER_URI = '/app-users';
const PUT_USER_URI = (id: number) => `/app-users/${id}`
const DELETE_USER_URI = (id: number) => `/app-users/${id}`
const GET_USERS_FOR_SELECT_URI = 'app-users/select';

const GET_COMPANIES_URI = 'app-users/companies';
const GET_COMPANY_URI = (id: number) => `/app-users/companies/${id}`;
const POST_COMPANY_URI = '/app-users/companies';
const PUT_COMPANY_URI = (id: number) => `/app-users/companies/${id}`
const DELETE_COMPANY_URI = (id: number) => `/app-users/companies/${id}`
const GET_COMPANIES_FOR_SELECT_URI = 'app-users/companies/select';
// User

export async function getVendorsUsers(params?: Params) {
  return client.get<ListResponse<VendorUser>>(GET_USERS_URI, params);
}

export async function getVendorUser(id: number, params?: Params) {
  return client.get<VendorUser>(GET_USER_URI(id), params);
}

export async function createVendorUser(payload?: CreateVendorUserRequest) {
  return client.post<CreateVendorUserRequest, CreateVendorUserResponse>(POST_USER_URI, payload);
}

export async function updateVendorUser(id: number, payload?: UpdateVendorUserRequest) {
  return client.put<UpdateVendorUserRequest, UpdateVendorUserResponse>(PUT_USER_URI(id), payload);
}

export async function deleteVendorUser(id: number, payload?: AxiosRequestConfig) {
  return client.del(DELETE_USER_URI(id), payload);
}


export async function getVendorsUsersForSelect(params?: Params) {
  return client.get<ListResponse<VendorUser>>(GET_USERS_FOR_SELECT_URI, params);
}

//Company

export async function getCompanies(params?: Params) {
  return client.get<ListResponse<VendorCompany>>(GET_COMPANIES_URI, params);
}

export async function getCompany(id: number, params?: Params) {
  return client.get<VendorCompany>(GET_COMPANY_URI(id), params);
}

export async function createCompany(payload?: CreateVendorCompanyRequest) {
  return client.post<CreateVendorCompanyRequest, CreateVendorCompanyResponse>(POST_COMPANY_URI, payload);
}

export async function updateCompany(id: number, payload?: UpdateVendorCompanyRequest) {
  return client.put<UpdateVendorCompanyRequest, UpdateVendorCompanyResponse>(PUT_COMPANY_URI(id), payload);
}

export async function deleteCompany(id: number, payload?: AxiosRequestConfig) {
  return client.del(DELETE_COMPANY_URI(id), payload);
}

export async function getCompaniesForSelect(params?: Params) {
  return client.get<ListResponse<VendorCompany>>(GET_COMPANIES_FOR_SELECT_URI, params);
}