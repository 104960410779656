import { create } from 'zustand';
import produce from 'immer';
import { getProcesses } from '@api';

export interface ProcessState {
  loading: boolean;
  data: [],
  getProcesses: () => Promise<any>;
}

export const useProcessStore = create<ProcessState>((set) => ({
  loading: false,
  data: [],
  getProcesses: () => {
    set(produce((state) => {
      state.loading = true;
    }));

    return getProcesses()
      .then((response) => {
        set(produce((state) => {
          state.loading = false;
          // state.grid.data = response.data;
          // state.grid.meta = response.meta;
          // state.grid.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.loading = false;
          // state.grid.data = response.data;
          // state.grid.meta = response.meta;
          // state.grid.loading = false;
        }));
      });
  },
}));