import {
  AntGetListParams,
  CreateCityRequest,
  CreateCityResponse,
  GetListParams,
  ListResponse,
  Params,
  UpdateCityRequest,
  UpdateCityResponse,
} from '@api/types';

import { AxiosRequestConfig } from 'axios';
import { City } from "../modelTypes/city";
import client from "./client";
import { getRestUri } from '@api/helpers';

const getUri = getRestUri('cities');
const ROOT_URI = getUri('');
const GET_CITIES_FOR_SELECT_URI = 'cities/select';
const GET_CITIES_FOR_SELECT2_URI = 'cities/select2';

export async function getCities(params?: AntGetListParams) {
  return client.get<ListResponse<City>>(ROOT_URI.GET(), params);
}

export async function getCitiesForSelect(params?: GetListParams) {
  return client.get<ListResponse<City>>(GET_CITIES_FOR_SELECT_URI, params);

}export async function getCitiesForSelect2(params?: GetListParams) {
  return client.get<ListResponse<City>>(GET_CITIES_FOR_SELECT2_URI, params);
}

export async function getCity(id: number, params?: Params) {
  return client.get<City>(ROOT_URI.SHOW({ id }), params);
}

export async function createCity(payload: CreateCityRequest) {
  return client.post<CreateCityRequest, CreateCityResponse>(ROOT_URI.POST(), payload);
}

export async function updateCity(id: number, payload: UpdateCityRequest) {
  return client.patch<UpdateCityRequest, UpdateCityResponse>(ROOT_URI.PATCH({ id }), payload);
}

export async function deleteCity(id: number, payload?: AxiosRequestConfig) {
  return client.del(ROOT_URI.DELETE({ id }), payload);
}
