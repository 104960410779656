import styles from './AuthLayout.module.scss';
import { ReactNode } from 'react';
import LogoIcon from '@components/icons/LogoIcon';
import { Link } from 'react-router-dom';

interface Props {
  children: ReactNode,
}

export default function AuthLayout(
  {
    children
  }: Props
) {
  return (
    <div className={styles.authLayout}>
      <main className={styles.main}>
        <div className={styles.logo}>
          <Link to="/">
            <LogoIcon />
          </Link>
        </div>
        {children}
      </main>
      <footer className={styles.footer}>
        © 2023 Airomedical
      </footer>
    </div>
  );
}
