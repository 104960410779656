import { Dropdown, MenuProps, Tooltip } from 'antd';
import styles from './FolderElement.module.scss';
import { FolderFilled, MoreOutlined } from '@ant-design/icons';

interface Props {
  title: string;
  menu: MenuProps['items'];
}

export default function FolderElement(props: Props & Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>) {
  const { title, menu, onClick } = props;

  return (
    <Dropdown menu={{ items: menu }} key={title} trigger={['contextMenu']}>
      <div className={styles.folder} onClick={onClick}>
        <div className={styles.nameContainer}>
          <FolderFilled />
          <Tooltip title={title}>
            <p>{title}</p>
          </Tooltip>
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <Dropdown menu={{ items: menu }} trigger={['click']}>
            <MoreOutlined />
          </Dropdown>
        </div>
      </div>
    </Dropdown>
  );
}
