import { Blog, BlogTypes } from '@modelTypes/blog';
import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { getBlogs } from '@storage/blogs/thunkActions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useSelector } from '@hooks';
import { BlogsState } from '@storage/blogs/reducer';
import { blogColumns, blogFiltersConfig } from '@components/AntGrid/columns/blog';
import { PaginationConfig } from '@components/AntGrid/types';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeGridOptions from "@components/AntGrid/hooks/useChangeGridOptions";

interface RouterParams {
  type: BlogTypes;
}

export const [refreshBlogsGridEvent, dispatchRefreshBlogsGridEvent] = makeRefreshGridEvent('onRefreshBlogsGrid');

export default function BlogAntGrid() {
  const dispatch = useDispatch();
  const { type } = useParams<RouterParams>();
  const firstLoad = useRef<boolean>(false);
  const { data, meta, loading } = useSelector<BlogsState['grid']>('blogs.grid');

  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
    // ...rest.paginationConfig,
  };

  const onFetchData = (params?: AntGetListParams) => {
    dispatch(getBlogs(type, params as any) as unknown as Promise<void>)
      .then(() => {
        firstLoad.current = true;
      });
  };

  useEffect(() => {
    if (firstLoad.current) {
      dispatch(getBlogs(type));
    }
  }, [firstLoad, type]);

  useRefreshGridEvent(refreshBlogsGridEvent, onFetchData);
  useChangeGridOptions('blogAntGrid');


  return (
    <Grid<Blog>
      id="blogsGrid"
      onFetch={onFetchData}
      columns={blogColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={blogFiltersConfig}
    />
  );
}