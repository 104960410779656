import * as React from 'react';
import { useEffect } from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';

import { Language } from '@modelTypes/language';
import { Button, Card, Checkbox, Col, Form, Input, InputNumber, message, Row, Spin, Tooltip } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import { CreateOfferRequest } from '@api/types';
import { SaveOutlined } from '@ant-design/icons';
import isEqual from 'lodash/isEqual';
import { pick } from 'lodash';
import { useGridStore } from '@zustandStorage/grid/grid';
import QueryString from 'query-string';
import { useOfferStore } from '@zustandStorage/offers/offer';
import QuillEditor from '@components/QuillEditor';
import CustomSelect from '@base/CustomSelect';
import { useSelect } from '@zustandStorage/customSelect/customSelect';
import { getSelectValue } from '@helpers/antForm';
import SelectAgeGroup from '@containers/Offers/Offers/components/SelectAgeGroup';
import { Offer } from '@modelTypes/offer';
import SelectStep from '@containers/Offers/Offers/components/SelectStep';
import OfferServicesTriggerModal
  from '@containers/Offers/Offers/components/ExtraServicesModal/OfferServicesTriggerModal';
import { setFormErrors } from '@api/helpers';

export default function MainDataStep() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const {
    form: { loading, row },
    updateOffer,
    createOffer,
    clearFormData: clearOfferFormData,
    fetchOffer
  } = useOfferStore()
  const [messageApi, contextHolder] = message.useMessage();
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);
  const [languageId, setLanguageId] = React.useState<number>(0);
  const [form] = Form.useForm<CreateOfferRequest>();

  const {
    fetchOffersMethods,
    fetchOffersTypesOfCare,
    fetchCurrency,
    fetchHospitalsForSelect,
    fetchDoctorsForSelect,
    fetchVendor,
    clearFormData,
    setData,
    setOpened,
  } = useSelect();
  const selectDoctor = useSelect((state) => state.data?.['selectDoctor']);
  const selectHospital = useSelect((state) => state.data?.['selectHospital']);

  const onSelectLanguage = (language: Language) => {
    setLanguageId(language.id);

    if (id) {
      fetchOffer(parseInt(id, 10), { languageId: language.id })
        .then((data) => {
          resetForm();
          setSelectsInitialDate(data);
          // setOpened('selectDoctor', true)
        });
    }
  };

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving offer...',
      duration: 0,
    });

    setFormSubmitting(true);
    const data = form.getFieldsValue(true);
    const formData = {
      ...data,
      languageId,
      currencyId: getSelectValue(data.currencyId),
      methodId: getSelectValue(data.methodId),
      typeId: getSelectValue(data.typeId),
      ageGroup: getSelectValue(data.ageGroup),
      hospitalId: getSelectValue(data.hospitalId),
      doctorId: getSelectValue(data.doctorId),
      vendorId: getSelectValue(data.vendorId),
    };

    if (id) {
      await updateOffer(parseInt(id, 10), formData as any)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The Offer has been successfully updated');
          setFormSubmitting(false);
          setOpened('selectHospital', false)
          resetForm();
          setSelectsInitialDate(data)
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          message.error('An error occurred, please try again now')
          setFormSubmitting(false);
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    } else {
      await createOffer(formData as any)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The Offer has been successfully created');
          history.push(`/offers/${data.id}/edit/1`);
          setFormSubmitting(false);
          setOpened('selectHospital', false)
          resetForm();
          setSelectsInitialDate(data)
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          message.error('An error occurred, please try again now')
          setFormSubmitting(false);
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    }
  };

  const resetHospitalAndDoctorSelects = () => {
    clearFormData('selectHospital')
    clearFormData('selectDoctor')
    form.resetFields(['hospitalId', 'doctorId']);
  };

  const setSelectsInitialDate = (response: Offer) => {
    setData('selectHospital', response && response.hospital ? [response.hospital] : []);
    setData('selectCurrency', response && response.currency ? [response.currency] : []);
    setData('selectDoctor', response && response.doctor ? [response.doctor] : []);
    setData('selectMethod', response && response.method ? [response.method] : []);
    setData('selectType', response && response.type ? [response.type] : []);
    setData('selectVendor', response && response.vendorId ? [response.vendor] : []);
  };

  useEffect(() => {
    resetForm();
  }, [languageId, id]);

  useEffect(() => {
    if (formChanged) {
      const fieldsToCompare = [
        'examination', 'distantServices', 'laboratoryTests', 'diagnosis',
        'treatment', 'other', 'description',
      ];

      if (isEqual(pick(row, fieldsToCompare), pick(form.getFieldsValue(), fieldsToCompare))) {
        return setFormChanged(false);
      }
    }
  }, [formChanged]);

  useEffect(() => {
    return () => {
      clearOfferFormData();
    };
  }, []);

  return (
    <>
      <Prompt
        when={!formSubmitting && formChanged}
        // when={!formik.isSubmitting && !redirect.length && formik.dirty}
        message={(location) =>
          `Data not saved!\nAre you sure you want to go to ${location.pathname}?`
        }
      />
      {contextHolder}
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.offerGrid || {};

          history.push(`/offers?${QueryString.stringify(params)}`)
        }}
        title={`${row ? 'Edit' : 'Create'} Offer`}
        // subTitle={<SelectedPlaceValue data={place} />}
        extra={[
          <SelectStep
            key="selectStep"
            disabled={!row}
          />,
          <SelectLanguagePerForm
            key="selectLanguage"
            onInit={onSelectLanguage}
            onChange={onSelectLanguage}
            disabled={!row}
          />,
          <OfferServicesTriggerModal key="extraModal" disabled={!row} />,
          <Button
            key="save"
            type="primary"
            loading={formSubmitting}
            onClick={() => form.submit()}
          >
            {id ? 'Update' : 'Create'}
          </Button>
        ]}
      />
      <Spin spinning={loading || languageId === 0}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          // style={{ marginTop: 32 }}
        >
          <Row gutter={[32, 24]}>
            <Col span={16}>
              <Form.Item
                name="name"
                label="Name"
                messageVariables={{ name: 'Name' }}
                rules={[{ required: true, type: 'string', max: 150 }]}
                initialValue={row?.name}
              >
                <Input placeholder="Input name" />
              </Form.Item>
              <Form.Item
                name="price"
                label="Price"
                messageVariables={{ name: 'Price' }}
                rules={[{ type: 'number', min: 1 }]}
                initialValue={row?.price}
                // style={{width: '100%'}}

              >
                <InputNumber
                  placeholder="Input price"
                  style={{ width: '100%' }}
                  addonAfter={
                    <Form.Item
                      name="currencyId"
                      // label="Currency"
                      initialValue={row && row?.currencyId ? row.currencyId : undefined}
                      messageVariables={{ name: 'Currency' }}
                      style={{ margin: 0 }}
                      valuePropName="defaultValue"
                    >
                      <CustomSelect
                        showSearch={false}
                        onSearch={undefined}
                        placeholder={'Currency'}
                        defaultQueryParams={{ page: 1, perPage: 50 }}
                        selectId={'selectCurrency'}
                        onUpdateSelect={fetchCurrency}
                        style={{ width: '100px' }}
                        transformFieldLabel={(i) => ({
                          ...i,
                          name: `${i.code}, ${i.symbol}`
                        })}
                      />
                    </Form.Item>
                  }
                />
              </Form.Item>
              <Form.Item
                name="discount"
                label="Discount"
                initialValue={row && row?.discount ? row.discount : 0}
                // rules={[{ min: 0, max: 100 }]}
                valuePropName="defaultValue"
              >
                <InputNumber
                  style={{ width: '100%' }}
                  step={0.1}
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value: string | undefined) => (value ? parseFloat(value) : 0)}
                />
              </Form.Item>
              <Form.Item
                name="duration"
                label="Duration (days)"
                messageVariables={{ name: 'Duration' }}
                rules={[{ required: true, type: 'number', min: 0 }]}
                initialValue={row?.duration}
              >
                <InputNumber
                  disabled={languageId > 1}
                  placeholder="Input duration"
                  style={{ width: '100%' }}
                  step={1}
                />
              </Form.Item>
              <Form.Item
                name="ageGroup"
                label="Age group"
                messageVariables={{ name: 'Age group' }}
                rules={[{ required: true, }]}
                initialValue={row?.ageGroup}
                valuePropName="defaultValue"
              >
                <SelectAgeGroup />
              </Form.Item>
              <Form.Item
                name="hospitalId"
                label="Hospital"
                rules={[{ required: true, }]}
                initialValue={row && row?.hospitalId ? row.hospitalId : undefined}
                valuePropName="defaultValue"
              >
                <CustomSelect
                  placeholder={'Select hospital'}
                  defaultQueryParams={{ page: 1, perPage: 50 }}
                  selectId={'selectHospital'}
                  onClear={() => resetHospitalAndDoctorSelects()}
                  onUpdateSelect={fetchHospitalsForSelect}
                  style={{ width: '100%' }}
                  fieldNames={{
                    label: 'title',
                    value: 'id',
                  }}
                  onChange={(value) => {
                    if (value && value.id) {
                      clearFormData('selectDoctor')
                      // form.setFieldValue('doctorId', null)
                      form.resetFields(['doctorId'])
                      fetchDoctorsForSelect('selectDoctor',
                        {
                          hospitalId: value.id
                        },
                        {
                          clearDataBeforeFetch: true,
                          saveParams: true
                        })
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name="doctorId"
                label="Doctor"
                // initialValue={row && row?.doctorId ? row.doctorId : undefined}
                initialValue={(row && !selectHospital?.opened && row?.doctorId) ? row.doctorId : null}
                valuePropName="defaultValue"
                help={<>{!(selectDoctor?.opened || id) && 'Select a hospital first'}</>}
              >
                <CustomSelect
                  disabled={!(selectDoctor?.opened || id)}
                  placeholder={'Select doctor'}
                  onClear={() => clearFormData('selectDoctor')}
                  defaultQueryParams={{
                    page: 1,
                    perPage: 50, ...(row?.hospitalId ? { hospitalId: row.hospitalId } : {})
                  }}
                  selectId={'selectDoctor'}
                  onUpdateSelect={fetchDoctorsForSelect}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                name="methodId"
                label="Method"
                rules={[{ required: true, }]}
                initialValue={row && row?.methodId ? row.methodId : undefined}
                valuePropName="defaultValue"
              >
                <CustomSelect
                  placeholder={'Select method'}
                  defaultQueryParams={{ page: 1, perPage: 50 }}
                  selectId={'selectMethod'}
                  onUpdateSelect={fetchOffersMethods}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                name="typeId"
                label="Type of care"
                rules={[{ required: true, }]}
                initialValue={row && row?.typeId ? row.typeId : undefined}
                valuePropName="defaultValue"
              >
                <CustomSelect
                  placeholder={'Select type'}
                  defaultQueryParams={{ page: 1, perPage: 50 }}
                  selectId={'selectType'}
                  onUpdateSelect={fetchOffersTypesOfCare}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                name="examination"
                label="Examination"
                initialValue={row ? row.examination : ''}
                valuePropName="defaultValue"
              >
                <QuillEditor />
              </Form.Item>
              <Form.Item
                name="laboratoryTests"
                label="Laboratory tests"
                initialValue={row ? row.laboratoryTests : ''}
                valuePropName="defaultValue"
              >
                <QuillEditor />
              </Form.Item>
              <Form.Item
                name="diagnosis"
                label="Diagnosis"
                initialValue={row ? row.diagnosis : ''}
                valuePropName="defaultValue"
              >
                <QuillEditor />
              </Form.Item>
              <Form.Item
                name="treatment"
                label="Treatment"
                initialValue={row ? row.treatment : ''}
                valuePropName="defaultValue"
              >
                <QuillEditor />
              </Form.Item>
              <Form.Item
                name="other"
                label="Other"
                initialValue={row ? row.other : ''}
                valuePropName="defaultValue"
              >
                <QuillEditor />
              </Form.Item>
              <Form.Item
                name="distantServices"
                label="Distant services"
                initialValue={row ? row.distantServices : ''}
                valuePropName="defaultValue"
              >
                <QuillEditor />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                initialValue={row ? row.description : ''}
                valuePropName="defaultValue"
              >
                <QuillEditor />
              </Form.Item>
              <Form.Item
                name="chooseUs"
                label="Why to choose us"
                initialValue={row ? row.chooseUs : ''}
                valuePropName="defaultValue"
              >
                <QuillEditor />
              </Form.Item>
              <Form.Item
                name="faq"
                label="FAQ"
                initialValue={row ? row.faq : ''}
                valuePropName="defaultValue"
              >
                <QuillEditor />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Card title="Statuses" bordered={false} style={{ marginTop: 24 }}>
                <Form.Item
                  name="isActive"
                  valuePropName="checked"
                  initialValue={row ? row?.isActive : false}
                  style={{ margin: 0 }}
                >
                  <Checkbox disabled={languageId > 1}>Active</Checkbox>
                </Form.Item>
                <Form.Item
                  name="isLanguageActive"
                  valuePropName="checked"
                  style={{ margin: 0 }}
                  initialValue={languageId === 1 ? true : row?.isLanguageActive}
                >
                  <Checkbox disabled={languageId === 1}>Active Language</Checkbox>
                </Form.Item>
              </Card>
              <Card title="Vendor" bordered={false} style={{ marginTop: 24 }}>
                <Form.Item
                  name="vendorId"
                  label="Vendor"
                  rules={[{ required: true, }]}
                  initialValue={row && row?.vendorId ? row.vendorId : undefined}
                  valuePropName="defaultValue"
                  messageVariables={{ name: 'Vendor' }}
                >
                  <CustomSelect
                    placeholder={'Select vendor'}
                    defaultQueryParams={{ page: 1, perPage: 50 }}
                    selectId={'selectVendor'}
                    transformFieldLabel={(i) => ({
                      ...i,
                      name: `${i.firstName} ${i.lastName}${i.company ? `, ${i.company.name}` : ''}`
                    })}
                    onClear={() => clearFormData('selectVendor')}
                    onUpdateSelect={fetchVendor}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Tooltip title={id ? 'Update' : 'Create'} placement="topRight">
        <Button
          style={{ position: 'fixed', bottom: 20, right: 16 }}
          onClick={() => form.submit()}
          // type="ghost"
          shape="circle"
          icon={<SaveOutlined />}
          size="large"
        />
      </Tooltip>
    </>
  );
}
