import { create } from 'zustand';
import { ListResponse, Params } from '@api/types';
import produce from 'immer';
import { Language } from '@modelTypes/language';
import { initialGridState } from '@zustandStorage/helpers/initialStates';
import { getLanguages } from '@api';

// interface Editable {
//   status: boolean,
//   rowId: number | null;
//   form: any,
// }

export interface LanguagesState {
  grid: ListResponse<Language> & {
    loading: boolean;
    // editable: Editable;
  };
  fetchLanguages: (params?: Params) => Promise<ListResponse<Language>>;
  // setGridEditable: (data: Editable) => void;
  clearGridData: () => void;

}

// const editableInitialData = {
//   status: false,
//   rowId: null,
//   form: {},
// };

export const useLanguagesStore = create<LanguagesState>((set) => ({
  grid: initialGridState,
  fetchLanguages: async (params?) => {
    set(produce((state) => {
      state.grid.loading = true;
    }));

    return getLanguages(params)
      .then((response) => {
        set(produce((state) => {
          state.grid.data = response.data;
          state.grid.meta = response.meta;
          state.grid.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.grid.loading = false;
        }));

        throw e;
      })
  },
  // setGridEditable: (data) => {
  //   set(produce((state) => {
  //     state.grid.editable = data;
  //   }))
  // },
  clearGridData: () => set(() => ({ grid: initialGridState })),

}));