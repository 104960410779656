import Auth from '@base/Auth';
import hasPermission from '@helpers/hasPermission';
import { useSelector } from '@hooks';
import SessionTimer from '@layouts/AppLayout/SessionTimer';
import ClearCache from '@layouts/ClearCache';
import ProcessNotification from '@layouts/Notification/ProcessNotification';
import { AppState } from '@storage/app/reducer';
import { Avatar, Layout, Menu, Space } from 'antd';
import styles from './AppLayout.module.scss';

export const Header = (): JSX.Element => {
  const user = useSelector<AppState['user']>('app.user');
  const initials =
    user && user?.name
      ? user?.name
          .split(' ')
          .map((n) => n[0])
          .join('')
      : 'N/A';

  return (
    <Layout.Header className={styles.siteLayoutSubHeaderBackground}>
      <div className={styles.leftActions}>
        <Space size="middle">
          {hasPermission(user, 'cache_cache_clear') && <ClearCache />}
          <SessionTimer />
        </Space>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
        <ProcessNotification />
        <Menu mode="horizontal" triggerSubMenuAction="click">
          <Menu.SubMenu
            key="SubMenu"
            title={
              <Avatar size="large" style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                {initials}
              </Avatar>
            }
          >
            <Menu.Item key="logout" onClick={() => Auth.logout()}>
              Logout
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </div>
    </Layout.Header>
  );
};
