import { Action, ActionData, ActionMeta } from './types';

export function createAction<Type extends string>(type: Type): () => Action<Type>;
export function createAction<Type extends string, Data extends ActionData>(type: Type):
  (data?: Data) => Action<typeof type, Data>;
export function createAction<Type extends string, Data extends ActionData, Meta extends ActionMeta>(type: Type):
  (data?: Data, meta?: Meta) => Action<Type, Data, Meta> {
  return function (data?: Data, meta?: Meta): Action<Type, Data, Meta> {
    return {
      type: type as Type,
      data: data as Data,
      meta: meta as Meta,
    };
  };
}
