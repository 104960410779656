import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { BaseRow } from '../types';
import isEqual from 'lodash/isEqual';

export function DateFormattedCell<T extends BaseRow>({ record, column }: {
  column: keyof T;
  record: T;
}): JSX.Element {
  const initValue = record[column];
  const [value, setValue] = useState<string | null>(initValue);

  useEffect(() => {
    if (!isEqual(initValue, value)) {
      setValue(initValue);
    }
  }, [initValue]);

  if (!value) {
    return (
      <>N/A</>
    );
  }

  if (column === 'publishedAt') {
    return <>{moment(value).format('DD/MM/YYYY')}</>;
  }

  return <>{moment(value).format('DD/MM/YYYY hh:mm')}</>;
}

export function getDateFormattedCell<T extends BaseRow>(column: string) {
  return (value: any, record: T) => {
    return (
      <DateFormattedCell<T>
        column={column}
        record={record}
      />
    );
  };
}
