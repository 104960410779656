import { Input, InputRef } from 'antd';
import Modal from 'antd/es/modal/Modal';
import React, { useEffect, useRef, useState } from 'react';
import { Node, useNodes, useReactFlow } from 'reactflow';
import { CustomNodeProps } from './CustomNode/CustomNode';

export default function FindNodeModal() {
  const [isOpen, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const nodes = useNodes<CustomNodeProps>();
  const flowState = useReactFlow();
  const input = useRef<InputRef>(null);
  const [foundNodes, setFoundNodes] = useState<Node<CustomNodeProps>[]>([]);
  const [lastFocusedNodeIndex, setLastFocusedNodeIndex] = useState(-1);

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (!['F3', 'f'].includes(event.key)) {
        return;
      }

      if (event.key === 'f' && !event.ctrlKey && !event.metaKey) {
        return;
      }

      event.preventDefault();

      setQuery('');
      setOpen(true);
      input.current?.focus();
    };

    document.addEventListener('keydown', handleKeydown);

    return () => document.removeEventListener('keydown', handleKeydown);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.currentTarget.value);

    if (!event.target.value) {
    }

    setFoundNodes(
      event.target.value
        ? nodes.filter((i) => i.data.name.toLowerCase().includes(event.currentTarget.value.toLowerCase()))
        : []
    );
    setLastFocusedNodeIndex(-1);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let lastFocusedNodeIndex = 0;

    setLastFocusedNodeIndex((prev) => {
      if (!foundNodes.length) {
        return -1;
      }
      
      if (prev + 1 >= foundNodes.length) {
        lastFocusedNodeIndex = 0;
        return 0;
      }

      lastFocusedNodeIndex = prev + 1;
      return prev + 1;
    });

    const node = foundNodes[lastFocusedNodeIndex];

    if (!node) {
      return;
    }

    flowState.fitBounds(
      {
        height: node.height as number,
        width: node.width as number,
        x: node.position.x,
        y: node.position.y,
      },
      { duration: 500 }
    );
  };

  return (
    <Modal title="Find node" open={isOpen} onCancel={() => setOpen(false)} footer={null}>
      <form onSubmit={handleSubmit}>
        <Input
          ref={input}
          placeholder="Search"
          value={query}
          onChange={handleChange}
          addonAfter={`${lastFocusedNodeIndex + 1}/${foundNodes.length}`}
        />
      </form>
    </Modal>
  );
}
