import { combineReducers } from 'redux'
import methods, { MethodsState } from './methods/reducer';
import folders, { FoldersState } from './folders/reducer';
import types, { TypesState } from './types/reducer';
import programs, { ProgramsState } from './programs/reducer';
import servicesCost, { ServicesCostState } from './servicesCost/reducer';

export interface TreatmentState {
  methods: MethodsState;
  folders: FoldersState;
  types: TypesState;
  programs: ProgramsState;
  servicesCost: ServicesCostState;
}

export default combineReducers<TreatmentState>({
  methods,
  folders,
  types,
  programs,
  servicesCost,
});
