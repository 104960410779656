import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M23.6968 12.9801C23.6214 12.699 23.4647 12.4772 23.2426 12.3386L15.5989 7.59368C15.4778 7.51851 15.3303 7.49743 15.1949 7.54204C14.7745 7.6806 14.7024 8.30075 15.0785 8.53425L22.779 13.3145C22.7816 13.3234 22.783 13.3408 22.7813 13.3609L16.0599 10.9457C12.8505 9.79246 9.45371 10.2718 6.48585 12.2947L6.37038 12.3747L1.80198 15.5815C1.58393 15.7316 1.3994 15.6448 1.28279 15.5457C1.08078 15.3732 0.960156 15.0626 0.960156 14.715V12.8895C0.960156 11.8893 1.37562 10.9899 2.01859 10.5973L3.38153 9.78696C3.54394 9.69039 3.64237 9.51438 3.6389 9.32546C3.63141 8.91781 3.1844 8.66467 2.83278 8.87108L1.63808 9.57241H1.62719L1.56187 9.61768C0.612884 10.1985 0 11.4818 0 12.889V14.7144C0 15.415 0.264752 16.0591 0.708584 16.4376C0.9656 16.6562 1.26216 16.772 1.56645 16.772C1.69997 16.772 1.94732 16.6782 2.12748 16.6013C2.27365 16.539 2.41029 16.4571 2.54036 16.3658L6.91364 13.2958C8.66748 12.0827 10.6285 11.4417 12.5852 11.4417C13.6579 11.4417 14.7293 11.6311 15.7697 12.0045L22.5873 14.454C22.8529 14.5494 23.1194 14.5021 23.3369 14.321C23.5056 14.1815 23.634 13.9657 23.6993 13.7119C23.7598 13.4706 23.7586 13.211 23.6968 12.9801Z"
    fill="currentColor" />
  <path
    d="M23.0521 19.9381C22.9191 19.8754 22.7698 19.8691 22.632 19.9204C21.1464 20.4754 19.7051 20.3986 18.3407 19.6937C19.0441 19.3648 19.5871 19.0419 20.024 18.6943C20.4329 18.3694 20.7375 18.0313 20.955 17.6608C21.2034 17.239 21.3295 16.7817 21.3295 16.3015C21.3295 15.9955 21.0839 15.7465 20.7822 15.7465C20.4805 15.7465 20.2349 15.9955 20.2349 16.3015C20.2349 16.8631 19.9275 17.374 19.2945 17.8634C18.6713 18.3453 17.8143 18.7321 17.1779 18.994C16.8169 18.7834 16.4536 18.5952 16.0923 18.4316C16.4659 18.1966 16.7771 17.9554 17.035 17.7009C17.3269 17.413 17.553 17.1029 17.7063 16.7806C17.8779 16.4216 17.9648 16.0373 17.9648 15.6388C17.9648 15.3328 17.7192 15.0838 17.4175 15.0838C17.1158 15.0838 16.8702 15.3328 16.8702 15.6388C16.8702 16.0938 16.6742 16.5081 16.2714 16.9055C15.9189 17.2534 15.4118 17.5877 14.723 17.9259C14.1038 17.7494 13.4812 17.6451 12.862 17.6133C12.8637 17.6118 12.8654 17.6104 12.8668 17.609C13.2127 17.3044 13.4814 16.9746 13.6657 16.6293C13.8746 16.2376 13.9803 15.8164 13.9803 15.3772C13.9803 15.0712 13.7347 14.8222 13.433 14.8222C13.1313 14.8222 12.8858 15.0712 12.8858 15.3772C12.8858 16.3299 12.0279 17.125 10.1047 17.9536C8.23568 18.5078 6.64001 19.6224 5.23172 20.606C5.03688 20.7421 4.86124 20.8648 4.68474 20.9857C4.43861 21.1547 4.37128 21.4977 4.53546 21.7513C4.63746 21.9072 4.80794 22 4.9919 22C5.10307 22 5.20937 21.9665 5.29963 21.9034C5.4873 21.7742 5.67584 21.6427 5.85148 21.5198C7.2804 20.5224 8.89957 19.3917 10.7586 18.935C12.8803 18.4141 14.9846 18.8611 17.1911 20.3023C18.1645 20.9384 19.1905 21.3017 20.2406 21.3817C21.1458 21.451 22.077 21.3098 23.0076 20.9619C23.2882 20.8576 23.4346 20.5401 23.3349 20.2559C23.2862 20.1146 23.1862 20.0015 23.0521 19.9381Z"
    fill="currentColor" />
  <path
    d="M16.2195 3.96734C16.1533 3.81634 16.0169 3.70832 15.8545 3.67795C15.8358 3.67451 15.8201 3.67193 15.8083 3.67079C15.6828 3.65732 15.5857 3.71577 15.5278 3.75015L15.5198 3.75503C15.5115 3.7599 15.5038 3.76477 15.4983 3.76735C15.0708 3.95961 14.6433 4.15101 14.2158 4.34269L14.0127 4.43381C12.6502 5.0444 11.2414 5.67562 9.84454 6.31745C9.55486 6.4504 9.32707 6.44495 9.0328 6.29796C8.22709 5.89568 7.39042 5.51574 6.6062 5.16503C6.02111 4.90314 5.47556 5.10486 5.18272 5.69138C4.89562 6.26673 5.05264 6.86013 5.57355 7.16786L5.87096 7.34351C6.838 7.91513 7.83827 8.50624 8.8437 9.05866C8.88209 9.07958 8.92306 9.09849 8.96576 9.11482C9.11217 9.17127 9.27922 9.19992 9.44139 9.19992C9.57406 9.19992 9.70357 9.18072 9.81617 9.14204C11.4992 8.56354 13.1823 7.94722 14.7505 7.367C14.7915 7.35181 14.8359 7.32001 14.8943 7.26442C15.0293 7.13606 15.072 6.9481 15.0058 6.77389C14.939 6.59796 14.7806 6.48478 14.5926 6.47848C14.5121 6.4759 14.4582 6.48077 14.4184 6.4951C13.6918 6.75268 12.9548 7.01973 12.2422 7.27789L12.2027 7.29222C11.3872 7.58791 10.544 7.89364 9.70443 8.18905C9.51446 8.2561 9.33022 8.24922 9.27578 8.21885C8.50387 7.78648 7.7291 7.32488 6.98012 6.87847L6.9761 6.87618C6.68814 6.70455 6.39044 6.52719 6.09675 6.35326C5.98013 6.2845 5.95664 6.24037 6.03515 6.09711C6.07497 6.0249 6.09302 5.99195 6.27869 6.07619C6.57525 6.21115 6.86722 6.33922 7.24687 6.50541L7.39501 6.57045C7.51764 6.62432 7.64687 6.68105 7.78297 6.74094C7.83741 6.76615 7.90302 6.79566 7.97924 6.82976C8.17064 6.91515 8.46004 7.04408 8.7288 7.18563C9.21074 7.43949 9.68953 7.43978 10.2354 7.18706C11.2912 6.69853 12.4021 6.20083 13.382 5.76187C13.8072 5.57133 14.2324 5.38079 14.6574 5.18939C14.7622 5.14211 14.8671 5.09541 14.9722 5.04841C15.325 4.89111 15.69 4.72865 16.0482 4.54957C16.0952 4.52607 16.1384 4.47994 16.1886 4.4C16.2708 4.26848 16.2817 4.11061 16.2184 3.9662L16.2195 3.96734Z"
    fill="currentColor" />
  <path
    d="M7.66406 5.15529C7.84686 5.30686 8.06147 5.31746 8.19757 5.18136C8.34141 5.03781 8.47493 4.88624 8.60387 4.73982L8.61046 4.73237C8.69069 4.64154 8.77235 4.549 8.85602 4.45931C8.66576 4.68882 8.47465 4.92034 8.28697 5.14956C8.16434 5.2997 8.18439 5.49769 8.33826 5.65385C8.44141 5.75872 8.59356 5.82892 8.74226 5.82892C8.83309 5.82892 8.92249 5.80284 8.99899 5.74239C9.01475 5.73006 9.02765 5.71746 9.03768 5.70456C9.17206 5.53694 9.2944 5.35729 9.40243 5.19913L9.40644 5.19311C9.51589 5.03323 9.62878 4.8679 9.74884 4.71919C11.0505 3.10461 12.4454 1.85162 14.0127 0.88831C14.0473 0.867107 14.0834 0.831004 14.1301 0.771406C14.2456 0.624131 14.2645 0.428145 14.1794 0.260526C14.0949 0.093767 13.927 -0.00680446 13.7413 0.000358749C13.6751 0.00265098 13.6299 0.00952766 13.5955 0.0229945C12.2227 0.551353 11.0018 1.26853 9.96746 2.15534C9.29584 2.73097 8.67092 3.37452 8.06549 3.99829C7.90331 4.16533 7.71965 4.35473 7.54114 4.53581C7.53197 4.54498 7.52337 4.5553 7.51592 4.56647C7.38269 4.76246 7.48985 5.01088 7.66406 5.15529Z"
    fill="currentColor" />
  <path
    d="M7.49902 8.60366C7.49415 8.55151 7.46721 8.49506 7.40848 8.41483C7.32481 8.30051 7.19644 8.22945 7.05605 8.21971C6.92625 8.21083 6.80132 8.25782 6.7125 8.34922C6.64803 8.4157 6.61565 8.47501 6.61107 8.53575C6.60792 8.57816 6.60448 8.62056 6.60075 8.66354C6.57582 8.96955 6.55004 9.28588 6.59846 9.60221C6.68385 10.1598 6.56436 10.5747 6.22282 10.9073C5.92684 11.1959 5.63916 11.2795 5.31739 11.1709C4.98043 11.0569 4.80565 10.8274 4.75035 10.4271C4.67557 9.88358 4.87155 9.46897 5.38616 9.08331C5.69962 8.84864 5.96437 8.56383 6.2085 8.2922C6.24804 8.24836 6.27039 8.18246 6.27869 8.08475C6.29159 7.93461 6.22741 7.78906 6.10678 7.69508C5.9787 7.59565 5.81108 7.56814 5.6575 7.62173C5.59103 7.64493 5.55034 7.66499 5.52226 7.68849L5.43745 7.7584C5.18129 7.96871 4.91654 8.18647 4.68273 8.43948L4.63775 8.48819C4.38045 8.76612 4.11455 9.05379 3.95782 9.4088C3.52229 10.3965 3.91799 11.5919 4.8217 12.0191C4.85608 12.0351 4.88817 12.0489 4.9194 12.0609C5.10192 12.1311 5.29905 12.1663 5.50249 12.1663C5.74661 12.1663 5.99962 12.1159 6.24775 12.0159C6.80705 11.7899 7.23312 11.3523 7.44773 10.7847L7.4589 10.7558L7.46234 10.7268C7.47323 10.6486 7.48727 10.5669 7.50074 10.4882L7.50217 10.4804C7.53569 10.285 7.57065 10.0833 7.57151 9.87326C7.5738 9.43917 7.53369 8.99792 7.49845 8.60882L7.49787 8.60394L7.49902 8.60366Z"
    fill="currentColor" />
</svg>`;

export default { id: '175-eyebrow-transplant', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
