import { ClearCacheRequest, ClearCacheResponse } from '@api/types';

import { Language } from '@modelTypes/language';
import { User } from '@modelTypes/user';
import { createAction } from '../helpers';

export enum ActionType {
  SET_HAS_TOKEN = 'APP__SET_HAS_TOKEN',

  START_FETCH_USER = 'APP__START_FETCH_USER',
  END_FETCH_USER = 'APP__END_FETCH_USER',

  START_FETCH_LANGUAGES = 'APP__START_FETCH_LANGUAGES',
  END_FETCH_LANGUAGES = 'APP__END_FETCH_LANGUAGES',

  SET_ACTIVE_SIDEBAR_ITEM = 'APP__SET_ACTIVE_SIDEBAR_ITEM',
  FORBIDDEN_MODAL_SET_VISIBILITY = 'APP__FORBIDDEN_MODAL_SET_VISIBILITY',

  UPDATE_USER_DATA = 'APP__UPDATE_USER_DATA',
  CLEAR_DATA = 'APP__CLEAR_DATA',

  START_CLEAR_CACHE = 'APP__START_CLEAR_CACHE',
  END_CLEAR_CACHE = 'APP__END_CLEAR_CACHE',
}

interface ForbiddenModalSetVisibility {
  visible: boolean;
  data: any;
}

export const setHasToken = createAction<ActionType.SET_HAS_TOKEN, boolean>(ActionType.SET_HAS_TOKEN);

export const startFetchUser = createAction<ActionType.START_FETCH_USER>(ActionType.START_FETCH_USER);
export const endFetchUser = createAction<ActionType.END_FETCH_USER, User>(ActionType.END_FETCH_USER);

export const startFetchLanguages = createAction<ActionType.START_FETCH_LANGUAGES>(ActionType.START_FETCH_LANGUAGES);
export const endFetchLanguages = createAction<ActionType.END_FETCH_LANGUAGES, Array<Language>>(ActionType.END_FETCH_LANGUAGES);

export const setActiveSidebarItem = createAction<ActionType.SET_ACTIVE_SIDEBAR_ITEM, string | null>(ActionType.SET_ACTIVE_SIDEBAR_ITEM);

export const forbiddenModalSetVisibility = createAction<ActionType.FORBIDDEN_MODAL_SET_VISIBILITY, ForbiddenModalSetVisibility>(ActionType.FORBIDDEN_MODAL_SET_VISIBILITY);

export const updateData = createAction<ActionType.UPDATE_USER_DATA, User>(ActionType.UPDATE_USER_DATA);
export const clearData = createAction<ActionType.CLEAR_DATA, User>(ActionType.CLEAR_DATA);

export const startClearCache = createAction<ActionType.START_CLEAR_CACHE, ClearCacheRequest>(ActionType.START_CLEAR_CACHE);
export const endClearCache = createAction<ActionType.END_CLEAR_CACHE, ClearCacheResponse>(ActionType.END_CLEAR_CACHE);

export type Actions = ReturnType<typeof setHasToken>
  | ReturnType<typeof startFetchUser>
  | ReturnType<typeof endFetchUser>
  | ReturnType<typeof startFetchLanguages>
  | ReturnType<typeof endFetchLanguages>
  | ReturnType<typeof setActiveSidebarItem>
  | ReturnType<typeof forbiddenModalSetVisibility>
  | ReturnType<typeof updateData>
  | ReturnType<typeof clearData>
  | ReturnType<typeof startClearCache>
  | ReturnType<typeof endClearCache>;