import { create } from 'zustand';
import produce from 'immer';
import {
  AntGetListParams,
  CreateVendorUserRequest,
  CreateVendorUserResponse,
  ListResponse,
  Params,
  UpdateVendorUserRequest,
  UpdateVendorUserResponse
} from '@api/types';
import { initialFormState, initialGridState } from '@zustandStorage/helpers/initialStates';
import { VendorUser } from '@modelTypes/vendor';
import { createVendorUser, deleteVendorUser, getVendorsUsers, getVendorUser, updateVendorUser } from '@api/vendorsApi';
import { AxiosRequestConfig } from 'axios';

export interface UserState {
  grid: ListResponse<VendorUser> & { loading: boolean }
  form: {
    loading: boolean;
    row: VendorUser | null;
  };
  clearFormData: () => void;
  fetchUsers: (params?: AntGetListParams) => Promise<ListResponse<VendorUser>>;
  fetchUser: (id: number) => Promise<VendorUser>;
  createUser: (payload: CreateVendorUserRequest) => Promise<VendorUser>;
  updateUser: (id: number, payload: UpdateVendorUserRequest) => Promise<VendorUser>;
  deleteUser: (id: number, payload?: AxiosRequestConfig) => Promise<any>;
}

export const useVendorUserStore = create<UserState>((set) => ({
  grid: initialGridState,
  form: initialFormState,
  clearFormData: () => set(() => ({ form: initialFormState })),

  fetchUsers: async (params?: AntGetListParams) => {
    set(produce((state) => {
      state.grid.loading = true;
    }));

    return getVendorsUsers(params)
      .then((response) => {
        set(produce((state) => {
          state.grid.data = response.data;
          state.grid.meta = response.meta
          state.grid.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.loading = false;
        }));

        throw e;
      });
  },
  fetchUser: async (id: number, params?: Params): Promise<VendorUser> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return getVendorUser(id, params)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  createUser: async (payload: CreateVendorUserRequest): Promise<CreateVendorUserResponse> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return createVendorUser(payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateUser: async (id: number, payload: UpdateVendorUserRequest): Promise<UpdateVendorUserResponse> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateVendorUser(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  deleteUser: async (id, payload?) => {
    return deleteVendorUser(id, payload)
  },
}));