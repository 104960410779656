import * as React from 'react';
import { useEffect, useState } from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { Avatar, Button, Card, Col, Form, Input, message, Row, Spin, Tooltip } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { CreateVendorUserRequest } from '@api/types';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { useGridStore } from '@zustandStorage/grid/grid';
import QueryString from 'query-string';
import CustomSelect from '@base/CustomSelect';
import { useSelect } from '@zustandStorage/customSelect/customSelect';
import { prepareFormDataForBackend } from '@helpers/antForm';
import { useVendorUserStore } from '@zustandStorage/vendor/user';
import { Vendors, VendorUser } from '@modelTypes/vendor';
import { setFormErrors } from '@api/helpers';
import styles from '@containers/Doctors/FormSteps/MainDataStep/MainDataStep.module.scss';
import UploadPhotoModal from '@components/UploadPhotoModal/UploadPhotoButton';
import TextArea from 'antd/es/input/TextArea';


interface RouterParams {
  seller: Vendors;
}

export default function UserForm() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const {
    form: { loading, row },
    fetchUser,
    createUser,
    updateUser,
    clearFormData: clearUserForm,

  } = useVendorUserStore()
  const [messageApi, contextHolder] = message.useMessage();
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
  const [form] = Form.useForm<CreateVendorUserRequest>();
  const { seller } = useParams<RouterParams>();
  const [imgPreview, setImgPreview] = useState<string>('');

  const {
    fetchCompany,
    setData,
    clearFormData,
  } = useSelect();

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const onPhotoSave = async (canvas: any, type: string) => {
    canvas.toBlob(
      async (blob: Blob) => {
        // await formik.setFieldValue('photoToSave', blob);
        form.setFields([
          {
            name: 'photoToSave',
            value: blob,
          }
        ]);

        setImgPreview(URL.createObjectURL(blob));

        if (id) {
          form.submit();
        }
      },
      type,
      type === 'image/jpeg' || type === 'image/jpg' ? .95 : undefined
    );
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving vendor...',
      duration: 0,
    });

    setFormSubmitting(true);
    const formData = prepareFormDataForBackend(form.getFieldsValue(true), { selectFieldNames: ['vendorCompanyId'] });

    if (id) {
      await updateUser(parseInt(id, 10), formData as any)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The Vendor has been successfully updated');
          setFormSubmitting(false);
          resetForm();
          setSelectsInitialDate(data)
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          message.error('An error occurred, please try again now')
          setFormSubmitting(false);
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    } else {
      await createUser(formData as any)
        .then((data) => {
          messageApi.destroy('startCreate');
          message.success('The Vendor has been successfully created');
          history.push(`/vendors/users/${data.id}/edit`);
          setFormSubmitting(false);
          resetForm();
          setSelectsInitialDate(data)
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          message.error('An error occurred, please try again now')
          setFormSubmitting(false);
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        })
    }
  };

  const setSelectsInitialDate = (response: VendorUser) => {
    setData('selectCompany', response && response.company ? [response.company] : []);
  };

  useEffect(() => {
    resetForm();
  }, [id]);

  useEffect(() => {

    return () => {
      clearUserForm();
    };
  }, []);

  useEffect(() => {
    if (row && row.imgPath) {
      setImgPreview(row.imgPath);
    }
  }, [row]);

  useEffect(() => {
    if (id) {
      fetchUser(parseInt(id, 10))
        .then((data) => {
          setSelectsInitialDate(data);
          resetForm();
        });
    }
  }, [id]);

  return (
    <>
      <Prompt
        when={!formSubmitting && formChanged}
        message={(location) =>
          `Data not saved!\nAre you sure you want to go to ${location.pathname}?`
        }
      />
      {contextHolder}
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.vendorsUserAntGrid || {};

          history.push(`/vendors/${seller}?${QueryString.stringify(params)}`)
        }}
        title={`${row ? 'Edit' : 'Create'} vendor`}
        extra={[
          <Button
            key="save"
            type="primary"
            loading={formSubmitting}
            onClick={() => form.submit()}
          >
            {id ? 'Update' : 'Create'}
          </Button>
        ]}
      />
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          // style={{ marginTop: 32 }}
        >
          <Row gutter={[32, 24]}>
            <Col span={16}>
              <Form.Item
                name="firstName"
                label="First Name"
                messageVariables={{ name: 'First Name' }}
                rules={[{ type: 'string', max: 254, required: true, }]}
                initialValue={row?.firstName}
              >
                <Input placeholder="Input first name" />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                messageVariables={{ name: 'Last Name' }}
                rules={[{ type: 'string', max: 254, required: true }]}
                initialValue={row?.lastName}
              >
                <Input placeholder="Input last name" />
              </Form.Item>
              <Form.Item
                name="username"
                label="Username"
                messageVariables={{ name: 'Username' }}
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: 'string', min: 3,
                    message: 'Username must be at least 3 characters'
                  },
                  {
                    type: 'string',
                    max: 30, message: 'Username cannot exceed 30 characters'
                  },
                  {
                    pattern: /^[a-zA-Z][a-zA-Z0-9.-_]*$/,
                    message: 'Please enter a valid username. Example: johnDoe123, user.name, user-name12345',
                  },
                ]}
                initialValue={row?.username}
              >
                <Input placeholder="Input username" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                messageVariables={{ name: 'Email' }}
                rules={[
                  {
                    required: true,
                  },
                  {
                    max: 254,
                    message: 'Email cannot exceed 254 characters'
                  },
                  {
                    type: 'email',
                    message: 'Please enter a valid email'
                  },
                ]}
                initialValue={row?.email}
              >
                <Input placeholder="Input email" />
              </Form.Item>
              <Form.Item
                name="description"
                label="About vendor"
                initialValue={row?.description}
              >
                <TextArea
                  placeholder="About vendor"
                  autoSize={{ minRows: 3, maxRows: 8 }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Card title="Photo" bordered={false}>
                <div className={styles.photoPreview}>
                  <div>
                    <Form.Item
                      name="photoToSave"
                      style={{ display: 'none' }}
                      initialValue=""
                    >
                      <Input style={{ display: 'none' }} />
                    </Form.Item>
                    <Avatar
                      className={styles.avatar}
                      src={imgPreview}
                    />
                  </div>
                  <div className={styles.actions}>
                    {!!imgPreview.length && (
                      <Tooltip title="Delete item">
                        <Button
                          danger
                          type="primary"
                          // shape="round"
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            form.setFieldValue('imgPath', false);
                            setImgPreview('');
                          }}
                          style={{ marginRight: 8 }}
                        />
                      </Tooltip>
                    )}
                    <UploadPhotoModal
                      handleSaveImage={onPhotoSave}
                      uploadButtonProps={
                        {
                          style: { width: 32, height: 32 },
                          type: 'primary',
                        }
                      }
                      cropperProps={
                        {
                          initialAspectRatio: 1,
                          viewMode: 0,
                          minCropBoxHeight: 10,
                          minCropBoxWidth: 10,
                          responsive: true,
                          aspectRatio: 1,
                          guides: true,
                        }
                      }
                      modalClassName={styles.cropperContainer}
                    />
                  </div>
                </div>
              </Card>
              <Card title="Organization" bordered={false} style={{ marginTop: 24 }}>
                <Form.Item
                  name="vendorCompanyId"
                  label="Company"
                  initialValue={row && row?.vendorCompanyId ? row.vendorCompanyId : undefined}
                  valuePropName="defaultValue"
                >
                  <CustomSelect
                    placeholder={'Select organization'}
                    defaultQueryParams={{ page: 1, perPage: 50 }}
                    selectId={'selectCompany'}
                    onClear={() => clearFormData('selectCompany')}
                    transformFieldLabel={(i) => ({
                      ...i,
                      name: `${i.name}, ${i.orgName}`
                    })}
                    onUpdateSelect={fetchCompany}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Tooltip title={id ? 'Update' : 'Create'} placement="topRight">
        <Button
          style={{ position: 'fixed', bottom: 20, right: 16 }}
          onClick={() => form.submit()}
          shape="circle"
          icon={<SaveOutlined />}
          size="large"
        />
      </Tooltip>
    </>
  );
}
