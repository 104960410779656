import { FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import React, { ChangeEvent } from 'react';
import { Setting } from '@modelTypes/setting';
import { getDateFormattedCell } from '../cells/DateFormattedCell';
import { Input, message, Popconfirm, Tooltip } from 'antd';
import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { useSettingStore } from '@zustandStorage/settings/settings/settings';
import { dispatchRefreshSettingsGridEvent } from '@containers/Settings/Settings/components/SettingsGrid';
import getIdCell from '@components/AntGrid/cells/IdCell';

export type SettingColumnsNames = `${keyof Setting}Column` | 'editColumn' | 'deleteColumn';
type CellProps = { record: Setting };

export const settingColumnsDefinitions: Partial<Record<SettingColumnsNames, ColumnType<Setting>>> = {
  idColumn: createColumnDefinition<Setting>('#', 'id', { width: 80, render: getIdCell }),
  nameColumn: createColumnDefinition<Setting>('Name', 'name', { render: getNameCell }),
  valueColumn: createColumnDefinition<Setting>('Value', 'value', { render: getValueCell }),
  updatedAtColumn: createColumnDefinition<Setting>('Updated At', 'updatedAt', {
    render: getDateFormattedCell,
    width: 160
  }),
  // createdAtColumn: createColumnDefinition<Setting>('Created At', 'createdAt', { render: getDateFormattedCell, width: 160 }),
  // isActiveColumn: createColumnDefinition<Setting>('Active', 'isActive', { render: getIsActiveCell, width: 80, filtered: false }),
  editColumn: {
    title: '',
    width: 104,
    fixed: 'right',
    render: (value: any, row: Setting) => {
      return <EditCell record={row} />
    },
    sorter: false,
    filtered: false,
    className: 'no-padding',
  },
  // deleteColumn: {
  //   title: '',
  //   width: 52,
  //   fixed: 'right',
  //   render: getDeleteCell<TreatmentMethod>(),
  //   sorter: false,
  //   filtered: false,
  // }
};

export const settingColumns: any = [
  settingColumnsDefinitions.idColumn,
  settingColumnsDefinitions.nameColumn,
  settingColumnsDefinitions.valueColumn,
  settingColumnsDefinitions.updatedAtColumn,
  // settingColumnsDefinitions.createdAtColumn,
  settingColumnsDefinitions.editColumn,
  // settingColumnsDefinitions.deleteColumn,
];

export const settingFiltersConfig: FiltersConfig = {
  [settingColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [settingColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [settingColumnsDefinitions.valueColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [settingColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [settingColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};


function getNameCell() {
  return (value: any, record: Setting) => {
    return <NameCell record={record} />
  };
}

function getValueCell() {
  return (value: any, record: Setting) => {
    return <ValueCell record={record} />
  };
}

function NameCell({ record }: CellProps) {
  return (
    <Tooltip title={record.description}>
      <span>{record.name}</span>
    </Tooltip>
  );
}

// function ValueCell({ record }: CellProps) {
//   return (
//     <>
//       {record.value}
//     </>
//   );
// }

// function EditCell({ record }: CellProps) {
//   return IconButtonRenderer<Setting>(`/settings/${record.id}/edit`);
// }
function ValueCell({ record }: { record: Setting }) {
  const { status, rowId, form } = useSettingStore((state) => state.grid.editable);
  const { setGridEditable } = useSettingStore();
  // const val = currency(record.value).divide(100).value

  if (rowId === record.id && status) {
    const onChange = (event: any) => {
      setGridEditable({ status, rowId, form: {...form, value: event.target.value } });
    };

    return (
      <div>
        {record.type === 3 && (
          <Input.TextArea
            defaultValue={record.value}
            placeholder="Value"
            onChange={onChange}
            rows={20}
          />
        )}
        {record.type === 2 && (
          <Input
            defaultValue={record.value}
            placeholder="Value"
            onChange={onChange}
          />
        )}
      </div>
    );
  }

  return (
    <>{record.value}</>
  );
}


function EditCell({ record }: { record: Setting }) {
  const { status, rowId, form } = useSettingStore((state) => state.grid.editable);
  const { setGridEditable, updateSetting } = useSettingStore();
  const [messageApi, contextHolder] = message.useMessage();

  const onClick = () => {
    setGridEditable({ status: true, rowId: record.id, form: {} })
  };
  const onSave = () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving Program Discount...',
      duration: 0,
    });

    const formData = {
      value: form.hasOwnProperty('value') ? form.value : record.value,
    }
    updateSetting(record.id, formData as any)
      .then(() => {
        dispatchRefreshSettingsGridEvent();
        setGridEditable({ status: false, rowId: null, form: {} });
        messageApi.destroy('startCreate');
        message.success('The Program Price has been successfully updated');
      });
  };
  const onCancel = () => {
    setGridEditable({ status: false, rowId: null, form: {} })
  };

  if (status && rowId === record.id) {
    return (
      <>
        {contextHolder}
        <Tooltip title="Save" placement="bottomRight">
          <SaveOutlined
            onClick={onSave}
            style={{ fontSize: 18, color: '#000', cursor: 'pointer', padding: 16 }}
          />
        </Tooltip>
        <Popconfirm title="Sure to cancel?" onConfirm={onCancel}>
          <Tooltip title="Cancel" placement="bottomRight">
            <CloseOutlined
              onClick={onClick}
              style={{ fontSize: 18, color: '#000', cursor: 'pointer', padding: 16 }}
            />
          </Tooltip>
        </Popconfirm>
      </>
    );
  }

  if (status) {
    return (
      <EditOutlined
        style={{ fontSize: 18, color: 'gray', cursor: 'default', padding: 16 }}
      />
    );
  }

  return (
    <Tooltip title="Edit item">
      <EditOutlined
        onClick={onClick}
        style={{ fontSize: 18, color: '#000', cursor: 'pointer', padding: 16 }}
      />
    </Tooltip>
  );
}
