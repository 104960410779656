import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks';
import { PaginationConfig } from '@components/AntGrid/types';
import { userColumns, userFiltersConfig } from '@components/AntGrid/columns/user';
import { UsersState } from '@storage/users/reducer';
import { getUsers } from '@storage/users/thunkActions';
import { User } from '@modelTypes/user';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeGridOptions from '@components/AntGrid/hooks/useChangeGridOptions';
import { AppState } from '@storage/app/reducer';

export const [refreshUsersGridEvent, dispatchRefreshUsersGridEvent] = makeRefreshGridEvent('onRefreshUsersGrid');

export default function UsersAntGrid() {
  const dispatch = useDispatch();
  const { data, meta, loading } = useSelector<UsersState['grid']>('users.grid');
  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
    // ...rest.paginationConfig,
  };
  const user = useSelector<AppState['user']>('app.user');

  const onFetchData = (params?: AntGetListParams) => {
    dispatch(getUsers(params));
  };

  useRefreshGridEvent(refreshUsersGridEvent, onFetchData);
  useChangeGridOptions('usersAntGrid');

  return (
    <Grid<User>
      id="usersGrid"
      onFetch={onFetchData}
      columns={userColumns.filter((i) => i.dataIndex !== 'activity' || user?.isSuperAdmin)}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={userFiltersConfig}
    />
  );
}
