import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg data-id="9" width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
  d="M11.1117 19.0956C10.3858 19.0956 9.65995 18.7605 9.15741 18.2022C8.43152 18.258 7.70563 17.9788 7.14726 17.4763C6.58888 16.9737 6.36553 16.2479 6.36553 15.522C5.80716 15.0194 5.47213 14.3494 5.47213 13.5677C5.47213 12.7859 5.80716 12.1159 6.36553 11.6133C6.30969 10.8875 6.58888 10.1616 7.14726 9.65903C7.70563 9.15649 8.37568 8.8773 9.15741 8.93314C9.65995 8.37476 10.33 8.03974 11.1117 8.03974C11.8376 8.03974 12.5635 8.37476 13.066 8.93314C13.7919 8.8773 14.5178 9.15649 15.0762 9.65903C15.6346 10.1616 15.8579 10.8875 15.8579 11.6133C16.4163 12.1159 16.7513 12.7859 16.7513 13.5677C16.7513 14.3494 16.4163 15.0194 15.8579 15.522C15.9138 16.2479 15.6346 16.9737 15.0762 17.4763C14.5178 17.9788 13.7919 18.258 13.066 18.2022C12.5635 18.7605 11.8376 19.0956 11.1117 19.0956ZM9.49244 16.7504L9.93914 16.9179L10.1066 17.1413C10.33 17.4763 10.7209 17.6996 11.1117 17.6996C11.5026 17.6996 11.8935 17.4763 12.1168 17.1413L12.2285 16.9179L12.6752 16.7504L12.9544 16.8062C13.3452 16.9179 13.7919 16.8062 14.0711 16.4712C14.3503 16.192 14.462 15.7453 14.4061 15.3545L14.3503 15.1311L14.5737 14.6286L14.7412 14.5169C15.0762 14.2935 15.2995 13.9585 15.2995 13.5118C15.2995 13.0651 15.0762 12.7301 14.7412 12.5067L14.5178 12.4509L14.2945 11.9484L14.3503 11.725C14.462 11.3342 14.3503 10.9433 14.0153 10.6083C13.7361 10.3291 13.2894 10.2174 12.8985 10.2732L12.6752 10.3291L12.1726 10.1057L12.061 9.93821C11.8376 9.60319 11.4467 9.37984 11.0559 9.37984C10.665 9.37984 10.2742 9.60319 10.0508 9.93821L9.93914 10.1616L9.49244 10.3291L9.21325 10.2732C8.82238 10.1616 8.37568 10.2732 8.0965 10.6083C7.81731 10.8875 7.70563 11.3342 7.76147 11.725L7.81731 11.9484L7.6498 12.3951L7.42645 12.5626C7.09142 12.7859 6.86807 13.121 6.86807 13.5677C6.86807 13.9585 7.09142 14.3494 7.42645 14.5727L7.6498 14.6844L7.81731 15.1311V15.3545C7.70563 15.7453 7.81731 16.1362 8.15233 16.4712C8.43152 16.7504 8.87822 16.8621 9.26909 16.8062L9.49244 16.7504Z"
  fill="currentColor" />
<path
  d="M11.1119 22C6.42159 22 2.62463 18.203 2.62463 13.5685C2.62463 11.6701 3.23885 9.82741 4.46727 8.3198L11.1119 0L17.7566 8.3198C18.9292 9.82741 19.5993 11.6142 19.5993 13.5685C19.5993 18.203 15.8023 22 11.1119 22ZM11.1119 2.2335L5.52819 9.2132C4.52311 10.4416 4.02057 11.9492 4.02057 13.5685C4.02057 17.4213 7.20331 20.6041 11.1119 20.6041C15.0206 20.6041 18.2033 17.4772 18.2033 13.5685C18.2033 11.9492 17.7008 10.4416 16.6957 9.2132L11.1119 2.2335Z"
  fill="currentColor" />
</svg>`;

export default { id: '9-blood-disease', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
