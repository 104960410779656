import { create } from 'zustand';
import produce from 'immer';
import {
  AntGetListParams,
  CreateHospitalRequest,
  ListResponse,
  Params,
  UpdateHospitalImageOrderRequest,
  UpdateHospitalImageRequest
} from '@api/types';
import { initialFormState, initialGridState } from '@zustandStorage/helpers/initialStates';
import {
  createHospital,
  createHospitalImages,
  deleteHospital,
  deleteHospitalImage,
  editHospital,
  getHospital,
  getHospitalAccessKey,
  getHospitals,
  updateHospitalImage,
  updateHospitalImageOrder
} from '@api';
import { Hospital, HospitalAccessKey, HospitalImage } from '@modelTypes/hospital';
import { AxiosRequestConfig } from 'axios';

interface Form {
  loading: boolean;
  row: Hospital | null;
}

export interface HospitalState {
  grid: ListResponse<Hospital> & { loading: boolean; };
  form: Form;
  hospitalAccessKey: {
    data: HospitalAccessKey | null
    loading: boolean;
  }
  clearHospitalFormData: () => void;
  setForm: (form: Form) => void;
  fetchHospitals: (payload?: AntGetListParams) => Promise<ListResponse<Hospital>>;
  deleteHospital: (id: number, payload?: AxiosRequestConfig) => Promise<any>;
  fetchHospital: (id: number, params?: Params) => Promise<Hospital>;
  fetchHospitalAccessKey: (id: number) => Promise<HospitalAccessKey>;
  createHospital: (payload: CreateHospitalRequest) => Promise<Hospital>;
  updateHospital: (id: number, payload: CreateHospitalRequest) => Promise<Hospital>;
  createHospitalImage: (id: number, payload: FormData) => Promise<HospitalImage>;
  deleteHospitalImage: (id: number, config?: AxiosRequestConfig) => Promise<any>;
  updateHospitalImage: (id: number, payload: UpdateHospitalImageRequest) => Promise<HospitalImage>;
  updateHospitalImageOrder: (id: number, payload: UpdateHospitalImageOrderRequest) => Promise<Array<HospitalImage>>;
}

export const useHospitalStore = create<HospitalState>((set) => ({
  grid: initialGridState,
  form: initialFormState,
  hospitalAccessKey: {
    data: null,
    loading: false,
  },
  setForm: (form: Form) => set(() => ({ form })),
  // Grid
  fetchHospitals: async (params?: AntGetListParams): Promise<ListResponse<Hospital>> => {
    set(produce((state) => {
      state.grid.loading = true;
    }));

    return getHospitals(params)
      .then((response) => {
        set(produce((state) => {
          state.grid.data = response.data;
          state.grid.meta = response.meta;
          state.grid.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.grid.loading = false;
        }));

        throw e;
      })
  },
  fetchHospitalAccessKey: async (id: number): Promise<HospitalAccessKey> => {
    set(produce((state) => {
      state.hospitalAccessKey.loading = true;
    }));

    return getHospitalAccessKey(id)
      .then((response) => {
        set(produce((state) => {
          state.hospitalAccessKey.data = response;
          state.hospitalAccessKey.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.hospitalAccessKey.loading = false;
        }));

        throw e;
      })
  },
  deleteHospital: async (id, payload?) => {
    return deleteHospital(id, payload)
  },

  // Form
  clearHospitalFormData: () => set(() => ({ form: initialFormState })),
  fetchHospital: async (id: number, params?: Params): Promise<Hospital> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return getHospital(id, params)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  createHospital: async (payload: CreateHospitalRequest): Promise<Hospital> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return createHospital(payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateHospital: async (id: number, payload: CreateHospitalRequest): Promise<Hospital> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return editHospital(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  createHospitalImage: async (id: number, payload: FormData) => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return createHospitalImages(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row.images = [...state.form.row.images, response];
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  deleteHospitalImage: async (id: number, payload?: AxiosRequestConfig) => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return deleteHospitalImage(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row.images = state.form.row.images.filter((i: HospitalImage) => i.id !== id);
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateHospitalImage: async (id: number, payload: UpdateHospitalImageRequest) => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateHospitalImage(id, payload)
      .then((response) => {
        set(produce((state) => {
          const imageIndex = state.form.row.images.findIndex((image: HospitalImage) => image.id === id);
          if (imageIndex !== -1) {
            state.form.row.images[imageIndex] = response;
          }
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateHospitalImageOrder: async (id: number, payload: UpdateHospitalImageOrderRequest) => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateHospitalImageOrder(id, payload)
      .then((response) => {
        console.log(response)
        set(produce((state) => {
          state.form.row.images = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
}));