import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { PaginationConfig } from '@components/AntGrid/types';
import { Tag } from '@modelTypes/tag';
import { tagColumns, tagFiltersConfig } from '@components/AntGrid/columns/tag';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import { useTagsStore } from '@zustandStorage/tags/tags';

export const [
  refreshTagsGridEvent,
  dispatchRefreshTagsGridEvent
] = makeRefreshGridEvent('onRefreshTagsGrid');

export default function TagsAntGrid() {
  // const dispatch = useDispatch();
  // const { data, meta, loading } = useSelector<TagsState['grid']>('tags.grid');
  const {fetchTags, grid: {data, loading, meta}} = useTagsStore()

  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
    // ...rest.paginationConfig,
  };

  const onFetchData = (params?: AntGetListParams) => {
    // dispatch(getTags(params));
    fetchTags(params)
  };

  useRefreshGridEvent(refreshTagsGridEvent, onFetchData);

  return (
    <Grid<Tag>
      id="tagsGrid"
      onFetch={onFetchData}
      columns={tagColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={tagFiltersConfig}
    />
  );
}