import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M13.5381 3.60077C13.6323 3.69501 13.7579 3.7469 13.8911 3.7469C14.0243 3.7469 14.1499 3.69501 14.2441 3.60077L14.9953 2.84961C15.1899 2.655 15.2971 2.39619 15.2971 2.12099C15.2971 1.8458 15.1899 1.58699 14.9953 1.39237C14.7105 1.10693 14.2646 1.01611 13.8911 1.16088C13.5169 1.01543 13.071 1.10693 12.7855 1.39305C12.3854 1.79458 12.3854 2.44809 12.7855 2.8503L13.5374 3.60145L13.5381 3.60077ZM13.4923 2.09777C13.5012 2.08958 13.5107 2.08821 13.5155 2.08821C13.5203 2.08821 13.5299 2.08958 13.5381 2.09777C13.7327 2.29239 14.0495 2.29239 14.2441 2.09777C14.2537 2.0889 14.2633 2.08753 14.2674 2.08753C14.2715 2.08753 14.281 2.08821 14.2892 2.09709C14.2974 2.10529 14.2988 2.11416 14.2988 2.11963C14.2988 2.12509 14.2974 2.13465 14.2892 2.14216L13.8911 2.54027L13.493 2.14216C13.4807 2.12987 13.4807 2.10938 13.493 2.09709L13.4923 2.09777Z"
    fill="currentColor" />
  <path
    d="M19.38 14.2528C19.38 14.528 19.4872 14.7869 19.6818 14.9815L20.433 15.7326C20.5272 15.8269 20.6529 15.8788 20.786 15.8788C20.9192 15.8788 21.0448 15.8269 21.1391 15.7326L21.8916 14.9808C22.2918 14.5793 22.2918 13.9258 21.8916 13.5235C21.6055 13.2381 21.1596 13.1473 20.7867 13.2921C20.4132 13.1473 19.9673 13.2381 19.6825 13.5235C19.4879 13.7182 19.3807 13.977 19.3807 14.2522L19.38 14.2528ZM20.379 14.2528C20.379 14.2467 20.3811 14.2378 20.3893 14.2296C20.4029 14.216 20.4152 14.2119 20.4337 14.2303C20.6283 14.4249 20.9451 14.4249 21.1398 14.2303C21.1486 14.2214 21.1582 14.2201 21.163 14.2201C21.1678 14.2201 21.1773 14.2214 21.1855 14.2289C21.1978 14.2412 21.1985 14.2624 21.1862 14.2747L20.7874 14.6728L20.39 14.2754C20.3818 14.2672 20.3804 14.2583 20.3804 14.2522L20.379 14.2528Z"
    fill="currentColor" />
  <path
    d="M15.3395 9.00909L16.9845 10.6534C17.0787 10.7477 17.2044 10.7996 17.3375 10.7996C17.4707 10.7996 17.5963 10.7477 17.6906 10.6534L19.3363 9.00841C19.9802 8.36173 19.9796 7.3067 19.3343 6.65661C19.0194 6.34317 18.6022 6.17109 18.159 6.17109C17.8688 6.17109 17.5861 6.24552 17.3375 6.38756C17.0883 6.24552 16.8063 6.17109 16.5154 6.17109C16.0708 6.17109 15.6529 6.34454 15.3381 6.65934C14.6955 7.30807 14.6962 8.36242 15.3401 9.00978L15.3395 9.00909ZM16.0462 7.36406C16.1712 7.2391 16.3378 7.17013 16.5154 7.17013C16.6929 7.17013 16.8595 7.2391 16.9845 7.36406C17.1791 7.55868 17.4953 7.55868 17.6906 7.36406C17.9405 7.11482 18.3782 7.11413 18.6275 7.36201C18.8856 7.62219 18.8863 8.0442 18.6288 8.30233L17.3375 9.59295L16.0469 8.30301C15.7888 8.0442 15.7888 7.62287 16.0455 7.36338L16.0462 7.36406Z"
    fill="currentColor" />
  <path
    d="M22.7834 0H11.8937C11.6185 0 11.3945 0.223981 11.3945 0.499177V1.10488L6.00532 3.3365C5.88241 3.38771 5.78681 3.48332 5.73559 3.60623C5.68506 3.72778 5.68506 3.86777 5.73559 3.98932L5.96777 4.54859L1.84324 8.67312C1.749 8.76736 1.69642 8.89301 1.69642 9.02617C1.69642 9.15933 1.74832 9.28497 1.84324 9.37921L2.2714 9.80737L0.0384114 15.1966C-0.0128038 15.3202 -0.0128038 15.4561 0.0384114 15.5797C0.0896266 15.7026 0.185234 15.7989 0.308833 15.8501L15.0643 21.9618C15.125 21.987 15.1892 22 15.2555 22C15.4583 22 15.6399 21.8791 15.7171 21.692L17.6728 16.97H22.7841C23.0593 16.97 23.284 16.746 23.284 16.4708V0.49986C23.284 0.224664 23.06 0.000682494 22.7841 0.000682494L22.7834 0ZM6.84867 4.06922L11.3938 2.18723V15.0422L6.84867 4.06922ZM15.2097 17.8932L13.4882 19.6147L12.7753 18.9018L15.2097 17.8939V17.8932ZM12.5786 17.9014L12.1928 16.97H14.8273L12.5786 17.9014ZM12.1259 19.6646L1.15149 15.1187L3.03484 10.5729L12.1259 19.6639V19.6646ZM2.901 9.02753L6.38022 5.549L11.3003 17.4268L2.901 9.02821V9.02753ZM15.9151 18.6007L14.9844 20.8487L14.0529 20.4629L15.9151 18.6007ZM22.2836 0.999038V15.9709H17.3751C17.358 15.9675 17.3259 15.9669 17.3068 15.9709H12.3929V0.999038H22.2836Z"
    fill="currentColor" />
  <path
    d="M1.80841 14.1518L2.2154 15.1338C2.37519 15.5209 2.7494 15.7709 3.16868 15.7709C3.30458 15.7709 3.43705 15.7442 3.5627 15.6923C3.81673 15.5865 4.01476 15.3885 4.11993 15.1344C4.16977 15.0142 4.19641 14.8886 4.19914 14.7595C4.2886 14.6667 4.35824 14.5588 4.40741 14.4393C4.51394 14.1852 4.51394 13.9046 4.40741 13.6499C4.19367 13.1343 3.57704 12.8789 3.06079 13.0927L2.07814 13.4996C1.82412 13.6048 1.70257 13.8978 1.80773 14.1525L1.80841 14.1518ZM3.19668 14.345C3.14615 14.4666 3.14615 14.6066 3.19668 14.7281C3.2001 14.7363 3.2001 14.7445 3.19737 14.7507C3.19327 14.7595 3.18712 14.7657 3.1803 14.7691C3.15913 14.7773 3.14342 14.7677 3.13727 14.752L2.92149 14.231L3.44183 14.0152C3.4589 14.0084 3.47803 14.0186 3.48418 14.0336C3.48759 14.0412 3.48759 14.0494 3.48418 14.0569C3.48076 14.0651 3.47462 14.0712 3.46574 14.0746C3.34419 14.1252 3.24585 14.2242 3.196 14.345H3.19668Z"
    fill="currentColor" />
  <path
    d="M5.54575 9.10401L5.46859 9.02685L5.62838 8.86706C5.823 8.67244 5.823 8.35559 5.62838 8.16097C5.53414 8.06674 5.4085 8.01415 5.27534 8.01415C5.14218 8.01415 5.01653 8.06605 4.92229 8.16097L4.76251 8.32076L4.60203 8.16029C4.40741 7.96567 4.09056 7.96567 3.89594 8.16029C3.70132 8.35491 3.70132 8.67176 3.89594 8.86637L4.05641 9.02685L3.89594 9.18732C3.8017 9.28156 3.7498 9.40652 3.74912 9.54037C3.74912 9.67421 3.80102 9.79918 3.89526 9.89409C3.98949 9.98833 4.11514 10.0402 4.2483 10.0402C4.38146 10.0402 4.50711 9.98833 4.60135 9.89409L4.76182 9.73362L4.83899 9.81078C4.73314 10.0006 4.76114 10.2458 4.92229 10.4069C5.01653 10.5012 5.14218 10.5531 5.27534 10.5531C5.4085 10.5531 5.53414 10.5012 5.62838 10.4069L6.1419 9.89341C6.33652 9.69879 6.33652 9.38194 6.1419 9.18732C5.98074 9.02617 5.73559 8.99817 5.54575 9.10401Z"
    fill="currentColor" />
  <path
    d="M9.83486 4.07058L8.65007 3.5796C8.52852 3.52907 8.38922 3.52907 8.26698 3.5796C8.14407 3.63082 8.04847 3.72642 7.99725 3.85002L7.50558 5.03616C7.45505 5.15771 7.45505 5.2977 7.50558 5.41925C7.5568 5.54217 7.6524 5.63777 7.776 5.68898L8.96215 6.17928C9.02292 6.20455 9.08711 6.21753 9.15335 6.21753C9.35616 6.21753 9.5378 6.09666 9.61497 5.90955L10.106 4.72409C10.2111 4.46938 10.0896 4.17643 9.83553 4.07127L9.83486 4.07058ZM8.99082 4.80194L8.88225 5.06416L8.61935 4.95558L8.72792 4.69268L8.99014 4.80125L8.99082 4.80194Z"
    fill="currentColor" />
</svg>`;

export default { id: '315-gaming-gambling-addiction', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
