import { FiltersConfig } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { MenuContent } from '@modelTypes/content';
import { Input, InputNumber, message, Popconfirm, Tooltip } from 'antd';
import React from 'react';
import { useMenuStore } from '@zustandStorage/menu/menu';
import { ProgramPrice } from '@modelTypes/treatment';
import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { dispatchRefreshContentsMenuItemsGridEvent } from '@containers/Contents/components/ContentsMenuItemsGrid';
import { getHistorySearchParam } from '@helpers/history';
import { useHistory } from 'react-router-dom';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import { deleteSubMenuItemLink } from '@storage/contents/thunkActions';
import getIdCell from '@components/AntGrid/cells/IdCell';

export type ContentMenuColumnsNames = `${keyof MenuContent}Column`
  | 'orderColumn'
  | 'linkColumn'
  | 'editColumn';

export const contentMenuItemsColumnsDefinitions: Partial<Record<ContentMenuColumnsNames, ColumnType<MenuContent>>> = {
  idColumn: createColumnDefinition<MenuContent>('#', 'id', { width: 100, render: getIdCell,  filtered: false, sorter: false }),
  orderColumn: createColumnDefinition<any>('Order', 'order', { width: 130, render: getOrderCell,  filtered: false, sorter: false }),
  nameColumn: createColumnDefinition<any>('Name', 'name', { render: getNameCell, filtered: false, sorter: false }),
  linkColumn: createColumnDefinition<any>('Link', 'link', { filtered: false, sorter: false }),
  editColumn: {
    title: '',
    width: 116,
    render: getEditCell<any>(),
    sorter: false,
    filtered: false,
    className: 'no-padding',
  },
};

export const contentMenuItemsColumns: any = [
  contentMenuItemsColumnsDefinitions.idColumn,
  contentMenuItemsColumnsDefinitions.orderColumn,
  contentMenuItemsColumnsDefinitions.nameColumn,
  contentMenuItemsColumnsDefinitions.linkColumn,
  contentMenuItemsColumnsDefinitions.editColumn,
];

export const contentMenuItemsFiltersConfig: FiltersConfig = {
  // [contentMenuItemsColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [contentMenuItemsColumnsDefinitions.keyColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [contentMenuItemsColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [contentMenuItemsColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [contentMenuItemsColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function OrderCell({ record }: { record: any }) {
  const history = useHistory();
  const { status, rowId, form } = useMenuStore((state) => state.subMenuGrid.editable);
  const { setSubMenuGridEditable } = useMenuStore();
  const languageId = +getHistorySearchParam(history, 'language-id', 1);

  if (rowId === record.id && status) {
    const onChange = (value: any) => {
      setSubMenuGridEditable({ status, rowId, form: { ...form, order: value } });
    };

    return (
      <div>
        <InputNumber
          disabled={languageId > 1}
          defaultValue={record.order}
          step={1}
          min={1}
          placeholder="Order"
          onChange={onChange}
        />
      </div>
    );
  }

  return (
    <>{record.order}</>
  );
}

function ValueCell({ record }: { record: any }) {
  const { status, rowId, form } = useMenuStore((state) => state.subMenuGrid.editable);
  const { setSubMenuGridEditable } = useMenuStore();

  if (rowId === record.id && status) {
    const onChange = (e: any) => {
      setSubMenuGridEditable({ status, rowId, form: { ...form, name: e.target.value } });
    };

    return (
      <div>
        <Input
          defaultValue={record.name}
          step={.01}
          min={0}
          placeholder="Name"
          onChange={onChange}
          // prefix="$"
        />
      </div>
    );
  }

  return (
    <>{record.name}</>
  );
}

function EditCell({ record }: { record: any }) {
  const history = useHistory();
  const { status, rowId, form } = useMenuStore((state) => state.subMenuGrid.editable);
  const { setSubMenuGridEditable, updateMenuSubItemLink } = useMenuStore();
  const [messageApi, contextHolder] = message.useMessage();
  const languageId = getHistorySearchParam(history, 'language-id', 1);

  const onClick = () => {
    setSubMenuGridEditable({ status: true, rowId: record.id, form: {} })
  };

  const onSave = () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving link name...',
      duration: 0,
    });

    const formData = {
      name: form.hasOwnProperty('name') ? form.name : record.name,
      order: form.hasOwnProperty('order') ? form.order : record.order,
      languageId: +languageId,
    }

    if (record.selectLinkId) {
      updateMenuSubItemLink(record.selectLinkId, formData)
        .then(() => {
          dispatchRefreshContentsMenuItemsGridEvent();
          setSubMenuGridEditable({ status: false, rowId: null, form: {} });
          messageApi.destroy('startCreate');
          message.success('The Link name has been successfully updated');
        });
    }
  };

  const onCancel = () => {
    setSubMenuGridEditable({ status: false, rowId: null, form: {} })
  };

  if (status && rowId === record.id) {
    return (
      <>
        {contextHolder}
        <Tooltip title="Save" placement="bottomRight">
          <SaveOutlined
            onClick={onSave}
            style={{ fontSize: 18, color: '#000', cursor: 'pointer', padding: 16 }}
          />
        </Tooltip>
        <Popconfirm title="Sure to cancel?" onConfirm={onCancel}>
          <Tooltip title="Cancel" placement="bottomRight">
            <CloseOutlined
              onClick={onClick}
              style={{ fontSize: 18, color: '#000', cursor: 'pointer', padding: 16 }}
            />
          </Tooltip>
        </Popconfirm>
      </>
    );
  }

  if (status) {
    return (
      <>
        <EditOutlined
          style={{ fontSize: 18, color: 'gray', cursor: 'default', padding: 16 }}
        />
      </>
    );
  }

  return (
    <>
      <Tooltip title="Edit item">
        <EditOutlined
          onClick={onClick}
          style={{ fontSize: 18, color: '#000', cursor: 'pointer', padding: 16 }}
        />
      </Tooltip>
      <DeleteCell
        record={record}
        title="Delete menu item"
        deleteThunkAction={deleteSubMenuItemLink}
        onSuccess={() => {
          dispatchRefreshContentsMenuItemsGridEvent();
          message.success('The menu item has been successfully deleted');
        }}
        triggerStyles={{ padding: '10px 15px', display: 'inline-block' }}
        hasNotRelationsContent={() => <div>The menu item has no any relations.</div>}
        hasRelationsContent={() => {
          return (
            <div style={{}}>
              <div>If you really want to continue, enter your password.</div>
            </div>
          );
        }}
      />
    </>
  );
}

function getOrderCell<T extends any>() {
  return (value: any, record: T) => {
    return <OrderCell record={record} />
  };
}

function getNameCell<T extends any>() {
  return (value: any, record: T) => {
    return <ValueCell record={record} />
  };
}

function getEditCell<T extends ProgramPrice>() {
  return (value: any, record: T) => {
    return (
      <EditCell record={record} />
    );
  };
}
