import { LineChartOutlined, ReloadOutlined } from '@ant-design/icons';
import { getUserActivities } from '@api/userActivitiesApi';
import { User } from '@modelTypes/user';
import { Button, DatePicker, InputNumber, Modal, Spin, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { CSSProperties, useEffect, useState } from 'react';
import styles from './ActivityCell.module.scss';

interface Props {
  record: User;
}

const formatMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const resultMinutes = Math.floor(minutes - hours * 60);

  return [`${hours}h`, `${resultMinutes}m`].filter((i) => !i.startsWith('0') || i.endsWith('m')).join(' ');
};

export default function ActivityCell({ record }: Props) {
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [activities, setActivities] = useState<{ activity: number; date: string }[]>([]);
  const [idleMinutes, setIdleMinutes] = useState(5);
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([dayjs().subtract(7, 'day'), dayjs()]);

  const refreshChart = async () => {
    if (!dateRange[0] || !dateRange[1]) {
      return;
    }

    setLoading(true);

    try {
      const data = await getUserActivities(record.id, {
        startDate: dateRange[0].toISOString(),
        endDate: dateRange[1].toISOString(),
        idleMinutes,
      });

      let activities: Record<string, number> = {};

      for (let i = 0; i <= dateRange[1].diff(dateRange[0], 'day'); i++) {
        activities[dateRange[0].add(i, 'day').toISOString().split('T')[0]] = 0;
      }

      activities = { ...activities, ...data };

      setActivities(Object.keys(activities).map((i) => ({ activity: activities[i], date: i })));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen || activities.length) {
      return;
    }

    refreshChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, idleMinutes]);

  return (
    <>
      <Tooltip title="View activity">
        <LineChartOutlined onClick={() => setOpen(true)} style={{ fontSize: 18, cursor: 'pointer' }} />
      </Tooltip>
      {isOpen && (
        <Modal open onCancel={() => setOpen(false)} title={`${record.name} activity`} footer={null}>
          <Spin spinning={isLoading}>
            <InputNumber
              className={styles.input}
              value={idleMinutes}
              onChange={(val) => setIdleMinutes(val ?? 5)}
              addonBefore={'Idle minutes'}
              addonAfter={
                <Button onClick={refreshChart}>
                  <ReloadOutlined />
                </Button>
              }
            />
            <DatePicker.RangePicker
              value={dateRange}
              format={'DD.MM.YYYY'}
              onChange={(e) => setDateRange([e?.[0] ?? null, e?.[1] ?? null])}
              className={styles.input}
            />
            <div className={styles.chart}>
              {activities.map((i) => (
                <Tooltip
                  key={JSON.stringify(i)}
                  placement="bottom"
                  title={`${formatMinutes(i.activity)} - ${new Date(i.date).toLocaleDateString()}`}
                >
                  <div
                    className={styles.bar}
                    style={
                      {
                        '--percent': `${Math.max(
                          (i.activity / Math.max(...activities.map((i) => i.activity))) * 100 || 1,
                          1
                        )}%`,
                      } as CSSProperties
                    }
                  />
                </Tooltip>
              ))}
            </div>
          </Spin>
        </Modal>
      )}
    </>
  );
}
