import { useHistory, useParams } from 'react-router-dom';

import { TemplateTypes } from '@modelTypes/template';
import { Button } from 'antd';
import TemplatesAntGrid from '@containers/Templates/components/TemplatesAntGrid';
import { PageHeader } from '@ant-design/pro-layout';

interface RouterParams {
  type: TemplateTypes;
}

const titles: Record<TemplateTypes, string> = {
  emails: 'Emails',
  legals: 'Legals',
};

const createButtonContents: Record<TemplateTypes, string> = {
  emails: 'Add Email',
  legals: 'Add Legal'
};

export default function TemplatesIndex(): JSX.Element {
  const history = useHistory();
  const { type } = useParams<RouterParams>();

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.push("/")}
        title={titles[type]}
        extra={(
          <Button
            key="createTemplate"
            type="primary"
            href={`/templates/${type}/create`}
            onClick={(e) => {
              e.preventDefault();

              history.push(`/templates/${type}/create`);
            }}
          >
            {createButtonContents[type]}
          </Button>
        )}
      />
      <TemplatesAntGrid />
    </>
  );
}
