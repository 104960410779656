import React from 'react';
import { Select } from 'antd';
import { AgeGroup } from '../../../../modelTypes/offer';

const ageGroupOptions = [
  {
    label: 'Children',
    value: AgeGroup.CHILDREN,
  },
  {
    label: 'Adults',
    value: AgeGroup.ADULTS,
  },
  {
    label: 'All',
    value: AgeGroup.ALL,
  }
]

export default function SelectAgeGroup(props: any) {
  return (
    <Select
      // style={{ width: 120 }}
      placeholder={'Select age group'}
      allowClear
      options={ageGroupOptions}
      {...props}
    />
  );
};

