import { getConditionTags } from '@api/conditionTagsApi';
import { Button, message, Spin } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Edge, Node } from '.';
import styles from './Playground.module.scss';

interface LevelNode extends Node {
  level?: number;
}

const getRelatedNodesLinksNumber = (edges: Edge[], uniqueResults: LevelNode[], node: Node) => {
  const edgesFrom: Edge[] = edges.filter((i) => i.source === node.id);
  const edgesTo: Edge[] = edges.filter((i) => i.target === node.id);

  return (
    edgesFrom.reduce(
      (accumulator, currentValue) => accumulator + (uniqueResults.some((i) => i.id === currentValue.target) ? 1 : 0),
      0
    ) +
    edgesTo.reduce(
      (accumulator, currentValue) => accumulator + (uniqueResults.some((i) => i.id === currentValue.source) ? 1 : 0),
      0
    )
  );
};

export default function Playground() {
  const [nodes, setNodes] = useState<LevelNode[]>();
  const [edges, setEdges] = useState<Edge[]>();
  const [isFetching, setFetching] = useState(false);
  const slider = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const history = useHistory();
  const selectedNodeId = useMemo(() => new URLSearchParams(location.search).get('selected'), [location.search]);

  const getRelatedNodes = (node: LevelNode, currentLevel: number, maxLevel: number): LevelNode[] => {
    if (!nodes || !edges) {
      return [];
    }

    const edgesFrom: Edge[] = edges.filter((i) => i.source === node.id);
    const edgesTo: Edge[] = edges.filter((i) => i.target === node.id);
    const nodesAll: LevelNode[] = [
      ...edgesFrom
        .map((i) => nodes.find((j) => j.id === i.target)!)
        .filter(Boolean)
        .map((i) => ({ ...i, level: currentLevel })),
      ...edgesTo
        .map((i) => nodes.find((j) => j.id === i.source)!)
        .filter(Boolean)
        .map((i) => ({ ...i, level: currentLevel })),
    ];

    const results = [{ ...node, level: currentLevel }, ...nodesAll.sort((a, b) => a.name.localeCompare(b.name))];

    if (currentLevel < maxLevel) {
      for (const node of [...results]) {
        results.push(...getRelatedNodes(node, currentLevel + 1, maxLevel));
      }
    }

    if (currentLevel === maxLevel) {
      const uniqueResults = results.filter((i, index) => !results.slice(0, index).some((j) => j.id === i.id));
      const nodeToCheck = uniqueResults[0];
      const edgesFrom: Edge[] = edges.filter((i) => i.source === nodeToCheck.id);
      const edgesTo: Edge[] = edges.filter((i) => i.target === nodeToCheck.id);
      const numberOfLinks =
        edgesFrom.reduce(
          (accumulator, currentValue) =>
            accumulator + (uniqueResults.some((i) => i.id === currentValue.target) ? 1 : 0),
          0
        ) +
        edgesTo.reduce(
          (accumulator, currentValue) =>
            accumulator + (uniqueResults.some((i) => i.id === currentValue.source) ? 1 : 0),
          0
        );

      console.log(`Checking node: ${nodeToCheck.name}`);
      console.log(numberOfLinks);
    }

    const uniqueResults = results.filter((i, index) => !results.slice(0, index).some((j) => j.id === i.id));

    return results
      .sort(
        (a, b) =>
          getRelatedNodesLinksNumber(edges, uniqueResults, b) - getRelatedNodesLinksNumber(edges, uniqueResults, a)
      )
      .sort((a, b) => (a.level ?? 0) - (b.level ?? 0))
      .filter((i, index) => !results.slice(0, index).some((j) => j.id === i.id))
      .sort((a, b) => (a.id === node.id ? -1 : b.id === node.id ? 1 : 0));
  };

  const filteredNodes = useMemo<LevelNode[]>(() => {
    if (!nodes || !edges) {
      return [];
    }

    if (!selectedNodeId) {
      return nodes.filter((i) => i.type === 100);
    }

    return getRelatedNodes(nodes.find((i) => i.id === selectedNodeId)!, 0, 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edges, nodes, selectedNodeId]);

  useEffect(() => {
    setFetching(true);

    (async () => {
      try {
        const data = await getConditionTags();
        const mappedNodes = data.nodes.map((i) => ({
          id: i.id,
          name: i.data.name,
          svg: i.data.svg,
          type: i.data.type,
        }));
        const mappedEdges = data.edges.map((i) => ({ source: i.source, target: i.target }));

        setNodes(mappedNodes as NonNullable<typeof nodes>);
        setEdges(mappedEdges as NonNullable<typeof edges>);
      } catch (err: any) {
        message.error(`Failed to load tags: ${err.message}`);
      } finally {
        setFetching(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!slider.current) {
      return;
    }

    slider.current.scrollLeft = 0;
  }, [selectedNodeId]);

  const setSelectedNode = (node: LevelNode | undefined | null) => {
    const searchParams = new URLSearchParams(location.search);
    node ? searchParams.set('selected', node.id) : searchParams.delete('selected');
    history.replace(`${location.pathname}?${searchParams}`);
  };

  return (
    <Spin spinning={isFetching}>
      <div ref={slider} className={styles.slider}>
        {filteredNodes.map((i) => (
          <Button
            key={i.id}
            className={styles.tag}
            onClick={() => (selectedNodeId === i.id ? setSelectedNode(undefined) : setSelectedNode(i))}
            type={selectedNodeId === i.id ? 'primary' : 'default'}
          >
            <div dangerouslySetInnerHTML={{ __html: i.svg }} />
            {i.name}
          </Button>
        ))}
      </div>
    </Spin>
  );
}
