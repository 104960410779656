import { RootState } from '@storage/appStorage';
import get from 'lodash/get';
import { useSelector as nativeUseSelector } from 'react-redux';

type SelectorCallback<Response> = (state: RootState) => Response;

export const useSelector = <TSelected = unknown>(
  selector: SelectorCallback<TSelected> | string,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected => {
  if (typeof selector === 'string') {
    return nativeUseSelector<RootState, TSelected>(
      (state: RootState) => get(state, selector),
      equalityFn
    );
  }

  return nativeUseSelector<RootState, TSelected>(selector, equalityFn);
};
