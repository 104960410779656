import { useHistory } from 'react-router-dom';
import { Button, message } from 'antd';
import DoctorsAntGrid from '@containers/Doctors/components/DoctorsAntGrid';
import { PageHeader } from '@ant-design/pro-layout';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import * as React from 'react';
import { Language } from '@modelTypes/language';
import { updateQueryParams } from '@helpers/history';
import { useDispatch } from 'react-redux';
import { updateFiltersCache } from '@storage/doctors/thunkActions';
import LocalStorage from '@base/LocalStorage';
import moment from 'moment';
import { useState } from 'react';

export default function DoctorsIndex(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const storedTime = LocalStorage.getObject('doctorsClearFiltersCacheAt');
  const tokensStoredAt = moment(storedTime);
  const [timeBetween, setTimeBetween] = useState(moment.duration(tokensStoredAt.diff(moment())));
  const handleOnUpdateFilters = () => {
    message.success('Start creating cache for Filters');
    LocalStorage.setObject('doctorsClearFiltersCacheAt', moment());
    setTimeBetween(moment.duration(moment(LocalStorage.getObject('doctorsClearFiltersCacheAt')).diff(moment())));

    dispatch(updateFiltersCache() as unknown as Promise<any>).then(() => {
      message.success('Started creating cache for Filters');
    });
  };

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.push("/")}
        title="Doctors"
        extra={[
          // ...!Object.keys(storedTime).length || timeBetween.hours() > 5 ? [
          //   <Button
          //     key="cache"
          //     type="primary"
          //     onClick={handleOnUpdateFilters}
          //     // style={{ display: 'none' }}
          //   >
          //     Update Filters
          //   </Button>,
          // ] : [],
          <SelectLanguagePerForm key="selectLanguage" updateParamKey={false} queryParamKey="language-id" />,
          <Button
            key="createDoctor"
            type="primary"
            href="/doctors/create"
            onClick={(e) => {
              e.preventDefault();

              history.push('/doctors/create');
            }}
          >
            Add Doctor
          </Button>,
        ]}
      />
      <DoctorsAntGrid />
    </>
    // <IndexPageLayout
    //   title="Doctors"
    //   headerActions={(
    //     <Button
    //       component={Link}
    //       to="/doctors/create"
    //       color="primary"
    //       variant="contained"
    //     >
    //       Add Doctor
    //     </Button>
    //   )}
    // >
    //   <DoctorsGrid />
    // </IndexPageLayout>
  );
}
