import { ReactNode } from 'react';
import styles from './FormContainer.module.scss';

interface Props {
  title: string;
  description?: string;
  children: ReactNode;
}

export default function FormContainer(
  {
    title,
    description,
    children,
  }: Props
): JSX.Element {
  return (
    <div className={styles.formContainer}>
      <div className={styles.title}>{title}</div>
      {typeof description === 'string' && <div className={styles.titleDescription}>{description}</div>}
      {children}
    </div>
  );
}
