import {
  AntGetListParams,
  CreateContentNavigationRequest,
  CreateContentNavigationResponse,
  ListResponse,
  UpdateContentNavigationRequest,
  UpdateContentNavigationResponse,
} from '@api/types';

import { AxiosRequestConfig } from 'axios';
import client from './client';
import { getRestUri } from '@api/helpers';
import { ContentNavigation } from '@modelTypes/contentNavigation';

const getUri = getRestUri('content-navigations');
const ROOT_URI = getUri('');

export async function getContentNavigations(params?: AntGetListParams) {
  return client.get<ListResponse<ContentNavigation>>(ROOT_URI.GET(), params);
}

export async function createContentNavigation(payload: CreateContentNavigationRequest) {
  return client.post<CreateContentNavigationRequest, CreateContentNavigationResponse>(ROOT_URI.POST(), payload);
}

export async function updateContentNavigation(id: number, payload: UpdateContentNavigationRequest) {
  return client.patch<UpdateContentNavigationRequest, UpdateContentNavigationResponse>(ROOT_URI.PATCH({ id }), payload);
}

export async function deleteContentNavigation(id: number, payload?: AxiosRequestConfig) {
  return client.del(ROOT_URI.DELETE({ id }), payload);
}
