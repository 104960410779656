import React from 'react';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const DeleteSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 48 48"
    xmlSpace="preserve"
  >
    <path d="M13.05 42q-1.2 0-2.1-.9-.9-.9-.9-2.1V10.5H8v-3h9.4V6h13.2v1.5H40v3h-2.05V39q0 1.2-.9 2.1-.9.9-2.1.9zm5.3-7.3h3V14.75h-3zm8.3 0h3V14.75h-3z"/>
  </svg>
);

export default function DeleteIcon(props: Partial<CustomIconComponentProps>) {
  return (
    <Icon
      component={DeleteSvg}
      {...props}
    />
  );
}
