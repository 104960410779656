import { useHistory } from 'react-router-dom';
import RolesAntGrid from './components/RolesAntGrid';
import { Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

export default function RolesIndex(): JSX.Element {
  const history = useHistory();

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => history.push("/")}
        title="Roles"
        extra={(
          <Button
            key="createRole"
            type="primary"
            href="/roles/create"
            onClick={(e) => {
              e.preventDefault();

              history.push("/roles/create");
            }}
          >
            Add Role
          </Button>
        )}
      />
      <RolesAntGrid />
    </>
  );
}
