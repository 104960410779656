// import './Grid.scss';

import { useEffect, useRef, useState } from 'react';
import { Table } from 'antd';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import _ from 'lodash';

// import { ColumnsVisibility, getDefaultColumnVisibility, useColumnsVisibility, } from 'components/ColumnsVisibility';

import {
  DEFAULT_PAGINATION_CONFIG,
  getExpandableConfig,
  getPaginationConfig,
  getPreparedColumns,
  getScrollConfig,
  getSelectionConfig,
} from './helpers/helpers';

import { BaseRow, FilterParams, FiltersParams, GridProps, PaginationParams, SortParams } from './types';

import stylesheet from './Grid.module.scss';
import { AntGetListParams, GetListParams } from '@api/types';
import { getHistorySearchParam, updateQueryParams } from '@helpers/history';
import { useHistory } from 'react-router-dom';
import { useGridStore } from '@zustandStorage/grid/grid';

export default function Grid<Row extends BaseRow>({
  id,
  className,
  loading,
  data = [],
  columns = [],
  enableFiltering = false,
  enableSorting = false,
  enableSelection = false,
  onFetch,
  expandableRowRenderer,
  expandableRow,
  selection,
  paginationConfig = DEFAULT_PAGINATION_CONFIG,
  customPage,
  customPerPage,
  filtersConfig,
  scroll,
  instance,
  header,
  footer,
}: GridProps<Row>): JSX.Element {
  const history = useHistory();
  const columnsVisibility = undefined;
  // const [columnsVisibility, setColumnsVisibility] = useState(getDefaultColumnVisibility(columnsEntity));

  const sort = useRef<SortParams>({ field: '', order: null });
  const filter = useRef<FiltersParams>({});
  const defaultPage = customPage ? customPage : getHistorySearchParam(history, 'page', 1);
  const perPage = getHistorySearchParam(history, 'per-page', 20);
  const pagination = useRef<PaginationParams>({ perPage: +perPage, page: +defaultPage });
  const [expandedRowKeys, setExpandedRowKeys] = useState<Array<number>>([]);

  const handleRowExpand = (expanded: any, record: any) => {
    if (expanded) {
      setExpandedRowKeys([record.id]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  // fetch fata on init, after filter or sorting change
  const handleFetchData = () => {
    // don't fetch data when some static data provided
    if (!onFetch) return;

    const params: AntGetListParams = {};

    if (enableSorting && sort.current.field && sort.current.order) {
      params.sort = {
        field: sort.current.field,
        sort: sort.current.order === 'ascend' ? 'asc' : sort.current.order === 'descend' ? 'desc' : '',
      };
    }

    if (enableFiltering && !_.isEmpty(filter)) {
      params.filter = filter.current;
    }

    if (paginationConfig?.serverSide) {
      params.perPage = pagination.current.perPage;
      params.page = pagination.current.page;
    }

    onFetch(params);
  };

  const handleFilter = (field: string, params: FilterParams): void => {
    if (!_.isEqual(filter.current[field], params)) {
      filter.current[field] = params;
      pagination.current.page = 1;
      handleFetchData();
    }
  };

  const handleResetAllFilters = (): void => {
    if (!_.isEmpty(filter.current)) {
      filter.current = {};
      pagination.current.page = 1;
      handleFetchData();
    }
  };

  const handleResetFilter = (field: string): void => {
    if (!_.isEmpty(filter.current[field])) {
      filter.current = _.omit(filter.current, field);
      pagination.current.page = 1;
      handleFetchData();
    }
  };

  const handleSort = ({ field, order }: SorterResult<Row>, wait = false): void => {
    if (!_.isEqual(sort.current.field, field) || !_.isEqual(sort.current.order, order)) {
      sort.current.field = field as string;
      sort.current.order = order;
    }
  };

  const handlePagination = ({ current, pageSize }: TablePaginationConfig): void => {
    if (paginationConfig?.serverSide && _.isNumber(pageSize) && _.isNumber(current)) {
      pagination.current.page = current;
      pagination.current.perPage = pageSize;

      updateQueryParams(history, {
        page: current > 1 ? current : null,
        'per-page': pageSize !== 20 ? pageSize : null,
      });
    }
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<Row> | SorterResult<Row>[]
  ): void => {
    console.log(pagination)
    if (!_.isEmpty(sorter)) handleSort(sorter as SorterResult<Row>);
    if (!_.isEmpty(pagination)) handlePagination(pagination);

    handleFetchData();
  };

  // uses to lift some grid functionality
  instance &&
    instance({
      refreshData: handleFetchData,
      resetAllFilters: handleResetAllFilters,
    });

  const preparedPaginationConfig = getPaginationConfig({
    ...paginationConfig,
    disabled: paginationConfig.disabled || _.isEmpty(data),
    pageSize: pagination.current.perPage,
    current: pagination.current.page,
  });
  // const selectionConfig = enableSelection ? getSelectionConfig<Row>(selection) : {};
  // const expandableConfig = getExpandableConfig<Row>({ renderer: expandableRowRenderer, expandable: expandableRow });
  const scrollConfig = getScrollConfig<Row>(scroll, columns, columnsVisibility);

  const preparedColumns = getPreparedColumns<Row>({
    enableFiltering,
    enableSorting,
    columns,
    columnsVisibility,
    filtersConfig,
    filter: filter.current,
    onFilter: handleFilter,
    onResetFilter: handleResetFilter,
  });

  // useColumnsVisibility(columnsEntity, columnsVisibility, (updatedColumnsVisibility) => {
  //   setColumnsVisibility(updatedColumnsVisibility);
  // }, [columnsVisibility]);

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div className={stylesheet.root}>
      {/*{columnsEntity && <ColumnsVisibility entity={columnsEntity} />}*/}
      <Table
        className={className}
        id={id}
        rowKey="id"
        loading={loading}
        dataSource={data}
        columns={preparedColumns}
        scroll={scrollConfig}
        {...preparedPaginationConfig}
        expandable={{
          // ...expandableConfig,
          // expandRowByClick: true,
          expandedRowRender: expandableRowRenderer,
          expandedRowKeys,
          onExpand: handleRowExpand,
        }}
        // title={header}
        // footer={footer}

        onChange={handleTableChange}
      />
    </div>
  );
}
