import { Doctor, DoctorTranslation } from '@modelTypes/doctor';
import { ListResponse } from '@api/types';
import { createAction } from '../helpers';
import { HospitalTranslation } from '@modelTypes/hospital';

export enum ActionType {
  START_FETCH_DOCTORS = 'DOCTORS__START_FETCH_DOCTORS',
  END_FETCH_DOCTORS = 'DOCTORS__END_FETCH_DOCTORS',

  START_FETCH_DOCTORS_FOR_SELECT = 'DOCTORS__START_FETCH_DOCTORS_FOR_SELECT',
  END_FETCH_DOCTORS_FOR_SELECT = 'DOCTORS__END_FETCH_DOCTORS_FOR_SELECT',

  START_FETCH_DOCTOR = 'DOCTORS__START_FETCH_DOCTOR',
  END_FETCH_DOCTOR = 'DOCTORS__END_FETCH_DOCTOR',

  START_POST_DOCTOR = 'DOCTORS__START_POST_DOCTOR',
  END_POST_DOCTOR = 'DOCTORS__END_POST_DOCTOR',
  END_POST_DOCTOR_ERROR = 'DOCTORS__END_POST_DOCTOR_ERROR',

  START_PATCH_DOCTOR = 'DOCTORS__START_PATCH_DOCTOR',
  END_PATCH_DOCTOR = 'DOCTORS__END_PATCH_DOCTOR',
  END_PATCH_DOCTOR_ERROR = 'DOCTORS__END_PATCH_DOCTOR_ERROR',

  START_DELETE_DOCTOR = 'DOCTORS__START_DELETE_DOCTOR',
  END_DELETE_DOCTOR = 'DOCTORS__END_DELETE_DOCTOR',

  CLEAR_FORM_DATA = 'DOCTORS__CLEAR_FORM_DATA',

  // Content.
  START_FETCH_TRANSLATIONS = 'DOCTORS__START_FETCH_TRANSLATIONS',
  END_FETCH_TRANSLATIONS = 'DOCTORS__END_FETCH_TRANSLATIONS',

  START_FETCH_TRANSLATION = 'DOCTORS__START_FETCH_TRANSLATION',
  END_FETCH_TRANSLATION = 'DOCTORS__END_FETCH_TRANSLATION',

  START_PATCH_TRANSLATION = 'DOCTORS__START_PATCH_TRANSLATION',
  END_PATCH_TRANSLATION = 'DOCTORS__END_PATCH_TRANSLATION',
  END_PATCH_TRANSLATION_ERROR = 'DOCTORS__END_PATCH_TRANSLATION_ERROR',
}

export const startFetchDoctors = createAction<ActionType.START_FETCH_DOCTORS>(ActionType.START_FETCH_DOCTORS);
export const endFetchDoctors = createAction<ActionType.END_FETCH_DOCTORS, ListResponse<Doctor>>(ActionType.END_FETCH_DOCTORS);

export const startFetchDoctorsForSelect = createAction<ActionType.START_FETCH_DOCTORS_FOR_SELECT>(ActionType.START_FETCH_DOCTORS_FOR_SELECT);
export const endFetchDoctorsForSelect = createAction<ActionType.END_FETCH_DOCTORS_FOR_SELECT, ListResponse<Doctor>>(ActionType.END_FETCH_DOCTORS_FOR_SELECT);

export const startFetchDoctor = createAction<ActionType.START_FETCH_DOCTOR>(ActionType.START_FETCH_DOCTOR);
export const endFetchDoctor = createAction<ActionType.END_FETCH_DOCTOR, Doctor>(ActionType.END_FETCH_DOCTOR);

export const startPostDoctor = createAction<ActionType.START_POST_DOCTOR>(ActionType.START_POST_DOCTOR);
export const endPostDoctor = createAction<ActionType.END_POST_DOCTOR, Doctor>(ActionType.END_POST_DOCTOR);
export const endPostDoctorError = createAction<ActionType.END_POST_DOCTOR_ERROR>(ActionType.END_POST_DOCTOR_ERROR);

export const startUpdateDoctor = createAction<ActionType.START_PATCH_DOCTOR>(ActionType.START_PATCH_DOCTOR);
export const endUpdateDoctor = createAction<ActionType.END_PATCH_DOCTOR, Doctor>(ActionType.END_PATCH_DOCTOR);
export const endUpdateDoctorError = createAction<ActionType.END_PATCH_DOCTOR_ERROR>(ActionType.END_PATCH_DOCTOR_ERROR);

export const startDeleteDoctor = createAction<ActionType.START_DELETE_DOCTOR>(ActionType.START_DELETE_DOCTOR);
export const endDeleteDoctor = createAction<ActionType.END_DELETE_DOCTOR>(ActionType.END_DELETE_DOCTOR);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);


// Contents.
export const startFetchTranslations = createAction<ActionType.START_FETCH_TRANSLATIONS>(ActionType.START_FETCH_TRANSLATIONS);
export const endFetchTranslations = createAction<ActionType.END_FETCH_TRANSLATIONS, ListResponse<DoctorTranslation>>(ActionType.END_FETCH_TRANSLATIONS);

export const startFetchTranslation = createAction<ActionType.START_FETCH_TRANSLATION>(ActionType.START_FETCH_TRANSLATION);
export const endFetchTranslation = createAction<ActionType.END_FETCH_TRANSLATION, Array<DoctorTranslation>>(ActionType.END_FETCH_TRANSLATION);

export const startPatchTranslation = createAction<ActionType.START_PATCH_TRANSLATION>(ActionType.START_PATCH_TRANSLATION);
export const endPatchTranslation = createAction<ActionType.END_PATCH_TRANSLATION, Array<DoctorTranslation>>(ActionType.END_PATCH_TRANSLATION);
export const endPatchTranslationError = createAction<ActionType.END_PATCH_TRANSLATION_ERROR>(ActionType.END_PATCH_TRANSLATION_ERROR);


export type Actions =
  | ReturnType<typeof startFetchDoctors>
  | ReturnType<typeof endFetchDoctors>
  | ReturnType<typeof startFetchDoctorsForSelect>
  | ReturnType<typeof endFetchDoctorsForSelect>
  | ReturnType<typeof startFetchDoctor>
  | ReturnType<typeof endFetchDoctor>
  | ReturnType<typeof startPostDoctor>
  | ReturnType<typeof endPostDoctor>
  | ReturnType<typeof endPostDoctorError>
  | ReturnType<typeof startUpdateDoctor>
  | ReturnType<typeof endUpdateDoctor>
  | ReturnType<typeof endUpdateDoctorError>
  | ReturnType<typeof startDeleteDoctor>
  | ReturnType<typeof endDeleteDoctor>
  | ReturnType<typeof clearFormData>
  | ReturnType<typeof startFetchTranslations>
  | ReturnType<typeof endFetchTranslations>
  | ReturnType<typeof startFetchTranslation>
  | ReturnType<typeof endFetchTranslation>
  | ReturnType<typeof startPatchTranslation>
  | ReturnType<typeof endPatchTranslation>
  | ReturnType<typeof endPatchTranslationError>;