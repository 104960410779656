import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M22.1314 3.51936C21.0873 1.54118 19.489 0.309993 17.6301 0.0521866C15.7619 -0.207048 13.7173 0.507811 12.0212 2.0118C10.2594 3.57363 9.10537 5.76035 8.77043 8.17059C8.73187 8.44982 8.92683 8.70834 9.20749 8.74761C9.48672 8.78546 9.74453 8.58979 9.78309 8.31127C10.0837 6.14241 11.1193 4.17709 12.6989 2.77736C14.1722 1.47119 15.9183 0.847744 17.4894 1.06556C19.0134 1.27695 20.3403 2.31817 21.2273 3.99784C22.1428 5.73321 22.4756 7.92563 22.1635 10.1716C21.8464 12.459 20.9059 14.5022 19.5162 15.9248C19.4455 15.9969 19.3983 16.0869 19.3798 16.1861C19.0984 17.6573 18.4692 18.9585 17.6087 19.8497C16.7917 20.6953 15.8405 21.0866 14.9285 20.9516C13.9601 20.8081 13.1089 20.0918 12.5311 18.9349C12.4054 18.6828 12.0976 18.58 11.8448 18.7057C11.5927 18.8321 11.4906 19.1391 11.6163 19.3912C12.3476 20.8552 13.4702 21.7679 14.7785 21.9622C14.9471 21.9871 15.1185 22 15.2884 22C16.3768 22 17.4337 21.5015 18.3435 20.5596C19.3091 19.5598 20.0218 18.1301 20.3539 16.5282C21.8357 14.9571 22.8369 12.7525 23.1754 10.3116C23.5161 7.85493 23.1447 5.44255 22.1307 3.52007L22.1314 3.51936Z"
    fill="currentColor" />
  <path
    d="M16.3375 14.1445C16.3932 14.2694 16.4946 14.3644 16.6224 14.413C16.681 14.4351 16.7424 14.4465 16.8045 14.4465C17.0152 14.4465 17.208 14.3137 17.2823 14.1166C17.9414 12.3841 17.9343 10.5409 17.263 9.05974C16.7609 7.95211 15.9447 7.16155 14.9027 6.77448C14.6385 6.67664 14.3436 6.81162 14.245 7.07514C14.1972 7.20297 14.2022 7.34223 14.2593 7.46649C14.3164 7.59075 14.4178 7.68502 14.5457 7.73286C15.3262 8.02281 15.9433 8.62769 16.3303 9.4818C16.8902 10.7166 16.8888 12.2734 16.3253 13.7524C16.2768 13.8802 16.281 14.0188 16.3367 14.143L16.3375 14.1445Z"
    fill="currentColor" />
  <path
    d="M12.2811 14.3393H13.0896C13.3059 14.9678 13.9087 15.4077 14.5843 15.4077C15.4555 15.4077 16.1647 14.6986 16.1647 13.8273C16.1647 12.9561 15.4555 12.2469 14.5843 12.2469C14.0194 12.2469 13.5052 12.5547 13.2252 13.0232L13.0896 13.316H11.8905C11.6606 13.316 11.4577 13.4709 11.397 13.693L11.0578 14.9378L9.86805 11.2735C9.80164 11.07 9.61382 10.9286 9.40172 10.9207C9.18747 10.9122 8.98966 11.04 8.90896 11.2385L8.06341 13.3167H6.64226C6.41516 13.3167 6.21877 13.4624 6.15307 13.6795L5.6446 15.3527L4.34843 10.6458C4.29201 10.4408 4.11133 10.2909 3.89923 10.2723C3.68713 10.2544 3.48431 10.3716 3.39362 10.563L2.09244 13.3167H0.511328C0.22924 13.3167 0 13.5466 0 13.828C0 14.1094 0.22924 14.3393 0.511328 14.3393H2.41524C2.61163 14.3393 2.79373 14.2244 2.87729 14.0466L3.72784 12.2462L5.12685 17.3259C5.18683 17.5437 5.38679 17.698 5.61961 17.7015C5.84599 17.7015 6.04238 17.5559 6.1088 17.3388L7.02076 14.3386H8.4062C8.61473 14.3386 8.80041 14.2137 8.87968 14.0201L9.32745 12.9203L10.6136 16.8838C10.6836 17.0995 10.8836 17.2402 11.1121 17.2373C11.3363 17.2323 11.5342 17.0774 11.5934 16.861L12.2804 14.3401L12.2811 14.3393ZM14.5836 13.2703C14.8913 13.2703 15.1413 13.5202 15.1413 13.828C15.1413 14.1358 14.8913 14.3858 14.5836 14.3858C14.2758 14.3858 14.0258 14.1358 14.0258 13.828C14.0258 13.5202 14.2758 13.2703 14.5836 13.2703Z"
    fill="currentColor" />
  <path
    d="M15.8284 15.6955C15.5484 15.6955 15.3192 15.9233 15.317 16.2026C15.3156 16.339 15.3685 16.4682 15.4642 16.5654C15.5598 16.6625 15.6877 16.7168 15.8262 16.7175H15.8526C18.4986 16.7175 20.6917 14.0216 20.7417 10.7072C20.7688 8.85331 20.1332 7.12365 18.9963 5.96174C18.1458 5.09191 17.1088 4.62843 15.9998 4.62057C15.8641 4.61772 15.7355 4.66985 15.6377 4.76554C15.5391 4.86195 15.4842 4.9905 15.4834 5.12833C15.4813 5.41042 15.7091 5.64109 15.9912 5.64323C16.8239 5.64966 17.6109 6.00673 18.2665 6.67731C19.2141 7.64641 19.744 9.1104 19.7205 10.6929C19.679 13.4517 17.9444 15.6955 15.8541 15.6955H15.8284Z"
    fill="currentColor" />
  <path
    d="M16.5738 2.90521C13.7858 2.90521 11.5184 5.17333 11.5184 7.96064C11.5184 9.42606 12.1532 10.8172 13.2602 11.7785C13.353 11.8592 13.4723 11.9034 13.5951 11.9034C13.7437 11.9034 13.8843 11.8392 13.9815 11.727C14.1657 11.5142 14.1436 11.1907 13.9308 11.0058C13.0474 10.2388 12.541 9.12826 12.541 7.95992C12.541 5.73608 14.35 3.92715 16.5738 3.92715C17.7607 3.92715 18.8819 4.44704 19.6503 5.35258C19.7389 5.45684 19.8624 5.5204 19.9981 5.53183C20.1345 5.54254 20.2666 5.50041 20.3702 5.41185C20.4745 5.3233 20.538 5.19975 20.5487 5.06407C20.5594 4.92838 20.5173 4.79555 20.4288 4.69128C19.4654 3.55651 18.0599 2.90521 16.5731 2.90449L16.5738 2.90521Z"
    fill="currentColor" />
</svg>`;

export default { id: '99-audiology', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
