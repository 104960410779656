import { create } from 'zustand';
import produce from 'immer';
import { ListResponse, Params } from '@api/types';
import { initialGridState } from '@zustandStorage/helpers/initialStates';
import { getDoctorPublications } from '@api';
import { Publication } from '@modelTypes/publication';

export interface DoctorPublicationsState {
  grid: ListResponse<Publication> & { loading: boolean; };
  fetchDoctorPublications: (id: number, params?: Params) => Promise<Publication>;
}

export const useDoctorPublicationsStore = create<DoctorPublicationsState>((set) => ({
  grid: initialGridState,
  fetchDoctorPublications: async (id: number, params?: Params): Promise<any> => {
    set(produce((state) => {
      state.grid.loading = true;
    }));

    return getDoctorPublications(id, params)
      .then((response) => {
        set(produce((state) => {
          state.grid.data = response.data;
          state.grid.meta = response.meta;
          state.grid.loading = false;
        }));

        return response;
      });
  },
}));