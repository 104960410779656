import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useSelector } from '@hooks';
import {
  Form, FormInstance,
} from 'antd';
import { getDoctorsForSelect } from '@storage/doctors/thunkActions';
import { DoctorsState } from '@storage/doctors/reducer';
import SelectDoctor from '@base/Select/SelectDoctor';

interface Props {
  form: FormInstance;
  initialValue?: any;
}

export default function DoctorsField(
  {
    form,
    initialValue,
  }: Props
) {
  const dispatch = useDispatch();
  const data = useSelector<DoctorsState['selectDoctor']>('doctors.selectDoctor');
  const [count, setCount] = React.useState<number>();
  const filteredData = useMemo(
    () => {
      return data.data.filter((i) => i.isActive);
    },
    [data.fetched, data.loading]
  );

  useEffect(() => {
    if (!data.fetched && !data.loading) {
      dispatch(getDoctorsForSelect() as unknown as Promise<void>)
        .then(() => {
          form.resetFields(['doctors']);
        });
    }
  }, [data.fetched]);

  useEffect(() => {
    if (initialValue && initialValue.length) {
      setCount(initialValue.length);
    }
  }, [initialValue]);

  return (
    <Form.Item
      name="doctors"
      label={`Doctors ${count ? `(${count} selected)` : ''}`}
      initialValue={data.fetched ? initialValue : []}
      valuePropName="defaultValue"
    >
      <SelectDoctor
        mode="multiple"
        // disabled={languageId > 1}
        loading={data.loading}
        options={filteredData}
        onChange={() => setCount(form.getFieldValue('doctors')?.length)}
        style={{ width: '100%' }}
      />
    </Form.Item>
  );
}
