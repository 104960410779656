import React, { CSSProperties, ReactNode } from 'react';
import { Button, Form, Input, Modal, Spin, Tooltip } from 'antd';
import useDeleteModal, { DeleteModalProps, UseDeleteModalOptions } from '@hooks/useDeleteModal';
import DeleteIcon from '@components/AntGrid/icons/delete';
import axios from 'axios';
import { FieldData } from 'rc-field-form/es/interface';

export interface DeleteCellProps {
  record: any;
  title: string;
  deleteThunkAction?: UseDeleteModalOptions['deleteThunkAction'];
  deleteAction?: UseDeleteModalOptions['deleteAction']
  extendedDeleteAction?: UseDeleteModalOptions['extendedDeleteAction']
  onSuccess: UseDeleteModalOptions['onSuccess'];
  hasNotRelationsContent: UseDeleteModalOptions['hasNotRelationsContent'];
  hasRelationsContent: UseDeleteModalOptions['hasRelationsContent'];
  trigger?: ReactNode;
  triggerStyles?: CSSProperties;
  customDeleteIcon?: ReactNode;
}

const initialDeleteModalState: DeleteModalProps = {
  open: false,
  loading: false,
  title: 'Delete',
};

export function DeleteCell(
  {
    record,
    title,
    deleteThunkAction,
    onSuccess,
    hasNotRelationsContent,
    hasRelationsContent,
    trigger,
    triggerStyles,
    deleteAction,
    extendedDeleteAction,
    customDeleteIcon
  }: DeleteCellProps
) {
  const [form] = Form.useForm();
  const [deleteModal, onDeleteRow, onCancel] = useDeleteModal(
    { ...initialDeleteModalState, title },
    { deleteAction, extendedDeleteAction, deleteThunkAction, onSuccess, hasNotRelationsContent, hasRelationsContent }
  );

  const handleOnSubmitForm = (values: any) => {
    return onDeleteRow({ id: record.id }, { data: { force: true, ...values } })
      .catch((e) => {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 422 && e.response?.data.errors) {
            const fields: Array<FieldData> = [];

            e.response?.data.errors.forEach((i: any) => {
              fields.push({
                name: i.field === 'password' ? 'confirmPassword' : i.field,
                value: values[i.field],
                errors: [i.message],
              });
            });

            form.setFields(fields);
          }
        }
      });
  };

  const handleSubmitForm = () => {
    if (deleteModal.showConfirmForm) {
      form.submit();
    } else {
      onDeleteRow({ id: record.id }, { data: { force: true } });
    }
  };

  const handleOnClickDeleteIcon = () => {
    onDeleteRow({ id: record.id }, {});
  };

  return (
    <>
      {trigger && (
        <div onClick={() => handleOnClickDeleteIcon()} style={triggerStyles}>
          <Spin spinning={!deleteModal.open && deleteModal.loading}>
            {trigger}
          </Spin>
        </div>
      )}
      {!trigger && (
        <span style={triggerStyles}>
          <Tooltip title="Delete item">
            <Spin spinning={!deleteModal.open && deleteModal.loading}>
              <div onClick={() => handleOnClickDeleteIcon()}>
                {customDeleteIcon ? customDeleteIcon :
                  <DeleteIcon style={{ fontSize: 24, cursor: 'pointer' }} />
                }
              </div>
            </Spin>
          </Tooltip>
        </span>
      )}
      <Modal
        closable
        title={deleteModal.title}
        open={deleteModal.open}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="clear-by-url" danger type="primary" loading={deleteModal.loading} onClick={handleSubmitForm}>
            Delete
          </Button>
        ]}
      >
        <Spin spinning={deleteModal.loading}>
          {deleteModal.content}
          {deleteModal.showConfirmForm && (
            <Form
              form={form}
              layout="vertical"
              onFinish={handleOnSubmitForm}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
              style={{ marginTop: 24, marginBottom: 34 }}
            >
              <Form.Item
                name="confirmPassword"
                label="Password"
                rules={[{ required: true, message: '"Password" is required' }, { type: 'string', warningOnly: true }]}
              >
                <Input placeholder="Password" type="password" autoComplete="new-password" />
              </Form.Item>
            </Form>
          )}
        </Spin>
      </Modal>
    </>
  );
}