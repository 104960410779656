import { Card, Checkbox, Form, Image, Input, message, Modal, Skeleton } from 'antd';
import 'cropperjs/dist/cropper.css';
import UploadPhotoModal from '@components/UploadPhotoModal';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { CreateDoctorRequest } from '@api/types';
import { useCertificatesStore } from '@zustandStorage/certificates/certificates';
import { dispatchRefreshCertificatesGridEvent } from '@containers/Certificates/components/CertificatesAntGrid';
import { Certificate } from '@modelTypes/certificate';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  data?: Certificate;
}

export default function CreateModal({ isOpen, closeModal, data }: ModalProps) {
  const { confirm } = Modal;
  const [prevImage, setPrevImage] = useState<Blob | null>(null);
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm<CreateDoctorRequest>();
  const {
    fetchCertificate,
    updateCertificate,
    createCertificate,
    clearFormData,
    form: { row, loading },
  } = useCertificatesStore();

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const handleSaveImage = (cropperRef: Cropper, type: string) => {
    cropperRef.getCroppedCanvas().toBlob(
      (blob: Blob | null) => {
        if (blob) {
          setPrevImage(blob);
        } else {
          message.error('An error save photo, please try again now');
        }
      },
      type,
      type === 'image/jpeg' || type === 'image/jpg' ? 0.95 : undefined
    );
  };

  const resetForm = () => {
    console.log('default, ', 'resetForm --- ');

    form.resetFields();
    setFormChanged(false);
    setPrevImage(null);
  };

  const showConfirm = () => {
    confirm({
      title: 'Data not saved!',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to close modal window?',
      onOk() {
        closeModal();
        resetForm();
      },
      // onCancel() {
      //
      // },
    });
  };

  const handleCloseModal = () => {
    if (formChanged || prevImage) {
      showConfirm();
    } else {
      closeModal();
      resetForm();
      clearFormData();
    }
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving image...',
      duration: 0,
    });

    const dataForm = form.getFieldsValue(true);
    const formData = new FormData();
    formData.append('name', dataForm.name);
    formData.append('isActive', dataForm.isActive);

    if (!data && prevImage) {
      formData.append('photoToSave', prevImage);

      await createCertificate(formData)
        .then((data: any) => {
          messageApi.destroy('startCreate');
          message.success('The certificate has been successfully created');
          clearFormData();
          resetForm();
          closeModal();
          dispatchRefreshCertificatesGridEvent();
        })
        .catch((e: any) => {
          messageApi.destroy('startCreate');
          message.error('An error occurred, please try again now');
        });
    } else if (data) {
      if (prevImage) {
        formData.append('photoToSave', prevImage);
      }
      await updateCertificate(data?.id, formData)
        .then((data: any) => {
          messageApi.destroy('startCreate');
          message.success('The certificate has been successfully update');
          clearFormData();
          resetForm();
          closeModal();
          dispatchRefreshCertificatesGridEvent();
        })
        .catch((e: any) => {
          messageApi.destroy('startCreate');
          message.error('An error occurred, please try again now');
        });
    }
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    if (data) {
      fetchCertificate(data.id).then(() => form.resetFields());
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      resetForm();
      clearFormData();
    };
  }, []);

  return (
    <Modal
      okButtonProps={{ disabled: prevImage === null && !row }}
      title={<div>Add certificate</div>}
      open={isOpen}
      onCancel={handleCloseModal}
      onOk={form.submit}
      bodyStyle={{ maxHeight: '70vh' }}
      width={510}
      okText={'Save'}
    >
      {contextHolder}
      <Card
        style={{ width: 460 }}
        bodyStyle={{ padding: 0 }}
        cover={
          !prevImage && !row?.imgPath ? (
            <Skeleton.Image style={{ width: 460, height: 60 }} active={false} />
          ) : (
            <div
              style={{
                width: 60,
                height: 60,
                margin: 'auto',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Image src={prevImage ? URL.createObjectURL(prevImage) : row?.imgPath} preview={false} />
            </div>
          )
        }
        actions={[
          <UploadPhotoModal
            cropperProps={{
              // initialAspectRatio: 1.6,
              viewMode: 0,
              minCropBoxHeight: 10,
              minCropBoxWidth: 10,
              // background: false,
              responsive: true,
              // aspectRatio: 1.6,
              guides: true,
            }}
            handleSaveImage={handleSaveImage}
            key={'upload'}
          />,
        ]}
      ></Card>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleOnFinish}
        onFieldsChange={handleFieldsChange}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        id={'test'}
      >
        <Form.Item
          name="name"
          label="Name"
          messageVariables={{ name: 'Name' }}
          rules={[{ required: true, type: 'string', max: 100 }]}
          initialValue={row?.name ? row.name : ''}
          style={{ width: 460, margin: '16px 0 0 0' }}
        >
          <Input placeholder="Enter certificate name" />
        </Form.Item>
        <Form.Item
          name="isActive"
          valuePropName="checked"
          initialValue={row?.isActive ? row.isActive : false}
          style={{ margin: '16px 0 0 0' }}
        >
          <Checkbox>isActive</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}
