import { BaseRow, FilterType, GetPreparedColumnsParams, PaginationConfig, ScrollParams, SortParams, } from '../types';
import { ColumnType, ColumnsType, TablePaginationConfig, TableRowSelection } from 'antd/es/table/interface';
import { getExpandIcon, getFilterIcon } from '../icons';

import { TooltipProps } from 'antd';
import _ from 'lodash';
import { getSearchFilter } from '../filters/SearchFilter';

export const MIN_COLUMN_WIDTH = 150; // px.

export const DEFAULT_PAGINATION_CONFIG: TablePaginationConfig = {
  hideOnSinglePage: false,
  showSizeChanger: true,
  defaultPageSize: 20,
  pageSizeOptions: [10, 20, 50, 100],
};

// ant design will apply selection even if we pass empty object (or just use rowSelection without props)
// so to omit selection usage when we don't need it, this function will wrap all selection props
export function getSelectionConfig<T extends BaseRow>(selection?: TableRowSelection<T>): {
  rowSelection?: TableRowSelection<T>
} {
  if (!selection) return {};

  return {
    rowSelection: {
      columnTitle: selection.columnTitle || 'Select',
      columnWidth: selection.columnWidth || '60px',
      selectedRowKeys: selection.selectedRowKeys || [],
      fixed: selection.fixed || true,
      onChange: selection.onChange,
      type: selection.type || 'checkbox',
    },
  };
}

// prepare expandable content config
export function getExpandableConfig<Row extends BaseRow>({ renderer, expandable }: {
  renderer?: (row: Row) => JSX.Element,
  expandable?: boolean | ((row: Row) => boolean)
}) {
  if (!_.isFunction(renderer)) return {};

  return {
    expandedRowRender: renderer,
    rowExpandable: expandable,
    // expandIcon: getExpandIcon,
  };
}

export function getPaginationCounters(params: {
  showTotal?: (total: number, range: [number, number]) => React.ReactNode,
  showCounter: boolean,
  range: [number, number]
  total: number | null,
  selected: number | null,
  filtered: number | null,
}) {
  // show custom counter if provided
  if (params.showTotal) {
    return params.showTotal(params.total || 0, params.range);
  }

  // don't show any counters
  if (!params.showCounter) {
    return undefined;
  }

  // show total & filtered & selectedCounters counters
  if (params.filtered && params.selected) {
    return `${params.range[0]}-${params.range[1]} of ${params.filtered} filtered, ${params.total} total, ${params.selected} selected items`;
  }

  // show total & filtered counters
  if (params.filtered && !params.selected) {
    return `${params.range[0]}-${params.range[1]} of ${params.filtered} filtered, ${params.total} total items`;
  }

  // show total & selected counters
  if (!params.filtered && params.selected) {
    return `${params.range[0]}-${params.range[1]} of ${params.total} total, ${params.selected} selected items`;
  }

  // show total counter
  return `${params.range[0]}-${params.range[1]} of ${params.total} total items`;
}

// prepare pagination config
export function getPaginationConfig(pagination?: PaginationConfig): { pagination: false | TablePaginationConfig } {
  const getWithDefault = (key: keyof PaginationConfig, defaultValue: any) => {
    return pagination && pagination.hasOwnProperty(key) ? pagination[key] : defaultValue;
  };

  if (pagination?.disabled) {
    return { pagination: false };
  }

  return {
    pagination: {
      current: pagination?.current,
      hideOnSinglePage: getWithDefault('hideOnSinglePage', DEFAULT_PAGINATION_CONFIG.hideOnSinglePage),
      showSizeChanger: getWithDefault('showSizeChanger', DEFAULT_PAGINATION_CONFIG.showSizeChanger),
      pageSize: getWithDefault('pageSize', DEFAULT_PAGINATION_CONFIG.defaultPageSize),
      pageSizeOptions: getWithDefault('pageSizeOptions', DEFAULT_PAGINATION_CONFIG.pageSizeOptions),

      // configs below should be provided only for server side pagination
      total: pagination?.filtered || pagination?.total || undefined,
      showTotal: (total, range) => getPaginationCounters({
        range,
        showTotal: pagination?.showTotal,
        showCounter: !!pagination?.showCounter,
        total: pagination?.total || total,
        filtered: pagination?.filtered || null,
        selected: pagination?.selected || null,
      }),

      onChange: pagination?.serverSide ? pagination?.onChange : undefined,
    },
  };
}

// prepare columns before pass them to the antd grid
// enrich by sorting options, filtering options, default and custom properties
// note: specific column property has highest priority
export function getPreparedColumns<T extends BaseRow>({
  columns = [],
  filter = {},
  filtersConfig = {},
  enableFiltering,
  enableSorting,
  onFilter,
  onResetFilter,
  columnsVisibility,
}: GetPreparedColumnsParams<T>): ColumnsType<T> {
  const visibleColumns = !_.isEmpty(columnsVisibility)
    ? _.filter(columns, ({ key }) => key ? columnsVisibility![key as string] : true)
    : columns;

  return _.map(visibleColumns, (column): ColumnType<T> => {
    const preparedColumn: ColumnType<T> = column;
    const enableFilteringForColumn = preparedColumn.hasOwnProperty('filtered') ? preparedColumn.filtered : true;

    if (enableSorting) {
      preparedColumn.sorter = preparedColumn.hasOwnProperty('sorter') ? preparedColumn.sorter : true;
      preparedColumn.sortDirections = column.sortDirections || ['ascend', 'descend', null];
      preparedColumn.defaultSortOrder = column.defaultSortOrder || null;
    }

    if (enableFiltering && enableFilteringForColumn) {
      const filterValue = filter[column.key as string]?.value;
      const filterConfig = filtersConfig[column.key as string];
      const isFiltered = !_.isEmpty(filterValue);

      preparedColumn.filterIcon = getFilterIcon(isFiltered);
      preparedColumn.filters = filterConfig?.filterOptions;

      const getterProps = {
        value: filterValue,
        column: column.key as string,
        columnTitle: column.title as string,
        onFilter,
        onResetFilter,
      };

      if (filterConfig?.filterType === FilterType.MULTISELECT) {
        // preparedColumn.filterDropdown = getDropdownFilter(getterProps);
      } else if (filterConfig?.filterType === FilterType.DATE) {
        // preparedColumn.filterDropdown = getDateFilter(getterProps);
      } else if (filterConfig?.filterType === FilterType.DURATION) {
        // preparedColumn.filterDropdown = getDurationFilter(getterProps);
      } else {
        preparedColumn.filterDropdown = getSearchFilter(getterProps);
      }
    }

    // preparedColumn.ellipsis = true;

    return preparedColumn;
  });
}

export function getTableYScroll(bottomOverheads: Array<number> = []) {
  const tHeader = document.getElementsByClassName('ant-table-thead')[0];
  const tHeaderBottom = tHeader ? tHeader.getBoundingClientRect().bottom : 0;
  const paginationContainer = 74;
  const overhead = _.reduce([tHeaderBottom, paginationContainer, ...bottomOverheads], (a, b) => a + b, 0);

  return `calc(100vh - ${overhead}px)`;
}

export function getScrollConfig<Row>(
  scrollParams?: ScrollParams,
  columns?: ColumnsType<Row>,
  columnsVisibility?: Record<string, boolean>,
): Omit<ScrollParams, 'bottomHeightOverheads'> {
  if (scrollParams?.y) return _.omit(scrollParams, 'bottomHeightOverheads');

  let x = scrollParams?.x;

  if (scrollParams?.x === 'auto' && columns && columns.length) {
    x = 0;

    columns?.forEach((i) => {
      if (typeof x === 'number' && (columnsVisibility ? columnsVisibility[i.key!] : true)) {
        x += i.width ? parseInt(i.width.toString(), 10) : MIN_COLUMN_WIDTH;
      }
    });
  }

  return {
    y: getTableYScroll(scrollParams?.bottomHeightOverheads),
    x,
    scrollToFirstRowOnChange: scrollParams?.scrollToFirstRowOnChange,
  };
}

export function getSorterTooltipConfig(prevSort: SortParams, sort: SortParams): TooltipProps {
  let title = '';

  if (sort.order === null) title = 'Click to sort ascending';
  if (sort.order === 'ascend') title = 'Click to sort descending';
  if (sort.order === 'descend') title = 'Click to cancel sorting';

  return {
    title,
    placement: 'topLeft',
  };
}

export function makeRefreshGridEvent(name: string, detail?: any): [Event, () => void] {
  const event = new CustomEvent(name, { detail });

  return [
    event,
    () => {
      document.dispatchEvent(event);
    }
  ];
}
