import {
  AntGetListParams,
  CreateSettingRequest,
  CreateSettingResponse,
  ListResponse,
  Params,
  UpdateSettingRequest,
  UpdateSettingResponse,
} from '@api/types';

import { AxiosRequestConfig } from 'axios';
import client from './client';
import { getRestUri } from '@api/helpers';
import { Setting } from '@modelTypes/setting';

const getUri = getRestUri('settings');

const ROOT_URI = getUri('');

export async function getSettings(params?: AntGetListParams) {
  return client.get<ListResponse<Setting>>(ROOT_URI.GET(), params);
}

// export async function getSettingsForSelect(params?: AntGetListParams) {
//   return client.get<ListResponse<TreatmentFolder>>(GET_DISCOUNTS_FOR_SELECT_URI, params);
// }

export async function getSetting(id: number, params?: Params) {
  return client.get<Setting>(ROOT_URI.SHOW({ id }), params);
}

export async function createSetting(payload: CreateSettingRequest) {
  return client.post<CreateSettingRequest, CreateSettingResponse>(ROOT_URI.POST(), payload);
}

export async function updateSetting(id: number, payload: UpdateSettingRequest) {
  return client.patch<UpdateSettingRequest, UpdateSettingResponse>(ROOT_URI.PATCH({ id }), payload);
}

export async function deleteSetting(id: number, payload?: AxiosRequestConfig) {
  return client.del(ROOT_URI.DELETE({ id }), payload);
}
