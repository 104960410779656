import * as React from 'react';

import { Prompt, useHistory, useParams } from 'react-router-dom';

import { Doctor } from '@modelTypes/doctor';
import map from 'lodash/map';
import { setFormErrors } from '@api/helpers';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSelector } from '@hooks';
import {
  Button,
  Col,
  Form,
  message,
  Row,
  Spin, Tooltip
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { SaveOutlined } from '@ant-design/icons';
import { CreateDoctorRequest } from '@api/types';
import { ContentsState } from '@storage/contents/reducer';
import { clearFormData, endUpdateContentError } from '@storage/contents/actions';
import { indexPageTitles, indexPageUri } from './helpers';
import { Content, ContentPages } from '@modelTypes/content';
import { getContent, updateContentRelation } from '@storage/contents/thunkActions';
import HospitalsField from '@containers/Contents/components/Form/HospitalsField';
import DoctorsField from './components/Form/DoctorsField';
import CountriesField from './components/Form/CountriesField';
import CitiesField from './components/Form/CitiesField';
import { Country } from '@modelTypes/country';
import { City } from '@modelTypes/city';
import { Hospital } from '@modelTypes/hospital';
import MedicalSpecialitiesField from '@containers/Contents/components/Form/MedicalSpecialitiesField';
import { SearchSection } from '@modelTypes/searchSection';
import DiagnosticsField from '@containers/Contents/components/Form/DiagnosticsField';
import TreatmentsField from '@containers/Contents/components/Form/TreatmentsField';
import ArticlesField from '@containers/Contents/components/Form/ArticlesField';
import { Blog } from '@modelTypes/blog';
import DiseasesField from './components/Form/DiseasesField';
import { useGridStore } from "@zustandStorage/grid/grid";
import QueryString from "query-string";

interface RouterParams {
  id: string;
  page: string;
}

type Fields = Array<Country | City | Doctor | Hospital | SearchSection | Blog>;

export default function ContentsEdit() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, page } = useParams<RouterParams>();
  const { loading, row } = useSelector<ContentsState['form']>('contents.form');
  const [messageApi, contextHolder] = message.useMessage();
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);
  const [languageId, setLanguageId] = React.useState<number>(0);
  // const [entityCount, setEntityCount] = React.useState<Record<string, number>>({});
  // const hSelect = useSelector<HospitalsState['select']>('hospitals.select');

  // const {
  //   data: specializationsData,
  //   loading: specializationsLoading
  // } = useSelector<SpecializationsState['selectSpecialization']>('specializations.selectSpecialization');

  const [form] = Form.useForm<CreateDoctorRequest>();

  const getInitialValue = (field?: Fields) => {
    return field && Array.isArray(field) ? map(field as any, (i) => i.id) : [];
  }

  const handleFieldsChange = () => {
    setFormChanged(true);
  };

  const resetForm = () => {
    form.resetFields();
    setFormChanged(false);
  };

  const handleOnFinish = async () => {
    messageApi.open({
      key: 'startCreate',
      type: 'loading',
      content: 'Saving...',
      duration: 0,
    });

    setFormSubmitting(true);
    const formData: any = {};
    const allowedField = [
      'hospitals', 'doctors', 'countries', 'cities', 'medicalSpecialities', 'diseases', 'diagnostics', 'treatments',
      'articles',
    ];

    map(form.getFieldsValue() as any, (v, k) => {
      allowedField.forEach((field) => {
        if (k === field && Array.isArray(v)) {
          const fName = `${field}Ids`;
          
          if (typeof v[0] === 'number') {
            formData[fName] = v.join(',');
          } else {
            formData[fName] = map(v, (i) => i.id).join(',');
          }
        }
      });
    });
    
    console.log('default, ', 'handleOnFinish --- ', formData);

    if (id) {
      await dispatch(updateContentRelation(parseInt(id, 10), formData as any) as unknown as Promise<Content>)
        .then(() => {
          messageApi.destroy('startCreate');
          message.success('The Content has been successfully updated');
          setFormSubmitting(false);
          resetForm();
        })
        .catch((e) => {
          messageApi.destroy('startCreate');
          dispatch(endUpdateContentError());
          setFormSubmitting(false);
          setFormErrors(e, form.getFieldsValue(), form.setFields);
        });
    }
  };

  console.log('default, ', 'ContentsEdit --- ', form.getFieldValue('hospitals'));
  // useEffect(() => {
  //   if (!specializationsData.length && !specializationsLoading) {
  //     dispatch(getSpecializationsForSelect());
  //   }
  // }, []);

  useEffect(() => {
    resetForm();
  }, [languageId, id]);

  useEffect(() => {
    if (formChanged) {
      // const fieldsToCompare = ['education', 'experience', 'awards'];
      //
      // if (isEqual(pick(row, fieldsToCompare), pick(form.getFieldsValue(), fieldsToCompare))) {
      //   return setFormChanged(false);
      // }
    }
  }, [formChanged]);

  useEffect(() => {
    dispatch(getContent(parseInt(id, 10)));

    return () => {
      dispatch(clearFormData());
    };
  }, [id]);
  
  console.log('default, ', 'ContentsEdit --- ', row);

  return (
    <>
      {contextHolder}
      <Prompt
        when={!formSubmitting && formChanged}
        message={(location) =>
          `Data not saved!\nAre you sure you want to go to ${location.pathname}?`
        }
      />
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.contentsGrid || {};
          history.push(`/contents/main?${QueryString.stringify(params)}`)}}
        title={`Edit Content - ${indexPageTitles[indexPageUri[page]]}`}
        subTitle={row ? `(${row?.name})` : null}
        extra={[
          <Button
            key="save"
            type="primary"
            loading={formSubmitting}
            onClick={() => form.submit()}
          >
            {id ? 'Update' : 'Create'}
          </Button>
        ]}
      />
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleOnFinish}
          onFieldsChange={handleFieldsChange}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          // id={'test'}
        >
          <Row gutter={[32, 24]}>
            <Col span={16}>
              {row?.key === 'hospitals__slider' && (
                <HospitalsField form={form} initialValue={getInitialValue(row?.hospitals)} />
              )}
              {row?.key === 'doctors__slider' && (
                <DoctorsField form={form} initialValue={getInitialValue(row?.doctors)} />
              )}
              {row?.key === 'treatment_programs__slider' && (
                <>
                </>
              )}
              {row?.key === 'featured_pages_and_queries__slider' && (
                <>
                  <CountriesField form={form} initialValue={getInitialValue(row?.countries)} />
                  <CitiesField form={form} initialValue={getInitialValue(row?.cities)} />
                  <MedicalSpecialitiesField form={form} initialValue={getInitialValue(row?.medicalSpecialities)} />
                  <DiseasesField form={form} initialValue={getInitialValue(row?.diseases)} />
                  <DiagnosticsField form={form} initialValue={getInitialValue(row?.diagnostics)} />
                  <TreatmentsField form={form} initialValue={getInitialValue(row?.treatments)} />
                  <HospitalsField form={form} initialValue={getInitialValue(row?.hospitals)} />
                  <DoctorsField form={form} initialValue={getInitialValue(row?.doctors)} />
                  <ArticlesField form={form} initialValue={getInitialValue(row?.articles)} />
                </>
              )}
            </Col>
          </Row>
        </Form>
      </Spin>
      <Tooltip title={id ? 'Update' : 'Create'} placement="topRight">
        <Button
          style={{ position: 'fixed', bottom: 20, right: 16 }}
          onClick={() => form.submit()}
          // type="ghost"
          shape="circle"
          icon={<SaveOutlined />}
          size="large"
        />
      </Tooltip>
    </>
  );
}
