import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { PaginationConfig } from '@components/AntGrid/types';
import { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getHistorySearchParam } from '@helpers/history';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeGridOptions from '@components/AntGrid/hooks/useChangeGridOptions';

import { Skill } from '@modelTypes/skill';
import { useDoctorSkillsStore } from '@zustandStorage/doctor/skills';
import { skillColumns, skillFiltersConfig } from '@components/AntGrid/columns/doctorSkills';

interface RouteParams {
  id: string;
}

export const [
  refreshDoctorSkillsGridEvent,
  dispatchRefreshDoctorSkillsGridEvent
] = makeRefreshGridEvent('onRefreshDoctorSkillsGrid');


export default function SkillsGrid() {
  const history = useHistory();
  const { id } = useParams<RouteParams>();

  const { meta, data, loading } = useDoctorSkillsStore((state) => state.grid);
  const { fetchDoctorSkills } = useDoctorSkillsStore();
  const languageId = getHistorySearchParam(history, 'language-id', 1);
  const firstLoad = useRef<boolean>(false);
  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: 0,
  };

  const onFetchData = (params?: AntGetListParams) => {
    fetchDoctorSkills(+id, { ...params, languageId })
      .then(() => {
        firstLoad.current = true;
      });
  };

  useEffect(() => {
    if (firstLoad.current) {
      fetchDoctorSkills(+id, { languageId });
    }
  }, [firstLoad, languageId]);

  // useEffect(() => {
  //   return () => {
  //     clearGridData();
  //   };
  // }, []);

  useRefreshGridEvent(refreshDoctorSkillsGridEvent, onFetchData);
  useChangeGridOptions('doctorSkillsGrid');

  return (
    <Grid<Skill>
      id="doctorSkillsGrid"
      onFetch={onFetchData}
      columns={skillColumns}
      data={data}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={skillFiltersConfig}
    />
  );
}