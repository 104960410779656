import { FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { getIsActiveCell } from '@components/AntGrid/cells/StatusCell';
import React from 'react';
import { useParams } from 'react-router-dom';
import { HospitalDepartment } from '@modelTypes/hospital';
import { IconButtonRenderer } from '../renderers/renderers';
import { message } from 'antd';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import { getDateFormattedCell } from '@components/AntGrid/cells/DateFormattedCell';
import { dispatchRefreshDepartmentsGridEvent } from '@containers/Hospitals/components/DepartmentsAntGrid';
import { doctorColumnsDefinitions } from '@components/AntGrid/columns/doctor';
import getIdCell from '@components/AntGrid/cells/IdCell';
import { useHospitalDepartmentStore } from '@zustandStorage/department/department';

export type HospitalDepartmentColumnsNames = `${keyof HospitalDepartment}Column`
  | 'placeColumn' | 'statisticColumn' | 'previewColumn' | 'eyeIconColumn' | 'deleteColumn';

export const hospitalDepartmentColumnsDefinitions: Partial<Record<HospitalDepartmentColumnsNames, ColumnType<HospitalDepartment>>> = {
  idColumn: createColumnDefinition<HospitalDepartment>('#', 'id', { width: 80, render: getIdCell }),
  nameColumn: createColumnDefinition<HospitalDepartment>('Name', 'name'),
  shortNameColumn: createColumnDefinition<HospitalDepartment>('Short name', 'shortName'),
  // urlSlugColumn: createColumnDefinition<HospitalDepartment>('Url Slug', 'urlSlug'),
  updatedAtColumn: createColumnDefinition<HospitalDepartment>('Updated At', 'updatedAt', { render: getDateFormattedCell, width: 160 }),
  createdAtColumn: createColumnDefinition<HospitalDepartment>('Created At', 'createdAt', { render: getDateFormattedCell, width: 160 }),
  // placeColumn: {
  //   title: 'Location',
  //   render: getPlaceCell<Hospital>(),
  //   sorter: false,
  //   filtered: false,
  // },
  // departmentsCountColumn: {
  //   title: 'Departments Count',
  //   width: 123,
  //   render: getDepartmentsCountCell<Hospital>(),
  //   sorter: false,
  //   filtered: false,
  // },
  // scoreColumn: createColumnDefinition<Hospital>('AiroScore', 'score', { width: 127 }),
  // googleRatingColumn: createColumnDefinition<Hospital>('Google Rating', 'googleRating', { width: 127 }),
  // googleTotalReviewsCountColumn: createColumnDefinition<Hospital>('Google Total Reviews', 'googleTotalReviewsCount', { width: 127 }),
  // patientScoreColumn: createColumnDefinition<Hospital>('Patient Score', 'patientScore', { width: 107 }),
  // faqColumn: {
  //   title: 'Has FAQ',
  //   width: 67,
  //   render: getHasFAQCell<Hospital>(),
  //   sorter: false,
  //   filtered: false,
  // },
  // statisticColumn: {
  //   title: 'Has Statistics',
  //   width: 95,
  //   render: getHasStatisticsCell<Hospital>(),
  //   sorter: false,
  //   filtered: false,
  // },
  // certificatesCountColumn: {
  //   title: 'Has Certificates',
  //   width: 111,
  //   render: getCertificatesCountCell<Hospital>(),
  //   sorter: false,
  //   filtered: false,
  // },
  isActiveColumn: createColumnDefinition<HospitalDepartment>('Active', 'isActive', { render: getIsActiveCell, width: 80, filtered: false }),
  // previewColumn: {
  //   title: '',
  //   width: 52,
  //   fixed: 'right',
  //   render: getPreviewCell<HospitalDepartment>(),
  //   sorter: false,
  //   filtered: false,
  // },
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, row: HospitalDepartment) => {
      return <EditCell record={row} />
    },
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell(),
    sorter: false,
    filtered: false,
  }
};

export const hospitalDepartmentColumns: any = [
  hospitalDepartmentColumnsDefinitions.idColumn,
  hospitalDepartmentColumnsDefinitions.nameColumn,
  hospitalDepartmentColumnsDefinitions.shortNameColumn,
  // hospitalDepartmentColumnsDefinitions.urlSlugColumn,
  // hospitalDepartmentColumnsDefinitions.placeColumn,
  // hospitalDepartmentColumnsDefinitions.departmentsCountColumn,
  // hospitalDepartmentColumnsDefinitions.scoreColumn,
  // hospitalDepartmentColumnsDefinitions.patientScoreColumn,
  // hospitalDepartmentColumnsDefinitions.googleRatingColumn,
  // hospitalDepartmentColumnsDefinitions.googleTotalReviewsCountColumn,
  // hospitalDepartmentColumnsDefinitions.faqColumn,
  // hospitalDepartmentColumnsDefinitions.statisticColumn,
  // hospitalDepartmentColumnsDefinitions.certificatesCountColumn,
  hospitalDepartmentColumnsDefinitions.updatedAtColumn,
  hospitalDepartmentColumnsDefinitions.createdAtColumn,
  hospitalDepartmentColumnsDefinitions.isActiveColumn,
  // hospitalDepartmentColumnsDefinitions.previewColumn,
  hospitalDepartmentColumnsDefinitions.eyeIconColumn,
  hospitalDepartmentColumnsDefinitions.deleteColumn,
];

export const hospitalDepartmentFiltersConfig: FiltersConfig = {
  [hospitalDepartmentColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [hospitalDepartmentColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [hospitalDepartmentColumnsDefinitions.shortNameColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [hospitalDepartmentColumnsDefinitions.urlSlugColumn?.key as string]: { filterType: FilterType.SEARCH },
  [doctorColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [doctorColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function EditCell({ record }: { record: HospitalDepartment }) {
  const { hospitalId } = useParams<{ hospitalId: string }>();

  return IconButtonRenderer<HospitalDepartment>(`/hospitals/${hospitalId}/departments/${record.id}/edit`)
}

// function HospitalDepartmentPreviewCell<T extends BaseRow>({ record }: { record: T }) {
//   const [loading, setLoading] = useState(false);
//
//   const handleGetAccessToken = () => {
//     // setLoading(true);
//     const hospitalUrl = `${process.env.REACT_APP_CLIENT_URL}/hospitals/${record.hospital ? record.hospital.urlSlug : ''}`;
//     const url = `${hospitalUrl}/departments/${record.urlSlug}?withoutCache=true`;
//
//     if (window !== null) {
//       // @ts-ignore
//       window.open(url, '_blank', 'noopener,noreferrer').focus();
//     }
//
//     // dispatch(getHospitalAccessKey(record.id) as unknown as Promise<HospitalAccessKey>)
//     //   .then((data) => {
//     //     const url = `${process.env.REACT_APP_CLIENT_URL}/hospitals/${record.urlSlug}?token=${data.accessKey}`;
//     //
//     //     setLoading(false);
//     //     if (window !== null) {
//     //       // @ts-ignore
//     //       window.open(url, '_blank', 'noopener,noreferrer').focus();
//     //     }
//     //   });
//   };
//
//   return (
//     <FrontendPreviewCell
//       loading={loading}
//       onGetAccessToken={handleGetAccessToken}
//     />
//   );
// }

// function getPreviewCell<T extends BaseRow>() {
//   return (value: any, record: T) => {
//     return (
//       <HospitalDepartmentPreviewCell record={record} />
//     );
//   };
// }

function CustomDeleteCell({ record }: { record: HospitalDepartment }) {
  const { deleteDepartment } = useHospitalDepartmentStore()
  return (
    <DeleteCell
      record={record}
      title="Delete Hospital Department"
      deleteAction={deleteDepartment}
      onSuccess={() => {
        dispatchRefreshDepartmentsGridEvent();
        message.success('The Hospital Department has been successfully deleted');
      }}
      hasNotRelationsContent={() => <div>The Department has been successfully deleted</div>}
      hasRelationsContent={(data: any) => {
        return (
          <div style={{}}>
            <div>Department is used by next hospital id ({data.relations.id})</div>
            <div>If you really want to continue, enter your password.</div>
          </div>
        );
      }}
    />
  );
}

function getDeleteCell() {
  return (value: any, record: HospitalDepartment) => {
    return (
      <CustomDeleteCell record={record} />
    );
  };
}
