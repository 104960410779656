import {
  CreateOrderRequest,
  CreateOrderResponse,
  GetListParams,
  ListResponse,
  UpdateOrderRequest,
  UpdateOrderResponse,
} from '@api/types';
import { Order, OrderStatuses, PhoneCountry, Status } from '@modelTypes/order';

import { AxiosRequestConfig } from 'axios';
import client from './client';
import { getRestUri } from '@api/helpers';
import { Filters } from '@containers/Orders/Ant/components/OrderListFilters';

const getUri = getRestUri('orders');
const ROOT_URI = getUri('');
const GET_COUNTRIES_FOR_SELECT_URI = 'phone-countries/select';
const GET_STATUSES_FOR_SELECT_URI = 'orders/statuses/select';
const GET_ORDERS_URI = (status: OrderStatuses) => `orders/${status}`;
const GET_ORDER_URI = (id: number) => `orders/${id}`;
const DELETE_ORDERS_URI = 'orders/bulk-delete';

export async function getOrders(status: OrderStatuses, params?: GetListParams, filters?: Partial<Filters>) {
  return client.get<ListResponse<Order>>(GET_ORDERS_URI(status),  { ...params, filter: filters as any });
}

export async function getOrder(id: number) {
  return client.get<Order>(GET_ORDER_URI(id));
}

export async function getPhoneCountriesForSelect(params?: GetListParams) {
  return client.get<ListResponse<PhoneCountry>>(GET_COUNTRIES_FOR_SELECT_URI, params);
}

export async function getStatusesForSelect(params?: GetListParams) {
  return client.get<Array<Status>>(GET_STATUSES_FOR_SELECT_URI, params);
}

export async function updateOrder(id: number, payload: UpdateOrderRequest) {
  return client.patch<UpdateOrderRequest, UpdateOrderResponse>(ROOT_URI.PATCH({ id }), payload);
}

export async function createOrder(payload: CreateOrderRequest) {
  return client.post<CreateOrderRequest, CreateOrderResponse>(ROOT_URI.POST(), payload);
}

export async function deleteOrders(payload?: AxiosRequestConfig) {
  return client.del(DELETE_ORDERS_URI, payload);
}