import { FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { HospitalTranslation } from '@modelTypes/hospital';
import { IconButtonRenderer } from '../renderers/renderers';
import { getDateFormattedCell } from '@components/AntGrid/cells/DateFormattedCell';
import ShowCheckIcon from '../cells/ShowCheckIcon';
import getIdCell from '@components/AntGrid/cells/IdCell';

export type HospitalTranslationColumnsNames = `${keyof HospitalTranslation}Column` | 'eyeIconColumn' | 'deleteColumn';

export const hospitalTranslationColumnsDefinitions: Partial<Record<HospitalTranslationColumnsNames, ColumnType<HospitalTranslation>>> = {
  idColumn: createColumnDefinition<HospitalTranslation>('#', 'id', { width: 80, render: getIdCell }),
  countryColumn: {
    title: 'Country',
    dataIndex: 'countryId',
    render: (value: any, row: HospitalTranslation) => {
      return <CountryCell record={row} />
    },
    sorter: true,
    filtered: false,
  },
  cityColumn: {
    title: 'City',
    dataIndex: 'cityId',
    render: (value: any, row: HospitalTranslation) => {
      return <CityCell record={row} />
    },
    sorter: true,
    filtered: false,
  },
  isLanguageActiveColumn: {
    title: 'Active',
    dataIndex: 'isLanguageActive',
    width: 100,
    render: (value: any, row: HospitalTranslation) => {
      return <ShowCheckIcon value={row.value === '1'} />
    },
    sorter: true,
    filtered: false,
  },
  updatedAtColumn: createColumnDefinition<HospitalTranslation>('Updated At', 'updatedAt', { render: getDateFormattedCell, width: 160 }),
  createdAtColumn: createColumnDefinition<HospitalTranslation>('Created At', 'createdAt', { render: getDateFormattedCell, width: 160 }),
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, row: HospitalTranslation) => {
      const country = row.countryId > 0 ? `countryId=${row.countryId}` : '';
      const city = row.cityId > 0 ? `cityId=${row.cityId}` : '';
      const params = country || city ? `?${country ? country : city}` : '';

      return IconButtonRenderer<HospitalTranslation>(`/destinations/content/edit${params}`);
    },
    sorter: false,
    filtered: false,
  },
};

export const destinationTranslationColumns: any = [
  hospitalTranslationColumnsDefinitions.idColumn,
  hospitalTranslationColumnsDefinitions.countryColumn,
  hospitalTranslationColumnsDefinitions.cityColumn,
  hospitalTranslationColumnsDefinitions.updatedAtColumn,
  hospitalTranslationColumnsDefinitions.createdAtColumn,
  hospitalTranslationColumnsDefinitions.isLanguageActiveColumn,
  hospitalTranslationColumnsDefinitions.eyeIconColumn,
];

export const destinationTranslationFiltersConfig: FiltersConfig = {
  [hospitalTranslationColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [hospitalTranslationColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [hospitalTranslationColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function CountryCell({ record }: { record: HospitalTranslation }) {
  if (!record.country) {
    return <>N/A</>;
  }

  return (
    <>{record.country.name}</>
  );
}

function CityCell({ record }: { record: HospitalTranslation }) {
  if (!record.city) {
    return <>N/A</>;
  }

  return (
    <>{record.city.name}</>
  );
}
