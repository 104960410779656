import { useEffect } from 'react';
import { useAuthorSelectStore } from '@zustandStorage/blogs/authorsForSelect';

export default function useLoadAuthors() {
  const { data, loading } = useAuthorSelectStore((state) => state.select);
  const { fetchAuthorForSelect } = useAuthorSelectStore();

  useEffect(() => {
    if (!data.length && !loading) {
      fetchAuthorForSelect({ page: 1, perPage: 50 });
    }
  }, []);

  return {data, loading};
}