import { useState } from 'react';
import { Button } from 'antd';
import CreateModal from '@containers/Certificates/components/CreateModal/CreateModal';
import { BaseButtonProps } from 'antd/es/button/button';
import { Certificate } from '@modelTypes/certificate';

interface Props {
  buttonProps?: BaseButtonProps;
  data?: Certificate;
}

export default function CreateButton({ buttonProps, data }: Props) {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleCloseModal = async () => {
    setIsOpenModal(false)
  }

  return (
    <>
      <Button
        type={'primary'}
        onClick={() => setIsOpenModal(true)}
        {...buttonProps}
      >
        {/*{!buttonProps?.children &&  "Add Certificate"}*/}
      </Button>
      <CreateModal
        data={data}
        closeModal={handleCloseModal}
        isOpen={isOpenModal}
      />
    </>
  )
}