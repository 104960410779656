import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M21.9852 19.9053V10.5391C21.9852 9.6403 21.5032 9.03739 21.0054 8.65419C21.2994 8.35976 21.4677 7.96251 21.4677 7.53714V6.23588C21.4677 5.35741 20.7531 4.64279 19.8746 4.64279H13.9258L13.0351 2.68906V0.527086C13.0351 0.236356 12.7987 0 12.508 0H2.04472C1.75399 0 1.51764 0.236356 1.51764 0.527086V2.71162L0.323279 5.33115C0.108746 5.80164 0 6.30209 0 6.81919V19.9823C0 20.273 0.236356 20.5094 0.527086 20.5094H9.76791C9.84633 20.7901 9.99502 21.1263 10.2761 21.4115C10.6604 21.8021 11.1842 22 11.833 22H19.8303C20.479 22 21.0028 21.8021 21.3871 21.4115C21.9822 20.8071 21.9856 19.9738 21.9848 19.905L21.9852 19.9053ZM20.9307 11.5689V18.6625H10.7333V11.5689H20.9307ZM20.9307 19.7171V19.9116C20.9307 19.929 20.9196 20.3843 20.6362 20.6717C20.4572 20.8534 20.1861 20.9455 19.8306 20.9455H11.8333C11.479 20.9455 11.209 20.8541 11.0303 20.6743C10.7389 20.3806 10.7333 19.9113 10.7333 19.9068V19.7171H20.9307ZM20.4132 7.53677C20.4132 7.83379 20.1717 8.07532 19.8746 8.07532H11.789C11.4919 8.07532 11.2504 7.83379 11.2504 7.53677V6.23515C11.2504 5.93813 11.4919 5.69659 11.789 5.69659H19.8746C20.1717 5.69659 20.4132 5.93813 20.4132 6.23515V7.53677ZM11.789 4.64205C10.9105 4.64205 10.1959 5.35667 10.1959 6.23515V7.53677C10.1959 7.96362 10.3649 8.36161 10.6608 8.65641C10.1615 9.03887 9.6784 9.64067 9.6784 10.5388V15.9317H1.05454V6.81882C1.05454 6.45375 1.13148 6.10051 1.28276 5.76835L2.39463 3.32933H12.1688L12.7673 4.64168H11.7897L11.789 4.64205ZM11.3107 9.48384C11.58 9.27819 11.8622 9.16611 11.9643 9.12986H19.7145C19.9486 9.21789 20.9129 9.63291 20.9307 10.5147H10.7337C10.7414 10.1178 10.9353 9.77124 11.3107 9.48421V9.48384ZM2.57181 2.27516V1.05454H11.9806V2.27516H2.57181ZM9.67877 16.9862V19.4548H1.05454V16.9862H9.67877Z"
    fill="currentColor" />
  <path
    d="M13.3321 16.6526H14.3282V17.6487C14.3282 17.9394 14.5646 18.1758 14.8553 18.1758H16.8442C17.1349 18.1758 17.3713 17.9394 17.3713 17.6487V16.6526H18.3674C18.6581 16.6526 18.8944 16.4162 18.8944 16.1255V14.1366C18.8944 13.8459 18.6581 13.6095 18.3674 13.6095H17.3713V12.6134C17.3713 12.3227 17.1349 12.0864 16.8442 12.0864H14.8553C14.5646 12.0864 14.3282 12.3227 14.3282 12.6134V13.6095H13.3321C13.0414 13.6095 12.805 13.8459 12.805 14.1366V16.1255C12.805 16.4162 13.0414 16.6526 13.3321 16.6526ZM16.8445 14.6633H17.8406V15.5977H16.8445C16.5538 15.5977 16.3175 15.834 16.3175 16.1248V17.1208H15.3831V16.1248C15.3831 15.834 15.1468 15.5977 14.856 15.5977H13.8599V14.6633H14.856C15.1468 14.6633 15.3831 14.427 15.3831 14.1363V13.1402H16.3175V14.1363C16.3175 14.427 16.5538 14.6633 16.8445 14.6633Z"
    fill="currentColor" />
</svg>`;

export default { id: '263-pain-medicine', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
