import { create } from 'zustand';
import produce from 'immer';
import { CreateDoctorRequest, Params, UpdateDoctorRequest } from '@api/types';
import { initialFormState } from '@zustandStorage/helpers/initialStates';
import { createDoctor, doctorGenerateAbout, doctorParseData, getDoctor, updateDoctor } from '@api';
import { Doctor } from '@modelTypes/doctor';

export interface AuthorState {
  form: {
    loading: boolean;
    row: Doctor | null;
  };
  parseForm: {
    loading: boolean;
  };
  generateAboutForm: {
    loading: boolean;
  };
  clearFormData: () => void;
  fetchDoctor: (id: number, params?:Params) => Promise<Doctor>;
  createDoctor: (payload: CreateDoctorRequest) => Promise<Doctor>;
  updateDoctor: (id: number, payload: UpdateDoctorRequest) => Promise<Doctor>;
  parseData: (id: number) => Promise<any>;
  generateAboutDoctorInfo: (id: number) => Promise<any>;
}

const initialParseFormState = {
  loading: false,
};

export const useDoctorStore = create<AuthorState>((set) => ({
  form: initialFormState,
  parseForm: initialParseFormState,
  generateAboutForm: initialParseFormState,
  clearFormData: () => set(() => ({ form: initialFormState })),
  clearParseFormData: () => set(() => ({ parseForm: initialParseFormState })),
  fetchDoctor: async (id: number, params?:Params): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return getDoctor(id, params)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      });
  },
  createDoctor: async (payload: CreateDoctorRequest): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return createDoctor(payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateDoctor: async (id: number, payload: UpdateDoctorRequest): Promise<any> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateDoctor(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  parseData: async (id) => {
    set(produce((state) => {
      state.parseForm.loading = true;
    }));

    return doctorParseData(id)
      .then((response) => {
        set(produce((state) => {
          // state.form.row = response;
          state.parseForm.loading = false;
        }));

        return response;
      });
  },
  generateAboutDoctorInfo: async (id) => {
    set(produce((state) => {
      state.generateAboutForm.loading = true;
    }));

    return doctorGenerateAbout(id)
      .then((response) => {
        set(produce((state) => {
          state.generateAboutForm.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.generateAboutForm.loading = false;
        }));

        throw e;
      });
  },
}));