import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { PaginationConfig } from '@components/AntGrid/types';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { getHistorySearchParam } from '@helpers/history';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import { treatmentTypeColumns, treatmentTypeFiltersConfig } from '@components/AntGrid/columns/treatmentTypes';
import { TreatmentType } from '@modelTypes/treatment';
import useChangeGridOptions from '@components/AntGrid/hooks/useChangeGridOptions';
import { useTypeOfCareState } from '@zustandStorage/offers/types';

export const [
  refreshTreatmentTypesGridEvent,
  dispatchRefreshTreatmentTypesGridEvent
] = makeRefreshGridEvent('onRefreshTreatmentTypesGrid');

export default function TypesOfCareGrid() {
  const history = useHistory();
  const { grid: { data, meta, loading }, fetchTypes } = useTypeOfCareState();
  const languageId = getHistorySearchParam(history, 'language-id', 1);
  const firstLoad = useRef<boolean>(false);
  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
    // ...rest.paginationConfig,
  };

  const onFetchData = (params?: AntGetListParams) => {
    fetchTypes({ ...params, languageId })
      .then(() => {
        firstLoad.current = true;
      });
  };

  useEffect(() => {
    if (firstLoad.current) {
      fetchTypes({ languageId });
    }
  }, [firstLoad, languageId]);

  useRefreshGridEvent(refreshTreatmentTypesGridEvent, onFetchData);
  useChangeGridOptions('typesOfCareGrid');

  return (
    <Grid<TreatmentType>
      id="treatmentTypesGrid"
      onFetch={onFetchData}
      columns={treatmentTypeColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={treatmentTypeFiltersConfig}
    />
  );
}