import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { PaginationConfig } from '@components/AntGrid/types';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeLanguage from '@components/AntGrid/hooks/useChangeLanguage';
import { Content, ContentPages } from '@modelTypes/content';
import useChangeGridOptions from "@components/AntGrid/hooks/useChangeGridOptions";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMenuStore } from '@zustandStorage/menu/menu';
import { contentMenuItemsColumns, contentMenuItemsFiltersConfig } from '@components/AntGrid/columns/contentMenuItems';
import { useGridStore } from '@zustandStorage/grid/grid';

export const [
  refreshContentsMenuItemsGridEvent,
  dispatchRefreshContentsMenuItemsGridEvent
] = makeRefreshGridEvent('onRefreshContentsMenuItemsGrid');

interface Props {
  pageName: ContentPages;
}

interface RouterParams {
  id: string;
}

export default function ContentsMenuItemsItemsGrid(
  {
    pageName,
  }: Props
) {
  const { subMenuGrid, fetchMenuSubItems, clearSubMenuGridData } = useMenuStore();
  const [languageId, setFirstLoad] = useChangeLanguage((languageId) => onFetchData({ languageId }));
  const { id } = useParams<RouterParams>();

  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: subMenuGrid.meta.total,
  };

  const onFetchData = async (params?: AntGetListParams) => {
    const savedParams = useGridStore.getState().gridOptions.contentsMenuItemsGrid || {};

    fetchMenuSubItems(+id, { languageId, ...savedParams, ...params })
      .then(() => {
        setFirstLoad(true);
      });
  };

  useEffect(() => {
    onFetchData();
  }, [pageName]);

  useRefreshGridEvent(refreshContentsMenuItemsGridEvent, onFetchData, [languageId]);
  useChangeGridOptions('contentsMenuSubItemsGrid');

  return (
    <Grid<Content>
      id="contentsMenuItemsGrid"
      onFetch={onFetchData}
      columns={contentMenuItemsColumns}
      data={subMenuGrid.data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={subMenuGrid.loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={contentMenuItemsFiltersConfig}
    />
  );
}