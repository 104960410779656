import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { useDispatch } from 'react-redux';
import { PaginationConfig } from '@components/AntGrid/types';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeGridOptions from '@components/AntGrid/hooks/useChangeGridOptions';
import { vendorsUserColumns, vendorsUserFiltersConfig } from '@components/AntGrid/columns/vendorUser';
import { VendorUser } from '@modelTypes/vendor';
import { useVendorUserStore } from '@zustandStorage/vendor/user';

export const [
  refreshVendorsUsersGridEvent,
  dispatchRefreshVendorsUsersGridEvent
] = makeRefreshGridEvent('onRefreshUsersGrid');

export default function UsersVendorsGrid() {
  const {
    grid: {
      data, meta, loading
    }, fetchUsers,
  } = useVendorUserStore();  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
    // ...rest.paginationConfig,
  };

  const onFetchData = (params?: AntGetListParams) => {
    fetchUsers(params);
  };

  useRefreshGridEvent(refreshVendorsUsersGridEvent, onFetchData);
  useChangeGridOptions('vendorsUserAntGrid');

  return (
    <Grid<VendorUser>
      id="vendorsUser"
      onFetch={onFetchData}
      columns={vendorsUserColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={vendorsUserFiltersConfig}
    />
  );
}