import { DollarCircleOutlined } from '@ant-design/icons';
import { Currency, Price, PriceType } from '@containers/Hospitals/FormSteps/PricesStep/types';
import { Button, Input, Modal, Select, notification } from 'antd';
import { useEffect, useState } from 'react';
import styles from './AddPriceTagModal.module.scss';
import { useParams } from 'react-router-dom';
import { createClinicPrice, updatePriceDetails } from '@api/hospitalApi';

interface Props {
  close: () => void;
  open: boolean;
  editingPriceTag: Price | null;
  parentId: number | null;
  handlePriceTagsUpdated: () => void;
  currencies: Currency[];
}

export default function AddPriceTagModal(props: Props) {
  const { open, close, editingPriceTag, parentId = null, handlePriceTagsUpdated, currencies } = props;

  const [selectedOption, setSelectedOption] = useState<Currency>(currencies[0]);
  const [priceValue, setPriceValue] = useState(String(selectedOption.symbol + ' '));
  const [priceName, setPriceName] = useState('');
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams<{ id: string }>();

  // const { clinic } = useClinicStore(state => state);

  const handleValueChanged = (value: string) => {
    const number = value.replace(String(selectedOption.symbol + ' '), '');

    if (!isNaN(Number(number)) || number === '') {
      setPriceValue(String(selectedOption.symbol + ' ') + number);
    }
  };

  const handleCurrencySelected = (item: string) => {
    const number = priceValue.replace(String(selectedOption.symbol + ' '), '');
    setPriceValue(String((currencies.find((i) => i.code === item) || currencies[0]).symbol + ' ') + number);
    setSelectedOption(currencies.find((i) => i.code === item) || currencies[0]);
  };

  const handleSave = async () => {
    if (isLoading) return;

    try {
      const priceValueString = priceValue.replace(String(selectedOption.symbol + ' '), '');
      const price: Omit<Price, 'id' | 'currency'> & { currencyId?: number | null } = {
        name: priceName,
        ...(priceValueString
          ? {
              currencyId: selectedOption.id,
              price: Number(priceValueString),
            }
          : { currencyId: null, price: null }),
        type: PriceType.PRICE,
        parentId: parentId,
      };
      if (editingPriceTag) {
        await updatePriceDetails(+params.id, editingPriceTag.id, price);
      } else {
        await createClinicPrice(+params.id, price);
      }

      handlePriceTagsUpdated();
      setPriceName('');
      setPriceValue(String(selectedOption.symbol + ' '));
      setSelectedOption(currencies[0]);
    } catch (e: any) {
      console.log(e);
      notification.error({ message: 'Failed to save price tag: ' + e.message, duration: 0, placement: 'top' });
    }
    close();
  };

  const handleDisabled = priceName.length === 0;

  useEffect(() => {
    setPriceName(editingPriceTag?.name || '');
    setPriceValue(
      String((currencies.find((i) => i.id === editingPriceTag?.currency?.id) || currencies[0]).symbol) +
        ' ' +
        (editingPriceTag?.price ? editingPriceTag?.price : '')
    );
    setSelectedOption(currencies.find((i) => i.id === editingPriceTag?.currency?.id) || currencies[0]);

    // eslint-disable-next-line
  }, [editingPriceTag]);

  return (
    <Modal
      open={open}
      title={editingPriceTag ? 'Edit price tag' : 'Add price tag'}
      onCancel={close}
      footer={
        <div className={styles.footer}>
          {/* {editingPriceTag ? <Button onClick={() => setIsUpgradeModalOpen(true)}>Upgrade</Button> : <div />} */}
          <Button type="primary" onClick={handleSave} disabled={handleDisabled}>
            Save
          </Button>
        </div>
      }
    >
      <p>Name</p>
      <Input maxLength={120} value={priceName} onInput={(e) => setPriceName(e.currentTarget.value)} />
      <div className={styles.priceContainer}>
        <div>
          <p>Currency</p>
          <Select
            defaultValue={selectedOption.code}
            style={{ width: 120 }}
            onChange={(val) => handleCurrencySelected(val)}
            options={currencies.map((i) => ({ value: i.code, label: i.code }))}
          />
        </div>
        <div style={{ width: '100%' }}>
          <p>Cost</p>
          <Input
            prefix={<DollarCircleOutlined />}
            value={priceValue}
            onInput={(e) => handleValueChanged(e.currentTarget.value)}
            min={'0'}
          />
        </div>
      </div>
    </Modal>
  );
}
