import { Tooltip, Button, Modal, Spin } from 'antd';
import { Form, Input, message } from 'antd';
import { DatabaseOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearCache } from '@storage/app/thunkActions';
import { useSelector } from '@hooks';
import { AppState } from '@storage/app/reducer';

export default function ClearCache(): JSX.Element {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { loading } = useSelector<AppState['clearCacheModal']>('app.clearCacheModal');

  const onFinish = () => {
    message.success('Start clearing cache');

    dispatch(clearCache({ ...form.getFieldsValue(), all: false }) as unknown as Promise<any>)
      .then((r) => {
        message.success(`Cache successfully cleared! ${r.count} rows affected.`);
        handleCancel();
      });
  };

  const handleClearAll = () => {
    message.success('Start clearing all cache');

    dispatch(clearCache({ all: true }) as unknown as Promise<void>)
      .then((r) => {
        message.success('Cache successfully cleared!');
        handleCancel();
      });
  };

  const onFinishFailed = () => {
    message.error('Submit failed!');
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  return (
    <Tooltip title="Clear Cache">
      <Button
        onClick={showModal}
        type="ghost"
        shape="circle"
        icon={<DatabaseOutlined />}
        size="middle"
      />
      {/*<div style={{ color: 'black', cursor: 'pointer' }} onClick={showModal}>*/}
      {/*  */}
      {/*</div>*/}
      <Modal
        title="Clear Cache"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="clear-all" loading={loading} onClick={handleClearAll}>
            Clear All
          </Button>,
          <Button key="clear-by-url" type="primary" loading={loading} onClick={handleOk}>
            Clear By Url
          </Button>
        ]}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="urlSlug"
              label="Url Slug"
              rules={[{ required: true, message: '"Url Slug" is required' }, { type: 'string', warningOnly: true }]}
            >
              <Input placeholder="Url Slug" />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </Tooltip>
  );
}