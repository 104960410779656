import { Key, useEffect, useMemo, useRef } from 'react';
import { Edge, Node, useEdges, useNodes, useReactFlow } from 'reactflow';
import { CustomEdgeProps } from './CustomEdge/CustomEdge';
import { CustomNodeProps } from './CustomNode/CustomNode';

interface Revision {
  nodes: Node<CustomNodeProps>[];
  edges: Edge<CustomEdgeProps>[];
}

interface Props {
  revisionKey: Key;
  onRevisionKeyUpdate(): void;
}

const maxRevisions = 10;

export default function RevisionManager({ revisionKey, onRevisionKeyUpdate }: Props) {
  const undoSteps = useRef<Revision[]>([]);
  const redoSteps = useRef<Revision[]>([]);
  const flowState = useReactFlow();
  const currentRevision = useRef<Revision | null>({ nodes: flowState.getNodes(), edges: flowState.getEdges() });
  const isUndoOrRedo = useRef(false);
  const initialRender = useRef(true);
  const nodes = useNodes<CustomNodeProps>();
  const edges = useEdges<CustomEdgeProps>();
  const nodesUpdateTriggers = useMemo(
    () => JSON.stringify(nodes.map((i) => [i.data.name, i.data.type, i.data.svg, i.data.position])),
    [nodes]
  );
  const edgesUpdateTriggers = useMemo(() => JSON.stringify(edges.map((i) => [i.data?.type])), [edges]);

  useEffect(() => {
    if (!currentRevision.current) {
      return;
    }

    if (isUndoOrRedo.current) {
      currentRevision.current = { nodes: flowState.getNodes(), edges: flowState.getEdges() };
      isUndoOrRedo.current = false;
      return;
    }

    if (undoSteps.current.length === maxRevisions) {
      undoSteps.current.shift();
    }

    undoSteps.current.push(currentRevision.current);
    currentRevision.current = { nodes: flowState.getNodes(), edges: flowState.getEdges() };
    redoSteps.current = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revisionKey]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    onRevisionKeyUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodesUpdateTriggers, edgesUpdateTriggers]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'z' && (event.ctrlKey || event.metaKey) && !event.shiftKey && undoSteps.current.length) {
        event.preventDefault();
        event.stopPropagation();

        isUndoOrRedo.current = true;

        if (redoSteps.current.length === maxRevisions) {
          redoSteps.current.shift();
        }

        redoSteps.current.push({ nodes: flowState.getNodes(), edges: flowState.getEdges() });

        const prevRevision = undoSteps.current.pop()!;
        flowState.setNodes(prevRevision.nodes);
        flowState.setEdges(prevRevision.edges);
      } else if (event.key === 'Z' && (event.ctrlKey || event.metaKey) && event.shiftKey && redoSteps.current.length) {
        event.preventDefault();
        event.stopPropagation();

        isUndoOrRedo.current = true;

        if (undoSteps.current.length === maxRevisions) {
          undoSteps.current.shift();
        }

        undoSteps.current.push({ nodes: flowState.getNodes(), edges: flowState.getEdges() });

        const prevRevision = redoSteps.current.pop()!;
        flowState.setNodes(prevRevision.nodes);
        flowState.setEdges(prevRevision.edges);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [flowState]);

  return null;
}
