import { BaseRow, FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { User } from '../../../modelTypes/user';
import { getDateFormattedCell } from '../cells/DateFormattedCell';
import ShowCheckIcon from '../cells/ShowCheckIcon';
import { createColumnDefinition } from '../helpers/columnsDefinitions';

import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import getIdCell from '@components/AntGrid/cells/IdCell';
import { dispatchRefreshUsersGridEvent } from '@containers/Users/components/UsersAntGrid';
import { deleteUser } from '@storage/users/thunkActions';
import { message } from 'antd';
import ActivityCell from '../cells/ActivityCell';
import { IconButtonRenderer } from '../renderers/renderers';

export type UserColumnsNames =
  | `${keyof User}Column`
  | 'twoFactorAuthColumn'
  | 'previewColumn'
  | 'eyeIconColumn'
  | 'deleteColumn'
  | 'activityColumn';

export const userColumnsDefinitions: Partial<Record<UserColumnsNames, ColumnType<User>>> = {
  idColumn: createColumnDefinition<User>('#', 'id', { width: 80, render: getIdCell }),
  nameColumn: createColumnDefinition<User>('Name', 'name'),
  emailColumn: createColumnDefinition<User>('Email', 'email'),
  updatedAtColumn: createColumnDefinition<User>('Updated At', 'updatedAt', {
    render: getDateFormattedCell,
    width: 160,
  }),
  createdAtColumn: createColumnDefinition<User>('Created At', 'createdAt', {
    render: getDateFormattedCell,
    width: 160,
  }),
  roleColumn: {
    title: 'Role',
    dataIndex: 'role',
    render: getRoleCell<User>(),
    sorter: false,
    filtered: false,
  },
  twoFactorAuthColumn: {
    title: 'Two Factor Auth',
    dataIndex: 'isTwoFactorAuth',
    width: 88,
    render: getHasTwoFactorAuthorCell<User>(),
    sorter: true,
    filtered: false,
  },
  hasGoogleSecretColumn: {
    title: 'Has G-Secret',
    key: 'hasGoogleSecret',
    dataIndex: 'hasGoogleSecret',
    width: 98,
    render: getHasGoogleSecretCell<User>(),
    sorter: true,
    filtered: false,
  },
  activityColumn: {
    key: 'activity',
    dataIndex: 'activity',
    width: 52,
    render: getActivityCell(),
    sorter: false,
    filtered: false,
  },
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, row: User) => {
      return IconButtonRenderer<User>(`/users/${row.id}/edit`);
    },
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell<User>(),
    sorter: false,
    filtered: false,
  },
};

export const userColumns: any[] = [
  userColumnsDefinitions.idColumn,
  userColumnsDefinitions.nameColumn,
  userColumnsDefinitions.emailColumn,
  userColumnsDefinitions.roleColumn,
  userColumnsDefinitions.updatedAtColumn,
  userColumnsDefinitions.createdAtColumn,
  userColumnsDefinitions.twoFactorAuthColumn,
  userColumnsDefinitions.hasGoogleSecretColumn,
  userColumnsDefinitions.activityColumn,
  userColumnsDefinitions.eyeIconColumn,
  userColumnsDefinitions.deleteColumn,
];

export const userFiltersConfig: FiltersConfig = {
  [userColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [userColumnsDefinitions.roleColumn?.key as string]: { filterType: FilterType.SEARCH },
  [userColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [userColumnsDefinitions.emailColumn?.key as string]: { filterType: FilterType.SEARCH },
  [userColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [userColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function RoleCell({ record }: { record: User }) {
  return <>{record && record.role ? record.role.name : 'N/A'}</>;
}

function getRoleCell<T extends BaseRow>() {
  return (value: any, record: User) => {
    return <RoleCell record={record} />;
  };
}

function getHasTwoFactorAuthorCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return <ShowCheckIcon value={record.isTwoFactorAuth} />;
  };
}

function getHasGoogleSecretCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return <ShowCheckIcon value={record.hasGoogleSecret} />;
  };
}

function getDeleteCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <DeleteCell
        record={record}
        title="Delete User"
        deleteThunkAction={deleteUser}
        onSuccess={() => {
          dispatchRefreshUsersGridEvent();
          message.success('User has been successfully deleted');
        }}
        hasNotRelationsContent={() => <div>User has no any relations.</div>}
        hasRelationsContent={() => {
          return (
            <div style={{}}>
              <div>If you really want to continue, enter your password.</div>
            </div>
          );
        }}
      />
    );
  };
}

function getActivityCell() {
  return (_: any, record: User) => <ActivityCell record={record} />;
}
