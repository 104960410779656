import { Doctor } from '@modelTypes/doctor';
import { Hospital } from '@modelTypes/hospital';
import { TreatmentMethod, TreatmentType } from '@modelTypes/treatment';
import { Service } from '@modelTypes/service';
import { VendorUser } from '@modelTypes/vendor';

export interface Currency {
  id: number;
  code: string;
  symbol: string;
  rate: number;
  name: string;
}

export interface Offer {
  id: number;
  methodId: number;
  hospitalId: number;
  doctorId: number;
  currencyId: number;
  price: number;
  discount: number;
  ageGroup: number;
  typeId: number;
  mainSearchSectionId: number;
  duration: number;
  name: string;
  updatedAt: string;
  createdAt: string;
  isActive: boolean;
  isLanguageActive: boolean;
  examination: string;
  distantServices: string;
  laboratoryTests: string;
  diagnosis: string;
  treatment: string;
  other: string;
  description: string;
  chooseUs: string;
  faq: string;
  uuid: string;
  vendorId: number;
  vendor?: VendorUser;
  currency?: Currency;
  doctor?: Doctor;
  hospital?: Hospital;
  method?:  Omit<TreatmentMethod, "createdAt" | 'updatedAt'>;
  type?: Omit<TreatmentType, 'createdAt' | 'updatedAt'>
  services?: Array<Service>;
}

export enum AgeGroup {
  CHILDREN,
  ADULTS,
  ALL,
}