import { AntGetListParams } from '@api/types';
import { DependencyList, useEffect } from 'react';
import { getHistorySearchParam } from '@helpers/history';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PAGINATION_CONFIG } from '@components/AntGrid/helpers/helpers';

export default function useRefreshGridEvent(event: Event, onFetchData: (params?: AntGetListParams) => void, deps: DependencyList = []): void {
  const history = useHistory();

  const handleRefreshGridEvent = () => {
    const page = +getHistorySearchParam(history, 'page', 1);
    const perPage = +getHistorySearchParam(history, 'per-page', DEFAULT_PAGINATION_CONFIG.defaultPageSize as number);

    onFetchData({ page, perPage });
  };

  useEffect(() => {
    document.addEventListener(event.type, handleRefreshGridEvent);

    return () => {
      document.removeEventListener(event.type, handleRefreshGridEvent);
    };
  }, deps);
}