import { Hospital, HospitalDepartment, HospitalDepartmentForSelect } from '@modelTypes/hospital';

import { ListResponse } from '@api/types';
import { createAction } from '../helpers';

export enum ActionType {
  START_FETCH_HOSPITAL = 'DEPARTMENTS__START_FETCH_HOSPITAL',
  END_FETCH_HOSPITAL = 'DEPARTMENTS__END_FETCH_HOSPITAL',

  START_FETCH_DEPARTMENTS = 'DEPARTMENTS__START_FETCH_DEPARTMENTS',
  END_FETCH_DEPARTMENTS = 'DEPARTMENTS__END_FETCH_DEPARTMENTS',

  START_FETCH_DEPARTMENTS_FOR_SELECT = 'DEPARTMENTS__START_FETCH_DEPARTMENTS_FOR_SELECT',
  END_FETCH_DEPARTMENTS_FOR_SELECT = 'DEPARTMENTS__END_FETCH_DEPARTMENTS_FOR_SELECT',

  START_FETCH_DEPARTMENT = 'DEPARTMENTS__START_FETCH_DEPARTMENT',
  END_FETCH_DEPARTMENT = 'DEPARTMENTS__END_FETCH_DEPARTMENT',

  START_POST_DEPARTMENT = 'DEPARTMENTS__START_POST_DEPARTMENT',
  END_POST_DEPARTMENT = 'DEPARTMENTS__END_POST_DEPARTMENT',
  END_POST_DEPARTMENT_ERROR = 'DEPARTMENTS__END_POST_DEPARTMENT_ERROR',

  START_PATCH_DEPARTMENT = 'DEPARTMENTS__START_PATCH_DEPARTMENT',
  END_PATCH_DEPARTMENT = 'DEPARTMENTS__END_PATCH_DEPARTMENT',
  END_PATCH_DEPARTMENT_ERROR = 'DEPARTMENTS__END_PATCH_DEPARTMENT_ERROR',

  START_DELETE_DEPARTMENT = 'DEPARTMENTS__START_DELETE_DEPARTMENT',

  CLEAR_FORM_DATA = 'DEPARTMENTS__CLEAR_FORM_DATA',

  // END_DELETE_CITY = 'CITIES__END_DELETE_CITY',
  // CLEAR_FORM_DATA = 'HOSPITALS__CLEAR_FORM_DATA',

  // OPEN_MODAL_WITH_FORM = 'HOSPITALS__OPEN_MODAL_WITH_FORM',
  // CLOSE_MODAL_WITH_FORM = 'HOSPITALS__CLOSE_MODAL_WITH_FORM',
  // MODAL_WITH_FORM_SET_DATA = 'HOSPITALS__MODAL_WITH_FORM_SET_DATA',
}

export const startFetchHospital = createAction<ActionType.START_FETCH_HOSPITAL>(ActionType.START_FETCH_HOSPITAL);
export const endFetchHospital = createAction<ActionType.END_FETCH_HOSPITAL, Hospital>(ActionType.END_FETCH_HOSPITAL);

export const startFetchDepartments = createAction<ActionType.START_FETCH_DEPARTMENTS>(ActionType.START_FETCH_DEPARTMENTS);
export const endFetchDepartments = createAction<ActionType.END_FETCH_DEPARTMENTS, ListResponse<HospitalDepartment>>(ActionType.END_FETCH_DEPARTMENTS);

export const startFetchDepartmentsForSelect = createAction<ActionType.START_FETCH_DEPARTMENTS_FOR_SELECT>(ActionType.START_FETCH_DEPARTMENTS_FOR_SELECT);
export const endFetchDepartmentsForSelect = createAction<ActionType.END_FETCH_DEPARTMENTS_FOR_SELECT, ListResponse<HospitalDepartmentForSelect>>(ActionType.END_FETCH_DEPARTMENTS_FOR_SELECT);

export const startFetchDepartment = createAction<ActionType.START_FETCH_DEPARTMENT>(ActionType.START_FETCH_DEPARTMENT);
export const endFetchDepartment = createAction<ActionType.END_FETCH_DEPARTMENT, HospitalDepartment>(ActionType.END_FETCH_DEPARTMENT);

export const startPostDepartment = createAction<ActionType.START_POST_DEPARTMENT>(ActionType.START_POST_DEPARTMENT);
export const endPostDepartment = createAction<ActionType.END_POST_DEPARTMENT, HospitalDepartment>(ActionType.END_POST_DEPARTMENT);
export const endPostDepartmentError = createAction<ActionType.END_POST_DEPARTMENT_ERROR>(ActionType.END_POST_DEPARTMENT_ERROR);

export const startUpdateDepartment = createAction<ActionType.START_PATCH_DEPARTMENT>(ActionType.START_PATCH_DEPARTMENT);
export const endUpdateDepartment = createAction<ActionType.END_PATCH_DEPARTMENT, HospitalDepartment>(ActionType.END_PATCH_DEPARTMENT);
export const endUpdateDepartmentError = createAction<ActionType.END_PATCH_DEPARTMENT_ERROR>(ActionType.END_PATCH_DEPARTMENT_ERROR);

export const startDeleteDepartment = createAction<ActionType.START_DELETE_DEPARTMENT>(ActionType.START_DELETE_DEPARTMENT);

export const clearFormData = createAction<ActionType.CLEAR_FORM_DATA>(ActionType.CLEAR_FORM_DATA);

// export const openModalWithForm = createAction<ActionType.OPEN_MODAL_WITH_FORM>(ActionType.OPEN_MODAL_WITH_FORM);
// export const closeModalWidthForm = createAction<ActionType.CLOSE_MODAL_WITH_FORM>(ActionType.CLOSE_MODAL_WITH_FORM);
// export const setModalWithFormData = createAction<ActionType.MODAL_WITH_FORM_SET_DATA, ModalData>(ActionType.MODAL_WITH_FORM_SET_DATA);

export type Actions =
  | ReturnType<typeof startFetchHospital>
  | ReturnType<typeof endFetchHospital>
  | ReturnType<typeof startFetchDepartments>
  | ReturnType<typeof endFetchDepartments>
  | ReturnType<typeof startFetchDepartmentsForSelect>
  | ReturnType<typeof endFetchDepartmentsForSelect>
  | ReturnType<typeof startFetchDepartment>
  | ReturnType<typeof endFetchDepartment>
  | ReturnType<typeof startPostDepartment>
  | ReturnType<typeof endPostDepartment>
  | ReturnType<typeof endPostDepartmentError>
  | ReturnType<typeof startUpdateDepartment>
  | ReturnType<typeof endUpdateDepartment>
  | ReturnType<typeof endUpdateDepartmentError>
  | ReturnType<typeof startDeleteDepartment>
  | ReturnType<typeof clearFormData>;
  // | ReturnType<typeof startFetchHospital>
  // | ReturnType<typeof endFetchHospital>
  // | ReturnType<typeof startPostHospital>
  // | ReturnType<typeof clearFormData>;