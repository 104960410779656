import { create } from 'zustand';
import produce from 'immer';
import {
  AntGetListParams,
  CreateDepartmentRequest,
  CreateDepartmentResponse,
  ListResponse,
  Params,
  UpdateDepartmentRequest,
  UpdateDepartmentResponse
} from '@api/types';
import { initialFormState, initialGridState } from '@zustandStorage/helpers/initialStates';
import { createDepartment, deleteDepartment, getDepartment, getDepartments, updateDepartment } from '@api';
import { HospitalDepartment } from '@modelTypes/hospital';
import { AxiosRequestConfig } from 'axios';
import { UrlParams } from '@api/hospitalApi';

export interface DepartmentState {
  grid: ListResponse<HospitalDepartment> & { loading: boolean; };
  form: {
    loading: boolean;
    row: HospitalDepartment | null;
  };
  clearFormData: () => void;
  fetchDepartments: (hospitalId: number, payload?: AntGetListParams) => Promise<ListResponse<HospitalDepartment>>;
  deleteDepartment: (id: number, payload?: AxiosRequestConfig) => Promise<any>;
  fetchDepartment: (urlParams: UrlParams, params?: Params) => Promise<HospitalDepartment>;
  createDepartment: (hospitalId: number, payload: CreateDepartmentRequest) => Promise<CreateDepartmentResponse>;
  updateDepartment: (urlParams: UrlParams, payload: UpdateDepartmentRequest) => Promise<UpdateDepartmentResponse>;
}

export const useHospitalDepartmentStore = create<DepartmentState>((set) => ({
  grid: initialGridState,
  form: initialFormState,

  // Grid
  fetchDepartments: async (hospitalId: number, params?: AntGetListParams): Promise<ListResponse<HospitalDepartment>> => {
    set(produce((state) => {
      state.grid.loading = true;
    }));

    return getDepartments(hospitalId, params)
      .then((response) => {
        set(produce((state) => {
          state.grid.data = response.data;
          state.grid.meta = response.meta;
          state.grid.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.grid.loading = false;
        }));

        throw e;
      })
  },
  deleteDepartment: async (id, payload?) => {
    return deleteDepartment(id, payload)
  },

  // Form
  clearFormData: () => set(() => ({ form: initialFormState })),
  fetchDepartment: async (urlParams: UrlParams, params?: Params): Promise<HospitalDepartment> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return getDepartment(urlParams, params)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  createDepartment: async (hospitalId: number, payload: CreateDepartmentRequest): Promise<CreateDepartmentResponse> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return createDepartment(hospitalId, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
  updateDepartment: async (urlParams: UrlParams, payload: UpdateDepartmentRequest): Promise<UpdateDepartmentResponse> => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateDepartment(urlParams, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
}));