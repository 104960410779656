import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Avatar } from '@mui/material';
import styles from '@containers/Hospitals/components/HospitalImages.module.scss';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Language } from '@modelTypes/language';
import {
  deleteAuthorCertificateImage,
  getAuthor,
  updateAuthorCertificate,
  uploadAuthorCertificates
} from '@storage/authors/thunkActions';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks';
import { AuthorsState } from '@storage/authors/reducer';
import Box from '@mui/material/Box';
import UploadPhotoModal from '@components/Modal/UploadPhoto';
import { HospitalImage } from '@modelTypes/hospital';
import { UpdateAuthorCertificateOrderResponse, UpdateAuthorCertificateResponse, } from '@api/types';
import { AuthorCertificate } from '@modelTypes/author';
import { updateAuthorCertificateOrder } from '@api';
import { endUploadAuthorCertificates } from '@storage/authors/actions';
import TextField from '@mui/material/TextField';
import debounce from 'lodash/debounce';
import { useGridStore } from '@zustandStorage/grid/grid';
import QueryString from 'query-string';
import SelectStep from '@containers/Blog/Authors/components/SelectStep';
import SelectLanguagePerForm from '@base/SelectLanguagePerForm';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, message, Spin } from 'antd';

const SortableAvatar = SortableHandle(({ value }: any) => {
  return (
    <Avatar
      sx={{
        width: 250,
        height: 135,
      }}
      variant="square"
      src={value.imgPath}
    />
  );
});

const SortableItem = SortableElement(({ languageId, value, onChange, onDelete }: any) => {
  const debouncedOnChange = useMemo(
    () => debounce((e: any, value: any) => {
      onChange(e, value, languageId);
    }, 400),
    []
  );

  return (
    <div className={styles.card}>
      <SortableAvatar value={value} />
      <div className={styles.delete} onClick={() => onDelete(value)} title="Delete image">
        <HighlightOffIcon color="error" />
      </div>
      <div className={styles.checkboxes}>
        <TextField
          // error={Boolean(formik.touched.urlSlug && formik.errors.urlSlug)}
          fullWidth
          // helperText={formik.touched.urlSlug && formik.errors.urlSlug}
          label="Alt Text"
          margin="dense"
          size="small"
          name="alt"
          // onBlur={handleOnBlur}
          defaultValue={value.alt}
          onChange={(e) => debouncedOnChange(e, value)}
          // value={formik.values.urlSlug}
          variant="outlined"
          autoComplete="off"
        />
      </div>
      {/*<Button*/}
      {/*  // onClick={() => setUploadModalOpen(true)}*/}
      {/*  color="primary"*/}
      {/*>*/}
      {/*  Delete*/}
      {/*</Button>*/}
    </div>
  );
});


const SortableList = SortableContainer(({ languageId, items, onChange, onDelete }: any) => {
  return (
    <div className={styles.images}>
      {items.map((value: any, index: number) => (
        <SortableItem
          key={`item-${value.id}-${value.alt}-${languageId}`}
          index={index}
          value={value}
          onChange={onChange}
          onDelete={onDelete}
          languageId={languageId}
        />
      ))}
    </div>
  );
});

export default function AuthorCertificatesForm() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { loading, row } = useSelector<AuthorsState['form']>('authors.form');
  const [languageId, setLanguageId] = React.useState<number>(0);
  const [uploadModalOpen, setUploadModalOpen] = React.useState<boolean>(false);
  const [uploadedImages, setUploadedImages] = React.useState<Array<AuthorCertificate>>([]);
  const history = useHistory();

  const onSelectLanguage = async (language: Language) => {
    setLanguageId(language.id);

    if (id) {
      dispatch(getAuthor(parseInt(id, 10), { languageId: language.id }));
    }
  };

  const onPhotoSave = async (canvas: any) => {
    canvas.toBlob(
      async (blob: Blob) => {
        // setImages(images.concat(blob));
        // setImagesPreview(imagesPreview.concat(URL.createObjectURL(blob)));
        submitForm(blob)
      },
      'image/jpeg',
      1
    );
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (oldIndex === newIndex) {
      return;
    }

    const imgToUpdate = uploadedImages[oldIndex];
    const newOrder = uploadedImages[newIndex].order;

    dispatch(updateAuthorCertificateOrder(imgToUpdate.id, { order: newOrder }) as unknown as Promise<UpdateAuthorCertificateOrderResponse>)
      .then((data) => {
        setUploadedImages(data);
        dispatch(endUploadAuthorCertificates());
      });
  };

  const handleChange = (e: any, item: any, languageId: number) => {
    const data = {
      [e.target.name]: e.target.value,
      languageId,
    };

    dispatch(updateAuthorCertificate(item.id, data) as unknown as Promise<UpdateAuthorCertificateResponse>)
      .then((data) => {
        // const index = findIndex(uploadedImages, ['id', img.id]);
        // const newUploadedImages = uploadedImages.slice(0);
        //
        // // @ts-ignore
        // newUploadedImages[index] = data;
        //
        // setUploadedImages(newUploadedImages);
        dispatch(endUploadAuthorCertificates());
      });
  };

  const handleOnDelete = (img: HospitalImage) => {
    dispatch(deleteAuthorCertificateImage(img.id) as unknown as Promise<void>)
      .then(() => {
        setUploadedImages(uploadedImages.filter((i) => i.id !== img.id));
        dispatch(endUploadAuthorCertificates());
      });
  };

  const submitForm = async (img: Blob) => {
    const formData = new FormData();
    formData.append('image', img);

    dispatch(uploadAuthorCertificates(parseInt(id, 10), formData) as unknown as Promise<void>)
      .then((data) => {
        setUploadedImages(uploadedImages.concat(data as unknown as AuthorCertificate));
        message.success('Certificate has been successfully uploaded');
      })
      .catch(() => {
        message.error('An error occurred, please try again now')
      })
  };

  useEffect(() => {
    // if (id && !row) {
    //   dispatch(getHospital(parseInt(id, 10), { languageId: languages[0]?.id || 0 }))
    // }

    if (row && row.certificates) {
      setUploadedImages(row.certificates);
    }
  }, [row]);

  return (
    <>
      <PageHeader
        ghost={true}
        onBack={() => {
          const params = useGridStore.getState().gridOptions.authorsAntGrid || {};
          history.push(`/authors?${QueryString.stringify(params)}`)
        }}
        title={`Author Certificates`}
        // subTitle={<SelectedPlaceValue data={place} />}
        extra={[
          <SelectStep
            key="selectStep"
          />,
          <SelectLanguagePerForm
            key="selectLanguage"
            onInit={onSelectLanguage}
            onChange={onSelectLanguage}
            disabled={!row}
          />,
          <Button
            key="save"
            type="primary"
            onClick={() => setUploadModalOpen(true)}
          >
            Add Image
          </Button>
        ]}
      />
      <Spin spinning={loading}>
        <Box sx={{ mt: 3 }}>
          <Box sx={{ mt: 3 }}>
            <SortableList
              useDragHandle
              axis={'xy'}
              items={uploadedImages}
              languageId={languageId}
              onSortEnd={onSortEnd}
              onChange={handleChange}
              onDelete={handleOnDelete}
            />
          </Box>
          {uploadModalOpen && (
            <UploadPhotoModal
              minWidth={560}
              minHeight={330}
              aspect={560 / 330}
              open={uploadModalOpen}
              onClose={() => setUploadModalOpen(false)}
              onSave={onPhotoSave}
            />
          )}
        </Box>
      </Spin>
    </>
  );
}
