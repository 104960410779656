import { FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { getDateFormattedCell } from '../cells/DateFormattedCell';
import { IconButtonRenderer } from '../renderers/renderers';
import { useHistory } from 'react-router-dom';
import { getHistorySearchParam } from '@helpers/history';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import { message } from 'antd';
import React from 'react';
import { TreatmentType } from '@modelTypes/treatment';
import getIdCell from '@components/AntGrid/cells/IdCell';
import { dispatchRefreshTreatmentTypesGridEvent } from '@containers/Offers/TypesOfCare/components/TypesOfCareGrid';
import { useTypeOfCareState } from '@zustandStorage/offers/types';

export type TreatmentTypesColumnsNames = `${keyof TreatmentType}Column` | 'editColumn' | 'deleteColumn';

export const treatmentTypeColumnsDefinitions: Partial<Record<TreatmentTypesColumnsNames, ColumnType<TreatmentType>>> = {
  idColumn: createColumnDefinition<TreatmentType>('#', 'id', { width: 80, render: getIdCell }),
  nameColumn: createColumnDefinition<TreatmentType>('Name', 'name'),
  urlSlugColumn: createColumnDefinition<TreatmentType>('Url Slug', 'urlSlug'),
  updatedAtColumn: createColumnDefinition<TreatmentType>('Updated At', 'updatedAt', {
    render: getDateFormattedCell,
    width: 160
  }),
  createdAtColumn: createColumnDefinition<TreatmentType>('Created At', 'createdAt', {
    render: getDateFormattedCell,
    width: 160
  }),
  editColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, row: TreatmentType) => {
      return <EditCell record={row} />
    },
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell(),
    sorter: false,
    filtered: false,
  }
};

export const treatmentTypeColumns: any = [
  treatmentTypeColumnsDefinitions.idColumn,
  treatmentTypeColumnsDefinitions.nameColumn,
  treatmentTypeColumnsDefinitions.urlSlugColumn,
  treatmentTypeColumnsDefinitions.updatedAtColumn,
  treatmentTypeColumnsDefinitions.createdAtColumn,
  treatmentTypeColumnsDefinitions.editColumn,
  treatmentTypeColumnsDefinitions.deleteColumn,
];

export const treatmentTypeFiltersConfig: FiltersConfig = {
  [treatmentTypeColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [treatmentTypeColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [treatmentTypeColumnsDefinitions.urlSlugColumn?.key as string]: { filterType: FilterType.SEARCH },
  [treatmentTypeColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  [treatmentTypeColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function EditCell({ record }: { record: TreatmentType }) {
  const history = useHistory();
  const langId = +getHistorySearchParam(history, 'language-id', 1);
  const params = langId > 1 ? `?language-tab=${langId - 1}` : '';

  return IconButtonRenderer<TreatmentType>(`/offers/types-of-care/${record.id}/edit${params}`);
}

function CustomDeleteCell({ record }: { record: TreatmentType }) {
  const { deleteType } = useTypeOfCareState()
  return (
    <DeleteCell
      record={record}
      title="Delete Treatment method"
      deleteAction={deleteType}
      onSuccess={() => {
        dispatchRefreshTreatmentTypesGridEvent();
        message.success('The Treatment Type of care has been successfully deleted');
      }}
      hasNotRelationsContent={() => <div>Treatment Type of care has no any relations.</div>}
      hasRelationsContent={() => {
        return (
          <div style={{}}>
            <div>If you really want to continue, enter your password.</div>
          </div>
        );
      }}
    />
  );
}

function getDeleteCell() {
  return (value: any, record: TreatmentType) => {
    return (
      <CustomDeleteCell record={record} />
    );
  };
}
