import React from 'react';
import { Select } from 'antd';
import { toLower } from 'lodash';
import { Blog } from '@modelTypes/blog';

interface Props {
  options: Array<Blog>;
  onChange: (option: any) => void;
  loading?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  placeholder?: string;
  mode?: 'multiple' | 'tags';
  style?: React.CSSProperties;
  excludedIds?: Array<number>;
  defaultValue?: any;
}

export default function SelectArticle(
  {
    options,
    onChange,
    placeholder,
    mode,
    defaultValue = undefined,
    loading = false,
    disabled = false,
    style = { width: 180 },
  }: Props
) {
  const placeholderValue = !placeholder ? mode ? 'Select blogs' : 'Select blog' : placeholder;

  const handleChange = (value: string[], option: any) => {
    onChange(option);
  };

  return (
    <Select
      allowClear
      mode={mode}
      style={style}
      placeholder={placeholderValue}
      fieldNames={{
        label: 'title',
        value: 'id',
      }}
      labelInValue={true}
      defaultValue={defaultValue}
      onChange={handleChange}
      loading={loading}
      options={options}
      disabled={disabled}
      filterOption={(inputValue: string, option?: Blog) => {
        if (inputValue && inputValue.length && option && option.title) {
          return Boolean(toLower(option.title).indexOf(toLower(inputValue)) > -1);
        }

        return true;
      }}
    />
  );
}
