import { FieldIcon } from '.';
import FieldIconWrapper from './FieldIconWrapper/FieldIconWrapper';

const svg = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M9.74056 16.8656C9.55733 16.67 9.25059 16.664 9.04194 16.8322C8.14913 17.5522 7.03118 17.994 5.90595 17.994C3.1724 17.994 0.948942 15.7705 0.948942 13.0369C0.948942 12.1505 1.23729 11.2183 1.69717 10.4415C1.8285 10.2196 1.78585 9.92927 1.57968 9.77451C1.368 9.61561 1.06564 9.66032 0.927515 9.8861C0.354533 10.8227 0 11.9495 0 13.0356C0 14.9552 0.910269 16.7038 2.49953 17.84C3.087 19.5236 3.29238 20.8663 3.36321 21.535C3.39048 21.7925 3.60561 21.998 3.86452 21.998C4.12885 21.998 4.34111 21.7838 4.32001 21.5203C4.27345 20.9388 4.13374 19.8733 3.71572 18.5088L3.71843 18.5061C4.44014 18.7957 5.17474 18.9422 5.90663 18.9422C7.24965 18.9422 8.62701 18.4011 9.70655 17.5308C9.91297 17.3644 9.92181 17.0591 9.74056 16.8656Z"
    fill="currentColor" />
  <path
    d="M21.0749 9.88931C20.9363 9.66248 20.6326 9.61767 20.4201 9.77736C20.2133 9.93281 20.1706 10.2242 20.3025 10.4469C20.7618 11.2225 21.0497 12.1525 21.0497 13.0383C21.0497 15.7712 18.8262 17.9953 16.0927 17.9953C14.9943 17.9953 13.8538 17.5562 12.955 16.8599C12.7446 16.6969 12.4409 16.7059 12.2593 16.9005C12.0777 17.0951 12.0868 17.4015 12.2941 17.5685C13.3734 18.438 14.7502 18.9782 16.0927 18.9782C16.8239 18.9782 17.5585 18.8317 18.2795 18.5427C18.2795 18.5427 18.2829 18.5434 18.2822 18.5454C17.8812 19.8379 17.7371 20.9261 17.6855 21.5186C17.6625 21.7831 17.8739 21.9995 18.1394 21.999C18.3969 21.9984 18.6105 21.7937 18.6373 21.5376C18.7071 20.8703 18.911 19.5276 19.4991 17.8427C21.0884 16.7065 21.9993 14.9572 21.9993 13.0376C21.9993 11.9522 21.6468 10.8257 21.0749 9.88931Z"
    fill="currentColor" />
  <path
    d="M2.98654 7.37725C2.98654 6.82906 3.43076 6.38456 3.97895 6.38422L8.96356 6.38107C9.22518 6.38091 9.43719 6.16877 9.43719 5.90715C9.43719 5.64592 9.22581 5.43395 8.96459 5.43322L8.70937 5.43251L8.70733 5.43047C8.66731 5.15373 8.60831 4.87495 8.52895 4.58531L8.53165 4.5826C9.28117 4.87562 10.1094 5.02417 10.9993 5.02417C11.9462 5.02417 12.7724 4.87969 13.4602 4.59549C13.4602 4.59549 13.4636 4.59616 13.4629 4.5982C13.389 4.86409 13.3327 5.1388 13.2913 5.43047L13.2893 5.43251L10.3239 5.43076C10.0609 5.4306 9.84756 5.64379 9.84756 5.90683C9.84756 6.16972 10.0606 6.38286 10.3235 6.38291L18.4467 6.38472C18.759 6.38479 19.0121 6.63794 19.0121 6.9502V12.6954C19.0121 12.9578 19.2248 13.1706 19.4872 13.1706C19.7496 13.1706 19.9624 12.9578 19.9624 12.6954V6.62434C19.9624 5.96716 19.4297 5.43435 18.7726 5.43412L14.2552 5.43251L14.2531 5.4298C14.321 5.08386 14.4634 4.48424 14.8093 3.78559C15.6442 3.05667 15.9943 2.16668 16.1361 1.64043C16.2037 1.38918 16.026 1.17389 15.7664 1.15518C15.4951 1.13562 15.2524 1.33846 15.1704 1.59783C14.906 2.43406 14.0044 4.07455 10.9986 4.07455C9.36597 4.07455 8.15249 3.59364 7.39348 2.64537C7.08169 2.25599 6.91255 1.86246 6.82184 1.57246C6.74468 1.32575 6.51354 1.13375 6.25574 1.15272C5.99021 1.17225 5.79883 1.40669 5.86994 1.66328C6.01676 2.19304 6.36932 3.06961 7.18863 3.78492C7.52642 4.46729 7.6709 5.03028 7.74687 5.4298L7.74483 5.43251L3.22474 5.43412C2.56834 5.43435 2.0364 5.96662 2.03657 6.62302L2.03816 12.6975C2.03822 12.9597 2.25113 13.1721 2.51337 13.1716C2.77486 13.171 2.98654 12.9589 2.98654 12.6974V7.37725Z"
    fill="currentColor" />
  <path
    d="M2.71522 14.0164C2.71522 14.8046 3.30942 15.487 4.09557 15.5446C4.78201 15.5955 5.39452 15.1804 5.63057 14.5319L5.63259 14.5306H16.3945L16.3966 14.5319C16.6021 15.1702 17.2566 15.6091 17.9655 15.5426C18.738 15.47 19.3166 14.7978 19.3166 14.0218C19.3155 13.778 19.1175 13.5809 18.8737 13.5809H3.15273C2.9119 13.5809 2.71635 13.7756 2.71522 14.0164ZM18.1045 14.5306C18.0224 14.576 17.918 14.5991 17.7884 14.5991C17.6969 14.5991 17.6066 14.574 17.528 14.5306H18.1045ZM4.46864 14.5306C4.39063 14.574 4.29973 14.5991 4.20816 14.5991C4.07929 14.5991 3.97415 14.5767 3.89208 14.5306H4.46864Z"
    fill="currentColor" />
  <path
    d="M10.6595 2.30893H11.3127C11.915 2.30893 12.4387 1.85854 12.4889 1.25825C12.5465 0.562311 12.0202 0 11.3391 0H10.6859C10.0836 0 9.55996 0.450392 9.50977 1.05069C9.45211 1.74662 9.97847 2.30893 10.6595 2.30893ZM10.4553 1.14768C10.4574 1.0378 10.551 0.950299 10.6608 0.950299H11.3357C11.4456 0.950299 11.5392 1.03712 11.5413 1.14768C11.544 1.28131 11.4694 1.35864 11.3378 1.35864H10.6588C10.5272 1.35864 10.4526 1.28131 10.4553 1.14768Z"
    fill="currentColor" />
  <path
    d="M6.1454 11.7678L7.26255 10.6507H7.26527L8.38243 11.7678H8.38515L9.0492 11.1038V11.1011L7.93204 9.9839V9.98119L9.0492 8.86403V8.86132L8.38515 8.19726H8.38243L7.26527 9.31442H7.26255L6.1454 8.19726H6.14268L5.47864 8.86132V8.86403L6.5958 9.98119V9.9839L5.47864 11.1011V11.1038L6.14268 11.7678H6.1454Z"
    fill="currentColor" />
  <path
    d="M9.88012 11.7678L10.9973 10.6507H11L12.1172 11.7678H12.1199L12.7839 11.1038V11.1011L11.6668 9.9839V9.98119L12.7839 8.86403V8.86132L12.1199 8.19726H12.1172L11 9.31442H10.9973L9.88012 8.19726H9.87741L9.21335 8.86132V8.86403L10.3305 9.98119V9.9839L9.21335 11.1011V11.1038L9.87741 11.7678H9.88012Z"
    fill="currentColor" />
  <path
    d="M13.6142 11.7678L14.7313 10.6507H14.734L15.8512 11.7678H15.8539L16.518 11.1038V11.1011L15.4008 9.9839V9.98119L16.518 8.86403V8.86132L15.8539 8.19726H15.8512L14.734 9.31442H14.7313L13.6142 8.19726H13.6115L12.9474 8.86132V8.86403L14.0646 9.98119V9.9839L12.9474 11.1011V11.1038L13.6115 11.7678H13.6142Z"
    fill="currentColor" />
</svg>`;

export default { id: '310 Sex & Porn Addiction', svg, component: <FieldIconWrapper svg={svg} /> } as FieldIcon;
