import { create } from 'zustand';
import produce from 'immer';
import { AntGetListParams, ListResponse, UpdateSettingRequest, UpdateSettingResponse } from '@api/types';
import { initialFormState, initialGridState, initialSelectState } from '@zustandStorage/helpers/initialStates';
import { Setting } from '@modelTypes/setting';
import { getSettings, updateSetting } from '@api/settingApi';

interface Editable {
  status: boolean,
  rowId: number | null;
  form: any,
}

export interface SettingGridState {
  grid: ListResponse<Setting> & {
    loading: boolean;
    editable: Editable

  };
  form: {
    loading: boolean;
    row: Setting | null;
  };
  select: {
    loading: boolean;
    search: string;
    data: Array<Setting>;
  }
  setGridEditable: (data: Editable) => void;
  clearGridData: () => void;
  clearFormData: () => void;
  fetchSettings: (params?: AntGetListParams) => Promise<ListResponse<Setting>>;
  // fetchSetting: (id: number) => Promise<any>;
  // createSetting: (payload: CreateSettingRequest) => Promise<CreateSettingResponse>;
  updateSetting: (id: number, payload: UpdateSettingRequest) => Promise<UpdateSettingResponse>;
}

const editableInitialData = {
  status: false,
  rowId: null,
  form: {},
};


export const useSettingStore = create<SettingGridState>((set) => ({
  grid: {
    ...initialGridState,
    editable: editableInitialData,
  },
  form: initialFormState,
  select: initialSelectState,
  setGridEditable: (data) => {
    set(produce((state) => {
      state.grid.editable = data;
    }))
  },
  clearGridData: () => set(() => ({ grid: { ...initialGridState, editable: editableInitialData } })),
  clearFormData: () => set(() => ({ form: initialFormState })),
  fetchSettings: async (params) => {
    set(produce((state) => {
      state.grid.loading = true;
    }));

    return getSettings(params)
      .then((response) => {
        set(produce((state) => {
          state.grid.data = response.data;
          state.grid.meta = response.meta;
          state.grid.loading = false;
        }));

        return response;
      });
  },
  updateSetting: async (id: number, payload) => {
    set(produce((state) => {
      state.form.loading = true;
    }));

    return updateSetting(id, payload)
      .then((response) => {
        set(produce((state) => {
          state.form.row = response;
          state.form.loading = false;
        }));

        return response;
      })
      .catch((e) => {
        set(produce((state) => {
          state.form.loading = false;
        }));

        throw e;
      })
  },
// fetchSetting: async (id) => {
  //   set(produce((state) => {
  //     state.form.loading = true;
  //   }));
  //
  //   return getSetting(id)
  //     .then((response) => {
  //       set(produce((state) => {
  //         state.form.row = response;
  //         state.form.loading = false;
  //       }));
  //
  //       return response;
  //     });
  // },
  // createSetting: async (payload) => {
  //   set(produce((state) => {
  //     state.form.loading = true;
  //   }));
  //
  //   return createSetting(payload)
  //     .then((response) => {
  //       set(produce((state) => {
  //         state.form.row = response;
  //         state.form.loading = false;
  //       }));
  //
  //       return response;
  //     })
  //     .catch((e) => {
  //       set(produce((state) => {
  //         state.form.loading = false;
  //       }));
  //
  //       throw e;
  //     })
  // },
}));