import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { Vendors } from '../../../modelTypes/vendor';

interface Props {
  onClear?: () => void;
  loading?: boolean;
  multiple?: boolean;
  mode?: 'multiple' | 'tags';
  defaultValue?: any;
  disabled?: boolean;
  isPlaceActive?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
}

interface Params {
  seller: string;
}

function getOptions() {
  return [
    { value: Vendors.USERS, label: 'Vendor list' },
    { value: Vendors.COMPANY, label: 'Organizations' },
  ];
}

export default function SelectVendor(
  {
    onClear,
    loading = false,
    disabled = false,
    style = { width: 180 },
  }: Props
) {
  const history = useHistory();
  const { seller } = useParams<Params>();
  const [defaultValue, setDefaultValue] = useState<any>(null);

  const handleChange = (value: number, option: any) => {
    const params = new URLSearchParams(window.location.search);
    history.push(`/vendors/${value}${params ? `?${params}` : ''}`);
  };

  useEffect(() => {
    if (seller === Vendors.USERS) {
      setDefaultValue(Vendors.USERS);
    } else if (seller === Vendors.COMPANY) {
      setDefaultValue(Vendors.COMPANY);
    }
  }, [seller]);

  return (
    <Select
      style={style}
      placeholder="Select step"
      value={defaultValue}
      onChange={handleChange}
      onClear={onClear}
      loading={loading}
      options={getOptions()}
      disabled={disabled}
    />
  );
}
