import { RootAppState } from './types';
import app from './app/reducer';
import authors from './authors/reducer';
import blogs from './blogs/reducer';
import certificates from './certificates/reducer';
import cities from './cities/reducer';
import { combineReducers } from 'redux'
import contents from './contents/reducer';
import countries from './countries/reducer';
import departments from './departments/reducer';
import doctors from './doctors/reducer';
import frontendTranslations from './frontendTranslations/reducer';
import hospitals from './hospitals/reducer';
import orders from './orders/reducer';
import roles from './roles/reducer';
import searchSections from './searchSections/reducer';
import searchSynonyms from './searchSynonyms/reducer';
import specializations from './specializations/reducer';
import tags from './tags/reducer';
import templates from './templates/reducer';
import users from './users/reducer';
import treatment from './treatment/reducers';

export default combineReducers<RootAppState>({
  app,
  authors,
  blogs,
  templates,
  cities,
  certificates,
  contents,
  countries,
  departments,
  doctors,
  frontendTranslations,
  hospitals,
  orders,
  roles,
  searchSections,
  searchSynonyms,
  specializations,
  tags,
  users,
  treatment,
});
