import { FrontendLanguage } from '@modelTypes/frontendLanguage';
import { ListResponse } from '@api/types';
import { ModalData } from '@storage/frontendTranslations/reducer';
import { createAction } from '../helpers';

export enum ActionType {
  START_FETCH_FRONTEND_DICTIONARY = 'FRONTEND_DICTIONARY__START_FETCH_FRONTEND_DICTIONARY',
  END_FETCH_FRONTEND_DICTIONARY = 'FRONTEND_DICTIONARY__END_FETCH_FRONTEND_DICTIONARY',

  START_POST_FRONTEND_TRANSLATION = 'FRONTEND_DICTIONARY__START_POST_FRONTEND_TRANSLATION',
  END_POST_FRONTEND_TRANSLATION = 'FRONTEND_DICTIONARY__END_POST_FRONTEND_TRANSLATION',

  START_UPDATE_FRONTEND_TRANSLATION = 'FRONTEND_DICTIONARY__START_UPDATE_FRONTEND_TRANSLATION',
  END_UPDATE_FRONTEND_TRANSLATION = 'FRONTEND_DICTIONARY__END_UPDATE_FRONTEND_TRANSLATION',

  START_POST_FRONTEND_DICTIONARY = 'APP__START_POST_FRONTEND_DICTIONARY',
  END_POST_FRONTEND_DICTIONARY = 'APP__END_POST_FRONTEND_DICTIONARY',

  START_DELETE_FRONTEND_TRANSLATION = 'FRONTEND_DICTIONARY__START_DELETE_FRONTEND_TRANSLATION',
  END_DELETE_FRONTEND_TRANSLATION = 'FRONTEND_DICTIONARY__END_DELETE_FRONTEND_TRANSLATION',

  OPEN_MODAL_WITH_FORM = 'FRONTEND_DICTIONARY__OPEN_MODAL_WITH_FORM',
  CLOSE_MODAL_WITH_FORM = 'FRONTEND_DICTIONARY__CLOSE_MODAL_WITH_FORM',
  MODAL_WITH_FORM_SET_DATA = 'FRONTEND_DICTIONARY__MODAL_WITH_FORM_SET_DATA',
}

export const startFetchFrontendDictionary = createAction<ActionType.START_FETCH_FRONTEND_DICTIONARY>(ActionType.START_FETCH_FRONTEND_DICTIONARY);
export const endFetchFrontendDictionary = createAction<ActionType.END_FETCH_FRONTEND_DICTIONARY, ListResponse<FrontendLanguage>>(ActionType.END_FETCH_FRONTEND_DICTIONARY);

export const startPostFrontendTranslation = createAction<ActionType.START_POST_FRONTEND_TRANSLATION>(ActionType.START_POST_FRONTEND_TRANSLATION);
export const endPostFrontendTranslation = createAction<ActionType.END_POST_FRONTEND_TRANSLATION, FrontendLanguage>(ActionType.END_POST_FRONTEND_TRANSLATION);

export const startUpdateFrontendTranslation = createAction<ActionType.START_UPDATE_FRONTEND_TRANSLATION>(ActionType.START_UPDATE_FRONTEND_TRANSLATION);
export const endUpdateFrontendTranslation = createAction<ActionType.END_UPDATE_FRONTEND_TRANSLATION, FrontendLanguage>(ActionType.END_UPDATE_FRONTEND_TRANSLATION);

export const startPostFrontendDictionary = createAction<ActionType.START_POST_FRONTEND_DICTIONARY>(ActionType.START_POST_FRONTEND_DICTIONARY);
export const endPostFrontendDictionary = createAction<ActionType.END_POST_FRONTEND_DICTIONARY>(ActionType.END_POST_FRONTEND_DICTIONARY);

export const startDeleteFrontendTranslation = createAction<ActionType.START_DELETE_FRONTEND_TRANSLATION>(ActionType.START_DELETE_FRONTEND_TRANSLATION);
// export const endDeleteFrontendTranslation = createAction<ActionType.END_DELETE_FRONTEND_TRANSLATION, number>(ActionType.END_DELETE_FRONTEND_TRANSLATION);

export const openModalWithForm = createAction<ActionType.OPEN_MODAL_WITH_FORM>(ActionType.OPEN_MODAL_WITH_FORM);
export const closeModalWidthForm = createAction<ActionType.CLOSE_MODAL_WITH_FORM>(ActionType.CLOSE_MODAL_WITH_FORM);
export const setModalWithFormData = createAction<ActionType.MODAL_WITH_FORM_SET_DATA, ModalData>(ActionType.MODAL_WITH_FORM_SET_DATA);

export type Actions = ReturnType<typeof startFetchFrontendDictionary>
  | ReturnType<typeof endFetchFrontendDictionary>
  | ReturnType<typeof startPostFrontendTranslation>
  | ReturnType<typeof endPostFrontendTranslation>
  | ReturnType<typeof startUpdateFrontendTranslation>
  | ReturnType<typeof endUpdateFrontendTranslation>
  | ReturnType<typeof startPostFrontendDictionary>
  | ReturnType<typeof endPostFrontendDictionary>
  | ReturnType<typeof startDeleteFrontendTranslation>
  // | ReturnType<typeof endDeleteFrontendTranslation>
  | ReturnType<typeof openModalWithForm>
  | ReturnType<typeof closeModalWidthForm>
  | ReturnType<typeof setModalWithFormData>;