import { create } from 'zustand';
import produce from 'immer';

interface GridOptions {
  [key: string]: string | Array<string> | null;
}

export interface GridState {
  gridOptions: {
    [key: string]: GridOptions
  },
  setGridOptions: (gridId: string, options: GridOptions) => void;
}

export const useGridStore = create<GridState>((set) => ({
  gridOptions: {},
  setGridOptions: (id, options) => {
    set(produce((state) => {
      state.gridOptions[id] = options;
    }))
  },
}));