import { ActionType, Actions } from './actions';

import { Language } from '@modelTypes/language';
import { User } from '@modelTypes/user';

export interface AppState {
  readonly hasToken: boolean;
  readonly user: User | null;
  readonly languages: Array<Language>;
  readonly activeSidebarItem: string | null;
  readonly forbiddenModal: {
    visible: boolean;
    data: any;
  };
  readonly clearCacheModal: Readonly<{
    loading: boolean;
  }>;
}

const initialState: AppState = {
  hasToken: !!localStorage.getItem('tokens'),
  user: null,
  languages: [],
  activeSidebarItem: null,
  forbiddenModal: {
    visible: false,
    data: {},
  },
  clearCacheModal: {
    loading: false,
  }
};

export default function user(state = initialState, action: Actions): AppState {
  switch (action.type) {
    case ActionType.SET_HAS_TOKEN:
      return {
        ...state,
        hasToken: action.data,
      };
    case ActionType.END_FETCH_USER:
      return {
        ...state,
        user: action.data,
      };
    case ActionType.UPDATE_USER_DATA:
      return {
        ...state,
        user: action.data,
      };
    case ActionType.END_FETCH_LANGUAGES:
      return {
        ...state,
        languages: action.data,
      };
    case ActionType.SET_ACTIVE_SIDEBAR_ITEM:
      return {
        ...state,
        activeSidebarItem: action.data,
      };
    case ActionType.FORBIDDEN_MODAL_SET_VISIBILITY:
      return {
        ...state,
        forbiddenModal: action.data,
      };
    case ActionType.CLEAR_DATA:
      return {
        ...initialState,
        hasToken: state.hasToken,
      };
    case ActionType.START_CLEAR_CACHE:
      return {
        ...state,
        clearCacheModal: {
          loading: true,
        }
      };
    case ActionType.END_CLEAR_CACHE:
      return {
        ...state,
        clearCacheModal: {
          loading: false,
        }
      };
    default:
      return state;
  }
}