import { Button, message, Modal, Radio, RadioChangeEvent, Table, Transfer } from 'antd';
import difference from 'lodash/difference';
import * as React from 'react';
import { useEffect, useState } from 'react';

import type { ColumnsType, TableRowSelection, } from 'antd/es/table/interface';
import type { TransferItem, TransferProps } from 'antd/es/transfer';
import { Skill, SkillOffer } from '@modelTypes/skill';
import { useDoctorSkillsStore } from '@zustandStorage/doctor/skills';
import { useParams } from 'react-router-dom';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { dispatchRefreshDoctorSkillsGridEvent } from '@containers/Doctors/FormSteps/SkillsStep/components/SkillsGrid';
import { InputStatus } from 'antd/es/_util/statusUtils';
import similarity from 'similarity';

interface ModalProps {
  isOpen: boolean;
  handleCancel: () => void;
}

interface RouteParams {
  id: string;
}

interface FooterComponentProps {
  handleCancel: () => void;
  handleOkButton: () => void;
  setWordCount: (value: number) => void;
  wordCount: number;
  disabled: boolean;
  updateLoading: boolean;
}

interface TableTransferProps extends TransferProps<TransferItem> {
  dataSource: SkillOffer[];
  leftColumns: ColumnsType<any>;
  rightColumns: ColumnsType<any>;
  rightLoading: boolean;
  leftLoading: boolean;
  // columns: ColumnsType<any>;
}

const MAX_SKILLS = 50;

const TableTransfer = (
  {
    dataSource,
    targetKeys,
    onChange,
    onSelectChange,
    leftColumns,
    rightColumns,
    leftLoading,
    rightLoading,
    disabled,
    status,
  }: TableTransferProps) => (

  <Transfer
    titles={['Tags offered', 'Doctor\'s tags']}
    dataSource={dataSource}
    targetKeys={targetKeys}
    onChange={onChange}
    onSelectChange={onSelectChange}
    rowKey={(t) => t.name}
    showSearch
    filterOption={(inputValue, item) =>
      item.name!.indexOf(inputValue) !== -1 || item.name.indexOf(inputValue) !== -1
    }
    disabled={disabled}
    status={status}
  >
    {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
      }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;
      // const loading = direction === 'left' ? leftLoading : rightLoading
      const rowSelection: TableRowSelection<TransferItem> = {
        // getCheckboxProps: (item) => ({ disabled:item.disabled }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys as string[], selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key as string, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      return (
        <Table
          loading={leftLoading}
          columns={columns}
          rowSelection={rowSelection}
          dataSource={filteredItems}
          size="small"
          scroll={{ x: 'auto', y: '50vh' }}
          style={{ height: '100%' }}
          pagination={{
            defaultPageSize: 200,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
        />
      );
    }}
  </Transfer>
);


const leftTableColumns: ColumnsType<SkillOffer> = [
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Name',
    width: '300px'
  },
  {
    key: 'frequency',
    dataIndex: 'frequency',
    title: 'Frequency',
  },
  {
    key: 'density',
    dataIndex: 'density',
    title: 'Density',
  },
];

const rightTableColumns: ColumnsType<Skill> = [
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Name',
  },
];

export default function AddSkillsModal({ isOpen, handleCancel }: ModalProps) {
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [initialDataSource, setDataSource] = useState<Array<SkillOffer>>([])
  const [wordCount, setWordCount] = useState(1);
  const [formChanged, setFormChanged] = React.useState<boolean>(false);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<InputStatus>('');
  const [messageApi, contextHolder] = message.useMessage();
  const { confirm, warning } = Modal;
  const { id } = useParams<RouteParams>();
  const {
    fetchDoctorSkillsOffers,
    updateDoctorSkills,
    skillsOffer: { data: offerSkills, loading: loadingOffers, updateLoading },
    grid: { data: doctorSkills, loading: loadingSkills }
  } = useDoctorSkillsStore()

  const getDataSource = (offerSkills: Array<SkillOffer>, doctorSkills: Array<Skill>) => {
    if (!offerSkills.length && !doctorSkills.length) {
      return []
    } else if (offerSkills.length) {
      const uniqueDoctorSkills = doctorSkills.filter((doctorSkill) => {
        return !offerSkills.some((offerSkill) => offerSkill.name === doctorSkill.name);
      })

      return [...offerSkills, ...uniqueDoctorSkills.map((skill) => ({
        name: skill.name,
        frequency: 0,
        density: 0,
      }))]
    } else {
      return doctorSkills.map((skill) => ({
        name: skill.name,
        frequency: 0,
        density: 0,
      }))
    }
  }

  const onChange = (nextTargetKeys: string[]) => {
    findSimilarWords(nextTargetKeys);

    setTargetKeys(nextTargetKeys);
    setFormChanged(true);
  };
  
  const findSimilarWords = async (nextTargetKeys: string[]) => {
    const diff = difference(nextTargetKeys, targetKeys);

    if (!diff.length) {
      return [];
    }

    const similarItems: string[] = [];

    for (let i = 0; i < diff.length; i++) {
      const item = diff[i];

      for (let j = 0; j < targetKeys.length; j++) {
        const itemToCompare = targetKeys[j];
        const isSimilar = similarity(item, itemToCompare) >= 0.8;
        
        if (isSimilar) {
          similarItems.push(`${item} - ${itemToCompare}`);
        }
      }
    }

    for (let i = 0; i < nextTargetKeys.length; i++) {
      const item = nextTargetKeys[i];
      const matches = nextTargetKeys.filter((s) => similarity(item, s) >= 0.8 && item !== s);

      similarItems.push(...matches.map((s) => `${item} - ${s}`));
    }

    if (similarItems.length) {
      warning({
        title: 'Found similar words',
        icon: <ExclamationCircleFilled />,
        content: (
          <div>
            <p>Similar words found:</p>
            <ul>
              {similarItems.map((word) => <li key={word}>{word}</li>)}
            </ul>
            <p>These words are already added. You can remove them from target list.</p>
          </div>
        ),
      });
    }
  };
  
  const onSelectChange = async (sourceSelectedKeys: string[]) => {

    if (sourceSelectedKeys.length + targetKeys.length > MAX_SKILLS) {
      setDisabled(true);
      setStatus('error');
      messageApi.error(`You can select up to ${MAX_SKILLS} skills`);
    } else {
      setDisabled(false);
      setStatus('');
    }
  };

  const handleOkButton = async () => {
    // const skills = targetKeys.map((item) => {
    //   return { name: item }
    // })
    const skills = initialDataSource.reduce((acc: Partial<Skill>[], item: SkillOffer) => {
      if (targetKeys.includes(item.name)) {
        acc.push({ name: item.name, frequency: item.frequency });
      }
      return acc;
    }, []);

    // console.log(skills)
    await updateDoctorSkills(+id, { skills })
      .then((data) => {
        messageApi.destroy('startCreate');
        message.success('The skill has been successfully added');
        dispatchRefreshDoctorSkillsGridEvent();

        setFormChanged(false);
        fetchDoctorSkillsOffers(+id, { wordsCount: wordCount });
        setTargetKeys(doctorSkills.map((item) => item.name));

        // handleCancel()
      })
      .catch((e) => {
        messageApi.destroy('startCreate');
        message.error('An error occurred, please try again now')
      })
  };

  const showConfirm = () => {
    confirm({
      title: 'Data not saved!',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to close modal window?',
      onOk() {
        handleCancel()
      },
      // onCancel() {
      //
      // },
    });
  };

  const handleCloseModal = () => {
    if (formChanged) {
      showConfirm()
    } else {
      handleCancel()
    }
  }

  useEffect(() => {
    if (!isOpen) {
      return
    }
    fetchDoctorSkillsOffers(+id, { wordsCount: wordCount });
  }, [isOpen, wordCount]);

  useEffect(() => {
    setTargetKeys(doctorSkills.map((item) => item.name));
    setDataSource(getDataSource(offerSkills, doctorSkills));
  }, [offerSkills, doctorSkills]);

  useEffect(() => {
    if (isOpen) {
      return
    }
    setFormChanged(false);
  }, [isOpen])

  return (
    <Modal
      title="Add tags"
      open={isOpen}
      onCancel={handleCloseModal}
      width="70vw"
      onOk={handleOkButton}
      footer={
        <FooterComponent
          handleOkButton={handleOkButton}
          handleCancel={handleCloseModal}
          setWordCount={(value) => setWordCount(value)}
          wordCount={wordCount}
          disabled={loadingOffers || formChanged}
          updateLoading={updateLoading}
        />
      }
    >
      {contextHolder}
      <TableTransfer
        leftLoading={loadingOffers}
        rightLoading={loadingSkills}
        dataSource={initialDataSource}
        targetKeys={targetKeys}
        onChange={onChange}
        onSelectChange={onSelectChange}
        leftColumns={leftTableColumns}
        rightColumns={rightTableColumns}
        disabled={disabled}
        status={status}
      />
    </Modal>
  )
}

function FooterComponent(
  {
    handleCancel,
    handleOkButton,
    setWordCount,
    wordCount,
    disabled,
    updateLoading,
  }: FooterComponentProps) {
  const onChange = (e: RadioChangeEvent) => {
    setWordCount(e.target.value);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
      <div>
        <span style={{ marginRight: 12 }}>Word count:</span>
        <Radio.Group
          onChange={onChange}
          value={wordCount}
          disabled={disabled}
        >
          <Radio value={1}>1</Radio>
          <Radio value={2}>2</Radio>
          <Radio value={3}>3</Radio>
        </Radio.Group>
      </div>
      <div>
        <Button onClick={handleCancel}>
          Cancel
        </Button>
        <Button type="primary" loading={updateLoading} onClick={handleOkButton}>
          Save
        </Button>
      </div>
    </div>
  )
}