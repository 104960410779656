import { FormInstance } from 'antd';
import map from 'lodash/map';

interface PrepareDataOptions {
  selectFieldNames: Array<string>;
}

export function getSelectValue(field: any, defaultValue: any = null) {
  if (typeof field === 'number') {
    return field;
  }

  if (Array.isArray(field)) {
    return typeof field[0] === 'number' ? field.join(',') : map(field, (i) => i.id).join(',');
  }

  if (field && typeof field === 'object' && typeof field !== 'undefined') {
    return field.hasOwnProperty('id') ? field.id : field.value;
  }

  return defaultValue;
}

export function prepareFormDataForBackend<Values = any>(data: FormInstance<Values>, options?: PrepareDataOptions): FormData {
  const formData = new FormData();
  const allOptions = typeof options === 'undefined' ? { selectFieldNames: [] } : options;

  map(data as any, (v, k) => {
    if (allOptions.selectFieldNames.indexOf(k) > -1) {
      formData.append(k, getSelectValue(v));
    } else if (typeof v === 'boolean') {
      formData.append(k, v ? 'true': 'false');
    } else if (typeof v === 'number') {
      formData.append(k, v.toString());
    } else {
      formData.append(k, v);
    }
  });

  return formData;
}