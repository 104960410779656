import Grid from '@components/AntGrid/Grid';
import { AntGetListParams } from '@api/types';
import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks';
import { PaginationConfig } from '@components/AntGrid/types';
import { makeRefreshGridEvent } from '@components/AntGrid/helpers/helpers';
import useRefreshGridEvent from '@components/AntGrid/hooks/useRefreshGridEvent';
import useChangeLanguage from '@components/AntGrid/hooks/useChangeLanguage';
import { getContents } from '@storage/contents/thunkActions';
import { contentColumns, contentFiltersConfig } from '@components/AntGrid/columns/content';
import { ContentsState } from '@storage/contents/reducer';
import { Content, ContentPages } from '@modelTypes/content';
import useChangeGridOptions from "@components/AntGrid/hooks/useChangeGridOptions";
import { useEffect } from 'react';

export const [
  refreshContentsGridEvent,
  dispatchRefreshContentsGridEvent
] = makeRefreshGridEvent('onRefreshContentsGrid');


interface Props {
  pageName: ContentPages;
  isMenu?: boolean;
}

export default function ContentsGrid(
  {
    isMenu,
    pageName,
  }: Props
) {
  const dispatch = useDispatch();
  const [languageId, setFirstLoad] = useChangeLanguage((languageId) => dispatch(getContents({ languageId })));
  const { data, meta, loading } = useSelector<ContentsState['grid']>('contents.grid');

  const paginationConfig: PaginationConfig = {
    showCounter: true,
    serverSide: true,
    total: meta.total,
    // ...rest.paginationConfig,
  };

  const onFetchData = (params?: AntGetListParams) => {
    dispatch(getContents({ ...params, languageId }, { pageIndex: pageName }) as unknown as Promise<void>)
      .then(() => {
        setFirstLoad(true);
      });
  };

  useEffect(() => {
    onFetchData();
  }, [pageName, isMenu]);

  useRefreshGridEvent(refreshContentsGridEvent, onFetchData);
  useChangeGridOptions('contentsGrid');

  return (
    <Grid<Content>
      id="contentsGrid"
      onFetch={onFetchData}
      columns={contentColumns}
      data={data}
      scroll={{ x: 'auto' }}
      enableSorting={true}
      loading={loading}
      enableFiltering={true}
      paginationConfig={paginationConfig}
      filtersConfig={contentFiltersConfig}
    />
  );
}