import { Doctor, DoctorTranslation } from '@modelTypes/doctor';
import { BaseRow, FiltersConfig, FilterType } from '@components/AntGrid/types';
import { ColumnType } from 'antd/es/table';
import { createColumnDefinition } from '../helpers/columnsDefinitions';
import { getIsActiveCell } from '@components/AntGrid/cells/StatusCell';
import { getDateFormattedCell } from '../cells/DateFormattedCell';
import { IconButtonRenderer } from '../renderers/renderers';
import React, { useMemo } from 'react';
import map from 'lodash/map';
import { DeleteCell } from '@components/AntGrid/cells/DeleteCell';
import { message } from 'antd';
import { deleteDoctor } from '@storage/doctors/thunkActions';
import { dispatchRefreshDoctorsGridEvent } from '@containers/Doctors/components/DoctorsAntGrid';
import useLanguageTabParam from '@components/AntGrid/hooks/useLanguageTabParam';
import IdCell from '@components/AntGrid/cells/IdCell';
import getIdCell from '@components/AntGrid/cells/IdCell';

export type DoctorColumnsNames = `${keyof Doctor}Column` | 'previewColumn' | 'eyeIconColumn' | 'deleteColumn';

export const doctorColumnsDefinitions: Partial<Record<DoctorColumnsNames, ColumnType<Doctor>>> = {
  idColumn: createColumnDefinition<Doctor>('#', 'id', { width: 80, render: getIdCell }),
  nameColumn: createColumnDefinition<Doctor>('Name', 'name'),
  urlSlugColumn: createColumnDefinition<Doctor>('Url Slug', 'urlSlug'),
  specializationColumn: createColumnDefinition<Doctor>('Specialization description', 'specialization'),
  scrapperLinksCountColumn: createColumnDefinition<Doctor>('Links', 'scrapperLinksCount', {
    render: getIsActiveCell,
    width: 100,
    filtered: false
  }),
  hasOnlineConsultationColumn: createColumnDefinition<Doctor>('Online Consultation', 'hasOnlineConsultation', {
    render: getIsActiveCell,
    width: 130,
    filtered: false
  }),
  publicationsCountColumn: createColumnDefinition<Doctor>('Publications', 'publicationsCount', {
    render: getIsActiveCell,
    width: 130,
    filtered: false
  }),
  hasAboutColumn: createColumnDefinition<Doctor>('Summary', 'hasAbout', {
    render: getIsActiveCell,
    width: 110,
    filtered: false
  }),
  skillsCountColumn: createColumnDefinition<Doctor>('Skills & Expertise', 'skillsCount', {
    render: getIsActiveCell,
    width: 100,
    filtered: false
  }),
  isActiveColumn: createColumnDefinition<Doctor>('Active', 'isActive', {
    render: getIsActiveCell,
    width: 80,
    filtered: false
  }),
  isLanguageActiveColumn: createColumnDefinition<Doctor>('Lang Active', 'isLanguageActive', {
    render: getIsActiveCell,
    width: 80,
    filtered: false
  }),
  updatedAtColumn: createColumnDefinition<Doctor>('Updated At', 'updatedAt', {
    render: getDateFormattedCell,
    width: 160
  }),
  createdAtColumn: createColumnDefinition<Doctor>('Created At', 'createdAt', {
    render: getDateFormattedCell,
    width: 160
  }),
  specializationsColumn: {
    title: 'Specializations',
    render: (value: any, row: Doctor) => {
      return <SpecializationsCell record={row} />
    },
    sorter: false,
    filtered: false,
  },
  eyeIconColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: (value: any, row: Doctor) => {
      return <EditCell record={row} />
    },
    sorter: false,
    filtered: false,
  },
  deleteColumn: {
    title: '',
    width: 52,
    fixed: 'right',
    render: getDeleteCell<Doctor>(),
    sorter: false,
    filtered: false,
  }
};

export const doctorColumns: any = [
  doctorColumnsDefinitions.idColumn,
  doctorColumnsDefinitions.nameColumn,
  // doctorColumnsDefinitions.urlSlugColumn,
  doctorColumnsDefinitions.specializationColumn,
  doctorColumnsDefinitions.specializationsColumn,
  // doctorColumnsDefinitions.updatedAtColumn,
  // doctorColumnsDefinitions.createdAtColumn,
  doctorColumnsDefinitions.scrapperLinksCountColumn,
  doctorColumnsDefinitions.publicationsCountColumn,
  doctorColumnsDefinitions.hasAboutColumn,
  doctorColumnsDefinitions.skillsCountColumn,
  doctorColumnsDefinitions.hasOnlineConsultationColumn,
  doctorColumnsDefinitions.isActiveColumn,
  doctorColumnsDefinitions.isLanguageActiveColumn,
  doctorColumnsDefinitions.eyeIconColumn,
  doctorColumnsDefinitions.deleteColumn,
];

export const doctorFiltersConfig: FiltersConfig = {
  [doctorColumnsDefinitions.idColumn?.key as string]: { filterType: FilterType.SEARCH },
  [doctorColumnsDefinitions.nameColumn?.key as string]: { filterType: FilterType.SEARCH },
  [doctorColumnsDefinitions.urlSlugColumn?.key as string]: { filterType: FilterType.SEARCH },
  [doctorColumnsDefinitions.specializationColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [doctorColumnsDefinitions.updatedAtColumn?.key as string]: { filterType: FilterType.SEARCH },
  // [doctorColumnsDefinitions.createdAtColumn?.key as string]: { filterType: FilterType.SEARCH },
};

function SpecializationsCell({ record }: { record: Doctor }) {
  const value = useMemo(
    () => {
      return map(record.specializations, (i) => i.name).join(', ');
    },
    [record.specializations]
  );

  return (
    <div>{value}</div>
  );
}

function EditCell({ record }: { record: Doctor }) {
  const langParam = useLanguageTabParam();

  return IconButtonRenderer<Doctor>(`/doctors/edit/${record.id}${langParam}`)
}

function getDeleteCell<T extends BaseRow>() {
  return (value: any, record: T) => {
    return (
      <DeleteCell
        record={record}
        title="Delete Doctor"
        deleteThunkAction={deleteDoctor}
        onSuccess={() => {
          dispatchRefreshDoctorsGridEvent();
          message.success('The doctor has been successfully deleted');
        }}
        hasNotRelationsContent={() => <div>Doctor has no any relations.</div>}
        hasRelationsContent={(data: any) => {
          return (
            <div style={{}}>
              {!!data.relations.length && (
                <div>Doctor is used by next department ids ({map(data.relations, (i) => i.id).join(', ')})</div>
              )}
              <div>If you really want to continue, enter your password.</div>
            </div>
          );
        }}
      />
    );
  };
}

// function BlogPreviewCell<T extends BaseRow>({ record }: { record: T }) {
//   const dispatch = useDispatch();
//   const [loading, setLoading] = useState(false);
//   const { type } = useParams<{ type: BlogTypes }>();
//
//   const handleGetAccessToken = () => {
//     setLoading(true);
//
//     dispatch(getAccessKey(record.id) as unknown as Promise<BlogAccessKey>)
//       .then((data) => {
//         const url = `${process.env.REACT_APP_CLIENT_URL}/blogs/${type}/${record.urlSlug}?token=${data.accessKey}`;
//
//         setLoading(false);
//         if (window !== null) {
//           // @ts-ignore
//           window.open(url, '_blank', 'noopener,noreferrer').focus();
//         }
//       });
//   };
//
//   return (
//     <FrontendPreviewCell
//       loading={loading}
//       onGetAccessToken={handleGetAccessToken}
//     />
//   );
// }
//
// function getPreviewCell<T extends BaseRow>() {
//   return (value: any, record: T) => {
//     return (
//       <BlogPreviewCell
//         record={record}
//       />
//     );
//   };
// }
//
