import React from 'react';
import { Select } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

interface Props {
  // onChange: (option: any) => void;
  onClear?: () => void;
  loading?: boolean;
  multiple?: boolean;
  mode?: 'multiple' | 'tags';
  defaultValue?: any;
  disabled?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
}

const options: Array<any> = [
  { value: 1, label: 'Data' },
  { value: 2, label: 'Certificates' },
];

export default function SelectStep(
  {
    // onChange,
    onClear,
    loading = false,
    disabled = false,
    style = { width: 180 },
  }: Props
) {
  const history = useHistory();
  const { id, step = '1' } = useParams<{ action: string; id: string; step: string }>();
  const stepInt = parseInt(step);

  const handleChange = (value: number) => {
    const v = value > 1 ? `/${value}` : '';
    const idStr = id ? `/${id}` : '';
    const action = v || id ? 'edit' : 'create';

    history.push(`/authors/${action}${idStr}${v}`);
  };

  return (
    <Select
      allowClear
      style={style}
      placeholder="Select step"
      value={stepInt}
      onChange={handleChange}
      onClear={onClear}
      loading={loading}
      options={options}
      disabled={disabled}
    />
  );
}
