import React from 'react';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const SearchSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    x="0"
    y="0"
    enableBackground="new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <path d="M236.535 108.8c24.635-31.165 62.76-51.2 105.477-51.2 15.703 0 30.78 2.715 44.8 7.688V0h-384v512h384V318.707a133.758 133.758 0 01-44.8 7.693c-42.719 0-80.842-20.035-105.477-51.2H66.812v-38.4H215.3c-4.972-14.02-7.688-29.097-7.688-44.8s2.716-30.78 7.688-44.8H66.812v-38.4h169.723zm-169.723 256h256v38.4h-256v-38.4z"/>
    <path d="M342.012 172.8v38.4c10.587 0 19.2-8.613 19.2-19.2s-8.613-19.2-19.2-19.2z"/>
    <path d="M422.066 244.904c10.065-15.18 15.946-33.365 15.946-52.902 0-52.934-43.066-96-96-96s-96 43.066-96 96 43.066 96 96 96c19.538 0 37.722-5.88 52.902-15.945l87.122 87.121 27.151-27.153-87.121-87.121zm-80.054 4.696c-31.761 0-57.6-25.838-57.6-57.6 0-31.761 25.839-57.6 57.6-57.6 31.761 0 57.6 25.839 57.6 57.6s-25.839 57.6-57.6 57.6z"/>
  </svg>
);

export default function SearchIcon(props: Partial<CustomIconComponentProps>) {
  return (
    <Icon
      component={SearchSvg}
      {...props}
    />
  );
}
