import { ActionType, Actions } from './actions';
import { ModalWithFormData, ModalWithFormTypes } from '@components/Modal/ModalWithForm/ModalWithForm';
import { Order, OrderStatuses, PhoneCountry, Status } from '@modelTypes/order';

import { ListResponse } from '@api/types';

export type ModalData = ModalWithFormData<Pick<Order, 'name' | 'email' | 'phone' | 'description'>, Order>;

export interface OrdersState {
  readonly list: ListResponse<Order> & {
    readonly selectedRowsIds: Array<number>;
    readonly status: OrderStatuses | null;
    readonly loading: boolean;
  };
  readonly modalWithForm: {
    readonly open: boolean;
    readonly loading: boolean;
    readonly data: ModalData
  };
  readonly selectPhoneCountry: {
    readonly loading: boolean;
    readonly search: string | null;
    readonly data: Array<PhoneCountry>;
  };
  readonly selectStatus: {
    readonly loading: boolean;
    readonly search: string | null;
    readonly data: Array<Status>;
  };
}

const initialState: OrdersState = {
  list: {
    selectedRowsIds: [],
    status: null,
    loading: false,
    data: [],
    meta: {
      currentPage: 1,
      perPage: 10,
    },
  },
  modalWithForm: {
    open: false,
    loading: false,
    data: {
      type: ModalWithFormTypes.ADD,
      formData: {
        name: '',
        email: '',
        phone: '',
        description: '',
      },
    },
  },
  selectPhoneCountry: {
    loading: false,
    search: null,
    data: [],
  },
  selectStatus: {
    loading: false,
    search: null,
    data: [],
  }
};

export default function orders(state = initialState, action: Actions): OrdersState {
  switch (action.type) {
    case ActionType.START_FETCH_ORDERS:
      return {
        ...state,
        list: {
          ...state.list,
          status: null,
          loading: true,
        }
      }
    case ActionType.END_FETCH_ORDERS:
      return {
        ...state,
        list: {
          selectedRowsIds: initialState.list.selectedRowsIds,
          ...action.data,
          loading: false,
        },
      }
    case ActionType.SET_SELECTED_ROWS_IDS:
      return {
        ...state,
        list: {
          ...state.list,
          selectedRowsIds: action.data,
        },
      }
    case ActionType.START_FETCH_PHONE_COUNTRIES_FOR_SELECT:
      return {
        ...state,
        selectPhoneCountry: {
          ...state.selectPhoneCountry,
          loading: true,
        }
      }
    case ActionType.END_FETCH_PHONE_COUNTRIES_FOR_SELECT:
      return {
        ...state,
        selectPhoneCountry: {
          ...state.selectPhoneCountry,
          loading: false,
          data: action.data.data,
        }
      }
    case ActionType.START_FETCH_STATUSES_FOR_SELECT:
      return {
        ...state,
        selectStatus: {
          ...state.selectStatus,
          loading: true,
        }
      }
    case ActionType.END_FETCH_STATUSES_FOR_SELECT:
      return {
        ...state,
        selectStatus: {
          ...state.selectStatus,
          loading: false,
          data: action.data,
        }
      }
    case ActionType.START_POST_ORDER:
      return {
        ...state,
        modalWithForm: {
          ...state.modalWithForm,
          loading: true,
        },
      }
    case ActionType.END_POST_ORDER_ERROR:
      return {
        ...state,
        modalWithForm: {
          ...state.modalWithForm,
          loading: false,
        },
      }
    case ActionType.START_PATCH_ORDER_FROM_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      }
    case ActionType.END_PATCH_ORDER_FROM_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
        },
      }
    case ActionType.OPEN_MODAL_WITH_FORM:
      return {
        ...state,
        modalWithForm: {
          ...state.modalWithForm,
          open: true,
        },
      };
    case ActionType.MODAL_WITH_FORM_SET_DATA:
      return {
        ...state,
        modalWithForm: {
          ...state.modalWithForm,
          data: action.data,
        },
      };
    case ActionType.CLOSE_MODAL_WITH_FORM:
      return {
        ...state,
        modalWithForm: {
          ...initialState.modalWithForm,
        },
      };
    default:
      return state;
  }
}