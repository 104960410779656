import React from 'react';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const DestinationsSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    x="0"
    y="0"
    enableBackground="new 0 0 60 60"
    version="1.1"
    viewBox="0 0 45.917 45.917"
    xmlSpace="preserve"
  >
    <path d="M33.523 28.334a109.305 109.305 0 01-2.344 3.608c7.121 1.065 10.766 3.347 10.766 4.481 0 1.511-6.459 5.054-18.986 5.054-12.528 0-18.988-3.543-18.988-5.054 0-1.135 3.645-3.416 10.768-4.481a107.787 107.787 0 01-2.345-3.608C5.365 29.661 0 32.385 0 36.424c0 5.925 11.551 9.024 22.959 9.024s22.958-3.1 22.958-9.024c0-4.039-5.364-6.763-12.394-8.09z"></path>
    <path d="M22.96 36.047a3.236 3.236 0 002.613-1.325c3.905-5.33 10.813-15.508 10.813-20.827 0-7.416-6.012-13.427-13.427-13.427-7.417 0-13.427 6.011-13.427 13.427 0 5.318 6.906 15.497 10.812 20.827a3.246 3.246 0 002.616 1.325zM17.374 13.63a5.584 5.584 0 1111.168 0 5.584 5.584 0 01-11.168 0z"></path>
  </svg>
);

export default function DestinationsIcon(props: Partial<CustomIconComponentProps>) {
  return (
    <Icon
      component={DestinationsSvg}
      {...props}
    />
  );
}
